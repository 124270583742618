<div [class]="wrapperClasses">
  <img
    *ngIf="url"
    [class]="imageclasses"
    [src]="url"
    appDefaultImg
    [default]="placeholderImg"
    [alt]="imgAlt"
  />
  <ng-container *ngIf="isVCardShown">
    <div
      class="profile__qr-wrapper rounded-circle pointer-cursor"
      container="body"
      [ngbTooltip]="
        tooltip
          | translate
          | stringReplacement
            : {
                '{tenantName}': tenantName
              }
      "
      (click)="
        $event.preventDefault(); $event.stopPropagation(); handleQrCodeModal()
      "
    >
      <img
        src="/wp-content/themes/jip/assets/images/icons/icon-barcode.svg"
        appDefaultImg
        alt=""
      />
    </div>
  </ng-container>

  <ng-content></ng-content>
</div>
