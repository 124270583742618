import { Component, OnInit } from '@angular/core';
import { BaseEventRegistrationComponent } from '@components/base/base-event-registration/base-event-registration.component';
import { environment } from '@env/environment';
import { UrlParam } from '@shared/enums/url-param.enum';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
})
export class EventRegistrationComponent
  extends BaseEventRegistrationComponent
  implements OnInit
{
  readonly UrlParam = UrlParam;

  ngOnInit(): void {
    this.redirectToEvents();
  }

  setLoginCallback(eventId: number): void {
    const eventDetail = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.event}/${eventId}`
    );

    this.sessionService.setLoginCallBackpage(eventDetail);
  }

  showSuccessMessage(): void {
    this.toastService.showSuccess('UI.Toast.SentSuccessfully');
  }
}
