<div class="organization-logo d-flex align-items-center">
  <a
    [attr.href]="windowOrigin"
    appUnsavedFormCheck
    class="organization-logo__href w-100 h-100"
  >
    <img
      [alt]="alt"
      [src]="organizationLogo"
      appDefaultPlaceholder
      appDynamicImgUrl
    />
  </a>

  <div
    *ngIf="showCopyText"
    (click)="$event.stopPropagation()"
    class="organization-logo__text-copy"
  >
    <app-copy-text [textToCopy]="filterTokenUrl"></app-copy-text>
  </div>
</div>
