<ng-container *ngTemplateOutlet="partnerOrgRef"></ng-container>

<ng-template #partnerOrgRef>
  <ng-container
    *ngIf="(isGridView || editing) && !isTableExpanded; else tableView"
  >
    <div>
      <ng-container *ngIf="editing">
        <div class="form-group search">
          <app-organisation-search
            [organizationType]="organizationType"
            [searchMode]="orgsSearchMode"
            [ignoreCompany]="ignoreCompany"
            [excludedOrganisationIds]="ignoreOrgIds"
            [userId]="userId"
            [searchPlaceholder]="
              'UI.ORG.Partner-organizations-search-placeholder' | translate
            "
            (selectOrganisation)="selectItem($event)"
          ></app-organisation-search>
        </div>
      </ng-container>
    </div>

    <app-organization-card-list
      *ngIf="!paginationInGridView || isCreating; else gridViewWithPagination"
      [editting]="editing"
      [displayItems]="organisations"
      [emptyPlacholderKey]="
        organizationType === organizationTypeEnum.Challenge
          ? 'UI.ORG.Partner-challenges-empty'
          : 'UI.ORG.Partner-organizations-empty'
      "
      [showVenturesCtrl]="showVenturesCtrl"
      (itemRemoved)="removeItem($event)"
      [advancedSetting]="advancedSetting"
      [ignoreCompany]="ignoreCompany"
    >
    </app-organization-card-list>
  </ng-container>
  <ng-template #tableView>
    <div>
      <app-search-input-wrapper
        [searchText]="keyword"
        [editMode]="false"
        (resetSearchClick)="onResetSearchText()"
      >
        <form (submit)="onFilter()" action="#">
          <span
            class="pointer-cursor search__icon-wrapper"
            (click)="onFilter()"
          >
            <i class="search__icon fas fa-search"></i>
          </span>

          <input
            type="text"
            class="form-control mb-3 pl-5"
            placeholder="{{ 'Search' | translate }} {{
              'Organizations' | translate
            }}"
            [(ngModel)]="keyword"
            type="text"
            name="searchOrgText"
            id="searchOrgText"
          />
        </form>
      </app-search-input-wrapper>
    </div>
    <app-metadata-table-partner-organization
      [entityDatas]="items"
      [isLoading]="isLoading"
      [editable]="true"
      [parentEntity]="parentEntity"
      [isAdmin]="isOwner"
      (reloadData)="onFilter()"
    ></app-metadata-table-partner-organization>
    <app-bootstrap-pagination
      [page]="pageIndex"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      [showChangePageSizeCtrl]="true"
      [pageSizeConfig]="pageSizeConfig.SixItemsFirstPage"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>
  </ng-template>
</ng-template>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="partnerOrgRef"></ng-container>
  </div>
</ng-template>

<ng-template #gridViewWithPagination>
  <app-organization-card-list
    [editting]="editing"
    [displayItems]="items"
    [emptyPlacholderKey]="
      organizationType === organizationTypeEnum.Challenge
        ? 'UI.ORG.Partner-challenges-empty'
        : 'UI.ORG.Partner-organizations-empty'
    "
    [showVenturesCtrl]="showVenturesCtrl"
    (itemRemoved)="removeItem($event)"
    [advancedSetting]="advancedSetting"
    [ignoreCompany]="ignoreCompany"
  >
  </app-organization-card-list>
  <app-bootstrap-pagination
    [page]="pageIndex"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    [showChangePageSizeCtrl]="true"
    [pageSizeConfig]="pageSizeConfig.SixItemsFirstPage"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
  ></app-bootstrap-pagination>
</ng-template>
