<div class="cookies-we-are-sorry">
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="activeModal.close(false)"
  >
    <span *ngIf="false" aria-hidden="true">&times;</span>
  </button>
  <div class="cookies-we-are-sorry__header"></div>
  <div class="cookies-we-are-sorry__body">
    <div class="cookies-we-are-sorry__content">
      <img src="/wp-content/themes/jip/assets/images/wearesorry.png" />
      <section class="cookies-we-are-sorry__messages">
        <h1 translate>WE_ARE_VERY_SORRY</h1>
        <h3 translate>WE_ARE_VERY_SORRY_DETAIL</h3>
      </section>
    </div>
  </div>
  <div class="cookies-we-are-sorry__controls">
    <button
      translate
      class="cookies-acceptance__button-accept btn btn-primary"
      (click)="closeWeAreSorry()"
    >
      Close
    </button>
  </div>
</div>
