import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { FormComponentInterface } from '@core/form/form-error.service';
import { environment } from '@env/environment';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { TextValidator } from '@shared/utils/form-fields-validation';
import { FormErrorService } from '@src/app/core/form/form-error.service';
import { SessionService } from '@src/app/core/session.service';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit, FormComponentInterface {
  @Output() sendConfirmationEmail: EventEmitter<any> = new EventEmitter();

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;

  confirmationForm: UntypedFormGroup;
  formErrorKey: string;
  errorMessage: { [key: string]: string };
  isSubmitting?: boolean;
  isSubmitted?: boolean;

  public isSentEmail = false;
  public sentEmailAddress: string;
  public resetPasswordToken: string;
  sendEmailFalseMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    public authenService: AuthenService,
    private formErrorService: FormErrorService,
    private sessionService: SessionService,
    private cookieService: CookieService
  ) {
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.portalUrl = this.cookieService.get(SessionKeyEnum.TENANT_DOMAIN);
  }

  getForm(): UntypedFormGroup {
    return this.confirmationForm;
  }

  ngOnInit(): void {
    if (this.sessionService.isLogin) {
      this.goTo(environment.jipUrl.profile);
    }

    this.initConfirmationForm();
  }

  goTo(url: string): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }

  initConfirmationForm(): void {
    this.confirmationForm = this.fb.group({
      usernameOrEmail: [
        '',
        {
          validators: [
            Validators.required,
            TextValidator.notEmptyOrWhitespace,
            TextValidator.cannotContainWhitespace,
          ],
        },
      ],
      recaptcha: ['', Validators.required],
    });
    this.formErrorService.register(this);
  }

  get usernameOrEmail(): any {
    return this.confirmationForm.get('usernameOrEmail');
  }

  onSubmit(): void {
    this.isSubmitting = true;
    const usernameOrEmail = this.usernameOrEmail.value;
    this.sendResetPassword(usernameOrEmail);
  }

  sendResetPassword(usernameOrEmail: string): void {
    const sentToEmail = usernameOrEmail?.trim();
    this.authenService.requestResetPassword(sentToEmail).subscribe({
      next: (data: any) => {
        const error = data as ApiResponse;
        if (error && error.status === 400) {
          this.formErrorService.handleError(data);
          this.isSubmitted = false;
        } else {
          this.isSentEmail = data.isEmailDelivered;
          this.sendEmailFalseMessage = !data.isEmailDelivered
            ? data.message
            : '';
          this.sentEmailAddress = data.email;
          this.resetPasswordToken = data.token;
          this.sendConfirmationEmail.emit(data);
          this.isSubmitted = true;
        }
        this.isSubmitting = false;
      },
      error: (error) => {
        this.handleError(error);
      },
    });
  }

  resendResetPassword(usernameOrEmail: string, token: string): void {
    this.isSubmitting = true;
    this.isSubmitted = false;

    const sentToEmail = usernameOrEmail?.trim();
    this.authenService
      .resendRequestResetPassword(sentToEmail, token)
      .subscribe({
        next: (data: any) => {
          const error = data as ApiResponse;

          if (error && error.status === 400) {
            this.formErrorService.handleError(data);
            this.isSubmitted = false;
          } else {
            this.isSentEmail = data.isEmailDelivered;
            this.sentEmailAddress = sentToEmail;
            this.resetPasswordToken = data.token;
            this.sendConfirmationEmail.emit(data);
            this.isSubmitted = true;
          }

          this.isSubmitting = false;
        },
        error: (error) => {
          this.handleError(error);
        },
      });
  }

  private handleError(error: any): void {
    this.isSubmitted = false;
    this.isSubmitting = false;
    this.formErrorService.handleError(error);
  }
}
