import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-layout',
  templateUrl: './card-layout.component.html',
})
export class CardLayoutComponent {
  @Input() isSquareImage: boolean;
  @Input() cardClass: string;

  shouldExpandContent = false;

  expandContent(): void {
    this.shouldExpandContent = !this.shouldExpandContent;
  }
}
