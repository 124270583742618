import { Pipe, PipeTransform } from '@angular/core';
import { News } from '@shared/interfaces/news.interface';
import { EntityName } from '@shared/enums/entity-name.enum';

type NewsContent = News['content'];

interface EntityProperties {
  headline: ((content: NewsContent) => string) | keyof NewsContent | '';
  subHeadline: ((content: NewsContent) => string) | keyof NewsContent | '';
}

@Pipe({
  name: 'entityDisplayText',
})
export class EntityDisplayTextPipe implements PipeTransform {
  private entityMap: Record<string, EntityProperties> = {
    [EntityName.Event]: {
      headline: 'title',
      subHeadline: 'description',
    },
    [EntityName.Venture]: {
      headline: 'title',
      subHeadline: 'description',
    },
    [EntityName.Organization]: {
      headline: 'orgName',
      subHeadline: 'headLine',
    },
    [EntityName.VentureAwarded]: {
      headline: 'title',
      subHeadline: 'description',
    },
    [EntityName.VentureSubmittedToChallenge]: {
      headline: 'title',
      subHeadline: 'description',
    },
    [EntityName.VentureSubmitOrganisation]: {
      headline: 'title',
      subHeadline: 'description',
    },
    [EntityName.Person]: {
      headline: (content: News['content']) =>
        [content.firstName, content.middleName, content.lastName].join(' '),
      subHeadline: '',
    },
  };

  transform(
    value: NewsContent,
    entityName: EntityName,
    display: 'headline' | 'subHeadline'
  ): string {
    const entityProperty = this.entityMap[entityName];
    if (!entityProperty) {
      throw new Error(`Unknown entityName '${entityName}'`);
    }

    const displayValue = entityProperty[display];

    return typeof displayValue === 'function'
      ? displayValue(value)
      : value[displayValue];
  }
}
