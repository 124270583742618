import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { MessageUIService } from '@shared/services/message-ui.service';
import { ApplicationService } from '@src/app/core/services/application.service';
import { EntityName, MessageAction } from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import { environment } from '@src/environments/environment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-get-in-touch-message-card',
  templateUrl: './get-in-touch-message-card.component.html',
})
export class GetInTouchMessageCardComponent implements OnChanges, OnDestroy {
  @Input() currentPersonId?: number;
  @Input() message: NewsMessageInterface;
  @Input() canReply = true;

  MessageAction = MessageAction;
  peopleUrl = environment.jipUrl.people;
  peopleDetailUrl = '';

  constructor(
    private messageUIservice: MessageUIService,
    private readonly applicationService: ApplicationService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message && changes.message.currentValue) {
      this.handlePeopleDetailUrl();
    }
  }

  openMessageBack(): void {
    this.messageUIservice.openMessage(this.message.id, false, this.message.content);
  }

  get messageText(): any {
    if (this.message.action === MessageAction.PendingRequest) {
      let result = this.message?.message;
      // Data PendingRequest come from textarea -> need handle line break for quill view
      result = result?.split('\n').join('<br>');
      return result;
    }
    else {
      return this.message?.latestMessage?.content || this.message?.message;
    }
  }

  getFullName(people: PeopleInterface): string {
    return `${people?.firstName} ${people?.lastName}`;
  }

  private handlePeopleDetailUrl(): void {
    if (this.message.action === MessageAction.PendingRequest && this.message.to) {
      const peopleId = this.message.to.id;
      const url = `${this.peopleUrl}/${peopleId}`;
      this.peopleDetailUrl = url;
      if (this.currentPersonId !== peopleId && this.message.to.isPrivate) {
        this.applicationService.encryptInfo(`${EntityName.Person}-${peopleId}`)
          .pipe(
            untilDestroyed(this),
            catchError(() => of(''))
          )
          .subscribe(
            (encryptedCode: string) => {
              this.peopleDetailUrl = encryptedCode ? `${url}?${UrlParam.Code}=${encryptedCode}` : url;
            });
      }
    }
  }

  ngOnDestroy(): void { /**/ }
}
