import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-key-offer-modal',
  templateUrl: './key-offer-modal.component.html',
  styles: [],
})
export class KeyOfferModalComponent implements OnChanges {
  @ViewChild('modalRef') modalRef: ElementRef;

  @Input() modalTitle = '';
  @Input() entityId: number;
  @Input() keyOfferings: { description: string }[];

  @Output() dismissModal = new EventEmitter<any>();

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entityId) {
      this.openModal();
    }
  }

  private openModal(): void {
    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        scrollable: true,
        windowClass: 'key-offer__modal people__modal',
      })
      .result.then(
        (result) => {
          this.entityId = null;
          this.keyOfferings = [];
        },
        (reason) => {
          this.entityId = null;
          this.keyOfferings = [];

          this.dismissModal.emit();
        }
      );
  }
}
