import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TagCanvasOptions } from 'ng-tagcanvas';

@Component({
  selector: 'app-tagcloud',
  templateUrl: './tagcloud.component.html',
  styleUrls: ['./tagcloud.component.scss'],
})
export class TagcloudComponent implements OnChanges {
  options: TagCanvasOptions = {
    initial: [0.1, -0.1],
    weight: true,
    textHeight: 10,
    clickToFront: 1500,
    outlineColour: 'transparent',
    textColour: '#000',
  };

  @Input() textHeight: number;

  @Input() data: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.options.textHeight = this.textHeight;

    if (this.data) {
      this.data.sort((a, b) => {
        return a.weight - b.weight;
      });
      const length = this.data.length;
      const leftArray = this.data.slice(0, length / 2).reverse();
      const rightArray = this.data.slice(length / 2, length).reverse();
      this.data = leftArray.concat(rightArray);
    } else {
      this.data = [];
    }
  }
}
