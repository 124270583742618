import { Component, EventEmitter, Input, Output } from '@angular/core';
import { copyToClipboard } from '@shared/functions/text-copy';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styles: [],
})
export class CopyTextComponent {
  @Input() isLoginRequired = false;
  @Input() isUrlCopied = false;
  @Input() textToCopy = '';
  @Input() copyWithCondition = false;

  @Output() copyWithConditionClicked = new EventEmitter();
  copyUrl(): void {
    if (this.copyWithCondition) {
      this.copyWithConditionClicked.emit();
    }
    else {
      copyToClipboard(this.textToCopy, () => {
        this.isUrlCopied = true;

        setTimeout(() => {
          this.isUrlCopied = false;
        }, 700);
      });
    }
  }
}
