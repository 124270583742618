import { Component } from '@angular/core';
import { PeopleCardListComponent } from '@src/app/shared/components/people-card-list/people-card-list.component';

@Component({
  selector: 'app-people-card-list-org-admin',
  templateUrl: './people-card-list-org-admin.component.html',
})
export class PeopleCardListOrgAdminComponent extends PeopleCardListComponent {

}
