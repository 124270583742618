import {
  Component,
  EventEmitter,
  Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';

@Component({
  selector: 'app-venture-phase-detail',
  templateUrl: './venture-phase-detail.component.html',
})
export class VenturePhaseDetailComponent implements OnChanges {
  @Input() currentIndex = 2;
  @Input() allPhases: Array<CustomAtrributeValue>;
  @Input() editable = false;

  @Output() selectItem = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentIndex || changes.allPhases) {
      if (this.allPhases) {
        this.allPhases.forEach((phase) => {
          if (
            phase.codeId === this.currentIndex ||
            phase.value === this.currentIndex ||
            phase.order === this.currentIndex
          ) {
            this.currentIndex = phase.order;
          }
        });
      }
    }
  }

  select(item: CustomAtrributeValue, index): void {
    if (this.editable) {
      this.currentIndex = index;
      this.selectItem.next(item);
    }
  }
}
