import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { TimestampsService } from '@src/app/core/http/timestamps.service';
import { ToastService } from '@src/app/core/toast.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import {
  AttributeDescription,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import {
  TimestampCreatePayload,
  TimestampIcon,
} from '@src/app/shared/interfaces/timestamp.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-timestamp-dialog',
  templateUrl: './create-timestamp-dialog.component.html',
  styles: [],
})
export class CreateTimestampDialogComponent implements OnInit {
  @Input() httpService: BaseHttpService<any>;
  @Input() entityId: number;

  isSubmitting = false;
  isLoading = false;

  currentTime = new Date();
  description = '';
  currentTimestampType = 0;
  timestampTypes: CustomAtrributeValue[] = [];
  DateFormat = DateFormat;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private timestampsService: TimestampsService
  ) {}

  ngOnInit(): void {
    this.getTimestampsAttributes();
  }

  getTimestampsAttributes(): void {
    this.isLoading = true;
    this.timestampsService
      .getAttributes()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((lstAttrDes: AttributeDescription[]) => {
        const timestampTypeAttrDes = lstAttrDes.find(
          (x) => x.propertyName === 'TimestampType'
        );
        if (timestampTypeAttrDes) {
          this.timestampTypes = timestampTypeAttrDes.choice?.selections || [];
          this.timestampTypes.forEach((timestampType: CustomAtrributeValue) => {
            timestampType.img = new TimestampIcon(timestampType.codeId)?.icon;
          });
        }
      });
  }

  onSubmit(): void {
    this.isSubmitting = true;
    if (this.entityId && !isNaN(this.entityId)) {
      const payload: TimestampCreatePayload = {
        timestampType: this.timestampTypes[this.currentTimestampType].codeId,
        description: this.description,
      };
      this.httpService
        .createTimestamp(this.entityId, payload)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
          })
        )
        .subscribe({
          next: (entityId: number) => {
            if (entityId && !isNaN(entityId)) {
              this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
              this.activeModal.close(true);
            }
          },
          error: (err: HttpErrorResponse) => {
            if (err.error?.title) {
              this.toastService.showError(err.error?.title);
            }
          },
        });
    }
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
