import { NavInterface } from '@shared/interfaces/nav.interface';
import {
  Directive,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Input,
} from '@angular/core';

@Directive({
  selector: 'a[appActiveRoute]',
})
export class ActiveRouteDirective implements AfterViewInit {
  @Input() subMenus: NavInterface[];

  activeClass = 'route-active';
  constructor(
    private el: ElementRef<HTMLLinkElement>,
    private render2: Renderer2
  ) {}

  ngAfterViewInit(): void {
    const nativeElement = this.el.nativeElement;
    const itemRoute = nativeElement.href;

    const currentUri = location.origin + location.pathname;

    if (this.samepath(currentUri, itemRoute) || this.hasActiveSubmenu()) {
      this.render2.addClass(nativeElement, this.activeClass);
    } else {
      this.render2.removeClass(nativeElement, this.activeClass);
    }
  }

  private hasActiveSubmenu(): boolean {
    if (this.subMenus) {
      const pathname = location.pathname;
      for (const item of this.subMenus) {
        if (this.samepath(pathname, item.route)) {
          return true;
        }
      }
    }
    return false;
  }

  private samepath(currentUri, itemRoute): boolean {
    return (
      currentUri.includes(itemRoute) && currentUri.length - itemRoute.length < 2
    );
  }
}
