<div class="email-input card p-3 venture__rapper">
  <div class="d-flex flex-wrap">
    <form [formGroup]="form" class="mr-md-3 w-100">
      <div class="form-group email-input__form">
        <label class="w-100" for="username" translate
          >UI.EventRegistration.EmailInputLabel</label
        >
        <div class="d-flex flex-wrap flex-sm-nowrap">
          <div class="email-input-box flex-grow-1">
            <input
              autocomplete="off"
              class="form-control"
              formControlName="email"
              id="username"
              required
              type="email"
            />
            <div *ngIf="emailControl?.value" class="email-input-clear">
              <em
                (click)="resetMailValue()"
                class="reset-email far fa-times mr-3"
                focusable="false"
              >
              </em>
            </div>
          </div>
          <button
            (click)="validateEmail()"
            class="mt-2 mt-sm-0 ml-sm-4 btn btn-primary text-white font-weight-bold white-space--no-wrap font-size-sm"
            translate
            [disabled]="form.invalid || validated"
          >
            <ng-container *ngIf="validating">
              <i class="fas fa-spinner fa-spin"></i>
            </ng-container>
            UI.Button.RegisterWithEmail
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="validated" class="font-weight-normal font-size-sm mt-md-3">
      <ng-container *ngIf="isEmailExisted; else notExist">
        <span
          >{{ 'UI.EventRegistration.EmailExisted.Prefix' | translate }}

          <a [href]="loginUrl" translate
            >UI.EventRegistration.EmailExisted.Middle</a
          >
          {{ 'UI.EventRegistration.EmailExisted.Suffix' | translate }}
        </span>
      </ng-container>

      <ng-template #notExist>
        <span translate
          >{{ 'UI.EventRegistration.EmailNotExist.Prefix' | translate }}

          <strong> {{ emailControl?.value }}</strong
          >.

          {{ 'UI.EventRegistration.EmailNotExist.Suffix' | translate }}
        </span>
        <div class="mt-4">
          <p class="mb-1" translate>UI.RegisterEvent.ClickLinkToResendEmail</p>
          <span
            (click)="resendEmail()"
            class="resend-email-btn btn-link-primary"
            translate
          >
            UI.RegisterEvent.ResendEmail
          </span>
          <ng-container *ngIf="resending">
            <i class="fas fa-spinner fa-spin ml-2"></i>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <ng-content></ng-content>
</div>
