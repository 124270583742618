import { HttpHeaders } from '@angular/common/http';
export class SaveFileUtils {
  /**
   * https://stackoverflow.com/a/54753174
   *
   */
  public static saveFile(response): void {
    // Create a URL for the blob
    const url = URL.createObjectURL(response.body);

    // Create an anchor element to "point" to it
    const anchor = document.createElement('a');
    anchor.href = url;

    // Get the suggested filename for the file from the response headers
    anchor.download =
      this.getFilenameFromHeaders(response.headers) || 'summary-report';

    // Simulate a click on our anchor element
    anchor.click();

    // Discard the object data
    URL.revokeObjectURL(url);
  }

  /**
   * https://stackoverflow.com/a/54753174
   *
   */
  public static getFilenameFromHeaders(headers: HttpHeaders): string {
    // The content-disposition header should include a suggested filename for the file
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
      return null;
    }

    const regex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

    const items = regex.exec(contentDisposition);
    if (items && items[1]) {
      return items[1];
    }
  }
}
