<div class="date-filter row" *ngIf="dateFilter">
  <div class="col-md-4 col-12">
    <app-boostrap-dropdown
      class="dropdown--full-width"
      [currentIndex]="selectedModeIndex"
      [items]="dateFilterOptions"
      (selectItem)="changeDateFilterMode($event, true)"
    >
    </app-boostrap-dropdown>
  </div>

  <ng-container *ngIf="dateFilter.selectedMode !== DateFilterType.NoFilter">
    <ng-container *ngIf="dateFilter.selectedMode !== DateFilterType.Before">
      <div
        class="col-md-4 col-12 d-flex flex-md-row flex-column align-items-md-center align-items-start"
        [class.position-relative]="labelPosition === 'top'"
      >
        <div
          *ngIf="dateFilter.selectedMode === DateFilterType.Period"
          class="mr-1"
          [class.label-top]="labelPosition === 'top'"
        >
          {{ 'UI.Common.From' | translate }}:
        </div>
        <app-boostrap-datepicker
          class="w-100 mt-2 mt-md-0"
          [time]="fromTime"
          [currentTime]="dateFilter?.from"
          [editable]="true"
          [hideTimeOfDate]="true"
          [shouldUseDefaultDate]="true"
          [maxTime]="maxFromDate"
          (dateTimeChange)="setFromDate($event, true)"
        >
        </app-boostrap-datepicker>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        dateFilter.selectedMode === DateFilterType.Period ||
        dateFilter.selectedMode === DateFilterType.Before
      "
    >
      <div
        class="col-md-4 col-12 d-flex flex-md-row flex-column align-items-md-center align-items-start"
        [class.position-relative]="labelPosition === 'top'"
      >
        <div
          *ngIf="dateFilter.selectedMode === DateFilterType.Period"
          class="mr-1"
          [class.label-top]="labelPosition === 'top'"
        >
          {{ 'UI.Common.To' | translate }}:
        </div>
        <app-boostrap-datepicker
          class="w-100 mt-2 mt-md-0"
          [time]="toTime"
          [currentTime]="dateFilter?.to"
          [editable]="true"
          [hideTimeOfDate]="true"
          [shouldUseDefaultDate]="true"
          [minTime]="minToDate"
          (dateTimeChange)="setToDate($event, true)"
        >
        </app-boostrap-datepicker>
      </div>
    </ng-container>
  </ng-container>
</div>
