<div
  *ngIf="
    isVisible && sessionService.isOnListPage && !sessionService.isOnHomePage
  "
  ngbDropdown
  [open]="openDropdown"
  class="channel-filter__search"
>
  <form
    action="#"
    class="channel-filter__search-form overflow-hidden truncate-line-1"
  >
    <input
      #channelInput
      *ngIf="!activeChannel; else activeChannelTmpl"
      ngbDropdownAnchor
      type="text"
      name="query"
      class="form-control channel-filter__search-input pl-0"
      [placeholder]="'UI.TopicFilter.FilterByATopic' | translate"
      [(ngModel)]="searchKeyword"
      (ngModelChange)="onKeywordChange($event)"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur()"
    />
    <ng-template #activeChannelTmpl>
      <div
        ngbDropdownToggle
        class="channel-filter__active-channel d-flex align-items-center"
      >
        <p class="m-0 mr-2 font-weight-bold">
          {{ 'UI.TopicFilter.AcitveTopic' | translate }}:
        </p>
        <div class="overflow-hidden" style="white-space: normal">
          <div class="d-flex align-items-center gap-8 w-100">
            <div
              *ngIf="activeChannel.image"
              class="upload-file__container partner-org-select__image-container flex-shrink-0"
            >
              <img
                [src]="activeChannel.image.url"
                [alt]="activeChannel.image.alt"
              />
            </div>
            <div class="overflow-hidden">
              <h6 class="option-name m-0 truncate-line-1">
                {{ activeChannel.displayName }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </form>

  <div class="search-control">
    <em
      *ngIf="activeChannel"
      appDebounceClick
      (debounceClick)="resetSearch()"
      class="reset-search far fa-times mr-3"
      focusable="false"
    >
    </em>

    <i
      *ngIf="!activeChannel"
      class="fa fa-search pointer-cursor"
    ></i>
  </div>

  <div
    ngbDropdownMenu
    class="channel-filter__search-dropdown w-100"
    (mouseenter)="onMouseenter()"
    (mouseleave)="onMouseLeave()"
  >
    <div class="channel-filter__search-dropdown--entity">
      <app-search-filter
        [data]="searchEntities"
        (filterChanged)="onFilterChanged($event)"
      ></app-search-filter>
    </div>
    <div class="channel-search-list">
      <div
        class="channel-search-list__group my-2"
        *ngFor="let entity of entitySearchKeys"
      >
        <app-channel-search-result
          [searchKeyword]="searchKeyword"
          [activeFilter]="activeFilter"
          [entityName]="entity"
          [searchEntities]="searchEntities"
          (selectedChannel)="onSelectChannel($event)"
        >
        </app-channel-search-result>
      </div>
    </div>
    <app-search-channel-history
      [activeFilter]="activeFilter"
      (selectedChannel)="onSelectChannel($event)"
    ></app-search-channel-history>
  </div>
</div>
