export interface SidebarItem {
  name: string;
  icon: string;
  childs?: SidebarItem[];
  disabled?: boolean;
  section?: AdminSettingsSection;
}

export enum AdminSettingsSection {
  Dashboard,
  Organizations,
  TenantConfiguration,
  Languages,
  Entities,
}

export const ADMIN_SIDEBARS: SidebarItem[] = [
  {
    icon: 'far fa-folder',
    name: 'Dashboard',
    section: AdminSettingsSection.Dashboard,
  },
  {
    icon: 'far fa-folder',
    name: 'Organizations',
    section: AdminSettingsSection.Organizations,
  },
  {
    icon: 'far fa-folder',
    name: 'UI.AdminSettings.TenantConfiguration',
    section: AdminSettingsSection.TenantConfiguration,
  },
  {
    icon: 'far fa-folder',
    name: 'UI.Common.Languages',
    disabled: true,
    section: AdminSettingsSection.Languages,
  },
  {
    icon: 'far fa-folder',
    name: 'Entities',
    disabled: true,
    section: AdminSettingsSection.Entities,
    childs: [
      {
        icon: 'far fa-list-alt',
        name: 'Challenges',
      },
      {
        icon: 'far fa-list-alt',
        name: 'Ventures',
      },
      {
        icon: 'far fa-list-alt',
        name: 'Network',
      },
      {
        icon: 'far fa-list-alt',
        name: 'Events',
      },
      {
        icon: 'far fa-list-alt',
        name: 'Organizations',
      },
    ],
  },
];
