import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CentralConfigService } from '@core/services/central-config.service';
import { Observable } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private readonly centralConfig: CentralConfigService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.centralConfig.apiUrl$.pipe(
      filter(Boolean),
      first(),
      mergeMap((url: string) => next.handle(this.appendPrefix(request, url)))
    );
  }

  private appendPrefix(
    request: HttpRequest<unknown>,
    prefix: string
  ): HttpRequest<unknown> {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({
        url: `${prefix}${request.url}`,
      });
    }

    return request;
  }
}
