import { SessionService } from '@core/session.service';
import { FormUtils } from '@shared/utils/form-utils';
import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { NotificationService } from '@core/notification.service';

@Directive({
  selector: '[appUnsavedFormCheck]',
})
export class UnsavedFormCheckDirective {
  @Input() destinationHref;
  @Output() clickAfterChecking = new EventEmitter();
  @Output() unsavedForm = new EventEmitter<boolean>();

  constructor(
    private unsavedFormCheckService: UnsavedFormCheckService,
    private notificationService: NotificationService,
    private sessionService: SessionService
  ) {}

  @HostListener('click', ['$event'])
  clickEvent(event): void {
    const hasUnsavedContent = this.unsavedFormCheckService.hasUnsavedContent();

    if (!this.unsavedFormCheckService.hasUnsavedContent()) {
      this.clickAfterChecking.emit(event);
      return;
    }

    this.unsavedForm.emit(hasUnsavedContent)
    const href = this.destinationHref || event.currentTarget.href;

    const isCreating =
      this.destinationHref && window.location.pathname.includes('/create');

    const title = isCreating
      ? 'SITCH_LANGUAGE_Warning-Title'
      : 'UNSAVED_FORM_Warning-Title';

    const message = isCreating
      ? 'SITCH_LANGUAGE_Warning-Content'
      : 'UNSAVED_FORM_Warning-Content';

    this.notificationService
      .confirm(title, message, true)
      .result.then((confirmed) => {
        if (confirmed) {
          this.unsavedFormCheckService.clearAll();
          this.clickAfterChecking.emit(event);
          if (href) {
            this.goTo(href);
          }
        }
      });
    event.stopPropagation();
    event.preventDefault();
  }

  goTo(url): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }
}
