<ng-container *ngIf="!isFirstLoad; else loadingRef">
  <div class="shared-venture-filter">
    <div class="row">
      <div class="col-12">
        <app-search-input-wrapper
          [searchText]="sharedOrgfilter.keyword"
          [editMode]="true"
          (resetSearchClick)="onResetSearchText()"
        >
          <form (submit)="onFilter()" action="#">
            <span
              class="pointer-cursor search__icon-wrapper"
              (click)="onFilter()"
            >
              <i class="search__icon fas fa-search"></i>
            </span>

            <input
              type="text"
              class="form-control mb-3 pl-5"
              placeholder="{{ 'Search' | translate }} {{
                'Organizations' | translate
              }}"
              [(ngModel)]="sharedOrgfilter.keyword"
              type="text"
              name="searchOrgText"
              id="searchOrgText"
            />
          </form>
        </app-search-input-wrapper>
      </div>
    </div>
  </div>

  <app-metadata-table-organization
    [entityDatas]="orgs"
    [isLoading]="isLoading"
    [editable]="true"
    (appendSortParams)="onSort($event)"
    (reloadData)="onFilter()"
  ></app-metadata-table-organization>
  <app-bootstrap-pagination
    [page]="sharedOrgfilter.pageIndex"
    [pageSize]="sharedOrgfilter.pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
  ></app-bootstrap-pagination>
</ng-container>
<ng-template #loadingRef>
  <div class="text-center">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-template>
