<ng-container *ngIf="isLoginRequired; else noRequiredLogin">
  <a
    appRequireLogin
    appDebounceClick
    class="badge badge-primary text-copy__btn pointer-cursor"
    [ngbTooltip]="'UI.Common.Copy-Hint' | translate"
    (debounceClick)="copyUrl(); $event.stopPropagation()"
  >
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>
</ng-container>
<ng-template #noRequiredLogin>
  <a
    container="body"
    class="badge badge-primary text-copy__btn pointer-cursor"
    [ngbTooltip]="'UI.Common.Copy-Hint' | translate"
    (click)="copyUrl(); $event.stopPropagation()"
  >
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>
</ng-template>

<ng-template #content>
  <i class="fas fa-link fa-xs text-copy__icon"></i>
  <small
    class="text-copy__hint-text text-muted ml-1"
    [ngClass]="isUrlCopied ? 'visible' : 'invisible'"
  >
    {{ 'UI.Common.Copied' | translate }}
  </small>
</ng-template>
