import { Component, Input, OnInit } from '@angular/core';
import { BaseDetailComponent } from '@src/app/components/base/base-detail/base-detail.component';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
})
export class ChallengeComponent extends BaseDetailComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() ui_template: TemplateName = TemplateName.Default;
}
