import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { FactoryService } from '@src/app/core/services/factory.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { EntityName } from '../../enums/entity-name.enum';
import { Entity } from '../../models/entity.model';

@Component({
  selector: 'app-entity-view-modal',
  templateUrl: './entity-view-modal.component.html',
})
export class EntityViewModalComponent implements OnInit, OnDestroy {
  @Input() entityName: EntityName;
  entityItems: Entity[] = [];
  @Input() modalTitle = '';
  @Input() entityIds: number[] = [];

  EntityName = EntityName;
  httpService: BaseHttpService<Entity>;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    public factoryService: FactoryService,
    private messageHttpService: SendMessageHttpService
  ) {}

  ngOnInit(): void {
    this.httpService = this.factoryService.createServiceByEntityName(
      this.entityName
    );

    this.isLoading = true;
    this.messageHttpService
      .getRecipientEntity(this.entityIds, this.entityName)
      .pipe(untilDestroyed(this))
      .subscribe((res: Entity[]) => {
        this.isLoading = false;
        this.entityItems = res;
      });
  }

  ngOnDestroy(): void {
    /** */
  }
}
