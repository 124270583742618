import { AttributeDescription } from '../interfaces/attribute-description.interface';
import { Pipe, PipeTransform } from '@angular/core';
import { getMetaAttribute } from './field-option.pipe';

@Pipe({
  name: 'fieldLabel',
})
export class FieldLabelPipe implements PipeTransform {
  transform(metaInfoList: AttributeDescription[][], fieldName: string): string {
    const attribute = getMetaAttribute(metaInfoList, fieldName);

    if (attribute) {
      return attribute.displayName;
    }
    return '';
  }
}
