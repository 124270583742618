<input
  class="form-control"
  [ngModel]="value?.url"
  (ngModelChange)="onLinkChange($event)"
  [placeholder]="placeholder || ''"
  [pattern]="regularExpression"
  #link="ngModel"
/>
<div
  class="alert alert-danger"
  *ngIf="(link.dirty || link.touched) && link.hasError('pattern')"
>
  <span class="pr-1">{{ 'UI.Common.Url-Hint' | translate }}</span>
</div>
