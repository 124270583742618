<div class="modal-header">
  <h4 class="modal-title">{{ personTitle | translate }}</h4>

  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="person-remove-card">
    <div class="sender-profile d-flex justify-content-center">
      <div class="message-to-profile">
        <img [src]="personImage" appDefaultAvatar appDynamicImgUrl />
      </div>
    </div>

    <div class="message-content-editor">
      <a appUnsavedFormCheck href="{{ peopleURL }}/{{ personId }}">{{
        personName
      }}</a>
    </div>
  </div>

  <div>
    <div class="person-remove-confirm">
      <label>
        {{ confirmText?.prefix | translate }}
        <span style="font-weight: bold">{{ personName }}</span>
        {{ confirmText?.suffix | translate }}
        {{ fromEntityName | translate | lowercase }}?
      </label>
    </div>

    <div *ngIf="fromEntity" class="person-remove-from-entity">
      <app-entity-card-container
        [entity]="fromEntity"
        [entityName]="fromEntityName"
      >
      </app-entity-card-container>
    </div>
  </div>
</div>

<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
    translate
  >
    Cancel
  </button>

  <button
    appRequireLogin
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="remove()"
    translate
  >
    Remove
  </button>
</div>
