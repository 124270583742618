import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  EntityName,
  MessageAction,
} from '@src/app/shared/enums/entity-name.enum';
import { CustomMessageInterface } from '@src/app/shared/interfaces/news-message.interface';
import { AttributePropertyName } from '@shared/enums/attribute-display-name.enum';
import { Entity } from '@shared/models/entity.model';
import { BaseHttpService } from '@core/http/base-http.service';

@Component({
  selector: 'app-share-entity-to-people-dialog',
  templateUrl: './share-entity-to-people-dialog.component.html',
  styles: [],
})
export class ShareEntityToPeopleDialogComponent implements OnInit {
  @Input() entityName: string;

  @Input() entity: Entity;

  @Input() httpService: BaseHttpService<any>;

  @Input() placeholder: string;

  @Input() choice = AttributePropertyName.Recipient;

  customMessage: CustomMessageInterface = null;
  title: string;
  defaultContent = '';

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.entity) {
      this.customMessage = {
        action: MessageAction[MessageAction.Shared],
        entityId: this.entity.id,
        entityName: this.entityName,
      };

      const entityNameTrans = this.translateService.instant(this.entityName);

      this.title = this.translateService.instant('UI.Interaction.ShareEntity', {
        entity: entityNameTrans,
      });

      if (this.placeholder) {
        return;
      }

      switch (this.entityName) {
        case EntityName.Venture:
          this.placeholder = 'UI.VentureEdit.ShareVenturePlaceholder';
          this.defaultContent = this.translateService.instant(
            'UI.VentureEdit.ShareVenturePlaceholder'
          );
          break;

        case EntityName.Event:
          this.placeholder = 'UI.EventEdit.ShareEventPlaceholder';
          this.defaultContent = this.translateService.instant(
            'UI.EventEdit.ShareEventPlaceholder'
          );
          break;

        default:
          break;
      }
    }
  }
}
