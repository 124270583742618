import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { SessionService } from '@src/app/core/session.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { GlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-page-cover',
  templateUrl: './page-cover.component.html',
})
export class PageCoverComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() headline: string;
  @Input() lead: string;

  displayImageUrl = '';
  displayHeadline = '';
  displaySubHeadline = '';

  constructor(
    private globalFilterStoredService: GlobalFilterStoredService,
    private orgService: OrganizationHttpService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.getDataFromWP();

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe(async (apiReady) => {
        if (apiReady) {
          await this.handleFirstLoad();
        }
      });

    this.listenGlobalFilterEvent();
  }

  ngOnDestroy(): void {
    /**/
  }

  private async handleFirstLoad(): Promise<void> {
    const globalFilter: GlobalFilterCriteria =
      await this.globalFilterStoredService.getFilterCriteria();
    if (globalFilter && globalFilter.organizationIds) {
      const currentOrgId = globalFilter.organizationIds[0];
      this.orgService
        .read(currentOrgId)
        .pipe(untilDestroyed(this))
        .subscribe((org: OrganizationInterface) => {
          if (org !== null) {
            this.updateInnovationSpaceData(org);
          }
        });
    }
  }

  private updateInnovationSpaceData(org: OrganizationInterface): void {
    if (org) {
      this.displayImageUrl = org.image?.url || this.url;
      this.displayHeadline = org.headline || '';
      this.displaySubHeadline = org.subHeadline || '';
    }
  }

  private getDataFromWP(): void {
    this.displayImageUrl = this.url;
    this.displayHeadline = this.headline;
    this.displaySubHeadline = this.lead;
  }

  private listenGlobalFilterEvent(): void {
    this.globalFilterStoredService
      .getCurrentFilterToken()
      .pipe(
        switchMap((orgId: number) => {
          return this.orgService.read(orgId).pipe(
            catchError(() => {
              return of(null);
            })
          );
        })
      )
      .subscribe((org: OrganizationInterface) => {
        if (org !== null) {
          this.updateInnovationSpaceData(org);
        } else {
          this.getDataFromWP();
        }
      });
  }
}
