<!-- //NOSONAR -->
<li class="list-inline-item venture__actions-item">
  <ng-container *ngIf="isLoadingLikingsCount; else likesBtn">
    <div class="text-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #likesBtn>
    <a
      class="interaction-btn interaction-btn__cheer-likes d-flex align-items-center"
      appRequireLogin
      appDebounceClick
      (debounceClick)="toggleLike()"
    >
      <app-internal-icon
        [height]="20"
        [name]="'thumbs-up'"
        [className]="
          entity?.isLiked ? 'liked icon-like' : 'filter-gray icon-like'
        "
      ></app-internal-icon>

      <span
        class="venture__actions-label text-hide-small font-weight-500 text-gray-600"
      >
        <span [class.route-active]="entity?.isLiked" translate>{{
          'Likes'
        }}</span>
      </span>
    </a>

    <a
      *ngIf="!hideLikeNumber"
      class="interaction-btn font-weight-500 text-gray-600"
      appDebounceClick
      (debounceClick)="showLikingsData()"
    >
      <span [innerHTML]="entity?.likesCount | parentheses"></span>
    </a>
  </ng-template>
</li>
