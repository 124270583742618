<ng-container *ngIf="formInput && orderedAttributeDescriptions.length > 0">
  <div class="row">
    <ng-container *ngFor="let attrDes of orderedAttributeDescriptions">
      <div
        *ngIf="!attrDes.isCustomDisplayAttribute"
        class="col-12 mb-3"
        [ngClass]="{
          'col-md-6': attrDes.attributeType === AttributeType.DateTime
        }"
      >
        <app-form-field-custom
          [formInput]="formInput"
          [currentValue]="formInput?.controls[attrDes.propertyName]?.value"
          [editable]="editable"
          [showLabel]="
            attrDes.attributeType === AttributeType.Boolean ? false : true
          "
          [attributeDescription]="attrDes"
          [options]="getFormOptions(attrDes)"
        ></app-form-field-custom>

        <div
          class="alert alert-danger"
          *ngIf="
            formInput.controls[attrDes.propertyName]?.hasError(
              attrDes.propertyName + 'Error'
            )
          "
        >
          {{
            'UI.CustomAttribute.' + attrDes.propertyName + 'Error' | translate
          }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
