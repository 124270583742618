<div
  *ngIf="false"
  class="loading-overlay modal-backdrop show d-flex align-items-center justify-content-center"
>
  <app-internal-icon
    [height]="80"
    [name]="InternalIcon.Rocket"
    [iconType]="IconType.GIF"
  ></app-internal-icon>
</div>
