import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';

@Component({
  selector: 'app-venture-state',
  templateUrl: './venture-state.component.html',
})
export class VentureStateComponent {
  @Input() currentIndex = 1;
  @Input() items: Array<CustomAtrributeValue>;
  @Input() editable = false;

  @Output() selectItem = new EventEmitter();

  select(item: CustomAtrributeValue, index): void {
    if (this.editable) {
      this.currentIndex = index;
      this.selectItem.next(item);
    }
  }
}
