<div class="mt-3">
  <ng-container *ngIf="editting">
    <div class="form-group search">
      <app-organisation-search
        [searchMode]="orgsSearchMode"
        [ignoreCompany]="ignoreCompany"
        [excludedOrganisationIds]="ignoreOrgIds"
        [userId]="userId"
        [searchPlaceholder]="
          'VENTURE-DETAILS_Submit-venture_Search-plaholder' | translate
        "
        (selectOrganisation)="selectItem($event)"
      ></app-organisation-search>
    </div>
  </ng-container>
</div>

<!-- Organisations -->
<h6 class="mb-4" translate>Organizations</h6>

<app-organization-card-list
  [editting]="editting"
  [displayItems]="
    organisations
      | slice: (organisationsPage - 1) * pageSize:organisationsPage * pageSize
  "
  [emptyPlacholderKey]="emptyTextOrganizations"
  (itemRemoved)="removeItem($event)"
>
</app-organization-card-list>

<app-bootstrap-pagination
  [page]="organisationsPage"
  [pageSize]="pageSize"
  [collectionSize]="organisations.length"
  (changePageSize)="pageSize = $event"
  (changePage)="organisationsPage = $event"
></app-bootstrap-pagination>

<!-- Challenges -->
<h6 class="my-4" translate>Challenges</h6>

<app-organization-card-list
  [editting]="editting"
  [showChallengeTimeline]="true"
  [displayItems]="
    challenges
      | slice: (challengesPage - 1) * pageSize:challengesPage * pageSize
  "
  [emptyPlacholderKey]="emptyTextChallenges"
  (itemRemoved)="removeItem($event)"
>
</app-organization-card-list>

<app-bootstrap-pagination
  [page]="challengesPage"
  [pageSize]="pageSize"
  [collectionSize]="challenges.length"
  (changePageSize)="pageSize = $event"
  (changePage)="challengesPage = $event"
></app-bootstrap-pagination>
