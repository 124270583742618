import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-entity-not-found-dialog',
  templateUrl: './entity-not-found-dialog.component.html',
  styles: [],
})
export class EntityNotFoundDialogComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() heading: string;
  @Input() message: string;
  @Input() confirmLabel = 'Ok';

  constructor(public activeModal: NgbActiveModal) {}

  confirm(): void {
    StringUtils.removeParamFromUrl(UrlParam.NotFound, null, true);
    this.activeModal.close(true);
  }
}
