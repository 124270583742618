<tag-input
  [(ngModel)]="items"
  [placeholder]="placeholder"
  [secondaryPlaceholder]="placeholder"
  onlyFromAutocomplete="true"
  (ngModelChange)="emitValue()"
  (onAdd)="addTag($event)"
  (onRemove)="removeTag()"
  #tagInput
>
  <tag-input-dropdown
    [minimumTextLength]="0"
    [showDropdownIfEmpty]="true"
    [keepOpen]="false"
    [autocompleteObservable]="searchItems"
    [limitItemsTo]="limitItemsTo"
    [matchingFn]="checkMatching"
    [appendToBody]="false"
  >
    <ng-template let-item="item" let-index="index">
      <ng-container [ngSwitch]="item.type">
        <div
          *ngSwitchCase="TagFieldType.PreSelection"
          class="tag-field--common tag-field__preselection"
        >
          <div class="text-truncate">
            {{ item.display }}
          </div>
        </div>

        <div
          *ngSwitchCase="TagFieldType.Header"
          class="tag-field--common tag-field__header"
        >
          {{ item.display }}
        </div>

        <div *ngSwitchDefault class="tag-field--common tag-field__default">
          <div class="tag-field__item-image mr-3 mrb-3">
            <app-image-wrapper
              imgClass="widget__image venture__image"
              [imgObject]="item[keyMapImg]"
              [wrapperClasses]="
                keyMapImg === 'logo'
                  ? 'widget__image-wrapper organization__logo'
                  : 'widget__image-wrapper entity__image-wrapper'
              "
            ></app-image-wrapper>
          </div>
          <div class="text-truncate">
            {{ item.display }}
          </div>
        </div>
      </ng-container>
    </ng-template>
  </tag-input-dropdown>
</tag-input>

<ng-template #viewOnly>
  <tag-input [hideForm]="true" [(ngModel)]="items"> </tag-input>
</ng-template>
