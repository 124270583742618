<ng-container *ngIf="form && attributeDescription && entityName">
  <div class="table-cell-edit d-flex flex-column">
    <div class="d-flex flex-column edit-section">
      <div class="edit-section__content">
        <ng-container
          *ngIf="
            lstRelatedEntityAsMultiString[entityName]?.propertyNames?.includes(
              attributeDescription.propertyName
            );
            else otherRelatedEntity
          "
        >
          <app-form-field-custom
            [formInput]="form"
            [editable]="!requesting"
            [type]="AttributeType.MultiString"
            [showLabel]="true"
            [attributeDescription]="attributeDescription"
            [options]="{
              placeholder: multiStringPlaceholder | translate
            }"
          >
          </app-form-field-custom>
        </ng-container>
        <ng-template #otherRelatedEntity>
          <ng-containter [ngSwitch]="attributeDescription.propertyName">
            <ng-container *ngSwitchCase="'CurrentSubmission'">
              <app-form-field-custom
                [formInput]="form"
                [controlName]="attributeDescription.propertyName"
                [editable]="!requesting"
                [showLabel]="true"
                [attributeDescription]="
                  lstAdditionalAttrDes | fieldSingleOption: 'Status'
                "
              >
              </app-form-field-custom>
            </ng-container>
          </ng-containter>
        </ng-template>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2 mt-md-3 edit-section__footer">
      <button
        class="btn btn-outline-secondary mr-2 btn__cancel"
        (click)="cancel()"
        [disabled]="requesting"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        class="btn btn-primary btn__save"
        appRequireLogin
        appDebounceClick
        (debounceClick)="save()"
        [disabled]="
          form.controls[attributeDescription.propertyName]?.invalid ||
          requesting
        "
      >
        <ng-container *ngIf="requesting; else saveIcon">
          <i class="fas fa-spinner fa-spin m-0"></i>
        </ng-container>
        <ng-template #saveIcon>
          <i class="fas fa-check"></i>
        </ng-template>
      </button>
    </div>
  </div>
</ng-container>
