export type PropertyName =
  | 'InnovationProgress'
  | 'ConstitutionState'
  | 'BusinessCharacteristics'
  | 'OpenFor'
  | 'Terms'
  | 'Content'
  | 'OnlyBusinessArea';

export enum TemplateName {
  Reduced = 'ReducedListTile1',
  Default = 'Default',
  SIListTile = 'SIListTile',
  Reduced2 = 'ReducedListTile2',
}

export type EntityVisibilityConfig = {
  [key in TemplateName]: {
    [property in PropertyName | 'default']: boolean;
  };
};

export const entityVisibilityConfig: EntityVisibilityConfig = {
  ReducedListTile1: {
    InnovationProgress: false,
    ConstitutionState: false,
    BusinessCharacteristics: false,
    OpenFor: true,
    default: true,
    Terms: false,
    Content: true,
    OnlyBusinessArea: false,
  },
  Default: {
    InnovationProgress: true,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: true,
    OnlyBusinessArea: false,
  },
  SIListTile: {
    InnovationProgress: true,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: true,
    OnlyBusinessArea: false,
  },
  ReducedListTile2: {
    InnovationProgress: true,
    ConstitutionState: true,
    BusinessCharacteristics: true,
    OpenFor: true,
    default: true,
    Terms: true,
    Content: false,
    OnlyBusinessArea: true,
  },
};
