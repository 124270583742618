<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>
<div class="modal-header">
  <h4 class="modal-title" translate>{{ postData | call : getModalTitle }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body new-message-dialog-modal-body">
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #sendMessageTemplate>
    <form [formGroup]="form">
      <div *ngIf="currentOrg" class="d-flex align-items-center mb-4">
        <button
          class="filter__btn mr-2 filter__btn--active flex-shrink-0"
          id="filterCollapse"
        >
          <app-internal-icon
            [hasFilter]="false"
            [height]="20"
            [iconType]="IconType.PNG"
            [name]="InternalIcon.InnovationSpace"
          ></app-internal-icon>
        </button>
        <span class="flex-shrink-0" translate>UI.News.ThisPostBelongsTo</span>

        <span class="font-weight-bold text-truncate">
          &nbsp;{{ currentOrg?.name || 'All Innovation Spaces' | translate }}
        </span>
      </div>
      <div class="d-flex flex-row">
        <div class="sender-profile d-flex justify-content-center">
          <div class="message-to-profile">
            <img
              alt="profile image"
              [src]="profileImage"
              appDefaultAvatar
              appDynamicImgUrl
            />
          </div>
        </div>
        <div class="message-content-quill mb-3">
          <app-form-field-custom
            #messageForm
            [formInput]="form"
            [type]="AttributeType.RTE"
            [attributeDescription]="contentAttributeDescription"
            [shouldValidateOnTouch]="true"
            [options]="{
              placeholder: placeholder | translate,
              allowEmojiOnRTE: true,
              allowImgOnRTE: true,
              allowCropImg: true,
              aspectRatio: 16 / 9
            }"
            (ngxVisibility)="formFieldLoaded(messageForm)"
          >
          </app-form-field-custom>
        </div>
      </div>
    </form>
  </ng-template>
</div>
<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
    translate
  >
    Cancel
  </button>
  <button
    appRequireLogin
    [disabled]="
      form.invalid || isSubmitting || messageForm?.imageInputDisplay?.uploading
    "
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="onSubmit(postData)"
  >
    <ng-container *ngIf="isSubmitting">
      <i class="fas fa-spinner fa-spin btn-icon-right"></i>
    </ng-container>
    <span translate>{{ postData | call : getSubmitButtonText }}</span>
  </button>
</div>
