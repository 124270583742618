import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SearchChannelsService } from '@src/app/components/master-layout/navs/components/thread-filter/services/search-channels.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { CrossSearchingEntity } from '@src/app/shared/models/cross-searching-entity.model';
import { EMPTY } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
type ChannelSearchItemInterface = OrganizationInterface | EventInterface;

@Component({
  selector: 'app-channel-search-result',
  templateUrl: './channel-search-result.component.html',
  styles: [],
})
export class ChannelSearchResultComponent implements OnInit, OnChanges {
  @Input() entityName: EntityName;
  @Input() searchKeyword: string;
  @Input() searchItems: ChannelSearchItemInterface[];
  @Input() activeChannel: string;
  @Input() activeFilter: EntityName;
  @Input() searchEntities: CrossSearchingEntity[];

  @Output() selectedChannel = new EventEmitter();

  isSearching = false;
  isVisible = false;
  showGroupLogo: boolean;
  entityLabel = '';

  constructor(
    private searchChannelsService: SearchChannelsService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.getSearchResult();

    const foundEntity = this.searchEntities.find(
      (entity) => entity.key === this.entityName
    );
    if (foundEntity) {
      this.entityLabel = foundEntity.label;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeFilter) {
      this.showGroupLogo = this.activeFilter === EntityName.All;
      this.isVisible =
        this.activeFilter === this.entityName ||
        this.activeFilter === EntityName.All;

      if (changes.activeFilter.previousValue) {
        this.getSearchResult();
      }
    }
  }

  get entityLogoName(): string {
    if (this.entityName === EntityName.Organization) {
      return 'organisation';
    }
    return this.entityName.toLowerCase();
  }

  get entityApi(): Function {
    return this.searchChannelsService.getEntitesAPIs[this.entityName];
  }

  getSearchResult(): void {
    const sessionReady$ = this.sessionService.apiReady$.pipe(filter(Boolean));

    sessionReady$.subscribe(() => {
      this.searchChannelsService
        .getKeyword()
        .pipe(
          tap(() => (this.isSearching = true)),
          debounceTime(400),
          switchMap((keyword) => {
            if (!this.isVisible || !keyword) return EMPTY;

            const getApi =
              this.searchChannelsService.getEntitesAPIs[this.entityName];
            const { threadId, threadEntity, ...restDto } =
              this.searchChannelsService.filterDto;
            return getApi(restDto);
          }),
          untilDestroyed(this)
        )
        .subscribe((data: any) => {
          this.isSearching = false;
          this.searchItems = data?.items?.map((item) =>
            this.mapDataToOptions(item)
          );
        });
    });
  }

  mapDataToOptions(item: any): any {
    switch (this.entityName) {
      case EntityName.Organization:
        return {
          id: item.id,
          displayName: item.orgName,
          image: item.logo,
          entityName: this.entityName,
          entityLogo: 'organisation',
          tenantId: +this.sessionService.getTenantId(),
        };
      case EntityName.Event:
        return {
          id: item.id,
          displayName: item.title,
          image: item.image,
          entityName: this.entityName,
          entityLogo: this.entityName.toLowerCase(),
          tenantId: +this.sessionService.getTenantId(),
        };
      case EntityName.Challenge:
        return {
          id: item.id,
          displayName: item.orgName,
          image: { url: item.logo, alt: item.orgName },
          entityName: this.entityName,
          entityLogo: this.entityName.toLowerCase(),
          tenantId: +this.sessionService.getTenantId(),
        };
    }
  }

  onSelectChannel($event, option) {
    this.selectedChannel.emit(option);
  }

  ngOnDestroy() {
    /** */
  }
}
