import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { EventInterface } from '@shared/interfaces/event.interface';
import { BaseHttpService } from '@core/http/base-http.service';

@Component({
  selector: 'app-event-card-footer',
  templateUrl: './event-card-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardFooterComponent {
  @Input() event: EventInterface;

  @Input() eventService: BaseHttpService<EventInterface>;

  @Output() jointEvent = new EventEmitter<EventInterface>();

  afterJoinEvent(e: EventInterface): void {
    this.jointEvent.emit(e);
  }
}
