<ng-container *ngIf="isShowFilterPane">
  <div
    class="filter-pane mb-3"
    *ngIf="isShowfilter && filterAttributeDescriptions.length > 0"
  >
    <ng-container *ngIf="!isMobile && !isTouchDevice; else filterMobileHeader">
      <ngb-accordion
        #filterPane="ngbAccordion"
        class="filter-pane__accordion filter-pane-simple"
        [activeIds]="['filter-panel']"
      >
        <ngb-panel
          cardClass="ignore-overflow"
          id="filter-panel"
          class="m-0 p-0"
          (click)="openFilterMobile()"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn-header-panel" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <i class="fas fa-filter mr-2"></i>
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                >
                  {{ 'Filters' | translate }}
                  <span *ngIf="numberOfFiltersApplied"
                    >({{ numberOfFiltersApplied }})</span
                  >
                </span>
              </div>
              <div>
                <button
                  *ngIf="!filterPane.isExpanded('filter-panel') && hasFilters"
                  class="btn btn-link form-section__btn clear-all-btn"
                  (click)="onClearAll()"
                  translate
                >
                  UI.SmallFilter.ClearFilter
                </button>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </div>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="widget__card--background m-0">
              <div class="form-section" *ngIf="form">
                <form [formGroup]="form" class="row mb-2">
                  <ng-container
                    *ngFor="
                      let attributeDescription of filterAttributeDescriptions;
                      index as i
                    "
                  >
                    <div class="col-4 mb-3">
                      <label
                        class="pointer-cursor font-weight-bold mb-1 filter-label"
                      >
                        {{ attributeDescription?.displayName }}
                      </label>

                      <div class="d-flex flex-column">
                        <ng-select
                          #filterSelect
                          [placeholder]="'UI.SmallFilter.Select' | translate"
                          [items]="
                            attributeDescription.choice.filteredSelections
                          "
                          [multiple]="false"
                          bindLabel="value"
                          [selectableGroup]="true"
                          [closeOnSelect]="true"
                          [formControlName]="attributeDescription.propertyName"
                          [searchFn]="customSearchFn"
                          [editableSearchTerm]="true"
                          notFoundText="No result found"	
                          (change)="
                            onValueSelectChange($event, attributeDescription)
                          "
                          (focus)="onFocus(filterSelect)"
                        >
                          <ng-template
                            ng-option-tmp
                            let-item="item"
                            let-item$="item$"
                            let-index="index"
                          >
                            {{ item.value }}
                          </ng-template>
                        </ng-select>
                      </div>
                    </div>
                  </ng-container>
                  <div
                    class="col-4 mb-3 d-flex justify-content-end align-items-end filter-btn-wrapper"
                  >
                    <div>
                      <button
                        *ngIf="hasFilters"
                        (click)="onClearAll()"
                        class="btn btn-link form-section__btn clear-all-btn"
                        translate
                        [disabled]="isFiltering"
                      >
                        UI.SmallFilter.ClearFilter
                      </button>

                      <button
                        appDebounceClick
                        (debounceClick)="onFilter(true)"
                        class="btn btn-primary form-section__btn apply-filter-btn mr-0"
                        [disabled]="isFiltering || !hasFilters"
                      >
                        <ng-container *ngIf="isFiltering">
                          <i class="fas fa-spinner fa-spin"></i>
                        </ng-container>
                        <span>{{
                          'UI.SmallFilter.ApplyFilter' | translate | uppercase
                        }}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </ng-container>
  </div>
  <div
    *ngIf="showFilterResult && filterResultCount && numberOfFiltersApplied > 0"
    class="my-3 font-weight-bold"
  >
    Filter results (<span>{{ filterResultCount }}</span
    >)
  </div>
  <div *ngIf="filterResultCount === 0 && numberOfFiltersApplied > 0">
    <div class="font-weight-bold">No results match</div>
    <div>
      The active filters are not matched with any results.
      <button
        class="btn btn-link form-section__btn clear-all-btn"
        (click)="onClearAll()"
        translate
      >
        UI.SmallFilter.ClearFilter
      </button>
    </div>
  </div>
</ng-container>

<ng-template #filterMobileHeader>
  <div (click)="openFilterMobile()">
    <button class="btn-header-panel-mobile" type="button">
      <div translate class="pull-left float-left w-5 d-flex align-items-center">
        <i class="fas fa-filter mr-2"></i>
        <span class="venture__company-header mr-5 d-block d-lg-inline-block">
          {{ 'Filters' | translate }}
          <span *ngIf="numberOfFiltersApplied"
            >({{ numberOfFiltersApplied }})</span
          >
        </span>
      </div>
      <i
        class="fas fa-caret-right float-right pull-right venture__company-header"
      ></i>
    </button>
  </div>
</ng-template>

<ng-template #filterMobile>
  <div class="modal-header">
    <h6 class="modal-title truncate-line-2" translate>
      {{ 'Filters' | translate }}
    </h6>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="closeFilterMobile()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body modal-body-confirmation">
    <div class="form-section" *ngIf="form">
      <form [formGroup]="form" class="row mb-2">
        <ng-container
          *ngFor="
            let attributeDescription of filterAttributeDescriptions;
            index as i
          "
        >
          <div class="col-12 mb-2">
            <label class="pointer-cursor font-weight-bold mb-1 filter-label">
              {{ attributeDescription?.displayName }}
            </label>

            <div class="d-flex flex-column">
              <ng-select
                #filterSelect
                [placeholder]="'UI.SmallFilter.Select' | translate"
                [items]="attributeDescription.choice.filteredSelections"
                [multiple]="false"
                bindLabel="value"
                [selectableGroup]="true"
                [closeOnSelect]="true"
                [formControlName]="attributeDescription.propertyName"
                [editableSearchTerm]="true"
                [searchFn]="customSearchFn"
                (change)="onValueSelectChange($event, attributeDescription)"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-item$="item$"
                  let-index="index"
                >
                  {{ item.value }}
                </ng-template>
              </ng-select>
            </div>
          </div>
        </ng-container>
      </form>

      <div class="filter-btn-wrapper d-flex flex-column">
        <button
          *ngIf="hasFilters"
          (click)="onClearAll()"
          class="btn btn-link form-section__btn clear-all-btn"
          translate
          [disabled]="isFiltering"
        >
          UI.SmallFilter.ClearFilter
        </button>

        <button
          appDebounceClick
          (debounceClick)="closeFilterMobile(); onFilter(true)"
          class="btn btn-primary form-section__btn apply-filter-btn mr-0 py-2 ml-0"
          [disabled]="isFiltering || !hasFilters"
        >
          <ng-container *ngIf="isFiltering">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span>{{
            'UI.SmallFilter.ApplyFilter' | translate | uppercase
          }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
