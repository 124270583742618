<app-file-upload
  *ngIf="editable"
  [multiLocale]="false"
  [acceptType]="acceptType"
  [isDocumentFiles]="true"
  [documentFiles]="files"
  (srcUrlChange)="setValue($event)"
>
  <div class="attachments">
    <em class="fas fa-file-upload fa-lg"></em>
    <span class="attachments__text pl-3" translate>Upload Files</span>
  </div>
</app-file-upload>

<div class="attachments__files">
  <ng-container *ngIf="files?.length > 0; else emptyFileMessage">
    <ng-container *ngFor="let item of files; index as index">
      <div
        *ngIf="item?.alt !== null && item?.url !== null"
        class="jip-tag tag-crop"
        [ngbTooltip]="getFileName(item)"
      >
        <a
          class="link-unstyled"
          [href]="item?.file?.url || item?.url || item"
          download
          target="_blank"
          ><em class="far fa-paperclip jip-tag__attached-icon"></em>

          <span class="jip-tag__text">
            {{ getFileName(item) }}
          </span>
        </a>

        <em
          *ngIf="editable"
          (click)="remove($event, index)"
          class="jip-tag__close-icon far fa-times fa-sm"
        ></em>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #emptyFileMessage>
    <div class="attachments__empty-msg font-italic">
      {{ 'UI.Validation.RequiredFile' | translate }}
    </div>
  </ng-template>
</div>
