<ng-container *ngIf="col">
  <ng-container *ngIf="isSupportedMultiStringRelatedEntity">
    <div class="multiselect-type">
      <div class="person-card__badge-wrapper person-card__info--ellipsis">
        <div class="badge" *ngFor="let multiSelectItem of value">
          {{
            multiSelectItem[lstRelatedEntityAsMultiString[entityName].keyMap]
          }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-containter [ngSwitch]="col.propertyName">
    <ng-container *ngSwitchCase="'Initiator'">
      <div (click)="openPeopleViewModal()" appClickPreventDefault>
        <app-venture-people
          [initiator]="value"
          [showLabel]="false"
          [fromTableCell]="true"
        >
        </app-venture-people>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'Stakeholders'">
      <div (click)="openPeopleViewModal()" appClickPreventDefault>
        <ng-template [ngTemplateOutlet]="memberTemp"></ng-template>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'PendingStakeholders'">
      <ng-template [ngTemplateOutlet]="memberTemp"></ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'CurrentSubmission'">
      <span> {{ value?.value }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'SubmittedOrganizations'">
      <app-organization-challenge-view [items]="value">
      </app-organization-challenge-view>
    </ng-container>

    <ng-container *ngSwitchCase="'SubmissionPhase'">
      <span class="truncate-line-3"> {{ value }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="'TimestampCreator'">
      <div class="d-flex flex-column timestamp-creator" *ngIf="value">
        <a
          class="w-100 timestamp-creator__created-date mt-1"
          [href]="timeStampDetailUrl"
        >
          <b>{{ value.createdDate | localizedDate: DateFormat.Timestamp }}</b>
        </a>

        <div
          class="d-flex align-items-center justify-content-center timestamp-creator__info"
        >
          <app-venture-people
            [initiator]="value.creator"
            [showLabel]="false"
            [fromTableCell]="true"
          >
          </app-venture-people>
          <span *ngIf="value.creator" class="d-flex align-items-center">{{
            value.creator?.firstName + ' ' + value.creator?.lastName
          }}</span>
        </div>
      </div>
    </ng-container>
  </ng-containter>

  <ng-template #memberTemp>
    <app-venture-people
      [stakeholders]="value"
      [showLabel]="false"
      [showOnlyStakeholder]="true"
      [fromTableCell]="true"
      [numberOfDisplays]="3"
    ></app-venture-people>
  </ng-template>
</ng-container>
