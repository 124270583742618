<div [ngClass]="styleClass" class="news-card-header mr-3">
  <app-message-header-wrapper
    [people]="news.from"
    [isSystemNews]="news.isSystemNews"
    [messageDate]="news.dateTime"
    [showPeopleAsJipLogo]="news.action === MessageAction.VentureAwarded"
  >
    <ng-template #ownerTemplate>
      <div class="news-messages__owner-name">
        <ng-container *ngIf="news.from; else replaceText">
          <a
            *ngIf="
              news.action !== MessageAction.VentureSubmittedOrganization &&
              news.action !== MessageAction.VentureAwarded
            "
            appUnsavedFormCheck
            class="underline d-inline-block jip-upper-first-letter btn-link-primary"
            [href]="peopleURL + '/' + news.from.id"
          >
            {{ sender }}
          </a>
          <app-bot-flag
            *ngIf="news.from?.isBot"
            [parentClass]="'ml-1 d-inline-block'"
          ></app-bot-flag>
        </ng-container>
        <ng-template #replaceText>
          <ng-container *ngIf="!news.isSystemNews; else portalPart">
            <span class="deactive-label">{{
              'UI.UnsubscribedUser' | translate
            }}</span>
          </ng-container>
          <ng-template #portalPart>
            <a [href]="portalUrl">{{ portalName }}</a>
          </ng-template>
        </ng-template>
      </div>
    </ng-template>

    <app-card-action
      [ownerNameTemplate]="ownerTemplate"
      [id]="news.content?.instanceId"
      [content]="news.content"
      [actionType]="news.action"
      [entityName]="news.content?.entityName"
      [from]="news.from"
      [currentPersonId]="currentPersonId"
    ></app-card-action>
  </app-message-header-wrapper>
</div>
