import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '@core/toast.service';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { ArrayUtils } from '@shared/utils/array-utils';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shared-pending-people',
  templateUrl: './shared-pending-people.component.html',
})
export class SharedPendingPeopleComponent implements OnInit {
  @Input() entityId: number;
  @Input() httpService: BaseHttpService<any>;

  @Output() notifyRequestChange = new EventEmitter<any>();

  items: PeopleInterface[] = [];
  page = 1;
  pageSize = 5;
  collectionSize = 0;
  requesting = { all: { reject: false, approve: false } };

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.paginateItems();
  }

  paginateItems(): void {
    if (!this.entityId || !this.httpService) {
      return;
    }
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    this.httpService
      .paginateByEntity('/pending-list/people', this.entityId, params)
      .subscribe((res: ApiGetResponse<PeopleInterface>) => {
        this.items = res?.items;
        this.collectionSize = res?.rowCount;
      });
  }

  approveOne(item: PeopleInterface, index: number): void {
    this.approve([item.id], index);
  }

  approveAll(): void {
    if (!this.items) {
      return;
    }
    const ids = this.items.map((item) => item.id);
    this.approve(ids);
  }

  approve(items: number[] = [], index: any = 'all'): void {
    const api = this.httpService.putByEntity(
      this.entityId,
      { ids: items },
      '/approve-all'
    );
    this.handleApi(api, items, index, 'approve');
  }

  rejectOne(item: PeopleInterface, index: number): void {
    this.reject([item.id], index);
  }

  rejectAll(): void {
    if (!this.items) {
      return;
    }
    const ids = this.items.map((item) => item.id);
    this.reject(ids);
  }

  reject(items: number[] = [], index: any = 'all'): void {
    const api = this.httpService.putByEntity(
      this.entityId,
      { ids: items },
      '/reject-all'
    );
    this.handleApi(api, items, index, 'reject');
  }

  handleApi(api: Observable<any>, items: number[], i, key): void {
    if (!this.requesting[i]) {
      this.requesting[i] = {};
    }
    this.requesting[i][key] = true;
    api.subscribe({
      next: (res) => {
        this.requesting[i][key] = false;

        this.items = ArrayUtils.removeItems(this.items, items, 'id');

        if (this.items.length === 0 && this.collectionSize > this.pageSize) {
          this.page = Math.max(this.page - 1, 1);
          this.paginateItems();
        }

        this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
        this.notifyRequestChange.emit();
      },
      error: (err) => {
        this.requesting[i][key] = false;
        this.toastService.showError('UI.Toast.UpdatedFailed');
      },
    });
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateItems();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.paginateItems();
  }
}
