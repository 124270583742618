import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AttributeDescription,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
})
export class BaseCardComponent<T extends { id: number; instanceId?: number }>
  implements OnInit, OnDestroy
{
  @Input() data: T;

  attributeType = AttributeType;
  attributes: { [key: string]: AttributeDescription };

  entityUrl = '#';

  constructor(protected service: BaseHttpService<T>) {}

  ngOnInit(): void {
    this.service
      .getAttributeDescription()
      .pipe(untilDestroyed(this))
      .subscribe((data: MetaInformation) => {
        const items = data?.entityDescription?.attributeDescriptions || [];

        this.attributes = {};
        for (const item of items) {
          this.attributes[item.propertyName] = item;
        }
      });
  }

  getFieldOptions(item: { attributeDescriptions? }, key): AttributeDescription {
    return item?.attributeDescriptions
      ? FormUtils.getFieldOptions(item.attributeDescriptions, key)
      : this.attributes[key];
  }

  getEntityDetailPageUrl(jipUrl: string): void {
    const entityId = this.data?.id ?? this.data?.instanceId;
    if (entityId) {
      this.entityUrl = `${jipUrl}/${entityId}`;
    }
  }

  ngOnDestroy(): void {
    /**/
  }
}
