import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { FileInterface } from '@shared/interfaces/file.interface';
import { BaseHttpService } from './base-http.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseHttpService<FileInterface> {
  imageUploadPublisher = new BehaviorSubject(null);
  imageUploadSubscriber = this.imageUploadPublisher.asObservable();

  getApiRootPath(): string {
    return 'files/images';
  }

  uploadSingleLocale(
    id,
    file,
    alt = '',
    navigateUrl = '',
    locale?: string
  ): Observable<any> {
    return this.uploadFile(
      id,
      file,
      FileType.SingleLocale,
      alt,
      navigateUrl,
      locale
    );
  }

  uploadMultiLocale(id, file, alt = '', navigateUrl = ''): Observable<any> {
    return this.uploadFile(id, file, FileType.MultiLocale, alt, navigateUrl);
  }

  private uploadFile(
    id: number,
    file: File,
    type: FileType,
    alt = '',
    navigateUrl = '',
    locale?: string
  ): Observable<any> {
    const url = `${this.apiUrl}`;
    const formData = new FormData();

    formData.append('file', file);
    formData.append('type', type);
    formData.append('Alt', alt);
    formData.append('NavigateUrl', navigateUrl);

    const options = locale
      ? {
          headers: new HttpHeaders({ 'jip-locale': locale }),
        }
      : {};

    if (id) {
      return this.http.put(`${url}/${id}`, formData, options);
    } else {
      // create
      return this.http.post(url, formData);
    }
  }
}

export enum FileType {
  SingleLocale = '0',
  MultiLocale = '1',
}
