import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PeopleHttpService } from '@core/http/people-http.service';
import { SessionService } from '@core/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityName } from '@shared/enums/entity-name.enum';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';
import { SendMessageDialogComponent } from '@src/app/components/dialogs/send-message-dialog/send-message-dialog.component';
import { NotificationService } from '@src/app/core/notification.service';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { PeopleAction } from '../../interfaces/people.interface';
import { EntityStateCountComponent } from '../entity-state-count/entity-state-count.component';

export enum CardType {
  Default,
  Jury,
}

@Component({
  selector: 'app-people-card-list',
  templateUrl: './people-card-list.component.html',
})
export class PeopleCardListComponent
  extends EntityStateCountComponent
  implements OnChanges, OnDestroy
{
  @Input() displayItems: UserInterface[] = [];
  @Input() editable = false;
  @Input() enableExtraAction = false;
  @Input() enableInteractionBar = false;
  @Input() extraActions: PeopleAction[] = [];
  @Input() customUserRoles: CustomAtrributeValue[] = [];
  @Input() allowRemovePeople: boolean;
  @Input() fromPendingPeople = false;
  @Input() emptyMessage = 'There are no people cards yet';
  @Input() globalOrgId = 0;
  @Input() cardType: CardType;

  @Output() peopleRemoved = new EventEmitter();
  @Output() peopleRoleChanged = new EventEmitter<UserInterface>();
  @Output() extraActionClicked = new EventEmitter<PeopleAction>();
  @Output() peopleCardClicked = new EventEmitter<void>();

  showVenturesModal = false;
  showPeopleModal = false;

  selectedEntityId: number;
  constructor(
    public httpService: PeopleHttpService,
    public sessionService: SessionService,
    public modalService: NgbModal,
    public notificationService: NotificationService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.emptyMessage) {
      this.emptyMessage = 'There are no people cards yet';
    }

    if (changes.displayItems) {
      this.handleRoleIndex();
      this.runEntityStateCount(
        EntityName.Person,
        this.displayItems,
        this.httpService
      );
    } else if (changes.customUserRoles) {
      this.handleRoleIndex();
    }

    if (changes.enableExtraAction || changes.cardType) {
      this.setEnableExtraActionState();
    }
  }

  handleRoleIndex(): void {
    if (this.customUserRoles.length > 0) {
      this.displayItems.forEach((user: UserInterface) => {
        if (!user.role) {
          user.role = this.customUserRoles.find(
            (x) => x.codeId === 'DefaultValue'
          );
        }
        user.roleIndex =
          this.customUserRoles
            .map((x) => x.codeId)
            .indexOf(user.role?.codeId) || 0;
      });
    }
  }

  protected handleSyncEntityStateCount(): void {
    this.syncFollowingsState();
    this.syncVenturesState();
  }

  showEntityData(person: UserInterface): void {
    if (this.enableInteractionBar) {
      if (person) {
        this.selectedEntityId = null;
        setTimeout(() => {
          this.selectedEntityId = person.id;
        });
      }
    }
  }

  doExtraAction(peopleAction: PeopleAction, people: UserInterface): void {
    if (peopleAction && people) {
      peopleAction.people = people;
      this.extraActionClicked.emit(peopleAction);
    }
  }

  openSendMessage(personId: number, personName: string): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;
    const modalRef = this.modalService.open(SendMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    modalRef.componentInstance.toPersonId = personId;
    modalRef.componentInstance.receiverName = personName;

    modalRef.result.catch((error) => {
      console.log(error);
    });
  }

  selectRole(selectedRole: CustomAtrributeValue, person: UserInterface): void {
    person.previousRoleIndex = person.roleIndex;
    person.previousRole = { ...person.role };
    person.role = selectedRole;
    this.peopleRoleChanged.emit(person);
  }

  openModalPendingMessage(pendingMessage: string): void {
    this.notificationService.show('Message', pendingMessage);
  }

  trackItem(_index: number, item: UserInterface): number {
    return item.id;
  }

  setEnableExtraActionState(): void {
    this.enableExtraAction =
      this.sessionService.isLogin && this.enableExtraAction;
  }

  shouldShowRemoveBtn(person: UserInterface): boolean {
    if (this.cardType === CardType.Jury) return this.allowRemovePeople;

    return (
      this.sessionService.currentUser?.id !== person?.id &&
      (this.allowRemovePeople !== undefined
        ? this.allowRemovePeople
        : this.editable)
    );
  }

  onPeopleCardClicked(): void {
    this.peopleCardClicked.emit();
  }
}
