<ng-container *ngIf="comment">
  <div
    class="news-messages__item comment"
    [class.comment--is-editing]="comment.isEditing"
  >
    <div class="news-messages__wrapper widget__card--background">
      <app-message-header-wrapper
        [people]="comment.owner"
        [messageDate]="
          comment.createdDate !== comment.modifiedDate
            ? comment.modifiedDate
            : comment.createdDate
        "
        [isEdited]="comment.createdDate !== comment.modifiedDate"
      >
        <ng-container *ngIf="comment.owner; else replaceText">
          <a
            appUnsavedFormCheck
            class="news-messages__owner-name"
            [href]="peopleURL + '/' + comment.owner?.id"
          >
            {{ comment.owner?.firstName }} {{ comment.owner?.lastName }}&nbsp;
          </a>
          <app-bot-flag *ngIf="comment.owner?.isBot" [parentClass]="'ml-1 d-inline-block'"></app-bot-flag>
        </ng-container>
        <ng-template #replaceText>
          <div class="deactive-label">
            {{ 'UI.UnsubscribedUser' | translate }}
          </div>
        </ng-template>
      </app-message-header-wrapper>

      <div class="news-messages__body">
        <ng-container>
          <div
            *ngIf="comment.comment"
            class="news-messages__description white-space--pre-wrap"
          >
            <app-rich-text-toggle
              [text]="comment.comment"
              [controlName]="'group-message' + comment.id"
              [minNumOfRows]="2"
            ></app-rich-text-toggle>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="comment__footer venture__footer">
      <ul class="list-inline d-flex venture__actions justify-content-between">
        <app-like-entity
          [entity]="comment"
          [isLoadingLikingsCount]="isLoadingLikingsCount"
          (triggerToggleLike)="toggleLike()"
          (triggerShowLikingsData)="showLikingData()"
        ></app-like-entity>
        <div
          *ngIf="isCommentOwner"
          ngbDropdown
          container="body"
          placement="left-top"
          class="dropdown-no-arrow pointer-cursor"
        >
          <a class="p-2" ngbDropdownToggle appClickPreventDefault>
            <i class="fa fa-ellipsis-v align-middle"></i>
          </a>
          <ul ngbDropdownMenu>
            <li ngbDropdownItem class="venture__actions-item p-0">
              <a class="link-unstyled w-100 p-2" (click)="editComment()">
                <i class="fas fa-pencil-alt"></i>
                <span class="venture__actions-label font-weight-bold ml-2">{{
                  'UI.Interaction.Edit' | translate
                }}</span>
              </a>
            </li>
            <li ngbDropdownItem class="venture__actions-item p-0">
              <a class="link-unstyled w-100 p-2" (click)="deleteComment()">
                <i class="fas fa-trash"></i>
                <span class="venture__actions-label font-weight-bold ml-2">{{
                  'UI.Interaction.Delete' | translate
                }}</span>
              </a>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </div>
</ng-container>
