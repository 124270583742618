export enum TokenTypes {
  GetInTouchMessage = 'GetInTouchMessage',
  Message = 'Message',
  ResetPassword = 'ResetPassword',
  RegisterNewUser = 'RegisterNewUser',
  InviteVenture = 'InviteVenture',
  InviteOrganization = 'InviteOrganization',
  InviteChallenge = 'InviteChallenge',
  InviteChallengeJuror = 'InviteChallengeJuror',
  InvitePeople = 'InvitePeople',
  InviteEvent = 'InviteEvent',
  ChangeEmail = 'ChangeEmail',
  ResendEmail = 'Resend-Email',
  RegisterEvent = 'RegisterEvent',
}
