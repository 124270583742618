import { Pipe, PipeTransform } from '@angular/core';
import { AttributeValue } from '@shared/interfaces/attribute-description.interface';
import { FormUtils } from '@shared/utils/form-utils';

@Pipe({
  name: 'attributeValue',
})
export class AttributeValuePipe implements PipeTransform {
  transform(
    attributeValues: AttributeValue[],
    fieldName: string
  ): AttributeValue {
    return FormUtils.getFieldValue(attributeValues, fieldName);
  }
}
