// tslint:disable:variable-name
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SessionService } from '@core/session.service';
import { EntityName } from '@shared/enums/entity-name.enum';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

interface EntityCountingResponse {
  totalChallenge: number;
  totalEvent: number;
  totalOrganization: number;
  totalPeople: number;
  totalVenture: number;
  totalPost: number;
  totalMessage: number;
  totalNews: number;
  totalNewsMessage: number;
}

@Component({
  selector: 'app-entity-counter',
  templateUrl: './entity-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCounterComponent
  extends BaseWidgetComponent
  implements OnChanges
{
  @Input() entity_name: EntityName;

  @Input() org_id: string;

  @Input() display_text: string;

  @Input() display_text_color: string;

  @Input() number_color: string;

  count$: Observable<number> = of(0);

  constructor(
    protected readonly sessionService: SessionService,
    private readonly httpService: HttpClient
  ) {
    super(sessionService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.entity_name?.currentValue || changes.org_id?.currentValue) {
      this.setCounting(this.entity_name, this.org_id);
    }
  }

  private setCounting(entityName: EntityName, id: string): void {
    const params = id && !isNaN(+id) ? { id } : null;
    const extractCounting = (res: EntityCountingResponse) =>
      this.extractCounting(entityName, res) || 0;
    const getCounting = () =>
      this.httpService.get<EntityCountingResponse>('count', { params });

    this.count$ = this.sessionService.apiReady$.pipe(
      filter(Boolean),
      startWith(0),
      switchMap(getCounting),
      map(extractCounting),
      catchError(() => of(0)),
      take(2)
    );
  }

  private extractCounting(
    entityName: EntityName,
    obj: EntityCountingResponse
  ): number {
    switch (entityName) {
      case EntityName.Challenge:
        return obj.totalChallenge;
      case EntityName.Event:
        return obj.totalEvent;
      case EntityName.Organization:
        return obj.totalOrganization;
      case EntityName.Person:
        return obj.totalPeople;
      case EntityName.Venture:
        return obj.totalVenture;
      case EntityName.Post:
        return obj.totalPost;
      case EntityName.Message:
        return obj.totalMessage;
      case EntityName.News:
        return obj.totalNews;
      case EntityName.NewsMessage:
        return obj.totalNewsMessage;
      default:
        return 0;
    }
  }
}
