import { Component, Inject, Input } from '@angular/core';
import { NavsComponent } from '@components';
import { LoadingService } from '@core/services/loading.service';
import { WINDOW } from '@shared/helpers/window.token';
import { I18nService } from '@src/app/core/i18n/i18n.service';
import { NavInterface } from '@src/app/shared/interfaces/nav.interface';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styles: [],
})
export class NavItemComponent {
  @Input() item: NavInterface;
  @Input() count = 0;
  @Input() wrapperClass = '';

  unsavedFormCheck: boolean;

  constructor(
    private readonly loading: LoadingService,
    private readonly parent: NavsComponent,
    private i18nService: I18nService,
    @Inject(WINDOW) private window: Window
  ) {}

  launchLoadingIndicator(event: Event, href: string): void {
    event.preventDefault();
    if (this.unsavedFormCheck) return;
    this.loading.setLoadingState(true);

    const locale = this.i18nService.language.locale.toLowerCase();
    href = '/' + locale + href;

    setTimeout(() => {
      this.window.location.href = href;
    }, 0);
  }

  handleUnsavedFormState(val: boolean) {
    this.unsavedFormCheck = val;
  }
}
