import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import {
  FormComponentInterface,
  FormErrorService,
} from '@core/form/form-error.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { LogoDisplayOption } from '@shared/models/logo-type.model';
import { TextValidator } from '@shared/utils/form-fields-validation';
import { LoadingService } from '@src/app/core/services/loading.service';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { BaseComponentWithServiceComponent } from '../../base/base-list/base-component-with-service/base-component-with-service.component';

export enum ChangePasswordMode {
  Design = 'Design',
  Manual = 'Manual',
  Reset = 'Reset',
}

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent
  extends BaseComponentWithServiceComponent
  implements OnInit, FormComponentInterface, OnDestroy
{
  @Input() domain: string;

  // tslint:disable-next-line:variable-name
  @Input() logo_display_option: LogoDisplayOption;

  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  passwordMinLength = environment.loginFormLimit.passwordMinLength;

  changePasswordForm: UntypedFormGroup;
  currentPasswordIsShow = false;
  newPasswordIsShow = false;
  passwordRepeatIsShow = false;

  formErrorKey: string;
  errorMessage: { [key: string]: string };
  formErrorMessage: string;

  isSubmitting?: boolean;
  isSubmitted?: boolean;

  ChangePasswordModeEnum = ChangePasswordMode;
  changePasswordMode: ChangePasswordMode = ChangePasswordMode.Manual;

  myProfileUrl: string = environment.jipUrl.profile;

  isLinkedUser = false;
  profile$ = this.authenService.profile$;
  userHasPassword = true;

  constructor(
    private fb: UntypedFormBuilder,
    public authenService: AuthenService,
    public sessionService: SessionService,
    public formErrorService: FormErrorService,
    public centralConfig: CentralConfigService,
    public loadingService: LoadingService
  ) {
    super(sessionService);
  }

  getForm(): UntypedFormGroup {
    return this.changePasswordForm;
  }

  ngOnInit(): void {
    this.changePasswordMode = this.identifyChangePasswordMode();

    if (
      !this.sessionService.isLogin &&
      this.changePasswordMode === ChangePasswordMode.Manual
    ) {
      this.redirectPreviousPage();
    }

    if (this.sessionService.isLogin) {
      this.loadingService.setLoadingState(true);
      this.profile$
        .pipe(untilDestroyed(this))
        .subscribe((data: UserInterface) => {
          if (data) {
            this.loadingService.setLoadingState(false);
            this.isLinkedUser = data.isLoginWithLinkedin;
            this.userHasPassword = data.hasPassword;
            this.registerForm();
            return;
          }
        });
    }

    this.registerForm();
  }

  registerForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        newPassword: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        passwordRepeat: [
          '',
          {
            validators: [Validators.required],
          } as AbstractControlOptions,
        ],
      },
      { validator: Validators.compose([checkPasswordMatch]) }
    );

    if (this.sessionService.isLogin && !this.userHasPassword) {
      this.changePasswordForm.removeControl('oldPassword');
    }

    this.formErrorService.register(this);

    if (this.changePasswordMode === ChangePasswordMode.Reset) {
      this.oldPassword.disable();
    }
  }

  private identifyChangePasswordMode(): ChangePasswordMode {
    // In case using widget on WP elementor to design page
    const previewDesignMode = StringUtils.getParamFromUrl(
      UrlParam.ElementorPreview
    );
    const designMode = StringUtils.getParamFromUrl(UrlParam.Elementor);
    if (previewDesignMode || designMode) {
      return ChangePasswordMode.Design;
    }

    const { email, token } = this.getResetPasswordToken();
    if (email && token) {
      return ChangePasswordMode.Reset;
    }

    return ChangePasswordMode.Manual;
  }

  private getResetPasswordToken(): any {
    const email = decodeURIComponent(
      StringUtils.getParamFromUrl(UrlParam.ChangeFor)
    );
    const token = StringUtils.getParamFromUrl(UrlParam.Token);

    return { email, token };
  }

  get oldPassword(): any {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword(): any {
    return this.changePasswordForm.get('newPassword');
  }

  get passwordRepeat(): any {
    return this.changePasswordForm.get('passwordRepeat');
  }

  toggleCurrentPassword(event): void {
    event.preventDefault();
    this.currentPasswordIsShow = !this.currentPasswordIsShow;
  }

  toggleNewPassword(event): void {
    event.preventDefault();
    this.newPasswordIsShow = !this.newPasswordIsShow;
  }

  togglePasswordRepeat(event): void {
    event.preventDefault();
    this.passwordRepeatIsShow = !this.passwordRepeatIsShow;
  }

  onSubmit(): void {
    this.isSubmitting = true;
    if (this.changePasswordMode === ChangePasswordMode.Manual) {
      this.changePassword();
    } else if (this.changePasswordMode === ChangePasswordMode.Reset) {
      this.resetPassword();
    } else {
      console.log('Cannot identify change password mode');
    }
  }

  private changePassword(): void {
    const newPasswordOnly = this.isLinkedUser && !this.userHasPassword;
    this.authenService
      .changePassword(this.changePasswordForm.value, newPasswordOnly)
      .subscribe((data) => {
        this.isSubmitting = false;
        const error = data as ApiResponse;

        if (error && error.status === 400) {
          this.isSubmitted = false;
          this.handleError(data);
        } else {
          this.redirectPreviousPage();
          this.isSubmitted = true;
        }
      });
  }

  private resetPassword(): void {
    const processToken = this.getResetPasswordToken();
    const { email, token } = processToken;
    const newPasswordValue = this.newPassword.value;

    this.authenService
      .commitResetPassword(email, token, newPasswordValue)
      .subscribe((error: ApiResponse) => {
        if (error && error.status === 400) {
          this.isSubmitted = false;
          this.isSubmitting = false;

          this.handleError(error);
        } else {
          this.authenService
            .login({
              username: email,
              password: newPasswordValue,
            })
            .subscribe((data: any) => {
              if (this.sessionService.isLogin) {
                this.sessionService.deleteGlobalFilterCookies();
                this.redirectPreviousPage();
              } else {
                this.handleError(data);
              }
            });

          this.isSubmitting = false;
          this.isSubmitted = true;
        }
      });
  }

  handleError(data): void {
    this.formErrorMessage = this.formErrorService.handleError(data);
  }

  redirectPreviousPage(): void {
    const redirectUrl =
      localStorage.getItem(StorageEnum.previousUrl) ||
      this.sessionService.getLoginCallBackpage() ||
      environment.jipUrl.home;

    localStorage.removeItem(StorageEnum.previousUrl);
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(redirectUrl));
  }

  ngOnDestroy(): void {
    /**/
  }
}

export const checkPasswordMatch: ValidatorFn = (
  group: UntypedFormGroup
): ValidationErrors | null => {
  const newPassword = group.get('newPassword').value;
  const confirmPass = group.get('passwordRepeat').value;

  return newPassword === confirmPass ? null : { passwordNotSame: true };
};
