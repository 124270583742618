import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-input-wrapper',
  templateUrl: './search-input-wrapper.component.html',
  styles: [],
})
export class SearchInputWrapperComponent {
  @Input() searchText = '';
  @Input() editMode = false;
  @Output() searchClick = new EventEmitter();
  @Output() resetSearchClick = new EventEmitter();

  search(): void {
    this.searchClick.emit();
  }

  resetSearch(): void {
    this.resetSearchClick.emit();
  }
}
