import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventStamp, EventState } from '@shared/enums/event-entity.enum';
import { EventInterface } from '@shared/interfaces/event.interface';
import { UIPosition } from '@shared/models/ui.model';
import { CustomMetadataUtils } from '@shared/utils/custom-metadata-utils';
import { TimeUtils } from '@shared/utils/time-utils';

@Component({
  selector: 'app-event-stamp',
  templateUrl: './event-stamp.component.html',
})
export class EventStampComponent implements OnChanges {
  @Input() event: EventInterface;
  @Input() uiPosition: UIPosition = new UIPosition('1.25rem', '0.75rem');
  @Input() wrapperClass: string;

  currentEventStamp: EventStamp = null;
  EventStamp = EventStamp;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event && changes.event.currentValue) {
      if (this.event?.eventDateTime) {
        const eventStartDate = new Date(this.event.eventDateTime);
        const eventLengh = this.event.eventLength
          || CustomMetadataUtils.getAttributeValueByPropertyName(
            this.event.attributeDescriptions,
            this.event.attributeValues,
            'EventLength')
          || 0;
        const eventEndDate = TimeUtils.isValidDate(eventStartDate)
          ? TimeUtils.addHoursToDate(eventStartDate, eventLengh)
          : null;

        const isCancelledEvent = this.event.state?.codeId === EventState.Cancelled;

        if(isCancelledEvent) {
          this.currentEventStamp = EventStamp.Cancelled;
          return;
        }

        if (TimeUtils.isValidDate(eventEndDate)) {
          const currentDateTime = new Date().getTime();

          if (currentDateTime < eventStartDate.getTime()) {
            if(this.event.isFullyBooked) {
              this.currentEventStamp = EventStamp.FullyBooked;
            } else {
              this.currentEventStamp = EventStamp.SaveTheDate;
            }
          }
          else if (eventStartDate.getTime() <= currentDateTime
            && currentDateTime <= eventEndDate.getTime()) {
            if(this.event.isFullyBooked) {
              this.currentEventStamp = EventStamp.FullyBooked;
            } else {
              this.currentEventStamp = EventStamp.Ongoing;
            }
          }
          else if (currentDateTime > eventEndDate.getTime()) {
            this.currentEventStamp = EventStamp.Passed;
          }
        }
      }
    }
  }
}
