<form
  class="event-payment"
  [formGroup]="paymentForm"
  *ngIf="isCreating || (isAdmin && editting); else paymentSettingView"
>
  <div class="col-12 mb-3">
    <div class="venture__label widget__label venture__nda-required">
      @if(!isAdvancedSettingOpen) {
      <div class="d-flex align-items-center">
        <button
          class="btn btn-link text-left p-0 font-size-sm"
          (click)="onAdvanceSettingClick()"
          translate
        >
          UI.EventPayment.AdvancedSettings
        </button>
        <i
          *ngIf="isLoadingPaymentInfo || isPaymentKeyLoading"
          class="fas fa-spinner fa-spin ml-1"
        ></i>
      </div>
      } @else {
      <ng-container>
        <div class="mb-2 pb-1" translate>UI.EventPayment.PaymentSettings</div>
        <p class="text-dark" translate>UI.EventPayment.PaymentInstructions</p>
        <div class="mt-3">
          <div class="d-flex align-items-center w-100">
            <div class="position-relative flex-grow-1">
              <input
                class="form-control"
                placeholder="Enter API Key"
                rows="2"
                formControlName="PaymentApiKey"
                readonly
              />

              <span *ngIf="isPaymentApiKeyCopied" class="copied-indicator"
                >Copied</span
              >
            </div>
            <div class="ml-2">
              <button
                class="btn btn-interact btn-interact--big copy-api-key-btn"
                (click)="copyPaymentApiKey()"
                translate
              >
                UI.EventPayment.CopyApiKey
              </button>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <input
            class="form-control no-focus"
            placeholder="Enter payment link"
            rows="2"
            formControlName="PaymentLink"
            [pattern]="paymentLinkAttributeDesciption?.regularExpression"
          />
          <div
            *ngIf="
              paymentLink?.invalid &&
              (paymentLink?.dirty || paymentLink?.touched)
            "
          >
            <div class="alert alert-danger">Please enter a valid URL.</div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="text-dark font-bold col-3" translate>
            UI.EventPayment.Parameter
          </div>
          <div class="text-dark font-bold col-5" translate>
            UI.EventPayment.ParameterName
          </div>
          <div class="text-dark font-bold col-4" translate>
            UI.EventPayment.SampleData
          </div>
        </div>

        <ng-container
          *ngFor="
            let attribute of attributeDescription?.relatedEntityAttributeDescriptions ??
              []
          "
        >
          <ng-container
            *ngTemplateOutlet="
              paymentInfo;
              context: {
                attribute,
                value: getRelatedEntityValue(
                  attributeDescription.relatedEntityDefaultValues,
                  attribute.propertyName
                ),
                formValue: form.value
              }
            "
          >
          </ng-container>
          <ng-template
            #paymentInfo
            let-attribute="attribute"
            let-value="value"
            let-formValue="formValue"
          >
            <div
              *ngIf="!(attribute.propertyName | call : isExcluded)"
              class="row"
              [ngClass]="isEditParameterOn ? 'mt-2' : 'mt-3'"
            >
              <div class="col-3 d-flex align-items-center">
                <app-check-box
                  [elementId]="attribute.propertyName"
                  [content]="attribute?.displayName | lowercase"
                  [isChecked]="attribute?.displayName | call : hasParams"
                  (selectItem)="onPaymentParamCheck($event, attribute, value)"
                  class="text-dark"
                  [disabled]="paymentLink.invalid"
                  [labelClass]="'payment-param-label'"
                  [wrapperClass]="'m-0'"
                ></app-check-box>
              </div>
              <div class="col-5">
                @if(isEditParameterOn) {
                <input
                  [formControlName]="attribute.propertyName"
                  class="form-control"
                  [placeholder]="value?.display"
                  (keyup)="
                    onPaymentParamAliasChange($event, attribute.displayName)
                  "
                />
                } @else { @if(isCreating ||
                !paymentForm.get(attribute.propertyName)?.value) {
                <div class="text-dark font-weight-500">
                  {{ value?.display }}
                </div>
                } @else {
                <div class="text-dark font-weight-500">
                  {{ paymentForm.get(attribute.propertyName)?.value }}
                </div>
                } }
              </div>
              <div class="col-4 text-dark d-flex align-items-center">
                {{ getSampleData(value?.sampleData, formValue) }}
              </div>
            </div>
          </ng-template>
        </ng-container>
        <div
          class="d-flex align-items-center mt-3"
          (click)="toggleEditParameter()"
        >
          <span class="upload-control-wrapper__change-logo d-block">
            <i class="fas fa-pencil-alt"></i>
          </span>
          <span class="ml-1 upload-control-wrapper__control-text" translate
            >UI.EventPayment.EditParameterNames</span
          >
        </div>

        <div class="text-bold mt-3" translate>
          UI.EventPayment.TestLinkInstructions
        </div>

        <div class="mt-2">
          <button
            class="btn btn-interact btn-interact--big test-link-btn"
            [disabled]="paymentLink.invalid"
            (click)="onTestLink()"
            translate
          >
            UI.EventPayment.TestLink
          </button>
        </div>
        <div class="mt-3">
          <button
            class="btn btn-link text-left pt-0 pl-0 font-size-sm"
            (click)="toggleSetting()"
            translate
          >
            UI.EventPayment.CloseAdvancedSettings
          </button>
        </div>
      </ng-container>
      }
    </div>
  </div>
</form>

<ng-template #paymentSettingView>
  <div *ngIf="isAdmin" class="venture__label widget__label ml-3 mb-2">
    +1 {{ 'UI.EventPayment.AdvancedSettings' | translate }}
  </div>
</ng-template>
