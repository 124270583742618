import { Injectable } from '@angular/core';
import { OrganizationFilter } from '@src/app/shared/interfaces/organization.interface';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunitySelectorStoredService {
  private selectedOrg$ = new BehaviorSubject<OrganizationFilter>(null);
  keyword$ = new BehaviorSubject<string>('')
  search$ = new Subject<void>()

  constructor() {}

  get selectedOrg() {
    return this.selectedOrg$.asObservable();
  }

  setSelectedOrg(org: OrganizationFilter): void {
    this.selectedOrg$.next(org);
  }

  setKeyword(keyword: string): void {
    this.keyword$.next(keyword);
  }

  search(){
    this.search$.next();
  }
}
