import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() title = 'Confirmation';
  @Input() message: string;
  @Input() confirmLabel = 'Ok';
  @Input() isLeaveSite: boolean;
  @Input() isHtmlMessage = false;
  @Input() checkboxConfirmMessage: string;
  @Input() shouldShowFooter = true;

  isConfirmMessage: boolean;

  constructor(public activeModal: NgbActiveModal) { }

  confirm(): void {
    this.activeModal.close(true);
  }
  cancel(): void {
    this.activeModal.close(false);
  }
  setCheckboxValue(value: boolean): void {
    this.isConfirmMessage = value;
  }
}
