import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-enforce-profile-dialog',
  templateUrl: './enforce-profile-dialog.component.html',
})
export class EnforceProfileDialogComponent {
  @Input() id: number;

  constructor(
    protected activeModal: NgbActiveModal,
    private cookieService: CookieService
  ) {}

  getTenantName(): string {
    const name = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    return name;
  }
}
