import { LinkInterface } from '@src/app/shared/interfaces/attribute-description.interface';
import { environment } from '@src/environments/environment';
import { ATTRIBUTES_TO_REMOVE } from '../constants/common';
import { EntityName } from '../enums/entity-name.enum';
import { IconType } from '../enums/internal-icon.enum';
import { TokenTypes } from '../enums/token-type';

export class StringUtils {
  public static includes(
    source: string,
    key: string,
    caseSensitive = false
  ): boolean {
    if (!source || !key) {
      return false;
    }

    if (caseSensitive) {
      return source.includes(key);
    }

    return source.toLowerCase().includes(key.toLowerCase());
  }
  /**
   *
   * @param str str1
   * @param str2 str2
   * @param caseSensitive default = false
   */
  public static equal(
    str: string,
    str2: string,
    caseSensitive = false
  ): boolean {
    if (!str || !str2) {
      return false;
    }

    if (caseSensitive) {
      return str === str2;
    }

    return str.toLowerCase() === str2.toLowerCase();
  }

  public static toLowerCaseFirstLetter(str: string): string {
    if (str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    }
  }

  public static toUpperCaseFirstLetter(str: string): string {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  /**
   * Check if content is empty
   * Empty html tag is consider as empty
   */
  public static isEmptyHtmlContent(content: string): boolean {
    const isEmptyString = !content || content.trim() === '';
    if (isEmptyString) {
      return true;
    }

    const tmp = document.createElement('div');
    tmp.innerHTML = content;
    const pureContent = tmp.textContent || tmp.innerText || '';
    return pureContent && pureContent.trim() === '';
  }

  public static getParamFromUrl(key: string, sourceUrl = null): string {
    const urlString = sourceUrl || window.location.href;
    const url = new URL(urlString);
    return url.searchParams.get(key);
  }

  public static setQueryParamOnUrl(
    key: string,
    value: string,
    sourceUrl = null,
    isReplaceState = false
  ): void {
    const urlString = sourceUrl || window.location.href;
    const url = new URL(urlString);
    url.searchParams.set(key, value);

    // Only update the URL if it's different from the current one
    if (url.toString() !== window.location.href) {
      this.updateCurrentUrl(url, isReplaceState);
    }
  }

  public static removeParamFromUrl(
    key: string,
    sourceUrl = null,
    isReplaceState = false
  ): void {
    const urlString = sourceUrl || window.location.href;
    const url = new URL(urlString);
    url.searchParams.delete(key);

    if (url.toString() !== window.location.href) {
      this.updateCurrentUrl(url, isReplaceState);
    }
  }

  public static removeParamByKey(sourceUrl: string, key: string): string {
    const regex = new RegExp(`([?&])${key}=[^&]*(&)?`, 'gi');
    return sourceUrl.replace(regex, (match, p1, p2) => (p2 ? p1 : ''));
  }

  public static removeAllParamsFromUrl(srcUrl: string): string {
    return srcUrl?.split('?')[0];
  }

  private static updateCurrentUrl(url: URL, isReplaceState = false): void {
    if (isReplaceState) {
      window.history.replaceState(null, null, url as any);
    } else {
      window.history.pushState(null, null, url as any);
    }
  }

  public static getEntityUrlByEntityName(entityName: string): string {
    let entityUrl = '';
    switch (entityName) {
      case EntityName.Venture:
        entityUrl = environment.jipUrl.venture;
        break;

      case EntityName.Challenge:
        entityUrl = environment.jipUrl.challenges;
        break;

      case EntityName.Organization:
        entityUrl = environment.jipUrl.organizations;
        break;

      case EntityName.Person:
        entityUrl = environment.jipUrl.people;
        break;

      case EntityName.Event:
        entityUrl = environment.jipUrl.event;
        break;
    }
    return entityUrl;
  }

  public static getEntityUrlByTokenTypes(tokenTypes: string): string {
    let entityUrl = '';
    switch (tokenTypes) {
      case TokenTypes.InviteVenture:
        entityUrl = environment.jipUrl.venture;
        break;

      case TokenTypes.InvitePeople:
        entityUrl = environment.jipUrl.people;
        break;

      case TokenTypes.InviteOrganization:
        entityUrl = environment.jipUrl.organizations;
        break;

      case TokenTypes.InviteChallenge:
      case TokenTypes.InviteChallengeJuror:
        entityUrl = environment.jipUrl.challenges;
        break;

      case TokenTypes.InviteEvent:
        entityUrl = environment.jipUrl.event;
        break;
    }
    return entityUrl;
  }

  public static stripHtml(sanitizeHtml: string): string {
    const temp = document.createElement('DIV');
    temp.innerHTML = sanitizeHtml;
    return temp.textContent || temp.innerText || '';
  }

  public static stripAttributesFromHtmlString(
    sanitizeHtml: string,
    attributesToRemove: string[] = ATTRIBUTES_TO_REMOVE
  ): string {
    if (attributesToRemove?.length > 0) {
      const temp = document.createElement('DIV');
      temp.innerHTML = sanitizeHtml;
      const allChilds = temp.getElementsByTagName('*');
      Array.from(allChilds).forEach((child: Element) => {
        attributesToRemove.forEach((attr: string) => {
          child.removeAttribute(attr);
        });
      });
      return temp.innerHTML;
    }
    return sanitizeHtml;
  }

  public static replaceTagFromHtmlString(
    sanitizeHtml: string,
    tags: string[],
    replaceTag: string
  ): string {
    const temp = document.createElement('DIV');
    temp.innerHTML = sanitizeHtml;
    tags?.forEach((tag: string) => {
      const allTags = temp.getElementsByTagName(tag);
      Array.from(allTags).forEach((tagEl: Element) => {
        const replaceTagEl = document.createElement(replaceTag);
        replaceTagEl.innerHTML = tagEl.innerHTML;
        tagEl.replaceWith(replaceTagEl);
      });
    });
    return temp.innerHTML;
  }

  public static replaceAll(
    text: string,
    find: string,
    replace: string
  ): string {
    return text.replace(new RegExp(find, 'g'), replace);
  }

  public static getIconByType(name: string, iconType: IconType): string {
    return name && iconType
      ? `${environment.wordpressJipImages}icons/${name}.${iconType}`
      : '';
  }

  public static isRTEContentMaxlengthError(
    text: string,
    maxlength: number
  ): boolean {
    return maxlength > 0 && text?.length > maxlength;
  }

  public static removeLineBreakFromTextArea(sanitizeHtml: string): string {
    return sanitizeHtml ? sanitizeHtml.replace(/(\r\n|\n|\r)/gm, '') : '';
  }

  public static getLocaleFromUrl(): string | null {
    const paths = location.pathname.split('/');

    return paths[1] || null; // first sub path
  }

  public static isString(content: unknown): boolean {
    return typeof content === 'string';
  }

  public static getCorrectUrl(unknownUrlSrc: string & LinkInterface): string {
    if (unknownUrlSrc?.url === undefined) {
      return unknownUrlSrc;
    }
    if (unknownUrlSrc.url === null) {
      return '';
    }
    return unknownUrlSrc.url;
  }

  public static generateLinkedinLoginLink(
    pathName: string,
    clientId: string,
    redirectUri: string
  ): string {
    return `${pathName}/?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=r_liteprofile%20r_emailaddress`;
  }

  public static extractContent(html: string): string {
    return new DOMParser().parseFromString(html, 'text/html').documentElement
      .textContent;
  }

  public static prefixUrl(urlValue: string): string {
    if (!urlValue) return '';
    return urlValue.includes('http') ? urlValue : `http://${urlValue}`;
  }

  public static buildEntityWithLinkText(linkText: string, url: string): string {
    return url ? `${linkText} (${url})` : linkText;
  }

  public static getEntityNameByUrl(url: string): string | EntityName {
    const { jipUrl } = environment;

    if (url.includes(jipUrl.organizations)) {
      return EntityName.Organization;
    }

    if (url.includes(jipUrl.challenges)) {
      return EntityName.Challenge;
    }

    if (url.includes(jipUrl.venture)) {
      return EntityName.Venture;
    }

    if (url.includes(jipUrl.event)) {
      return EntityName.Event;
    }

    if (url.includes(jipUrl.people)) {
      return EntityName.Person;
    }

    return EntityName.All;
  }
}
