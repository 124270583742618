import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, NgModel } from '@angular/forms';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { ImageInterface } from '@src/app/shared/interfaces/file.interface';
import { SocialMedia } from '@src/app/shared/interfaces/people.interface';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SocialLinkFigureComponent } from '../social-link-figure/social-link-figure.component';

@Component({
  selector: 'app-social-link-item',
  templateUrl: './social-link-item.component.html',
})
export class SocialLinkItemComponent implements OnDestroy {
  @Input() data: SocialMedia;
  @Input() placeholder: string;
  @Input() regex: string;
  @Input() addBtnClicked: boolean;
  @Input() formControlLinkItem: AbstractControl;
  @Input() firstLoadInput!: boolean;

  @Output() addLinkEvent = new EventEmitter();

  @ViewChild('inputName') inputNameTag!: ElementRef;
  @ViewChild(SocialLinkFigureComponent)
  socialLinkFigureComp: SocialLinkFigureComponent;
  isLoadingSocialIcon = false;
  xDomain = 'x.com';

  constructor(private http: PeopleHttpService) {}

  addLink(event: Event, nameInput: NgModel, linkInput: NgModel): void {
    event.stopPropagation();
    event.preventDefault();

    if (
      this.isValid([nameInput, linkInput]) &&
      !this.isLoadingSocialIcon &&
      this.data.socialMediaLogo
    ) {
      this.addLinkEvent.emit();

      if (this.inputNameTag) {
        this.inputNameTag.nativeElement.focus();
      }

      this.firstLoadInput = true;
    }
  }

  isValid(inputs: any[]): boolean {
    let isValidInputs = true;
    this.firstLoadInput = false;

    if (inputs.length) {
      inputs.forEach((input) => {
        if (input?.control?.invalid) {
          if (input?.control?.hasError('pattern')) {
            this.formControlLinkItem.setErrors({ pattern: true });
          } else {
            this.formControlLinkItem.setErrors(input.control.errors);
          }

          isValidInputs = false;
        }
      });
    }

    if (isValidInputs) {
      this.formControlLinkItem.setErrors(null);
    }
    return isValidInputs;
  }

  changeSourceUrl(image: ImageInterface): void {
    this.isLoadingSocialIcon = false;
    this.data.socialMediaLogoId = image.id;
    this.data.socialMediaLogo = image.url;
  }

  onLinkChange(value: string): void {
    const socialMedias =
      JSON.parse(localStorage.getItem(StorageEnum.socialMediaResources)) || [];
    const socialMediaKeys = socialMedias.map((item) => item.socialMedia);
    const keys = socialMediaKeys.filter((key) => isNaN(Number(key)));

    const foundKey = keys.find((name) => {
      if (name === this.xDomain) {
        return this.isXSocialMedia(value);
      } else {
        // Product Owner requires matches to include 'facebook.', 'twitter.', etc
        return value
          .toLocaleLowerCase()
          .includes(`${name.toLocaleLowerCase()}.`);
      }
    });

    if (!foundKey) {
      this.uploadFavicon(value)
        .pipe(
          debounceTime(300),
          switchMap((res) => of(res)),
          untilDestroyed(this)
        )
        .subscribe({
          next: (res: SocialMedia[]) => {
            this.data.socialMediaLogoId = res[0].socialMediaLogoId;
            this.data.socialMediaLogo = res[0].socialMediaLogo;
            return;
          },
          error: () => {
            this.data.socialMediaLogoId = 0;
            this.data.socialMediaLogo = null;
            return;
          },
        });
    }

    const socialIcon = socialMedias.find(
      (item) => item.socialMedia === foundKey
    );
    if (socialIcon) {
      this.data.socialMediaLogoId = socialIcon.id;
      this.data.socialMediaLogo = socialIcon.url;
    }
  }

  isXSocialMedia(value: string): boolean {
    const xUrls = ['https://x.com', 'https://www.x.com', 'www.x.com'];
    const xDomainRegex = /^(https?:\/\/)?(www\.)?x\.com/i;

    if (xDomainRegex.test(value)) {
      return true;
    }

    return xUrls.some((url) => value.toLowerCase().includes(url));
  }

  getFaviconUrl(domain: string): string {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=128`;
  }

  uploadFavicon(domain: string): Observable<SocialMedia> {
    const url = this.getFaviconUrl(domain);
    return this.http.uploadFavicon([url]);
  }

  ngOnDestroy(): void {
    /** */
  }
}
