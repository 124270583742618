import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { SessionService } from '@src/app/core/session.service';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceClick]',
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input() debounceTime = 1000;
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {
    this.subscription = this.clicks
      .pipe(untilDestroyed(this), throttleTime(this.debounceTime))
      .subscribe((e) => {
        if (
          this.sessionService.isLogin &&
          this.sessionService.isTokenExpired()
        ) {
          return;
        }
        
        this.debounceClick.emit(e);
      });
  }
  @HostListener('click', ['$event'])
  clickEvent(event): void {
    event.preventDefault();
    event.stopPropagation();

    this.clicks.next(event);
  }

  ngOnDestroy(): void {
    /**/
  }
}
