import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QUILL_FORMATS, QUILL_MODULES } from '@app/configs/ngx-quill.config';
import { FormLoginComponent } from '@components/auth/login/form-login/form-login.component';
import { LoginWithLinkedinBtnComponent } from '@components/auth/login/login-with-linkedin-btn/login-with-linkedin-btn.component';
import { JoinEventComponent } from '@components/entity-section/interaction-bar/join-interact/join-event/join-event.component';
import { InvitationByEmailComponent } from '@components/entity-section/invitation-by-email/invitation-by-email.component';
import { EmailInputComponent } from '@components/event-registration/components/email-input/email-input.component';
import { ModalWrapperComponent } from '@components/form/modal-wrapper/modal-wrapper.component';
import { CardActionComponent } from '@components/news-messages/components/card-action/card-action.component';
import { InitatorCardsComponent } from '@components/peoples/components/people-cards/initator-cards/initator-cards.component';
import { PeopleCardsComponent } from '@components/peoples/components/people-cards/people-cards.component';
import { StakeholderCardsComponent } from '@components/peoples/components/people-cards/stakeholder-cards/stakeholder-cards.component';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import {
  NgbAccordionModule,
  NgbButtonsModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbPopoverModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CollapsibleHintComponent } from '@shared/components/atoms/collapsible-hint/collapsible-hint.component';
import { CopyTextComponent } from '@shared/components/atoms/copy-text/copy-text.component';
import { EntityCreationButtonComponent } from '@shared/components/atoms/entity-creation-button/entity-creation-button.component';
import { ImageInputComponent } from '@shared/components/atoms/image-input/image-input.component';
import { ReadMoreComponent } from '@shared/components/atoms/read-more/read-more.component';
import { TextareaComponent } from '@shared/components/form-custom-attributes/textarea/textarea.component';
import { ChallengeCardFooterComponent } from '@shared/components/molecules/challenge-card-footer/challenge-card-footer.component';
import { ImageUploaderDialogComponent } from '@shared/components/molecules/image-uploader-dialog/image-uploader-dialog.component';
import { NewsCardFooterComponent } from '@shared/components/molecules/news-card-header/news-card-footer/news-card-footer.component';
import { NewsCardHeaderComponent } from '@shared/components/molecules/news-card-header/news-card-header.component';
import { CardLayoutComponent } from '@shared/components/molecules/simple-card/card-layout.component';
import { TagFieldDropdownItemComponent } from '@shared/components/molecules/tag-field-dropdown-item/tag-field-dropdown-item.component';
import { TagFieldExpandedComponent } from '@shared/components/molecules/tag-field-expanded/tag-field-expanded.component';
import { ChallengeCardListComponent } from '@shared/components/organisms/challenge-card-list/challenge-card-list.component';
import { ChangeNdaInformModalComponent } from '@shared/components/organisms/change-nda-inform-modal/change-nda-inform-modal.component';
import { SubmitVenturesComponent } from '@shared/components/organisms/submit-ventures/submit-ventures.component';
import { VentureListComponent } from '@shared/components/organisms/venture-list/venture-list.component';
import { VentureSubmitionInformModalComponent } from '@shared/components/organisms/venture-submition-inform-modal/venture-submition-inform-modal.component';
import { ReportingChartComponent } from '@shared/components/reporting-chart/reporting-chart.component';
import { EllipsisDirective } from '@shared/directives/ellipsis.directive';
import { CallPipe } from '@shared/pipes/call.pipe';
import { EntityDisplayTextPipe } from '@shared/pipes/entity-display-text.pipe';
import { EventBindingVariablesPipe } from '@shared/pipes/event-binding-variables.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { StarPipe } from '@shared/pipes/star.pipe';
import { VisibilityPipe } from '@shared/pipes/visibility.pipe';
import { DeactivateProfileDialogComponent } from '@src/app/components/dialogs/deactivate-profile-dialog/deactivate-profile-dialog.component';
import { FilterPaneOrganizationComponent } from '@src/app/components/entity-section/filter-pane/filter-pane-organization/filter-pane-organization/filter-pane-organization.component';
import { MetadataTablePartnerOrganizationComponent } from '@src/app/shared/components/metadata-table/metadata-table-partner-organization/metadata-table-partner-organization.component';
import { PeopleCardListOrgAdminComponent } from '@src/app/shared/components/people-card-list/people-card-list-org-admin/people-card-list-org-admin/people-card-list-org-admin.component';
import { environment } from '@src/environments/environment';
import { NgxDatatableModule } from '@siemens/ngx-datatable';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgChartsModule } from 'ng2-charts';
import { TagInputModule } from 'ngx-chips';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { JuryInvitationDialogComponent } from 'src/app/shared/components/molecules/jury-invitaion-dialog/jury-invitation-dialog.component';
import { JurySectionComponent } from 'src/app/shared/components/templates/juror-section/jury-section.component';
import { AdvancedEditButtonComponent } from './components/atoms/advanced-edit-button/advanced-edit-button.component';
import { DeactivatedOrganizationWarningComponent } from './components/atoms/deactivated-organization-warning/deactivated-organization-warning.component';
import { EventExternalRegisterButtonComponent } from './components/atoms/event-external-register-button/event-external-register-button.component';
import { LanguageIndicatorComponent } from './components/atoms/language-indicator/language-indicator.component';
import { MailForwardingCheckboxComponent } from './components/atoms/mail-forwarding-checkbox/mail-forwarding-checkbox.component';
import { TermAgreementCheckboxComponent } from './components/atoms/term-agreement-checkbox/term-agreement-checkbox.component';
import { AttachmentsUploadComponent } from './components/attachments-upload/attachments-upload.component';
import { BoostrapDatepickerComponent } from './components/boostrap-datepicker/boostrap-datepicker.component';
import { BoostrapDropdownComponent } from './components/boostrap-dropdown/boostrap-dropdown.component';
import { BootstrapPaginationComponent } from './components/bootstrap-pagination/bootstrap-pagination.component';
import { BotFlagComponent } from './components/bot-flag/bot-flag.component';
import { BtnCreateTimestampComponent } from './components/btn-create-timestamp/btn-create-timestamp.component';
import { BtnDirectMessageComponent } from './components/btn-direct-message/btn-direct-message.component';
import { CheckBoxConfirmationComponent } from './components/check-box/check-box-confirmation/check-box-confirmation.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { CommentEntityComponent } from './components/comments-modal/comment-entity/comment-entity.component';
import { CommentComponent } from './components/comments-modal/comment/comment.component';
import { CommentsModalComponent } from './components/comments-modal/comments-modal.component';
import { CommentsComponent } from './components/comments-modal/comments/comments.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DeactiveEntityComponent } from './components/deactive-entity/deactive-entity.component';
import { EmptyContentComponent } from './components/empty-content/empty-content.component';
import { EntityStateCountComponent } from './components/entity-state-count/entity-state-count.component';
import { EntityViewModalComponent } from './components/entity-view-modal/entity-view-modal.component';
import { EventLocationFlagComponent } from './components/event-location-flag/event-location-flag.component';
import { EventStampComponent } from './components/event-stamp/event-stamp.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DynamicObjectComponent } from './components/form-custom-attributes/dynamic-object/dynamic-object.component';
import { FormFieldCustomComponent } from './components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { LinkComponent } from './components/form-custom-attributes/link/link.component';
import { RangeSliderComponent } from './components/form-custom-attributes/range-slider/range-slider.component';
import { FormSectionComponent } from './components/form-section/form-section.component';
import { GoogleMapAutoCompleteComponent } from './components/google-map-autocomplete/google-map-autocomplete.component';
import { GoogleMapViewComponent } from './components/google-map-view/google-map-view.component';
import { HiddenContentComponent } from './components/hidden-content/hidden-content.component';
import { ImageWrapperComponent } from './components/image-wrapper/image-wrapper.component';
import { InternalIconComponent } from './components/internal-icon/internal-icon.component';
import { KeyOfferModalComponent } from './components/key-offer-modal/key-offer-modal.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LikeEntityComponent } from './components/like-modal/like-entity/like-entity.component';
import { LikeModalComponent } from './components/like-modal/like-modal.component';
import { LikesModalComponent } from './components/likes-modal/likes-modal.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoadingWrapperComponent } from './components/loading-wrapper/loading-wrapper.component';
import { MessageHeaderWrapperComponent } from './components/message-header-wrapper/message-header-wrapper.component';
import { CellEditRelatedEntityComponent } from './components/metadata-table-cell-edit/cell-edit-related-entity/cell-edit-related-entity.component';
import { CellViewRelatedEntityComponent } from './components/metadata-table-cell-edit/cell-view-related-entity/cell-view-related-entity.component';
import { MetadataTableCellEditComponent } from './components/metadata-table-cell-edit/metadata-table-cell-edit.component';
import { MetadataTableChallengeComponent } from './components/metadata-table/metadata-table-challenge/metadata-table-challenge.component';
import { MetadataTableEventComponent } from './components/metadata-table/metadata-table-event/metadata-table-event.component';
import { MetadataTableOrganizationComponent } from './components/metadata-table/metadata-table-organization/metadata-table-organization.component';
import { MetadataTablePeopleComponent } from './components/metadata-table/metadata-table-people/metadata-table-people.component';
import { MetadataTableVentureComponent } from './components/metadata-table/metadata-table-venture/metadata-table-venture.component';
import { MetadataTableComponent } from './components/metadata-table/metadata-table.component';
import { EventCardFooterComponent } from './components/molecules/challenge-card-footer/event-card-footer/event-card-footer.component';
import { EmojisPickerComponent } from './components/molecules/emojis-picker/emojis-picker.component';
import { EntityCounterComponent } from './components/molecules/entity-counter/entity-counter.component';
import { ImageInputDisplayComponent } from './components/molecules/image-input-display/image-input-display.component';
import { JuryMenuTabsBarComponent } from './components/molecules/jury-menu-tabs-bar/jury-menu-tabs-bar.component';
import { JuryRadioButtonComponent } from './components/molecules/jury-radio-button/jury-radio-button.component';
import { OpenForComponent } from './components/molecules/open-for/open-for.component';
import { OrganizationLogoComponent } from './components/molecules/organization-logo/organization-logo.component';
import { OrganizationSettingDialogComponent } from './components/molecules/organization-setting-dialog/organization-setting-dialog.component';
import { ShareViaEmailComponent } from './components/molecules/share-via-email/share-via-email.component';
import { TermOfUseComponent } from './components/molecules/term-of-use/term-of-use.component';
import { VentureStagesMenuBarComponent } from './components/molecules/venture-stages-menu-bar/venture-stages-menu-bar.component';
import { MoodboardGroupComponent } from './components/moodboard-group/moodboard-group.component';
import { MultiLinkComponent } from './components/multi-link/multi-link.component';
import { MultiSelectionComponent } from './components/multi-selection/multi-selection.component';
import { OrganisationSearchComponent } from './components/organisation-search/organisation-search.component';
import { SimpleFormLoginComponent } from './components/organisms/simple-form-login/simple-form-login.component';
import { OrganizationCardListComponent } from './components/organization-card-list/organization-card-list.component';
import { OrganizationChallengeViewComponent } from './components/organization-challenge-view/organization-challenge-view.component';
import { OrganizerComponent } from './components/organizer/organizer.component';
import { PartnerStatusComponent } from './components/partner-status/partner-status.component';
import { PendingPeopleModalComponent } from './components/pending-people-modal/pending-people-modal.component';
import { SharedPendingPeopleComponent } from './components/pending-people-modal/shared-pending-people/shared-pending-people.component';
import { PeopleCardListComponent } from './components/people-card-list/people-card-list.component';
import { PeopleInfoSmallComponent } from './components/people-card-list/people-info-small/people-info-small.component';
import { PeopleModalComponent } from './components/people-modal/people-modal.component';
import { PrivacyFlagComponent } from './components/privacy-flag/privacy-flag.component';
import { QrCodeModalComponent } from './components/qr-code-modal/qr-code-modal.component';
import { RichTextToggleComponent } from './components/rich-text-toggle/rich-text-toggle.component';
import { SearchPeopleBoxComponent } from './components/search-people-box/search-people-box.component';
import { SendMessageBtnComponent } from './components/send-message-btn/send-message-btn.component';
import { SharedEventsClientComponent } from './components/shared-events/shared-events-client/shared-events-client.component';
import { SharedEventsComponent } from './components/shared-events/shared-events.component';
import { SharedIconComponent } from './components/shared-icon/shared-icon.component';
import { SharedVentureItemComponent } from './components/shared-ventures/shared-venture-item/shared-venture-item.component';
import { SharedVenturesClientComponent } from './components/shared-ventures/shared-ventures-client/shared-ventures-client.component';
import { SharedVenturesComponent } from './components/shared-ventures/shared-ventures.component';
import { SocialLinkFigureComponent } from './components/social-link/social-link-figure/social-link-figure.component';
import { SocialLinkItemComponent } from './components/social-link/social-link-item/social-link-item.component';
import { SocialLinkComponent } from './components/social-link/social-link.component';
import { SwitchViewControlComponent } from './components/switch-view-control/switch-view-control.component';
import { TagFieldAutocompleteComponent } from './components/tag-field/tag-field-autocomplete/tag-field-autocomplete.component';
import { TagFieldComponent } from './components/tag-field/tag-field.component';
import { TimelineCurrentPhaseComponent } from './components/timeline/timeline-current-phase/timeline-current-phase.component';
import { TimelineViewComponent } from './components/timeline/timeline-view/timeline-view.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TreeViewCheckBoxComponent } from './components/tree-view-check-box/tree-view-check-box.component';
import { VentureOrganizationsComponent } from './components/venture-organizations/venture-organizations.component';
import { VenturePeopleComponent } from './components/venture-people/venture-people.component';
import { VenturePhaseDetailComponent } from './components/venture-phase-detail/venture-phase-detail.component';
import { VentureStateComponent } from './components/venture-state/venture-state.component';
import { VenturesModalComponent } from './components/ventures-modal/ventures-modal.component';
import { SearchInputWrapperComponent } from './components/wrapper/search-input-wrapper/search-input-wrapper.component';
import { ZoomOutImgModalComponent } from './components/zoom-out-img-modal/zoom-out-img-modal.component';
import { ActiveRouteDirective } from './directives/active-route.directive';
import { AddToCalendarDirective } from './directives/add-to-calendar.directive';
import { ClickPreventDefaultDirective } from './directives/click-prevent-default.directive';
import { CountUpDirective } from './directives/count-up.directive';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { DefaultAvatarDirective } from './directives/default-avatar.directive';
import { DefaultImageDirective } from './directives/default-image.directive';
import { DefaultPlaceholderDirective } from './directives/default-placeholder.directive';
import { DynamicImgUrlDirective } from './directives/dynamic-img-url.directive';
import { HoverDisplayDirective } from './directives/hover-display.directive';
import { LanguagePathDirective } from './directives/language-path.directive';
import { LeaveEntityDirective } from './directives/leave-entity.directive';
import { PagingItemDirective } from './directives/paging-item.directive';
import { RequireLoginToCreateDirective } from './directives/require-login-to-create.directive';
import { RequireLoginDirective } from './directives/require-login.directive';
import { ScrollToFragmentDirective } from './directives/scroll-to-fragment.directive';
import { SmoothHeightDirective } from './directives/smooth-height.directive';
import { ToggleDisplayDirective } from './directives/toggle-display.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { UnsavedFormCheckDirective } from './directives/unsaved-form-check.directive';
import { UrlPrefixDirective } from './directives/url-prefix.directive';
import { AttributeValuePipe } from './pipes/attribute-value.pipe';
import { Base64ImagePipe } from './pipes/base64-image.pipe';
import { ChallengeOrganizationUrlPipe } from './pipes/challenge-org-url.pipe';
import { ConcatNamePipe } from './pipes/concat-name.pipe';
import { DescriptionValuePipe } from './pipes/description-value.pipe';
import {
  EventRegisterBtnDisabledPipe,
  EventRegisterTooltipPipe,
} from './pipes/event-register-tooltip.pipe';
import { FieldLabelPipe } from './pipes/field-label.pipe';
import {
  FieldOptionPipe,
  FieldSingleOptionPipe,
} from './pipes/field-option.pipe';
import { JointStateMapperPipe } from './pipes/joint-state-mapper.pipe';
import { JsonParsePipe } from './pipes/json-parse.pipe';
import { KczTranslationKeyPipe } from './pipes/kcz-translation-key.pipe';
import {
  EmailWrapperPipe,
  LinkWrapperPipe,
  SpanWrapperPipe,
} from './pipes/link-wrapper.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { ParenthesesPipe } from './pipes/parentheses.pipe';
import { StringReplacementPipe } from './pipes/string-replacement.pipe';
import { YouTransformPipe } from './pipes/you-transform.pipe';
import { ToggleButtonSliderComponent } from './toggle-slider/toggle-button-slider/toggle-button-slider.component';
import { ToggleSliderComponent } from './toggle-slider/toggle-slider.component';
import { NavItemDropdownComponent } from '@src/app/components/master-layout/navs/components/nav-item-dropdown/nav-item-dropdown.component';
import { RadioSelectionComponent } from '@src/app/shared/components/form-custom-attributes/radio-selection/radio-selection.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { RadioBooleanComponent } from '@src/app/shared/components/atoms/radio-boolean/radio-boolean.component';
import { ServiceNotAvailableDialogComponent } from '../components/dialogs/service-not-available-dialog/service-not-available-dialog.component';
import { EnforceProfileDialogComponent } from '@src/app/components/dialogs/enforce-profile-dialog/enforce-profile-dialog.component';
import { EnforceProfileComponent } from '@src/app/components/peoples/components/people/enforce-profile/enforce-profile.component';
import { PersonOrganisationsComponent } from '@src/app/components/peoples/components/people/people-edit/person-organisations/person-organisations.component';
import { ListLayoutWrapperComponent } from '@src/app/components/master-layout/list-layout-wrapper/list-layout-wrapper.component';
import { CommunitySelectorComponent } from '@src/app/components/master-layout/list-layout-wrapper/components/community-selector/community-selector.component';
import { GlobalFilterComponent } from '@src/app/components/master-layout/navs/components/global-filter/global-filter.component';
import { TagFieldWithSuggestionComponent } from '@src/app/shared/components/molecules/tag-field-with-suggestion/tag-field-with-suggestion.component';
import { LongPressDirective } from '@src/app/shared/directives/long-press.directive';
import { SomethingWrongDialogComponent } from '@src/app/components/dialogs/something-wrong-dialog/something-wrong-dialog.component';
const DECLARE_AND_EXPORT = [
  ToggleDisplayDirective,
  KeyOfferModalComponent,
  JuryRadioButtonComponent,
  DebounceClickDirective,
  DefaultImageDirective,
  ParenthesesPipe,
  TooltipDirective,
  ActiveRouteDirective,
  DefaultAvatarDirective,
  DefaultPlaceholderDirective,
  BoostrapDropdownComponent,
  BoostrapDatepickerComponent,
  BootstrapPaginationComponent,
  ToggleSliderComponent,
  GoogleMapViewComponent,
  GoogleMapAutoCompleteComponent,
  FormSectionComponent,
  TagFieldComponent,
  ImageWrapperComponent,
  ConcatNamePipe,
  CommentsModalComponent,
  SharedEventsComponent,
  PeopleModalComponent,
  PeopleCardListComponent,
  MultiSelectionComponent,
  MultiLinkComponent,
  SharedVenturesComponent,
  VenturePhaseDetailComponent,
  FormFieldCustomComponent,
  VentureStateComponent,
  FileUploadComponent,
  AttachmentsUploadComponent,
  MoodboardGroupComponent,
  VenturePeopleComponent,
  VenturesModalComponent,
  SocialLinkFigureComponent,
  SocialLinkComponent,
  PendingPeopleModalComponent,
  SharedPendingPeopleComponent,
  PeopleInfoSmallComponent,
  OrganisationSearchComponent,
  SocialLinkItemComponent,
  RequireLoginDirective,
  UrlPrefixDirective,
  StringReplacementPipe,
  LinkWrapperPipe,
  EmailWrapperPipe,
  SpanWrapperPipe,
  YouTransformPipe,
  CallPipe,
  LanguagePathDirective,
  UnsavedFormCheckDirective,
  RichTextToggleComponent,
  HiddenContentComponent,
  TagFieldAutocompleteComponent,
  ToggleButtonSliderComponent,
  LoadingWrapperComponent,
  LikesModalComponent,
  CopyTextComponent,
  HoverDisplayDirective,
  ZoomOutImgModalComponent,
  CheckBoxComponent,
  TimelineCurrentPhaseComponent,
  TimelineViewComponent,
  TreeViewCheckBoxComponent,
  SearchInputWrapperComponent,
  TimelineComponent,
  DynamicObjectComponent,
  FieldOptionPipe,
  FieldSingleOptionPipe,
  FieldLabelPipe,
  DynamicImgUrlDirective,
  SharedIconComponent,
  EntityStateCountComponent,
  DeactiveEntityComponent,
  PartnerStatusComponent,
  CheckBoxConfirmationComponent,
  MetadataTableComponent,
  SwitchViewControlComponent,
  MetadataTableVentureComponent,
  MetadataTableOrganizationComponent,
  MetadataTableEventComponent,
  MetadataTablePeopleComponent,
  MetadataTableChallengeComponent,
  BtnDirectMessageComponent,
  MetadataTableCellEditComponent,
  CellEditRelatedEntityComponent,
  BtnCreateTimestampComponent,
  LocalizedDatePipe,
  AddToCalendarDirective,
  InternalIconComponent,
  OrganizationChallengeViewComponent,
  VentureOrganizationsComponent,
  CellViewRelatedEntityComponent,
  OrganizationCardListComponent,
  OrganizerComponent,
  LeaveEntityDirective,
  EventStampComponent,
  EventLocationFlagComponent,
  ClickPreventDefaultDirective,
  PrivacyFlagComponent,
  CommentComponent,
  MessageHeaderWrapperComponent,
  LikeEntityComponent,
  LikeModalComponent,
  CommentsComponent,
  CommentEntityComponent,
  SendMessageBtnComponent,
  SharedVentureItemComponent,
  EntityViewModalComponent,
  SharedEventsClientComponent,
  SharedVenturesClientComponent,
  LoadingIndicatorComponent,
  DateFilterComponent,
  EmojisPickerComponent,
  TextareaComponent,
  LinkComponent,
  CollapsibleHintComponent,
  ImageInputComponent,
  ImageUploaderDialogComponent,
  SearchPeopleBoxComponent,
  RangeSliderComponent,
  LanguageIndicatorComponent,
  LanguageSwitcherComponent,
  OrganizationLogoComponent,
  ShareViaEmailComponent,
  TagFieldExpandedComponent,
  TagFieldDropdownItemComponent,
  VentureStagesMenuBarComponent,
  TermOfUseComponent,
  SafeHtmlPipe,
  SubmitVenturesComponent,
  VentureListComponent,
  ChallengeCardListComponent,
  VentureSubmitionInformModalComponent,
  ModalWrapperComponent,
  ChangeNdaInformModalComponent,
  ReportingChartComponent,
  ReadMoreComponent,
  EllipsisDirective,
  ChallengeCardFooterComponent,
  NewsCardHeaderComponent,
  CardLayoutComponent,
  CardActionComponent,
  MailForwardingCheckboxComponent,
  NewsCardFooterComponent,
  EntityDisplayTextPipe,
  ChallengeOrganizationUrlPipe,
  TermAgreementCheckboxComponent,
  EmailInputComponent,
  OpenForComponent,
  VisibilityPipe,
  CountUpDirective,
  EntityCounterComponent,
  EntityCreationButtonComponent,
  JoinEventComponent,
  EventCardFooterComponent,
  JointStateMapperPipe,
  JsonParsePipe,
  EventExternalRegisterButtonComponent,
  AttributeValuePipe,
  DescriptionValuePipe,
  ImageInputDisplayComponent,
  EventBindingVariablesPipe,
  SmoothHeightDirective,
  SimpleFormLoginComponent,
  FormLoginComponent,
  LoginWithLinkedinBtnComponent,
  InitatorCardsComponent,
  StakeholderCardsComponent,
  PeopleCardsComponent,
  JurySectionComponent,
  JuryInvitationDialogComponent,
  JuryMenuTabsBarComponent,
  DeactivatedOrganizationWarningComponent,
  InvitationByEmailComponent,
  Base64ImagePipe,
  AdvancedEditButtonComponent,
  KczTranslationKeyPipe,
  ScrollToFragmentDirective,
  OrganizationSettingDialogComponent,
  StarPipe,
  QrCodeModalComponent,
  PagingItemDirective,
  EventRegisterTooltipPipe,
  EventRegisterBtnDisabledPipe,
  RequireLoginToCreateDirective,
  BotFlagComponent,
  EmptyContentComponent,
  PeopleCardListOrgAdminComponent,
  FilterPaneOrganizationComponent,
  DeactivateProfileDialogComponent,
  MetadataTablePartnerOrganizationComponent,
  NavItemDropdownComponent,
  RadioSelectionComponent,
  RadioBooleanComponent,
  ServiceNotAvailableDialogComponent,
  EnforceProfileDialogComponent,
  EnforceProfileComponent,
  PersonOrganisationsComponent,
  ListLayoutWrapperComponent,
  CommunitySelectorComponent,
  GlobalFilterComponent,
  TagFieldWithSuggestionComponent,
  LongPressDirective,
  SomethingWrongDialogComponent,
];

const RE_EXPORT_MODULES = [
  ClickOutsideModule,
  CommonModule,
  EmojiModule,
  FormsModule,
  HttpClientModule,
  NgbAccordionModule,
  NgbButtonsModule,
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTimepickerModule,
  NgbToastModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
  NgxDatatableModule,
  NgxFileDropModule,
  ReactiveFormsModule,
  TagInputModule,
  TranslateModule,
  VirtualScrollerModule,
  NgbPopoverModule,
  NgSelectModule,
  ImageCropperModule,
  NgChartsModule,
];
@NgModule({
  imports: [
    ...RE_EXPORT_MODULES,
    HttpClientJsonpModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot({
      modules: QUILL_MODULES,
      formats: QUILL_FORMATS,
    }),
    NgMapsCoreModule,
    NgMapsGoogleModule,
    NgSelectModule,
    NgChartsModule,
    AngularCropperjsModule,
  ],
  providers: [
    DatePipe,
    LinkWrapperPipe,
    SpanWrapperPipe,
    YouTransformPipe,
    StringReplacementPipe,
    LocalizedDatePipe,
    DescriptionValuePipe,
    AttributeValuePipe,
    Base64ImagePipe,
    EventRegisterBtnDisabledPipe,
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: environment.googleMapApiKey,
        libraries: ['places'],
      },
    },
  ],
  declarations: [...DECLARE_AND_EXPORT],
  exports: [
    ...RE_EXPORT_MODULES,
    ...DECLARE_AND_EXPORT,
    QuillModule,
    NgxMaskModule,
  ],
})
export class SharedModule {}
