import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { FactoryService } from '@src/app/core/services/factory.service';
import { maxPageSize } from '@src/app/shared/constants/common';
import { DeactiveShowType } from '@src/app/shared/enums/deactive-show-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { AttributeDescription, AttributeValue, CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { environment } from '@src/environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-people-view-modal',
  templateUrl: './people-view-modal.component.html'
})
export class PeopleViewModalComponent implements OnInit, OnDestroy {
  @Input() entityName: EntityName;
  @Input() entityId: number;
  @Input() allowEditRole = false;

  isLoadingEntity = false;
  httpService: BaseHttpService<Entity> = null;
  entity: VentureInterface;
  stakeholders: UserInterface[] = [];
  initiatorAttrDes: AttributeDescription;
  stakeholdersAttrDes: AttributeDescription;

  customUserRoles: CustomAtrributeValue[] = [];
  customAdminRoles: CustomAtrributeValue[] = [];
  DeactiveShowType = DeactiveShowType;

  constructor(
    public activeModal: NgbActiveModal,
    private factoryService: FactoryService
  ) { }

  ngOnInit(): void {
    this.httpService = this.factoryService.createServiceByEntityName(EntityName.Venture);
    this.getEntityDetail();
  }

  private getEntityDetail(): void {
    if (!this.httpService) {
      return;
    }

    this.isLoadingEntity = true;
    this.httpService
      .read(this.entityId)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoadingEntity = false;
        })
      )
      .subscribe((entity: VentureInterface) => {
        if (!entity || !entity.id) {
          return;
        }
        this.entity = entity;
        const lstAttrDes = entity.attributeDescriptions;
        const lstAttrVal = entity.attributeValues;

        this.initiatorAttrDes = lstAttrDes.find(x => x.propertyName === 'Initiator');
        this.stakeholdersAttrDes = lstAttrDes.find(x => x.propertyName === 'Stakeholders');

        this.handleAdminCustomRole(lstAttrDes, lstAttrVal);
        this.loadStakeholders();
        this.loadStakeholdersCustomRole();
      });
  }

  private loadStakeholders(): void {
    this.httpService
      .paginateByEntity(environment.jipUrl.stakeholders, this.entityId, {
        pageSize: maxPageSize,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.stakeholders = res?.items;
      });
  }

  private loadStakeholdersCustomRole(): void {
    this.customUserRoles = [];

    this.httpService.getStakeHolderCustomAttribute(this.entityId)
      .pipe(untilDestroyed(this))
      .subscribe(
        (lstAttrDes: AttributeDescription[]) => {
          const roleAttrDes = lstAttrDes?.find(x => x.propertyName === 'Role');
          this.customUserRoles = roleAttrDes ? roleAttrDes.choice?.selections : [];
        });
  }

  private handleAdminCustomRole(lstAttrDes: AttributeDescription[] = [], lstAttrVal: AttributeValue[] = []): void {
    this.customAdminRoles = [];
    if (lstAttrDes.length > 0) {
      const adminRolePropertyName = 'AdminRole';
      const adminRoleAttrDes = lstAttrDes.find(x => x.propertyName === adminRolePropertyName);
      if (adminRoleAttrDes) {
        this.customAdminRoles = adminRoleAttrDes.choice.selections;
        const adminRoleAttrVal = lstAttrVal.find(x => x.propertyName === adminRolePropertyName);
        if (this.entity.initiator) {
          this.entity.initiator.role = adminRoleAttrVal?.describedValue.selection ?? adminRoleAttrDes.defaultValue;
        }
      }
    }

  }

  ngOnDestroy(): void { /**/ }
}
