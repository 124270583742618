<div class="entity-counter d-flex flex-column align-items-center">
  <h1
    class="mb-0 font-weight-500 text-primary widget__heading"
    [countUp]="count$ | async"
    [duration]="2000"
    [attr.style]="'color:' + number_color + ' !important'"
  ></h1>
  <span
    class="font-size-base font-weight-500 widget__label"
    [attr.style]="'color:' + display_text_color + ' !important'"
  >
    {{ display_text || (entity_name | translate) }}
  </span>
</div>
