<ng-container *ngIf="lstTimestamp.length > 0; else noData">
  <div class="metadata-table">
    <ngx-datatable
      class="material"
      [rows]="lstTimestamp"
      [headerHeight]="56"
      [footerHeight]="50"
      [rowHeight]="80"
      [columnMode]="ColumnMode.force"
      [scrollbarH]="true"
      [loadingIndicator]="isLoading"
    >
      <ngx-datatable-column
        prop="timestampTypeSingleSelection"
        [name]="'UI.TimestampTypes' | translate"
        [sortable]="false"
        [resizeable]="false"
        [draggable]="false"
        [width]="200"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          <div class="w-100 pl-3">
            <div class="timestamp-icon-section d-flex align-items-center">
              <img
                class="icon-red"
                appDefaultPlaceholder
                appDynamicImgUrl
                [src]="timestampIconRed"
              />
              <img
                appDefaultPlaceholder
                appDynamicImgUrl
                [src]="getTimestampIcon(value.codeId)"
                class="mr-2"
              />
              <span> {{ value.value }}</span>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        prop="createdDate"
        [name]="'UI.Common.CreatedDate' | translate"
        [sortable]="false"
        [resizeable]="false"
        [draggable]="false"
        [width]="230"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | localizedDate: DateFormat.TimestampDate }}
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        prop="createdDate"
        [name]="'Time' | translate"
        [sortable]="false"
        [resizeable]="false"
        [draggable]="false"
        [width]="100"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          {{ value | localizedDate: DateFormat.TimestampTime }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="creator"
        [name]="'UI.Common.CreatedBy' | translate"
        [sortable]="false"
        [resizeable]="false"
        [draggable]="false"
        [width]="100"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          <div class="custom-cell-wrapper">
            <app-venture-people
              [initiator]="value"
              [showLabel]="false"
              [fromTableCell]="true"
            >
            </app-venture-people>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        prop="description"
        [name]="'Description' | translate"
        [sortable]="false"
        [resizeable]="false"
        [draggable]="false"
        [width]="310"
      >
        <ng-template let-value="value" ngx-datatable-cell-template>
          <div class="custom-cell-wrapper">
            <span class="truncate-line-2 text-left">{{ value }}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Tool -->
      <ngx-datatable-column
        [width]="32"
        [resizeable]="false"
        [draggable]="false"
        [sortable]="false"
        [frozenRight]="true"
        prop="timestampId"
      >
        <ng-template ngx-datatable-header-template>
          <i class="fa fa-ellipsis-v text-center"></i>
        </ng-template>
        <ng-template let-value="value" ngx-datatable-cell-template>
          <div
            class="custom-cell-wrapper cell-tool"
            (click)="$event.stopPropagation(); toolDrop.open()"
          >
            <div
              ngbDropdown
              #toolDrop="ngbDropdown"
              container="body"
              placement="left-top"
            >
              <a ngbDropdownAnchor>
                <i class="fa fa-ellipsis-v align-middle"></i>
              </a>
              <ul ngbDropdownMenu>
                <li
                  ngbDropdownItem
                  class="metadata-table__dropdown-item list-inline-item venture__actions-item p-0"
                >
                  <a
                    class="link-unstyled w-100 p-2"
                    [href]="
                      getEntityUrl(entityName) +
                      '/' +
                      entityId +
                      '/?timestampId=' +
                      value
                    "
                  >
                    <i class="fas fa-eye"></i>
                    <span class="venture__actions-label font-weight-bold">
                      {{ 'UI.Timestamp.ViewDetail' | translate }}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <app-bootstrap-pagination
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    [showChangePageSizeCtrl]="true"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
  ></app-bootstrap-pagination>
</ng-container>
<ng-template #noData>
  <span class="message-empty">{{ 'UI.Timestamp.Empty' | translate }}</span>
</ng-template>
