import {
  Component,
  EventEmitter,
  Input,
  Output,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ShareEntityToPeopleDialogComponent } from '@components/dialogs/share-entity-to-people-dialog/share-entity-to-people-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttributePropertyName } from '@shared/enums/attribute-display-name.enum';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { I18nService } from '@src/app/core/i18n/i18n.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import {
  SocialSharing,
  SocialSharingUrl,
} from '@src/app/shared/enums/social-media.enum';
import { ChallengeInterface } from '@src/app/shared/interfaces/challenge.interface';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-interaction-bar',
  templateUrl: './interaction-bar.component.html',
})
export class InteractionBarComponent {
  @Input() entity: Entity;
  @Input() entityName: EntityName;
  @Input() isLoadingLikingsCount = false;
  @Input() isLoadingFollowingsCount = false;
  @Input() isLoadingCommentsCount = false;
  @Input() httpService: BaseHttpService<Entity>;

  @Input() entityPath: string;
  @Input() hideShareIcon: boolean = false;

  @Input() hideLikeNumber = false;
  @Input() useFollowIcon = false;
  
  @Output() triggerToggleLike = new EventEmitter<any>();
  @Output() triggerShowLikingsData = new EventEmitter<any>();
  @Output() triggerShowFollowingsData = new EventEmitter<any>();
  @Output() afterCommentModalClosed = new EventEmitter();
  @Output() triggerToggleFavorite = new EventEmitter();

  SocialSharingEnum = SocialSharing;
  EntityName = EntityName;
  tenantName = environment.portalName;
  tenantLogo = '';
  defaultTenantLogo =
    'https://home.jointcreate.com/wp-content/uploads/2021/01/jc.png';
  constructor(
    public sessionService: SessionService,
    protected modalService: NgbModal,
    private i18nService: I18nService,
    private domSanitizer: DomSanitizer,
    private cookieService: CookieService
  ) {
    this.tenantName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.tenantLogo =
      this.cookieService.get(SessionKeyEnum.TENANT_LOGO) ||
      this.defaultTenantLogo;
  }

  get entityDisplayName(): string {
    const name =
      'orgName' in this.entity
        ? this.entity.orgName
        : 'title' in this.entity
        ? this.entity.title
        : '';

    return name;
  }

  toggleLike(entity: Entity): void {
    this.triggerToggleLike.emit(entity);
  }

  showLikingsData(entity: Entity): void {
    this.triggerShowLikingsData.emit(entity);
  }

  showFollowingsData(entity: Entity): void {
    this.triggerShowFollowingsData.emit(entity);
  }

  toggleFavorite(entity: Entity): void {
    this.triggerToggleFavorite.emit(entity);
  }

  openShare(entity: Entity): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    const modalRef = this.modalService.open(
      ShareEntityToPeopleDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: 'message-modal-custom',
      }
    );

    modalRef.componentInstance.entity = entity;
    modalRef.componentInstance.entityName = this.entityName;
    modalRef.componentInstance.httpService = this.httpService;
    modalRef.componentInstance.placeholder = 'UI.SendMessage.Placeholder';
    modalRef.componentInstance.choice = AttributePropertyName.ShareRecipient;
  }

  socialSharing(entity: Entity, entityName: string, social: string): void {
    if (!entity || !entityName || !social) {
      return;
    }
    let socialShareUrl = '';

    if(social === SocialSharing.LinkedIn) {
      socialShareUrl = SocialSharingUrl[SocialSharing.LinkedIn];
    }

    let title = '';
    let imageUrl = '';

    switch (entityName) {
      case EntityName.Venture:
        const venture = entity as VentureInterface;
        if (venture) {
          title = venture.title;
          imageUrl = venture.image?.url;
        }
        break;

      case EntityName.Challenge:
        const call = entity as ChallengeInterface;
        if (call) {
          title = call.orgName;
          imageUrl = call.image as string;
        }
        break;

      case EntityName.Organization:
        const org = entity as OrganizationInterface;
        if (org) {
          title = org.orgName;
          imageUrl = org.image?.url;
        }
        break;

      case EntityName.Event:
        const event = entity as EventInterface;
        if (event) {
          title = event.title;
          imageUrl = event.image?.url;
        }
        break;
    }

    const currentOrigin = window.origin + this.i18nService.languagePath;
    const entityDetailUrl = `${currentOrigin}${StringUtils.getEntityUrlByEntityName(
      entityName
    )}/${entity.id}`;

    let joincreateShareUrl = `${currentOrigin}${
      environment.jipUrl.sharing
    }/?jcurl=${encodeURIComponent(entityDetailUrl)}&title=${encodeURIComponent(
      title
    )}&image=${encodeURIComponent(imageUrl) || ''}`;

    joincreateShareUrl = StringUtils.replaceAll(joincreateShareUrl, ' ', '+');

    const finalUrl = `${socialShareUrl}${encodeURIComponent(
      joincreateShareUrl
    )}`;

    window.open(
      finalUrl,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=400,width=573,height=706'
    );
  }

  getSummaryDesc(desc: string): string {
    // Remove all HTML and line break from string
    const sanitizeHtml = this.domSanitizer.sanitize(SecurityContext.HTML, desc);
    const resultHtml = StringUtils.stripHtml(sanitizeHtml);
    return (
      StringUtils.removeLineBreakFromTextArea(resultHtml)?.substring(0, 100) ||
      ''
    );
  }

  supportSocialSharing(entityName: string): boolean {
    const currentSupportEntity = [
      EntityName.Venture,
      EntityName.Event,
      EntityName.Challenge,
      EntityName.Organization,
    ];
    return currentSupportEntity.includes(entityName as EntityName);
  }
}
