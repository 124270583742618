export function ToNumber(): PropertyDecorator {
  return (target: Object, propertyKey: string | symbol) => {
    let value: any;

    const getter = () => {
      return value;
    };

    const setter = (newValue: any) => {
      value = Number(newValue);
    };

    if (delete target[propertyKey]) {
      Object.defineProperty(target, propertyKey, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true,
      });
    }
  };
}
