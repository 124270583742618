<ng-container *ngIf="item" [ngSwitch]="item.type">
  <div
    *ngSwitchCase="TagFieldType.PreSelection"
    class="tag-field--common tag-field__preselection"
  >
    <div class="text-truncate">
      {{ item.display }}
    </div>
  </div>

  <div
    *ngSwitchCase="TagFieldType.Header"
    class="tag-field--common tag-field__header"
  >
    {{ item.display }}
  </div>

  <div *ngSwitchDefault class="tag-field--common tag-field__default">
    <div class="tag-field__item-image mr-3">
      <app-image-wrapper
        imgClass="widget__image venture__image"
        [imgObject]="item[keyMapImg] || defaultAvatar"
        [placeholderImg]="defaultAvatar"
        [wrapperClasses]="
          keyMapImg === 'logo'
            ? 'widget__image-wrapper organization__logo'
            : 'widget__image-wrapper entity__image-wrapper'
        "
      ></app-image-wrapper>
    </div>
    <div class="text-truncate tag-field__default--medium-text">
      {{ item.display }}
    </div>
  </div>
</ng-container>
