<div class="modal-header">
  <div class="modal-title">
    {{ 'Images' | translate }} &#34;<span translate>{{ modalTitle }}</span
    >&#34;
  </div>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="text-center mb-3">
    <app-file-upload
      (srcUrlChange)="uploadChange($event)"
      [allowCropImg]="allowCropImg"
    >
      <button class="btn-upload-image">
        <i class="fas fa-camera"></i>
        <span translate>Upload custom image</span>
      </button>
    </app-file-upload>
  </div>
  <div class="image-list__items">
    <div
      (click)="choseImage(item)"
      *ngFor="let item of displayImages"
      class="image-list__item"
      [class.image-list__item--selected]="
        item.imageId === selectedItem?.imageId
      "
      [ngbTooltip]="'MOOD-BOARD_Choose-image' | translate"
      tooltipClass="no-limit-width-tooltip"
    >
      <i class="icon-placeholder" [class]="item.image"></i>
      <div class="image-list__image-wraper pointer-cursor">
        <img
          *ngIf="item.image"
          [src]="item.image"
          appDefaultPlaceholder
          appDynamicImgUrl
        />
      </div>
      <span
        *ngIf="item.imageId === selectedItem?.imageId"
        class="image-list__selected-icon bg-primary d-flex align-items-center justify-content-center"
      >
        <i class="fas fa-check text-white"></i>
      </span>
    </div>
  </div>

  <div class="image-list__btn-wrapper">
    <button
      (click)="cancel()"
      class="btn btn-outline-primary image-list__btn-cancel"
      translate
    >
      Cancel
    </button>

    <button
      (click)="apply()"
      class="btn btn-primary image-list_btn-save"
      translate
    >
      Apply
    </button>
  </div>
</div>
