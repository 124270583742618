import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from '@core/http/base-http.service';
import { EntityJointStateService } from '@shared/services/entity-joint-state.service';

@Pipe({
  name: 'jointStateMapper',
})
export class JointStateMapperPipe implements PipeTransform {
  transform<T>(value: T[], service: BaseHttpService<T>): Observable<any[]> {
    return EntityJointStateService.mapJointState<any>(value, service);
  }
}
