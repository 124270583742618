import { Injectable } from '@angular/core';
import { ChallengeInterface } from '@shared/interfaces/challenge.interface';
import { StringUtils } from '@shared/utils/string-utils';
import { UrlParam } from '@shared/enums/url-param.enum';
import { StringReplacementPipe } from '@shared/pipes/string-replacement.pipe';
import { NotificationService } from '@core/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '@core/session.service';
import { LinkWrapperPipe } from '@shared/pipes/link-wrapper.pipe';
import { EntityName } from '@shared/enums/entity-name.enum';
import { NewMessageDialogComponent } from '@components/news-messages/components/new-message-dialog/new-message-dialog.component';
import {
  TagFieldCustom,
  TagFieldType,
} from '@shared/components/tag-field/tag-field.component';
import { UserInterface } from '@shared/interfaces/user.interface';
import { delay, filter, first, map, skip } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

@Injectable()
export class OrganizationEditService {
  constructor(
    private readonly stringReplacementPipe: StringReplacementPipe,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly modalService: NgbModal,
    private readonly sessionService: SessionService,
    private readonly linkWrapperPipe: LinkWrapperPipe
  ) {}

  showReasonWhyCannotSubmitVentureToCall(
    challenge: ChallengeInterface,
    text: string
  ): Observable<boolean> {
    StringUtils.removeParamFromUrl(UrlParam.OrganizationId, null, true);
    const entityLink = this.getEntityLink(
      this.translateService.instant(EntityName.Challenge),
      EntityName.Challenge,
      challenge.id
    );

    const replacements: Record<string, string> = {
      '{name}': challenge.orgName,
      '{challenge}': entityLink,
    };

    const htmlMessage = this.stringReplacementPipe.transform(
      this.translateService.instant(text),
      replacements
    );

    const phaseDate = challenge.challengeTimeline[0].phaseDate;
    const isComingSoon = new Date() < new Date(phaseDate);

    const modalRef = this.notificationService.show(
      'Coming Soon or Expired',
      htmlMessage,
      isComingSoon
        ? ''
        : this.translateService
            .instant('UI.Modal.SendMessageToAdmin')
            .toUpperCase(),
      true
    );

    return from(modalRef.result).pipe(map(() => !isComingSoon));
  }

  openSendMessageToAdmins(challenge: ChallengeInterface): void {
    if (!challenge) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(NewMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    const admins = challenge.initiators.map((item: UserInterface) => ({
      id: item.id,
      display: [item.firstName, item.lastName].join(' '),
      hasSubOrganization: false,
      image: item.image,
      type: TagFieldType.Normal,
      value: item.id,
    })) as TagFieldCustom[];

    modalRef.componentInstance.isLoadingAttributeDescriptions$
      .pipe(
        skip(1), // ignore the "false" as default value
        filter((loading) => !loading),
        first(),
        delay(0)
      )
      .subscribe(() => {
        modalRef.componentInstance.selectedRecipients = admins;
        modalRef.componentInstance.handleRecipientsChanged(admins);
      });
  }

  getEntityLink(
    translateText: string,
    entityName: string,
    challengeId: number
  ): string {
    const entityUrl = StringUtils.getEntityUrlByEntityName(entityName);
    if (!entityUrl) {
      return '';
    }
    const url = this.sessionService.appendLanguagePath(
      `${entityUrl}/${challengeId}`
    );

    return this.linkWrapperPipe.transform(url, translateText, '', 'underline');
  }
}
