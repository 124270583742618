import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SendMessageHttpService } from '@core/http/send-messages-http.service';
import { SessionService } from '@core/session.service';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { MessageUIService } from '@shared/services/message-ui.service';

@Component({
  selector: 'app-message-card-footer',
  templateUrl: './message-card-footer.component.html',
  providers: [
    { provide: SendMessageHttpService, useClass: SendMessageHttpService },
  ],
})
export class MessageCardFooterComponent {
  @Output() clickLikes: EventEmitter<any> = new EventEmitter();
  @Input() message: NewsMessageInterface;

  isLoadingThreadMessage = false;

  constructor(
    private messageUIService: MessageUIService,
    private sessionService: SessionService,
    private messageService: SendMessageHttpService
  ) { }

  toggleLike(message: NewsMessageInterface): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    if (message) {
      if (!message.isLiked) {
        this.messageService.like(message.id).subscribe((newLikeId) => {
          message.isLiked = !message.isLiked;
          message.likesCount = message.likesCount + 1;
          message.likeId = newLikeId;
        });
      } else {
        this.messageService.unlike(message.likeId).subscribe((newLikeId) => {
          message.isLiked = !message.isLiked;
          message.likesCount = message.likesCount > 0 ? message.likesCount - 1 : 0;
          message.likeId = null;
        });
      }
    }
  }

  onLikesClicked(message: any): void {
    this.clickLikes.emit(message);
  }

  openMessageBack(): void {
    this.isLoadingThreadMessage = true;
    this.messageUIService.openMessage(
      this.message.id,
      false,
      this.message.content,
      () => {
        this.isLoadingThreadMessage = false;
      }
    );
  }
}
