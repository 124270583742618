import { Component, HostBinding, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PeopleViewModalComponent } from '@src/app/components/modals/people-view-modal/people-view-modal.component';
import { LST_RELATED_ENTITY_AS_MULTISTRING } from '@src/app/shared/constants/multi-string.const';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { MultiStringRelatedEntity } from '@src/app/shared/interfaces/attribute-description.interface';
import { TimestampModel } from '@src/app/shared/interfaces/timestamp.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-cell-view-related-entity',
  templateUrl: './cell-view-related-entity.component.html',
})
export class CellViewRelatedEntityComponent {
  @Input() entityId: number;
  @Input() entityName: EntityName;
  @Input() col: Record<string, any>;
  @Input() value: any;
  @Input() editable = false;

  lstRelatedEntityAsMultiString: Record<string, MultiStringRelatedEntity> = LST_RELATED_ENTITY_AS_MULTISTRING;
  DateFormat = DateFormat;

  @HostBinding('class') class = 'cell-view-related-entity';

  constructor(
    private modalService: NgbModal,
  ) { }

  get timeStampDetailUrl(): string {
    const timestampValue: TimestampModel = this.value;
    return `${StringUtils.getEntityUrlByEntityName(this.entityName)}/${this.entityId}/?timestampId=${timestampValue.timestampId}`;
  }

  get isSupportedMultiStringRelatedEntity(): boolean {
    return this.lstRelatedEntityAsMultiString[this.entityName]?.propertyNames?.includes(
      this.col.attrDes.propertyName
    );
  }

  openPeopleViewModal(): void {
    if (this.entityName !== EntityName.Venture || (this.col.propertyName === 'Initiator' && !this.value)) {
      return;
    }

    const modalRef = this.modalService.open(PeopleViewModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.componentInstance.entityId = this.entityId;
    modalRef.componentInstance.entityName = this.entityName;
    modalRef.componentInstance.allowEditRole = this.editable;
  }
}
