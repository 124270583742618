import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TextareaComponent } from '@shared/components/form-custom-attributes/textarea/textarea.component';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  AttributeDescription,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { CommentInterface } from '@shared/interfaces/comment.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { CreateCommentPayload } from '@shared/models/comment.model';
import { FormUtils } from '@shared/utils/form-utils';
import { SendMessageBoxBaseComponent } from '@src/app/components/dialogs/send-message-box-base/send-message-box-base.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { CommentHttpService } from '@src/app/core/http/comment-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { FactoryService } from '@src/app/core/services/factory.service';
import { ToastService } from '@src/app/core/toast.service';
import { environment } from '@src/environments/environment';
import { of } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { EntityName } from '../../enums/entity-name.enum';
import { Entity } from '../../models/entity.model';

@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.component.html',
})
export class CommentsModalComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() entityId: number;
  @Input() entityName: EntityName;
  @Input() placeholder = 'UI.Comment.DefaultPlaceholder';

  @ViewChild(TextareaComponent) commentRef: TextareaComponent;
  @ViewChild('commentBody') commentBody: ElementRef;

  profile: UserInterface;
  isLogin: false;

  comments: CommentInterface[] = [];
  page = 1;
  pageSize = 3;
  collectionSize = 0;

  isLoadComments = false;
  isLoadMore = false;
  isGetLatestComment = false;
  isEditing = false;
  currentEditingCommentId: number = null;
  parentHttpService: BaseHttpService<Entity>;

  //#region SERVICES
  private commentService: CommentHttpService;
  private toastService: ToastService;
  private factoryService: FactoryService;
  //#endregion End Services

  typeAttrDes: AttributeDescription;

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected authenService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected injector: Injector
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );
    this.commentService =
      this.injector.get<CommentHttpService>(CommentHttpService);
    this.toastService = this.injector.get<ToastService>(ToastService);
    this.factoryService = this.injector.get<FactoryService>(FactoryService);
  }

  ngOnInit(): void {
    this.parentHttpService = this.factoryService.createServiceByEntityName(
      this.entityName
    );

    super.ngOnInit();

    this.isLoadComments = true;
    this.paginateItems();
  }

  paginateItems(): void {
    if (!this.entityId) {
      return;
    }
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    if (this.parentHttpService) {
      this.parentHttpService
        .paginateByEntity(environment.jipUrl.comments, this.entityId, params)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isLoadComments = false;
            this.isLoadMore = false;
          })
        )
        .subscribe((res: ApiGetResponse<CommentInterface>) => {
          if (res?.items) {
            this.comments = res.items;
            this.collectionSize = res?.rowCount;
            this.handleEditingCommentWhenPaginateItems();
          }
        });
    }
  }

  onComment(): void {
    if (this.entityId) {
      this.isSubmitting = true;

      if (!this.isEditing) {
        this.addNewComment();
      } else {
        this.editComment();
      }
    }
  }

  onDeleteComment(commentId: number): void {
    const deletedCommentIndex = this.comments.findIndex(
      (x) => x.id === commentId
    );
    if (deletedCommentIndex > -1) {
      this.commentService
        .delete(commentId)
        .pipe(
          untilDestroyed(this),
          catchError(() => {
            this.toastService.showError('UI.Toast.DeletedFailed');
            return of(undefined);
          })
        )
        .subscribe((res: boolean) => {
          if (res !== undefined) {
            this.comments.splice(deletedCommentIndex, 1);
            this.collectionSize--;
            this.paginateItems();
            this.toastService.showSuccess('UI.Toast.DeletedSuccessfully');
          }
        });
    }
  }

  onEditComment(comment: CommentInterface): void {
    this.comments.forEach((x) => (x.isEditing = false));
    comment.isEditing = true;
    this.isEditing = true;
    this.currentEditingCommentId = comment.id;
    this.content?.setValue(comment.comment);
    this.commentRef?.focus();
  }

  onCancelEdit(): void {
    const editingComment = this.comments.find(
      (x) => x.id === this.currentEditingCommentId
    );
    if (editingComment) {
      editingComment.isEditing = false;
    }
    this.isEditing = false;
    this.currentEditingCommentId = null;
    this.resetForm();
  }

  onLoadMore(): void {
    this.pageSize += 10;
    this.isLoadMore = true;
    this.paginateItems();
  }

  protected loadAttributeDescription(): void {
    this.isLoadingAttributeDescriptions$.next(true);
    this.commentService
      .getAttributeDescription()
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingAttributeDescriptions$.next(false);
        })
      )
      .subscribe((res: MetaInformation) => {
        const commentAttrDes = res.entityDescription.attributeDescriptions;
        this.contentAttributeDescription = FormUtils.getFieldOptions(
          commentAttrDes,
          'Content'
        );

        this.typeAttrDes = FormUtils.getFieldOptions(commentAttrDes, 'Type');

        this.handleAfterLoadAttributeDescription(res);
      });
  }

  protected handleAfterLoadProfileData(userProfile: UserInterface): void {
    this.profileImage = userProfile?.image;
    this.profile = userProfile;
  }

  private addNewComment(): void {
    const createCommentPayload: CreateCommentPayload = {
      content: this.content?.value,
      type: this.typeAttrDes.defaultValue.codeId,
    };
    if (this.parentHttpService) {
      this.parentHttpService
        .postByEntity(
          this.entityId,
          createCommentPayload,
          environment.jipUrl.comments
        )
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isSubmitting = false;
          })
        )
        .subscribe((newCommentId: number) => {
          if (newCommentId !== null && !isNaN(newCommentId)) {
            this.addLatestComment(newCommentId);
          }
        });
    }
  }

  private editComment(): void {
    const editCommentPayload: CreateCommentPayload = {
      content: this.content?.value,
      type: this.typeAttrDes.defaultValue.codeId,
    };
    this.commentService
      .update(this.currentEditingCommentId, editCommentPayload as any)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe((updatedCommentId: number) => {
        if (updatedCommentId !== null && !isNaN(updatedCommentId)) {
          const updatedComment = this.comments.find(
            (x) => x.id === updatedCommentId
          );
          if (updatedComment) {
            updatedComment.comment = this.content?.value;
            updatedComment.modifiedDate = new Date();
            this.onCancelEdit();
          }
        }
      });
  }

  private addLatestComment(commentId: number): void {
    this.isGetLatestComment = true;
    this.commentService
      .read(commentId)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isGetLatestComment = false;
        })
      )
      .subscribe((comment: CommentInterface) => {
        if (comment) {
          this.comments.unshift(comment);
          this.collectionSize++;
          this.resetForm();
          if (this.commentBody?.nativeElement?.scrollTop !== 0) {
            this.commentBody.nativeElement.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }
        }
      });
  }

  private handleEditingCommentWhenPaginateItems(): void {
    if (this.isEditing) {
      const editingComment = this.comments.find(
        (x) => x.id === this.currentEditingCommentId
      );
      if (editingComment) {
        editingComment.isEditing = true;
      } else {
        this.onCancelEdit();
      }
    }
  }

  private resetForm(): void {
    this.form?.reset({ Content: '' });
  }
}
