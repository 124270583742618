import { defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  apiUrl: 'https://jip-test-api.bbv-demo.ch/api/',
  googleMapApiKey: 'AIzaSyDMLiQznlCZdnfCQDjSnLb_Bcgx8IaFWb0',
  test: true,
  termOfUseUrl: 'https://home.jointcreate.com/privacy-policy/',
  linkedinConfig: {
    pathName: 'https://www.linkedin.com/oauth/v2/authorization',
    clientId: '78gcysj07rkflu',
    redirectUri: 'https://jip-test.bbv-demo.ch',
  },
};
