export enum AttributeType {
  String = 'String',
  Map = 'Map',
  Link = 'Link',
  MultiLink = 'MultiLink',
  SocialLink = 'SocialLink',
  MultiString = 'MultiString',
  Integer = 'Integer',
  Double = 'Double',
  StringSingle = 'StringSingle',
  SingleSelection = 'SingleSelection',
  MultipleSelection = 'MultipleSelection',
  DateTime = 'DateTime',
  Files = 'Files',
  Image = 'Image',
  VenturePhase = 'VenturePhase',
  VentureStatus = 'VentureStatus',
  RelatedEntity = 'RelatedEntity',
  Boolean = 'Boolean',
  MoodboardGroup = 'MoodboardGroup',
  PhoneNumber = 'PhoneNumber',
  RTE = 'RTE'
}

export enum EntitySelectionType {
  SingleItem = 'SingleItem',
}

export enum PatternType {
  System = 'System',
  Dedicated = 'Dedicated',
  Dynamic = 'Dynamic',
}

export enum SelectionType {
  DropdownList = 'DropdownList',
  Radio = 'Radio'
}

export enum SystemType {
  Selection = 'Selection',
  MultilingualImage = 'MultilingualImage',
  ImageType = 'ImageType',
}

export enum UIControlId {
  ConfirmationV1 = 'Confirmation.V1',
  TextAreaV1 = 'TextArea.V1',
  TextInputV1 = 'TextInput.V1',
  PasswordInputV1 = 'PasswordInput.V1',
  RadioV1 = 'Radio.V1'
}

export enum UIHelpTextPosition {
  Tooltip = 'UI.Tooltip',
  Placeholder = 'UI.PlaceholderText',
}
