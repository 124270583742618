import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-jip-logo',
  templateUrl: './jip-logo.component.html',
  styles: [],
})
export class JipLogoComponent {
  @Input() name = 'logo';
  @Input() width: string;
  @Input() height: string;
}
