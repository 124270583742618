<div class="page-header widget__heading--background">
  <div class="container p-0">
    <div
      class="page-header__row d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center flex-1">
        <h2
          class="page-header__text widget__heading truncate-line-1 m-0 mr-auto mr-md-0 font-weight-bold"
        >
          <ng-container *ngIf="title">{{ title }}</ng-container>
          <ng-content></ng-content>
        </h2>

        <div
          *ngIf="helpText"
          class="ml-2 d-flex align-items-center"
          triggers="click"
          [placement]="helpTextPlacement"
          [ngbTooltip]="helpText"
          tooltipClass="page-header-tooltip"
          appTooltip
          #t="ngbTooltip"
          [toolTipControl]="t"
          container="body"
        >
          <i
            class="tooltip-icon fas fa-info-circle align-middle pointer-cursor font-size-xl mt-1"
          ></i>
        </div>
      </div>

      <button
        *ngIf="isEventPage"
        class="page-header__btn btn btn-outline-primary mr-3 open-calendar-btn font-weight-bold"
        (click)="openEventCalendarDialog()"
      >
        <em class="fas fa-calendar-alt pr-2"></em>
        <span class="text-uppercase" translate>UI.Calendar.EventCalendar</span>
      </button>

      <button
        (click)="onCreatePostClick($event)"
        *ngIf="isShownActionBtn && isHomePage && !isMessages()"
        class="page-header__btn btn btn-primary mr-3 font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>UI.NewMessage.CreatePost</span>
      </button>

      <button
        (click)="onBtnClick($event)"
        *ngIf="isShownActionBtn && isHomePage && isMessages()"
        class="page-header__btn btn btn-primary mr-3 font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>
          UI.NewMessage.CreateMessage
        </span>
      </button>

      <button
        (click)="onBtnClick($event)"
        *ngIf="isShownActionBtn && !isHomePage"
        class="page-header__btn btn btn-primary font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>
          {{ createBtnText }}
        </span>
      </button>
    </div>
  </div>
</div>
