import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const collapseAnimation = trigger('expandCollapse', [
  state('open', style({ height: '100%', opacity: 1 })),
  state('closed', style({ height: 0, opacity: 0 })),
  transition('open <=> closed', animate('{{duration}}ms {{easing}}'), {
    params: {
      duration: 300,
      easing: 'ease-in-out',
    },
  }),
]);

export const smoothHeight = trigger('grow', [
  transition('void <=> *', []),
  transition(
    '* <=> *',
    [style({ height: '{{startHeight}}px', opacity: 0 }), animate('1s ease')],
    {
      params: { startHeight: 0 },
    }
  ),
]);
