import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '@core/session.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileEmailService {
  baseUrl = 'accounts/email/change';
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  requestChangeEmail(email: string): Observable<any> {
    return this.http
      .post(this.baseUrl, {
        email,
      })
      .pipe(
        catchError((error: ApiResponse) => {
          if (error.status === 400) {
            return of(error);
          }
          throw error;
        })
      );
  }

  processChangeEmail(email: string, token: string): Observable<any> {
    return this.http.post(this.baseUrl + '/process', {
      email,
      token,
    });
  }

  resendChangeEmail(email: string, token: string): Observable<any> {
    return this.http.post(this.baseUrl + '/re-send', {
      email,
      token,
    });
  }

  resendChangeEmailAnonymously(email: string, token: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/register/re-send`, {
      email,
      token,
    });
  }
}
