<div class="row">
  <ng-container *ngIf="message && messageText">
    <div class="col-12 col-lg-10 mb-3 d-flex news-messages__body--rte">
      <div class="mr-2">
        <i class="fas fa-quote-left"></i>
      </div>
      <div class="d-flex flex-column w-100">
        <app-rich-text-toggle
          [text]="messageText"
          [controlName]="'group-message' + message.id"
          [minNumOfRows]="2"
        ></app-rich-text-toggle>
        <ng-container
          *ngIf="message.action === MessageAction.PendingRequest && message.to"
        >
          <div class="news-messages__body--message-by mt-1">
            <span> {{ 'UI.MessageBy' | translate }} </span>
            <a appUnsavedFormCheck [href]="peopleDetailUrl">
              {{ getFullName(message.to) }}
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="message?.from && message?.to && canReply">
    <div class="col-12 col-lg-2 text-lg-right">
      <button
        appRequireLogin
        (click)="openMessageBack()"
        class="btn btn-outline-primary people__follow-btn btn-get-in-touch-reply"
        translate
      >
        Reply
      </button>
    </div>
  </ng-container>
</div>
