import { Component } from '@angular/core';
import { SessionService } from '@src/app/core/session.service';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styles: [],
})
export class EmptyContentComponent {
  constructor(public sessionService: SessionService) {}
}
