<ng-container *ngIf="orgId && entityName; else requiredField">
  <ng-container [ngSwitch]="entityName">
    <ng-container *ngSwitchCase="EntityName.Organization">
      <app-organization-list
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      ></app-organization-list>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Challenge">
      <app-challenges
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      ></app-challenges>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Event">
      <app-event-list
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      ></app-event-list>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Venture">
      <app-ventures
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      ></app-ventures>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Person">
      <app-peoples
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      ></app-peoples>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #requiredField>
  <p>Please input entity name and organization/challenge id</p>
</ng-template>
