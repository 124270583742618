import { Pipe, PipeTransform } from '@angular/core';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { environment } from '@src/environments/environment';

@Pipe({
  name: 'challengeOrgUrl',
})
export class ChallengeOrganizationUrlPipe implements PipeTransform {
  transform(value: LeadCompany, ...args: unknown[]): string {
    const url = value?.showAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;

    return `${url}/${value.id}`;
  }
}
