<ng-template #modalRef let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" translate>RequestedApplications</h4>
    </div>

    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true"> &times; </span>
    </button>

    <div *ngIf="pendingList?.items?.length > 0" class="modal-header__btns mt-2">
      <button
        (click)="pendingList.rejectAll()"
        [disabled]="requesting"
        class="jip-btn-small btn btn-outline-primary mr-3"
      >
        <ng-container *ngIf="requesting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>

        <i class="fas fa-times"></i>
        <span class="ml-1">{{ 'UI.Common.RejectAll' | translate }}</span>
      </button>

      <button
        (click)="pendingList.approveAll()"
        class="jip-btn-small btn btn-primary"
        [disabled]="requesting"
      >
        <ng-container *ngIf="requesting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>

        <i class="fas fa-check"></i>
        <span class="ml-1">{{ 'UI.Common.ApproveAll' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="pt-md-3 pb-4">
    <app-shared-pending-people
      #pendingList
      [entityId]="entityId"
      [httpService]="httpService"
      (notifyRequestChange)="notifyRequestChange.emit()"
    >
    </app-shared-pending-people>
  </div>
</ng-template>
