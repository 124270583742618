<ng-container *ngIf="editting">
  <div
    class="form-group d-flex flex-wrap justify-content-between align-items-center"
  >
    <div class="widget__label col-sm-12 px-0">
      {{ 'UI.ORG.Partner-organizations' | translate }}
    </div>
  </div>
  <div class="form-group search">
    <app-organisation-search
      [searchMode]="orgsSearchMode"
      [ignoreCompany]="ignoreCompany"
      [excludedOrganisationIds]="ignoreOrgIds"
      [userId]="userId"
      [searchPlaceholder]="
        'UI.ORG.Partner-organizations-search-placeholder' | translate
      "
      (selectOrganisation)="selectItem($event)"
    ></app-organisation-search>
  </div>
</ng-container>

<!-- Organisations -->
<h6 class="mb-4" translate>Organizations</h6>

<app-organization-card-list
  [editting]="editting"
  [displayItems]="
    organisations
      | slice: (organisationsPage - 1) * pageSize:organisationsPage * pageSize
  "
  [emptyPlacholderKey]="'UI.EventEdit.PartnerOrganizationsEmpty'"
  [lstEventPartnerStatus]="lstEventPartnerStatus"
  [entityName]="entityName"
  [entity]="entity"
  (itemRemoved)="removeItem($event)"
>
</app-organization-card-list>

<app-bootstrap-pagination
  [page]="organisationsPage"
  [pageSize]="pageSize"
  [collectionSize]="organisations.length"
  (changePageSize)="pageSize = $event"
  (changePage)="organisationsPage = $event"
></app-bootstrap-pagination>

<!-- Challenges -->
<h6 class="my-4" translate>Challenges</h6>

<app-organization-card-list
  [editting]="editting"
  [displayItems]="
    challenges
      | slice: (challengesPage - 1) * pageSize:challengesPage * pageSize
  "
  [emptyPlacholderKey]="'UI.EventEdit.PartnerChallengesEmpty'"
  (itemRemoved)="removeItem($event)"
>
</app-organization-card-list>

<app-bootstrap-pagination
  [page]="challengesPage"
  [pageSize]="pageSize"
  [collectionSize]="challenges.length"
  (changePageSize)="pageSize = $event"
  (changePage)="challengesPage = $event"
></app-bootstrap-pagination>
