<div class="modal-header">
  <h4 class="modal-title">
    {{ 'UI.Venture.PublishingConfirmation' | translate }}
  </h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="publish-inform">
    <span> {{ confirmContent | translate }} </span>
  </div>
</div>
<div class="modal-footer message-command">
  <button
    appRequireLogin
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="operate()"
    translate
  >
    {{ confirmAction }}
  </button>
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
    translate
  >
    Cancel
  </button>
</div>
