<div *ngIf="orgOptions.length > 1">
  <div
    ngbDropdown
    class="partner-org-select"
  >
    <div
      class="d-flex gap-8"
      [ngClass]="{
        'flex-column align-items-start': innerWidth <= MediaBreakpoint.xl,
        'align-items-center': innerWidth > MediaBreakpoint.xl
      }"
    >
      <p class="m-0 select-title">
        {{ 'Organizations' | translate }}/Challenges
      </p>
      <button
        class="btn organization-search-box"
        ngbDropdownToggle
        (click)="$event.stopPropagation()"
      >
        <div class="d-flex justify-content-between align-items-center gap-8">
          <div class="selected-option d-flex align-items-center gap-8">
            <div
              *ngIf="selectedOption.logo"
              class="upload-file__container partner-org-select__image-container flex-shrink-0"
            >
              <img
                appDefaultPlaceholder
                appDynamicImgUrl
                [src]="selectedOption?.logo.url"
                [alt]="selectedOption?.logo.alt"
              />
            </div>
            <div class="overflow-hidden">
              <h6
                class="option-name m-0"
                [ngbTooltip]="
                  selectedOption.id !== NO_FILTER_OPTION_ID
                    ? selectedOption?.name
                    : ('NO_FILTER_OPTION' | translate)
                "
                placement="top"
                container="body"
              >
                {{
                  selectedOption.id !== NO_FILTER_OPTION_ID
                    ? selectedOption?.name
                    : ('NO_FILTER_OPTION' | translate)
                }}
              </h6>
            </div>
          </div>
          <div>
            <i class="fas fa-caret-down"></i>
          </div>
        </div>
      </button>
    </div>
    <ul ngbDropdownMenu class="partner-org-select__dropdown">
      <li
        *ngFor="let option of orgOptions"
        ngbDropdownItem
        class="organization-search-box"
        (click)="$event.stopPropagation(); onSelectPartnerOrg(option)"
      >
        <div class="d-flex align-items-center gap-8 w-100">
          <div
            *ngIf="option.logo"
            class="upload-file__container partner-org-select__image-container flex-shrink-0"
          >
            <img
              appDefaultPlaceholder
              appDynamicImgUrl
              [src]="option.logo.url"
              [alt]="option.logo.alt"
            />
          </div>
          <div class="overflow-hidden">
            <h6
              class="option-name m-0"
              [ngClass]="{
                'font-weight-bold': option.id === selectedOption.id
              }"
              placement="top"
              [ngbTooltip]="
                option.id !== NO_FILTER_OPTION_ID
                  ? option.name
                  : ('NO_FILTER_OPTION' | translate)
              "
              container="body"
            >
              {{
                option.id !== NO_FILTER_OPTION_ID
                  ? option.name
                  : ('NO_FILTER_OPTION' | translate)
              }}
            </h6>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
