<div class="venture-state">
  <ng-container *ngFor="let item of items; index as index">
    <div
      (click)="select(item, index)"
      class="venture-state__item"
      [class]="{
        'pointer-cursor': editable,
        active: index === currentIndex,
        'bg-primary': index === currentIndex
      }"
    >
      {{ item.value }}
    </div>
  </ng-container>
</div>
