<app-news-messages-filter
  (filterChanged)="onFilterChange($event)"
  (subFilterChanged)="onSubfilterChange($event)"
  [globalFilterCriteria]="isHeaderVisible ? entityGlobalFilterCriteria : null"
  [showFollowingOrLikeFilter]="isLogin$ | async"
  [mainFilter]="currentFilter"
  [newsAndMessagesTab]="newsAndMessagesTab"
></app-news-messages-filter>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="items?.length > 0; else emptyContent">
    <app-news-and-message-list
      [currentUserId]="currentUser?.id"
      [items]="items"
      [backgroundColor]="bg_color"
      (commentModalClosed)="syncCommentStateCount($event)"
      (likesModalOpened)="openLikesModal($event)"
    ></app-news-and-message-list>
  </ng-container>

  <app-bootstrap-pagination
    *ngIf="show_paginator"
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageChange($event)"
  ></app-bootstrap-pagination>

  <div class="text-right widget__btn-create-wrapper">
    <button
      class="btn btn-primary widget__btn-create my-3"
      appRequireLogin
      appDebounceClick
      (debounceClick)="currentFilter === filterType.News ? openCreatePostModal() : openNewMessage()"
    >
      <i class="fas fa-plus fa-lg"></i>
    </button>
  </div>

  <ng-template #emptyContent>
    <h6 class="mx-2 my-3" translate>
      {{
        (isLogin$ | async)
          ? 'FILTERING_LISTING_EMPTY_MESSAGE'
          : 'MESSAGE_LIST_NOT_LOGIN'
      }}
    </h6>
  </ng-template>
</ng-container>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [modalTitle]="'UI.Likes.Message' | translate"
  [httpService]="
    selectedEntity?.messageType === MESSAGE_TYPE.News
      ? listService
      : messageHttpService
  "
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
