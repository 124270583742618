<div class="modal-header">
  <h4 class="modal-title" translate>{{ title || 'Information' }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body white-space--pre-wrap">
  <ng-container *ngIf="isHtmlMessage; else defaultView">
    <div [innerHTML]="message"></div>
  </ng-container>
  <ng-template #defaultView>
    {{ message | translate }}
  </ng-template>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary mx-auto"
    (click)="activeModal.close(false)"
    translate
  >
    {{ btnText || 'Close' }}
  </button>
</div>
