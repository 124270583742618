<app-loading-wrapper [isLoading]="isLoading">
  <div
    *ngIf="message || registerEmailExpire"
    class="alert alert-warning"
    role="alert"
  >
    <ng-container *ngIf="!toEmail; else withEmail">
      <ng-container *ngIf="!registerEmailExpire; else registerEmailFail">
        <i class="fas fa-exclamation-circle mr-3"></i>
        <span [innerHTML]="message"></span>
      </ng-container>
    </ng-container>
    <ng-template #withEmail>
      <i class="fas fa-exclamation-circle mr-3"></i>
      <span>
        {{
          message
            | stringReplacement
              : {
                  '{toEmail}': toEmail
                }
        }}
      </span>
    </ng-template>
    <ng-template #registerEmailFail>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <i class="fas fa-exclamation-circle mr-3"></i>
          <span translate>UI.ProcessToken.ExpiredLink</span>
        </div>
        <button
          type="button"
          appDebounceClick
          (debounceClick)="resendConfirmationEmail(confirmationEmail)"
          class="btn btn-secondary"
          [disabled]="resendEmailLoading"
        >
          <ng-container *ngIf="resendEmailLoading">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span>{{ 'UI.Registration.ResendEmail' | translate }}</span>
        </button>
      </div>
    </ng-template>
  </div>
</app-loading-wrapper>
