import { Component, Input } from '@angular/core';
import { IconType } from '../../enums/internal-icon.enum';
import { StringUtils } from '../../utils/string-utils';

@Component({
  selector: 'app-internal-icon',
  templateUrl: './internal-icon.component.html',
})
export class InternalIconComponent {
  @Input() name: string;
  @Input() height = 16;
  @Input() minWidth: number;
  @Input() className = '';
  @Input() iconType = IconType.SVG;
  @Input() hasFilter: boolean;
  @Input() width = 20;

  get iconSrc(): string {
    return StringUtils.getIconByType(this.name, this.iconType);
  }
}
