<ng-container *ngFor="let attribute of attributes; index as index">
  <td>
    <div class="d-md-flex">
      <app-form-field-custom
        [editable]="advancedEditing || (editing && isEditable(attribute))"
        [formInput]="form"
        [attributeDescription]="attribute"
        [options]="{
          hideTimeOfDate: true,
          shouldUseDefaultDate: false,
          isOptional: data['isOptional'],
          elementId: attribute.propertyName + data['nameKey'],
          preventDisableControl: true
        }"
      >
        <div
          *ngIf="
            attribute.propertyName === ChallengeTimelineAttributeName.PhaseDate
          "
          part="postfix"
          triggers="click"
          [placement]="tooltipPlacement"
          [ngbTooltip]="data['tooltip']"
          tooltipClass="dynamic-object-tooltip"
          appTooltip
        >
          <i class="tooltip-icon tooltip-icon--hint fas fa-info-circle"></i>
        </div>
      </app-form-field-custom>

      <span
        class="timeline__label--optional pl-1"
        *ngIf="
          attribute.propertyName === ChallengeTimelineAttributeName.Name &&
          data['isOptional'] &&
          !advancedEditing
        "
      >
        {{ 'UI.Common.Optional' | translate }}
      </span>
    </div>

    <div
      *ngIf="getError(attribute.propertyName) as error"
      class="alert alert-danger"
    >
      {{ error }}
    </div>
  </td>
</ng-container>
<ng-container *ngIf="isTest && data">
  <td>{{ data['phaseDate'] }}</td>
</ng-container>
