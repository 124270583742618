import { Injectable } from '@angular/core';
import { OrganizationHttpService } from '@core/http/organization-http.service';
import { SessionService } from '@core/session.service';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { filter, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CentralConfigService {
  innovationSpaceId: number;

  innovationSpace$ = new Subject<OrganizationInterface>();

  apiUrl$ = new BehaviorSubject<string>('');

  uiTemplateConfig$ = new BehaviorSubject<TemplateName>(TemplateName.Default);
  newsRedirectPath: string;

  private cachedOrg$: Observable<OrganizationInterface>;

  constructor(
    private readonly session: SessionService,
    private readonly organizationHttpService: OrganizationHttpService
  ) {}

  read(orgId?: string): Observable<OrganizationInterface> {
    if (!orgId && !this.innovationSpaceId) {
      return EMPTY;
    }

    if (!this.cachedOrg$) {
      const getOrg = () =>
        this.organizationHttpService.read(+orgId ?? this.innovationSpaceId);

      this.cachedOrg$ = this.session.apiReady$.pipe(
        filter(Boolean),
        switchMap(getOrg),
        shareReplay(),
        tap((v) => {
          this.innovationSpace$.next(v);
        })
      );
    }

    return this.cachedOrg$;
  }
}
