<div
  (click)="toggle()"
  class="button-toggle"
  [ngClass]="{
    'button-toggle--active': status,
    'button-toggle--disabled': settings && settings?.disabled
  }"
>
  <span class="button-toggle__text">
    {{
      (status
        ? settings?.activeText || 'UI.Common.Activated'
        : settings?.deactiveText || 'UI.Common.Deactivated'
      ) | translate
    }}
  </span>
  <span class="button-toggle__slider"></span>
</div>
