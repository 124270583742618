<!-- //NOSONAR -->
<li
  *ngIf="receiver && receiver.id !== (profile$ | async)?.id"
  class="list-inline-item people__actions-item interaction-btn"
  role="button"
>
  <a
    appDebounceClick
    appRequireLogin
    (debounceClick)="openSendMessage()"
    class="d-flex"
  >
    <app-internal-icon
      [height]="20"
      [name]="'comments'"
      [className]="'filter-gray'"
    ></app-internal-icon>
    <span
      class="people__actions-label font-weight-500 text-hide-small text-gray-600"
    >
      {{ 'UI.Interaction.MessageMe' | translate }}
    </span>
  </a>
</li>
