import {
  Directive,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EntityName } from '@shared/enums/entity-name.enum';
import { Entity } from '@shared/models/entity.model';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { ToastService } from '@src/app/core/toast.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '../functions/until-destroyed';
import { StringReplacementPipe } from '../pipes/string-replacement.pipe';

@Directive({
  selector: '[appLeaveEntity]',
})
export class LeaveEntityDirective implements OnInit, OnDestroy {
  @Input() entityId: number;
  @Input() entityName: EntityName;
  @Input() isLeaving = false;

  @Output() afterLeaved = new EventEmitter<boolean>();
  @Output() isLeavingChange = new EventEmitter<boolean>();

  httpService: BaseHttpService<Entity>;

  @HostListener('click', ['$event'])
  clickEvent(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.entityId && this.entityName) {
      this.openRemoveEntityPopup();
    }
  }

  constructor(
    private modalService: NgbModal,
    private stringReplacementPipe: StringReplacementPipe,
    private translateService: TranslateService,
    private toastService: ToastService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    switch (this.entityName) {
      case EntityName.Venture:
        this.httpService =
          this.injector.get<VentureHttpService>(VentureHttpService);
        break;
      case EntityName.Event:
        this.httpService =
          this.injector.get<EventHttpService>(EventHttpService);
        break;
      case EntityName.Person:
        this.httpService =
          this.injector.get<PeopleHttpService>(PeopleHttpService);
        break;
      case EntityName.Organization:
      case EntityName.Challenge:
        this.httpService = this.injector.get<OrganizationHttpService>(
          OrganizationHttpService
        );
        break;
    }
  }

  openRemoveEntityPopup(): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });

    const entityNameTranslate = this.translateService.instant(this.entityName);
    const message = this.stringReplacementPipe.transform(
      this.translateService.instant('UI.Common.ConfirmLeave'),
      { '{entity}': entityNameTranslate }
    );
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmLabel = 'Confirm';

    modalRef.result.then((accepted: boolean) => {
      if (accepted && this.httpService) {
        this.isLeaving = true;
        this.isLeavingChange.emit(this.isLeaving);
        this.httpService
          .removeMyself(this.entityId)
          .pipe(
            untilDestroyed(this),
            finalize(() => {
              this.isLeaving = false;
              this.isLeavingChange.emit(this.isLeaving);
            })
          )
          .subscribe({
            next: () => {
              const currentUser =
                this.translateService.instant('UI.Common.You');
              const leaveMessage =
                this.entityName === EntityName.Event
                  ? 'UI.NewMessage.RemovedMyselfFromMemberInEvent'
                  : 'UI.NewMessage.RemovedMyselfFromMember';
              const leaveMessageTranslate =
                this.stringReplacementPipe.transform(
                  this.translateService.instant(leaveMessage),
                  { '{link}': entityNameTranslate }
                );
              this.toastService.showSuccess(
                `${currentUser} ${leaveMessageTranslate}`
              );
              this.afterLeaved.emit(true);
            },
            error: () => {
              this.toastService.showError('UI.Toast.UpdatedFailed');
              this.afterLeaved.emit(false);
            },
          });
      }
    });
  }

  ngOnDestroy(): void {
    /**/
  }
}
