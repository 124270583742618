import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChildren,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FilterPaneComponent } from '@src/app/components/entity-section/filter-pane/filter-pane.component';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES } from '@src/app/shared/constants/entities.const';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { FilterPaneUi } from '@src/app/shared/enums/venture.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { take } from 'rxjs';

@Component({
  selector: 'app-filter-pane-organization',
  templateUrl: './filter-pane-organization.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
    NgbActiveModal,
  ],
})
export class FilterPaneOrganizationComponent extends FilterPaneComponent {
  @Input() filterResultCount = 0;
  @Input() isFiltered: boolean;
  @Input() showFilterResult = false;
  @Output() displayFilterPane = new EventEmitter(false);
  filterPaneUi = FilterPaneUi.Simple;
  isClearAll = false;
  ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES =
    ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES;
  customSearchFn = FormUtils.customSearchFn;
  filterAttributeDescriptions: AttributeDescription[] = [];

  @ViewChildren('filterSelect') filterSelect: NgSelectComponent[];

  async generateFilterData(): Promise<void> {
    let orgFilterTokenId: number;
    orgFilterTokenId = this.orgId
      ? Number(this.orgId)
      : await this.getOrgFilterTokenId();

    this.httpService
      .getFilter(this.entityName, orgFilterTokenId?.toString())
      .pipe(untilDestroyed(this))
      .subscribe((attributeDescriptions: AttributeDescription[]) => {
        let orderedCustomAttributes = this.getOrderedCustomAttributes(
          attributeDescriptions
        );

        if (orderedCustomAttributes.length > 0) {
          orderedCustomAttributes.forEach((attrDes: AttributeDescription) => {
            if (
              attrDes.attributeType === AttributeType.SingleSelection ||
              attrDes.attributeType === AttributeType.MultipleSelection
            ) {
              if (attrDes.attributeType === AttributeType.SingleSelection) {
                if (attrDes.frequentlyUsedChoice) {
                  if (attrDes.frequentlyUsedChoice.length > 0) {
                    attrDes.choice.selections = [
                      ...attrDes.frequentlyUsedChoice,
                      ...attrDes.choice.selections,
                    ].sort((a, b) => a.order - b.order);
                    attrDes.frequentlyUsedChoice = [];
                  }
                }

                if (attrDes.defaultValue.codeId !== 'DefaultValue') {
                  const defaultValue = {
                    codeId: 'DefaultValue',
                    value: '--.--​​​​',
                    order: -1,
                    masterCodeId: null,
                  };

                  attrDes.defaultValue = defaultValue;
                  attrDes.choice.selections.unshift(defaultValue);
                }
                attrDes.choice.filteredSelections = attrDes.choice.selections;
              }
            }
          });
          this.filterAttributeDescriptions = orderedCustomAttributes;
          this.isShowfilter = true;
          this.setShowFilterPaneOnSession();

          if (this.filterAttributeDescriptions.length > 0) {
            this.displayFilterPane.emit(true);
          }

          if (!this.isShowFilterPane) {
            orderedCustomAttributes = [];
          }
          this.buildCustomAttributeForm(orderedCustomAttributes);
        } else {
          this.resetFilterPane();
        }
        this.onFilter();
      });
  }

  buildCustomAttributeForm(attrDes: AttributeDescription[] = []): void {
    this.customFormService.setCustomAttributeControlConfig(attrDes);
    const customControls = this.createControl(attrDes);
    this.form = this.fb.group(customControls);
    this.updateCustomFilter();
  }

  onValueSelectChange(
    value: any,
    attributeDescription: AttributeDescription
  ): void {
    const controlName = attributeDescription?.propertyName;
    if (this.form && controlName) {
      this.updateCustomFilter(controlName);
      this.updateActiveFilters();
    }
  }

  updateCustomFilter(controlName = '') {
    if (controlName) {
      this.updateDependantSelection(controlName);
    } else {
      const formValuesObj = this.form.getRawValue();
      for (const key of Object.keys(formValuesObj)) {
        this.updateDependantSelection(key);
      }
    }
  }
  protected setShowFilterPaneOnSession(): void {
    this.sessionService.hasFilterPane =
      this.isShowFilterPane && this.filterAttributeDescriptions.length > 0;
  }
  private updateDependantSelection(controlName: string) {
    this.filterAttributeDescriptions.forEach((attributeDescription) => {
      const masterChoiceId = attributeDescription.choice.masterCodeId;
      const control = this.form.get(attributeDescription.propertyName);
      if (masterChoiceId === controlName) {
        const value = this.form.get(controlName)?.value;
        if (value.codeId === 'DefaultValue') {
          control.disable();
          return;
        }
        control.enable();
        attributeDescription.choice.filteredSelections =
          attributeDescription.choice.selections.filter(
            (x) => x.masterCodeId === value.codeId
          );
      }
    });
  }

  clearSelectionFilter(): void {
    if (this.filterAttributeDescriptions.length > 0) {
      this.filterAttributeDescriptions.forEach(
        (attributeDescription: AttributeDescription) => {
          const attrDes = attributeDescription;
          this.form.patchValue({
            [attrDes.propertyName]: attrDes.defaultValue,
          });
        }
      );
    }
  }

  createControl(attrDescriptions: AttributeDescription[]) {
    const result = {};
    if (!!attrDescriptions && attrDescriptions.length > 0) {
      attrDescriptions.forEach((item) => {
        result[item.propertyName] = item.defaultValue;
      });
    }
    return result;
  }

  onClearAll(): void {
    this.filterSelect.forEach((comp) => {
      comp.searchTerm = '';
    });
    this.clearSelectionFilter();
    this.updateFilterDto();
    this.updateCustomFilter();
    this.numberOfFiltersApplied = 0;
    this.hasFilters = false;
    this.onFilter(true);
  }

  updateNumberOfFilter(): void {
    const control = this.form?.controls;
    if (!control) return;
    this.numberOfFiltersApplied = Object.values(control).reduce(
      (count, { value }) => {
        if (value) {
          if (
            typeof value === 'object' &&
            value.codeId &&
            value.codeId !== 'DefaultValue'
          ) {
            count++;
          }
        }
        return count;
      },
      0
    );
  }

  getOrderedCustomAttributes(
    attrDescriptions: AttributeDescription[]
  ): AttributeDescription[] {
    let orderedCustomAttributes = [];
    this.ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES.forEach((key) => {
      const attrDes = attrDescriptions.find(
        (attr) => attr.propertyName === key
      );
      if (attrDes) {
        orderedCustomAttributes.push(attrDes);
      }
    });
    return orderedCustomAttributes;
  }

  updateActiveFilters(): void {
    const hasActiveFilters = () => {
      for (const key in this.form.controls) {
        const value = this.form.controls[key].value;
        if (value && value.codeId !== 'DefaultValue') {
          return true;
        }
      }
      return false;
    };

    this.hasFilters = hasActiveFilters();
  }

  onFilter(isFromFilterBtn = false): void {
    this.updateFilterDto();
    super.onFilter(isFromFilterBtn);
  }

  private updateFilterDto() {
    if (!this.form) return;
    let filterDto = {};
    this.filterStoredService
      .getFilterPaneSource()
      .pipe(take(1))
      .subscribe((currentFilter: Record<string, any>) => {
        filterDto = currentFilter;
      });
    for (const key in this.form.value) {
      const value = this.form.value[key];
      const lowerCaseFirstLetterKey = StringUtils.toLowerCaseFirstLetter(key);
      if (!value || value.codeId === 'DefaultValue') {
        delete filterDto[lowerCaseFirstLetterKey];
      } else {
        filterDto[lowerCaseFirstLetterKey] = [value.codeId];
      }
    }

    this.filterStoredService.updateFilterPaneSource(filterDto);
    this.updateActiveFilters();
  }

  onFocus(select: NgSelectComponent) {
    select.searchTerm = '';
    select.placeholder = '';
  }
}
