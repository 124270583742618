import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { TimestampModel, TimestampPaginationPayload } from '@src/app/shared/interfaces/timestamp.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimestampsService {
  constructor(
    protected http: HttpClient
  ) { }

  paginate(payload: TimestampPaginationPayload): Observable<ApiGetResponse<TimestampModel>> {
    const url = `timestamps`;
    const params = Object.assign({}, payload) as Record<string, any>;
    return this.http.get<ApiGetResponse<TimestampModel>>(url, { params });
  }

  getAttributes(): Observable<AttributeDescription[]> {
    const url = `timestamps/attributes`;
    return this.http.get<AttributeDescription[]>(url);
  }
}
