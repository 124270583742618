<div
  #messageActionRef
  class="group-message-action jip-upper-first-letter"
  [innerHTML]="messageHtml"
></div>
<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [ignoreMapModal]="true"
  [modalTitle]="'UI.New-Message.Recipients' || message?.group?.name"
  [httpService]="httpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>
