import { NavInterface } from '@shared/interfaces/nav.interface';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { environment } from '@src/environments/environment';

export const navs: NavInterface[] = [
  { route: '/challenges', name: 'Calls & Challenges', jipIcon: 'lightbulb' },
  {
    route: '/ventures',
    name: 'Ideas & Ventures',
    jipIcon: 'unicorn',
    minWidth: 70,
  },
  { route: '/people', name: 'Network', jipIcon: 'people' },
  { route: '/events', name: 'Events', jipIcon: 'event' },
  {
    route: '/organizations',
    name: 'Organizations',
    jipIcon: 'organisation',
  },
];

export const newNavs: NavInterface[] = [
  { route: environment.jipUrl.news, name: 'News', jipIcon: InternalIcon.News },
  {
    route: '/challenges',
    name: 'Calls & Challenges',
    jipIcon: InternalIcon.Lightbulb,
    minWidth: 115,
  },
  {
    route: '/ventures',
    name: 'Ideas & Ventures',
    jipIcon: InternalIcon.Unicorn,
    minWidth: 78,
  },
  { route: '/people', name: 'Network', jipIcon: InternalIcon.Community },
  { route: '/events', name: 'Events', jipIcon: InternalIcon.Calendar },
  {
    route: '/organizations',
    name: 'Organizations',
    jipIcon: InternalIcon.Organization,
  },
];
