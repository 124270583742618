import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionService } from '@src/app/core/session.service';

@Component({
  selector: 'app-toggle-slider',
  templateUrl: './toggle-slider.component.html',
})
export class ToggleSliderComponent {
  @Input() status = false;
  @Input() shouldRequiredLogin = false;
  @Input() settings: {
    disabled?: boolean;
    activeText: string;
    activeClass?: string;
    deactiveText: string;
    activeIcon?: string;
    deactiveIcon?: string;
  };

  constructor(private sessionService: SessionService) {}

  @Output() statusChange = new EventEmitter<boolean>();

  toggle(active = null): void {
    if (
      (this.shouldRequiredLogin &&
        this.sessionService.isLogin &&
        this.sessionService.isTokenExpired()) ||
      (this.settings && this.settings.disabled)
    ) {
      return;
    }
    this.status = active !== null ? active : !this.status;
    this.statusChange.emit(this.status);
  }
}
