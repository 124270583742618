import { Component, Input } from '@angular/core';
import { environment } from '@src/environments/environment';
import { TagFieldType } from '../../tag-field/tag-field.component';

@Component({
  selector: 'app-tag-field-dropdown-item',
  templateUrl: './tag-field-dropdown-item.component.html',
})
export class TagFieldDropdownItemComponent {
  @Input() item: any;

  @Input() keyMapImg = 'image';

  TagFieldType = TagFieldType;

  defaultAvatar = environment.wordpressJipImages + 'avatar-square.png';
}
