<div
  *ngIf="!acceptedCookies && !isOpenSetttings && isBrowserCookiesEnabled"
  class="cookies-acceptance__container"
>
  <div class="cookies-acceptance__content">
    <div class="cookies-acceptance__notice d-flex align-items-center">
      {{ 'UI.Cookies.AcceptanceContent' | translate }}
    </div>
    <div class="cookies-acceptance__button d-flex">
      <button
        translate
        class="cookies-acceptance__button-settings text-uppercase btn btn-outline-primary background-white mr-3"
        (click)="openSettings()"
      >
        Learn More
      </button>

      <button
        translate
        class="cookies-acceptance__button-accept text-uppercase btn btn-primary"
        (click)="acceptCookies()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
