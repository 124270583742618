<div
  class="venture__people"
  [ngClass]="{ 'ml-xl-0': fromTableCell, 'ml-xl-5': !fromTableCell }"
  [ngClass]="styleClass"
>
  <!-- Initiator / Admin -->
  <ng-container *ngIf="!showOnlyStakeholder">
    <div
      class="list-inline-item venture__initiator col-4 p-0 mb-0"
      [class.m-0]="fromTableCell"
      [class.w-100]="fromTableCell"
      [class.col-4]="!fromTableCell"
    >
      <div
        class="venture__label widget__label widget__label--sm mb-0"
        translate
        *ngIf="showLabel"
      >
        Initiator
      </div>
      <ul class="list-inline">
        <li class="list-inline-item">
          <ng-container *ngIf="initiator; else deactiveUser">
            <a [href]="personBaseUrl + '/' + initiator.id">
              <span class="venture__people-wrapper">
                <img
                  class="fill-container"
                  [src]="initiator.image"
                  appDefaultAvatar
                  appDynamicImgUrl
                  alt=""
                  [ngbTooltip]="initiator.firstName + ' ' + initiator.lastName"
                  container="body"
                />
              </span>
            </a>
          </ng-container>
          <ng-template #deactiveUser>
            <span
              class="venture__people-wrapper position-relative"
              [ngbTooltip]="'UI.UnsubscribedUser' | translate"
              container="body"
            >
              <app-deactive-entity [smallAvatar]="true"></app-deactive-entity>
            </span>
          </ng-template>
        </li>
      </ul>
    </div>
  </ng-container>

  <!-- Team / Stakeholders -->
  <div
    *ngIf="stakeholders?.length > 0"
    class="list-inline-item venture__stakeholders col p-0 mb-0"
  >
    <div
      class="venture__label widget__label widget__label--sm mb-0"
      translate
      *ngIf="showLabel"
    >
      Stakeholders
    </div>

    <ul class="list-inline">
      <li
        *ngFor="let stakeholder of stakeholders.slice(0, numberOfDisplays)"
        class="list-inline-item venture__more-list"
      >
        <a [href]="personBaseUrl + '/' + stakeholder.id">
          <span class="venture__people-wrapper">
            <img
              class="fill-container"
              [src]="stakeholder.image"
              alt=""
              appDefaultAvatar
              appDynamicImgUrl
              [ngbTooltip]="stakeholder.firstName + ' ' + stakeholder.lastName"
              container="body"
            />
          </span>
        </a>
      </li>

      <li
        *ngIf="stakeholders.length > numberOfDisplays"
        class="list-inline-item venture__more-list"
      >
        <span class="venture__people-wrapper venture__more-list--add">
          +{{ stakeholders?.length - numberOfDisplays }}
        </span>
      </li>
    </ul>
  </div>
</div>
