import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree-view-check-box',
  templateUrl: './tree-view-check-box.component.html',
  styles: [],
})
export class TreeViewCheckBoxComponent implements OnInit {
  @Input() disabled: boolean;

  @Input() items: TreeNode[];

  @Output() treeChanged = new EventEmitter();

  selectedTreeNode: TreeNode | null;

  ngOnInit(): void {
    this.selectedTreeNode = null;
  }

  public selectNode(node: TreeNode, value: boolean): void {
    this.check(node, value);
    this.treeChanged.emit();
  }

  check(node: any, value: boolean): void {
    node.checked = value;

    if (node.children?.length) {
      node.children.forEach((x: any) => {
        this.check(x, value);
      });
    }
  }
}

export interface TreeNode {
  text: string;
  value: any;
  checked?: boolean;
  children?: TreeNode[];
}
