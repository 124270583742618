import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProfileEmailService } from '@core/authen/profile-email.service';
import { FormComponentInterface } from '@core/form/form-error.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { LogoDisplayOption } from '@shared/models/logo-type.model';
import { BaseComponentWithServiceComponent } from '@src/app/components/base/base-list/base-component-with-service/base-component-with-service.component';
import { FormErrorService } from '@src/app/core/form/form-error.service';
import { SessionService } from '@src/app/core/session.service';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { environment } from '@src/environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styles: [],
})
export class ChangeEmailComponent
  extends BaseComponentWithServiceComponent
  implements OnInit, FormComponentInterface
{
  // tslint:disable-next-line:variable-name
  @Input() logo_display_option: LogoDisplayOption;

  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;
  myProfileUrl = environment.jipUrl.profile;

  form: UntypedFormGroup;
  errorMessage: { [key: string]: string };
  formErrorKey;
  errorMessages: string;

  isSubmitting?: boolean;
  isEmailDelivered = false;
  data;

  constructor(
    private fb: UntypedFormBuilder,
    public profileEmailService: ProfileEmailService,
    public formErrorService: FormErrorService,
    protected sessionService: SessionService,
    public centralConfig: CentralConfigService
  ) {
    super(sessionService);
  }

  getForm(): UntypedFormGroup {
    return this.form;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [
        '',
        {
          validators: [Validators.required, Validators.email],
        },
      ],
      recaptcha: ['', Validators.required],
    });

    this.formErrorService.register(this);
  }

  onSubmit(): void {
    this.isSubmitting = true;
    this.profileEmailService
      .requestChangeEmail(this.form.value.email)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe((data: any) => {
        if (data.isEmailDelivered) {
          this.isEmailDelivered = true;
          this.data = data;
        } else {
          this.checkError(data);
        }
      });
  }

  resend(): void {
    this.isSubmitting = true;

    this.profileEmailService
      .resendChangeEmail(this.data.email, this.data.token)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe({
        next: (data: any) => {
          const error = data as ApiResponse;
          if (data.isEmailDelivered) {
            this.isEmailDelivered = true;
          } else {
            this.checkError(error);
          }
        },
        error: (error) => {
          this.checkError(error);
        },
      });
  }

  checkError(data): void {
    this.errorMessages = this.formErrorService.handleError(data);
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }
}
