import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { ApplicationRef, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// tslint:disable-next-line:max-line-length
import { NewsAndMessageListComponent } from '@app/components/news-messages/components/news-and-message-list/news-and-message-list.component';
import { SearchFilterComponent } from '@app/components/search-results/components/search-filter/search-filter.component';
import { SearchResultsSectionLayoutComponent } from '@app/components/search-results/components/search-results-section-layout/search-results-section-layout.component';
import { SearchResultsComponent } from '@app/components/search-results/containers/search-results/search-results.component';
import { BaseEventRegistrationComponent } from '@components/base/base-event-registration/base-event-registration.component';
import { CookiesAcceptanceComponent } from '@components/cookies-acceptance/cookies-acceptance.component';
import { CookiesSettingsComponent } from '@components/cookies-acceptance/cookies-settings/cookies-settings.component';
import { TagcloudComponent } from '@components/entity-section/tagcloud/tagcloud.component';
import { EventRegistrationFormComponent } from '@components/event-registration/components/event-registration-form/event-registration-form.component';
import { EventRegistrationComponent } from '@components/event-registration/containers/event-registration/event-registration.component';
import { EventEditComponent } from '@components/events/components/event/event-edit/event-edit.component';
import { EventComponent } from '@components/events/components/event/event.component';
import { EventTilesComponent } from '@components/list-tiles-widget/event-tiles/event-tiles.component';
import { NewsTilesItemComponent } from '@components/list-tiles-widget/news-tiles-item/news-tiles-item.component';
import { NewsTilesComponent } from '@components/list-tiles-widget/news-tiles/news-tiles.component';
import { OrganizationTilesComponent } from '@components/list-tiles-widget/organization-tiles/organization-tiles.component';
import { PeopleTilesComponent } from '@components/list-tiles-widget/people-tiles/people-tiles.component';
import { NewPostDialogComponent } from '@components/news-messages/components/new-post-dialog/new-post-dialog.component';
import { NewsMessagesFilterComponent } from '@components/news-messages/components/news-messages-filter/news-messages-filter.component';
import { NewsMessagesComponent } from '@components/news-messages/news-messages.component';
import { NotificationComponent } from '@components/notification/notification.component';
import { ToastsContainerComponent } from '@components/toasts-container/toasts-container.component';
import { VentureEditComponent } from '@components/ventures/components/venture/venture-edit/venture-edit.component';
import { BootstrapComponent } from '@core/components/bootstrap/bootstrap.component';
import { I18nService } from '@core/i18n/i18n.service';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { TranslateModule } from '@ngx-translate/core';
import { EntityCreationButtonComponent } from '@shared/components/atoms/entity-creation-button/entity-creation-button.component';
import { EntityCounterComponent } from '@shared/components/molecules/entity-counter/entity-counter.component';
import { SimpleFormLoginComponent } from '@shared/components/organisms/simple-form-login/simple-form-login.component';
import { ReportingChartComponent } from '@shared/components/reporting-chart/reporting-chart.component';
import { StorageEnum } from '@shared/enums/storage.enum';
import { FormRegisterComponent } from '@src/app/components/auth/login/form-register/form-register.component';
import { BaseDetailComponent } from '@src/app/components/base/base-detail/base-detail.component';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { BaseComponentWithServiceComponent } from '@src/app/components/base/base-list/base-component-with-service/base-component-with-service.component';
import { NavProfileComponent } from '@src/app/components/master-layout/navs/components/nav-profile/nav-profile.component';
import { TagCanvasModule } from 'ng-tagcanvas';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CookieService } from 'ngx-cookie-service';
import { EventBusService } from 'ngx-eventbus';
import { NgxVisibilityModule, NgxVisibilityService } from 'ngx-visibility';
import { AdminSettingsComponent } from './components/admin-settings/admin-settings.component';
import { OrganizationsAdminSectionComponent } from './components/admin-settings/organizations-admin-section/organizations-admin-section.component';
import { TenantConfigurationComponent } from './components/admin-settings/tenant-configuration/tenant-configuration.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { GoogleRecapchaComponent } from './components/auth/google-recapcha/google-recapcha.component';
import { ForgotPasswordComponent } from './components/auth/login/forgot-password/forgot-password.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ProcessTokenComponent } from './components/auth/process-token/process-token.component';
import { BaseWidgetComponent } from './components/base/base-widget/base-widget.component';
import { ChallengesComponent } from './components/challenges/challenges.component';
import { ChallengeComponent } from './components/challenges/components/challenge/challenge.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { CookiesWeAreSorryComponent } from './components/cookies-acceptance/cookies-operate/cookies-we-are-sorry/cookies-we-are-sorry.component';
import { CreateTimestampDialogComponent } from './components/dialogs/create-timestamp-dialog/create-timestamp-dialog.component';
import { GetInTouchDialogComponent } from './components/dialogs/get-in-touch-dialog/get-in-touch-dialog.component';
import { JoinInDialogComponent } from './components/dialogs/join-in-dialog/join-in-dialog.component';
import { MessageBackDialogComponent } from './components/dialogs/message-back-dialog/message-back-dialog.component';
import { MessageBackItemComponent } from './components/dialogs/message-back-dialog/message-back-item/message-back-item.component';
import { RemovePersonDialogComponent } from './components/dialogs/remove-person-dialog/remove-person-dialog.component';
import { SendMessageDialogComponent } from './components/dialogs/send-message-dialog/send-message-dialog.component';
import { ShareEntityDialogComponent } from './components/dialogs/share-entity-dialog/share-entity-dialog.component';
import { ShareEntityToPeopleDialogComponent } from './components/dialogs/share-entity-to-people-dialog/share-entity-to-people-dialog.component';
import { CustomAttributesComponent } from './components/entity-section/custom-attributes/custom-attributes.component';
import { EntityCardContainerComponent } from './components/entity-section/entity-card-container/entity-card-container.component';
import { EntityExportComponent } from './components/entity-section/entity-export/entity-export.component';
import { EntityListByTokenComponent } from './components/entity-section/entity-list-by-token/entity-list-by-token.component';
import { EntityOrganizationsComponent } from './components/entity-section/entity-organizations/entity-organizations.component';
import { FilterPaneComponent } from './components/entity-section/filter-pane/filter-pane.component';
import { ImagesPopupSelectionComponent } from './components/entity-section/images-popup-selection/images-popup-selection.component';
import { FollowInteractComponent } from './components/entity-section/interaction-bar/follow-interact/follow-interact.component';
import { InteractionBarPeopleComponent } from './components/entity-section/interaction-bar/interaction-bar-people/interaction-bar-people.component';
import { InteractionBarComponent } from './components/entity-section/interaction-bar/interaction-bar.component';
import { JoinInteractComponent } from './components/entity-section/interaction-bar/join-interact/join-interact.component';
import { JoinOrganizationComponent } from './components/entity-section/interaction-bar/join-interact/join-organization/join-organization.component';
import { JoinVentureComponent } from './components/entity-section/interaction-bar/join-interact/join-venture/join-venture.component';
import { TimestampHeaderComponent } from './components/entity-section/timestamp-section/timestamp-header/timestamp-header.component';
import { TimestampSectionComponent } from './components/entity-section/timestamp-section/timestamp-section.component';
import { WidgetMetadataTableChallengeComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table-challenge/widget-metadata-table-challenge.component';
import { WidgetMetadataTableEventComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table-event/widget-metadata-table-event.component';
import { WidgetMetadataTableOrganizationComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table-organization/widget-metadata-table-organization.component';
import { WidgetMetadataTablePeopleComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table-people/widget-metadata-table-people.component';
import { WidgetMetadataTableVentureComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table-venture/widget-metadata-table-venture.component';
import { WidgetMetadataTableComponent } from './components/entity-section/widget-metadata-table/widget-metadata-table.component';
import { EventExportComponent } from './components/events/components/event-export/event-export.component';
import { EventOrganizationsComponent } from './components/events/components/event/event-edit/event-organizations/event-organizations.component';
import { EventsModalComponent } from './components/events/components/events-modal/events-modal.component';
import { EventListComponent } from './components/events/events.component';
import { FormSummaryExportComponent } from './components/form/form-summary-export/form-summary-export.component';
import { UnsavedFormCheckComponent } from './components/form/unsaved-form-check/unsaved-form-check.component';
import { JipLogoComponent } from './components/jip-logo/jip-logo.component';
import { ChallengeTilesComponent } from './components/list-tiles-widget/challenge-tiles/challenge-tiles.component';
import { VentureTilesComponent } from './components/list-tiles-widget/venture-tiles/venture-tiles.component';
import { FooterComponent } from './components/master-layout/footer/footer.component';
import { PowerByBbvComponent } from './components/master-layout/footer/power-by-bbv/power-by-bbv.component';
import { VersionInfoComponent } from './components/master-layout/footer/version-info/version-info.component';
import { GlobalFilterComponent } from './components/master-layout/navs/components/global-filter/global-filter.component';
import { LanguagesComponent } from './components/master-layout/navs/components/languages/languages.component';
// tslint:disable-next-line:max-line-length
import { FullCalendarModule } from '@fullcalendar/angular';
import { EntityNotFoundDialogComponent } from '@src/app/components/dialogs/entity-not-found-dialog/entity-not-found-dialog.component';
import { SharedModule } from '@src/app/shared';
import { AdminDashboardComponent } from './components/admin-settings/admin-dashboard/admin-dashboard.component';
import { EventCalendarDialogComponent } from './components/dialogs/event-calendar-dialog/event-calendar-dialog.component';
import { EventPopoverWrapperComponent } from './components/dialogs/event-calendar-dialog/event-popover-wrapper/event-popover-wrapper.component';
import { SessionExpirationDialogComponent } from './components/dialogs/session-expiration-dialog/session-expiration-dialog.component';
import { EventPaymentComponent } from './components/events/components/event/event-edit/event-payment/event-payment/event-payment.component';
import { NavCustomLinkProfileComponent } from './components/master-layout/navs/components/nav-custom-link-profile/nav-custom-link-profile.component';
import { NavInformationComponent } from './components/master-layout/navs/components/nav-information/nav-information.component';
import { NavItemComponent } from './components/master-layout/navs/components/nav-item/nav-item.component';
import { NavNewsMessagesComponent } from './components/master-layout/navs/components/nav-news-messages/nav-news-messages.component';
import { ChannelSearchResultComponent } from './components/master-layout/navs/components/thread-filter/components/channel-search-result/channel-search-result.component';
import { SearchChannelHistoryComponent } from './components/master-layout/navs/components/thread-filter/components/search-channel-history/search-channel-history.component';
import { ThreadFilterComponent } from './components/master-layout/navs/components/thread-filter/thread-filter.component';
import { NavsComponent } from './components/master-layout/navs/navs.component';
import { PageCoverComponent } from './components/master-layout/page-cover/page-cover.component';
import { PageHeaderComponent } from './components/master-layout/page-header/page-header.component';
import { PeopleViewModalComponent } from './components/modals/people-view-modal/people-view-modal.component';
import { MoodboardEditComponent } from './components/moodboard/components/moodboard-edit/moodboard-edit.component';
import { MoodboardComponent } from './components/moodboard/moodboard.component';
import { BaseCardComponent } from './components/news-messages/components/base-card/base-card.component';
import { DeletePostDialogComponent } from './components/news-messages/components/delete-post-dialog/delete-post-dialog.component';
import { EventCardComponent } from './components/news-messages/components/event-card/event-card.component';
import { GetInTouchMessageCardComponent } from './components/news-messages/components/get-in-touch-message-card/get-in-touch-message-card.component';
import { GetInTouchMessageItemComponent } from './components/news-messages/components/get-in-touch-message-item/get-in-touch-message-item.component';
import { GroupMessageActionComponent } from './components/news-messages/components/group-message-card/group-message-action/group-message-action.component';
import { GroupMessageCardComponent } from './components/news-messages/components/group-message-card/group-message-card.component';
import { MessageCardFooterComponent } from './components/news-messages/components/message-card-footer/message-card-footer.component';
import { MessageCardComponent } from './components/news-messages/components/message-card/message-card.component';
import { MessageItemContainerComponent } from './components/news-messages/components/message-item-container/message-item-container.component';
import { MessageItemComponent } from './components/news-messages/components/message-item/message-item.component';
import { NewMessageDialogComponent } from './components/news-messages/components/new-message-dialog/new-message-dialog.component';
import { NewsItemComponent } from './components/news-messages/components/news-item/news-item.component';
import { OrganizationCardComponent } from './components/news-messages/components/organization-card/organization-card.component';
import { PersonCardComponent } from './components/news-messages/components/person-card/person-card.component';
import { VentureCardComponent } from './components/news-messages/components/venture-card/venture-card.component';
import { VenturePhaseCardComponent } from './components/news-messages/components/venture-phase-card/venture-phase-card.component';
import { OrganizationCustomAttributesComponent } from './components/organizations/components/organization/organization-edit/organization-custom-attributes/organization-custom-attributes/organization-custom-attributes.component';
import { OrganizationEditComponent } from './components/organizations/components/organization/organization-edit/organization-edit.component';
import { OrganizationComponent } from './components/organizations/components/organization/organization.component';
import { PartnerOganizationsComponent } from './components/organizations/components/organization/partner-oganizations/partner-oganizations.component';
import { PartnerOrganizationSelectComponent } from './components/organizations/components/organization/partner-organization-select/partner-organization-select.component';
import { OrganizationListComponent } from './components/organizations/organizations.component';
import { PendingPeopleCardsComponent } from './components/peoples/components/people-cards/pending-people-cards/pending-people-cards.component';
import { PeopleEditComponent } from './components/peoples/components/people/people-edit/people-edit.component';
import { PersonOrganisationsComponent } from './components/peoples/components/people/people-edit/person-organisations/person-organisations.component';
import { PeopleComponent } from './components/peoples/components/people/people.component';
import { ProfileComponent } from './components/peoples/components/people/profile/profile.component';
import { PeopleListComponent } from './components/peoples/peoples.component';
import { ChangeEmailComponent } from './components/profile-actions/change-email/change-email.component';
import { SocialLinksComponent } from './components/socials/social-links/social-links.component';
import { PublishVentureConfirmationDialogComponent } from './components/ventures/components/venture/publish-venture-confirmation-dialog/publish-venture-confirmation-dialog.component';
import { VentureDetailComponent } from './components/ventures/components/venture/venture-detail/venture-detail.component';
import { VentureTimestampComponent } from './components/ventures/components/venture/venture-timestamp/venture-timestamp.component';
import { VentureComponent } from './components/ventures/components/venture/venture.component';
import { VenturesComponent } from './components/ventures/ventures.component';
import { CoreModule } from './core/core.module';
import { CommunitySelectorComponent } from '@src/app/components/master-layout/list-layout-wrapper/components/community-selector/community-selector.component';

registerLocaleData(localeEN);
registerLocaleData(localeDe);
registerLocaleData(localeFr);

export const APP_DECLARATIONS: Array<any> = [
  NavsComponent,
  LanguagesComponent,
  VenturesComponent,
  VentureComponent,
  PeopleListComponent,
  EventListComponent,
  OrganizationListComponent,
  LoginComponent,
  FormRegisterComponent,
  NotificationComponent,
  CookiesAcceptanceComponent,
  CookiesSettingsComponent,
  BaseComponentWithServiceComponent,
  VentureEditComponent,
  TagcloudComponent,
  ToastsContainerComponent,
  EventComponent,
  NavProfileComponent,
  EventsModalComponent,
  EventRegistrationComponent,
  NewsMessagesFilterComponent,
  BaseEventRegistrationComponent,
  EventRegistrationComponent,
  EventRegistrationFormComponent,
];

@NgModule({
  declarations: [
    ...APP_DECLARATIONS,
    EventEditComponent,
    BaseDetailComponent,
    BaseEntityEditComponent,
    NewsMessagesComponent,
    EventCardComponent,
    VentureCardComponent,
    PersonCardComponent,
    OrganizationCardComponent,
    MessageCardComponent,
    BaseCardComponent,
    PeopleComponent,
    PeopleEditComponent,
    MoodboardComponent,
    VentureDetailComponent,
    VenturePhaseCardComponent,
    MessageBackDialogComponent,
    MessageBackItemComponent,
    SendMessageDialogComponent,
    InteractionBarPeopleComponent,
    NavNewsMessagesComponent,
    MessageCardFooterComponent,
    SocialLinksComponent,
    NewsItemComponent,
    MessageItemComponent,
    GetInTouchMessageItemComponent,
    GetInTouchMessageCardComponent,
    ProfileComponent,
    GetInTouchDialogComponent,
    ShareEntityDialogComponent,
    MoodboardEditComponent,
    ImagesPopupSelectionComponent,
    ConfirmationComponent,
    OrganizationComponent,
    OrganizationEditComponent,
    RemovePersonDialogComponent,
    PublishVentureConfirmationDialogComponent,
    UnsavedFormCheckComponent,
    ProcessTokenComponent,
    CookiesWeAreSorryComponent,
    FooterComponent,
    FormSummaryExportComponent,
    ChangePasswordComponent,
    NewMessageDialogComponent,
    NewPostDialogComponent,
    ForgotPasswordComponent,
    GroupMessageCardComponent,
    GroupMessageActionComponent,
    ChangeEmailComponent,
    GoogleRecapchaComponent,
    PartnerOganizationsComponent,
    ChallengesComponent,
    ChallengeComponent,
    NavItemComponent,
    JipLogoComponent,
    VersionInfoComponent,
    PowerByBbvComponent,
    PageHeaderComponent,
    PageCoverComponent,
    InteractionBarComponent,
    CustomAttributesComponent,
    FilterPaneComponent,
    PendingPeopleCardsComponent,
    JoinInteractComponent,
    JoinInDialogComponent,
    ShareEntityToPeopleDialogComponent,
    EntityCardContainerComponent,
    EntityListByTokenComponent,
    CreateTimestampDialogComponent,
    TimestampSectionComponent,
    VentureTimestampComponent,
    TimestampHeaderComponent,
    EventOrganizationsComponent,
    EntityOrganizationsComponent,
    EventExportComponent,
    EntityExportComponent,
    JoinVentureComponent,
    JoinOrganizationComponent,
    FollowInteractComponent,
    PeopleViewModalComponent,
    AdminSettingsComponent,
    OrganizationsAdminSectionComponent,
    TenantConfigurationComponent,
    WidgetMetadataTableComponent,
    WidgetMetadataTableOrganizationComponent,
    WidgetMetadataTableVentureComponent,
    WidgetMetadataTableEventComponent,
    WidgetMetadataTablePeopleComponent,
    WidgetMetadataTableChallengeComponent,
    SearchResultsComponent,
    SearchResultsSectionLayoutComponent,
    SearchFilterComponent,
    NewsAndMessageListComponent,
    ChallengeTilesComponent,
    BaseWidgetComponent,
    VentureTilesComponent,
    NewsTilesComponent,
    MessageItemContainerComponent,
    NewsMessagesFilterComponent,
    BaseEventRegistrationComponent,
    EventRegistrationFormComponent,
    PeopleTilesComponent,
    OrganizationTilesComponent,
    EventTilesComponent,
    NewsTilesItemComponent,
    NavCustomLinkProfileComponent,
    NavInformationComponent,
    PartnerOrganizationSelectComponent,
    EventCalendarDialogComponent,
    EventPopoverWrapperComponent,
    AdminDashboardComponent,
    ThreadFilterComponent,
    ChannelSearchResultComponent,
    SearchChannelHistoryComponent,
    DeletePostDialogComponent,
    SessionExpirationDialogComponent,
    OrganizationCustomAttributesComponent,
    EntityNotFoundDialogComponent,
    EventPaymentComponent,
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    TagCanvasModule.forRoot(),
    CoreModule,
    VirtualScrollerModule,
    NgxCaptchaModule,
    NgxVisibilityModule,
    FullCalendarModule,
    SharedModule,
  ],
  bootstrap: [],
  providers: [
    NgxVisibilityService,
    EventBusService,
    CookieService,
    { provide: LOCALE_ID, useFactory: getCurrentLocale, deps: [CookieService] },
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false },
  ],
})
export class AppModule {
  constructor(private injector: Injector, private i18nService: I18nService) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    customElements.define(
      'jip-navs',
      createCustomElement(NavsComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-languages',
      createCustomElement(LanguagesComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-nav-profile',
      createCustomElement(NavCustomLinkProfileComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-nav-news-messages',
      createCustomElement(NavNewsMessagesComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-ventures',
      createCustomElement(VenturesComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-venture',
      createCustomElement(VentureComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-peoples',
      createCustomElement(PeopleListComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-events',
      createCustomElement(EventListComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-event',
      createCustomElement(EventComponent, { injector: this.injector })
    );
    customElements.define(
      'jip-organizations',
      createCustomElement(OrganizationListComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-login',
      createCustomElement(LoginComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-cookies-acceptance',
      createCustomElement(CookiesAcceptanceComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-news-messages',
      createCustomElement(NewsMessagesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-people',
      createCustomElement(PeopleComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-profile',
      createCustomElement(ProfileComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-organization',
      createCustomElement(OrganizationComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-process-token',
      createCustomElement(ProcessTokenComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-footer',
      createCustomElement(FooterComponent, {
        injector: this.injector,
      })
    );

    customElements.define(
      'jip-version-info',
      createCustomElement(VersionInfoComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-power-by-bbv',
      createCustomElement(PowerByBbvComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-change-password',
      createCustomElement(ChangePasswordComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-change-email',
      createCustomElement(ChangeEmailComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-challenges',
      createCustomElement(ChallengesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-challenge',
      createCustomElement(ChallengeComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-page-header',
      createCustomElement(PageHeaderComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-page-cover',
      createCustomElement(PageCoverComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-entity-list-by-token',
      createCustomElement(EntityListByTokenComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-admin-settings',
      createCustomElement(AdminSettingsComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-widget-metadata-table',
      createCustomElement(WidgetMetadataTableComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-search-results',
      createCustomElement(SearchResultsComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-reporting-chart',
      createCustomElement(ReportingChartComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-challenge-tiles',
      createCustomElement(ChallengeTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-venture-tiles',
      createCustomElement(VentureTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-news-tiles',
      createCustomElement(NewsTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-people-tiles',
      createCustomElement(PeopleTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-organization-tiles',
      createCustomElement(OrganizationTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-event-tiles',
      createCustomElement(EventTilesComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-event-registration',
      createCustomElement(EventRegistrationComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-event-registration-form',
      createCustomElement(EventRegistrationFormComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-entity-creation-button',
      createCustomElement(EntityCreationButtonComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-entity-counter',
      createCustomElement(EntityCounterComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-bootstrap',
      createCustomElement(BootstrapComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-simple-form-login',
      createCustomElement(SimpleFormLoginComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-global-filter',
      createCustomElement(GlobalFilterComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-thread-filter',
      createCustomElement(ThreadFilterComponent, {
        injector: this.injector,
      })
    );
    customElements.define(
      'jip-community-selector',
      createCustomElement(CommunitySelectorComponent, {
        injector: this.injector,
      })
    );
  }
}

export function getCurrentLocale(cookieService: CookieService): string {
  let locale: string;
  try {
    locale = cookieService.get(StorageEnum.locale);
  } catch (error) {}
  return locale || 'en';
}
