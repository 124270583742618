import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { ProfileEmailService } from '@core/authen/profile-email.service';
import { SessionService } from '@core/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TermOfUseComponent } from '@shared/components/molecules/term-of-use/term-of-use.component';
import { SectionId } from '@shared/enums/section-id.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { TokenTypes } from '@shared/enums/token-type';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  TokenValidation,
  VerifyInvititationsPayload,
} from '@shared/interfaces/token-validation';
import { UserInterface } from '@shared/interfaces/user.interface';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { HttpStatusCode } from '@src/app/shared/enums/httpstatuscode.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { LinkWrapperPipe } from '@src/app/shared/pipes/link-wrapper.pipe';
import { StringReplacementPipe } from '@src/app/shared/pipes/string-replacement.pipe';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { BaseComponentWithServiceComponent } from '../../base/base-list/base-component-with-service/base-component-with-service.component';
import { EventPaymentService } from '@src/app/core/http/event-payment.service';

@Component({
  selector: 'app-process-token',
  templateUrl: './process-token.component.html',
})
export class ProcessTokenComponent
  extends BaseComponentWithServiceComponent
  implements OnChanges, AfterViewChecked, OnDestroy
{
  @Input() token: string;
  @Input() code: string; // LinkedIn code

  message: string;
  toEmail: string;

  isLoading = true;

  registerEmailExpire = false;
  resendEmailLoading = false;
  confirmationEmail: string;

  event: EventInterface;

  constructor(
    public authenService: AuthenService,
    public sessionService: SessionService,
    private translateService: TranslateService,
    private profileEmailService: ProfileEmailService,
    private modalService: NgbModal,
    private linkWrapperPipe: LinkWrapperPipe,
    private stringReplacementPipe: StringReplacementPipe,
    private toastService: ToastService,
    private eventHttpService: EventHttpService,
    private eventPaymentService: EventPaymentService
  ) {
    super(sessionService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.processWithSearchParams();

    this.sessionService.apiReady$
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe({
        next: () => {
          if (this.token) {
            this.processToken(this.token);
          }
          if (this.code) {
            this.processLinkedin(this.code);
          }
        },
      });
  }

  ngAfterViewChecked(): void {
    if (!this.token && !this.code) {
      this.goTo(environment.jipUrl.home);
    }
  }

  ngOnDestroy(): void {
    /**/
  }

  processWithSearchParams(): void {
    const linkedInErrorCode = StringUtils.getParamFromUrl(UrlParam.Error);

    if (linkedInErrorCode) {
      this.goTo(environment.jipUrl.login);
    }
  }

  processResetPasswordConfirmation(token: string, toEmail: string): void {
    const url = `${environment.jipUrl.changePassword}?token=${token}&${
      UrlParam.ChangeFor
    }=${encodeURIComponent(toEmail)}`;

    this.goTo(url);
  }

  processToRegistrationPage(token: string, event?: EventInterface): void {
    let url = `${environment.jipUrl.login}?token=${token}`;

    if(event && event.hasPayment) {
      url = `${environment.jipUrl.login}?token=${token}&register-event=${event.id}&event-date-time=${event.eventDateTime}`;
    }
    
    this.goTo(url);
  }

  goTo(url: string): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }

  processChangeEmail(token: string, email: string): void {
    this.profileEmailService
      .processChangeEmail(email, token)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            this.showError(error, `Cannot change account email to ${email}`);
          } else {
            const redirectUrl =
              localStorage.getItem(StorageEnum.previousUrl) ||
              this.sessionService.getLoginCallBackpage() ||
              environment.jipUrl.home;

            localStorage.removeItem(StorageEnum.previousUrl);

            if (this.sessionService.isLogin) {
              this.goTo(redirectUrl);
            } else {
              this.goTo(environment.jipUrl.login);
            }
          }
        },
        error: (error) => {
          this.showError(error, `Cannot change account email to ${email}`);
        },
      });
  }

  processRegisterEvent(eventId: number, email: string): void {
    const encodedEmail = encodeURIComponent(email);
    this.goTo(
      `${environment.jipUrl.eventRegisterForm}/${eventId}/?${UrlParam.Email}=${encodedEmail}`
    );
  }

  resendConfirmationEmail(email: string): void {
    this.resendEmailLoading = true;

    const sentToEmail = email?.trim();
    this.authenService.resendConfirmationEmail(sentToEmail).subscribe({
      next: () => {
        this.toastService.showSuccess('UI.Toast.SentSuccessfully');
        this.resendEmailLoading = false;
      },
      error: () => {
        this.resendEmailLoading = false;
      },
    });
  }

  private processToken(token: string): void {
    this.isLoading = true;
    this.authenService
      .getTokenValidation(token)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        const uriTokenData = data as TokenValidation;

        if (!uriTokenData) return;

        if (uriTokenData.isExpired) {
          if (uriTokenData.isConfirmed) {
            const hereWord = this.translateService.instant(
              'UI.ProcessToken.Here'
            );
            this.message = this.stringReplacementPipe.transform(
              this.translateService.instant('UI.ProcessToken.AlreadyConfirmed'),
              {
                '{here}': this.linkWrapperPipe.transform(
                  this.sessionService.appendLanguagePath('/'),
                  hereWord,
                  '',
                  'card-action__link'
                ),
              }
            );
          } else if (uriTokenData.type === TokenTypes.RegisterNewUser) {
            this.registerEmailExpire = true;
            this.confirmationEmail = uriTokenData.toEmail;
          } else {
            this.message = this.translateService.instant(
              'UI.ProcessToken.ExpiredLink'
            );
          }
        } else {
          this.classifyTokenValidation(token, uriTokenData);
        }
      });
  }

  private processLinkedin(code: string): void {
    const eventId = StringUtils.getParamFromUrl(UrlParam.RegisterEvent);

    let orgId: number;
    const pathAppended = this.sessionService.appendLanguagePath(
      environment.jipUrl.processToken
    );
    let applyPendingProcess: number;
    const autoAssignment = StringUtils.getParamFromUrl(UrlParam.AutoAssignment);

    let redirectUri = `${window.location.origin}${pathAppended}`;

    if (eventId) {
      redirectUri += `?${UrlParam.RegisterEvent}=${eventId}`;

      this.processEventRegistration(code, redirectUri, +eventId);
      return;
    }

    if (autoAssignment) {
      orgId = Number(autoAssignment.split('-')[0]);
      applyPendingProcess = Number(autoAssignment.split('-')[1]);
      redirectUri += `?${UrlParam.AutoAssignment}=${orgId}-${applyPendingProcess}`;
    }

    this.isLoading = true;

    if (autoAssignment) {
      this.authenService
        .loginWithLinkedIn(
          code,
          redirectUri,
          undefined,
          +orgId,
          Boolean(applyPendingProcess)
        )
        .pipe(
          finalize(() => (this.isLoading = false)),
          untilDestroyed(this)
        )
        .subscribe((data: UserInterface | string) => {
          this.setEventDetailLoginCallback(eventId);

          if (!(data as UserInterface).accessToken) {
            this.showTermOfUse(
              data as string,
              +orgId,
              Boolean(applyPendingProcess)
            );
          } else {
            this.authenService.handleLoginSuccess(data as UserInterface);
            this.goBack();
          }
        });
    } else {
      this.authenService
        .loginWithLinkedIn(code, redirectUri)
        .pipe(
          finalize(() => (this.isLoading = false)),
          untilDestroyed(this)
        )
        .subscribe((data: UserInterface | string) => {
          this.setEventDetailLoginCallback(eventId);

          if (!(data as UserInterface).accessToken) {
            this.showTermOfUse(data as string);
          } else {
            this.authenService.handleLoginSuccess(data as UserInterface);
            this.goBack();
          }
        });
    }
  }

  private setEventDetailLoginCallback(eventId: string): void {
    if (!eventId) return;
    const registerEventUrl = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.event}/${eventId}?${UrlParam.RegisterEvent}=true`
    );

    this.sessionService.setLoginCallBackpage(registerEventUrl);
  }

  private processGetInTouchMessageLink(
    messageId: number,
    toPersonId: number
  ): void {
    const storedKey = {
      messageId,
      toPersonId,
    };

    const newsMessagesUrl = environment.jipUrl.messages;
    localStorage.setItem(
      StorageEnum.tokenMessageKey,
      JSON.stringify(storedKey)
    );

    if (this.sessionService.isLogin) {
      this.goTo(newsMessagesUrl);
    } else {
      this.sessionService.setLoginCallBackpage(
        this.sessionService.appendLanguagePath(newsMessagesUrl)
      );
      this.goTo(environment.jipUrl.login);
    }
  }

  private processRegisterNewUserConfirmation(
    token: string,
    email: string
  ): void {
    this.authenService
      .activeAccount(email, token)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            this.message = `Cannot active account email ${email}`;
            console.log(error);
          } else {
            this.handleRegisterCallbackPage(data);
            const eventId = (data as any).invitationEventIds[0];

            if(eventId) {
              this.eventHttpService.read(eventId).pipe(untilDestroyed(this)).subscribe((event: EventInterface) => {
                const url = `?register-event=${event.id}&event-date-time=${event.eventDateTime}`
                this.goTo(environment.jipUrl.login + url);
              })
            } else {
              this.goTo(environment.jipUrl.login);
            }
          }
        },
        error: (error) => {
          this.message = `Cannot active account email ${email}`;
          console.log(error);
        },
      });
  }

  private classifyTokenValidation(
    token: string,
    uriTokenData: TokenValidation
  ): void {
    switch (uriTokenData.type) {
      case TokenTypes.GetInTouchMessage: {
        this.processGetInTouchMessageLink(
          uriTokenData.entityId,
          uriTokenData.toPersonId
        );
        break;
      }
      case TokenTypes.InvitePeople:
        this.processToRegistrationPage(token);
        break;

      case TokenTypes.InviteVenture:
      case TokenTypes.InviteOrganization:
      case TokenTypes.InviteChallenge:
      case TokenTypes.InviteChallengeJuror:
        this.processInviteToEntity(token, uriTokenData);
        break;
      case TokenTypes.InviteEvent:
        this.processInviteToEvent(token, uriTokenData);
        break;
      case TokenTypes.RegisterNewUser: {
        this.processRegisterNewUserConfirmation(token, uriTokenData.toEmail);
        break;
      }
      case TokenTypes.ResetPassword: {
        this.processResetPasswordConfirmation(token, uriTokenData.toEmail);
        break;
      }
      case TokenTypes.ChangeEmail: {
        this.processChangeEmail(token, uriTokenData.toEmail);
        break;
      }
      case TokenTypes.RegisterEvent:
        this.processRegisterEvent(uriTokenData.entityId, uriTokenData.toEmail);
        break;
      default: {
        break;
      }
    }
  }

  processInviteToEntity(token: string, uriTokenData: TokenValidation) {
    const entityDetailUrl = this.buildEntityDetailsUrl(uriTokenData);

    if (uriTokenData.isEntityDeleted) {
      this.goTo(environment.jipUrl.deleted);
      return;
    }

    if (this.sessionService.isLogin) {
      this.processToNavigateToEntity(token, entityDetailUrl);
    } else {
      this.sessionService.setLoginCallBackpage(
        this.sessionService.appendLanguagePath(entityDetailUrl)
      );
      this.processToRegistrationPage(token);
    }
  }

  processInviteToEvent(token: string, uriTokenData: TokenValidation) {
    this.eventHttpService
      .read(uriTokenData.entityId)
      .pipe(untilDestroyed(this))
      .subscribe((event: EventInterface) => {
        this.event = event;
        const entityDetailUrl = this.buildEntityDetailsUrl(uriTokenData);

        if (uriTokenData.isEntityDeleted) {
          this.goTo(environment.jipUrl.deleted);
          return;
        }

        if (this.sessionService.isLogin) {
          if(event.hasPayment) {
            this.eventPaymentService.handleEventPayment(event)
          } else {
            this.processToNavigateToEntity(token, entityDetailUrl);
          }
        } else {
          this.sessionService.setLoginCallBackpage(
            this.sessionService.appendLanguagePath(entityDetailUrl)
          );
          this.processToRegistrationPage(token, event);
        }
      });
  }

  private handleRegisterCallbackPage(data: any): void {
    const orgId = data.invitationOranizationIds[0];
    const ventureId = data.invitationVentureIds[0];
    let entityDetailUrl = '';
    if (orgId) {
      entityDetailUrl = environment.jipUrl.organizations + '/' + orgId;
    } else if (ventureId) {
      entityDetailUrl = environment.jipUrl.venture + '/' + ventureId;
    } 
    if (entityDetailUrl) {
      const registerCallBackPage =
        this.sessionService.appendLanguagePath(entityDetailUrl);
      this.sessionService.setLoginCallBackpage(registerCallBackPage);
    }
  }

  private processToNavigateToEntity(
    token: string,
    entityDetailUrl: string
  ): void {
    const payload: VerifyInvititationsPayload = {
      invitedTokens: [token],
    };
    this.authenService
      .verifyInvititations(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.goTo(entityDetailUrl);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) {
            this.goTo(environment.jipUrl.deleted);
          }
        },
      });
  }

  private showError(data, defaultMessage = ''): void {
    const errorObj = FormUtils.getErrorObject(data);
    const messages = Object.values(errorObj).join(' - ');
    this.message = messages || defaultMessage;
  }

  private showTermOfUse(
    linkedinToken: string,
    assignToOrgId?: number,
    applyPendingProcess?: boolean
  ): void {
    this.modalService
      .open(TermOfUseComponent, {
        windowClass: 'modal-term-of-use',
        centered: true,
        backdrop: 'static',
        size: 'md',
      })
      .result.then((isAccept: boolean) => {
        if (isAccept) {
          this.authenService
            .registerAccount(
              linkedinToken,
              assignToOrgId ? assignToOrgId : undefined,
              applyPendingProcess ? applyPendingProcess : undefined
            )
            .subscribe((data: UserInterface) => {
              this.authenService.handleLoginSuccess(data);
              if (this.event.hasPayment) {
                this.eventPaymentService.handleEventPayment(this.event);
              } else {
                this.goBack();
              }
            });
        } else {
          const url = environment.jipUrl.login;
          this.goTo(url);
        }
      });
  }

  private goBack(): void {
    const url = this.sessionService.getLoginCallBackpage() || location.origin;
    FormUtils.navigateTo(url);
  }

  private buildEntityDetailsUrl(uriTokenData: TokenValidation): string {
    const { type, entityId } = uriTokenData;
    const entitiesPath = StringUtils.getEntityUrlByTokenTypes(type);
    const fragment: string =
      type === TokenTypes.InviteChallengeJuror ? `#${SectionId.JuryPanel}` : '';

    return `${entitiesPath}/${entityId}/${fragment}`;
  }

  processEventRegistration(
    code: string,
    redirectUri: string,
    id: number
  ): void {
    this.isLoading = true;
    this.eventHttpService
      .read(id)
      .pipe(
        switchMap((event: EventInterface) => {
          this.event = event;
          return this.authenService
            .loginWithLinkedIn(code, redirectUri, !event.hasPayment && id)
            .pipe(
              finalize(() => (this.isLoading = false)),
              untilDestroyed(this)
            );
        })
      )
      .subscribe((data: UserInterface | string) => {
        this.setEventDetailLoginCallback(id.toString());

        if (!(data as UserInterface).accessToken) {
          this.showTermOfUse(data as string);
        } else {
          this.authenService.handleLoginSuccess(data as UserInterface);
          if (this.event.hasPayment) {
            this.eventPaymentService.handleEventPayment(this.event);
          } else {
            this.goBack();
          }
        }
      });
  }
}
