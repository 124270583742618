import { Injectable } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventPaymentService {
  constructor(
    private sessionService: SessionService,
    private eventHttpService: EventHttpService
  ) {}

  handleEventPayment(event: EventInterface) {
    const targetDateTimeZoneOffset = TimeUtils.getTargetDateTimezone(
      event.eventDateTime
    );

    this.eventHttpService
      .checkout(event.id, { targetTimezone: targetDateTimeZoneOffset })
      .subscribe({
        next: (res: string) => {
          const registerEventUrl = this.sessionService.appendLanguagePath(
            `${environment.jipUrl.event}/${event.id}`
          );

          this.sessionService.setLoginCallBackpage(registerEventUrl);
          window.open(res, '_blank');
          this.goBack();
        },
      });
  }

  private goBack(): void {
    const url = this.sessionService.getLoginCallBackpage() || location.origin;
    FormUtils.navigateTo(url);
  }
}
