<app-card-layout #cardLayout [cardClass]="'news-tile-item'">
  <!--        Image-->
  <ng-container slot="header">
    <app-news-card-header
      [news]="news"
      [sender]="news.from | youTransform : userId"
      [currentPersonId]="userId"
      styleClass="mt-3"
    ></app-news-card-header>
  </ng-container>
  <ng-container slot="img">
    <ng-container
      *ngIf="
        news.action === MessageAction.Post
          ? defaultImage
          : defaultAvatar as placeholderImg
      "
    >
      <app-image-wrapper
        imgAlt="news image"
        [wrapperClasses]="
          'widget__image-wrapper entity__image-wrapper card-item__image-wrapper'
        "
        [imgObject]="news.content.image || placeholderImg"
        [placeholderImg]="placeholderImg"
      >
      </app-image-wrapper>
    </ng-container>
  </ng-container>

  <!--        Content-->
  <ng-container *ngIf="news.content as content" slot="body">
    <ng-container
      *ngIf="news.action === MessageAction.Post; else nonePostTemplate"
    >
      <div
        [appSmoothHeight]="cardLayout.shouldExpandContent"
        (click)="$event.stopPropagation()"
        class="simple-card__description"
      >
        <app-rich-text-toggle
          (viewMoreClicked)="cardLayout.expandContent()"
          [className]="'h-100 w-100 d-inline-block'"
          [controlStyleByCentralConfig]="true"
          [expandedMode]="cardLayout.shouldExpandContent"
          [isRevertCaret]="true"
          [minNumOfRows]="3"
          [text]="news.message"
          controlName="{{ 'dummyName' + uid }}"
          [coverImage]="content.image"
        >
        </app-rich-text-toggle>
      </div>
    </ng-container>
    <ng-template #nonePostTemplate>
      <h3 class="simple-card__title widget__heading">
        {{ content | entityDisplayText : content.entityName : 'headline' }}
      </h3>
      <div class="simple-card__description">
        <app-rich-text-toggle
          (viewMoreClicked)="cardLayout.expandContent()"
          [className]="'h-100 w-100 d-inline-block'"
          [controlStyleByCentralConfig]="true"
          [expandedMode]="cardLayout.shouldExpandContent"
          [isRevertCaret]="true"
          [minNumOfRows]="3"
          [text]="
            content | entityDisplayText : content.entityName : 'subHeadline'
          "
          controlName="{{ 'dummyName' + uid }}"
          [coverImage]="content.image"
        >
        </app-rich-text-toggle>
      </div>
    </ng-template>
  </ng-container>

  <!--        Footer-->
  <ng-container slot="footer">
    <app-news-card-footer
      (commentModalClosed)="onSyncCommentStateCount($event)"
      [news]="news"
      [currentPersonId]="userId"
    ></app-news-card-footer>
  </ng-container>
</app-card-layout>
