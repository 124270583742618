<ng-container *ngIf="message">
  <app-message-header-wrapper
    [people]="sender"
    [isSystemNews]="message.isSystemNews"
    [messageDate]="message.dateTime"
  >
    <ng-container
      *ngIf="
        message.messageType !== MessageType.Message && entityName;
        else groupMessageAction
      "
    >
      <div class="news-messages__owner-name flex-shrink-0">
        <ng-container *ngIf="message.from; else replaceText">
          <a
            *ngIf="
              message.action !== MessageAction.VentureSubmittedOrganization
            "
            appUnsavedFormCheck
            [href]="peopleURL + '/' + message.from.id"
            class="d-inline-block jip-upper-first-letter btn-link-primary"
            >{{ getSender() }}</a
          >
          <app-bot-flag *ngIf="message.from?.isBot" [parentClass]="'ml-1 d-inline-block'"></app-bot-flag
          >&nbsp;</ng-container
        >
        <ng-template #replaceText>
          <ng-container *ngIf="!message.isSystemNews; else portalPart">
            <span class="deactive-label">{{
              'UI.UnsubscribedUser' | translate
            }}</span
            >&nbsp;</ng-container
          >
          <ng-template #portalPart>
            <a [href]="portalUrl">{{ portalName }}</a>
          </ng-template>
        </ng-template>
      </div>

      <app-card-action
        [id]="message.content?.instanceId"
        [content]="message.content"
        [actionType]="message.action"
        [entityName]="entityName"
        [from]="message.from"
        [toItems]="message.group?.toItems || []"
        [currentPersonId]="currentPersonId"
      ></app-card-action>
    </ng-container>
    <ng-template #groupMessageAction>
      <app-group-message-action
        [message]="message"
        [currentPersonId]="currentPersonId"
      ></app-group-message-action>
    </ng-template>
  </app-message-header-wrapper>

  <div class="news-messages__body">
    <app-message-card [message]="message" [currentPersonId]="currentPersonId">
    </app-message-card>

    <ng-container
      *ngIf="message.messageType !== MessageType.Message && entityName"
    >
      <app-entity-card-container
        [uiTemplate]="uiTemplate"
        [entity]="message.content"
        [entityName]="entityName"
      >
      </app-entity-card-container>
    </ng-container>
  </div>
</ng-container>
