import { Injectable } from '@angular/core';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportHttpServiceService extends BaseHttpService<any> {
  getApiRootPath(): string {
    return 'reporting';
  }

  getReportForEventOwner(eventId: number, params: Record<string, any>): Observable<any> {
    const url = `${this.apiUrl}/event/${eventId}`;
    return this.downloadRerportFile(url, params);
  }

  getReportForOrgOwner(orgId: number, params): Observable<any> {
    const url = `${this.apiUrl}/organization/${orgId}`;
    return this.downloadRerportFile(url, params);
  }

  getReportForAdmin(params): Observable<any> {
    const url = `${this.apiUrl}/all`;
    return this.downloadRerportFile(url, params);
  }

  downloadRerportFile(url: string, params): Observable<any> {
    return this.http.get(url, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
