import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SessionService } from '@core/session.service';
import { EntityStateCountComponent } from '@src/app/shared/components/entity-state-count/entity-state-count.component';

@Component({
  selector: 'app-base-component-with-service',
  template: '',
})
export class BaseComponentWithServiceComponent
  extends EntityStateCountComponent
  implements OnChanges
{
  @Input() domain: string;

  history = window.history;

  constructor(protected sessionService: SessionService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.domain?.currentValue && this.domain) {
      this.sessionService.setDomain(this.domain);
    }
  }

  backToPreviousPage(ele: HTMLElement): void {
    history.back();

    setTimeout(() => {
      ele.classList.add('disabled-input');
    });
  }
}
