<ng-container
  [appScrollToFragment]="data$ | async"
  *ngIf="data$ | async as response; else skeleton"
>
  <ng-container *ngIf="response.items?.length; else emptyContent">
    <ng-container *ngIf="!isLoading; else skeleton">
      <div
        *ngIf="
          response.items | jointStateMapper : eventService | async as events;
          else skeleton
        "
        class="list-tiles"
        [class.widget__card--list--background]="!bg_color"
        [style.background-color]="bg_color"
        [class.two-columns]="columns === '2'"
      >
        <div
          (click)="goToEntityDetails(event.id)"
          *ngFor="let event of events"
          class="list-tiles__item"
        >
          <app-card-layout [cardClass]="'event-tile-item'">
            <!--        Image-->
            <ng-container slot="img">
              <app-image-wrapper
                [wrapperClasses]="
                  'widget__image-wrapper entity__image-wrapper'
                "
                [imgObject]="event.image"
              >
                <app-event-stamp
                  [event]="event"
                  [uiPosition]="uiPosition"
                  [wrapperClass]="'small-stamp'"
                ></app-event-stamp>
                <span
                  class="simple-card__floating organization__logo position-absolute"
                >
                  <img
                    [src]="event.leadCompany?.logo?.url"
                    appDefaultPlaceholder
                    appDynamicImgUrl
                    alt=""
                  />
                </span>

                <app-event-location-flag
                  [event]="event"
                  [uiPosition]="eventLocationFlagPos"
                  [className]="'flat-flag'"
                  flagStyle="minimal"
                ></app-event-location-flag>
              </app-image-wrapper>
            </ng-container>

            <!--        Content-->
            <ng-container slot="body">
              <h3 class="simple-card__title widget__heading">
                {{ event.title }}
              </h3>
              <div class="simple-card__description widget__content">
                <p appEllipsis [innerHTML]="event.description"></p>
              </div>
            </ng-container>

            <!--        Footer-->
            <ng-container slot="footer">
              <div class="p-3">
                <app-event-card-footer
                  [event]="event | eventBindingVariables"
                  [eventService]="eventService"
                  (jointEvent)="afterJoinEvent($event)"
                ></app-event-card-footer>
              </div>
            </ng-container>
          </app-card-layout>
        </div>
      </div>
    </ng-container>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="searchEntitiesService.changePaging($event)"
      [page]="searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>

    <div class="text-right" *ngIf="show_creation_btn">
      <button
        class="btn btn-primary widget__btn-create my-3"
        [callBackLoginPage]="entityCreationPath"
        appRequireLogin
        translate
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>

<ng-template #skeleton>
  <div class="loading-skeleton">
    <div class="row">
      <div
        *ngFor="let item of skeletonCount"
        class="col-12"
        [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="skeleton-item img-skeleton"></div>
          <div class="card-body">
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6 class="mb-4">Card title</h6>
            <hr />
            <h6 class="mt-4 w-25">Card title</h6>
            <div class="d-flex justify-content-between">
              <h6 class="w-50">Card title</h6>
              <h6 class="w-50">Card title</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
