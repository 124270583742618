import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { ChallengeHttpService } from '@core/http/challenge-http.service';
import { PeopleHttpService } from '@core/http/people-http.service';
import { VentureHttpService } from '@core/http/venture.http.service';
import { ToastService } from '@core/toast.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import { ChallengeCardListComponent } from '@shared/components/organisms/challenge-card-list/challenge-card-list.component';
import { VentureListComponent } from '@shared/components/organisms/venture-list/venture-list.component';
import { VentureSubmitionInformModalComponent } from '@shared/components/organisms/venture-submition-inform-modal/venture-submition-inform-modal.component';
import { EntityName } from '@shared/enums/entity-name.enum';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { Observable, Subject, defer, from, of } from 'rxjs';
import {
  finalize,
  first,
  map,
  pluck,
  repeatWhen,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-submit-ventures',
  templateUrl: './submit-ventures.component.html',
})
export class SubmitVenturesComponent
  extends EntityStateCountComponent
  implements OnDestroy
{
  @Input() challengeId: number;
  @Input() ventureId: number;
  @Input() venture: VentureInterface;
  @Input() disabled: boolean;
  @Input() shouldOpenModal = true;
  @Input() modalHeader = 'UI.Common.SubmitToChallenge';
  @Input() globalOrgId = 0;

  @Output() modalClosed = new EventEmitter<boolean>();

  @ViewChild('modalRef') modalRef: ElementRef;

  pageSize = PageSizeConfig.EightItemsFirstPage;
  collectionSize = 0;
  pageIndex = 1;
  keyword = '';
  loading = true;
  isEmpty = false;
  isLoadingCommentsCount: boolean;
  showPeopleModal: boolean;
  selectedEntityId: number;
  selectedEntity: VentureInterface;

  private componentRef: ComponentRef<any>;
  private hasUpdated = false;

  private readonly fetchData$ = new Subject<void>();
  constructor(
    public readonly ventureService: VentureHttpService,
    private readonly modalService: NgbModal,
    private readonly challengeHttpService: ChallengeHttpService,
    private readonly peopleHttpService: PeopleHttpService,
    private readonly authService: AuthenService,
    private readonly resolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private readonly appRef: ApplicationRef,
    private readonly toast: ToastService
  ) {
    super();
  }

  ngOnDestroy(): void {
    /**/
  }

  @HostListener('click', ['$event'])
  openModal(): void {
    if (!this.shouldOpenModal) return;

    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
        container: 'body',
      })
      .result.then(() => {
        this.modalClosed.emit(this.hasUpdated);
        this.hasUpdated = false;
        this.loading = true;
      });

    this.loadComponent();
  }

  onPageChange(pageIndex: number): void {
    this.pageIndex = pageIndex;
    this.loading = true;
    this.fetchData$.next();
  }

  searchVenture(): void {
    this.loading = true;
    this.fetchData$.next();
  }

  showEntityData(entity: VentureInterface): void {
    this.showPeopleModal = true;
    if (entity) {
      this.selectedEntityId = null;
      this.selectedEntity = null;
      setTimeout(() => {
        this.selectedEntityId = entity.id;
        this.selectedEntity = entity;
      });
    }
  }

  checkNDA(entity: VentureInterface): Observable<boolean> {
    if (entity.isNDARequired) {
      const modalRef = this.modalService.open(
        VentureSubmitionInformModalComponent,
        {
          windowClass: '',
          centered: true,
          backdrop: 'static',
          size: 'md',
        }
      );

      return from(modalRef.result);
    }

    return of(true);
  }

  private loadComponent(): void {
    this.componentRef = this.resolver
      .resolveComponentFactory<
        ChallengeCardListComponent | VentureListComponent
      >(this.ventureId ? ChallengeCardListComponent : VentureListComponent)
      .create(this.injector);

    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<unknown>)
      .rootNodes[0] as HTMLElement;

    const containerEle = document.getElementById('modalContent');

    if (!containerEle) return;

    containerEle.appendChild(domElem);

    this.setCompData(this.componentRef.instance);

    this.subscribeEvent(this.componentRef.instance);
  }

  private setCompData(
    component: ChallengeCardListComponent | VentureListComponent
  ): void {
    this.componentRef.instance.checkNDAFn = this.venture
      ? this.checkNDA.bind(this, this.venture)
      : this.checkNDA.bind(this);

    const items$ = defer(() =>
      !!this.ventureId ? this.fetchChallenges() : this.fetchVentures()
    ).pipe(
      repeatWhen(() => this.fetchData$.asObservable()),
      tap(({ rowCount }) => {
        this.collectionSize = rowCount ?? this.collectionSize;
      }),
      pluck('items'),
      finalize(() => (this.loading = false)),
      untilDestroyed(this),
      takeUntil(this.modalClosed)
    );

    items$.subscribe((items: VentureInterface[] | LeadCompany[]) => {
      component.items = items;
      this.loading = false;
      this.isEmpty = items?.length === 0;
    });
  }

  private subscribeEvent(
    component: ChallengeCardListComponent | VentureListComponent
  ): void {
    component.submittedVentureToChallenge.subscribe((res) => {
      const ids = {
        challengeId: this.challengeId,
        ventureId: this.ventureId,
        ...res,
      };

      this.submitVentureToChallenge(ids.ventureId, ids.challengeId);
    });

    if (component instanceof VentureListComponent) {
      component.commentModalClosed.subscribe(() => {
        this.syncCommentsState();
      });

      component.openedFollower.subscribe((venture: VentureInterface) => {
        this.showEntityData(venture);
      });

      component.venturesChanged.subscribe(() => {
        this.fetchData$.next();
      });
    }
  }

  private fetchVentures(): Observable<any> {
    const body = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      keyword: this.keyword,
      excludeOrganizationIds: [this.challengeId],
    };

    if (this.globalOrgId) {
      body[UrlParam.FilterTokenId] = this.globalOrgId;
    }

    return this.authService.getProfile().pipe(
      switchMap((profile: UserInterface) =>
        this.peopleHttpService.paginateByEntity(
          environment.jipUrl.venture,
          profile.id,
          body,
          'people'
        )
      ),
      map((res) => {
        this.runEntityStateCount(
          EntityName.Venture,
          res.items,
          this.ventureService
        );
        return res;
      }),
      first() // Complete observable for repeatWhen operator
    );
  }

  private fetchChallenges(): Observable<any> {
    const params = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      showAsChallenge: true,
      keyword: this.keyword,
      excludeVentureIds: [this.ventureId],
    };

    return this.challengeHttpService.paginateX(
      params,
      OrgsSearchMode.ForSubmitting
    );
  }

  private submitVentureToChallenge(
    ventureId: number,
    challengeId: number
  ): void {
    this.ventureService
      .submitVentureToChallenge(ventureId, challengeId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.fetchData$.next();
        this.toast.showSuccess('UI.Toast.SubmittedSuccessfully');
        this.hasUpdated = true;
      });
  }
}
