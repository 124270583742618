<ng-container *ngIf="entity">
  <ng-container *ngIf="!sessionService.isLogin; else loggedUser">
    <button
      *ngIf="isAllowToJoin"
      class="btn btn-interact btn-interact--small btn-join-org"
      [ngClass]="{
        'show-interact-text': !hideTextSmall
      }"
      appRequireLogin
    >
      <i class="fas fa-user-friends"></i>
      <span
        [ngClass]="{
          'text-hide-small': hideTextSmall
        }"
      >
        {{ 'UI.Interaction.JoinOrganization' | translate }}</span
      >
    </button>
  </ng-container>

  <ng-template #loggedUser>
    <ng-container *ngIf="isSyncLatestJoinState; else joinTemp">
      <i class="fas fa-spinner fa-spin"></i>
    </ng-container>
    <ng-template #joinTemp>
      <ng-container *ngIf="entity.canJoin && isAllowToJoin">
        <button
          class="btn btn-interact btn-interact--small btn-join-org"
          [ngClass]="{
            'show-interact-text': !hideTextSmall
          }"
          appRequireLogin
          appDebounceClick
          (debounceClick)="join($event, entity)"
        >
          <i class="fas fa-user-friends"></i>
          <span
            [ngClass]="{
              'text-hide-small': hideTextSmall
            }"
          >
            {{ 'UI.Interaction.JoinOrganization' | translate }}</span
          >
        </button>
      </ng-container>

      <ng-container *ngIf="entity.hasJoined">
        <button
          class="btn btn-interact btn-interact--small btn-is-member"
          [ngClass]="{
            'show-interact-text': !hideTextSmall
          }"
        >
          <i class="fas fa-check-circle"></i>
          <span
            [ngClass]="{
              'text-hide-small': hideTextSmall
            }"
          >
            {{ 'UI.Interaction.ImMember' | translate }}</span
          >
        </button>
      </ng-container>

      <ng-container *ngIf="entity.isPending">
        <button
          class="btn btn-interact btn-interact--small btn-joining"
          [ngClass]="{
            'show-interact-text': !hideTextSmall
          }"
        >
          <i class="fas fa-clock"></i>
          <span
            [ngClass]="{
              'text-hide-small': hideTextSmall
            }"
          >
            {{ 'UI.Interaction.Joining' | translate }}</span
          >
        </button>
      </ng-container>
    </ng-template>
  </ng-template>
</ng-container>
