import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { DirectMessage } from '@shared/interfaces/direct-message.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { MessageType } from '@src/app/shared/enums/entity-name.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { TextDirection } from '@src/app/shared/enums/text-direction.enum';
import { ChatMessage } from '@src/app/shared/interfaces/chat-message.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { EventBusService } from 'ngx-eventbus';
import { Subscription } from 'rxjs';
import { SendMessageBoxBaseComponent } from '../send-message-box-base/send-message-box-base.component';

@Component({
  selector: 'app-message-back-dialog',
  templateUrl: './message-back-dialog.component.html',
  providers: [
    { provide: SendMessageHttpService, useClass: SendMessageHttpService },
  ],
})
export class MessageBackDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() message: ChatMessage;
  @Input() profileImage: string;

  currentUser: UserInterface;
  messages: ChatMessage[] = [];
  requestSubscription: Subscription;

  @ViewChild('messageForm') messageForm: FormFieldCustomComponent;

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected authenService: AuthenService,
    protected injector: Injector,
    private eventBus: EventBusService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );
  }

  ngOnInit(): void {
    this.setMessages();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.scrollToLastMessage();
  }

  sendMesageBack(): void {
    if (!this.message) {
      return;
    }
    this.isSubmitting = true;
    const payload: DirectMessage = {
      content: this.content?.value,
      isSendMail: !!this.isSendMail?.value,
      imageId: this.imageId?.value,
    };

    this.requestSubscription = this.sendMessageHttpService
      .reply(this.message.id, payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res: any) => {
          if (res > 0) {
            if (!this.messages) {
              this.messages = [];
            }
            const reply = {
              id: res,
              from: this.currentUser,
              content: '' + this.content?.value,
              messageType: MessageType.Message,
              dateTime: new Date(),
              image: this.messageForm?.imageInputDisplay?.imgUrl,
              parentId: this.message.id,
              isForward: payload.isSendMail,
              isCC: this.currentUser.isCCMyselfSetting,
            } as any;

            this.messages.push(reply);
            if (this.message.replies) {
              this.message.replies.push(reply);
            } else {
              this.message.replies = [reply];
            }
            this.message.latestMessage = reply;
            this.form.reset({ Content: '', imageId: '' });
            this.messageForm?.imageInputDisplay?.setImage({});

            this.triggerMessageChanged();
            this.scrollToLastMessage();
          }
          const error = res as ApiResponse;
          if (error && error.status === 400) {
            console.log(error);
          }
          this.isSubmitting = false;
        },
        error: (error) => {
          console.log(error);
          this.isSubmitting = false;
        },
      });
  }

  triggerMessageChanged(): void {
    this.message.replyCount =
      this.messages?.length > 0 ? this.messages.length - 1 : 0;
    this.eventBus.triggerEvent(GlobalEventBus.ListItemUpdate, {
      ...this.message,
    });
  }

  close(): void {
    this.triggerMessageChanged();
    super.close();
  }

  getMessageDirection(msg: ChatMessage): TextDirection {
    return this.currentUser.id === msg.from?.id
      ? TextDirection.Right
      : TextDirection.Left;
  }

  scrollToLastMessage(): void {
    setTimeout(() => {
      const messagesRef: HTMLElement = document.querySelector(
        '.message-back-dialog-body'
      );

      if (!messagesRef) {
        return;
      }

      messagesRef.scroll({
        top: messagesRef.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 100);
  }

  isHideSendBtn(): boolean {
    if (this.message) {
      if (this.message.isGroup) {
        return !this.message.from;
      } else {
        return !this.message.from || !this.message.to;
      }
    }
    return false;
  }

  private setMessages(): void {
    if (!this.message) {
      return;
    }

    let replies = [];
    if (this.message.replies?.length > 0) {
      replies = this.message.replies;
    }

    this.messages = [this.message, ...replies];
  }

  protected handleAfterLoadProfileData(userProfile: UserInterface): void {
    super.handleAfterLoadProfileData(userProfile);
    this.currentUser = userProfile;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

  afterDeleteThreadMessage(id: number): void {
    this.message.replies = this.message.replies.filter((msg) => msg.id !== id);
    const { length } = this.message.replies;

    if (length > 0) {
      const lastReplyMessageIndex = length - 1;
      if (
        this.message.latestMessage.id !==
        this.message.replies[lastReplyMessageIndex].id
      ) {
        this.message.latestMessage =
          this.message.replies[lastReplyMessageIndex];
      }
    } else {
      this.message.latestMessage = null;
    }

    this.setMessages();
    this.triggerMessageChanged();
  }
}
