export enum PartnerStatusEnum {
  Hide = 'Hide',
  Free = 'DefaultValue',
  Silver = 'Silver',
  Gold = 'Gold',
  Platinum = 'Platinum'
}

export enum EventPartnerStatusCodeIdEnum {
  DefaultValue = 'DefaultValue',
  BronzePartner = 'BronzePartner',
  SilverPartner = 'SilverPartner',
  GoldPartner = 'GoldPartner',
  PlatinumPartner = 'PlatinumPartner',
  DiamondPartner = 'DiamondPartner',
  MediaPartner = 'MediaPartner',
  ExecutionPartner = 'ExecutionPartner',
}
