<div
  id="timestamp-header"
  class="timestamp-header"
  *ngIf="timestampInfo"
  [ngStyle]="{ 'width.px': getHeaderParentWidth() }"
>
  <div class="d-flex justify-content-between">
    <div class="row mr-0">
      <div class="col-12 col-md-6 d-flex align-items-center">
        <span>
          {{ 'UI.Timestamp.HeaderInfo' | translate }}:
          <b>
            {{
              timestampInfo.createdDate | localizedDate: DateFormat.Timestamp
            }}
          </b>
        </span>
      </div>
      <div class="col-12 col-md-6 d-flex flex-column flex-lg-row pl-xl-0">
        <div class="d-flex align-items-center mr-4">
          <span class="mr-2"> {{ 'UI.TimestampTypes' | translate }}</span>
          <div class="d-flex align-items-center mt-1 mt-sm-0">
            <img
              appDefaultPlaceholder
              appDynamicImgUrl
              [src]="getTimestampIcon(timestampInfo.timestampTypeCodeId)"
              class="dropdown-img mr-1"
            />
            <span> {{ timestampInfo.timestampType }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-2 white-space--no-wrap">
            {{ 'UI.Common.CreatedBy' | translate }}:</span
          >
          <div class="d-flex align-items-center">
            <app-venture-people
              [initiator]="timestampInfo.creator"
              [showLabel]="false"
              [fromTableCell]="true"
            >
            </app-venture-people>
            <span *ngIf="timestampInfo.creator">{{
              timestampInfo.creator?.firstName +
                ' ' +
                timestampInfo.creator?.lastName
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 mt-1 mt-sm-0">
        <span> {{ 'UI.Timestamp.HeaderWarn' | translate }} </span>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <i class="fas fa-times pointer-cursor" (click)="close()"></i>
    </div>
  </div>
</div>
