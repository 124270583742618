import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-not-available-dialog',
  templateUrl: './service-not-available-dialog.component.html',
})
export class ServiceNotAvailableDialogComponent {
  @Input() message = '';

  constructor(protected activeModal: NgbActiveModal) {}

  confirm() {
    this.activeModal.close();
  }
}
