import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InternalIcon } from '@shared/enums/internal-icon.enum';

@Component({
  selector: 'app-switch-view-control',
  templateUrl: './switch-view-control.component.html',
})
export class SwitchViewControlComponent {
  @Input() isGridView = true;
  @Output() isGridViewChange = new EventEmitter<boolean>();
  @Output() showExpandTable = new EventEmitter();

  InternalIcon = InternalIcon;

  onChangeView(event: Event, mode: boolean): void {
    event.stopPropagation();
    this.isGridView = mode;
    this.isGridViewChange.emit(mode);
  }

  onExpandTable(event: Event): void {
    event.stopPropagation();
    this.showExpandTable.emit();
  }
}
