<div class="form-check d-flex align-items-start">
  <div class="d-flex align-items-center form-check-input-container">
    <input
      (change)="onValueChange($event.target?.checked)"
      [checked]="value"
      [value]="value"
      autocomplete="off"
      class="my-auto"
      id="termAgreementCheckbox"
      type="checkbox"
    />
  </div>
  <label class="form-check-label" for="termAgreementCheckbox">
    {{ 'I hereby agree to the' | translate }}
    <a
      [value]="termOfUseUrl"
      class="jip-login__text-underline"
      target="_blank"
      rel="noopener"
      appUrlPrefix
    >
      {{ 'UI.TermsOfUse' | translate }}
    </a>
    {{ 'Of-JointCreate.Com' | translate : { portalName: portalName } }}
  </label>
</div>
