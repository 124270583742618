import { Component, Input } from '@angular/core';
import { CentralConfigService } from '@core/services/central-config.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoDisplayOption } from '@shared/models/logo-type.model';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';

@Component({
  selector: 'app-cookies-settings',
  templateUrl: './cookies-settings.component.html',
})
export class CookiesSettingsComponent {
  constructor(
    public activeModal: NgbActiveModal,
    public centralConfig: CentralConfigService
  ) {}
  @Input() logoDisplayOption: LogoDisplayOption;

  @Input() globalOrgId: string; // Central config

  @Input() @ToBoolean() hidePoweredBy: boolean; // Central config

  @Input() poweredByLogo: 'bbv' | 'jointcreate' | 'none';

  isActiveStrictlyCookies = true;

  saveSettings(): void {
    this.activeModal.close({ strictlyCookies: this.isActiveStrictlyCookies });
  }
}
