import { Pipe, PipeTransform } from '@angular/core';
import { EventInterface } from '@shared/interfaces/event.interface';
import { EventUtils } from '@shared/utils/event-utils';

@Pipe({
  name: 'eventBindingVariables',
})
export class EventBindingVariablesPipe implements PipeTransform {
  transform(event: EventInterface): EventInterface {
    return EventUtils.syncEventBidingVariables(event);
  }
}
