<div class="search-input-wrapper">
  <ng-content></ng-content>
  <div *ngIf="editMode" class="search-input-wrapper__controls">
    <em
      *ngIf="!!searchText"
      appDebounceClick
      (debounceClick)="resetSearch()"
      class="far fa-times search-input-wrapper__reset-search"
      focusable="false"
    >
    </em>
    <i
      appDebounceClick
      (debounceClick)="search()"
      class="fa fa-search pointer-cursor search-input-wrapper__btn-search"
    ></i>
  </div>
</div>
