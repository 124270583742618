import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { VentureSubmissionStatus as State } from '@shared/enums/venture.enum';

export type Filter = State | 'All';

@Component({
  selector: 'app-venture-stages-menu-bar',
  templateUrl: './venture-stages-menu-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VentureStagesMenuBarComponent {
  @Input() currentFilter: Filter;

  @Input() stateCount: Record<string, number>;

  @Output() filterChanged = new EventEmitter<Filter>();

  readonly filters: Record<string, string>[] = [
    {
      key: 'All',
      label: 'UI.Common.AllResults',
    },
    {
      key: State.Submitted,
      label: 'UI.Ventures.Status.Submitted',
    },
    {
      key: State.Accepted,
      label: 'UI.Ventures.Status.Accepted',
    },
    {
      key: State.Awarded,
      label: 'UI.Ventures.Status.Awarded',
    },
  ];

  onFilterChange(event: Filter): void {
    this.currentFilter = event;

    this.filterChanged.emit(event);
  }
}
