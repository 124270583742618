import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EntityCreationModalService } from '@core/services/entity-creation-modal.service';
import { environment } from '@env/environment';
import { EntityName } from '@shared/enums/entity-name.enum';
import { UrlParam } from '@shared/enums/url-param.enum';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-entity-creation-button',
  templateUrl: './entity-creation-button.component.html',
})
export class EntityCreationButtonComponent implements OnChanges {
  // tslint:disable-next-line:variable-name
  @Input() org_id: string;

  // tslint:disable-next-line:variable-name
  @Input() entity_name: EntityName;

  creationPath = '';

  readonly EntityName = EntityName;

  constructor(
    private readonly createEntityModalService: EntityCreationModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.org_id || !this.entity_name) return;

    if (this.entity_name === EntityName.Venture) {
      const queryParams = !isNaN(Number(this.org_id))
        ? `?${UrlParam.LeadOrgId}=${this.org_id}`
        : '';

      this.creationPath += `${environment.jipUrl.venture}/create${queryParams}`;
    }
  }

  createEntity(entityName: EntityName): void {
    switch (entityName) {
      case EntityName.Post:
        this.createEntityModalService.createPost(
          this.org_id ? +this.org_id : null
        );
        break;
      case EntityName.Message:
        this.createEntityModalService.createMessage();
        break;
      default:
        break;
    }
  }

  shouldShowButton(): boolean {
    const isMessage = !!StringUtils.getParamFromUrl(UrlParam.TabNews);
    return this.entity_name === EntityName.Post ? !isMessage : isMessage;
  }
}
