<div class="login-with-linkedin-btn form-group text-center">
  <div class="explain-text-linkedin">
    {{
      'UI.ExplainTextForLinkedinButton'
        | translate
        | stringReplacement
          : {
              '{tenantName}': tenantName
            }
    }}
  </div>
  <button
    type="button"
    class="btn"
    (click)="redirectToLinkedInLoginPage(); emitEvent()"
  >
    <div class="d-flex align-items-center font-size-sm">
      <img
        width="26"
        class="mr-3"
        src="/wp-content/themes/jip/assets/images/LI-In-Bug.png"
        alt="logo-linkedin"
      />
      <span class="text-uppercase text-white font-weight-bold">{{
        text | translate
      }}</span>
    </div>
  </button>
</div>
