import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-venture-submition-inform-modal',
  templateUrl: './venture-submition-inform-modal.component.html',
})
export class VentureSubmitionInformModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
