import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { environment } from '@env/environment';
import { UrlParam } from '@shared/enums/url-param.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
})
export class EmailInputComponent implements OnInit, OnDestroy {
  @Input() eventId: number;

  @Output() resent = new EventEmitter();

  form: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
  });

  isEmailExisted: boolean;

  validating = false;

  resending = false;

  validated = false;

  loginUrl: string;

  constructor(private authService: AuthenService) {}

  get emailControl(): AbstractControl {
    return this.form?.get('email');
  }

  ngOnInit(): void {
    this.subscribeToEmailChanges();
  }

  ngOnDestroy(): void {
    /**/
  }

  validateEmail(): void {
    this.validating = true;
    this.checkMailAndSendInvitation()
      .pipe(
        finalize(() => (this.validating = false)),
        untilDestroyed(this)
      )
      .subscribe((isExited: boolean) => {
        this.validated = true;
        this.isEmailExisted = isExited;
      });
  }

  resendEmail(): void {
    this.resending = true;
    this.checkMailAndSendInvitation()
      .pipe(finalize(() => (this.resending = false)))
      .subscribe(() => {
        this.resent.emit();
      });
  }

  private checkMailAndSendInvitation(): Observable<boolean> {
    return this.authService.checkExistedEmail(
      this.emailControl.value,
      this.eventId.toString()
    );
  }

  private subscribeToEmailChanges(): void {
    this.emailControl.valueChanges.subscribe((email) => {
      this.loginUrl = `${environment.jipUrl.login}?${UrlParam.Email}=${email}&${UrlParam.RegisterEvent}=${this.eventId}`;

      this.validated = false;
    });
  }

  resetMailValue(): void {
    this.form.reset();
  }
}
