import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SearchChannelsService } from '@src/app/components/master-layout/navs/components/thread-filter/services/search-channels.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';

@Component({
  selector: 'app-search-channel-history',
  templateUrl: './search-channel-history.component.html',
  styles: [],
})
export class SearchChannelHistoryComponent implements OnInit, OnDestroy {
  @Input() activeFilter: EntityName;
  @Output() selectedChannel = new EventEmitter();

  isVisble: boolean = true;
  historyChannels = [];
  EntityName = EntityName;
  tenantId: number;

  constructor(
    private searchChannelService: SearchChannelsService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.searchChannelService.keyword$
      .pipe(untilDestroyed(this))
      .subscribe((keyword: string) => {
        this.isVisble = !Boolean(keyword);
      });

    this.searchChannelService.historyChannels$
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => {
        this.historyChannels = data.filter(
          (channel) => channel.tenantId === +this.sessionService.getTenantId()
        );
      });

  }

  deleteHistoryChannel(channel): void {
    this.searchChannelService.deleteChannel(channel);
  }

  onSelectChannel(item) {
    this.selectedChannel.emit(item);
  }

  ngOnDestroy(): void {
    /** */
  }
}
