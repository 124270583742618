<div
  class="tag-field-expanded w-100"
  [hidden]="disabledInput"
  [class.viewing]="!editable"
>
  <tag-input
    #tagInput
    *ngIf="!hideTagInput"
    (ngModelChange)="emitValue($event)"
    (onAdd)="addTag($event)"
    (onFocus)="editable && myDrop.open()"
    (onRemove)="myDrop.close(); removeTag($event)"
    (onTextChange)="searchItems($event + '')"
    [onTextChangeDebounce]="300"
    [(ngModel)]="items"
    [placeholder]="placeholder"
    [secondaryPlaceholder]="placeholder"
    [inputClass]="'form-control'"
    [addOnBlur]="true"
  >
  </tag-input>

  <div
    #myDrop="ngbDropdown"
    (openChange)="onOpenChange($event)"
    class="w-100 d-inline-block"
    ngbDropdown
    placement="bottom"
    [ngClass]="dropdownClass"
  >
    <div class="w-100 toggle-anchor" ngbDropdownToggle></div>

    <div class="dropdown-panel w-100" ngbDropdownMenu>
      <div class="overflow-auto container d-flex h-100 w-100 p-3">
        <div
          *ngIf="rightItems?.length"
          class="right-column flex-1 h-100 d-flex flex-column"
        >
          <div
            class="tag-field--common tag-field__header pl-0 pl-sm-3 mb-sm-3 mb-2 flex-shrink-0"
          >
            {{ 'Suggestions' | translate }}
          </div>

          <div
            (scroll)="onScroll($event)"
            class="pl-0 pl-sm-3 flex-1 h-100 overflow-auto flex-1"
          >
            <div class="items-container d-flex">
              <div
                *ngFor="let item of rightItems; trackBy: trackByFn"
                class="mr-0 mr-sm-3 mb-sm-3 mb-2 suggestion-item"
              >
                <div
                  (click)="onSelect(item)"
                  class="tag-field--common tag-field__default"
                >
                  <div class="text-truncate tag-field__default--medium-text">
                    {{ item.display }}
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  (searchData$.loading$ | async) && searchData$.hasMore$ | async
                "
                class="mr-0 mr-sm-3 mb-sm-3 mb-2 d-flex justify-content-center align-items-center"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="!rightItems?.length && (searchData$?.loading$ | async)"
          class="h-100 w-100 d-flex justify-content-center align-items-center"
        >
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>
