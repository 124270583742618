<ng-container [ngSwitch]="deactiveShowType">
  <div class="deactive-entity">
    <!-- Avatar -->
    <ng-container *ngSwitchCase="deactiveShowTypeEnum.Avatar">
      <div
        class="deactive-entity__avatar d-flex justify-content-center align-items-center"
        [ngClass]="{ 'small-avatar': smallAvatar }"
      >
        <ng-container *ngIf="entityName === EntityName.Person">
          <i class="fas fa-user-alt-slash"></i>
        </ng-container>
        <ng-container *ngIf="entityName === EntityName.Organization">
          <i class="{{ iconClass || 'fas fa-ban' }}"></i>
        </ng-container>
      </div>
    </ng-container>

    <!-- Card -->
    <ng-container *ngSwitchCase="deactiveShowTypeEnum.Card">
      <div [ngClass]="{ 'people-cards': entityName === EntityName.Person }">
        <div
          class="deactive-entity__card d-flex justify-content-center align-items-center p-3 p-xl-0"
        >
          <ng-container *ngIf="entityName === EntityName.Person">
            <i class="fas fa-user-alt-slash mr-1"></i>
            <span class="deactive-entity__card--text text-center">
              {{ 'UI.UnsubscribedUser' | translate }}
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
