<ng-container *ngIf="formInput" [formGroup]="formInput">
  <ngx-recaptcha2
    #captchaElem
    [siteKey]="siteKey"
    [useGlobalDomain]="useGlobalDomain"
    [size]="'normal'"
    [hl]="reCAPTCHAlang"
    [theme]="'light'"
    [type]="'image'"
    (success)="onSuccess($event)"
    formControlName="recaptcha"
  >
  </ngx-recaptcha2>
</ng-container>
