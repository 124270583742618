import { DefaultImageDirective } from '@shared/directives/default-image.directive';
import { environment } from '@src/environments/environment';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appDefaultPlaceholder]',
})
export class DefaultPlaceholderDirective extends DefaultImageDirective {
  default = environment.wordpressJipImages + 'placeholder.png';
}
