import { Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityName } from '@shared/enums/entity-name.enum';

@Component({
  selector: 'app-remove-person-dialog',
  templateUrl: './remove-person-dialog.component.html',
})
export class RemovePersonDialogComponent {
  peopleURL = environment.jipUrl.people;
  EntityName = EntityName;

  @Input() personTitle = 'UI.RemoveDialog.RemoveFromTeam';
  @Input() confirmText = {
    prefix: 'Are you sure to remove',
    suffix: 'from this',
  };

  @Input() personImage: string;
  @Input() personId: string;
  @Input() personName: string;
  @Input() fromEntityName = 'Entity';
  @Input() fromEntity: any;

  constructor(public activeModal: NgbActiveModal) {}
  close(): void {
    this.activeModal.close(false);
  }

  remove(): void {
    this.activeModal.close(true);
  }
}
