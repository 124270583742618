<ng-container *ngIf="editable; else image">
  <app-file-upload
    [multiLocale]="false"
    [disabled]="false"
    (srcUrlChange)="changeSrcUrl.emit($event)"
  >
    <div
      *ngIf="!data?.socialMediaLogoId"
      class="card upload-file__container upload-file__container--sm justify-content-center align-items-center"
    >
      <i class="fas fa-camera fa-lg"></i>
    </div>

    <div
      *ngIf="data?.socialMediaLogoId"
      class="card upload-file__container upload-file__container--sm border-0"
    >
      <img
        [src]="data.socialMediaLogo"
        appDefaultImg
        [default]="placeholderImg"
        [ngbTooltip]="data?.socialMediaName"
        container="body"
      />
    </div>
  </app-file-upload>
</ng-container>

<ng-template #image>
  <img
    [src]="data.socialMediaLogo"
    appDefaultImg
    [default]="placeholderImg"
    [ngbTooltip]="data?.socialMediaName"
    container="body"
  />
</ng-template>
