import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UIPosition } from '@shared/models/ui.model';
import { EventInterface } from '../../interfaces/event.interface';
import { CustomMetadataUtils } from '../../utils/custom-metadata-utils';
import { LocationInfo } from '../google-map-autocomplete/google-map-autocomplete.component';

@Component({
  selector: 'app-event-location-flag',
  templateUrl: './event-location-flag.component.html',
})
export class EventLocationFlagComponent implements OnChanges {
  @Input() flagStyle: 'standard' | 'minimal' = 'standard';
  @Input() event: EventInterface;
  @Input() uiPosition: UIPosition = new UIPosition(
    '0.75rem',
    'unset',
    '0.75rem'
  );

  isPhysicalLocation = false;
  isVirtualLocation = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.event && changes.event.currentValue) {
      const jsonLocation =
        this.event.location ||
        CustomMetadataUtils.getAttributeValueByPropertyName(
          this.event.attributeDescriptions,
          this.event.attributeValues,
          'Location'
        );

      if (jsonLocation) {
        try {
          const location: LocationInfo = JSON.parse(jsonLocation);
          this.isPhysicalLocation = !!location.address && !!location.name;
        } catch (error) {
          this.isPhysicalLocation = false;
        }
      }

      this.isVirtualLocation =
        this.event.virtualLocation ||
        CustomMetadataUtils.getAttributeValueByPropertyName(
          this.event.attributeDescriptions,
          this.event.attributeValues,
          'VirtualLocation'
        );
    }
  }
}
