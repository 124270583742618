<div
  *ngIf="currentPhase"
  class="timeline-current d-flex align-items-center"
  (click)="goToEntityPage()"
  [ngClass]="{
    'pointer-none': !leadCompany?.id
  }"
>
  <div class="timeline-current__title flex-grow-1 d-flex flex-column">
    <ng-container *ngIf="!hideNameBlock">
      <div class="timeline-current__title__label">
        {{ 'UI.Timeline.CurrentPhase' | translate }}
      </div>
      <div class="timeline-current__title__text">
        {{ currentPhase?.name }}
      </div>
    </ng-container>
  </div>

  <div class="flex-grow-0 text-center text-white timeline-current__info">
    <div class="font">
      {{ currentPhase?.name }}
    </div>
    <div class="timeline-current__info__time">{{ currentPhase.daysLeft }}</div>
    <div class="timeline-current__info__time-subfix">
      {{ 'UI.Timeline.Days-left' | translate }}
    </div>
  </div>
</div>
