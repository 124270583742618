<div class="modal-header">
  <h4 class="modal-title" translate>
    {{ textHeader }}
  </h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="cancelBtnOk()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="cancelBtnOk()"
  >
    {{ textCancel | translate }}
  </button>
  <button
    [disabled]="disabled || isSubmitting"
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="clickBtnOk()"
  >
    <ng-container *ngIf="isSubmitting">
      <i class="fas fa-spinner fa-spin"></i>
    </ng-container>
    <span>{{ textOk | translate }}</span>
  </button>
</div>
