import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
  TagFieldComponent,
  TagFieldCustom,
  TagFieldType,
} from '@src/app/shared/components/tag-field/tag-field.component';
import { InfiniteScrollDataAdapter } from '@src/app/shared/helpers/infinite-scroll-data.adapter';
import { TagInputComponent } from 'ngx-chips';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-tag-field-with-suggestion',
  templateUrl: './tag-field-with-suggestion.component.html',
})
export class TagFieldWithSuggestionComponent extends TagFieldComponent {
  @Input() searchData$: InfiniteScrollDataAdapter;

  @Input() limitItemsTo = 8;

  @Input() disabledInput = false;

  @Input() keyMapImg = 'image';

  @Input() isShowLeftColum = true;

  @Input() dropdownClass = '';

  @Input() hideTagInput = false;

  @Output() newTagAdded = new EventEmitter<TagFieldCustom>();
  @Output() tagRemoved = new EventEmitter<TagFieldCustom>();

  @ViewChild(NgbDropdown) ngbDropdown: NgbDropdown;

  @ViewChild('tagInput') tagInputViewChild: TagInputComponent;

  TagFieldType = TagFieldType;

  searchData: TagFieldCustom[];

  leftItems: TagFieldCustom[] = [];

  rightItems: TagFieldCustom[] = [];

  isClose$ = new Subject<void>();

  onScroll(event: any): void {
    const OFFSET_BOTTOM = 20;

    if (
      event.target.offsetHeight + event.target.scrollTop + OFFSET_BOTTOM >=
      event.target.scrollHeight
    ) {
      this.searchData$.loadMore();
    }
  }

  searchItems(text: string): void {
    if (!this.ngbDropdown.isOpen()) {
      this.ngbDropdown.open();
    }
    this.searchData$.reload(text);
  }

  addTag(item: TagFieldCustom): void {
    this.newTagAdded.emit(item);
    this.resetInputText(false);
    this.ngbDropdown?.close();
    this.searchData$.reload('');
  }

  removeTag(item: TagFieldCustom): void {
    // Reload search input
    this.tagRemoved.emit(item);
    this.ngbDropdown?.open();
  }

  emitValue(event: TagFieldCustom[]): void {
    this.itemsChange.emit(event);
  }

  onSelect(item: TagFieldCustom): void {
    if (!this.items) {
      this.items = [];
    }
    this.items.push(item);
    this.emitValue(this.items);
    this.addTag(item);
    setTimeout(() => this.setData(this.searchData));
  }

  onOpenChange(isOpen: boolean): void {
    if (!isOpen) {
      this.isClose$.next();
      this.searchData$.reload();

      return;
    }

    this.searchData$.pipe(takeUntil(this.isClose$)).subscribe((res) => {
      this.searchData = res;
      this.setData(res);
    });
  }

  resetInputText(emitEvent: boolean): void {
    this.tagInputViewChild.setInputValue('', emitEvent);
    this.setData([]);
  }

  trackByFn(_index: number, tag: TagFieldCustom): number {
    return tag.id;
  }

  private setData(searchData: TagFieldCustom[]): void {
    this.rightItems = [...searchData].filter(
      (item) =>
        !this.items?.find((x) => x.id === item.id || x.display === item.display)
    );

    if (this.rightItems.length < 20 && this.searchData$.hasMore$.value) {
      this.searchData$.loadMore();
    }
  }
}

export function transformToTagSuggestionModal(
  items: any[],
  type: TagFieldType = TagFieldType.Normal
): TagFieldCustom[] {
  if (items && Array.isArray(items)) {
    return items.map((item) => {
      return {
        id: item.id,
        display: item.content,
        value: item.content,
        ownerId: item.ownerId,
      };
    });
  } else {
    return [];
  }
}
