import { Component, Input } from '@angular/core';
import { DeactiveShowType } from '../../enums/deactive-show-type.enum';
import { EntityName } from '../../enums/entity-name.enum';

@Component({
  selector: 'app-deactive-entity',
  templateUrl: './deactive-entity.component.html',
  styles: [],
})
export class DeactiveEntityComponent {
  @Input() entityName = EntityName.Person;
  @Input() deactiveShowType = DeactiveShowType.Avatar;
  @Input() smallAvatar = false;
  @Input() iconClass: string;

  EntityName = EntityName;
  deactiveShowTypeEnum = DeactiveShowType;
}
