import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimelineItem } from '@shared/interfaces/timeline-item.interface';

@Component({
  selector: 'app-challenge-card-footer',
  templateUrl: './challenge-card-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengeCardFooterComponent {
  @Input() keyOfferings: any;

  @Input() currentPhase: TimelineItem;
}
