import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import {
  MoodboardCategoryItem,
  PeopleInterface,
  SocialMedia,
} from '@shared/interfaces/people.interface';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { MoodboardImageItem } from '@src/app/shared/interfaces/people.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PeopleHttpService extends BaseHttpService<PeopleInterface> {
  getApiRootPath(): string {
    return 'people';
  }

  read(id: number): Observable<PeopleInterface> {
    if (id) {
      let url = `${this.apiUrl}/${id}`;
      const encryptedCode = StringUtils.getParamFromUrl(UrlParam.Code);
      if (encryptedCode) {
        const encodeParam = StringUtils.replaceAll(encryptedCode, ' ', '%2B');
        url = `${url}?${UrlParam.Code}=${encodeParam}`;
        // clear param
        history.pushState(null, '', location.pathname);
      }
      return this.http
        .get<PeopleInterface>(url)
        .pipe(map((item) => this.tranformData(item)));
    } else {
      return of(null);
    }
  }

  paginate(
    params: HttpParams = null,
    innerEntity = null
  ): Observable<ApiGetResponse<PeopleInterface>> {
    return super.paginate(params, innerEntity).pipe(
      map((res: ApiGetResponse<PeopleInterface>) => {
        res.items = res.items.map((person: PeopleInterface) =>
          this.tranformPersonData(person)
        );
        return res;
      })
    );
  }

  paginateX(
    params: any,
    innerEntity = null
  ): Observable<ApiGetResponse<PeopleInterface>> {
    return super.paginateX(params, innerEntity).pipe(
      map((res: ApiGetResponse<PeopleInterface>) => {
        res.items = res.items.map((person: PeopleInterface) =>
          this.tranformPersonData(person)
        );
        return res;
      })
    );
  }

  private tranformPersonData(person: PeopleInterface): PeopleInterface {
    if (person) {
      const { firstName, lastName } = person;

      if (firstName && lastName) {
        person.name = `${firstName} ${lastName}`;
      } else if (firstName) {
        person.name = firstName;
      } else if (lastName) {
        person.lastName = lastName;
      }
    }
    return person;
  }

  getAfferableOrgs(
    entityId: number,
    params = null
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    const url = `${this.apiUrl}/${entityId}/affable-orgs`;
    params = params || new HttpParams();

    return this.http.get<ApiGetResponse<OrganizationInterface>>(url, {
      params,
    });
  }

  gettMoodboardCategories(section): Observable<MoodboardCategoryItem[]> {
    const url = `${this.apiUrl}/categories`;
    const params = new HttpParams().set('section', section);
    return this.http.get<MoodboardCategoryItem[]>(url, { params });
  }

  getMoodboardImages(category): Observable<MoodboardImageItem[]> {
    const url = `${this.apiUrl}/moodboard-images`;
    const params = new HttpParams().set('category', category);
    return this.http.get<MoodboardImageItem[]>(url, { params });
  }

  getVCard(id: number, size: number = 5): Observable<string> {
    if (!id) return of('');

    const url = `${this.apiUrl}/${id}/vcard`;
    const params = new HttpParams().set('size', size + '');
    return this.http.get<string>(url, { params });
  }

  uploadFavicon(urls: string[]): Observable<SocialMedia> {
    return this.http.post(`${this.apiUrl}/socialmedia`, urls);
  }

  deactivateAccount(params) {
    const url = `${this.apiUrl}/deactivate`;
    return this.http.post(url, null, { params });
  }
}
