<div class="custom-org-attributes">
  <div class="row">
    <ng-container *ngFor="let attrDes of orderedAttributeDescriptions">
      <div
        class="col-12 col-md-6 mb-3"
        [ngClass]="{
          'col-md-12': attrDes.propertyName === 'ResidentOf'
        }"
      >
        <app-form-field-custom
          [labelClass]="'venture__label--head'"
          [formInput]="formInput"
          [editable]="editable"
          [attributeDescription]="attrDes"
          [showLabel]="true"
          [options]="{ smartTyping: true }"
        ></app-form-field-custom>

        <div
          class="alert alert-danger"
          *ngIf="
            formInput.controls[attrDes.propertyName]?.hasError(
              attrDes.propertyName + 'Error'
            )
          "
        >
          {{
            'UI.CustomAttribute.' + attrDes.propertyName + 'Error' | translate
          }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
