import { Component, Input } from '@angular/core';
import { MessageUIService } from '@shared/services/message-ui.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import {
  MessageAction,
  MessageType,
} from '@src/app/shared/enums/entity-name.enum';
import { NewsMessageInterface } from '@src/app/shared/interfaces/news-message.interface';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  providers: [
    { provide: SendMessageHttpService, useClass: SendMessageHttpService },
  ],
})
export class MessageCardComponent {
  @Input() currentPersonId?: number;
  @Input() message: NewsMessageInterface;
  @Input() showAsHeader: boolean;

  lastMessage: NewsMessageInterface;
  MessageAction = MessageAction;

  constructor(private messageUIservice: MessageUIService) {}

  openMessageBack(): void {
    this.messageUIservice.openMessage(
      this.message.id,
      false,
      this.message.content
    );
  }

  get messageText(): any {
    if (this.message.messageType !== MessageType.Message) {
      return this.message?.latestMessage?.content || this.message?.message;
    }

    return this.message?.latestMessage?.content
      ? this.message?.latestMessage.content
      : this.message?.content;
  }

  shouldShowReplyBtn(message: NewsMessageInterface): boolean {
    const excludedActions = [
      MessageAction.Shared,
      MessageAction.InviteJuror,
      MessageAction.AcceptJuror,
      MessageAction.DenyJuror,
      MessageAction.WelcomeJuror,
    ];
    const replyGroupNames = ['Everybody', 'Tous', 'Für Alle'];

    return (
      message?.from &&
      (message?.to ||
        message.group?.toItems?.length > 0 ||
        replyGroupNames.includes(message?.group?.name) ||
        message.isRepliable) &&
      !excludedActions.includes(message.action)
    );
  }
}
