import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StorageEnum } from '@shared/enums/storage.enum';
import {
  LanguageInterface,
  TranslateServiceLanguage,
} from '@shared/interfaces/language.interface';
import { TenantLanguageInterface } from '@shared/interfaces/tenant-langauge.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import deCH from '@src/assets/i18n/de-CH.json';
import enUS from '@src/assets/i18n/en-US.json';
import frCH from '@src/assets/i18n/fr-CH.json';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TenantLanguageService } from './../http/tenant-language.service';

/**
 * Running `npm translations:extract` will include the given string by using this.
 * @param text The string to extract for translation.
 * @return The same string.
 */
export function extract(text: string): string {
  return text;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: LanguageInterface[];

  public supportedLanguages$ = new BehaviorSubject<LanguageInterface[]>([]);
  public language$ = new BehaviorSubject<LanguageInterface>(null);

  private languageChangeSubscription!: Subscription;
  constructor(
    private cookieService: CookieService,
    private translateService: TranslateService,
    private tenantLanguageService: TenantLanguageService
  ) {
    translateService.setTranslation(TranslateServiceLanguage.en, enUS);
    translateService.setTranslation(TranslateServiceLanguage.de, deCH);
    translateService.setTranslation(TranslateServiceLanguage.fr, frCH);

    console.log('language path', this.languagePath);
    if (this.languagePath === '/de_ch') {
      translateService.setDefaultLang(TranslateServiceLanguage.de);
    } else if (this.languagePath === '/en_us') {
      translateService.setDefaultLang(TranslateServiceLanguage.en);
    } else {
      translateService.setDefaultLang(TranslateServiceLanguage.fr);
    }
  }

  /**
   * Initializes i18n for the application.
   * @param defaultLanguage The default language to use.
   */
  async init(): Promise<any> {
    // trigger language changed event
    this.language = null;
    this.languageChangeSubscription =
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.cookieService.delete(StorageEnum.locale, '/');
        this.cookieService.delete(StorageEnum.locale);
        this.setCookie(StorageEnum.locale, event.lang);
      });
  }

  private setCookie(key: string, value: string): void {
    const expiredDate = TimeUtils.getDateWithOffset(new Date(), 1);
    this.cookieService.set(key, value, expiredDate, '/');
  }

  private getBrowserLang(): string {
    const cultureLang = this.translateService.getBrowserCultureLang();
    return cultureLang.replace('-', '_');
  }

  public loadLanguageForTenant(): Observable<any> {
    return new Observable((observer) => {
      this.tenantLanguageService.gets().subscribe({
        next: (languages: TenantLanguageInterface[]) => {
          if (languages) {
            this.supportedLanguages = languages
              .filter((item) => item.activated)
              .map((item: TenantLanguageInterface) => {
                return {
                  name: item.name,
                  code: item.locale ? item.locale.slice(0, 2) : '',
                  locale: item.locale,
                };
              });

            const userLang = this.getBrowserLang();
            const tenantDefaultLang = languages.find(
              (lang) => lang.activated && lang.isDefault
            );

            const matchedLang = this.supportedLanguages.find(
              (lang) => lang.locale.slice(0, 2) === userLang.slice(0, 2)
            );

            this.defaultLanguage = matchedLang
              ? matchedLang.locale
              : tenantDefaultLang?.locale;

            this.supportedLanguages$.next(this.supportedLanguages);
            this.init();

            observer.next(true);
            observer.complete();
          }
        },
        error: (err) => {
          observer.next(false);
          observer.complete();
        },
      });
    });
  }

  public appendTranslationResourceFromBE(translateData: {} = null): void {
    if (translateData) {
      this.translateService.setTranslation(TranslateServiceLanguage.en, {
        ...enUS,
        ...translateData,
      });
      this.translateService.setTranslation(TranslateServiceLanguage.de, {
        ...deCH,
        ...translateData,
      });
      this.translateService.setTranslation(TranslateServiceLanguage.fr, {
        ...frCH,
        ...translateData,
      });
    }
  }
  /**
   * Cleans up language change subscription.
   */
  destroy(): void {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  /**
   * Forces to reload language to default one if container webapp locale does not match.
   */
  checkExternalMatchedDefaultLangAndReload(): void {
    const cookiesLang = this.cookieService.get(StorageEnum.locale) || '';
    const isNotMatchedCookies =
      cookiesLang !== this.translateService.currentLang;
    const isNotSupported = !this.supportedLanguages?.some(
      (lang) => lang.locale === cookiesLang
    );
    if (isNotMatchedCookies && isNotSupported) {
      this.setCookie(StorageEnum.locale, this.defaultLanguage);
      location.reload();
    }
  }

  /**
   * Set the current language.
   * @param language The IETF language code to set.
   */
  set language(language: LanguageInterface | null) {
    let locale =
      language?.locale ||
      StringUtils.getLocaleFromUrl() ||
      this.cookieService.get(StorageEnum.locale) ||
      this.getBrowserLang();

    if (this.supportedLanguages) {
      language = this.supportedLanguages.find((l) =>
        StringUtils.equal(l.locale, locale)
      );
    }

    if (locale && language === undefined) {
      locale = this.defaultLanguage;
    } else {
      locale = language.locale;
    }

    this.setCookie(StorageEnum.locale, locale);
    this.translateService.use(locale);
  }

  /**
   * Get the current language.
   * @return The current language code.
   */
  get language(): LanguageInterface {
    if (this.supportedLanguages) {
      let currentLocale = this.translateService.currentLang;

      if (currentLocale === undefined) {
        currentLocale = this.defaultLanguage;
      }

      let matchedSupported = this.supportedLanguages.find(
        (l) => l.locale === currentLocale
      );
      if (!matchedSupported) {
        // match code only
        matchedSupported = this.supportedLanguages.find(
          (lang) => lang.locale.slice(0, 2) === currentLocale.slice(0, 2)
        );
      }
      return matchedSupported;
    } else {
      const browserLang = this.getBrowserLang();
      const code = browserLang.slice(0, 2);
      return { name: code, code, locale: browserLang };
    }
  }

  get languagePath(): string {
    const lang =
      StringUtils.getLocaleFromUrl() ||
      this.language?.locale ||
      TranslateServiceLanguage.en.toLocaleLowerCase();

    return '/' + lang.toLowerCase();
  }
}
