<ng-container *ngIf="item">
  <a
    appActiveRoute
    appUnsavedFormCheck
    class="nav-link d-flex flex-sm-column justify-content-lg-start align-items-center nav-link-route"
    [href]="item.route ? item.route : '#'"
    [ngClass]="{ 'dropdown-hover__trigger': item.subMenus }"
    [ngClass]="wrapperClass"
    (click)="launchLoadingIndicator($event, item.route ? item.route : '#')"
    (unsavedForm)="handleUnsavedFormState($event)"
  >
    <span
      class="nav-item__icon-container position-relative"
      [ngClass]="{
        'py-1': item.jipIcon && item.isSharedIcon,
        'pt-1': !item.isSharedIcon
      }"
    >
      <app-shared-icon
        *ngIf="item.jipIcon && item.isSharedIcon"
        [name]="item.jipIcon"
        [hasBigSize]="true"
      ></app-shared-icon>
      <i *ngIf="item.faIcon" class="nav__item-icon" [class]="item.faIcon"></i>
      <app-internal-icon
        *ngIf="item.jipIcon && !item.isSharedIcon"
        [name]="item.jipIcon"
        [height]="24"
      >
      </app-internal-icon>

      <ng-container *ngIf="!!count">
        <span class="news__badge"></span>
      </ng-container>
    </span>
    <span [style.min-width.px]="item.minWidth || 'unset'" class="py-1 font-size-base">
      {{ item.name | translate }}
    </span>
  </a>
</ng-container>
