import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { BaseEventRegistrationComponent } from '@components/base/base-event-registration/base-event-registration.component';
import { ToastsContainerComponent } from '@components/toasts-container/toasts-container.component';
import { CentralConfigService } from '@core/services/central-config.service';
import { environment } from '@env/environment.prod';
import { HttpStatusCode } from '@shared/enums/httpstatuscode.enum';
import { RegisterEventPayload } from '@shared/interfaces/request/register-event.interface';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { LogoDisplayOption } from '@shared/models/logo-type.model';
import { AppendComponentToBodyService } from '@shared/services/append-component-to-body.service';
import { TextValidator } from '@shared/utils/form-fields-validation';
import { FormUtils } from '@shared/utils/form-utils';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { TenantInterface } from '@src/app/shared/interfaces/tenant.interface';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { EMPTY } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

export enum FormKey {
  FirstName = 'firstName',
  LastName = 'lastName',
  Recaptcha = 'recaptcha',
  TermAgreement = 'termAgreement',
}

@Component({
  selector: 'app-event-registration-form',
  templateUrl: './event-registration-form.component.html',
})
export class EventRegistrationFormComponent
  extends BaseEventRegistrationComponent
  implements OnInit
{
  @Input() domain: string;

  @Input() email: string;

  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_logo: boolean;

  // tslint:disable-next-line:variable-name
  @Input() logo_display_option: LogoDisplayOption;

  readonly FormKey = FormKey;

  eventForm: UntypedFormGroup;

  formFields = [
    {
      key: FormKey.FirstName,
      label: 'UI.Common.FirstName',
      requiredLabel: 'First Name is required.',
      columnSize: [6, 6, 12],
      validator: [Validators.required, TextValidator.notEmptyOrWhitespace],
    },
    {
      key: FormKey.LastName,
      label: 'UI.Common.LastName',
      requiredLabel: 'Last Name is required.',
      columnSize: [6, 6, 12],
      validator: [Validators.required, TextValidator.notEmptyOrWhitespace],
    },
    {
      key: FormKey.Recaptcha,
      columnSize: [12, 12, 12],
      validator: [Validators.required],
    },
    {
      key: FormKey.TermAgreement,
      columnSize: [12, 12, 12],
      validator: [Validators.requiredTrue],
      value: false,
    },
  ];

  centralConfig: CentralConfigService;

  termOfUseUrl = '';

  ngOnInit(): void {
    this.generateForm();
    this.injector
      .get(AppendComponentToBodyService)
      .append(ToastsContainerComponent);
    this.centralConfig = this.injector.get(CentralConfigService);
    this.redirectToEvents();
    this.sessionService.tenant$.pipe(untilDestroyed(this)).subscribe((tenant: TenantInterface) => {
      this.termOfUseUrl = tenant.termsOfServiceUrl;
    })
  }

  // Override
  generateForm(): void {
    this.eventForm = this.createForm();
  }

  createForm(): UntypedFormGroup {
    const controls = this.formFields.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: ['', item.validator],
      }),
      {}
    );

    return this.fb.group(controls);
  }

  registerEvent(): void {
    this.isSubmitting = true;

    const { firstName, lastName } = this.eventForm.value;
    const targetTimezone = TimeUtils.getTargetDateTimezone(
      this.entity.eventDateTime
    );
    const payload: RegisterEventPayload = {
      eventId: this.id,
      firstName,
      lastName,
      email: this.email,
    };

    if (this.entity.hasPayment) {
      payload.targetTimezone = targetTimezone;
      this.handlePayment(payload);
      return;
    }

    this.authService
      .registerEvent(payload)
      .pipe(
        catchError((res: ApiResponse) => {
          const { error } = res;

          if (res.status === HttpStatusCode.BadRequest && error?.title) {
            this.toastService.showError(error.title);

            return EMPTY;
          }
        }),
        finalize(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.toastService.showSuccess('UI.Event.Registered');
        FormUtils.navigateTo(
          this.sessionService.appendLanguagePath(
            `${environment.jipUrl.event}/${this.id}}`
          )
        );
      });
  }

  handlePayment(params): void {
    this.eventHttpService
      .checkout(this.entity.id, params)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: (res: string) => {
          window.open(res, '_blank');
        },
      });
  }
}
