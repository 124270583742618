import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { ToastService } from '@src/app/core/toast.service';
import {
  TagFieldCustom,
  transformToTagModaByKey,
} from '@src/app/shared/components/tag-field/tag-field.component';
import {
  LST_RELATED_ENTITY_AS_MULTISTRING,
  MULTISTRING_DEFAULT_PLACEHOLDER,
} from '@src/app/shared/constants/multi-string.const';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { VentureSubmissionStatus } from '@src/app/shared/enums/venture.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  AttributeDescription,
  CustomAtrributeValue,
  MultiStringRelatedEntity,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { VentureSubmissionPayloadInterface } from '@src/app/shared/interfaces/venture.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MetadataTableCellEditComponent } from '../metadata-table-cell-edit.component';

@Component({
  selector: 'app-cell-edit-related-entity',
  templateUrl: './cell-edit-related-entity.component.html',
})
export class CellEditRelatedEntityComponent
  extends MetadataTableCellEditComponent
  implements OnInit
{
  @Input() lstAdditionalAttrDes: AttributeDescription[] = [];
  @Input() parentEntityId: number;
  @Input() parentHttpService: BaseHttpService<Entity>;

  lstRelatedEntityAsMultiString: Record<string, MultiStringRelatedEntity> =
    LST_RELATED_ENTITY_AS_MULTISTRING;
  multiStringPlaceholder = MULTISTRING_DEFAULT_PLACEHOLDER;
  multiStringRE: MultiStringRelatedEntity;

  storedPreviousData: Record<string, any> = {};

  constructor(
    protected fb: UntypedFormBuilder,
    protected customFormService: CustomFormService,
    protected unsavedFormCheckService: UnsavedFormCheckService,
    protected toastService: ToastService,
    protected modalService: NgbModal
  ) {
    super(
      fb,
      customFormService,
      unsavedFormCheckService,
      toastService,
      modalService
    );
  }

  ngOnInit(): void {
    this.multiStringRE = LST_RELATED_ENTITY_AS_MULTISTRING[this.entityName];
    super.ngOnInit();
  }

  protected generateControl(): Record<string, any> {
    switch (this.entityName) {
      case EntityName.Venture:
        this.multiStringPlaceholder = 'Type hashtag';
        break;

      case EntityName.Event:
        this.multiStringPlaceholder = 'Enter Speaker';
        break;
    }
    return super.generateControl();
  }

  protected updateFormValue(): void {
    this.storedPreviousData = {};

    if (this.multiStringRE?.propertyNames.includes(this.propName)) {
      const tagModelValue: TagFieldCustom[] = transformToTagModaByKey(
        this.currentValue || [],
        false,
        this.multiStringRE.keyMap,
        this.multiStringRE.keyMap
      );
      this.form.controls[this.propName].setValue(tagModelValue);
    } else if (this.propName === 'CurrentSubmission') {
      this.storedPreviousData[this.propName] = { ...this.currentValue };
      this.form.controls[this.propName].setValue(
        this.currentValue as CustomAtrributeValue
      );
    }
  }

  protected emitValue(transformValue: Record<string, any>): void {
    if (this.multiStringRE?.propertyNames.includes(this.propName)) {
      this.valueUpdated.emit(transformValue[this.propName]);
    } else if (this.propName === 'CurrentSubmission') {
      super.emitValue(transformValue);
    }
  }

  protected getPayload(): Record<string, any> {
    const payload: Record<string, any> = {};
    if (this.multiStringRE?.propertyNames.includes(this.propName)) {
      payload[this.propName] = this.form.controls[this.propName].value.map(
        (item: TagFieldCustom) => {
          const result: Record<string, any> = {};
          result[this.multiStringRE.keyMap] = item.value;
          return result;
        }
      );
    } else if (this.propName === 'CurrentSubmission') {
      const submissionPayload: VentureSubmissionPayloadInterface = {
        ventureSubmissions: [
          {
            ventureId: this.entityId,
            currentStatus:
              this.storedPreviousData[this.propName]?.codeId ||
              VentureSubmissionStatus.Submitted,
            newStatus: this.form.controls[this.propName].value?.codeId,
          },
        ],
      };
      return submissionPayload;
    }
    return payload;
  }

  protected handlePatchApi(payload: Record<string, any>): void {
    let observable: Observable<any>;
    if (this.multiStringRE?.propertyNames.includes(this.propName)) {
      observable = this.baseHttpService.patch(this.entityId, payload);
    } else if (this.propName === 'CurrentSubmission') {
      observable = this.parentHttpService.postByEntity(
        this.parentEntityId,
        payload,
        '/change-venture-submisions'
      );
    }

    if (observable) {
      observable
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.requesting = false;
          })
        )
        .subscribe({
          next: (res: any) => {
            if (
              (res && !isNaN(res)) ||
              (this.propName === 'CurrentSubmission' && res === null)
            ) {
              this.handleSuccessfulResponse(payload);
            } else {
              this.toastService.showError('UI.Toast.UpdatedFailed');
            }
          },
          error: (errorRes: HttpErrorResponse) => {
            this.handleError(errorRes);
          },
        });
    }
  }
}
