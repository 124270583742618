import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
})
export class FormSectionComponent implements OnDestroy {
  @Input() index: number;
  @Input() showEditButton: boolean;
  @Input() hideControls: boolean;
  @Input() formSectionClass: string;
  @Input() saveSectionCallback: (index: number) => Observable<boolean>;

  @Output() cancelSection = new EventEmitter<number>();
  @Output() saveSection = new EventEmitter<number>();
  @Output() saveSuccess = new EventEmitter<number>();
  @Output() editState = new EventEmitter<boolean>();

  @Input() editting: boolean;
  @Input() shouldButtonsShown = true;
  @Input() canSave = true;
  @Output() edittingChange = new EventEmitter<boolean>();
  @Output() editIndexChange = new EventEmitter<number>();

  @Input() requesting = false;
  @Input() hasBindingDataBeforeSave!: boolean;

  @Input() showLanguageSwitcher = false;

  @Input() preSaveSectionCallback: () => Observable<boolean>;

  @Input() hideSaveBtn = false;

  constructor(private readonly customFormService: CustomFormService) {}

  ngOnDestroy(): void {
    /**/
  }

  toggle(event: Event): void {
    event.stopPropagation();
    this.setEditing(!this.editting);
    this.editState.emit(this.editting);
    if (!this.editting) {
      this.cancel();
    }
  }

  setEditing(value: boolean): void {
    this.editting = value;
    this.edittingChange.emit(this.editting);
    if (this.editting) {
      this.editIndexChange.emit(this.index);
    }
  }

  onSave(cancelEditingMode = true): void {
    if (this.preSaveSectionCallback) {
      this.preSaveSectionCallback()
        .pipe(filter(Boolean), untilDestroyed(this))
        .subscribe(() => this.save(cancelEditingMode));
    } else {
      this.save(cancelEditingMode);
    }
  }

  save(cancelEditingMode = true): void {
    if (this.hasBindingDataBeforeSave) {
      this.customFormService.subject.next(true);
    }

    this.saveSection.emit(this.index);

    if (!this.saveSectionCallback) {
      this.setEditing(false);
      return;
    }

    this.requesting = true;

    this.saveSectionCallback(this.index).subscribe({
      next: (success) => {
        if (success && cancelEditingMode) {
          this.setEditing(false);
          this.saveSuccess.emit();
        }
        this.requesting = false;
      },
      error: (err) => {
        console.log(err);
        this.requesting = false;
      },
    });
  }

  cancel(): void {
    this.cancelSection.emit(this.index);
    this.setEditing(false);
    this.editState.emit(this.editting);
  }
}
