import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { CheckBoxComponent } from '../check-box.component';

@Component({
  selector: 'app-check-box-confirmation',
  templateUrl: './check-box-confirmation.component.html',
  styles: [
  ]
})
export class CheckBoxConfirmationComponent extends CheckBoxComponent {
  @Input() confirmationContent: string;

  @ViewChild('checkbox') checkbox: ElementRef;
  constructor(
    private modalService: NgbModal
  ) {
    super();
  }

  openConfirmationModal(event: Event): void {
    if (!this.isChecked && this.confirmationContent) {
      event.preventDefault();
      const modalRef = this.modalService.open(ConfirmationComponent, {
        centered: true,
        backdrop: 'static',
        size: 'md'
      });
      modalRef.componentInstance.isHtmlMessage = true;
      modalRef.componentInstance.message = this.confirmationContent;
      modalRef.componentInstance.checkboxConfirmMessage = this.content;
      modalRef.componentInstance.confirmLabel = 'Confirm';
      modalRef.result
        .then((accepted) => {
          if (accepted) {
            this.isChecked = true;
            this.checkbox.nativeElement?.click();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  openConfirmationModalAsView(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      const modalRef = this.modalService.open(ConfirmationComponent, {
        centered: true,
        backdrop: 'static',
        size: 'md'
      });
      modalRef.componentInstance.isHtmlMessage = true;
      modalRef.componentInstance.message = this.confirmationContent;
      modalRef.componentInstance.shouldShowFooter = false;
    }
  }
}
