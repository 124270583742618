import { Component, OnDestroy } from '@angular/core';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { finalize } from 'rxjs/operators';
import { EntityName, EntityStateCount } from '../../enums/entity-name.enum';
import { untilDestroyed } from '../../functions/until-destroyed';
import { CheckEntityStateCountInterface, CheckOrganizationAdminState } from '../../interfaces/check-entity-state-count.interface';
import { VentureInterface } from '../../interfaces/venture.interface';
import { Entity } from '../../models/entity.model';

@Component({
  selector: 'app-entity-state-count',
  templateUrl: './entity-state-count.component.html',
})
export class EntityStateCountComponent implements OnDestroy {
  private privateEntityName = '';
  private privateEntityItems: Partial<Entity>[] = [];
  private privateBaseHttpService: BaseHttpService<Entity>;

  isLoadingLikingsCount = false;
  isLoadingFollowingsCount = false;
  isLoadingCommentsCount = false;
  isLoadingVenturesCount = false;
  isLoadingEventsCount = false;

  isLoadingParticipantsCount = false;

  // #region SYNC COUNT STATE
  protected runEntityStateCount(entityName: string, entityItems: Partial<Entity>[], baseHttpService: BaseHttpService<Entity>): void {
    this.privateEntityName = entityName;
    this.privateEntityItems = entityItems ?? [];
    this.privateBaseHttpService = baseHttpService;

    if (entityName && entityItems) {
      this.handleSyncEntityStateCount();
    }
  }

  // Default sync all -> can be handle in inherit entity
  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncVenturesState();
    this.syncEventsState();
  }

  protected syncLikingsState(): void {
    this.isLoadingLikingsCount = true;
    this.syncEntityStateCount(EntityStateCount.Liking);
  }

  protected syncFollowingsState(): void {
    this.isLoadingFollowingsCount = true;
    this.syncEntityStateCount(EntityStateCount.Following);
  }

  protected syncCommentsState(): void {
    this.isLoadingCommentsCount = true;
    this.syncEntityStateCount(EntityStateCount.Comment);
  }

  protected syncVenturesState(): void {
    this.isLoadingVenturesCount = true;
    this.syncEntityStateCount(EntityStateCount.Venture);
  }

  protected syncEventsState(): void {
    this.isLoadingEventsCount = true;
    this.syncEntityStateCount(EntityStateCount.Event);
  }

  protected syncParticipantsState(): void {
    this.isLoadingParticipantsCount = true;
    this.syncEntityStateCount(EntityStateCount.Participant);
  }

  protected syncEntityStateCount(
    entityStateCount: string,
    directEntityName = '',
    directEntityItems: Partial<Entity>[] = [],
    directHttpService: BaseHttpService<Entity> = null,
  ): void {
    const httpService = directHttpService ?? this.privateBaseHttpService;
    const entityName = directEntityName ? directEntityName : this.privateEntityName;
    const entityItems = directEntityItems?.length > 0 ? directEntityItems : this.privateEntityItems;

    httpService.checkEntityStateCount(
      entityName,
      entityItems.map((item: Entity) => item.id),
      entityStateCount
    )
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          switch (entityStateCount) {
            case EntityStateCount.Liking:
              this.isLoadingLikingsCount = false;
              break;

            case EntityStateCount.Following:
              this.isLoadingFollowingsCount = false;
              break;

            case EntityStateCount.Comment:
              this.isLoadingCommentsCount = false;
              break;

            case EntityStateCount.Venture:
              this.isLoadingVenturesCount = false;
              break;

            case EntityStateCount.Event:
              this.isLoadingEventsCount = false;
              break;

            case EntityStateCount.Participant:
              this.isLoadingParticipantsCount = false;
              break;
          }
        })
      )
      .subscribe((stateCounts: CheckEntityStateCountInterface[]) => {
        this.updateEntityStateCount(entityStateCount, entityItems, stateCounts);
      });
  }

  protected updateEntityStateCount(
    entityStateCount: string,
    entityItems: Partial<Entity>[] = [],
    stateCounts: CheckEntityStateCountInterface[] = [],
  ): void {
    entityItems.forEach((item: Entity) => {
      const checkItem = stateCounts.find(
        (x) => x.entityId === item.id
      );
      if (checkItem) {
        this.mapStateCount(item, checkItem, entityStateCount);
      }
    });
  }

  protected mapStateCount(item: Entity, checkItem: CheckEntityStateCountInterface, entityStateCount: string): void {
    if (checkItem) {
      switch (entityStateCount) {
        case EntityStateCount.Liking:
          item.isLiked = checkItem.isFavorite;
          item.likesCount = checkItem.count;
          item.likeId = checkItem.favoriteId;
          break;

        case EntityStateCount.Following:
          item.followerCount = checkItem.count;
          item.isFavorite = checkItem.isFavorite;
          break;

        case EntityStateCount.Comment:
          item.commentsCount = checkItem.count;
          break;

        case EntityStateCount.Venture:
          item.venturesCount = checkItem.count;
          break;

        case EntityStateCount.Event:
          item.eventsCount = checkItem.count;
          break;

        case EntityStateCount.Participant:
          item.participantsCount = checkItem.count;
          break;
      }
    }
  }
  // #endregion End SYNC COUNT STATE

  protected syncCheckUserIsOrgAdminOfEntity(): void {
    if (this.privateEntityItems && this.privateEntityName === EntityName.Venture) {
      const ids = this.privateEntityItems.map((entity: Entity) => entity.id);

      this.privateBaseHttpService
        .checkUserIsOrgAdminOfEntity(ids)
        .pipe(untilDestroyed(this))
        .subscribe((lstCheckIsOrgAdminRes: CheckOrganizationAdminState[]) => {
          this.privateEntityItems = this.privateEntityItems.map((entity: VentureInterface) => {
            const result = lstCheckIsOrgAdminRes.find((check) => check.entityId === entity.id);
            entity.isOrganizationAdmin = result ? result.isAdmin : false;
            return entity;
          });
        });
    }
  }

  ngOnDestroy(): void { /* */ }
}
