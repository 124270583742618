<ng-template #modalRef let-modal>
  <div class="modal-header">
    <div>
      <div class="d-flex">
        <h4 translate class="modal-title">
          {{ modalTitle }}
        </h4>

        <h4 *ngIf="keyOfferings" class="modal-title">
          ({{ keyOfferings.length }})
        </h4>
      </div>
    </div>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true"> &times; </span>
    </button>
  </div>

  <div class="modal-body modal-body-minspace">
    <ul *ngIf="!!keyOfferings.length; else emptyKeyOfferMsg" class="modal-list">
      <li *ngFor="let key of keyOfferings" class="modal-line">
        {{ key.description }}
      </li>
    </ul>

    <ng-template #emptyKeyOfferMsg>
      <span class="message-empty" translate></span>
    </ng-template>
  </div>
</ng-template>
