<div class="modal-header">
  <h1 class="modal-title truncate-line-2">
    {{ 'UI.TermsOfUse' | translate }}
  </h1>
</div>

<div class="modal-body modal-body-confirmation">
  <div class="form-group mb-4">
    <app-term-agreement-checkbox
      [(ngModel)]="isAccepted"
    ></app-term-agreement-checkbox>
  </div>
</div>

<div class="modal-footer">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        (click)="activeModal.close(true)"
        class="ml-4 btn btn-primary"
        [disabled]="!isAccepted"
      >
        {{ 'Accept' | translate }}
      </button>
    </div>
  </div>
</div>
