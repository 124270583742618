<label
  class="venture__label venture__label--head widget__label mr-4"
  *ngIf="label"
>
  {{ label | translate }}
</label>

<app-people-cards
  (extraActionClicked)="handleExtraAction($event)"
  (peopleRemoved)="removeItem($event)"
  (removeItemClicked)="openConfirmRemoveItemDialog($event)"
  [allowAddPeople]="allowAddPeople"
  [allowRemovePeople]="allowRemovePeople"
  [customUserRoles]="[]"
  [editable]="editable"
  [emptyMessage]="emptyMessage"
  [enableExtraAction]="enableExtraAction"
  [excludedPeopleIds]="excludedPeopleIds"
  [extraActions]="extraActions"
  [filteringKeyword]="filteringKeyword"
  [ignoreId]="ignoreId"
  [items]="items"
  [parentEntityName]="parentEntityName"
  [parentEntity]="parentEntityName"
  [removePeopleTitle]="removePeopleTitle"
></app-people-cards>
