import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { BaseDetailComponent } from '@src/app/components/base/base-detail/base-detail.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
})
export class ProfileComponent extends BaseDetailComponent implements OnInit {
  profile: UserInterface;

  ngOnInit(): void {
    this.authenService.getProfile().subscribe((profile) => {
      this.profile = profile;
    });
  }
}
