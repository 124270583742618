<ng-container *ngIf="!userAccountNotActive; else informAcountNotActive">
  <form class="jip-login" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div
        class="col-12"
        [ngClass]="
          usernameControlClass
            ? usernameControlClass
            : loginButtonPosition === loginButtonPositionEnum.Top
            ? 'col-sm-4'
            : 'col-sm-6'
        "
      >
        <ng-container *ngTemplateOutlet="emailControl"></ng-container>
      </div>
      <div
        class="col-12"
        [ngClass]="
          usernameControlClass
            ? usernameControlClass
            : loginButtonPosition === loginButtonPositionEnum.Top
            ? 'col-sm-4'
            : 'col-sm-6'
        "
      >
        <ng-container *ngTemplateOutlet="passwordControl"></ng-container>
      </div>
      <ng-container *ngIf="loginButtonPosition === loginButtonPositionEnum.Top">
        <div
          class="col-12 col-sm-4 form-group text-right text-sm-left position-relative login-btn-right"
          [ngClass]="loginButtonClass"
        >
          <button
            *ngIf="true"
            type="submit"
            class="btn btn-primary horizontal-btn"
            [disabled]="
              !loginForm.valid ||
              isSubmitting ||
              !(loginForm?.touched || loginForm?.dirty)
            "
          >
            <ng-container *ngIf="isSubmitting">
              <i class="fas fa-spinner fa-spin"></i>
            </ng-container>
            <span translate>Login</span>
          </button>
        </div>
      </ng-container>
    </div>

    <ng-template #emailControl>
      <div class="form-group">
        <label for="username">
          <span class="simple-login_username">{{
            'Username' | translate
          }}</span>
          <span class="simple-login_slash">/</span
          ><span class="simple-login_email">{{
            'Email' | translate
          }}</span></label
        >
        <input
          id="username"
          type="text"
          class="form-control"
          formControlName="username"
        />
        <div
          *ngIf="username.invalid && (username.dirty || username.touched)"
          class="alert alert-danger"
        >
          <div *ngIf="username.errors.required" translate>
            User Name is required.
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #passwordControl>
      <div class="form-group">
        <label for="password" translate>Password</label>
        <div class="input-group">
          <input
            id="password"
            [type]="passwordIsShow ? 'text' : 'password'"
            class="form-control"
            formControlName="password"
          />
          <div class="input-group-append">
            <a
              href=""
              class="input-group-text"
              (click)="togglePassword($event)"
            >
              <i
                class="fa"
                [class]="{
                  'fa-eye-slash': passwordIsShow,
                  'fa-eye': !passwordIsShow
                }"
                aria-hidden="true"
              ></i>
            </a>
          </div>
        </div>

        <div
          *ngIf="password.invalid && (password.dirty || password.touched)"
          class="alert alert-danger"
        >
          <div *ngIf="password.errors.required">
            {{ 'UI.Validation.PasswordIsRequired' | translate }}
          </div>
        </div>
      </div>
    </ng-template>

    <div
      *ngIf="loginForm.errors && (loginForm.dirty || loginForm.touched)"
      class="alert alert-danger login-error-msg"
      translate
      [ngClass]="
        loginButtonPosition === loginButtonPositionEnum.Top ? 'small-width' : ''
      "
    >
      {{ errorMessage[formErrorKey] }}
    </div>

    <div
      *ngIf="loginButtonPosition === loginButtonPositionEnum.Bottom"
      class="form-group text-right"
      [ngClass]="loginButtonClass"
    >
      <button
        *ngIf="true"
        type="submit"
        class="btn btn-primary"
        [disabled]="
          !loginForm.valid ||
          isSubmitting ||
          !(loginForm?.touched || loginForm?.dirty)
        "
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span translate>Login</span>
      </button>
    </div>
  </form>
</ng-container>
<ng-template #informAcountNotActive>
  <div class="email-confirmation">
    <div class="email-confirmation__info mt-4">
      <span
        class="info-text"
        [innerHTML]="
          'UI.UserNotActive.Inform-email-sent'
            | translate
            | stringReplacement
              : {
                  '{portalName}': portalUrl | linkWrapper : portalName,
                  '{userEmail}':
                    this.sentEmailAddress | emailWrapper : this.sentEmailAddress
                }
        "
      ></span>
      <span class="info-text" translate
        >UI.UserNotActive.Inform-follow-link</span
      >
      <span class="mt-3">
        <b translate>UI.UserNotActive.Didnot-receive-email</b>
        <span class="ml-2" translate
          >UI.UserNotActive.Please-check-your-spam</span
        >
      </span>
    </div>
    <div *ngIf="loginForm.errors" class="alert alert-danger" translate>
      {{ errorMessage[formErrorKey] }}
    </div>
    <div class="form-group text-center email-confirmation__buttons mb-3">
      <button
        type="button"
        appDebounceClick
        (debounceClick)="resendConfirmationEmail(this.sentEmailAddress)"
        class="btn btn-secondary"
        [disabled]="isSubmitting"
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span>{{ 'UI.UserNotActive.Resend-email' | translate }}</span>
      </button>
      <a href="/" class="btn btn-primary ml-3">
        {{ 'UI.UserNotActive.OK' | translate }}
      </a>
    </div>
  </div>
</ng-template>
