<div class="challenge-card-footer d-flex justify-content-between">
  <div *ngIf="currentPhase" class="challenge-card-footer__days-left">
    <div class="challenge-card-footer--small-label">
      {{ currentPhase?.name }}
    </div>
    <span
      *ngIf="currentPhase?.daysLeft as daysLeft"
      class="challenge-card-footer--medium-label"
    >
      {{ daysLeft }} {{ 'UI.Timeline.Days-left' | translate }}
    </span>
  </div>

  <div
    [class.mw-100]="!currentPhase"
    class="challenge-card-footer__key-offerings"
  >
    <div class="challenge-card-footer--small-label">
      {{ 'UI.Organization.KeyOfferings' | translate }}
    </div>
    <span class="challenge-card-footer--medium-label truncate-line-1">
      <ng-container *ngFor="let keyOffering of keyOfferings; let isLast = last">
        {{ keyOffering?.description
        }}<ng-container *ngIf="!isLast">,</ng-container>
      </ng-container>
    </span>
  </div>
</div>
