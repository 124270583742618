import { Injectable, OnDestroy } from '@angular/core';
import { SendMessageHttpService } from '@core/http/send-messages-http.service';
import { SessionService } from '@core/session.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { MessageBackDialogComponent } from '@src/app/components/dialogs/message-back-dialog/message-back-dialog.component';
import { ToastService } from '@src/app/core/toast.service';
import { finalize } from 'rxjs/operators';
import { untilDestroyed } from '../functions/until-destroyed';

@Injectable({
  providedIn: 'root',
})
export class MessageUIService implements OnDestroy {
  constructor(
    private sessionService: SessionService,
    private sendMessageService: SendMessageHttpService,
    private modalService: NgbModal,
    private readonly toastService: ToastService
  ) { }

  openMessage(
    messageId: number,
    findParent: boolean = false,
    keepContent: any = null,
    finalizeCallback: (() => void) = null
  ): void {
    this.sendMessageService.getMessageDetail(messageId)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          if (finalizeCallback) {
            finalizeCallback();
          }
        }))
      .subscribe(
        (chatMessage: ChatMessage) => {
          if (chatMessage && !chatMessage.content && keepContent !== null) {
            chatMessage.content = keepContent;
          }
          this.handleMessageInfo(chatMessage, findParent);
        },
        (error) => {
          console.log('failed to get message', error);
        }
      );
  }

  handleMessageInfo(chatMessage: ChatMessage, findParent = false): void {
    if (chatMessage) {
      if (findParent && chatMessage.parentId) {
        this.sendMessageService
          .getMessageDetail(chatMessage.parentId)
          .pipe(untilDestroyed(this))
          .subscribe((parentChatMessage: ChatMessage) => {
            this.updateContent(this.showMessageBackDialog(), parentChatMessage);
          });
      }
      else {
        this.updateContent(this.showMessageBackDialog(), chatMessage);
      }
    }
  }

  closeMessage(): void {
    this.modalService.dismissAll();
  }

  showMessageBackDialog(): NgbModalRef {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(MessageBackDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: true,
      windowClass: 'message-modal-custom message-back-dialog',
    });

    modalRef.result
      .catch((error) => {
        console.log(error);
      });

    return modalRef;
  }

  updateContent(modalRef, message: ChatMessage): void {
    if (!modalRef) {
      return;
    }

    modalRef.componentInstance.message = message;
  }

  ngOnDestroy(): void { /**/ }
}
