import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityName } from '@shared/enums/entity-name.enum';
import { defaultEntities } from '@shared/constants/entities.const';
import { CrossSearchingEntity } from '@shared/models/cross-searching-entity.model';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styles: [],
})
export class SearchFilterComponent {
  @Input() model = EntityName.All;

  @Input() class = '';

  @Output()
  filterChanged = new EventEmitter<EntityName>();

  @Input() data: CrossSearchingEntity[] = [
    {
      key: EntityName.All,
      label: 'UI.Common.AllResults',
    },
    ...defaultEntities,
  ];

  onFilterChange(entity: EntityName): void {
    this.filterChanged.emit(entity);
  }
}
