import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parentheses',
})
export class ParenthesesPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value) {
      return `<span class="jip-parentheses">(<span class="wrap-value">${value}</span>)</span>`;
    } else {
      return '';
    }
  }
}
