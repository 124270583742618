import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

function debounce(delay: number = 300): MethodDecorator {
  return (
    target: any,
    propertyKey: string | symbol,
    descriptor: PropertyDescriptor
  ) => {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args): void {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}

@Directive({
  selector: '[appEllipsis]',
})
export class EllipsisDirective implements AfterViewChecked {
  private get el(): HTMLElement {
    return this.elementRef.nativeElement;
  }
  constructor(
    private readonly elementRef: ElementRef,
    private readonly r2: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: unknown
  ) {}

  @HostListener('window:resize')
  @debounce()
  private onWindowResize(): void {
    this.truncate();
  }
  ngAfterViewChecked(): void {
    this.truncate();
  }

  private truncate(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const lineHeight: number = parseFloat(getComputedStyle(this.el).lineHeight);
    const clientHeight: number = parseFloat(
      getComputedStyle(this.el.parentElement).height
    );

    if (!isNaN(lineHeight) && lineHeight !== 0) {
      const lines = Math.floor(clientHeight / lineHeight);
      this.r2.setStyle(this.el, '-webkit-line-clamp', lines);
      this.r2.setStyle(this.el, '-webkit-box-orient', 'vertical');
      this.r2.setStyle(this.el, 'display', '-webkit-box');
      this.r2.setStyle(this.el, 'overflow', 'hidden');
      this.r2.setStyle(this.el, 'height', `${lineHeight * lines}px`);
    }
  }
}
