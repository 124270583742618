<div class="jip-login">
  <div
    *ngIf="logo_display_option === 'jip'"
    class="jip-login__logo text-center"
  >
    <app-jip-logo
      class="jip-login__logo--others"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
    <app-jip-logo
      class="jip-login__logo--mobile"
      name="jip-logo"
      width="380px"
      height="106px"
    ></app-jip-logo>
  </div>

  <div
    *ngIf="logo_display_option === 'innovation_space'"
    class="jip-login__org-logo"
  >
    <app-organization-logo
      [className]="'filter__org-logo text-center'"
      [organizationLogo]="
        (centralConfig?.read(global_org_id) | async)?.logo.url
      "
    ></app-organization-logo>
  </div>

  <div class="jip-login__header d-flex justify-content-center mb-3">
    <span class="jip-login__header-title" translate
      >UI.Change-email.Page-title</span
    >
  </div>

  <form
    *ngIf="!isEmailDelivered; else resendSection"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-group">
      <label for="email">{{ 'UI.Change-email.New-email' | translate }}</label>
      <input
        id="email"
        type="text"
        class="form-control"
        formControlName="email"
      />
      <div
        *ngIf="email.invalid && (email.dirty || email.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="email.errors.required" translate>Field is required.</div>
        <div *ngIf="email.errors.email" translate>
          {{ 'Invalid email address' }}
        </div>
      </div>
    </div>

    <div class="form-group confirm-account-recaptcha">
      <app-google-recapcha [formInput]="form"></app-google-recapcha>
    </div>
    <div
      class="alert alert-danger"
      *ngIf="errorMessage[formErrorKey]"
      translate
    >
      {{ errorMessage[formErrorKey] }}
    </div>

    <div class="form-group text-center">
      <button
        appRequireLogin
        type="submit"
        class="btn btn-primary"
        [disabled]="
          !form.valid || isSubmitting || !(form?.touched || form?.dirty)
        "
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span>{{ 'UI.Common.Change' | translate }}</span>
      </button>
    </div>
  </form>

  <ng-template #resendSection>
    <div class="email-sent">
      <div class="email-sent__info mt-4">
        <span
          class="info-text"
          [innerHTML]="
            'UI.Change-email.Inform-email-and-follow-link'
              | translate
              | stringReplacement
                : {
                    '{userEmail}': data?.email | emailWrapper : data?.email
                  }
          "
        ></span>
        <span class="mt-3">
          <b translate>UI.Common.Didnot-receive-email</b>
          <span class="ml-2" translate>UI.Common.Please-check-your-spam</span>
        </span>
      </div>

      <div class="form-group text-center email-sent__buttons">
        <button
          type="button"
          appRequireLogin
          appDebounceClick
          (debounceClick)="resend()"
          class="btn btn-secondary"
          [disabled]="isSubmitting"
        >
          <ng-container *ngIf="isSubmitting">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span translate>{{ 'UI.Common.Resend-email' | translate }}</span>
        </button>
        <a href="/" class="btn btn-primary ml-3">
          {{ 'UI.Common.OK' | translate }}
        </a>
      </div>
    </div>
  </ng-template>

  <div class="jip-login__header d-flex justify-content-center">
    <span class="jip-login__header-info">
      {{ 'UI.Forgot_Password.Back-to' | translate }}
      <a [href]="myProfileUrl" class="jip-login__text-underline" translate>
        My Profile
      </a>
    </span>
  </div>
</div>
