import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { UserInterface } from '@shared/interfaces/user.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { Observable } from 'rxjs';
import { finalize, tap, timeout } from 'rxjs/operators';
import { StorageEnum } from '../enums/storage.enum';

@Directive({
  selector: '[appRequireLogin]',
})
export class RequireLoginDirective implements OnDestroy {
  @Input() loginUrl = environment.jipUrl.login;
  @Input() callBackLoginPage: string;
  @Input() bypassRequiredLogin = false;

  constructor(
    private sessionService: SessionService,
    private authService: AuthenService,
    private unsavedFormCheckService: UnsavedFormCheckService,
  ) {}

  ngOnDestroy(): void {
    /**/
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event): void {
    let isRefreshedToken = false;

    const authHandler = () => {
      if (
        (this.sessionService.isLogin &&
          !this.sessionService.isTokenExpired()) ||
        this.bypassRequiredLogin
      ) {
        this.handleAuthorizedUser();
      } else {
        // Retry to fetch a new token
        if (!isRefreshedToken) {
          this.refreshToken(authHandler)
            .pipe(finalize(() => (isRefreshedToken = true)))
            .subscribe();

          return;
        }

        this.handleUnauthorizedUser(event);
      }
    };

    authHandler();
  }

  private handleAuthorizedUser(): void {
    if (this.callBackLoginPage) {
      this.goTo(this.callBackLoginPage);
    }
  }

  private handleUnauthorizedUser(event): void {
    if (this.sessionService.isLogin && this.sessionService.isTokenExpired()) {
      this.sessionService.setCookie(StorageEnum.isTokenExpired, true);
      this.sessionService.onSessionExpire();
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    const callbackHref = this.callBackLoginPage
      ? this.sessionService.appendLanguagePath(this.callBackLoginPage)
      : location.href;

    this.sessionService.setLoginCallBackpage(callbackHref);
    this.goTo(this.loginUrl);
  }

  private refreshToken(callBack: () => void): Observable<UserInterface> {
    return this.authService.refreshToken().pipe(
      timeout(2000),
      tap((data) => this.authService.handleLoginSuccess(data)),
      finalize(callBack),
      untilDestroyed(this)
    );
  }

  private goTo(url: string): void {
    this.unsavedFormCheckService.clearAll();
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }
}
