import { Pipe, PipeTransform } from '@angular/core';
import { AttributeDescription } from './../interfaces/attribute-description.interface';
import { FormUtils } from './../utils/form-utils';

@Pipe({
  name: 'fieldOption',
})
export class FieldOptionPipe implements PipeTransform {
  transform(
    metaInfoList: AttributeDescription[][],
    fieldName: string
  ): AttributeDescription {
    return getMetaAttribute(metaInfoList, fieldName);
  }
}

@Pipe({
  name: 'fieldSingleOption',
})
export class FieldSingleOptionPipe implements PipeTransform {
  transform(
    lstAttrDes: AttributeDescription[],
    fieldName: string
  ): AttributeDescription {
    const result = FormUtils.getFieldOptions(lstAttrDes, fieldName);
    if (result) {
      return result;
    }
    return null;
  }
}

export const getMetaAttribute = (
  metaInfoList: AttributeDescription[][],
  fieldName: string
) => {
  if (!fieldName || !metaInfoList || metaInfoList.length === 0) {
    return null;
  }

  for (const metaInfo of metaInfoList) {
    const result = FormUtils.getFieldOptions(metaInfo, fieldName);
    if (result) {
      return result;
    }
  }

  return null;
};
