import { Component, Input, OnInit } from '@angular/core';
import { VentureHttpService } from '@core/http/venture.http.service';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { environment } from '@src/environments/environment';
import { BaseCardComponent } from './../base-card/base-card.component';

@Component({
  selector: 'app-venture-card',
  templateUrl: './venture-card.component.html',
})
export class VentureCardComponent
  extends BaseCardComponent<VentureInterface>
  implements OnInit
{
  @Input() uiTemplate: TemplateName;
  constructor(protected service: VentureHttpService) {
    super(service);
  }

  ngOnInit(): void {
    if (this.data?.attributeDescriptions) {
      this.attributes = {};
      for (const item of this.data.attributeDescriptions) {
        this.attributes[item.propertyName] = item;
      }
    } else {
      super.ngOnInit();
    }
    this.getEntityDetailPageUrl(environment.jipUrl.venture);
  }

  shouldShowDescription(item): boolean {
    return this.service.shouldShowContent(item?.attributeDescriptions, item, [
      'Content',
      'Excerpt',
    ]);
  }
}
