<div class="modal-header">
  <h4 class="modal-title truncate-line-2" translate>{{ title }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-confirmation">
  {{ message | translate }}
</div>
<div class="modal-footer">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        type="button"
        class="btn"
        [class.btn-outline-primary]="!isLeaveSite"
        [class.btn-primary]="isLeaveSite"
        (click)="cancel()"
        translate
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn ml-4"
        [class.btn-primary]="!isLeaveSite"
        [class.btn-outline-primary]="isLeaveSite"
        [disabled]="isSubmitting"
        (click)="confirm()"
        translate
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin btn-icon-right"></i>
        </ng-container>
        {{ confirmLabel }}
      </button>
    </div>
  </div>
</div>
