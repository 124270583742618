import { Component, HostListener, Input } from '@angular/core';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';

@Component({
  selector: 'app-unsaved-form-check',
  templateUrl: './unsaved-form-check.component.html',
})
export class UnsavedFormCheckComponent {
  @Input() unsavedFormCheckService: UnsavedFormCheckService;
  /**
   *
   *
   * @returns Return false to show browser confirmation dialog
   */
  @HostListener('window:beforeunload')
  canDeactivate(): Promise<boolean | any> | boolean {
    let willNavigate = false;

    if (
      !this.unsavedFormCheckService ||
      !this.unsavedFormCheckService.hasUnsavedContent()
    ) {
      willNavigate = true;
    }

    return willNavigate;
  }
}
