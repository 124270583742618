export enum SocialMediaLogo {
  LinkedIn = 'linkedin.svg',
  Twitter = 'twitter.svg',
  Facebook = 'facebook.svg',
  Instagram = 'instagram.svg',
  Whatsapp = 'whatsapp.svg',
  Tiktok = 'tiktok.svg',
  Xing = 'xing.svg',
}

export enum SocialSharing {
  LinkedIn = 'LinkedIn',
}

export enum SocialSharingUrl {
  LinkedIn = 'https://www.linkedin.com/sharing/share-offsite/?url=',
}
