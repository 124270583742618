import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageInterface } from '@shared/interfaces/file.interface';

@Component({
  selector: 'app-images-popup-selection',
  templateUrl: './images-popup-selection.component.html',
})
export class ImagesPopupSelectionComponent {
  @Input() modalTitle = '';
  @Input() defaultImages: { imageId; image }[];

  @Input() selectedItem: { imageId; image };

  @Input() allowCropImg = false;

  displayImages: { imageId; image }[];
  constructor(public activeModal: NgbActiveModal) {}

  calculateDisplayImages(): void {
    const defaultImages = this.defaultImages || [];
    const selectedItem = this.selectedItem;

    this.displayImages = [...defaultImages];

    if (!!selectedItem?.imageId) {
      const found = this.displayImages.find(
        (item) => item.imageId === selectedItem.imageId
      );

      if (!found) {
        this.displayImages.push(selectedItem);
      }
    }
  }

  cancel(): void {
    this.activeModal.close(false);
  }

  apply(): void {
    this.activeModal.close(this.selectedItem);
  }

  uploadChange(file: ImageInterface): void {
    if (file) {
      this.defaultImages = this.defaultImages || [];
      const newItem = {
        imageId: file.id,
        image: file.url,
      };
      this.selectedItem = newItem;
      this.calculateDisplayImages();
    }
  }

  choseImage(item): void {
    this.selectedItem = item;
  }
}
