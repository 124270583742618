<div class="modal-header people-role-view-modal__modal-header">
  <h4 class="modal-title">{{ 'Edit Role' | translate }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="isLoadingEntity; else peopleViewSection">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #peopleViewSection>
    <ng-container *ngIf="entity">
      <div class="venture__body venture-people widget__card--background">
        <div class="row">
          <div class="col col-12 px-md-0">
            <label class="venture__label venture__label--head widget__label">
              {{ initiatorAttrDes?.displayName }}
            </label>

            <ng-container *ngIf="entity.initiator; else deactiveUser">
              <app-initator-cards
                [items]="[entity.initiator]"
                [entityId]="entity?.id"
                [httpService]="httpService"
                [enablePagination]="false"
                [editable]="allowEditRole"
                [allowAddPeople]="false"
                [allowRemovePeople]="false"
                [customUserRoles]="customAdminRoles"
              ></app-initator-cards>
            </ng-container>

            <ng-template #deactiveUser>
              <app-deactive-entity
                [deactiveShowType]="DeactiveShowType.Card"
              ></app-deactive-entity>
            </ng-template>

            <div
              class="d-flex flex-column flex-md-row align-items-start align-items-md-center mt-3"
            >
              <label class="venture__label venture__label--head widget__label">
                {{ stakeholdersAttrDes?.displayName }}
              </label>
            </div>

            <app-stakeholder-cards
              [items]="stakeholders"
              [entityId]="entityId"
              [httpService]="httpService"
              [parentEntityName]="entityName"
              [parentEntity]="entity"
              [editable]="allowEditRole"
              [allowAddPeople]="false"
              [allowRemovePeople]="false"
              [customUserRoles]="customUserRoles"
            ></app-stakeholder-cards>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
