import { Component, Input } from '@angular/core';
import { MessageAction } from '@shared/enums/entity-name.enum';
import { environment } from '@env/environment';
import { News } from '@shared/interfaces/news.interface';

@Component({
  selector: 'app-news-card-header',
  templateUrl: './news-card-header.component.html',
})
export class NewsCardHeaderComponent {
  @Input() styleClass = '';

  @Input() sender: string;

  @Input() currentPersonId: number;

  @Input() news: News;

  @Input() portalUrl: string = environment.portalUrl;

  @Input() portalName: string = environment.portalName;

  @Input() peopleURL: string = environment.jipUrl.people;

  MessageAction = MessageAction;
}
