<ul
  *ngIf="data"
  class="list-inline people__actions interaction-bar justify-content-between px-2"
>
  <li class="list-inline-item people__actions-item">
    <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
      <div class="text-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-container>
    <ng-template #followersBtn>
      <a class="d-flex">
        <span
          appRequireLogin
          appDebounceClick
          (debounceClick)="toggleFavorite($event)"
          class="people__actions-label font-weight-500 text-gray-600 d-flex align-items-center"
        >
          <span class="d-flex align-items-center">
            <app-internal-icon
              [className]="
                !data.isFavorite ? 'filter-gray' : 'icon-follow following'
              "
              [name]="'user-follow'"
            ></app-internal-icon>
            <span
              class="text-hide-small ml-1"
              [class.text-primary]="data.isFavorite"
              >{{ 'UI.Interaction.Follow' | translate }}</span
            >
          </span>
          <span
            appDebounceClick
            (debounceClick)="showEntityData(data); showPeopleModal = true"
            [innerHTML]="data.followerCount | parentheses"
          ></span>
        </span>
      </a>
    </ng-template>
  </li>

  <app-send-message-btn [receiver]="data"> </app-send-message-btn>

  <li *ngIf="isSamePerson && showDeactivateProfileBtn">
    <button
      class="btn btn-primary d-flex align-items-center w-100 ml-1 justify-content-between font-size-sm"
      (click)="openDeactivateProfileDialog(data)"
    >
      <i class="fas fa-user-times"></i>
      <span class="ml-2">Deactivate my profile</span>
    </button>
  </li>

  <li
    *ngIf="!isSamePerson"
    class="list-inline-item people__actions-item"
    role="button"
  >
    <a
      appDebounceClick
      appRequireLogin
      (debounceClick)="openSharePerson(data)"
      [ngClass]="{
        'cursor-not-allowed': !isEnableCommunication()
      }"
    >
      <app-internal-icon
        [height]="20"
        [name]="'share'"
        [className]="isEnableCommunication() ? 'filter-gray' : 'grey-out'"
      ></app-internal-icon>
      <span
        class="people__actions-label font-weight-500 text-hide-small"
        [ngClass]="isEnableCommunication() ? 'text-gray-600' : 'text-gray-300'"
      >
        {{ 'UI.Interaction.Share' | translate }}
      </span>
    </a>
  </li>
</ul>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="data?.firstName + ' ' + data?.lastName"
  modalTitle="Followers"
  [httpService]="httpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>
