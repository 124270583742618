import { Component, Input } from '@angular/core';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { ChallengeInterface } from '@src/app/shared/interfaces/challenge.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { JoinInteractComponent } from '../join-interact.component';

@Component({
  selector: 'app-join-organization',
  templateUrl: './join-organization.component.html',
})
export class JoinOrganizationComponent extends JoinInteractComponent<
  OrganizationInterface | ChallengeInterface
> {
  @Input() isAllowToJoin = true;
  @Input() hideTextSmall = true;

  constructor(
    public readonly sessionService: SessionService,
    protected readonly toastService: ToastService
  ) {
    super(sessionService, toastService);
  }
}
