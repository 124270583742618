import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { EntityOrganizationsComponent } from '@src/app/components/entity-section/entity-organizations/entity-organizations.component';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { OrgsSearchMode } from '@src/app/shared/enums/org-search-mode.enum';
import { EventPartnerStatusCodeIdEnum } from '@src/app/shared/enums/partner-status.enum';
import {
  AttributeDescription,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-event-organizations',
  templateUrl: './event-organizations.component.html',
  styles: [],
})
export class EventOrganizationsComponent
  extends EntityOrganizationsComponent
  implements OnInit
{
  @Input() entity: Entity;

  @Output() removedPartnerOrgSuccess = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private eventHttpService: EventHttpService,
    private toastSerivce: ToastService
  ) {
    super();
  }

  isLoadAttrDes = false;
  lstEventPartnerStatus: CustomAtrributeValue[] = [];
  orgsSearchMode: OrgsSearchMode = OrgsSearchMode.ForSubmittingAll;
  entityName = EntityName.Event;

  ngOnInit(): void {
    this.isLoadAttrDes = false;
    this.baseHttpService
      ?.getPartnerOrganizationAttribute()
      .pipe(
        finalize(() => {
          this.isLoadAttrDes = true;
        })
      )
      .subscribe((lstAttrDes: AttributeDescription[]) => {
        this.lstEventPartnerStatus =
          lstAttrDes.find((x) => x.propertyName === 'PartnerStatus')?.choice
            ?.selections || [];
      });
  }

  selectItem(item: LeadCompany): void {
    if (!item.eventPartnerStatus) {
      item.eventPartnerStatus = this.lstEventPartnerStatus.find(
        (x) => x.codeId === EventPartnerStatusCodeIdEnum.DefaultValue
      );
    }
    this.handleSelectItem(item);
  }

  removeItem(item) {
    if (this.editting) {
      super.removeItem(item);
    } else {
      this.removeMyselfAsPartnerOrg(item);
    }
  }

  removeMyselfAsPartnerOrg(item) {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });

    modalRef.componentInstance.title = 'Confirmation';
    modalRef.componentInstance.message =
      'UI.RemoveMyselfAsAdminPartner.Confirmation';
    modalRef.componentInstance.confirmLabel = 'Yes';

    modalRef.result.then((accepted) => {
      if (!accepted) return;
      this.eventHttpService
        .removeMyselfAsPartnerOrg(this.entity.id, {
          orgId: item.id,
        })
        .subscribe({
          next: () => {
            this.removedPartnerOrgSuccess.emit();
            this.toastSerivce.showSuccess('Remove successfully');
          },
          error: () => {
            this.toastSerivce.showError('Remove fail');
          },
        });
    });
  }
}
