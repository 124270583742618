<div class="icon-control mr-3">
  <i
    class="fas fa-th-large"
    [ngClass]="{ selected: isGridView }"
    (click)="onChangeView($event, true)"
    [ngbTooltip]="'UI.SwitchView.TileView' | translate"
  ></i>
  <i
    class="fas fa-th-list ml-2"
    [ngClass]="{ selected: !isGridView }"
    (click)="onChangeView($event, false)"
    [ngbTooltip]="'UI.SwitchView.TabularView' | translate"
  ></i>
  <app-internal-icon
    class="ml-2 pointer-cursor"
    [name]="InternalIcon.ExpandTable"
    [height]="20"
    (click)="onExpandTable($event)"
    [ngbTooltip]="'UI.SwitchView.Expand' | translate"
  ></app-internal-icon>
</div>
