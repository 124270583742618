import { environment } from '@src/environments/environment';
import { DefaultImageDirective } from '@shared/directives/default-image.directive';
import { Directive } from '@angular/core';

@Directive({
  selector: '[appDefaultAvatar]',
})
export class DefaultAvatarDirective extends DefaultImageDirective {
  default = environment.wordpressJipImages + 'avatar-square.png';
}
