import { Component, forwardRef } from '@angular/core';
import { BaseControlComponent } from '@shared/components/form-custom-attributes/base-control/base-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi: true,
    },
  ],
})
export class RangeSliderComponent extends BaseControlComponent<number> {
  DEFAULT_SCALE = 1;

  RANGE = [-0.9, 0.9];

  STEP = 0.1;

  onValueChange(event: number): void {
    super.onValueChange(this.roundNumber(this.DEFAULT_SCALE + event));
  }

  onZoom(scale: number): void {
    super.onValueChange(this.roundNumber(this.value + scale));
  }

  roundNumber(num: number): number {
    return Math.round((num + Number.EPSILON) * 10) / 10;
  }
}
