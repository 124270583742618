<ng-container *ngIf="!userAccountNotActive; else informAcountNotActive">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="username"
        >{{ 'Username' | translate }}/{{ 'Email' | translate }}</label
      >
      <input
        id="username"
        type="text"
        class="form-control"
        formControlName="username"
      />
      <div
        *ngIf="username.invalid && (username.dirty || username.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="username.errors.required" translate>
          User Name is required.
        </div>
      </div>
      <small>
        {{ 'UI.Validation.NoSpace' | translate }}
      </small>
    </div>

    <div class="form-group">
      <label for="password" translate>Password</label>
      <div class="input-group">
        <input
          id="password"
          [type]="passwordIsShow ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
        />
        <div class="input-group-append">
          <a href="" class="input-group-text" (click)="togglePassword($event)">
            <i
              class="fa"
              [class]="{
                'fa-eye-slash': passwordIsShow,
                'fa-eye': !passwordIsShow
              }"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>

      <div
        *ngIf="password.invalid && (password.dirty || password.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="password.errors.required">
          {{ 'UI.Validation.PasswordIsRequired' | translate }}
        </div>
      </div>
    </div>

    <div class="forgot-password-link">
      <a href="" translate (click)="toggleForgotPasswordForm($event)"
        >ForgotPassword</a
      >
    </div>

    <div
      *ngIf="loginForm.errors && (loginForm.dirty || loginForm.touched)"
      class="alert alert-danger"
      translate
    >
      {{ errorMessage[formErrorKey] }}
    </div>
    <div class="form-group text-center">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="
          !loginForm.valid ||
          isSubmitting ||
          !(loginForm?.touched || loginForm?.dirty)
        "
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span translate>Login</span>
      </button>
    </div>
    <app-login-with-linkedin-btn
      [assignToOrgId]="assignToOrgId"
      [applyPendingToAutoAssignment]="applyPendingToAutoAssignment"
    ></app-login-with-linkedin-btn>
  </form>
</ng-container>
<ng-template #informAcountNotActive>
  <div class="email-confirmation">
    <div class="email-confirmation__info mt-4">
      <span
        class="info-text"
        [innerHTML]="
          'UI.UserNotActive.Inform-email-sent'
            | translate
            | stringReplacement
              : {
                  '{portalName}':
                    'https://' + portalUrl | linkWrapper : portalName,
                  '{userEmail}':
                    this.sentEmailAddress | emailWrapper : this.sentEmailAddress
                }
        "
      ></span>
      <span class="info-text" translate
        >UI.UserNotActive.Inform-follow-link</span
      >
      <span class="mt-3">
        <b translate>UI.UserNotActive.Didnot-receive-email</b>
        <span class="ml-2" translate
          >UI.UserNotActive.Please-check-your-spam</span
        >
      </span>
    </div>
    <div *ngIf="loginForm.errors" class="alert alert-danger" translate>
      {{ errorMessage[formErrorKey] }}
    </div>
    <div class="form-group text-center email-confirmation__buttons mb-3">
      <button
        type="button"
        appDebounceClick
        (debounceClick)="resendConfirmationEmail(this.sentEmailAddress)"
        class="btn btn-secondary"
        [disabled]="isSubmitting"
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span>{{ 'UI.UserNotActive.Resend-email' | translate }}</span>
      </button>
      <a href="/" class="btn btn-primary ml-3">
        {{ 'UI.UserNotActive.OK' | translate }}
      </a>
    </div>
  </div>
</ng-template>
