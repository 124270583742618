<!-- //NOSONAR -->
<li
  class="nav-item dropdown dropdown-hover nav-profile nav-link-separator-after"
>
  <!-- Login / Profile dropdown in navbar -->
  <a
    [href]="profileUrl"
    appUnsavedFormCheck
    class="navigation__profile-user d-sm-block d-none"
  >
    <div
      *ngIf="!sessionService.isLogin; else profileLink"
      class="nav-link text-center"
    >
      <a appRequireLogin class="d-flex align-items-center">
        <img class="rounded-circle nav-profile__avatar" appDefaultAvatar />
        <i
          class="fas fa-sort-down ml-1 d-none d-sm-block font-size-base text-black"
        ></i>
      </a>
    </div>

    <ng-template #profileLink>
      <div class="nav-link text-center">
        <ng-container *ngTemplateOutlet="profileAvatarRef"></ng-container>
      </div>
    </ng-template>
  </a>

  <!-- Dropdown -->
  <div
    class="nav-profile__dropdown-menu dropdown-menu dropdown-menu-right dropdown px-0"
    aria-labelledby="dropdownMenuButton"
  >
    <!-- Sections on mobiles -->
    <section class="d-block d-sm-none">
      <ng-container *ngTemplateOutlet="profileActions"></ng-container>

      <ng-container *ngTemplateOutlet="appInformationRef"></ng-container>

      <ng-container *ngTemplateOutlet="loginLogoutRef"></ng-container>
    </section>

    <!-- Logged-in user info -->
    <section
      class="nav-profile d-none d-sm-block"
      *ngIf="profile && sessionService.isLogin"
    >
      <div class="navigation__content--padding">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <img
              [src]="profile?.image"
              appDefaultAvatar
              class="rounded-circle nav-profile__avatar nav-profile__avatar--small"
            />
          </div>

          <div class="nav-profile__text">
            <li class="nav-profile__name text-truncate font-weight-bold">
              {{ profile.firstName }} {{ profile.lastName }}
            </li>

            <li class="text-truncate">
              {{ profile.email }}
            </li>

            <li class="text-truncate">
              {{ profile.phone }}
            </li>

            <li class="text-truncate">
              {{ profile.company?.name || profile.company }}
            </li>
          </div>
        </div>

        <a
          appUnsavedFormCheck
          [href]="profileUrl"
          class="btn btn-outline-primary w-100 py-0 mt-2"
          translate
        >
          <i class="fas fa-user"></i>
          My Profile
        </a>
      </div>

      <ng-container *ngTemplateOutlet="profileActions"></ng-container>

      <ng-container *ngTemplateOutlet="appInformationRef"></ng-container>

      <ng-container *ngTemplateOutlet="loginLogoutRef"></ng-container>
    </section>
  </div>

  <div
    class="dropdown-menu dropdown-menu-right dropdown px-0"
    aria-labelledby="dropdownMenuLogin"
  >
    <section
      class="nav-profile d-none d-sm-block"
      aria-labelledby="dropdownMenuLink"
      *ngIf="!sessionService.isLogin"
    >
      <ng-container *ngTemplateOutlet="appInformationRef"></ng-container>

      <ng-template [ngTemplateOutlet]="loginRef"></ng-template>
    </section>
  </div>
</li>

<ng-template #profileActions>
  <ng-container *ngIf="sessionService.isLogin">
    <div class="navigation__content--padding">
      <div
        class="text-left nav-profile__action-item font-weight-bold text-lg-center"
        translate
      >
        UI.Nav-Profile.Account
      </div>

      <div
        *ngIf="profile"
        class="nav-profile__action-item d-sm-none d-flex align-items-center"
      >
        <ng-container *ngTemplateOutlet="profileAvatarRef"></ng-container>
        <a class="text-truncate font-weight-bold pl-2"
          >{{ profile.firstName }} {{ profile.lastName }}</a
        >
      </div>

      <div class="nav-profile__action-item">
        <a
          appRequireLogin
          appUnsavedFormCheck
          (click)="storePreviousUrl()"
          [href]="changePasswordUrl"
          class="nav-link p-0 text-lg-center text-left"
        >
          {{ 'ChangePassword' | translate }}
        </a>
      </div>

      <div class="nav-profile__action-item">
        <a
          appRequireLogin
          appUnsavedFormCheck
          (click)="storePreviousUrl()"
          [href]="changeEmailUrl"
          class="text-left nav-link p-0 text-lg-center"
        >
          {{ 'UI.Nav-Profile.Change-email' | translate }}
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #appInformationRef>
  <app-nav-information
    [navigateUrl]="tenantUrl"
    [isTenantAdmin]="profile?.isTenantAdmin"
  ></app-nav-information>
</ng-template>

<ng-template #profileAvatarRef>
  <a [href]="profileUrl" appUnsavedFormCheck class="d-flex align-items-center">
    <img
      [src]="profile?.image"
      class="rounded-circle nav-profile__avatar"
      appDefaultAvatar
    />
    <i
      class="fas fa-sort-down ml-1 font-size-base text-black d-none d-sm-block"
    ></i>
  </a>
</ng-template>

<ng-template #loginRef>
  <div
    (click)="goToLoginPage()"
    appRequireLogin
    class="nav-profile__action-item nav-profile__action-item--logout"
    translate
  >
    Login
  </div>
</ng-template>

<ng-template #loginLogoutRef>
  <div
    *ngIf="sessionService.isLogin; else loginRef"
    appUnsavedFormCheck
    (clickAfterChecking)="logout($event)"
    class="nav-profile__action-item nav-profile__action-item--logout"
    translate
  >
    Log out
  </div>
</ng-template>
