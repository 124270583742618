import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { OrgsSearchMode } from '@src/app/shared/enums/org-search-mode.enum';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';

@Component({
  selector: 'app-entity-organizations',
  templateUrl: './entity-organizations.component.html',
  styles: [],
})
export class EntityOrganizationsComponent implements OnChanges, OnDestroy {
  @Input() baseHttpService: BaseHttpService<any>;
  @Input() ignoreCompany: LeadCompany = {};
  @Input() items: LeadCompany[] = [];
  @Input() userId: number;
  @Input() editting = false;

  @Output() notifyOrganisationPayload = new EventEmitter<LeadCompany[]>();

  previousItems: LeadCompany[];
  orgsSearchMode: OrgsSearchMode = OrgsSearchMode.ForSubmitting;

  organisations: LeadCompany[] = [];
  challenges: LeadCompany[] = [];

  // Pagination
  organisationsPage = 1;
  challengesPage = 1;
  pageSize = 3;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      if (this.items) {
        this.items = [...this.items];
      } else {
        this.items = [];
      }
      this.previousItems = this.items.map((item) => ({ ...item }));

      this.setOrganisations();
      this.setChallenges();

      this.emitOrganisationPayload();
    }
  }

  get ignoreOrgIds(): number[] {
    return this.items.map((o) => o.id);
  }

  removeItem(item: LeadCompany): void {
    if (!item) {
      return;
    }

    const idx = this.items.findIndex((org) => org.id === item.id);
    if (idx < 0) {
      return;
    }

    this.items.splice(idx, 1);

    this.setOrganisations();
    this.setChallenges();

    this.emitOrganisationPayload();
  }

  reset(): void {
    if (Array.isArray(this.previousItems)) {
      this.items = this.previousItems.map((prevItem) => ({ ...prevItem }));

      this.setOrganisations();
      this.setChallenges();

      this.emitOrganisationPayload();
    }
  }

  handleSelectItem(item: LeadCompany): void {
    const idx = this.items.findIndex((org) => org.id === item.id);

    if (!item || item.id === undefined || idx >= 0) {
      return;
    }

    const leadOrg: LeadCompany = { ...item };
    leadOrg.name = item.name || item.orgName;
    leadOrg.url = item.url || item.orgUrl;

    this.items.push(leadOrg);

    this.setOrganisations();
    this.setChallenges();

    this.emitOrganisationPayload();
  }

  protected setOrganisations(): void {
    this.organisations = this.items.filter((item) => !item.showAsChallenge);
  }

  protected setChallenges(): void {
    this.challenges = this.items.filter((item) => item.showAsChallenge);
  }

  protected emitOrganisationPayload(): void {
    this.notifyOrganisationPayload.emit([...this.items]);
  }

  ngOnDestroy(): void {
    /**/
  }
}
