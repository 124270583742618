<ng-container *ngIf="isShownPartnerStatus">
  <div
    class="partner-state"
    [ngClass]="getPartnerStatusClass(partnerStatus?.codeId)"
  >
    <div class="partner-state-circle">
      <i class="fas fa-star"></i>
    </div>
    <label class="partner-state-flag">
      <span>
        {{ getPartnertStatusValue(partnerStatus?.value) }}
      </span>
    </label>
  </div>
</ng-container>
