import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ShareEntityToPeopleDialogComponent } from '@components/dialogs/share-entity-to-people-dialog/share-entity-to-people-dialog.component';
import { BaseHttpService } from '@core/http/base-http.service';
import { SessionService } from '@core/session.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttributePropertyName } from '@shared/enums/attribute-display-name.enum';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { DeactivateProfileDialogComponent } from '@src/app/components/dialogs/deactivate-profile-dialog/deactivate-profile-dialog.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { Entity } from '@src/app/shared/models/entity.model';

@Component({
  selector: 'app-interaction-bar-people',
  templateUrl: './interaction-bar-people.component.html',
})
export class InteractionBarPeopleComponent {
  @Input() data: PeopleInterface;
  @Input() isSamePerson = false;
  @Input() httpService: BaseHttpService<any>;
  @Input() isLoadingFollowingsCount = false;
  @Input() showDeactivateProfileBtn: boolean;

  @Output() triggerToggleFavorite = new EventEmitter();

  selectedEntityId;
  showPeopleModal = false;

  constructor(
    protected modalService: NgbModal,
    private sessionService: SessionService,
    private authenService: AuthenService
  ) {}

  showEntityData(venture: { id }): void {
    if (venture) {
      this.selectedEntityId = null;
      setTimeout(() => {
        this.selectedEntityId = venture.id;
      });
    }
  }

  openSharePerson(person: PeopleInterface): void {
    if (
      !this.sessionService.isLogin ||
      !this.sessionService.tenant.enableCommunication
    ) {
      return;
    }
    const modalRef = this.modalService.open(
      ShareEntityToPeopleDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: 'message-modal-custom',
      }
    );

    modalRef.componentInstance.entity = person;
    modalRef.componentInstance.entityName = EntityName.Person;
    modalRef.componentInstance.httpService = this.httpService;
    modalRef.componentInstance.placeholder = 'UI.SendMessage.Placeholder';
    modalRef.componentInstance.choice = AttributePropertyName.ShareRecipient;
  }

  openDeactivateProfileDialog(person: PeopleInterface): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const modalRef = this.modalService.open(DeactivateProfileDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'md',
      modalDialogClass: 'deactivate-modal',
    });

    const deactivateObs$ = (
      this.httpService as PeopleHttpService
    ).deactivateAccount({ id: person.id });

    const successCallback = () => {
      this.authenService.logout();
    };

    modalRef.componentInstance.heading =
      'UI.DeactivateProfile.confirmationMessage';
    modalRef.componentInstance.message =
      'UI.DeactivateProfile.deactivateSadMessage';
    modalRef.componentInstance.subMessage =
      'UI.DeactivateProfile.deactivateWarning';
    modalRef.componentInstance.confirmLabel =
      'UI.DeactivateProfile.sorryButton';
    modalRef.componentInstance.cancelLabel = 'UI.DeactivateProfile.stayButton';
    modalRef.componentInstance.deactivateObs$ = deactivateObs$;
    modalRef.componentInstance.successCallback = successCallback.bind(this);
  }

  isEnableCommunication(): boolean {
    return this.sessionService.tenant.enableCommunication;
  }

  toggleFavorite(entity: Entity): void {
    this.triggerToggleFavorite.emit(entity);
  }
}
