<div
  class="form-group"
  [ngbTooltip]="htmlTooltipContent"
  [disableTooltip]="!tooltip"
  [class]="wrapperClass"
>
  <div
    class="form-check custom-control custom-checkbox"
    [class.disabled-action]="disabled"
  >
    <input
      class="form-check-input custom-control-input"
      type="checkbox"
      [id]="elementId"
      [name]="elementId"
      [ngModel]="isChecked"
      (change)="toggle($event)"
    />
    <label class="form-check-label custom-control-label" [for]="elementId" [ngClass]="labelClass">
      {{ content }}
    </label>
  </div>
</div>

<ng-template #htmlTooltipContent>
  <quill-view
    class="ql-editor-only-view"
    [content]="tooltip | translate"
  ></quill-view>
</ng-template>
