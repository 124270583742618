<tag-input
  *ngIf="editable; else viewOnly"
  [(ngModel)]="items"
  [placeholder]="placeholder"
  [secondaryPlaceholder]="placeholder"
  [addOnBlur]="true"
  (ngModelChange)="emitValue()"
>
</tag-input>
<ng-template #viewOnly>
  <tag-input [hideForm]="true" [(ngModel)]="items"> </tag-input>
</ng-template>
