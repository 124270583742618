<ng-container *ngIf="!isSentEmail; else sentEmailDeliveredMessage">
  <div class="forgot-password">
    <p class="title" translate>
      UI.Forgot_Password.Please-enter-your-username-or-email
    </p>

    <form [formGroup]="confirmationForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="usernameOrEmail" translate
          >{{ 'Username' | translate }}/{{ 'Email' | translate }}</label
        >

        <input
          id="usernameOrEmail"
          type="text"
          class="form-control"
          formControlName="usernameOrEmail"
        />

        <div
          *ngIf="
            usernameOrEmail.invalid &&
            (usernameOrEmail.dirty || usernameOrEmail.touched)
          "
          class="alert alert-danger"
        >
          <div *ngIf="usernameOrEmail.errors.required" translate>
            UI.Forgot_Password.UsernameEmail-required
          </div>

          <div *ngIf="usernameOrEmail.errors.email" translate>
            {{ 'Invalid email address' }}
          </div>

          <div *ngIf="usernameOrEmail.errors.jip_usernameOrEmail" translate>
            {{ errorMessage ? errorMessage.usernameOrEmail : '' }}
          </div>
        </div>

        <ng-container *ngIf="!isSentEmail && sendEmailFalseMessage">
          <div class="alert alert-danger mt-2">
            <div>
              {{ sendEmailFalseMessage }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="confirm-account-recaptcha">
        <app-google-recapcha
          [formInput]="confirmationForm"
        ></app-google-recapcha>
      </div>

      <!-- error -->
      <div
        *ngIf="
          confirmationForm.errors &&
          (confirmationForm.dirty || confirmationForm.touched)
        "
        class="alert alert-danger"
        translate
      >
        {{ errorMessage[formErrorKey] }}
      </div>

      <div class="form-group text-center">
        <button
          type="submit"
          appDebounceClick
          (debounceClick)="onSubmit()"
          class="btn btn-primary"
          [disabled]="
            !confirmationForm.valid ||
            isSubmitting ||
            isSubmitted ||
            !(confirmationForm?.touched || confirmationForm?.dirty)
          "
        >
          <ng-container *ngIf="isSubmitting">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span>{{ 'UI.Common.Request' | translate }}</span>
        </button>
      </div>
    </form>
  </div>
</ng-container>

<ng-template #sentEmailDeliveredMessage>
  <div class="email-sent">
    <div class="email-sent__info mt-4">
      <span
        class="info-text"
        [innerHTML]="
          'UI.Forgot_Password.Inform-email-and-follow-link'
            | translate
            | stringReplacement
              : {
                  '{userEmail}':
                    this.sentEmailAddress | emailWrapper: this.sentEmailAddress
                }
        "
      ></span>

      <span class="mt-3">
        <b translate>UI.Common.Didnot-receive-email</b>
        <span class="ml-2" translate>UI.Common.Please-check-your-spam</span>
      </span>
    </div>

    <div class="form-group text-center email-sent__buttons">
      <button
        type="button"
        appDebounceClick
        (debounceClick)="
          resendResetPassword(this.sentEmailAddress, this.resetPasswordToken)
        "
        class="btn btn-secondary"
        [disabled]="isSubmitting"
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span> {{ 'UI.Common.Resend-email' | translate }} </span>
      </button>

      <a href="/" class="btn btn-primary ml-3" translate> UI.Common.OK </a>
    </div>
  </div>
</ng-template>
