import { MultiStringRelatedEntity } from '../interfaces/attribute-description.interface';

export const LST_RELATED_ENTITY_AS_MULTISTRING: Record<
  string,
  MultiStringRelatedEntity
> = {
  Venture: {
    propertyNames: ['Demands'],
    keyMap: 'content',
  },
  Event: {
    propertyNames: ['Speakers'],
    keyMap: 'value',
  },
  Organization: {
    propertyNames: ['Expertises', 'KeyOfferings'],
    keyMap: 'description',
  },
  Challenge: {
    propertyNames: ['Expertises', 'KeyOfferings'],
    keyMap: 'description',
  },
  Person: {
    propertyNames: [
      'PersonalOfferings',
      'PersonalSearchings',
      'CompanyOfferings',
      'CompanySearchings',
    ],
    keyMap: 'value',
  },
};

export const MULTISTRING_DEFAULT_PLACEHOLDER =
  'Just hit enter to add a tag (spaces allowed)';
