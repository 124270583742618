import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bot-flag',
  templateUrl: './bot-flag.component.html',
  styles: [],
})
export class BotFlagComponent {
  @Input() parentClass = '';
}
