import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES } from '@src/app/core/http/organization-http.service';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { MetadataRecord } from '@src/app/shared/interfaces/metadata-table.interface';

@Component({
  selector: 'app-organization-custom-attributes',
  templateUrl: './organization-custom-attributes.component.html',
  styles: [],
})
export class OrganizationCustomAttributesComponent implements OnChanges {
  @Input() formInput: UntypedFormGroup;
  @Input() editable = false;
  @Input() metadataRecord: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };
  @Input() orderOfPropertyNames: string[] = [];

  orderedAttributeDescriptions: AttributeDescription[] = [];

  AttributeType = AttributeType;

  ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES =
    ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metadataRecord && changes.metadataRecord.currentValue) {
      this.orderedAttributeDescriptions =
        this.attributeDescriptionsSortByOrderOfPropertyNames(
          this.metadataRecord.attributeDescriptions,
          this.ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES
        );
    }
  }

  private attributeDescriptionsSortByOrderOfPropertyNames(
    attributeDescriptions: AttributeDescription[] = [],
    orderOfPropertyNames: string[] = []
  ): AttributeDescription[] {
    if (attributeDescriptions.length > 0 && orderOfPropertyNames.length > 0) {
      const existAttrDesLst = attributeDescriptions.filter((x) =>
        orderOfPropertyNames.includes(x.propertyName)
      );
      const remainAttrDesLst = attributeDescriptions.filter(
        (x) => !orderOfPropertyNames.includes(x.propertyName)
      );

      existAttrDesLst.sort((a, b) => {
        return (
          orderOfPropertyNames.indexOf(a.propertyName) -
          orderOfPropertyNames.indexOf(b.propertyName)
        );
      });

      return [...existAttrDesLst, ...remainAttrDesLst];
    }
    return attributeDescriptions;
  }
}
