<ng-container>
  <div class="w-100">
    <div class="venture__label widget__label">
      {{ 'UI.Ventures.OpenFor' | translate }}
    </div>
    <ul
      appTooltip
      triggers="manual"
      #t3="ngbTooltip"
      [toolTipControl]="t3"
      [ngbTooltip]="openFor"
      tooltipClass="no-limit-width-tooltip"
      class="list-inline widget__bold truncate-line-1 mb-0"
    >
      <ng-container *ngTemplateOutlet="openFor"></ng-container>
    </ul>
    <ng-template #openFor>
      <ng-container *ngIf="demands">
        <li
          *ngFor="let item of demands"
          class="list-inline-item font-weight-bold"
        >
          <span class="venture__hashtags widget__bold badge"
            ># {{ item?.content }}</span
          >
        </li>
      </ng-container>
      <ng-container *ngIf="offerings">
        <li
          *ngFor="let item of offerings || []"
          class="list-inline-item font-weight-bold"
        >
          <span class="venture__hashtags widget__bold badge"
            ># {{ item?.content }}</span
          >
        </li>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
