import { ChangeDetectorRef, Component } from '@angular/core';
import { ChallengeHttpService } from '@src/app/core/http/challenge-http.service';
import { SessionService } from '@src/app/core/session.service';
import { MetadataTableComponent } from '@src/app/shared/components/metadata-table/metadata-table.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { ChallengeInterface } from '@src/app/shared/interfaces/challenge.interface';

@Component({
  selector: 'app-metadata-table-challenge',
  templateUrl: '../metadata-table.component.html',
})
export class MetadataTableChallengeComponent extends MetadataTableComponent<ChallengeInterface> {
  entityName = EntityName.Challenge;
  orgFilterPropertyName: string[] = [
    'Id',
    'Logo',
    'OrgName',
    'EnclosingOrgName',
    'CreatedDate',
    'Image',
    'PartnerStatus',
    'Headline',
    'SubHeadline',
    'Description',
    'Expertises',
    'KeyOfferings',
  ];
  readonlyField = ['Id', 'CreatedDate'];

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: ChallengeHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  hasEditPermission(attrDes: AttributeDescription, entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    const canEdit = super.hasEditPermission(attrDes, entityId);

    return (
      canEdit && (this.isOwner(entity) || this.hasCustomPermission(attrDes))
    );
  }

  getImgCustomClass(propertyName: string): string {
    if (propertyName === 'Logo') {
      return 'organization__logo';
    } else {
      return super.getImgCustomClass(propertyName);
    }
  }

  getEditCellCustomClass(propertyName: string): string {
    if (propertyName === 'PartnerStatus') {
      return 'position-relative w-100 h-100';
    } else {
      return super.getEditCellCustomClass(propertyName);
    }
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.orgFilterPropertyName;
  }

  protected customUpdateColumn(resultColumn: Record<string, any>): void {
    if (resultColumn.propertyName === 'PartnerStatus') {
      resultColumn.width = 170;
    }
  }

  protected hasCustomPermission(attrDes: AttributeDescription): boolean {
    return (
      this.sessionService.currentUser?.isTenantAdmin &&
      attrDes.propertyName === 'PartnerStatus'
    );
  }
}
