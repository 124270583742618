<div *ngIf="isVisble" class="search-channel-history">
  <p class="search-channel-history__recent-use m-0 mb-1 pt-0" translate>
    UI.TopicFilter.RecentlyUsedTopics
  </p>
  <p
    *ngIf="historyChannels && historyChannels.length === 0"
    class="search-channel-history__no-filter m-0"
    translate
  >
    UI.TopicFilter.NoFilterTopic
  </p>
  <div class="search-channel-history__channel-list">
    <ul
      *ngFor="let item of historyChannels"
      class="channel-search-list__group--items m-0 p-0"
      (click)="$event.preventDefault(); $event.stopPropagation()"
    >
      <li
        *ngIf="
          activeFilter === EntityName.All || item.entityName === activeFilter
        "
        class="channel-filter-item dropdown-item w-100 d-flex justify-content-between align-items-center"
        style="list-style-type: none"
        (click)="onSelectChannel(item)"
      >
        <div class="channel-item d-flex align-items-center gap-8">
          <div
            *ngIf="item.image"
            class="upload-file__container partner-org-select__image-container flex-shrink-0"
          >
            <img
              appDefaultPlaceholder
              appDynamicImgUrl
              [src]="item.image.url"
              [alt]="item.image.alt"
            />
          </div>
          <div class="overflow-hidden truncate-line-1">
            <h6 class="option-name m-0">
              {{ item.displayName }}
            </h6>
            <div class="entity-type d-flex align-items-center">
              <app-shared-icon [name]="item.entityLogo"></app-shared-icon>
              <span class="ml-1">
                {{ item.entityName | translate }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0">
          <em
            appDebounceClick
            (debounceClick)="deleteHistoryChannel(item)"
            class="delete-channel far fa-times mr-3"
            focusable="false"
          >
          </em>
        </div>
      </li>
    </ul>
  </div>
</div>
