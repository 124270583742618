<div class="menu-tabs-bar d-flex align-items-center">
  <div
    [(ngModel)]="currentTab"
    (ngModelChange)="onFilterChange($event)"
    class="btn-group btn-group-toggle d-flex flex-wrap"
    ngbRadioGroup
    role="group"
  >
    <label
      *ngFor="let item of tabs"
      class="btn-outline-primary p-0"
      ngbButtonLabel
    >
      <input [value]="item.key" ngbButton type="radio" />
      <span class="font-size-sm font-weight-600" translate>{{
        item.label
      }}</span>
    </label>
  </div>
</div>
