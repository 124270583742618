<ng-container *ngIf="isPhysicalLocation || isVirtualLocation">
  <div
    class="partner-state partner-state--location"
    [style.left]="uiPosition.left"
    [style.top]="uiPosition.top"
    [style.bottom]="uiPosition.bottom"
    [style.right]="uiPosition.right"
  >
    <ng-container *ngIf="isPhysicalLocation && isVirtualLocation">
      <div class="partner-state-circle">
        <app-internal-icon
          class="icon-bring-forward"
          [name]="'icon-bring-forward'"
          [height]="20"
        ></app-internal-icon>
      </div>
      <label class="partner-state-flag">
        <span> {{ 'UI.Event.Flag.Hybrid' | translate }} </span>
      </label>
    </ng-container>

    <ng-container *ngIf="isPhysicalLocation && !isVirtualLocation">
      <div class="partner-state-circle">
        <i class="fas fa-map-marked-alt"></i>
      </div>
      <label class="partner-state-flag">
        <span> {{ 'UI.Event.Flag.Physical' | translate }} </span>
      </label>
    </ng-container>

    <ng-container *ngIf="!isPhysicalLocation && isVirtualLocation">
      <div class="partner-state-circle">
        <i class="fas fa-chalkboard-teacher"></i>
      </div>
      <label class="partner-state-flag">
        <span> {{ 'UI.Event.Flag.Virtual' | translate }} </span>
      </label>
    </ng-container>
  </div>
</ng-container>
