import { HttpParams } from '@angular/common/http';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { SendMessageBoxBaseComponent } from '@components/dialogs/send-message-box-base/send-message-box-base.component';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import {
  MessageRecipient,
  SendMessageHttpService,
} from '@core/http/send-messages-http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  TagFieldCustom,
  TagFieldType,
  transformToTagModal,
} from '@shared/components/tag-field/tag-field.component';
import { SUGGESTIONS } from '@shared/constants/common';
import { AttributePropertyName } from '@shared/enums/attribute-display-name.enum';
import { EntityName, MessageAction } from '@shared/enums/entity-name.enum';
import { HttpStatusCode } from '@shared/enums/httpstatuscode.enum';
import { RecipientType } from '@shared/enums/message.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { InfiniteScrollDataAdapter } from '@shared/helpers/infinite-scroll-data.adapter';
import {
  CustomAtrributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { TextValidator } from '@shared/utils/form-fields-validation';
import { FormUtils } from '@shared/utils/form-utils';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-jury-invitation-dialog',
  templateUrl: './jury-invitation-dialog.component.html',
})
export class JuryInvitationDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() challengeId: number;

  items: CustomAtrributeValue[] = [];

  selectedRecipients: TagFieldCustom[] = [];

  searchData$: InfiniteScrollDataAdapter;

  currentGroup: CustomAtrributeValue;

  get isAssigningJuror(): boolean {
    return this.form?.controls['isAssigningJuror']?.value;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected authService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected injector: Injector,
    private translateService: TranslateService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authService,
      sendMessageHttpService,
      injector
    );
  }
  ngOnInit(): void {
    super.ngOnInit();
    this.searchData$ = new InfiniteScrollDataAdapter(this.getSource(), 20);
  }

  initForm(): void {
    this.form = this.fb.group({
      selectedRecipients: [null, [Validators.required]],
      currentGroup: [],
      Content: [null, [Validators.required]],
      isSendMail: [false],
      isAssigningJuror: [false],
    });

    if (this.contentAttributeDescription?.required) {
      this.content?.setValidators([
        Validators.required,
        TextValidator.notEmptyOrWhitespace,
      ]);
    }
    this.formErrorService.register(this);
  }

  onSend(): void {
    const action = this.isAssigningJuror
      ? MessageAction[MessageAction.WelcomeJuror]
      : MessageAction[MessageAction.InviteJuror];
    const payload = {
      customMessage: {
        entityName: EntityName.Challenge,
        entityId: this.challengeId,
        action,
      },
      content: this.content.value,
      ids: this.recipients.value?.map(({ id }) => id),
      type: RecipientType.People,
      isSendMail: this.isSendMail?.value,
      includeSubOrganization: false,
    };

    this.sendMessageHttpService
      .sendToRecipients(payload as any)
      .pipe(
        catchError((err) => {
          this.formErrorService.handleError(err);
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((res: any) => {
        const error = res as ApiResponse;

        if (error?.status === HttpStatusCode.BadRequest) {
          this.activeModal.close(false);

          return;
        }

        this.activeModal.close(true);
      });
  }

  handleRecipientsChanged(selectedRecipients: TagFieldCustom[]): void {
    this.selectedRecipients = selectedRecipients || [];
    this.form.patchValue({ selectedRecipients });
  }

  private getSource(): (
    searchKeyword: string,
    pageIndex: number,
    pageSize: number
  ) => Observable<TagFieldCustom[]> {
    return (
      searchKey: string,
      pageIndex: number,
      pageSize: number
    ): Observable<TagFieldCustom[]> =>
      of({}).pipe(
        switchMap(() => this.searchEntities(searchKey, pageIndex, pageSize))
      );
  }

  searchEntities(
    text: string,
    pageIndex?: number,
    pageSize?: number
  ): Observable<TagFieldCustom[]> {
    const currentGroup = this.form?.get('currentGroup')?.value;
    const customParams = new HttpParams()
      .set(UrlParam.OrgIds, this.challengeId.toString())
      .set(UrlParam.IsIncludeMyself, String(true));

    return this.sendMessageHttpService
      .searchRecipients(currentGroup, text, pageIndex, pageSize, customParams)
      .pipe(
        map((res: MessageRecipient) => {
          const suggestions: TagFieldCustom = {
            display: this.translateService.instant(SUGGESTIONS),
            type: TagFieldType.Header,
            value: SUGGESTIONS,
          };
          const preSelections = [suggestions];
          const items = transformToTagModal(res.items);

          return pageIndex ? items : preSelections.concat(...items);
        })
      );
  }

  protected handleAfterLoadAttributeDescription(res: MetaInformation): void {
    this.initForm();

    const recipientAttribute = FormUtils.getFieldOptions(
      res.entityDescription.attributeDescriptions,
      AttributePropertyName.Recipient
    );

    // Community group
    const currentGroup = recipientAttribute?.choice.selections[0];

    this.form.patchValue({ currentGroup });
  }
}
