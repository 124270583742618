<ng-container [ngSwitch]="currentEventStamp">
  <div
    class="position-absolute"
    [style.left]="uiPosition.left"
    [style.top]="uiPosition.top"
    [style.bottom]="uiPosition.bottom"
    [style.right]="uiPosition.right"
    [ngClass]="wrapperClass"
  >
    <div
      class="event-stamp event-stamp__ongoing"
      *ngSwitchCase="EventStamp.Ongoing"
    >
      {{ 'UI.Event.Stamp.Ongoing' | translate }}
    </div>
    <div
      class="event-stamp event-stamp__passed"
      *ngSwitchCase="EventStamp.Passed"
    >
      {{ 'UI.Event.Stamp.Passed' | translate }}
    </div>
    <div
      class="event-stamp event-stamp__fully-booked"
      *ngSwitchCase="EventStamp.FullyBooked"
    >
      {{'UI.EventEdit.FullyBooked' | translate }}
    </div>
    <div
      class="event-stamp event-stamp__cancelled"
      *ngSwitchCase="EventStamp.Cancelled"
    >
      {{'UI.Event.Stamp.Cancelled' | translate }}
    </div>
  </div>
</ng-container>
