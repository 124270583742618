<ng-container
  *ngIf="
    (sessionService.isLogin && (profile$ | async)) ||
    changePasswordMode === ChangePasswordModeEnum.Reset
  "
>
  <div class="jip-login">
    <div
      *ngIf="logo_display_option === 'jip'"
      class="jip-login__logo text-center"
    >
      <app-jip-logo
        class="jip-login__logo--others"
        name="jip-logo"
        width="380px"
        height="106px"
      ></app-jip-logo>
      <app-jip-logo
        class="jip-login__logo--mobile"
        name="jip-logo"
        width="380px"
        height="106px"
      ></app-jip-logo>
    </div>

    <div
      *ngIf="logo_display_option === 'innovation_space'"
      class="jip-login__org-logo"
    >
      <app-organization-logo
        [className]="'filter__org-logo text-center'"
        [organizationLogo]="
          (centralConfig?.read(global_org_id) | async)?.logo.url
        "
      ></app-organization-logo>
    </div>

    <div class="jip-login__header d-flex justify-content-center mb-3">
      <ng-container
        *ngIf="
          changePasswordMode === ChangePasswordModeEnum.Reset;
          else changePasswordTittle
        "
      >
        <span class="jip-login__header-title" translate>ResetPassword</span>
      </ng-container>
      <ng-template #changePasswordTittle>
        <span class="jip-login__header-title" translate>ChangePassword</span>
      </ng-template>
    </div>
    <div>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <!-- Current Password -->
        <div
          class="form-group"
          *ngIf="
            changePasswordMode === ChangePasswordModeEnum.Manual &&
            userHasPassword
          "
        >
          <label for="oldPassword" translate>CurrentPassword</label>
          <div class="input-group">
            <input
              id="oldPassword"
              [type]="currentPasswordIsShow ? 'text' : 'password'"
              class="form-control"
              formControlName="oldPassword"
            />
            <div class="input-group-append">
              <a
                href=""
                class="input-group-text"
                (click)="toggleCurrentPassword($event)"
              >
                <i
                  class="fa"
                  [class]="{
                    'fa-eye-slash': currentPasswordIsShow,
                    'fa-eye': !currentPasswordIsShow
                  }"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>

          <div
            *ngIf="
              oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)
            "
            class="alert alert-danger"
          >
            <div *ngIf="oldPassword.errors.required" translate>
              CurrentPasswordIsRequired
            </div>
            <div *ngIf="oldPassword.errors.notEmptyOrWhitespace" translate>
              {{ 'Current Password' | translate }}
              {{ 'cannot be empty or whitespace' | translate }}.
            </div>
            <div *ngIf="oldPassword.errors.jip_oldPassword" translate>
              {{ errorMessage.jip_oldPassword }}
            </div>
          </div>
        </div>

        <!-- New Password -->
        <div class="form-group">
          <label class="d-block" for="newPassword" translate>NewPassword</label>
          <div class="input-group">
            <input
              id="newPassword"
              [type]="newPasswordIsShow ? 'text' : 'password'"
              class="form-control"
              formControlName="newPassword"
            />
            <div class="input-group-append">
              <a
                href=""
                class="input-group-text"
                (click)="toggleNewPassword($event)"
              >
                <i
                  class="fa"
                  [class]="{
                    'fa-eye-slash': newPasswordIsShow,
                    'fa-eye': !newPasswordIsShow
                  }"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>

          <ng-container
            *ngIf="
              newPassword.invalid && (newPassword.dirty || newPassword.touched)
            "
          >
            <div
              class="alert alert-danger"
              *ngIf="newPassword.errors.required"
              translate
            >
              NewPasswordIsRequired
            </div>

            <div
              class="alert alert-danger"
              *ngIf="newPassword.errors.notEmptyOrWhitespace"
            >
              {{ 'New Password' | translate }}
              {{ 'cannot be empty or whitespace' | translate }}.
            </div>
          </ng-container>
          <small>
            {{ 'UI.Validation.PasswordHint' | translate }}
            <b><span>#$^+=!*()&#64;%&.,+-_<></span></b></small
          >
        </div>

        <!-- Retype Password -->
        <div class="form-group">
          <label class="d-block" for="passwordRepeat" translate
            >Re-type Password</label
          >
          <div class="input-group">
            <input
              id="passwordRepeat"
              [type]="passwordRepeatIsShow ? 'text' : 'password'"
              class="form-control"
              formControlName="passwordRepeat"
            />
            <div class="input-group-append">
              <a
                href=""
                class="input-group-text"
                (click)="togglePasswordRepeat($event)"
              >
                <i
                  class="fa"
                  [class]="{
                    'fa-eye-slash': passwordRepeatIsShow,
                    'fa-eye': !passwordRepeatIsShow
                  }"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>

          <div
            *ngIf="
              passwordRepeat.invalid &&
              (passwordRepeat.dirty || passwordRepeat.touched)
            "
            class="alert alert-danger"
          >
            <div *ngIf="passwordRepeat.errors.required" translate>
              RetypePasswordIsRequired
            </div>
          </div>
          <div
            *ngIf="
              passwordRepeat.value &&
              changePasswordForm.errors?.passwordNotSame &&
              (passwordRepeat.dirty || passwordRepeat.touched)
            "
            class="alert alert-danger"
          >
            <div translate>Password does not match.</div>
          </div>
        </div>

        <ng-container
          *ngIf="
            changePasswordForm.invalid &&
            (changePasswordForm.dirty || changePasswordForm.touched)
          "
        >
          <div
            *ngIf="errorMessage[formErrorKey] || formErrorMessage"
            class="alert alert-danger"
          >
            {{ errorMessage[formErrorKey] || formErrorMessage | translate }}
          </div>
        </ng-container>

        <!-- Change button -->
        <div class="form-group text-center">
          <ng-container
            *ngIf="
              changePasswordMode === ChangePasswordModeEnum.Reset;
              else changePasswordModeBtn
            "
          >
            <button
              type="submit"
              appDebounceClick
              (debounceClick)="onSubmit()"
              class="btn btn-primary"
              [disabled]="
                !changePasswordForm.valid ||
                isSubmitting ||
                isSubmitted ||
                !(changePasswordForm?.touched || changePasswordForm?.dirty)
              "
            >
              <ng-container *ngIf="isSubmitting">
                <i class="fas fa-spinner fa-spin"></i>
              </ng-container>
              <span translate>Change</span>
            </button>
          </ng-container>
          <ng-template #changePasswordModeBtn>
            <button
              appRequireLogin
              type="submit"
              appDebounceClick
              (debounceClick)="onSubmit()"
              class="btn btn-primary"
              [disabled]="
                !changePasswordForm.valid ||
                isSubmitting ||
                isSubmitted ||
                !(changePasswordForm?.touched || changePasswordForm?.dirty)
              "
            >
              <ng-container *ngIf="isSubmitting">
                <i class="fas fa-spinner fa-spin"></i>
              </ng-container>
              <span translate>Change</span>
            </button>
          </ng-template>
        </div>
      </form>
    </div>

    <div class="jip-login__footer">
      <span class="jip-login__header-info" translate
        >UI.Change_Password.Back-to
        <a [href]="myProfileUrl" class="jip-login__text-underline" translate>{{
          changePasswordMode === ChangePasswordModeEnum.Reset
            ? 'Log in'
            : 'My Profile'
        }}</a>
      </span>
    </div>
  </div>
</ng-container>
