import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TimestampsService } from '@src/app/core/http/timestamps.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { TimestampIcon, TimestampModel, TimestampPaginationPayload } from '@src/app/shared/interfaces/timestamp.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { ColumnMode } from '@siemens/ngx-datatable';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-timestamp-section',
  templateUrl: './timestamp-section.component.html',
})
export class TimestampSectionComponent implements OnInit, OnDestroy {
  @Input() entityName: EntityName;
  @Input() entityId: number;

  lstTimestamp: TimestampModel[] = [];
  isLoading = false;

  pageSize = 4;
  page = 1;
  collectionSize = 0;

  ColumnMode = ColumnMode;
  DateFormat = DateFormat;

  timestampIconRed = new TimestampIcon('Icon-red').icon;
  showColumns = ['TimestampType', 'CreatedDate', 'Creator', 'Description'];

  constructor(
    private timestampsService: TimestampsService
  ) { }

  ngOnInit(): void {
    if (this.entityName && this.entityId) {
      this.getLstTimestamp();
    }
  }

  getLstTimestamp(): void {
    this.isLoading = true;
    const payload: TimestampPaginationPayload = {
      pageIndex: this.page,
      pageSize: this.pageSize,
      entityName: this.entityName,
      entityId: this.entityId
    };
    this.timestampsService.paginate(payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res: ApiGetResponse<TimestampModel>) => {
        if (res?.items) {
          this.lstTimestamp = res.items;
          this.lstTimestamp.forEach((timestamp: TimestampModel) => {
            timestamp.timestampTypeSingleSelection =
              CustomMetadataUtils.getAttributeValueByPropertyName(
                timestamp.attributeDescriptions,
                timestamp.attributeValues,
                'TimestampType');
          });
          this.collectionSize = res.rowCount;
        }
      });
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.getLstTimestamp();
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.getLstTimestamp();
  }

  getEntityUrl(entityName: string): string {
    return StringUtils.getEntityUrlByEntityName(entityName);
  }

  getTimestampIcon(name: string): string {
    return new TimestampIcon(name).icon;
  }

  ngOnDestroy(): void { /**/ }
}
