import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { VENTURE_REQUIRED_KEY } from '@core/http/venture.http.service';
import {
  NgbDateParserFormatter,
  NgbModal,
  NgbPopover,
} from '@ng-bootstrap/ng-bootstrap';
import { ORG_REQUIRED_KEYS } from '@src/app/components/organizations/components/organization/organization-edit/organization-edit.component';
import { PublishVentureConfirmationDialogComponent } from '@src/app/components/ventures/components/venture/publish-venture-confirmation-dialog/publish-venture-confirmation-dialog.component';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import {
  UnsavedFormCheckService,
  UnsavedFormComponent,
} from '@src/app/core/form/unsaved-form-check.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { finalize } from 'rxjs/operators';
import { VenturePropertyName } from '../../enums/property-name.enum';
import { untilDestroyed } from '../../functions/until-destroyed';

@Component({
  selector: 'app-metadata-table-cell-edit',
  templateUrl: './metadata-table-cell-edit.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class MetadataTableCellEditComponent
  implements OnInit, OnDestroy, UnsavedFormComponent
{
  @Input() entityName = '';
  @Input() entityId: number;
  @Input() attributeDescription: AttributeDescription;
  @Input() currentValue: any;
  @Input() popover: NgbPopover;
  @Input() baseHttpService: BaseHttpService<any>;

  @Output() valueUpdated = new EventEmitter<any>();

  form: UntypedFormGroup;
  requesting = false;

  AttributeType = AttributeType;
  PropertyName = VenturePropertyName;

  propName: string;

  constructor(
    protected fb: UntypedFormBuilder,
    protected customFormService: CustomFormService,
    protected unsavedFormCheckService: UnsavedFormCheckService,
    protected toastService: ToastService,
    protected modalService: NgbModal
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): Promise<boolean | any> | boolean {
    let willNavigate = false;
    if (
      !this.unsavedFormCheckService ||
      !this.unsavedFormCheckService.hasUnsavedContent()
    ) {
      willNavigate = true;
    }

    return willNavigate;
  }

  ngOnInit(): void {
    if (this.attributeDescription) {
      this.propName = this.attributeDescription.propertyName;
      const control = this.generateControl();
      this.unsavedFormCheckService.register(this);
      if (control) {
        this.form = this.fb.group({
          ...control,
        });
        this.updateFormValue();
      }
    }
  }

  get formControl(): AbstractControl {
    return this.form.controls[this.propName];
  }

  isFormNotSaved(): boolean {
    return true;
  }

  cancel(): void {
    this.popover.close();
  }

  save(): void {
    FormUtils.maskFormAsTouched(this.form);
    if (this.form.invalid) {
      this.toastService.removeAll();
      this.toastService.showError('UI.Toast.CheckMandatoryField', '', 5000);
      return;
    }

    this.requesting = true;
    const payload: Record<string, any> = this.getPayload();
    this.handlePatchApi(payload);
  }

  getFormOptions(attrDes: AttributeDescription): Record<string, any> {
    return CustomMetadataUtils.getFormOptions(attrDes);
  }

  publish(): void {
    const confirmMessage = 'UI.Venture.AskConfirmPublishAllStakeholders';
    const confirmAction = 'UI.Venture.ConfirmPublish';

    this.handlePublishVenture(confirmMessage, confirmAction, true);
  }

  unpublish(): void {
    const confirmMessage = 'UI.Venture.AskConfirmUnpublish';
    const confirmAction = 'UI.Venture.ConfirmUnpublish';

    this.handlePublishVenture(confirmMessage, confirmAction, false);
  }

  handlePublishVenture(
    confirmMessage: string,
    confirmAction: string,
    willPublish: boolean
  ): void {
    const modalRef = this.modalService.open(
      PublishVentureConfirmationDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
      }
    );

    modalRef.componentInstance.confirmContent = confirmMessage;
    modalRef.componentInstance.confirmAction = confirmAction;

    modalRef.result
      .then((accepted) => {
        if (accepted) {
          this.requesting = true;
          if (willPublish) {
            this.baseHttpService
              .publish(this.entityId)
              .pipe(
                untilDestroyed(this),
                finalize(() => {
                  this.requesting = false;
                })
              )
              .subscribe((res) => {
                this.form.controls[VenturePropertyName.IsPrivate]?.setValue(
                  false
                );
                this.handleSuccessfulResponse({ IsPrivate: false });
              });
          } else {
            this.baseHttpService
              .unpublish(this.entityId)
              .pipe(
                untilDestroyed(this),
                finalize(() => {
                  this.requesting = false;
                })
              )
              .subscribe((res) => {
                this.form.controls[VenturePropertyName.IsPrivate]?.setValue(
                  true
                );
                this.handleSuccessfulResponse({ IsPrivate: true });
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  protected generateControl(): Record<string, any> {
    let requiredKeys: string[] = [];
    switch (this.entityName) {
      case EntityName.Venture:
        requiredKeys = VENTURE_REQUIRED_KEY;
        break;

      case EntityName.Event:
        break;

      case EntityName.Organization:
      case EntityName.Challenge:
        requiredKeys = ORG_REQUIRED_KEYS;
        break;
    }

    return this.customFormService.createControl(
      [this.attributeDescription],
      requiredKeys
    );
  }

  protected updateFormValue(): void {
    this.form.controls[this.propName].setValue(this.currentValue);
  }

  protected emitValue(transformValue: Record<string, any>): void {
    this.valueUpdated.emit(this.form.controls[this.propName].value);
  }

  protected getPayload(): Record<string, any> {
    const payload: Record<string, any> = {};
    payload[this.propName] = this.form.controls[this.propName].value;
    CustomMetadataUtils.formatDtoValueForUpdateCustomAttribute(
      payload,
      this.propName,
      this.attributeDescription.attributeType
    );
    return payload;
  }

  protected handlePatchApi(payload: Record<string, any>): void {
    this.baseHttpService
      .updateCustomAttribute(this.entityId, payload)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.requesting = false;
        })
      )
      .subscribe({
        next: (res: any) => {
          if (res && !isNaN(res)) {
            this.handleSuccessfulResponse(payload);
          } else {
            this.toastService.showError('UI.Toast.UpdatedFailed');
          }
        },
        error: (errorRes: HttpErrorResponse) => {
          this.handleError(errorRes);
        },
      });
  }

  protected handleSuccessfulResponse(payload: Record<string, any>): void {
    this.toastService.showSuccess('UI.Toast.UpdatedSuccessfully');
    this.emitValue(payload);
    this.popover.close();
  }

  protected handleError(errorRes: HttpErrorResponse): void {
    if (errorRes.error?.title) {
      this.toastService.showError(errorRes.error.title);
    }
  }

  ngOnDestroy(): void {
    this.unsavedFormCheckService.remove(this);
  }
}
