import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'concatName',
})
export class ConcatNamePipe implements PipeTransform {
  transform(firstName: string = '', lastName: string = ''): string {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    } else if (firstName) {
      return firstName;
    } else if (lastName) {
      return lastName;
    }
    return '';
  }
}
