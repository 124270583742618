<div
  class="venture-stages-menu-bar menu-tabs-bar d-flex align-items-center mt-3 m-0"
>
  <div
    (ngModelChange)="onFilterChange($event)"
    [(ngModel)]="currentFilter"
    aria-label="Basic outlined example"
    class="btn-group btn-group-toggle d-flex flex-wrap"
    ngbRadioGroup
    role="group"
  >
    <label
      *ngFor="let item of filters"
      class="btn-outline-primary p-0"
      ngbButtonLabel
    >
      <input [value]="item.key" ngbButton type="radio" />
      <span class="font-weight-600"
        >{{ item.label | translate }} ({{ stateCount[item.key] || 0 }})</span
      >
    </label>
  </div>
</div>
