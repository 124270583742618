<div *ngIf="displayItems" class="jip-multi-selection">
  <div
    *ngFor="let item of displayItems"
    (click)="toggleItem(item)"
    class="jip-multi-selection__item"
    [class]="{
      'jip-multi-selection__item--selected': item.selected,
      'jip-multi-selection__item--editable': editable
    }"
  >
    {{ item.value }}
  </div>
</div>
