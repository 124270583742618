import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse',
})
export class JsonParsePipe implements PipeTransform {
  transform(value: string, propName?: string): unknown {
    try {
      return propName ? JSON.parse(value)[propName] : JSON.parse(value);
    } catch (err) {
      return propName ? undefined : {};
    }
  }
}
