import { TenantLanguageInterface } from '@shared/interfaces/tenant-langauge.interface';
import { BaseHttpService } from '@core/http/base-http.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantLanguageService extends BaseHttpService<
  TenantLanguageInterface
> {
  getApiRootPath(): string {
    return 'tenant-languages';
  }
}
