import { Component, Injector, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VenturesComponent } from '@src/app/components/ventures/ventures.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityGlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import { SortCriteria } from '@src/app/shared/models/pagination.model';

@Component({
  selector: 'app-widget-metadata-table-venture',
  templateUrl: './widget-metadata-table-venture.component.html',
})
export class WidgetMetadataTableVentureComponent
  extends VenturesComponent
  implements OnInit
{
  @Input() editable = false;

  constructor(
    public listService: VentureHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadData();
  }

  onSort(sortCriteria: SortCriteria): void {
    const filterDto = {
      ...this.entityGlobalFilterCriteria,
      sortingAttribute: sortCriteria,
    };

    this.paginateFilteredItems(filterDto);
  }

  protected getFilterDto(
    filterCriteria: EntityGlobalFilterCriteria
  ): EntityGlobalFilterCriteria {
    delete filterCriteria.filteringPath;

    let filterDto: EntityGlobalFilterCriteria = {
      ...filterCriteria,
    };

    if (this.orgId) {
      filterDto = this.getFilterDataFollowOrgId(filterDto, this.orgId);
    }
    return filterDto;
  }
}
