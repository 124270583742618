<ng-container *ngIf="form && attributeDescription && entityName">
  <div class="table-cell-edit d-flex flex-column">
    <div class="d-flex flex-column edit-section">
      <div class="edit-section__content">
        <ng-container
          *ngIf="propName !== PropertyName.IsPrivate; else editIsPrivateSection"
        >
          <app-form-field-custom
            [formInput]="form"
            [attributeDescription]="attributeDescription"
            [editable]="!requesting"
            [showLabel]="
              attributeDescription.attributeType === AttributeType.Boolean
                ? false
                : true
            "
            [options]="getFormOptions(attributeDescription)"
          >
          </app-form-field-custom>
        </ng-container>
        <ng-template #editIsPrivateSection>
          <ng-container *ngIf="formControl?.value; else willUnpublish">
            <div class="d-flex align-items-center">
              <button
                type="button"
                appRequireLogin
                appDebounceClick
                (click)="publish()"
                class="btn btn-interact btn-interact--big btn-primary"
              >
                <i class="fas fa-eye"></i>
                <span class="text-hide-small" translate>Publish</span>
              </button>

              <i
                class="fas fa-info-circle widget__color ml-2"
                appTooltip
                placement="left auto"
                triggers="click"
                [ngbTooltip]="'UI.Venture.PublishVenture' | translate"
              ></i>
            </div>
          </ng-container>
          <ng-template #willUnpublish>
            <div class="d-flex align-items-center">
              <button
                type="button"
                appRequireLogin
                appDebounceClick
                (click)="unpublish()"
                class="btn btn-interact btn-interact--big btn-primary"
              >
                <i class="fas fa-eye-slash"></i>
                <span class="text-hide-small" translate>Unpublish</span>
              </button>
              <i
                class="fas fa-info-circle widget__color ml-2"
                appTooltip
                placement="left auto"
                triggers="click"
                [ngbTooltip]="'UI.Venture.UnpublishVenture' | translate"
              ></i>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-2 mt-md-3 edit-section__footer">
      <button
        class="btn btn-outline-secondary mr-2 btn__cancel"
        (click)="cancel()"
        [disabled]="requesting"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        *ngIf="propName !== PropertyName.IsPrivate"
        class="btn btn-primary btn__save"
        appRequireLogin
        appDebounceClick
        (debounceClick)="save()"
        [disabled]="
          form.controls[attributeDescription.propertyName]?.invalid ||
          requesting
        "
      >
        <ng-container *ngIf="requesting; else saveIcon">
          <i class="fas fa-spinner fa-spin m-0"></i>
        </ng-container>
        <ng-template #saveIcon>
          <i class="fas fa-check"></i>
        </ng-template>
      </button>
    </div>
  </div>
</ng-container>
