import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { BaseControlComponent } from '@shared/components/form-custom-attributes/base-control/base-control.component';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent
  extends BaseControlComponent<string>
  implements OnInit, OnDestroy
{
  @Input() defaultMaxLength = 10000;

  @Input() defaultInputHeight = 40;

  @ViewChild('elementRef', { static: true }) textarea: ElementRef;

  ngOnInit(): void {
    this.onWriteValue$
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.autoGrowHeight());
  }

  ngOnDestroy(): void {
    /**/
  }

  autoGrowHeight(element: HTMLElement = this.textarea?.nativeElement): void {
    if (element && element.style) {
      element.style.height = `${this.defaultInputHeight}px`;
      element.style.height =
        (element.scrollHeight < this.defaultInputHeight
          ? this.defaultInputHeight
          : element.scrollHeight) + 'px';
    }
  }

  insertEmoji(event: string): void {
    const target = this.textarea.nativeElement;

    target.focus();

    if (!document.execCommand('insertText', false, event)) {
      target.setRangeText(event);
    }
  }

  onEmojiPicked(event: EmojiData): void {
    this.insertEmoji(event.native);
    this.onValueChange(this.value);
  }

  focus(): void {
    this.textarea?.nativeElement.focus();
  }
}
