<ng-container *ngIf="entity">
  <ng-container
    *ngIf="
      isSyncLatestJoinState || entity.hasJoined === undefined || isPaying;
      else joinEventTemp
    "
  >
    <i class="fas fa-spinner fa-spin"></i>
  </ng-container>
  <ng-template #joinEventTemp>
    <div *ngIf="!entity.hasJoined; else joinedTemp" class="event-register-btn">
      <button
        type="button"
        appDebounceClick
        appTooltip
        class="btn btn-interact btn-primary mw-100"
        (debounceClick)="join($event, entity)"
        [class.btn-interact--big]="btnStyle === 'standard'"
        [class.btn-interact--small]="btnStyle === 'minimal'"
        [ngbTooltip]="entity | eventRegisterTooltip | translate"
        [disabled]="entity | eventRegisterBtnDisabled"
        [class.disable-register]="entity | eventRegisterBtnDisabled"
      >
        <ng-container *ngIf="btnStyle === 'standard'; else minimalBtn">
          <i class="register-icon fas fa-file-medical"></i>
          <span class="text-hide-small">
            {{ 'UI.Event.Register' | translate }}
          </span>
        </ng-container>

        <ng-template #minimalBtn>
          <span class="truncate-line-1" translate
            >UI.Event.RegisterThisEvent</span
          >
        </ng-template>
      </button>
    </div>
    <ng-template #joinedTemp>
      <button
        *ngIf="!isOwner; else isOwnerRegisterBtn"
        appLeaveEntity
        [entityId]="entity.id"
        [entityName]="entityName"
        (afterLeaved)="leave($event)"
        class="btn btn-interact btn-interact--big btn-is-member"
      >
        <i class="fas fa-check-circle"></i>

        <span class="text-hide-small">
          {{ 'UI.Event.Registered' | translate }}</span
        >
      </button>

      <ng-template #isOwnerRegisterBtn>
        <button
          class="btn btn-interact btn-interact--big btn-is-member default-cursor"
        >
          <i class="fas fa-check-circle"></i>

          <span class="text-hide-small">
            {{ 'UI.Event.Registered' | translate }}</span
          >
        </button>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>
