import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SessionService } from '@core/session.service';
import { TranslateService } from '@ngx-translate/core';
import * as COLOR from '@shared/constants/colors.const';
import { DateFormat as DF } from '@shared/enums/date.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { TimeUtils } from '@shared/utils/time-utils';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  ChartType,
  Decimation,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { filter, pluck, switchMap } from 'rxjs/operators';
import Base = moment.unitOfTime.Base;

interface Reporting {
  date: Date;
  numberOfRecords: number;
  month: number;
  year: number;
}

interface ReportingVariables {
  type: string;
  from: Date;
  to: Date;
  id?: number;
}

type TimeScale = 'last7Days' | 'last30Days' | 'last5Months';

const QUERY_BY_DATE = `query getReports(
  $type: EntityType!
  $from: DateTime!
  $to: DateTime!
  $id: Int
) {
  reportingByDate(type: $type, from: $from, to: $to, id: $id) {
    date
    numberOfRecords
    entityType
    id
  }
}`;

const QUERY_BY_MONTH = `query getReports(
  $type: EntityType!
  $from: DateTime!
  $to: DateTime!
  $id: Int
) {
  reportingByMonth(type: $type, from: $from, to: $to, id: $id) {
    month
    numberOfRecords
    entityType
    id
    year
  }
}`;

@Component({
  selector: 'app-reporting-chart',
  templateUrl: './reporting-chart.component.html',
})
export class ReportingChartComponent implements OnInit, OnDestroy {
  @Input() title = 'UI.Chart.NumberOfProjectIdeas';

  // tslint:disable-next-line:variable-name
  @Input() chart_type: ChartType = 'line';

  @Input() tension = 0;

  // tslint:disable-next-line:variable-name
  @Input() entity_type = 'VENTURE_SUBMITTED';

  @Input() from: Date;

  @Input() to: Date;

  @Input() year: number;

  // tslint:disable-next-line:variable-name
  @Input() time_scale: TimeScale = 'last5Months';

  // tslint:disable-next-line:variable-name
  @Input() entity_id: number;

  @Input() exportReportName = '';
  @Input() showExportButton = false;

  @Output() isExporting = new EventEmitter();

  isExportLoading = false;

  timeScaleIndex = 2; // following time_scale

  timeScaleOptions = [
    {
      codeId: 'last7Days',
      value: this.translateService.instant('UI.Date.Last7Days'),
      order: 0,
    },
    {
      codeId: 'last30Days',
      value: this.translateService.instant('UI.Date.Last30Days'),
      order: 1,
    },
    {
      codeId: 'last5Months',
      value: this.translateService.instant('UI.Date.Month'),
      order: 2,
    },
  ];

  charOptions: ChartOptions;

  chartData: ChartData;

  private locale: string;

  private baseDatasetConfig: Partial<ChartDataset>;

  private lineDatasetConfig: Partial<ChartDataset>;

  private readonly API_PATH = 'reporting';

  constructor(
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
    private readonly translateService: TranslateService,
    private readonly cookieService: CookieService
  ) {
    Chart.register(
      CategoryScale,
      Decimation,
      Filler,
      Title,
      Tooltip,
      LinearScale,
      LineController,
      LineElement,
      PointElement,
      BarController,
      BarElement
    );
  }

  ngOnInit(): void {
    this.initFilters();
    this.initChartOptions();
    this.initDatasetConfiguration();
    this.getData();
    this.getCurrentLocale();
  }

  ngOnDestroy(): void {
    /**/
  }

  selectTimeScale(timeScale: CustomAtrributeValue): void {
    this.timeScaleIndex = this.timeScaleOptions.findIndex(
      (o) => o.codeId === timeScale.codeId
    );

    this.setDateRangeByTimeScale();

    this.getData();
  }

  private getCurrentLocale(): void {
    try {
      this.locale = this.cookieService.get(StorageEnum.locale);
    } catch (error) {
      this.locale = 'en';
    }
  }

  private initChartOptions(): void {
    this.charOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          yAlign: 'bottom',
          callbacks: {
            title: this.computeTooltipTitle,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: COLOR.BLACK,
            callback: this.computeAxisLabels,
          },
          grid: {
            display: true,
            color: COLOR.PALE_GREEN,
          },
        },
        y: {
          ticks: {
            color: COLOR.BLACK,
            precision: 0,
          },
          suggestedMax: 5,
          beginAtZero: true,
          grid: {
            display: true,
            borderDash: [5],
            color: COLOR.PALE_GREEN,
          },
        },
      },
    };
  }

  private initDatasetConfiguration(): void {
    this.baseDatasetConfig = {
      backgroundColor: 'rgba(255, 0, 0, 0.3)',
      hoverBackgroundColor: 'rgba(255, 0, 0, 1)',
      maxBarThickness: 30,
    };

    const tension = !isNaN(this.tension) ? Number(this.tension) : 0;

    this.lineDatasetConfig = {
      borderColor: COLOR.PRIMARY,
      fill: false,
      pointRadius: 4,
      borderWidth: 2,
      pointBorderWidth: 1,
      pointHoverBorderWidth: 4,
      backgroundColor: COLOR.WHITE,
      pointHoverBorderColor: COLOR.PRIMARY,
      pointBackgroundColor: COLOR.PRIMARY,
      tension,
    };

    if (this.chart_type === 'line') {
      this.baseDatasetConfig = {
        ...this.lineDatasetConfig,
      };
    }
  }

  private initFilters(): void {
    this.timeScaleIndex = this.timeScaleOptions.findIndex(
      (o) => o.codeId === this.time_scale
    );

    if (!this.from || !this.to) {
      this.setDateRangeByTimeScale();
    } else {
      this.from = moment(this.from).toDate();
      this.to = moment(this.to).toDate();
    }
  }

  private setDateRangeByTimeScale(): void {
    switch (this.timeScaleIndex) {
      case 0:
        this.setDateRange(7);
        break;

      case 1:
        this.setDateRange(30);
        break;

      case 2:
        this.setDateRange(4, 'months');
        break;
    }
  }

  private setDateRange(amount: number, unit?: Base): void {
    const [from, to] = TimeUtils.getLastDateRange(amount, unit);

    this.from = from;
    this.to = to;
  }

  private getData(): void {
    const query =
      this.timeScaleIndex === 0 || this.timeScaleIndex === 1
        ? QUERY_BY_DATE
        : QUERY_BY_MONTH;

    this.getReportingData(query);
  }

  private getReportingData(query: string): void {
    const variables: ReportingVariables = {
      type: this.entity_type,
      from: this.from,
      to: this.to,
      id: Number(this.entity_id),
    };

    const body = {
      query,
      variables,
    };

    this.getReportings(body);
  }

  private getReportings(body: unknown): void {
    this.sessionService.apiReady$
      .pipe(
        filter(Boolean),
        switchMap(() => this.http.post(this.API_PATH, body)),
        pluck('data'),
        untilDestroyed(this)
      )
      .subscribe(this.mapReportingsData);
  }

  private mapReportingsData = (data: {
    reportingByDate: Reporting[];
    reportingByMonth: Reporting[];
  }): void => {
    if (data?.reportingByDate) {
      this.mapReportingByDateToChartData(data.reportingByDate);
    }

    if (data?.reportingByMonth) {
      this.mapReportingByMonthToChartData(data.reportingByMonth);
    }
  };

  private mapReportingByDateToChartData(data: Reporting[]): void {
    const dateRanges = this.getDaysOfRange();
    const values = dateRanges.map((date) => {
      const value =
        data.find((d) => date.isSame(moment(d.date), 'day'))?.numberOfRecords ??
        0;
      return {
        label: date.toISOString(),
        value,
      };
    });

    this.populateChartData(values);
  }

  private mapReportingByMonthToChartData(data: Reporting[]): void {
    const months = TimeUtils.monthsBetweenDates(
      this.from,
      this.to,
      DF.MMMM_YYYY
    );
    const values = months.map((month) => ({
      label: month,
      value:
        data.find((d) => moment(month, DF.MMMM_YYYY).month() + 1 === d.month)
          ?.numberOfRecords ?? 0,
    }));

    this.populateChartData(values);
  }

  private getDaysOfRange(): moment.Moment[] {
    const date = moment(this.from).clone();
    const days: moment.Moment[] = [];

    while (moment(this.to).isSameOrAfter(date)) {
      const newDate = date.clone();
      days.push(newDate);
      date.add(1, 'days');
    }
    return days;
  }

  private populateChartData(values: { label: string; value: number }[]): void {
    this.chartData = {
      labels: values.map((d) => d.label),
      datasets: [
        {
          data: values.map((d) => d.value),
          ...this.baseDatasetConfig,
          label: this.translateService.instant(this.entity_type),
        },
      ],
    };
  }

  private computeTooltipTitle = (context: any) => {
    const label = context[0].label || '';

    if (this.timeScaleIndex === 0 || this.timeScaleIndex === 1) {
      return moment(label)
        .locale(this.locale)
        .format(`${DF.dddd}, ${DF.DD_MMM_YYYY}`);
    }

    return moment(label, DF.MMMM_YYYY).locale(this.locale).format(DF.MMMM_YYYY);
  };

  private computeAxisLabels = (_, index): string => {
    const value = this.chartData.labels[index];

    if (this.timeScaleIndex === 0 || this.timeScaleIndex === 1) {
      return moment(value).locale(this.locale).format(DF.ddd);
    }

    return moment(value, DF.MMMM_YYYY).locale(this.locale).format(DF.MMM_YYYY);
  };

  exportData(reportName: string): void {
    const variables = {
      type: this.entity_type,
      from: this.from,
      to: this.to,
      id: Number(this.entity_id),
    };
    const query =
      this.timeScaleIndex === 0 || this.timeScaleIndex === 1
        ? QUERY_BY_DATE
        : QUERY_BY_MONTH;

    const body = { query, variables };

    this.isExporting.emit(true);
    this.isExportLoading = true;
    this.sessionService.apiReady$
      .pipe(
        filter(Boolean),
        switchMap(() =>
          this.http.post(`${this.API_PATH}/export`, body, {
            responseType: 'text',
          })
        ),
        untilDestroyed(this)
      )
      .subscribe((data: string) => {
        this.isExporting.emit(false);
        this.isExportLoading = false;
        this.handleExportCSV(data, reportName);
      });
  }

  private handleExportCSV(base64: string, reportName: string) {
    const decodedData = atob(base64);
    const blob = new Blob([decodedData], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const fileName = this.getFileName(reportName);

    link.href = url;

    link.download = `${fileName}.csv`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  private getFileName(reportName: string): string {
    const timeStamp = this.timeScaleOptions[this.timeScaleIndex].codeId;

    return `${reportName}-${this.entity_id}-${timeStamp}`;
  }
}
