<ng-container *ngIf="comments?.length > 0; else noComment">
  <ng-container
    *ngFor="let comment of comments; index as i; trackBy: commentTrackBy"
  >
    <app-comment
      [comment]="comment"
      [isLoadingLikingsCount]="isLoadingLikingsCount"
      (triggerToggleLike)="toggleLike(comment)"
      (triggerShowLikingsData)="openLikeModal(comment)"
      (triggerDeleteComment)="triggerDeleteComment.emit($event)"
      (triggerEditComment)="triggerEditComment.emit($event)"
    ></app-comment>
  </ng-container>
</ng-container>

<ng-template #noComment>
  <span class="message-empty">{{ 'UI.Comment.Empty' | translate }}</span>
</ng-template>
