import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { I18nService } from '@core/i18n/i18n.service';
import { LanguageInterface } from '@shared/interfaces/language.interface';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent implements OnInit {
  @Output() languageSelected = new EventEmitter();

  @Output() languageRemoved = new EventEmitter<string>();

  currentLang = this.i18n.language;

  selectedLanguages = [this.currentLang];

  remainingLanguage = [];

  constructor(readonly i18n: I18nService) {}

  ngOnInit(): void {
    this.i18n.supportedLanguages$.subscribe((items) => {
      this.remainingLanguage = items.filter(
        (item) => !this.selectedLanguages.find(({ code }) => code === item.code)
      );
    });
  }

  addLanguage(language: LanguageInterface): void {
    this.selectedLanguages.push(language);
    this.ngOnInit();
    this.selectLanguage(language);
  }

  selectLanguage(item: LanguageInterface): void {
    this.currentLang = item;
    this.languageSelected.emit(item);
  }

  removeItem(language: LanguageInterface): void {
    this.selectedLanguages = this.selectedLanguages.filter(
      ({ code }) => code !== language.code
    );

    this.remainingLanguage.push(language);

    if (this.currentLang.code === language.code) {
      this.currentLang = this.i18n.language;
    }

    this.languageRemoved.emit(language.locale);
  }
}
