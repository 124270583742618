import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appSmoothHeight]',
})
export class SmoothHeightDirective implements OnChanges {
  @Input() appSmoothHeight: boolean;
  pulse: boolean;
  startHeight: number;

  constructor(private element: ElementRef) {}

  @HostBinding('@grow')
  get grow(): { params: { startHeight: number }; value: boolean } {
    return { value: this.pulse, params: { startHeight: this.startHeight } };
  }

  setStartHeight(): void {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setStartHeight();
    this.pulse = !this.pulse;
  }
}
