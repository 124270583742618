<button
  onmouseup="this.blur();"
  class="btn btn-primary form-section__btn m-0 w-100"
  type="button"
  appRequireLogin
  appDebounceClick
  (debounceClick)="openSendMessageToMember(entityId)"
>
  <i class="fas fa-paper-plane btn-icon-right"></i>
  <span>{{ 'UI.VentureEdit.DirectMessageButton' | translate }}</span>
</button>
