<div class="card-body" *ngIf="item">
  <a
    appUnsavedFormCheck
    class="link-unstyled"
    [href]="getVentureDetailUrl(item.id)"
    *ngIf="item.attributeValues | attributeValue : 'UiTemplate' as template"
  >
    <div class="row">
      <div class="col-12 col-md-4 col-lg-3 p-0 pr-md-3">
        <div class="card-item__image-wrapper">
          <img
            class="item__image"
            [src]="item.image?.url"
            alt="Event image cap"
            appDefaultPlaceholder
            appDynamicImgUrl
          />
        </div>
      </div>
      <div class="item__info col">
        <div class="row mt-2 mt-md-1 mb-1">
          <div class="col d-flex justify-content-between mb-0">
            <app-privacy-flag [isPrivate]="item.isPrivate"></app-privacy-flag>

            <div
              *ngIf="!allowUpdateSubmissionStatus"
              ngbDropdown
              container="body"
              placement="left-top"
              class="dropdown-no-arrow"
            >
              <a class="p-2" ngbDropdownToggle appClickPreventDefault>
                <i class="fa fa-ellipsis-v align-middle"></i>
              </a>
              <ul ngbDropdownMenu>
                <li
                  *ngIf="isSendMessToMemberShown(item)"
                  ngbDropdownItem
                  class="venture__actions-item p-2"
                >
                  <app-btn-direct-message
                    [entityId]="item.id"
                  ></app-btn-direct-message>
                </li>

                <div class="dropdown-divider m-0"></div>

                <li ngbDropdownItem class="venture__actions-item p-0">
                  <a
                    class="link-unstyled w-100 p-2"
                    [href]="getVentureDetailUrl(item.id)"
                  >
                    <i class="fas fa-eye"></i>
                    <span class="venture__actions-label font-weight-bold">
                      Detail
                    </span>
                  </a>
                </li>
                <li
                  *ngIf="showSubmitMenu"
                  class="ventwure__actions-item p-0"
                  ngbDropdownItem
                >
                  <a class="link-unstyled w-100 p-2 pointer-cursor">
                    <app-submit-ventures
                      appRequireLogin
                      [ventureId]="item.id"
                      [venture]="item"
                      [modalHeader]="'UI.Common.SubmitToChallenge'"
                    ></app-submit-ventures>
                  </a>
                </li>

                <ng-container *ngIf="isVentureStakeholder(item)">
                  <li ngbDropdownItem class="venture__actions-item p-0">
                    <a
                      class="link-unstyled w-100 p-2"
                      appLeaveEntity
                      [entityId]="item.id"
                      [entityName]="EntityName.Venture"
                      (afterLeaved)="afterLeaved.emit($event)"
                    >
                      <i class="fas fa-user-times"></i>
                      <span
                        class="venture__actions-label font-weight-bold ml-2"
                        [innerHTML]="
                          'UI.Common.LeaveThis'
                            | translate
                            | stringReplacement
                              : {
                                  '{entity}': (EntityName.Venture | translate)
                                }
                        "
                      ></span>
                    </a>
                  </li>
                </ng-container>

                <ng-container *ngIf="canDeleteVenture(item)">
                  <li ngbDropdownItem class="venture__actions-item p-0">
                    <a
                      class="link-unstyled w-100 p-2"
                      (click)="deleteVenture(item.id)"
                    >
                      <i class="fas fa-trash"></i>
                      <span
                        class="venture__actions-label font-weight-bold ml-2"
                        >{{ 'UI.Venture.Delete' | translate }}</span
                      >
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <h6 class="card-title col-12">
            <div class="d-flex justify-content-between">
              <a
                class="flex-grow-1"
                appUnsavedFormCheck
                [href]="getVentureDetailUrl(item.id)"
                >{{ item.title }}</a
              >
            </div>
          </h6>

          <ng-container
            *ngIf="
              template?.describedValue?.value
                | visibility : 'InnovationProgress'
            "
          >
            <div class="col-12 col-lg-7">
              <div class="venture__label widget__label">
                {{ 'UI.VenturePhase.InnovationProgress' | translate }}
              </div>

              <app-form-field-custom
                [editable]="false"
                [currentValue]="item.phase?.value"
                [type]="AttributeType.VenturePhase"
                [attributeDescription]="getFieldOptions('Phase')"
              ></app-form-field-custom>
            </div>
          </ng-container>

          <div
            class="col-12 col-lg-5"
            [class.ml-lg-auto]="
              !(
                template?.describedValue?.value
                | visibility : 'InnovationProgress'
              )
            "
          >
            <app-venture-people
              [initiator]="item.initiator"
              [stakeholders]="item.stakeholders"
              [styleClass]="
                (template?.describedValue?.value
                | visibility : 'InnovationProgress')
                  ? 'ml-xl-0'
                  : ''
              "
            ></app-venture-people>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            template?.describedValue?.value | visibility : 'ConstitutionState'
          "
        >
          <div class="col-12">
            <div
              class="venture__label widget__label--no-truncate d-flex align-items-center"
            >
              {{
                getFieldOptions('Status')?.displayName ||
                  ('Constitution State' | translate)
              }}: &nbsp;

              <ng-container *ngIf="item?.canViewNdaContent; else hiddenTempRef">
                <span class="venture-state-content">{{
                  item.status?.value
                }}</span>
              </ng-container>
              <ng-template #hiddenTempRef>
                <span class="venture-state-content"
                  ><i class="fas fa-eye-slash"></i>
                  {{ 'Hidden-content-message' | translate }}
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
