import { PagingParams } from '@shared/models/pagination.model';
import { environment } from '@src/environments/environment';
import { AttributeDescription, AttributeValue, CustomAtrributeValue } from './attribute-description.interface';
import { PeopleInterface } from './people.interface';

export interface TimestampCreatePayload {
  timestampType: string;
  description: string;
}

export interface TimestampPaginationPayload extends PagingParams {
  entityName: string;
  entityId: number;
  timestampType?: string;
}

export interface TimestampModel {
  timestampId: string;
  timestampType: string;
  timestampTypeCodeId?: string;
  createdDate: Date;
  creator: PeopleInterface;
  description: string;
  attributeDescriptions: AttributeDescription[];
  attributeValues: AttributeValue[];
  timestampTypeSingleSelection: CustomAtrributeValue;
}

export class TimestampIcon {
  icon: string;

  constructor(
    timestampType: string
  ) {
    this.icon = `${environment.wordpressJipImages}icons/ts-${timestampType}.svg`;
  }
}

export interface TimestampResponse<T> {
  result: T;
  timestampInfo: TimestampModel;
}
