import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BaseDetailComponent } from '@src/app/components/base/base-detail/base-detail.component';
import { TemplateName } from '@shared/constants/visibility-config.const';

@Component({
  selector: 'app-venture',
  templateUrl: './venture.component.html',
})
export class VentureComponent
  extends BaseDetailComponent
  implements OnInit, OnChanges
{
  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  // tslint:disable-next-line:variable-name
  @Input() ui_template: TemplateName = TemplateName.Default;
}
