import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SessionService } from '@core/session.service';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { MessageUtils } from '@shared/utils/message-utils';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import {
  EntityName,
  MessageAction,
  MessageType,
} from '@src/app/shared/enums/entity-name.enum';
import { YouTransformPipe } from '@src/app/shared/pipes/you-transform.pipe';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-group-message-card',
  templateUrl: './group-message-card.component.html',
})
export class GroupMessageCardComponent implements OnChanges, AfterContentInit {
  @Input() message: NewsMessageInterface;
  @Input() uiTemplate: TemplateName;
  @Input() currentPersonId?: number;

  @Output() contentLoaded = new EventEmitter();

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;

  peopleURL = environment.jipUrl.people;

  MessageType = MessageType;
  MessageAction = MessageAction;

  sender: PeopleInterface | UserInterface;

  constructor(
    private readonly youTransformPipe: YouTransformPipe,
    private readonly sessionService: SessionService
  ) {}

  get entityName(): string {
    switch (this.message.messageType) {
      case MessageType.VentureMessage:
        return EntityName.Venture;

      case MessageType.OrganizationMessage:
        return EntityName.Organization;

      case MessageType.ChallengeMessage:
        return EntityName.Challenge;

      case MessageType.EventMessage:
        return EntityName.Event;

      case MessageType.PersonMessage:
        return EntityName.Person;
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message?.currentValue) {
      const shouldGetLastPerson =
        this.message.action !== MessageAction.InviteJuror;

      this.sender = shouldGetLastPerson
        ? MessageUtils.getLastReplier(this.message)
        : this.sessionService.currentUser;
    }
  }

  ngAfterContentInit(): void {
    this.contentLoaded.emit();
  }

  getSender(): string {
    return this.youTransformPipe.transform(this.sender, this.currentPersonId);
  }
}
