import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  AttributeValue,
  CustomAtrributeValue,
} from '@shared/interfaces/attribute-description.interface';
import { ArrayUtils } from '@shared/utils/array-utils';
import { SessionService } from '@src/app/core/session.service';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { StringUtils } from './string-utils';

export const FORM_ERROR_KEY = 'formError';

export class FormUtils {
  public static maskFormAsTouched(
    formGroup: UntypedFormGroup,
    onlySelf = false
  ): void {
    if (!formGroup || !formGroup.controls) {
      return;
    }
    for (const key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        const control: UntypedFormControl = formGroup.controls[
          key
        ] as UntypedFormControl;

        if (Object.keys(control).includes('controls')) {
          const formGroupChild: UntypedFormGroup = formGroup.controls[
            key
          ] as UntypedFormGroup;
          FormUtils.maskFormAsTouched(formGroupChild, onlySelf);
        }

        control.markAsTouched({ onlySelf });
      }
    }
  }

  public static markControlAsTouch(
    form: UntypedFormGroup,
    controlName: string
  ): void {
    const control = form.controls[controlName];
    if (control) {
      control.markAllAsTouched();
      control.markAsDirty();
    }
  }

  public static removeError(control: AbstractControl, errorKey: string): void {
    if (!control || !control.errors) {
      return;
    }

    delete control.errors[errorKey];
    if (Object.keys(control.errors || {}).length === 0) {
      control.setErrors(null);
    }
  }

  public static isEqual(value1: any, value2: any, primaryKey = null): boolean {
    if (!value1 && !value2) {
      return true;
    }
    if (value1 === value2) {
      return true;
    }

    if (value1 == null || value2 == null) {
      return false;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
      return ArrayUtils.isEqual(value1, value2, primaryKey);
    }

    if (typeof value1 === 'object' && typeof value2 === 'object') {
      const isEqualUsingPrimaryKey =
        primaryKey && value1[primaryKey] && value2[primaryKey]
          ? value1[primaryKey] === value2[primaryKey]
          : false;
      return (
        isEqualUsingPrimaryKey ||
        JSON.stringify({ ...value1 }) === JSON.stringify({ ...value2 })
      );
    }

    return false;
  }

  public static isEqualSingleSelection(
    value1: string | CustomAtrributeValue,
    value2: string | CustomAtrributeValue,
    key = 'codeId'
  ): boolean {
    const compareValue1 = (value1 as CustomAtrributeValue)[key] || value1;
    const compareValue2 = (value2 as CustomAtrributeValue)[key] || value2;
    return FormUtils.isEqual(compareValue1, compareValue2);
  }

  public static getFieldOptions(
    attributeDescriptions: AttributeDescription[],
    key
  ): AttributeDescription | null {
    if (!attributeDescriptions || !key) {
      return null;
    }

    for (const item of attributeDescriptions) {
      if (StringUtils.equal(key, item.propertyName)) {
        return item;
      }
    }
    return null;
  }

  public static getFieldValue(
    attributeValues: AttributeValue[],
    key: string
  ): AttributeValue | null {
    if (!attributeValues || !key) {
      return null;
    }

    for (const item of attributeValues) {
      if (StringUtils.equal(key, item.propertyName)) {
        return item;
      }
    }
    return null;
  }

  public static hasContentInAnyControl(
    control: UntypedFormGroup,
    errorKey: string,
    controlNames: string[]
  ): { [key: string]: object } | null {
    if (!control || !controlNames) {
      return null;
    }

    const controls = controlNames.map((name) => {
      return control.controls[name];
    });
    let valid = false;
    for (const item of controls) {
      if (!StringUtils.isEmptyHtmlContent(item?.value)) {
        valid = true;
        break;
      }
    }

    if (valid) {
      for (const item of controls) {
        FormUtils.removeError(item, errorKey);
      }
      return null;
    } else {
      for (const item of controls) {
        if (item) {
          item.setErrors({ [errorKey]: true });
        }
      }
      return { [errorKey]: { value: control?.value } };
    }
  }

  public static getErrorObject(data: any): { [key: string]: string } {
    const errorMessage = {};

    if (data.error) {
      const errors = data.error.errors;

      if (typeof errors === 'object') {
        for (const key of Object.keys(errors)) {
          if (key) {
            const message = errors[key];
            errorMessage[StringUtils.toLowerCaseFirstLetter(key)] = message;
          }
        }
      } else if (typeof errors === 'string') {
        errorMessage[FORM_ERROR_KEY] = '' + errors;
      } else if (data.error.title) {
        errorMessage[FORM_ERROR_KEY] = data.error.title;
      } else if (data.error) {
        const message = navigator.onLine
          ? data.error.toString()
          : 'Network-Disconnected';

        errorMessage[FORM_ERROR_KEY] = message;
      }
    } else if (data.isEmailDelivered === false && data.message) {
      errorMessage[FORM_ERROR_KEY] = data.message;
    }

    return errorMessage;
  }

  public static navigateTo(url: string): void {
    if (url) {
      window.location.href = url; // NOSONAR
    }
  }

  public static navigateToWithLanguagePath(
    sessionService: SessionService,
    url: string
  ): void {
    this.navigateTo(sessionService.appendLanguagePath(url));
  }

  public static openWithLanguagePath(
    sessionService: SessionService,
    url: string,
    target: '_blank' | '_self'
  ): void {
    window.open(sessionService.appendLanguagePath(url), target);
  }

  public static hasTrueValueControl(
    formControls: UntypedFormGroup,
    errorKey: string,
    controlNames: string[]
  ): { [key: string]: boolean } | null {
    if (!formControls || !controlNames) {
      return null;
    }

    const controls = controlNames.map((name) => {
      return formControls.controls[name];
    });

    let isValid = true;
    for (const item of controls) {
      if (item.value) {
        if (item.hasError(errorKey)) {
          FormUtils.removeError(item, errorKey);
        }
      } else {
        item.setErrors({ [errorKey]: item.value });
        isValid = false;
      }
    }

    return isValid ? null : { [errorKey]: false };
  }

  public static lowercaseProperty(source: object): {} {
    const result = {};

    for (const key of Object.keys(source)) {
      const lowerKey = StringUtils.toLowerCaseFirstLetter(key);
      result[lowerKey] = source[key];
    }

    return result;
  }

  public static customSearchFn(term: string, item: any) {
    return item.value.toLowerCase().startsWith(term.toLowerCase());
  }
}

export function integerOnly(errorKey: string): ValidatorFn {
  return regexValidator(errorKey, /^\d*$/);
}

export function regexValidator(
  errorKey: string,
  validRegex: RegExp
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const valid = validRegex.test(control.value);
    return valid || !value ? null : { [errorKey]: { value: control.value } };
  };
}
