import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';

@Component({
  selector: 'app-events-modal',
  templateUrl: './events-modal.component.html',
  providers: [{ provide: BaseHttpService, useClass: EventHttpService }],
})
export class EventsModalComponent implements OnChanges {
  @Input() modalTitle = '';
  @Input() entityId: number;
  @Input() entityTitle: string;
  @Input() httpService: BaseHttpService<any>;

  @Output() dismissModal = new EventEmitter<any>();

  @ViewChild('modalRef') modalRef: ElementRef;

  constructor(private modalService: NgbModal) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entityId !== null && this.entityId >= 0) {
      this.open();
    }
  }

  open(): void {
    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        scrollable: true,
        windowClass: 'events-modal',
        size: 'xl',
      })
      .result.then(
        (result) => {
          // do nothing
        },
        (reason) => {
          this.dismissModal.emit();
        }
      );
  }
}
