<div *ngIf="allPhases" class="venture-phase">
  <ng-container
    *ngFor="let item of allPhases; first as istFirst; index as index"
  >
    <div
      *ngIf="!istFirst"
      class="venture-phase__bar"
      [class]="{
        active: index <= currentIndex,
        current: index == currentIndex
      }"
      [class.bg-primary]="index <= currentIndex"
    ></div>
    <div
      (click)="select(item, index)"
      class="venture-phase__circle"
      [class.pointer-cursor]="editable"
      [class]="{
        active: index <= currentIndex,
        'bg-primary': index < currentIndex,
        current: index == currentIndex
      }"
      [ngbTooltip]="item?.value"
    >
      <span
        class="phase-text"
        [class]="{
          active: index <= currentIndex,
          'text-primary': index === currentIndex,
          current: index == currentIndex
        }"
        >{{ item.value }}</span
      >
      <i class="fas fa-check"></i>
      <i
        class="fas fa-circle"
        [class]="{ 'text-primary': index === currentIndex }"
      ></i>
    </div>
  </ng-container>
</div>
