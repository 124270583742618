import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import {
  TagFieldComponent,
  TagFieldCustom,
  TagFieldType,
} from '@shared/components/tag-field/tag-field.component';
import { InfiniteScrollDataAdapter } from '@shared/helpers/infinite-scroll-data.adapter';
import { TagInputComponent } from 'ngx-chips';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tag-field-expanded',
  templateUrl: './tag-field-expanded.component.html',
})
export class TagFieldExpandedComponent extends TagFieldComponent {
  @Input() searchData$: InfiniteScrollDataAdapter;

  @Input() limitItemsTo = 8;

  @Input() disabledInput = false;

  @Input() keyMapImg = 'image';

  @Input() isShowLeftColum = true;

  @Input() dropdownClass = '';

  @Input() hideTagInput = false;

  @Output() newTagAdded = new EventEmitter<TagFieldCustom>();

  @ViewChild(NgbDropdown) ngbDropdown: NgbDropdown;

  @ViewChild('tagInput') tagInputViewChild: TagInputComponent;

  TagFieldType = TagFieldType;

  searchData: TagFieldCustom[];

  leftItems: TagFieldCustom[] = [];

  rightItems: TagFieldCustom[] = [];

  isClose$ = new Subject<void>();

  onScroll(event: any): void {
    const OFFSET_BOTTOM = 20;

    if (
      event.target.offsetHeight + event.target.scrollTop + OFFSET_BOTTOM >=
      event.target.scrollHeight
    ) {
      this.searchData$.loadMore();
    }
  }

  searchItems(text: string): void {
    if (!this.ngbDropdown.isOpen()) {
      this.ngbDropdown.open();
    }
    this.searchData$.reload(text);
  }

  addTag(item: TagFieldCustom): void {
    this.newTagAdded.emit(item);
    this.resetInputText(false);
    this.ngbDropdown?.close();
    this.searchData$.reload('');
  }

  removeTag(): void {
    // Reload search input
    this.ngbDropdown?.open();
  }

  emitValue(event: TagFieldCustom[]): void {
    this.itemsChange.emit(event);
  }

  onSelect(item: TagFieldCustom): void {
    this.items.push(item);
    this.emitValue(this.items);
    this.addTag(item);
    setTimeout(() => this.setData(this.searchData));
  }

  onOpenChange(isOpen: boolean): void {
    if (!isOpen) {
      this.isClose$.next();
      this.searchData$.reload();

      return;
    }

    this.searchData$.pipe(takeUntil(this.isClose$)).subscribe((res) => {
      this.searchData = res;
      this.setData(res);
    });
  }

  resetInputText(emitEvent: boolean): void {
    this.tagInputViewChild.setInputValue('', emitEvent);
    this.setData([]);
  }

  trackByFn(_index: number, tag: TagFieldCustom): number {
    return tag.id;
  }

  private setData(searchData: TagFieldCustom[]): void {
    this.leftItems = [...searchData].filter(
      (item) =>
        item?.type === TagFieldType.PreSelection &&
        !this.items.find((x) => x.codeId === item.codeId)
    );

    this.rightItems = [...searchData].filter(
      (item) =>
        item?.type !== TagFieldType.PreSelection &&
        !this.items.find((x) => x.id === item.id)
    );

    if (this.rightItems.length < 20 && this.searchData$.hasMore$.value) {
      this.searchData$.loadMore();
    }
  }
}
