import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attachments-upload',
  templateUrl: './attachments-upload.component.html',
})
export class AttachmentsUploadComponent {
  @Input() acceptType = '.pdf';
  @Input() files: any[] = [];
  @Input() editable = true;

  @Output() getFiles = new EventEmitter<string[]>();

  getFileName(item: any): string {
    if (item) {
      return (
        item.file?.alt ||
        item.alt ||
        (typeof item === 'string' && item.replace(/^.*[\\\/]/, ''))
      );
    }
  }

  setValue(value: any): void {
    if (value) {
      this.files = !this.files ? [] : [...this.files];
      this.files.push(value);
      this.emitOutput();
    }
  }

  remove(event: MouseEvent, index: number): void {
    this.files = [...this.files] || [];
    this.files.splice(index, 1);
    this.emitOutput();
    event.stopPropagation();
    event.preventDefault();
  }

  emitOutput(): void {
    this.getFiles.emit(this.files);
  }
}
