<div class="pending-people">
  <ng-container *ngIf="items?.length > 0; else emptyRef">
    <div
      *ngFor="let item of items; index as i"
      class="pending-people__item d-md-flex align-items-center mx-3 mx-md-4"
    >
      <app-people-info-small [person]="item" [fromPendingPeople]="true"></app-people-info-small>

      <div class="ml-auto mb-3 mb-md-0">
        <button
          (click)="rejectOne(item, i)"
          [disabled]="requesting[i]?.reject || requesting.all?.reject"
          class="jip-btn-small btn btn-outline-primary mr-3"
          translate
        >
          <ng-container *ngIf="requesting[i]?.reject || requesting.all?.reject">
            <i class="fas fa-spinner fa-spin mr-2"></i>
          </ng-container>

          <i class="fas fa-times"></i>
          <span class="ml-1">{{ 'UI.Common.Reject' | translate }}</span>
        </button>

        <button
          (click)="approveOne(item, i)"
          class="jip-btn-small btn btn-primary"
          [disabled]="requesting[i]?.approve || requesting.all?.approve"
        >
          <ng-container
            *ngIf="requesting[i]?.approve || requesting.all?.approve"
          >
            <i class="fas fa-spinner fa-spin mr-2"></i>
          </ng-container>

          <i class="fas fa-check"></i>
          <span class="ml-1">{{ 'UI.Common.Approve' | translate }}</span>
        </button>
      </div>
    </div>

    <div class="mr-4">
      <app-bootstrap-pagination
        [page]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (changePageSize)="onPageSizeChange($event)"
        (changePage)="onPageChange($event)"
      ></app-bootstrap-pagination>
    </div>
  </ng-container>

  <ng-template #emptyRef>
    <div class="people-cards-emtpy" translate>
      UI.Pending-people.Empty-message
    </div>
  </ng-template>
</div>
