import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewMessageDialogComponent } from '@src/app/components/news-messages/components/new-message-dialog/new-message-dialog.component';
import { SessionService } from '@src/app/core/session.service';
import { RecipientType } from '../../enums/message.enum';
import { MessagePayload } from '../../interfaces/news-message.interface';

@Component({
  selector: 'app-btn-direct-message',
  templateUrl: './btn-direct-message.component.html',
  styles: [],
})
export class BtnDirectMessageComponent {
  @Input() entityId: number;

  constructor(
    private sessionService: SessionService,
    private modalService: NgbModal
  ) {}

  openSendMessageToMember(entityId: number): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;
    const modalRef = this.modalService.open(NewMessageDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    const messagePayload: MessagePayload = {
      type: RecipientType.Ventures,
      ids: [entityId]
    };
    modalRef.componentInstance.messagePayload = messagePayload;
    modalRef.componentInstance.description = 'UI.VentureEdit.DirectMessage';
  }
}
