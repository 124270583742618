import { SendMessageHttpService } from '@core/http/send-messages-http.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { FollowerInterface } from '@shared/interfaces/follower.interface';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MessageUtils } from '@shared/utils/message-utils';

@Injectable()
export class SendMessageAdapterService extends SendMessageHttpService {
  personIds: number[];

  paginateFollowers(
    id: number,
    params: HttpParams
  ): Observable<ApiGetResponse<FollowerInterface>> {
    return this.personIds
      ? this.getRecipients(id, params)
      : super.paginateFollowers(id, params);
  }

  getRecipients(
    id: number,
    params: HttpParams
  ): Observable<ApiGetResponse<FollowerInterface>> {
    const url = `${this.apiUrl}/${id}/recipients`;

    const body = {
      pageIndex: params.get('pageIndex'),
      pageSize: params.get('pageSize'),
      personIds: this.personIds,
    };

    return this.http.post<ApiGetResponse<FollowerInterface>>(url, body).pipe(
      map((res: ApiGetResponse<FollowerInterface>) => ({
        ...res,
        items: res.items.map((item) => {
          MessageUtils.transformRecipientName(item.owner);
          return item;
        }),
      }))
    );
  }

  setRecipientList(recipientList: PeopleInterface[]): void {
    this.personIds = recipientList?.map((recipient) => recipient.id);
  }
}
