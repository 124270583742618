import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { AbstractControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SocialMedia } from '@shared/interfaces/people.interface';
import { ArrayUtils } from '@shared/utils/array-utils';

@Component({
  selector: 'app-social-link',
  templateUrl: './social-link.component.html',
})
export class SocialLinkComponent implements OnInit {
  @Input() items: SocialMedia[] = [];
  @Input() regex: string;
  @Input() formControlSocialLink: AbstractControl;

  @Output() selectItem = new EventEmitter<SocialMedia[]>();
  firstAddItem: boolean;

  readonly defaultLink = {
    link: null,
    socialMediaName: null,
    socialMediaLogoId: null
  };
  newLink: SocialMedia = { ...this.defaultLink };

  placeholder = this.translateService.instant('Please Enter a Link');
  addBtnClicked = false;

  listLinkForSet = [];

  constructor(
    private translateService: TranslateService,
    private customFormService: CustomFormService
  ) {}

  ngOnInit(): void {
    this.customFormService.data.subscribe((res) => {
      if (res) {
        if (this.isNewLinkValid) {
          this.patchValueForm(this.newLink);
          this.newLink = { ...this.defaultLink };
        }
      }
    });
  }

  addLink(): void {
    this.addBtnClicked = true;

    if (!this.isNewLinkValid) {
      return;
    }

    if (!this.items) {
      this.items = [];
    }

    this.items.push(this.newLink);

    this.newLink = { ...this.defaultLink };
    this.emitOutput();
    this.firstAddItem = true;

    this.addBtnClicked = false;
  }

  removeLink(item: SocialMedia): void {
    this.items = ArrayUtils.removeItem(this.items, item);
    this.emitOutput();
  }

  emitOutput(listLink?): void {
    const itemsToPatchValue = listLink ? listLink : this.items;
    this.selectItem.emit(itemsToPatchValue);
  }

  get isNewLinkValid(): boolean {
    const { socialMediaName, link } = this.newLink;

    return socialMediaName && link ? true : false;
  }

  addLinkFromEvent(): void {
    this.addLink();
  }

  patchValueForm(link): void {
    this.firstAddItem = true;
    const arrSocialLinks = [...this.items];
    arrSocialLinks.push(link);
    this.emitOutput(arrSocialLinks);
  }
}
