import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NEW_LINE_CODE } from '@components/entity-section/invitation-by-email/invitation-by-email.component';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { ToastService } from '@core/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { TranslateServiceLanguage } from '@shared/interfaces/language.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { StringReplacementPipe } from '@shared/pipes/string-replacement.pipe';
import { FormUtils } from '@shared/utils/form-utils';

@Component({
  selector: 'app-share-via-email',
  templateUrl: './share-via-email.component.html',
  styles: [],
})
export class ShareViaEmailComponent implements OnInit, OnDestroy {
  @Input() entityPath: string;

  @Input() entityName: string;

  @Input() messageBody = '';

  profile: UserInterface;

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private stringReplacementPipe: StringReplacementPipe,
    private sessionService: SessionService,
    private authService: AuthenService
  ) {}

  ngOnInit(): void {
    this.authService
      .getProfile()
      .pipe(untilDestroyed(this))
      .subscribe((userProfile: UserInterface) => (this.profile = userProfile));
  }

  ngOnDestroy(): void {
    /**/
  }

  openEmailClient(): void {
    if (
      !this.messageBody ||
      !this.sessionService.isLogin ||
      this.sessionService.isTokenExpired()
    ) {
      return;
    }

    const userName = [this.profile.firstName, this.profile.lastName]
      .filter(Boolean)
      .join(' ');

    // build Subject
    const subject = this.stringReplacementPipe.transform(
      this.translateService.instant(`UI.Entity-Sharing.Mail-content--Subject`),
      {
        '{entityTitle}': this.entityName,
        '{firstName}': this.profile.firstName,
      }
    );

    // build Dear format
    const dearReservedText = this.translateService.instant(
      'UI.Entity-Sharing.Mail-content--Dear'
    );

    // build Body main text
    const currentLang =
      this.translateService.currentLang?.toLocaleLowerCase() ||
      TranslateServiceLanguage.en.toLocaleLowerCase();
    const bodyMainText = this.stringReplacementPipe.transform(
      this.translateService.instant(this.messageBody),
      {
        '{entityUrl}': `${location.origin}/${currentLang}${this.entityPath}`,
        '{name}': userName,
      }
    );

    // build signature
    const signature = this.stringReplacementPipe.transform(
      this.translateService.instant(
        'UI.Entity-Sharing.Mail-content--Signature-Share-Entity'
      ),
      {
        '{name}': userName,
        '{tenantName}': this.sessionService.tenant.name
      }
    );

    const emailFooter = this.stringReplacementPipe.transform(
      this.translateService.instant(
        'UI.Invitation.Mail-content-tenant-specific--Footer'
      ), 
      {
        '{tenantName}': this.sessionService.tenant.name
      }
    );

    const lines = [
      dearReservedText,
      ' ',
      bodyMainText,
      ' ',
      signature,
      ' ',
      emailFooter,
    ];

    const body = lines.join(NEW_LINE_CODE);
    const ccEmail = this.profile.email;

    this.goTo(`mailto:?cc=${ccEmail}&subject=${subject}&body=${body}`);
  }

  goTo(url: string): void {
    FormUtils.navigateTo(url);
  }
}
