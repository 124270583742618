<div [ngClass]="class">
  <div
    (ngModelChange)="onFilterChange($event)"
    [(ngModel)]="model"
    class="search-filter btn-group btn-group-toggle d-flex flex-wrap"
    name="radioBasic"
    ngbRadioGroup
    role="group"
  >
    <div *ngFor="let entity of data">
      <label class="btn-outline-primary px-2 py-1 btn-search-filter" ngbButtonLabel>
        <input [value]="entity.key" class="d-none" ngbButton type="radio" />
        <span class="font-size-sm font-weight-500" translate>{{
          entity.label
        }}</span>
      </label>
    </div>
  </div>
</div>
