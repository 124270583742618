import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { VentureHttpService } from '@src/app/core/http/venture.http.service';
import { PaginationSettingService } from '@src/app/core/services/pagination-setting.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { SharedVenturesComponent } from '../shared-ventures.component';

@Component({
  selector: 'app-shared-ventures-client',
  templateUrl: '../shared-ventures.component.html'
})
export class SharedVenturesClientComponent extends SharedVenturesComponent {
  @Input() ventures: VentureInterface[] = [];

  constructor(
    public readonly ventureHttpService: VentureHttpService,
    protected readonly organizationHttpService: OrganizationHttpService,
    protected readonly paginationSettingservice: PaginationSettingService,
    protected readonly modalService: NgbModal,
    protected customFormService: CustomFormService
  ) {
    super(ventureHttpService, organizationHttpService, paginationSettingservice, modalService, customFormService);
  }

  paginateItems(): void {
    if (this.ventures?.length > 0) {
      this.items = this.ventures.slice(
        (this.sharedVenturesfilter.pageIndex - 1) * this.sharedVenturesfilter.pageSize,
        this.sharedVenturesfilter.pageIndex * this.sharedVenturesfilter.pageSize
      );
      this.collectionSize = this.ventures?.length;
      this.runEntityStateCount(EntityName.Venture, this.items, this.ventureHttpService);
    }
    else {
      this.items = [];
      this.collectionSize = 0;
    }
  }
}
