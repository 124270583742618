<div class="reporting-chart">
  <div class="report-header">
    <label class="report-header__title">{{ title | translate }}</label>
    <div class="d-flex">
      <button
        *ngIf="showExportButton"
        type="button"
        class="btn btn-outline-primary form-section__btn ml-0"
        appRequireLogin
        appDebounceClick
        (debounceClick)="exportData(exportReportName)"
      >
        <ng-container *ngIf="isExportLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <i *ngIf="!isExportLoading" class="fas fa-file-export"></i>
        <span>
          {{ 'UI.Common.Export' | translate }}
        </span>
      </button>
      <app-boostrap-dropdown
        class="report-header__option"
        [items]="timeScaleOptions"
        [currentIndex]="timeScaleIndex"
        (selectItem)="selectTimeScale($event)"
      >
      </app-boostrap-dropdown>
    </div>
  </div>

  <div class="report-body d-block">
    <canvas
      baseChart
      [data]="chartData"
      [options]="charOptions"
      [type]="chart_type"
    >
    </canvas>
  </div>
</div>
