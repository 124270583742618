import { Component, OnInit } from '@angular/core';
import { ApplicationService, Version } from '@core/services/application.service';

@Component({
  selector: 'app-version-info',
  templateUrl: './version-info.component.html',
  styles: [],
})
export class VersionInfoComponent implements OnInit {
  version: string;
  constructor(private readonly applicationService: ApplicationService) {}

  ngOnInit(): void {
    const packageVersion = this.applicationService.getStaticVersion();
    this.applicationService.getServiceVersion().subscribe((version) => {
      Object.assign(packageVersion, version);
      this.displayVersion(packageVersion);
    });
  }

  displayVersion(version: Version): void {
    this.version = this.simplifyVersion(version.version);
  }

  private simplifyVersion(version: string): string {
    // stripping trailing zeros to recognize v1 and v1.0.0 as equal
    const regExStrip = '/(.0+)+$/;';
    const segments = version.replace(regExStrip, '').split('.');

    // simplify to format 1.0.0 or 1.0
    const segmentLength = Math.min(segments.length, 3);

    const finalSegments = segments.slice(0, segmentLength);
    let formatedVersion = finalSegments.join('.');

    // transform 2 => 2.0.0
    if (formatedVersion.length === 1) {
      formatedVersion += '.0.0';
    }

    return formatedVersion;
  }
}
