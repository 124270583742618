import { Component, Input } from '@angular/core';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';

@Component({
  selector: 'app-entity-list-by-token',
  templateUrl: './entity-list-by-token.component.html',
})
export class EntityListByTokenComponent {
  @Input() domain: string;
  @Input() orgId: number;
  @Input() entityName: string;

  EntityName = EntityName;
}
