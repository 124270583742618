import { FileInterface } from '@shared/interfaces/file.interface';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { CustomAtrributeValue } from './attribute-description.interface';
import { BaseEntityInterface } from './base/base-entity.interface';
import { ImageInterface } from './file.interface';
import { UserInterface } from './user.interface';

export interface EventInterface extends BaseEntityInterface {
  instanceId?: number;
  title: string;
  image: ImageInterface;
  imageId: number;
  imageData: FileInterface;
  initiatorId: number;
  registrationLink: string;
  eventDateTime: string;
  description: string;
  isActivated: boolean;
  initiator: UserInterface;

  organizer;
  eventLength: number;
  location: string;
  virtualLocation: string;
  content: string;
  agenda: string;
  eventNumber: number;
  totalPlaces: number;
  availableSeats: number;
  price: number;
  priceUnit: string;
  language?: CustomAtrributeValue | string;
  speakers?;
  state: CustomAtrributeValue;
  leadCompany: LeadCompany;
  favoriteId?: number;
  stakeholders?: UserInterface[];

  showExternalRegistration: boolean;
  partnerOrganizations: LeadCompany[];
  eventPartnerStatus?: CustomAtrributeValue;
  isFullyBooked?: boolean;
  isPassedEvent?: boolean;
  isPrivate: boolean;
  canInvite?: boolean;
  isCancelled?: boolean;
  hasPayment?: boolean;
  payment?: any[];
  apiKey?: string;
}

export enum EventMessageRights {
  Everybody = 'EventMessageRightEverybody',
  AdminAndCommunity = 'EventMessageRightAdminAndCommunity',
  AdminOnly = 'EventMessageRightAdminOnly',
}