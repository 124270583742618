import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { SharedEventsComponent } from '../shared-events.component';

@Component({
  selector: 'app-shared-events-client',
  templateUrl: '../shared-events.component.html',
})
export class SharedEventsClientComponent
  extends SharedEventsComponent
  implements OnChanges
{
  @Input() events: EventInterface[] = [];

  constructor(
    public eventHttpService: EventHttpService,
    public sessionService: SessionService
  ) {
    super(eventHttpService, sessionService);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.events) {
      this.paginateItems();
    }
  }

  paginateItems(): void {
    if (this.events?.length > 0) {
      this.items = this.events.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize
      );
      this.collectionSize = this.events?.length;
      this.runEntityStateCount(
        EntityName.Event,
        this.items,
        this.eventHttpService
      );
    } else {
      this.items = [];
      this.collectionSize = 0;
    }
  }
}
