import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntityName, MessageAction } from '@shared/enums/entity-name.enum';
import {
  OwnershipVerb,
  Pronoun,
} from '@shared/enums/translation-variable.enum';
import { PeopleInterface } from '@shared/interfaces/people.interface';
import {
  LinkWrapperPipe,
  SpanWrapperPipe,
} from '@shared/pipes/link-wrapper.pipe';
import { StringReplacementPipe } from '@shared/pipes/string-replacement.pipe';
import { SessionService } from '@src/app/core/session.service';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { YouTransformPipe } from '@src/app/shared/pipes/you-transform.pipe';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-card-action',
  templateUrl: './card-action.component.html',
})
export class CardActionComponent implements OnChanges {
  @Input() id: number;
  @Input() actionType: MessageAction;
  @Input() entityName: EntityName;
  @Input() content?: any;
  @Input() currentPersonId?: number;
  @Input() from?: PeopleInterface;
  @Input() toItems?: PeopleInterface[];

  @Input() ownerNameTemplate: TemplateRef<unknown>;

  peopleURL = environment.jipUrl.people;
  portalName = environment.portalName;
  portalUrl = environment.portalUrl;

  actionText = '';
  suffixText = '';
  entityText = '';
  entityLink = '';
  messageHtml = '';

  haveVerb = '';
  pronoun = '';

  readonly MessageAction = MessageAction;
  readonly EntityName = EntityName;

  constructor(
    private linkWrapperPipe: LinkWrapperPipe,
    private youTransformPipe: YouTransformPipe,
    private translateService: TranslateService,
    private stringReplacementPipe: StringReplacementPipe,
    private spanWrapperPipe: SpanWrapperPipe,
    public sessionService: SessionService,
    private cookieService: CookieService
  ) {
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentPersonId?.currentValue || changes.from?.currentValue) {
      const ownership = this.isOwner() ? OwnershipVerb.Have : OwnershipVerb.Has;
      this.haveVerb = this.translateService.instant(ownership);
    }

    if (changes.entityName) {
      this.handleEntityChange();
      this.pronoun =
        this.entityName === EntityName.Venture ? Pronoun.This : Pronoun.These;
    }

    if (changes.actionType || changes.content) {
      this.handleActionChange();
    }
  }

  isOwner(): boolean {
    return this.currentPersonId === this.from?.id;
  }

  get isCustomTemplate(): boolean {
    return (
      (this.actionType === MessageAction.VentureSubmittedOrganization &&
        this.entityName === EntityName.VentureSubmitOrganisation) ||
      (this.actionType === MessageAction.VentureAwarded &&
        this.entityName === EntityName.VentureAwarded)
    );
  }

  handleActionChange(): void {
    const verbReplacement = {
      '{have}': this.haveVerb,
    };

    switch (this.actionType) {
      case MessageAction.Created:
        this.actionText = 'created this ';
        break;

      case MessageAction.Updated:
        this.actionText = 'updated this ';
        this.suffixText =
          this.entityText === EntityName.Venture ? `(Phase)` : '';
        break;

      case MessageAction.Shared:
        this.actionText = 'UI.MessageLabel.SharedThis.Prefix ';
        this.suffixText = 'UI.MessageLabel.SharedThis.Suffix';
        break;

      case MessageAction.VentureSubmittedOrganization:
        this.updateSubmittedOrgMessage();
        break;

      case MessageAction.UserJoined:
        const messageKey = this.isOwner()
          ? 'has-joined-owner'
          : 'has-joined-other';
        const replacementOptions = {
          '{portalName}': this.wrapLink(
            'https://' + this.sessionService.tenant.domain.url,
            this.portalName
          ),
        };
        this.actionText = this.updateMessageHtml(
          messageKey,
          replacementOptions
        );
        this.entityText = '';
        break;

      case MessageAction.VentureAwarded:
        this.updateVentureAwardedMessage();
        break;

      case MessageAction.Post:
        this.actionText = this.updateMessageHtml(
          'UI.MessageLabel.ShareAPost',
          verbReplacement
        );
        break;

      case MessageAction.InviteJuror:
        this.actionText = 'UI.Challenge.BeingInvitedAsAJurorShort';
        this.suffixText = 'eingeladen';
        break;

      case MessageAction.AcceptJuror:
        this.actionText = this.updateMessageHtml(
          'UI.Challenge.AcceptedAsAJuror',
          verbReplacement
        );
        this.suffixText = 'angenommen';
        this.entityText = this.content?.orgName;
        break;

      case MessageAction.DenyJuror:
        this.actionText = this.updateMessageHtml(
          'UI.Challenge.RejectedAsAJuror',
          verbReplacement
        );
        this.suffixText = 'nicht angenommen';
        this.entityText = this.content?.orgName;
        break;

      case MessageAction.WelcomeJuror:
        this.actionText = this.getWelcomeJurorText();
        this.suffixText = this.isOwner() ? 'hinzugefügt' : '';
        this.entityText = this.content?.orgName;
        break;

      default:
        break;
    }
  }

  updateMessageHtml(messageKey: string, replacementOptions: any): string {
    return this.stringReplacementPipe.transform(
      this.translateService.instant(messageKey),
      replacementOptions
    );
  }

  wrapLink(
    url: string,
    displayText: string,
    className = 'card-action__link'
  ): string {
    const link = this.linkWrapperPipe.transform(url, displayText);
    if (className) {
      return this.spanWrapperPipe.transform(link, className);
    } else {
      return link;
    }
  }

  handleEntityChange(): void {
    switch (this.entityName) {
      case EntityName.Event:
        this.entityText = EntityName.Event;
        this.entityLink = `${environment.jipUrl.event}/${this.id}`;
        break;

      case EntityName.Venture:
        this.entityText = EntityName.Venture;
        this.entityLink = `${environment.jipUrl.venture}/${this.id}`;
        break;

      case EntityName.Person:
        this.entityText = EntityName.Person;
        this.entityLink = `${environment.jipUrl.people}/${this.id}`;
        break;

      case EntityName.Organization:
      case EntityName.Challenge:
        if (this.content?.showAsChallenge) {
          this.entityText = EntityName.Challenge;
          this.entityLink = `${environment.jipUrl.challenges}/${this.id}`;
        } else {
          this.entityText = EntityName.Organization;
          this.entityLink = `${environment.jipUrl.organizations}/${this.id}`;
        }
        break;
    }
  }

  wrapSenderLink(): string {
    const senderUrl = this.sessionService.appendLanguagePath(
      this.peopleURL + '/' + this.from?.id
    );
    const senderDisplayName = this.youTransformPipe.transform(
      this.from,
      this.currentPersonId
    );

    return this.wrapLink(
      senderUrl,
      senderDisplayName,
      'news-messages__owner-name'
    );
  }

  updateSubmittedOrgMessage(): void {
    const ventureUrl = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.venture}/${this.id}`
    );
    const ventureTranslatedText = this.translateService.instant(
      EntityName.Venture
    );

    let orgUrl = '';
    let orgTranslatedText = '';

    if (
      this.content?.showAsChallenge ||
      this.content?.organization?.showAsChallenge
    ) {
      orgUrl = this.sessionService.appendLanguagePath(
        `${environment.jipUrl.challenges}/${this.content?.organization?.id}`
      );
      orgTranslatedText = this.translateService.instant(EntityName.Challenge);
    } else {
      orgUrl = this.sessionService.appendLanguagePath(
        `${environment.jipUrl.organizations}/${this.content?.organization?.id}`
      );
      orgTranslatedText = this.translateService.instant(
        EntityName.Organization
      );
    }

    const replacementOptions = {
      '{submitter}': this.wrapSenderLink(),
      '{have}': this.haveVerb,
      '{venture}': this.wrapLink(ventureUrl, ventureTranslatedText),
      '{organization}': this.wrapLink(orgUrl, orgTranslatedText),
    };

    this.messageHtml = this.updateMessageHtml(
      'UI.News.Submit-venture-to-organization',
      replacementOptions
    );
  }

  updateVentureAwardedMessage(): void {
    const ventureUrl = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.venture}/${this.id}`
    );
    const ventureTranslatedText = this.translateService.instant(
      EntityName.Venture
    );

    const challengeUrl = this.getChallengeOrgUrl();

    const challengeTranslatedText = this.content?.organization?.orgName;

    const replacementOptions = {
      '{venture}': this.wrapLink(ventureUrl, ventureTranslatedText),
      '{challenge}': this.wrapLink(challengeUrl, challengeTranslatedText),
    };

    const messageKey: string = this.sessionService.isLogin
      ? 'UI.News.VentureAwarded'
      : 'UI.News.VentureAwarded.Anonymous';

    this.messageHtml = this.updateMessageHtml(messageKey, replacementOptions);
  }

  getWelcomeJurorText(): string {
    const translateKey: string = this.isOwner()
      ? 'UI.Challenge.WelcomedJuror'
      : 'UI.Challenge.WelcomesJuror';

    return this.updateMessageHtml(translateKey, {
      '{members}': this.buildHrefString(this.toItems[0]),
    });
  }

  private buildHrefString(recipient: PeopleInterface): string {
    const peopleUrl = this.sessionService.appendLanguagePath(
      `${this.peopleURL}/${recipient.id}`
    );
    const displayName = [recipient.firstName, recipient.lastName].join(' ');

    return this.linkWrapperPipe.transform(
      peopleUrl,
      displayName,
      'appUnsavedFormCheck',
      'font-weight-normal text-decoration-underline'
    );
  }

  private getChallengeOrgUrl(): string {
    return this.content?.showAsChallenge ||
      this.content?.organization?.showAsChallenge
      ? this.sessionService.appendLanguagePath(
          `${environment.jipUrl.challenges}/${this.content?.organization?.id}`
        )
      : this.sessionService.appendLanguagePath(
          `${environment.jipUrl.organizations}/${this.content?.organization?.id}`
        );
  }
}
