import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const willHandle = !/(wp-json\/wp\/v2\/media)/i.test(request.url);

    if (willHandle) {
      const headersConfig: Record<string, string> = {};

      const accessToken = this.authService.getAccessToken();
      const locale = request.headers.get('jip-locale');

      if (accessToken) {
        headersConfig.Authorization = 'Bearer ' + accessToken;
      }

      if (locale) {
        headersConfig['jip-locale'] = locale;
      }

      request = request.clone({ setHeaders: headersConfig });
    }

    return next.handle(request);
  }
}
