<app-modal-wrapper
  class="export-summary-dialog"
  [textHeader]="'UI.Timestamp.Create'"
  [isSubmitting]="isSubmitting"
  [disabled]="isLoading || timestampTypes?.length === 0"
  textOk="Create"
  (btnOkClicked)="onSubmit()"
  (btnCancelClicked)="onCancel()"
>
  <div class="row">
    <div class="col-12 mb-3">
      <span>
        On: <b>{{ currentTime | localizedDate: DateFormat.TimestampDate }}</b></span
      >
    </div>
    <div class="form-group mb-3 col-12 col-md-6">
      <label class="widget__label">
        {{ 'UI.TimestampTypes' | translate }}:
      </label>
      <ng-container *ngIf="isLoading; else timestampTypesTemp">
        <i class="fas fa-spinner fa-spin"></i>
      </ng-container>
      <ng-template #timestampTypesTemp>
        <app-boostrap-dropdown
          *ngIf="timestampTypes.length > 0"
          [items]="timestampTypes"
          [(currentIndex)]="currentTimestampType"
        >
        </app-boostrap-dropdown>
      </ng-template>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label class="widget__label"> {{ 'Description' | translate }}: </label>
        <textarea
          class="form-control"
          [rows]="6"
          [(ngModel)]="description"
        ></textarea>
      </div>
    </div>
  </div>
</app-modal-wrapper>
