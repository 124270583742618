import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output, SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LocationInfo } from './../google-map-autocomplete/google-map-autocomplete.component';

@Component({
  selector: 'app-google-map-view',
  templateUrl: './google-map-view.component.html',
})
export class GoogleMapViewComponent implements OnChanges {
  @Input() googleMapUrl = '';
  @Input() placeholder = '';
  @Input() hideLink = false;

  @Output() locationSelect = new EventEmitter();

  mapObject: LocationInfo;
  embedMapSrc: any;
  secureMap: SafeHtml;

  constructor(httpClient: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.googleMapUrl) {
      this.extractSource();
      this.handleUrlChange();
    }
  }

  extractSource(): void {
    const regex = new RegExp(/src\s*=\s*"(.+?)"/);
    const result = regex.exec(this.googleMapUrl);
    this.secureMap = null;
    if (result && result[1]) {
      this.embedMapSrc = result[1];
      if (
        this.embedMapSrc &&
        this.embedMapSrc.startsWith('https://www.google.com/maps/embed')
      ) {
        this.secureMap = this.sanitizer.bypassSecurityTrustHtml(this.googleMapUrl); // NOSONAR
      }
    }
  }

  handleUrlChange(): void {
    try {
      const regex = new RegExp('@' + '(.*)' + 'z');  // NOSONAR /(?<=\@)(.*?)(?=z)/;
      const location = regex.exec(this.googleMapUrl);
      if (location && location[1]) {
        const latLongZoom = location[1].split(',');
        this.mapObject = {
          latitude: parseFloat(latLongZoom[0]),
          longitude: parseFloat(latLongZoom[1]),
          zoom: parseFloat(latLongZoom[2]),
        };
      }
    } catch (err) { }
  }

  setValue(data): void {
    this.locationSelect.emit(data);
  }
}
