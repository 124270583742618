import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';
import { DependantSelectionService } from '@src/app/core/form/dependant-selection.service';
import { FormUtils } from '@src/app/shared/utils/form-utils';

@Component({
  selector: 'app-boostrap-dropdown',
  templateUrl: './boostrap-dropdown.component.html',
  styleUrls: ['./boostrap-dropdown.component.scss'],
})
export class BoostrapDropdownComponent implements OnChanges {
  @Input() currentIndex = 0;
  @Input() editable = true;
  @Input() items: CustomAtrributeValue[] = [];
  @Input() defaultLabel = 'Select Item';
  @Input() replaceAsStarKey = '';
  @Input() container = 'body';
  @Input() styleClass = '';
  @Input() smartTyping = false;
  @Input() frequentlyUsedChoice: CustomAtrributeValue[];
  @Input() masterChoiceId: string;

  @Output() selectItem = new EventEmitter<CustomAtrributeValue>();
  @Output() currentIndexChange = new EventEmitter<number>();

  @ViewChild('selectComp') selectComp: NgSelectComponent;

  model: any;

  unknownSelectionCodeId = '???';

  selectedItem: CustomAtrributeValue;
  customSearchFn = FormUtils.customSearchFn;

  constructor(private dependantSelectionService: DependantSelectionService) {
    this.dependantSelectionService.getMasterChoiceId().subscribe((id) => {
      if (this.masterChoiceId === id) {
        this.selectComp?.open();
        this.selectComp.searchTerm = '';
        this.currentIndex = 0;
        this.selectItem.emit(this.items[0]);
        this.currentIndexChange.emit(this.currentIndex);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.editable) {
      this.handleUnknownSelection();
      this.selectedItem = this.items[this.currentIndex];
    }
  }

  select(item: CustomAtrributeValue, index?: number): void {
    this.currentIndex =
      index ?? this.items.findIndex((i) => i.codeId === item.codeId);
    this.selectItem.emit(item);
    this.currentIndexChange.emit(this.currentIndex);
  }

  handleUnknownSelection(): void {
    const unknownSelectionIndex = this.items.findIndex(
      (item) => item.codeId === this.unknownSelectionCodeId
    );

    if (unknownSelectionIndex === -1) {
      return;
    }

    this.items = this.items.filter(
      (item) => item.codeId !== this.unknownSelectionCodeId
    );

    this.select(this.items[0], 0);
  }

  onSearch(value) {
    const { term } = value;
    if (term) {
      this.items = this.items.map((item) => {
        if (item.group === 'Frequently used choices') {
          item.group = '';
        }
        return item;
      });
    } else {
      this.items = this.items.map((item) => {
        const isFrequentChoice = this.isFrequentlyUsedChoice(item);
        if (isFrequentChoice) {
          item.group = 'Frequently used choices';
        }
        return item;
      });
    }
  }

  isFrequentlyUsedChoice(choice): boolean {
    return !!this.frequentlyUsedChoice.find((i) => i.codeId === choice.codeId);
  }

  onFocus(select: NgSelectComponent) {
    select.searchTerm = '';
    select.placeholder = '';
  };
}

export interface DropdownItemInterface {
  text: string;
  [key: string]: string;
}
