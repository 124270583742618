<ng-container *ngIf="message">
  <app-message-header-wrapper
    [people]="keyPeople"
    [isSystemNews]="message.isSystemNews"
    [messageDate]="message.dateTime"
  >
    <div class="card-action">
      <div
        *ngIf="
          message.content?.entityName ===
            EntityName.VentureSubmittedToChallenge;
          else notVentureSubmittedTitle
        "
        class="card-action jip-upper-first-letter"
      >
        <span [innerHTML]="messageHtml"> </span>
      </div>
      <ng-template #notVentureSubmittedTitle>
        <span class="news-messages__owner-name">
          <ng-container *ngIf="keyPeople; else replaceText">
            <a
              [href]="peopleUrl + '/' + keyPeople?.id"
              appUnsavedFormCheck
              class="underline btn-link-primary"
            >
              <ng-container
                *ngIf="
                  keyPeople.id !== sessionService.currentUser?.id;
                  else youPart
                "
              >
                {{ keyPeople.firstName }}
                {{ keyPeople.lastName }}
              </ng-container>
              <ng-template #youPart>
                {{ 'UI.Common.You' | translate }}
              </ng-template>
            </a>
            <app-bot-flag
              *ngIf="keyPeople.isBot"
              [parentClass]="'ml-1 d-inline-block'"
            ></app-bot-flag>
          </ng-container>
          <ng-template #replaceText>
            <ng-container *ngIf="!message.isSystemNews; else portalPart">
              <span class="deactive-label">
                {{ 'UI.UnsubscribedUser' | translate }}
              </span>
              &nbsp;
            </ng-container>
            <ng-template #portalPart>
              <a [href]="portalUrl" class="underline">{{ portalName }}</a>
            </ng-template>
          </ng-template>
        </span>

        <span [innerHTML]="messageHtml"> </span>
      </ng-template>
    </div>
  </app-message-header-wrapper>
  <div class="news-messages__body" *ngIf="showMessageBody">
    <app-get-in-touch-message-card
      [message]="message"
      [currentPersonId]="sessionService.currentUser?.id"
      [canReply]="canReply"
    >
    </app-get-in-touch-message-card>
  </div>
  <div *ngIf="message.content && entityName">
    <app-entity-card-container
      [entity]="message.content"
      [entityName]="entityName"
      [uiTemplate]="uiTemplate"
    >
    </app-entity-card-container>
  </div>

  <div
    *ngIf="
      message.content &&
      message.content.entityName === EntityName.VentureSubmittedToChallenge
    "
  >
    <app-entity-card-container
      [entityName]="EntityName.VentureSubmittedToChallenge"
      [entity]="message.content"
      [uiTemplate]="uiTemplate"
    >
    </app-entity-card-container>
  </div>
</ng-container>
