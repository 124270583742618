import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageInputComponent } from '@shared/components/atoms/image-input/image-input.component';

@Component({
  selector: 'app-image-uploader-dialog',
  template: '',
})
export class ImageUploaderDialogComponent implements OnDestroy {
  @Input() acceptType;

  @Input() aspectRatio: number;

  @Output() imageCopped = new EventEmitter<File>();

  @Output() loadImageFailed = new EventEmitter<File>();

  constructor(private readonly modalService: NgbModal) {}

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  open(): void {
    const imgUploadComp = this.modalService.open(ImageInputComponent, {
      windowClass: 'modal-image-uploader',
      centered: true,
      backdrop: 'static',
    });

    const { result, componentInstance } = imgUploadComp;

    result.then((file: File) => file && this.imageCopped.emit(file));

    componentInstance.acceptType = this.acceptType;
    componentInstance.aspectRatio = this.aspectRatio;

    componentInstance.loadImgFailed.subscribe(() =>
      this.loadImageFailed.emit()
    );
  }
}
