<section
  class="cover"
  [ngStyle]="{ 'background-image': 'url(' + displayImageUrl + ')' }"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-12 cover__container">
        <h1
          class="cover__title truncate-line-2"
          appTooltip
          triggers="manual"
          #t1="ngbTooltip"
          [toolTipControl]="t1"
          [ngbTooltip]="displayHeadline"
        >
          {{ displayHeadline }}
        </h1>

        <div
          class="cover__tagline truncate-line-2"
          appTooltip
          triggers="manual"
          #t2="ngbTooltip"
          [toolTipControl]="t2"
          [ngbTooltip]="displaySubHeadline"
        >
          {{ displaySubHeadline }}
        </div>
      </div>
    </div>
  </div>
</section>
