import {
  Component, EventEmitter, Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import { ArrayUtils } from '@shared/utils/array-utils';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { LinkItem } from '../../interfaces/attribute-description.interface';

@Component({
  selector: 'app-multi-link',
  templateUrl: './multi-link.component.html',
})
export class MultiLinkComponent implements OnInit {
  @Input() fixedItems: LinkItem[] = [];
  @Input() items: LinkItem[] = [];
  @Input() editable = false;
  @Input() placeholder = 'Enter link';
  @Input() enabledAddNew: boolean;
  @Input() enableImageUploads: boolean;
  @Input() enableNameInput: boolean;
  @Input() regex: string;
  @ViewChild('newLinkInput') newLinkInputTagView;

  @Output() selectItem = new EventEmitter();

  newLink = '';

  constructor(private customFormService: CustomFormService) {}

  ngOnInit(): void {
    this.customFormService.data.subscribe((res) => {
      if (res) {
        if (this.newLink && this.newLinkInputTagView) {
          this.addLink(this.newLinkInputTagView);
        }
      }
    });
  }

  addLink(input): void {
    if (!this.newLink || input?.invalid) {
      return;
    }

    if (!this.items) {
      this.items = [];
    }

    this.items.push({
      url: this.newLink,
    });

    this.newLink = '';
    this.emitOutput();
  }

  removeLink(item: LinkItem): void {
    this.items = ArrayUtils.removeItem(this.items, item);
    this.emitOutput();
  }

  emitOutput(itemList?): void {
    const output = ArrayUtils.concact(this.fixedItems, this.items);
    const itemsToEmit = itemList ? itemList : output;
    this.selectItem.emit(itemsToEmit);
  }
}
