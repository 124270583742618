import {
  Component,
  EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges
} from '@angular/core';
import { environment } from '@env/environment';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { PersonOrganisation } from '@src/app/shared/interfaces/people.interface';

@Component({
  selector: 'app-person-organisations',
  templateUrl: './person-organisations.component.html',
})
export class PersonOrganisationsComponent implements OnChanges {
  orgRoot = environment.jipUrl.organizations;

  @Input() ignoreCompany: LeadCompany = {};
  @Input() editing: boolean;
  @Input() userId: number;
  @Input() organisations: PersonOrganisation[] = [];

  @Output() notifyOrganisationPayload = new EventEmitter<number[]>();

  data: PersonOrganisation[] = [];
  orgsSearchMode: OrgsSearchMode = OrgsSearchMode.PresonAfferableOrgs;

  get ignoreOrgIds(): number[] {
    return this.organisations.map(o => o.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.organisations && this.organisations?.length > 0) {
      this.data = [...this.organisations];
      this.updateOrganisationPayload();
    }
  }

  selectOrganisation(item: PersonOrganisation): void {
    const idx = this.organisations.findIndex((org) => org.id === item.id);

    if (!item || item.id === undefined || idx >= 0) {
      return;
    }

    item.isPending = item.isPrivate && item.initiatorId !== this.userId;
    this.organisations.push(item);
    this.updateOrganisationPayload();
  }

  removeOrganisation(item: PersonOrganisation): void {
    if (!item) {
      return;
    }

    const idx = this.organisations.findIndex((org) => org.id === item.id);
    if (idx < 0) {
      return;
    }

    this.organisations.splice(idx, 1);
    this.updateOrganisationPayload();
  }

  private updateOrganisationPayload(): void {
    const orgIds = this.organisations.map((org) => org.id);
    this.notifyOrganisationPayload.emit(orgIds);
  }
}
