<div
  appRequireSession
  (credentialClick)="
    allowCropImg ? imgCropperRef?.open() : inputFileUpload.click()
  "
  class="jip-upload__container"
  [ngClass]="{ 'alert alert-danger': invalid, uploading: isUploading }"
>
  <input
    #inputFileUpload
    type="file"
    class="upload-file__input"
    (change)="onSelectFile($event)"
    [accept]="acceptType"
    [disabled]="disabled || isUploading"
    [class]="inputClass"
    [id]="inputId"
  />

  <app-image-uploader-dialog
    *ngIf="allowCropImg"
    #imgCropper
    (imageCopped)="onSelectFile({ target: { files: [$event] } })"
    (loadImageFailed)="showCommonError()"
    [acceptType]="acceptType"
    [aspectRatio]="aspectRatio"
  ></app-image-uploader-dialog>

  <i *ngIf="isUploading && displayFileLoading; else content" class="fas fa-spinner rotating"></i>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>
