<div
  *ngIf="item"
  [class.news-messages--no-reply-yet]="isShowAsNoReplyMessageYet"
  class="news-messages__wrapper widget__card--background"
>
  <app-group-message-card
    *ngIf="item.isGroup && !isNotSupportSendGroupView; else singleMessage"
    (contentLoaded)="messageLoaded(item.id)"
    [currentPersonId]="currentUserId"
    [message]="item"
    [uiTemplate]="item.uiTemplate"
  >
  </app-group-message-card>

  <ng-template #singleMessage>
    <ng-container
      *ngIf="
        item.messageType === MessageType.Message &&
        item.content.entityName !== EntityName.VentureSubmittedToChallenge
      "
    >
      <app-message-item [currentPersonId]="currentUserId" [message]="item">
      </app-message-item>
    </ng-container>

    <!-- Venture submitted to Challenge-->
    <ng-container
      *ngIf="
        item.messageType === MessageType.Message &&
        item.content.entityName === EntityName.VentureSubmittedToChallenge
      "
    >
      <app-get-in-touch-message-item
        (contentLoaded)="messageLoaded(item.id)"
        [message]="item"
        [uiTemplate]="item.uiTemplate"
      >
      </app-get-in-touch-message-item>
    </ng-container>

    <!-- Handle Entity Message -->
    <ng-container *ngIf="item.messageType !== MessageType.Message">
      <app-get-in-touch-message-item
        (contentLoaded)="messageLoaded(item.id)"
        [message]="item"
        [uiTemplate]="item.uiTemplate"
      >
      </app-get-in-touch-message-item>
    </ng-container>
  </ng-template>
</div>

<div class="venture__footer">
  <app-message-card-footer
    (clickLikes)="openLikesModal(item)"
    [message]="item"
  ></app-message-card-footer>
</div>
