import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import {
  FormComponentInterface,
  FormErrorService,
} from '@src/app/core/form/form-error.service';
import {
  UnsavedFormCheckService,
  UnsavedFormComponent,
} from '@src/app/core/form/unsaved-form-check.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { MessageValidation } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  AttributeDescription,
  MetaInformation,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { TextValidator } from '@src/app/shared/utils/form-fields-validation';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { ContentChange } from 'ngx-quill';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-send-message-box-base',
  template: '',
})
export class SendMessageBoxBaseComponent
  implements OnInit, OnDestroy, UnsavedFormComponent, FormComponentInterface
{
  @ViewChild('messageForm') messageForm: FormFieldCustomComponent;

  form = new UntypedFormGroup({});
  contentAttributeDescription: AttributeDescription;
  isLoadingAttributeDescriptions$ = new BehaviorSubject(false);
  profileImage: string;
  isLogin = false;

  formErrorKey: string;
  errorMessage: { [key: string]: string };
  isSubmitting = false;

  AttributeType = AttributeType;

  //#region SERVICES
  protected fb: UntypedFormBuilder;
  protected formErrorService: FormErrorService;
  //#endregion End Services

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected authenService: AuthenService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected injector: Injector
  ) {
    this.fb = this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.formErrorService =
      this.injector.get<FormErrorService>(FormErrorService);
  }

  ngOnInit(): void {
    this.unsavedFormCheckService.register(this);
    this.loadProfileData();
    this.loadAttributeDescription();
  }

  get content(): AbstractControl {
    return this.form?.get('Content');
  }

  get isSendMail(): AbstractControl {
    return this.form?.get('isSendMail');
  }

  get imageId(): AbstractControl {
    return this.form?.get('imageId');
  }

  get recipients(): AbstractControl {
    return this.form?.get('selectedRecipients');
  }

  getForm(): UntypedFormGroup {
    return this.form;
  }

  isFormNotSaved(): boolean {
    return !!this.content;
  }

  isValidContent(event: ContentChange): void {
    const { text } = event;
    const maxlength =
      this.contentAttributeDescription?.maximumLength ||
      MessageValidation.MaxLength;
    if (StringUtils.isRTEContentMaxlengthError(text, maxlength)) {
      this.content.setErrors({ maxlength });
    } else {
      if (this.content.getError('maxlength')) {
        delete this.content.errors.maxlength;
      }
    }
  }

  close(): void {
    this.activeModal.close(false);
  }

  protected loadAttributeDescription(): void {
    this.isLoadingAttributeDescriptions$.next(true);
    this.sendMessageHttpService
      .getAttributeDescription()
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingAttributeDescriptions$.next(false);
        })
      )
      .subscribe((res: MetaInformation) => {
        const messageAttributeDescription =
          res.entityDescription.attributeDescriptions;
        this.contentAttributeDescription = FormUtils.getFieldOptions(
          messageAttributeDescription,
          'Content'
        );

        this.handleAfterLoadAttributeDescription(res);
      });
  }

  protected loadProfileData(): void {
    this.authenService
      .getProfile()
      .pipe(untilDestroyed(this))
      .subscribe((userProfile: UserInterface) => {
        this.handleAfterLoadProfileData(userProfile);
      });
    this.authenService
      .isLogin()
      .pipe(untilDestroyed(this))
      .subscribe((islogin: boolean) => {
        this.isLogin = islogin;
      });
  }

  protected handleAfterLoadAttributeDescription(res: MetaInformation): void {
    this.form = this.fb.group({
      Content: [''],
      isSendMail: [false],
      imageId: [],
    });
    if (this.contentAttributeDescription?.required) {
      this.content?.setValidators([
        Validators.required,
        TextValidator.notEmptyOrWhitespace,
      ]);
    }
    this.formErrorService.register(this);
  }

  protected handleAfterLoadProfileData(userProfile: UserInterface): void {
    this.profileImage = userProfile?.image;
  }

  ngOnDestroy(): void {
    this.unsavedFormCheckService.remove(this);
  }
}
