import { EventInterface } from '../interfaces/event.interface';
import { CustomMetadataUtils } from './custom-metadata-utils';
import { TimeUtils } from './time-utils';
import { EventState } from '@shared/enums/event-entity.enum';

export class EventUtils {
  static syncEventBidingVariables(event: EventInterface): EventInterface {
    // @ts-ignore
    const mappingEvent: EventInterface = structuredClone(event);

    mappingEvent.isFullyBooked = EventUtils.isEventFullyBooked(mappingEvent);
    mappingEvent.isPassedEvent = EventUtils.isPassedEvent(mappingEvent);
    mappingEvent.isCancelled = EventUtils.isCancelledEvent(mappingEvent);
    
    return mappingEvent;
  }

  static isEventFullyBooked(event: EventInterface): boolean {
    return (
      event &&
      ((event.totalPlaces !== 0 && event.availableSeats === 0) ||
        event.state?.codeId === EventState.FullyBooked)
    );
  }

  static isPassedEvent(event: EventInterface): boolean {
    const eventStartDate = new Date(event.eventDateTime);
    const eventLength =
      event.eventLength ||
      CustomMetadataUtils.getAttributeValueByPropertyName(
        event.attributeDescriptions,
        event.attributeValues,
        'EventLength'
      ) ||
      0;
    const eventEndDate = TimeUtils.isValidDate(eventStartDate)
      ? TimeUtils.addHoursToDate(eventStartDate, eventLength)
      : null;
    return (
      TimeUtils.isValidDate(eventEndDate) &&
      new Date().getTime() > eventEndDate.getTime()
    );
  }

  static isCancelledEvent(event: EventInterface): boolean {
    return event.state?.codeId === EventState.Cancelled;
  }
}
