import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ApplicationService } from '@core/services/application.service';
import { SessionService } from '@src/app/core/session.service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-google-recapcha',
  templateUrl: './google-recapcha.component.html',
  styles: [],
})
export class GoogleRecapchaComponent implements OnInit {
  siteKey = environment.google_reCAPTCHA_siteKey;

  @Input() formInput: UntypedFormGroup;

  public useGlobalDomain = false;
  public reCAPTCHAlang = 'en';

  constructor(
    private sessionService: SessionService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.reCAPTCHAlang = this.sessionService.getCurrentLanguage()?.code || 'en';
  }

  onSuccess(event: string): void {
    this.applicationService.verifyCaptChaSolution(event).subscribe();
  }
}
