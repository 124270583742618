import { ChangeDetectorRef, Component } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { MetadataTableComponent } from '../metadata-table.component';

@Component({
  selector: 'app-metadata-table-event',
  templateUrl: '../metadata-table.component.html'
})
export class MetadataTableEventComponent extends MetadataTableComponent<EventInterface> {
  entityName = EntityName.Event;
  eventFilterPropertyName: string[] = [
    'Id',
    'Title',
    'EventDateTime',
    'Description',
    'Agenda',
    'LeadCompanyName',
    'EventNumber',
    'Price',
    'PriceUnit',
    'AvailableSeats',
    'EventLength',
    'TotalPlaces',
    'Image',
    'LeadCompanyLogo',
    'State',
    'Language',
    'RegistrationLink',
    'VirtualLocation',
  ];
  readonlyField = ['Id'];

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: EventHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.eventFilterPropertyName;
  }
}
