<form class="d-flex">
  <div class="flex-grow-1">
    <textarea
      #elementRef
      (ngModelChange)="onValueChange($event)"
      [maxlength]="defaultMaxLength"
      [ngModel]="value"
      [placeholder]="placeholder"
      name="text"
      class="form-control w-100"
      rows="1"
    ></textarea>
    <ng-content></ng-content>
  </div>

  <div class="p-1 p-sm-2">
    <app-emojis-picker
      (emojiSelected)="onEmojiPicked($event)"
      position="bottom-left"
    ></app-emojis-picker>
  </div>
</form>
