import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageAction } from '@shared/enums/entity-name.enum';
import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { MessageUtils } from '@shared/utils/message-utils';
import { SessionService } from '@src/app/core/session.service';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import {
  LinkWrapperPipe,
  SpanWrapperPipe,
} from '@src/app/shared/pipes/link-wrapper.pipe';
import { StringReplacementPipe } from '@src/app/shared/pipes/string-replacement.pipe';
import { YouTransformPipe } from '@src/app/shared/pipes/you-transform.pipe';

@Component({
  selector: 'app-message-item',
  templateUrl: './message-item.component.html',
})
export class MessageItemComponent implements OnChanges {
  @Input() message: ChatMessage;
  @Input() currentPersonId?: number;

  messageHtml = '';
  senderUrl = '';

  lastReplier: PeopleInterface;

  welcomeMessagePerson: PeopleInterface;

  constructor(
    private linkWrapperPipe: LinkWrapperPipe,
    private youTransformPipe: YouTransformPipe,
    private translateService: TranslateService,
    private stringReplacementPipe: StringReplacementPipe,
    private spanWrapperPipe: SpanWrapperPipe,
    private sessionService: SessionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.message?.currentValue) {
      return;
    }

    this.message = changes.message.currentValue;
    this.lastReplier = MessageUtils.getLastReplier(this.message);

    this.welcomeMessagePerson =
      this.message.action === MessageAction.WelcomeMessage
        ? this.message.to
        : null;

    this.messageHtml = this.stringReplacementPipe.transform(
      this.getMessageTitle(),
      this.getFormattedTitle()
    );
  }

  getFormattedTitle(): Record<string, string> {
    let url = '';
    let membersHtml = '';
    if (this.message?.to) {
      const receiver = this.getReceiver();

      url = this.sessionService.appendLanguagePath(
        environment.jipUrl.people + '/' + receiver.id
      );

      membersHtml =
        receiver.id === this.currentPersonId
          ? this.translateService.instant('UI.Common.you')
          : this.linkWrapperPipe.transform(
              url,
              this.youTransformPipe.transform(receiver, this.currentPersonId),
              'appUnsavedFormCheck',
              'card-action__link'
            );
    } else {
      const deactivateUser = this.translateService.instant(
        'UI.UnsubscribedUser'
      );
      membersHtml = this.spanWrapperPipe.transform(
        deactivateUser,
        'deactive-label'
      );
    }

    const haveVerb =
      MessageUtils.getLastReplier(this.message)?.id === this.currentPersonId
        ? this.translateService.instant('have')
        : this.translateService.instant('has');

    return {
      '{sender}': this.getSender(),
      '{members}': membersHtml,
      '{membres}': membersHtml,
      '{Mitgliedern}': membersHtml,
      '{have}': haveVerb,
    };
  }

  getSender(): string {
    if (!this.message) {
      return;
    }

    if (this.message.from) {
      this.senderUrl = environment.jipUrl.people + '/' + this.lastReplier.id;

      const text = this.youTransformPipe.transform(
        this.lastReplier,
        this.currentPersonId
      );

      const transformedSenderUrl = this.sessionService.appendLanguagePath(
        this.senderUrl
      );

      return this.spanWrapperPipe.transform(
        this.linkWrapperPipe.transform(transformedSenderUrl, text),
        'group-message-action'
      );
    } else {
      if (!this.message.isSystemNews) {
        const deactivateUser = this.translateService.instant(
          'UI.UnsubscribedUser'
        );
        return this.spanWrapperPipe.transform(deactivateUser, 'deactive-label');
      } else {
        return this.spanWrapperPipe.transform(
          this.linkWrapperPipe.transform(
            environment.portalUrl,
            environment.portalName
          ),
          this.message.replyCount === 0 ? 'news-messages__owner-name' : ''
        );
      }
    }
  }

  isSender(): boolean {
    return this.currentPersonId === this.message?.from?.id;
  }

  getReceiver(): PeopleInterface {
    return this.message.to.id !== MessageUtils.getLastReplier(this.message).id
      ? this.message.to
      : this.message.from;
  }

  isConversationReplied(): boolean {
    return (
      this.message.replyCount &&
      this.message.replies?.some(
        ({ from: replier }) => replier.id !== this.message.from.id
      )
    );
  }

  private getMessageTitle(): string {
    const sender = MessageUtils.getLastReplier(this.message);
    const isConversationReplied = this.isConversationReplied();

    let messageTitle = 'UI.New-Message.Send-message-to';

    if (isConversationReplied && sender.id !== this.currentPersonId) {
      messageTitle = 'UI.New-Message.Replied-to-you';
    } else if (isConversationReplied) {
      messageTitle = 'UI.New-Message.Replied-to';
    }

    return this.translateService.instant(messageTitle);
  }
}
