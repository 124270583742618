<div class="modal-body modal-body-confirmation">
  <div class="d-flex justify-content-center px-2 pt-2">
    <img
      src="/wp-content/themes/jip/assets/images/not-found-entity-icon.png"
      alt="session-expire-icon"
    />
  </div>
  <div class="text-center mt-2">
    <h4 class="font-bold" translate>
      {{ heading }}
    </h4>
    <p class="mb-0" translate>{{ message }}</p>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        type="button"
        class="btn btn-primary ml-4"
        (click)="confirm()"
        translate
      >
        {{ confirmLabel }}
      </button>
    </div>
  </div>
</div>
