<div class="shared-entity">
  <div
    *ngFor="let item of items; trackBy: trackByFn"
    class="item card container"
  >
    <app-shared-venture-item
      [item]="item"
      (ventureDeleted)="refreshVentures()"
      (afterLeaved)="refreshVentures()"
    >
    </app-shared-venture-item>
    <div class="card-footer">
      <ul class="venture__actions list-inline d-flex justify-content-start">
        <!-- Followers -->
        <li
          class="venture__actions-item list-inline-item mr-2 mr-md-5 white-space--no-wrap"
        >
          <ng-container *ngIf="isLoadingFollowingsCount; else followersBtn">
            <div class="text-center">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </ng-container>
          <ng-template #followersBtn>
            <a
              appDebounceClick
              (debounceClick)="showFollower(item)"
              class="d-flex"
            >
              <app-internal-icon
                [className]="'filter-gray'"
                [name]="'user-follow'"
              ></app-internal-icon>
              <span class="venture__actions-label">
                <span class="text-hide-small text-gray-600">{{
                  'Followers' | translate
                }}</span>

                <span [innerHTML]="item.followerCount | parentheses"></span>
              </span>
            </a>
          </ng-template>
        </li>

        <app-comment-entity
          [entity]="item"
          [entityName]="EntityName.Venture"
          [isLoadingCommentsCount]="isLoadingCommentsCount"
          (afterCommentModalClosed)="afterCommentModalClosed()"
        ></app-comment-entity>

        <!--        Submit button-->
        <button
          appDebounceClick
          class="flex-shrink-0 h-fit btn btn-primary form-section__btn ml-auto"
          [disabled]="submitInput.checked"
          (click)="onSubmitClicked(submitInput, item)"
        >
          <input type="checkbox" #submitInput hidden />
          <i class="fas fa-spinner fa-spin m-0" *ngIf="submitInput.checked"></i>
          {{ 'UI.Common.Submit' | translate }}
        </button>
      </ul>
    </div>
  </div>
</div>
