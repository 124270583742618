<div class="d-flex align-items-center">
  <div class="">
    <button
      *ngFor="let item of selectedLanguages"
      class="btn language-chip mr-1 mr-sm-3 btn-outline-primary"
      [class.btn-primary]="item.code === currentLang.code"
      (click)="selectLanguage(item)"
    >
      <span>
        {{ 'UI.Common.Language.' + item.code | translate }}
      </span>

      <i
        (click)="$event.stopPropagation(); removeItem(item)"
        *ngIf="item.code !== i18n.language.code"
        class="fas fa-times ml-1 pointer-cursor"
      ></i>
    </button>
  </div>

  <div
    container="body"
    [placement]="'bottom-right'"
    #myDrop="ngbDropdown"
    ngbDropdown
  >
    <button
      [class.disabled]="remainingLanguage.length === 0"
      class="add-language btn btn-outline-primary flex-shrink-0 p-0 ml-1 ml-sm-2"
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      <i class="fas fa-plus"></i>
    </button>

    <div class="p-3" ngbDropdownMenu>
      <span
        ngbDropdownItem
        (click)="addLanguage(item); myDrop.close()"
        *ngFor="let item of remainingLanguage"
        class="add-language p-0 pointer-cursor"
      >
        {{ 'UI.Common.Language.' + item.code | translate }}
      </span>
    </div>
  </div>
</div>
