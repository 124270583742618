import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { WINDOW } from '@shared/helpers/window.token';
import { GlobalFilterComponent } from '@src/app/components/master-layout/navs/components/global-filter/global-filter.component';
import { SearchChannelsService } from '@src/app/components/master-layout/navs/components/thread-filter/services/search-channels.service';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { ChallengeHttpService } from '@src/app/core/http/challenge-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { NotificationService } from '@src/app/core/notification.service';
import { CommunitySelectorStoredService } from '@src/app/core/services/community-selector-stored.service';
import { FactoryService } from '@src/app/core/services/factory.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { OrganisationSearchComponent } from '@src/app/shared/components/organisation-search/organisation-search.component';
import { HttpStatusCode } from '@src/app/shared/enums/httpstatuscode.enum';
import { OrgsSearchMode } from '@src/app/shared/enums/org-search-mode.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import {
  OrganizationFilter,
  OrganizationInterface,
} from '@src/app/shared/interfaces/organization.interface';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { UserConfigManagementService } from '@src/app/shared/services/user-config-management.service';
import { environment } from '@src/environments/environment';
import { isMobile } from 'detect-touch-device';
import _ from 'lodash';
import { EventBusService } from 'ngx-eventbus';
import { catchError, filter, throwError } from 'rxjs';
@Component({
  selector: 'app-community-selector',
  templateUrl: './community-selector.component.html',
  providers: [NgbActiveModal, NgbDropdown],
})
export class CommunitySelectorComponent
  extends GlobalFilterComponent
  implements OnInit
{
  @Input() isExpanded = true;

  @Output() expanded = new EventEmitter();

  isFavoriteOpen = true;
  isPopularOpen = true;
  keyword = '';
  orgsSearchMode = OrgsSearchMode;
  environment = environment;
  isSmallScreen = false;
  isTouchDevice: boolean = isMobile;

  get ignoreOrgIds(): number[] {
    return this.orgFilterList.map((o) => o.id);
  }

  get selectedOrg(): OrganizationFilter {
    const org = super.selectedOrg;

    if (!org) {
      const popularOrg = this.popularOrgs?.find(
        (x) => x.id === this.organizationId
      );
      if (popularOrg) {
        this.orgUrl = environment.jipUrl.organizations;
        popularOrg.name = popularOrg.orgName;
        popularOrg.imgUrl = popularOrg.logo.url;
        popularOrg.isPopular = true;
      }
      return popularOrg;
    }

    return org;
  }

  @ViewChild('modalRef') modalRef: ElementRef;
  @ViewChild('organizationSearch')
  organizationSearch!: OrganisationSearchComponent;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isSmallScreen = window.innerWidth <= MediaBreakpoint.md;
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event): void {
    this.isSmallScreen = window.innerWidth <= MediaBreakpoint.md;
  }

  constructor(
    @Inject(WINDOW) protected window: Window,
    public sessionService: SessionService,
    protected eventBus: EventBusService,
    protected authService: AuthenService,
    protected factoryService: FactoryService,
    protected userConfig: UserConfigManagementService,
    protected readonly renderer: Renderer2,
    protected readonly injector: Injector,
    protected searchChannel: SearchChannelsService,
    protected orgHttpService: OrganizationHttpService,
    protected communitySelectorStored: CommunitySelectorStoredService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private challengeHttpService: ChallengeHttpService,
    private activeModal: NgbActiveModal,
    private toastService: ToastService
  ) {
    super(
      window,
      sessionService,
      eventBus,
      authService,
      factoryService,
      userConfig,
      renderer,
      injector,
      searchChannel,
      orgHttpService,
      communitySelectorStored
    );
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.listenToSearch();
    this.loadPopularOrg();
    this.listenToCentralConfig();
  }

  listenToCentralConfig() {
    this.centralConfig.innovationSpace$
      .pipe(filter(Boolean))
      .subscribe((id) => {
        const themeWrapper = document.querySelector('.theme-wrapper');
        if (themeWrapper) {
          themeWrapper.classList.add('has-active-innovation-space');
        }
      });
  }

  toggleCollapse() {
    this.isExpanded = !this.isExpanded;
    this.expanded.emit(this.isExpanded);

    if (!this.isExpanded) {
      document.body.classList.add('community-selector-collapse');
    } else {
      document.body.classList.remove('community-selector-collapse');
    }
  }

  toggleFavorite() {
    this.isFavoriteOpen = !this.isFavoriteOpen;
  }

  togglePopular() {
    this.isPopularOpen = !this.isPopularOpen;
  }

  listenToSearch() {
    this.communitySelectorStored.keyword$.subscribe((keyword) => {
      this.searchKeyword = keyword;
    });
    this.communitySelectorStored.search$.subscribe(() => {
      this.search();
    });
  }

  loadPopularOrg() {
    this.sessionService.apiReady$.subscribe((apiReady) => {
      if (apiReady) {
        this.orgHttpService
          .getPopularOrgs()
          .pipe(untilDestroyed(this))
          .subscribe((orgs) => {
            const favoriteOrgIds = this.orgFilterList.map((org) => org.id);
            this.popularOrgs = (
              orgs as (OrganizationInterface & OrganizationFilter)[]
            )
              .filter((org) => !favoriteOrgIds.includes(org.id))
              .map((org) => {
                return { ...org, isPopular: true };
              });

            const popularOrgIds = this.popularOrgs.map((org) => org.id);
            this.orgFilterList.forEach((org) => {
              if (popularOrgIds.includes(org.id)) {
                org.isPopular = true;
              }
            });
          });
      }
    });
  }

  shouldShowAddToFavorite(item): boolean {
    return this.orgFilterList.every((org) => {
      return org.id !== item.id;
    });
  }

  openAddFavoriteDialog() {
    this.modalService.open(this.modalRef, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      container: 'body',
      modalDialogClass: 'add-to-favorite-modal',
    });
  }

  selectItem(event) {
    this.handleAddOrgToFavorite(event.id, true);
  }

  handleAddOrgToFavorite(orgId: number, showToastMessageOnSuccess = false) {
    if (orgId > 0) {
      const popularOrgIds = this.popularOrgs.map((org) => org.id);
      const isPopularOrganization = popularOrgIds.includes(orgId);

      this.storeNewFavoriteOrg(orgId, isPopularOrganization);
      if (showToastMessageOnSuccess) {
        this.toastService.removeAll();
        this.toastService.showSuccess('Added to favorite successfully');
      }

      this.popularOrgs = this.popularOrgs.filter((org) => org.id !== orgId);
    }
  }

  storeNewFavoriteOrg(orgId, isPopularOrg = false) {
    const challengeService = this.injector.get(ChallengeHttpService);
    const orgService = this.injector.get(OrganizationHttpService);

    const handleReadError = (err: any) => {
      if (err.status === HttpStatusCode.NotFound) {
        return challengeService.read(orgId);
      }
      return throwError(() => new Error(err));
    };

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (apiReady) {
          orgService
            .read(orgId)
            .pipe(catchError(handleReadError), untilDestroyed(this))
            .subscribe({
              next: async (org: OrganizationInterface) => {
                if (org) {
                  const orgObj: OrganizationFilter = {
                    id: org.id,
                    name: org.orgName,
                    imgUrl: org.logo?.url || org.logo,
                    isChallenge: org.showAsChallenge,
                    tenantId: +this.sessionService.getTenantId(),
                    isPopular: isPopularOrg,
                  };

                  this.orgFilterList.push(orgObj);
                  await this.setFilterTokenStorage();
                }
              },
              error: (err) => {
                console.log(err);
              },
            });
        }
      });
  }

  async removeItem(orgFilter: OrganizationFilter): Promise<void> {
    await super.removeItem(orgFilter);
    if (orgFilter.isPopular) {
      this.loadPopularOrg();
    }
  }

  onLongPress(item) {
    const removeBox = document.querySelector(`#remove-box-${item.id}`);

    removeBox.classList.add('d-flex');
  }

  onClickItem(id: string): void {
    const removeBox = document.querySelector(
      `#remove-box-${id.split('box-org-')[1]}`
    );

    if (removeBox && removeBox.classList.contains('d-flex')) {
      return;
    }

    super.onClickItem(id);
  }

  removeAllBoxes(event): void {
    event.stopPropagation();
    const removeBoxes = document.querySelectorAll('.remove-box-container');

    [...Array.from(removeBoxes)].forEach((removeBox) => {
      removeBox.classList.remove('d-flex');
      removeBox.classList.add('d-none');
    });
  }

  removeDuplicates(arr: any[]): any[] {
    return _.uniqWith(arr, _.isEqual);
  }
}
