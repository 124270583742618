<ng-container *ngIf="entity && entityName">
  <div [ngSwitch]="entityName">
    <div *ngSwitchCase="EntityName.Event">
      <app-event-card [data]="entity"></app-event-card>
    </div>

    <div *ngSwitchCase="EntityName.Venture">
      <app-venture-card
        [data]="entity"
        [uiTemplate]="uiTemplate"
      ></app-venture-card>
    </div>

    <div *ngSwitchCase="EntityName.Person">
      <app-person-card [data]="entity"></app-person-card>
    </div>

    <div *ngSwitchCase="EntityName.Organization">
      <ng-template [ngTemplateOutlet]="orgCard"></ng-template>
    </div>

    <div *ngSwitchCase="EntityName.Challenge">
      <ng-template [ngTemplateOutlet]="orgCard"></ng-template>
    </div>

    <div *ngSwitchCase="EntityName.VentureSubmittedToChallenge">
      <app-venture-card
        [data]="entity"
        [uiTemplate]="uiTemplate"
      ></app-venture-card>
    </div>
  </div>

  <ng-template #orgCard>
    <app-organization-card
      [isLabelNameShown]="false"
      [data]="entity"
    ></app-organization-card>
  </ng-template>
</ng-container>
