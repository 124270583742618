import { SharedVenturesFilter } from '@shared/interfaces/filters/venture-filter.interface';

export class PaginationSetting {
  keepPageSize: number;
  pageSizeConfig: PageSizeConfig;

  constructor(
    keepPageSize: number,
    pageSizeConfig = PageSizeConfig.FourItemsFirstPage
  ) {
    this.keepPageSize = keepPageSize;
    this.pageSizeConfig = pageSizeConfig;
  }
}

export class PaginationFilterStored {
  keepFilter: PaginationSettingFilter;
  paginationSettingPlace: PaginationSettingPlace;
  pageSizeConfig: PageSizeConfig;
  isGridView: boolean;
  parentEntityId: number;

  constructor(
    keepFilter: PaginationSettingFilter,
    paginationSettingPlace: PaginationSettingPlace,
    pageSizeConfig: PageSizeConfig,
    isGridView: boolean,
    parentEntityId: number = null
  ) {
    this.keepFilter = keepFilter;
    this.paginationSettingPlace = paginationSettingPlace;
    this.pageSizeConfig = pageSizeConfig;
    this.isGridView = isGridView;
    this.parentEntityId = parentEntityId;
  }
}

export class PaginationSizeSetting {
  keepPageSize: number;
  pageSizeConfig: PageSizeConfig;
}

export type PaginationSettingFilter =
  | SharedVenturesFilter
  | Record<string, any>;

export interface PagingParams {
  pageIndex?: number;
  pageSize?: number;
}

export interface SortCriteria {
  sortingDirection?: string;
  propertyName?: string;
}

export enum PageSizeConfig {
  ThreeItemsFirstPage = 3,
  FourItemsFirstPage = 4,
  SixItemsFirstPage = 6,
  EightItemsFirstPage = 8,
  TwoTwentyFirstPage = 20,
}

export enum PaginationSettingPlace {
  VentureListInProfile = 1,
  VentureListInOrganizationDetail,
  VentureListInChallengeDetail,
  VentureMainList,
}

export const PAGE_SIZE_CONFIG_DATA: { [index: number]: number[] } = {
  [PageSizeConfig.FourItemsFirstPage]: [4, 8, 16, 24, 32],
  [PageSizeConfig.SixItemsFirstPage]: [6, 12, 18, 24, 30],
};
