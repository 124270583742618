import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';

@Component({
  selector: 'app-term-of-use',
  templateUrl: './term-of-use.component.html',
})
export class TermOfUseComponent {
  isAccepted: boolean;
  termOfUseUrl = environment.termOfUseUrl;

  constructor(public activeModal: NgbActiveModal) {}
}
