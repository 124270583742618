export class UIPosition {
  top: string;
  left: string;
  right: string;
  bottom: string;

  constructor(top = 'unset', left = 'unset', right = 'unset', bottom = 'unset') {
    this.top = top;
    this.left = left;
    this.right = right;
    this.bottom = bottom;
  }
}

export enum MediaBreakpoint {
  xxl = 1400,
  xl = 1200,
  lg = 992,
  md = 768,
  sm = 576
}
