import {
  Directive,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
} from '@angular/core';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { SessionService } from '@src/app/core/session.service';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';

@Directive({
  selector: '[appRequireSession]',
})
export class RequireLoginToCreateDirective implements OnDestroy {
  @Output() credentialClick = new EventEmitter();
  profile: UserInterface;

  constructor(
    private sessionService: SessionService,
    private authenService: AuthenService,
    private unsavedFormCheckService: UnsavedFormCheckService
  ) {
    this.authenService.profile$
      .pipe(untilDestroyed(this))
      .subscribe((profile: UserInterface) => {
        this.profile = profile;
      });
  }

  @HostListener('mousedown', ['$event'])
  clickEvent(event: Event): void {
    const isTokenExpired = this.sessionService.isTokenExpired();

    if (this.sessionService.isLogin && isTokenExpired) {
      this.handleUnauthorizedUser();
      return;
    } else if (!this.sessionService.isLogin) {
      this.goTo(environment.jipUrl.login);
    }

    this.credentialClick.emit();
  }

  handleUnauthorizedUser(): void {
    this.sessionService.setLoginCallBackpage(
      this.sessionService.appendLanguagePath('/')
    );
    this.sessionService.setCookie(StorageEnum.isTokenExpired, true);
    this.sessionService.setCookie(
      StorageEnum.lastLoggedInUser,
      JSON.stringify({
        userId: this.profile.userId,
      })
    );

    this.sessionService.onSessionExpire();
  }

  ngOnDestroy(): void {
    /** */
  }

  private goTo(url: string): void {
    this.unsavedFormCheckService.clearAll();
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }
}
