<div class="modal-header">
  <h6 class="modal-title">{{ 'Comments' | translate }}</h6>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true"> &times; </span>
  </button>
</div>

<div class="modal-body comments d-flex flex-column">
  <div class="comment-section px-3 pt-3">
    <ng-container *ngIf="isLogin; else pleaseLoginRef">
      <form
        [formGroup]="form"
        (ngSubmit)="onComment()"
        *ngIf="!(isLoadingAttributeDescriptions$ | async)"
      >
        <div class="d-flex align-items-start">
          <div class="comment-section__img mr-3" *ngIf="false">
            <img [src]="profile.image" appDefaultAvatar appDynamicImgUrl />
          </div>

          <div class="d-flex align-items-start w-100">
            <div class="comment-section__input d-flex flex-column w-100">
              <div class="comment-section__textarea d-flex w-100">
                <app-textarea
                  [defaultMaxLength]="contentAttributeDescription.maximumLength"
                  [placeholder]="placeholder | translate"
                  class="w-100"
                  formControlName="Content"
                >
                  <small class="float-right">
                    {{ content?.value?.length || 0 }}/{{
                      contentAttributeDescription.maximumLength
                    }}
                  </small>
                </app-textarea>
              </div>

              <div
                class="d-flex alert alert-danger jip-upper-first-letter"
                *ngIf="
                  content.invalid &&
                  (content.dirty || content.touched) &&
                  (content.errors?.notEmptyOrWhitespace ||
                    content.errors?.required)
                "
              >
                {{ 'Field is required.' | translate }}
              </div>
            </div>

            <button
              *ngIf="isEditing"
              class="comment-section__submit btn btn-interact btn-interact--big btn-outline-secondary text-uppercase mr-1"
              [disabled]="isSubmitting"
              (click)="onCancelEdit()"
            >
              <i class="fas fa-times"></i>
            </button>

            <button
              type="submit"
              class="comment-section__submit btn btn-interact btn-interact--big btn-primary text-uppercase"
              [disabled]="isSubmitting || isGetLatestComment || form.invalid"
            >
              <i
                class="fas fa-spinner fa-spin m-0"
                *ngIf="isSubmitting || isGetLatestComment; else submitIcon"
              ></i>

              <ng-template #submitIcon>
                <i
                  *ngIf="!isEditing; else editIcon"
                  class="fas fa-paper-plane"
                ></i>
                <ng-template #editIcon>
                  <i class="fas fa-check"></i>
                </ng-template>
              </ng-template>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #pleaseLoginRef>
      <div class="pb-3">
        <span> {{ 'UI.Comment.PleaseLogin.Prefix' | translate }}</span>
        <a
          class="comment-section__login-link underline pointer-cursor"
          appRequireLogin
          >{{ 'login link' | translate }}</a
        ><span>{{ 'UI.Comment.PleaseLogin.Suffix' | translate }}</span>
      </div>
    </ng-template>
  </div>

  <div class="comment-body p-4" #commentBody>
    <ng-container *ngIf="isLoadComments; else commentsRef">
      <div class="d-flex justify-content-center align-items-center">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-container>

    <ng-template #commentsRef>
      <app-comments
        [comments]="comments"
        (triggerDeleteComment)="onDeleteComment($event)"
        (triggerEditComment)="onEditComment($event)"
      ></app-comments>
    </ng-template>

    <ng-container
      *ngIf="comments?.length > 0 && comments?.length < collectionSize"
    >
      <ng-container *ngIf="isLoadMore; else loadMoreRef">
        <div class="d-flex justify-content-center align-items-center">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </ng-container>
      <ng-template #loadMoreRef>
        <div
          class="loadmore d-flex align-items-start pointer-cursor"
          (click)="onLoadMore()"
        >
          {{ 'UI.Comment.ShowMore' | translate }}
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
