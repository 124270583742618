import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { SessionService } from '@src/app/core/session.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { CommentInterface } from '@src/app/shared/interfaces/comment.interface';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
})
export class CommentComponent implements OnDestroy {
  @Input() comment: CommentInterface;
  @Input() isLoadingLikingsCount = false;

  @Output() triggerToggleLike = new EventEmitter();
  @Output() triggerShowLikingsData = new EventEmitter();
  @Output() triggerDeleteComment = new EventEmitter<number>();
  @Output() triggerEditComment = new EventEmitter<CommentInterface>();

  peopleURL = environment.jipUrl.people;
  DateFormat = DateFormat;

  constructor(
    private sessionService: SessionService,
    private modalService: NgbModal,
  ) { }

  get isCommentOwner(): boolean {
    return this.sessionService.currentUser?.id === this.comment.owner.id;
  }

  toggleLike(): void {
    this.triggerToggleLike.emit();
  }

  showLikingData(): void {
    this.triggerShowLikingsData.emit();
  }

  deleteComment(): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.confirmLabel = 'Confirm';
    modalRef.componentInstance.message = 'UI.Comment.ConfirmDelete';
    modalRef.result.then((accepted: boolean) => {
      if (accepted) {
        this.triggerDeleteComment.emit(this.comment.id);
      }
    });
  }

  editComment(): void {
    this.triggerEditComment.emit(this.comment);
  }

  ngOnDestroy(): void { /* */ }
}
