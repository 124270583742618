export function generateUniqueId(): string {
  const timestamp = Date.now().toString(36);
  const array = new Uint32Array(2);
  crypto.getRandomValues(array);
  const random = Array.from(array, (num) => num.toString(36)).join('');
  return timestamp + random;
}

export function isBackForwardNavigation(window): boolean {
  const perfNavList = window?.performance?.getEntriesByType(
    'navigation'
  ) as PerformanceNavigationTiming[];

  return perfNavList?.length > 0 && perfNavList[0].type === 'back_forward';
}

export function getCurrentFragment(window: Window): string {
  const hash = window.location.hash;

  return hash ? hash.substring(1) : '';
}
