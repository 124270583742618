import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from '@core/session.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import {
  TimestampIcon,
  TimestampModel,
} from '@src/app/shared/interfaces/timestamp.interface';

@Component({
  selector: 'app-timestamp-header',
  templateUrl: './timestamp-header.component.html',
})
export class TimestampHeaderComponent implements OnInit {
  @Input() timestampInfo: TimestampModel;
  @Output() closeTimestamp = new EventEmitter();

  headerBarPos = -1;
  DateFormat = DateFormat;

  constructor(private readonly sessionService: SessionService) {}

  ngOnInit(): void {
    this.resgisterWindowEvent();
  }

  getTimestampIcon(name: string): string {
    return new TimestampIcon(name).icon;
  }

  close(): void {
    this.closeTimestamp.emit();
  }

  getHeaderParentWidth(): number {
    const bar = document.getElementById('timestamp-header');
    // skip component wrapper parent
    return bar.parentElement?.parentElement?.clientWidth;
  }

  private resgisterWindowEvent(): void {
    window.onscroll = () => {
      const bar = document.getElementById('timestamp-header');

      if (!bar) return;

      if (this.headerBarPos < 0) {
        this.headerBarPos = this.findPosY(bar);
      }

      if (scrollY > this.headerBarPos) {
        bar.classList.add('fixed-mode');
        bar.style.top = !this.sessionService.isHeaderVisible ? '0px' : 'unset';
      } else {
        bar.classList.remove('fixed-mode');
      }
    };
  }

  private findPosY(obj: any): number {
    let curtop = 0;
    if (typeof obj.offsetParent !== 'undefined' && obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = obj.offsetParent;
      }
      curtop += obj.offsetTop;
    } else if (obj.y) {
      curtop += obj.y;
    }
    return curtop;
  }
}
