import { ArrayUtils } from '@shared/utils/array-utils';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnsavedFormCheckService {
  components: UnsavedFormComponent[] = [];

  register(component: UnsavedFormComponent): void {
    this.components.push(component);
  }

  remove(component: UnsavedFormComponent): void {
    this.components = ArrayUtils.removeItem(this.components, component);
  }

  clearAll(): void {
    this.components = [];
  }

  hasUnsavedContent(): boolean {
    if (!this.components || this.components.length === 0) {
      return false;
    }
    let isUnsaved = false;

    for (const item of this.components) {
      if (item.isFormNotSaved()) {
        isUnsaved = true;
        break;
      }
    }

    return isUnsaved;
  }
}

export interface UnsavedFormComponent {
  isFormNotSaved(): boolean;
  ngOnDestroy(): void;
}
