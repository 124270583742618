<div [ngbTooltip]="event | eventRegisterTooltip | translate">
  <div class="event-register-btn">
    <a
      [class.disabled-input]="event | eventRegisterBtnDisabled"
      [ngbTooltip]="'UI.Event.ExternalRegistration' | translate"
      [value]="event.registrationLink"
      appTooltip
      appUnsavedFormCheck
      appUrlPrefix
      class="btn btn-interact btn-interact--small btn-primary"
      target="_blank"
      [class.disable-register]="event | eventRegisterBtnDisabled"
    >
      <span class="text-truncate">{{
        'UI.Event.ExternalRegistration' | translate
      }}</span>
    </a>
  </div>
</div>
