import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { ChallengeInterface } from '@shared/interfaces/challenge.interface';
import { JurorRating } from '@shared/interfaces/juror.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { transformToTagModaByKey } from '@src/app/shared/components/tag-field/tag-field.component';
import { MetaInformation } from '@src/app/shared/interfaces/attribute-description.interface';
import { PrivateInfo } from '@src/app/shared/interfaces/base/base-entity-edit.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChallengeHttpService extends BaseHttpService<ChallengeInterface> {
  getApiRootPath(): string {
    return 'challenges';
  }

  create(dto: any): Observable<ChallengeInterface> {
    if (!dto) {
      return of(null);
    }
    let url = `${this.apiUrl}/${dto.challengeCreateFromOrgId ?? ''}`;
    url = url.replace(/\/$/, ''); // Remove trailing slash

    return this.http.post<ChallengeInterface>(url, dto);
  }

  tranformData(org: ChallengeInterface): ChallengeInterface {
    org.expertisesTag = this.getExpertiesTags(org);
    org.keyOfferingTags = this.transformKeyOfferingTags(org);
    return org;
  }

  getAttributeDescription(): Observable<MetaInformation> {
    return super.getAttributeDescription().pipe(
      map((data: MetaInformation) => {
        this.sortTimelineNameToFirst(data); // TODO: remove when BE sort attributes
        return data;
      })
    );
  }

  /*
   * Retrieve all Juror of a challenge
   * */
  getJurors(challengeId: number): Observable<ApiGetResponse<UserInterface>> {
    return this.http.get<ApiGetResponse<UserInterface>>(
      `${this.getApiRootPath()}/${challengeId}/jurors`
    );
  }

  /*
   * Delete jurors
   * */
  deleteJurors(challengeId: number, jurorIds: number[]): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: jurorIds,
    };

    return this.http.delete<void>(
      `${this.getApiRootPath()}/${challengeId}/jurors`,
      httpOptions
    );
  }

  /*
   * Retrieve all pending Juror of a challenge
   * */
  getPendingJurors(
    challengeId: number
  ): Observable<ApiGetResponse<UserInterface>> {
    return this.http.get<ApiGetResponse<UserInterface>>(
      `${this.getApiRootPath()}/${challengeId}/pending-jurors`
    );
  }

  /*
   * Accept to be a Juror
   * */
  acceptJurors(challengeId: number): Observable<void> {
    return this.http.put<void>(
      `${this.getApiRootPath()}/${challengeId}/accept/jurors`,
      null
    );
  }

  /*
   * Deny being a Juror
   * */
  denyJurors(challengeId: number): Observable<void> {
    return this.http.put<void>(
      `${this.getApiRootPath()}/${challengeId}/deny/jurors`,
      null
    );
  }

  /*
   * Get Rating Link
   * */
  getRatingLink(challengeId: number): Observable<JurorRating> {
    return this.http.get<JurorRating>(
      `${this.getApiRootPath()}/${challengeId}/jury-rating-link`
    );
  }

  /*
   * Update Rating Link
   * */
  updateRatingLink(challengeId: number, link: JurorRating): Observable<void> {
    return this.http.post<void>(
      `${this.getApiRootPath()}/${challengeId}/jury-rating-link`,
      link
    );
  }

  checkPrivateEntity(id: number): Observable<PrivateInfo> {
    const url = `${this.apiUrl}/${id}/check-private`;
    return this.http.get<PrivateInfo>(url);
  }

  private sortTimelineNameToFirst(data): void {
    const timelineMeta = data.entityDescription.attributeDescriptions.find(
      (item) => item.propertyName === 'ChallengeTimeline'
    );

    if (timelineMeta) {
      const attributes: any[] =
        timelineMeta?.relatedEntityAttributeDescriptions;
      if (attributes) {
        const nameIndex = attributes.findIndex(
          (item) => item.propertyName === 'Name'
        );
        if (nameIndex > -1) {
          const removedItem = attributes.splice(nameIndex, 1);
          attributes.unshift(removedItem[0]);
        }
      }
    }
  }

  private getExpertiesTags(
    item: ChallengeInterface
  ): { text: string; weight: number }[] {
    if (item.expertises) {
      return item.expertises.map((expertise) => {
        return {
          text: expertise.description,
          weight: expertise.priority + 10,
        };
      });
    }
    return [];
  }

  private transformKeyOfferingTags(
    item: ChallengeInterface
  ): { display: string; value: number; readonly?: boolean }[] {
    if (item.keyOfferings) {
      return transformToTagModaByKey(
        item.keyOfferings,
        true,
        'description',
        'description'
      );
    }
    return [];
  }
}
