import { Injectable } from '@angular/core';
import { StorageEnum } from '@shared/enums/storage.enum';
import { GlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { TimeUtils } from '@shared/utils/time-utils';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalFilterStoredService {
  private filterPaneSource$ = new BehaviorSubject<any>({});
  private filterTokenAddedFromOutside$ = new BehaviorSubject<number>(null);
  private currentFilterToken$ = new BehaviorSubject<number>(null);
  private topicFilter$ = new BehaviorSubject({});

  constructor(private userConfig: UserConfigManagementService) {}

  async getFilterCriteria(): Promise<GlobalFilterCriteria> {
    const currentFiltertokenJson = await this.userConfig.get(
      StorageEnum.currentFilterToken
    );
    const activeFilterChannel = await this.userConfig.get(
      StorageEnum.activeFilterChannel
    );

    let currentOrgIds: number[] = [];
    let threadId = null,
      threadEntity = '';

    if (currentFiltertokenJson) {
      currentOrgIds = currentFiltertokenJson;
      if (currentFiltertokenJson.length > 0 && activeFilterChannel) {
        threadId = activeFilterChannel.id;
        threadEntity =
          activeFilterChannel.entityName === EntityName.Event
            ? EntityName.Event
            : '';
      }
    }

    const defaultFiltercriterial: GlobalFilterCriteria = {
      keyword: '',
      isMyInvolvement: false,
      isMyOrgs: false,
      organizationIds: currentOrgIds,
      ...(threadId ? { threadId } : {}),
      threadEntity
    };
    const globalFilters = await this.userConfig.get(StorageEnum.globalFilters);

    if (globalFilters) {
      const result: GlobalFilterCriteria = globalFilters;
      if (result) {
        result.organizationIds = currentOrgIds;
        if (threadId) {
          result.threadId = threadId;
        }
        result.threadEntity = threadEntity;
        return result;
      }
    }

    return defaultFiltercriterial;
  }

  async saveFilterCriteria(
    searchKeyword: string,
    myInvolvement: boolean,
    myOrgs: boolean,
    path: string,
    organizationId: number,
    threadId: number,
    threadEntity: string
  ): Promise<GlobalFilterCriteria> {
    const filterCriteria: GlobalFilterCriteria = {
      keyword: searchKeyword || '',
      isMyInvolvement: myInvolvement,
      isMyOrgs: myOrgs,
      filteringPath: path,
      organizationIds: [],
      organizationId: '',
      ...(threadId && { threadId, threadEntity }),
    };

    if (organizationId) {
      filterCriteria.organizationIds.push(organizationId);
      filterCriteria.organizationId = organizationId.toString();
    }

    const expiredDate = TimeUtils.getDateWithOffset(new Date(), 1);

    await this.userConfig.set(
      StorageEnum.currentFilterToken,
      filterCriteria.organizationIds
    );

    this.appendFilterTokenOnUrl(filterCriteria.organizationIds);

    await this.userConfig.set(StorageEnum.globalFilters, filterCriteria, {
      expires: expiredDate,
      path: '/',
    });

    return filterCriteria;
  }

  appendFilterTokenOnUrl(currentOrgIds: number[]): void {
    if (currentOrgIds.length > 0) {
      StringUtils.setQueryParamOnUrl(
        UrlParam.FilterTokenId,
        currentOrgIds[0].toString(),
        null,
        true
      );
    } else {
      StringUtils.removeParamFromUrl(UrlParam.FilterTokenId, null, true);
    }
  }

  //#region HANDLE FILTER PANE FLOW
  public getFilterPaneSource(): Observable<{}> {
    return this.filterPaneSource$.asObservable();
  }

  public updateFilterPaneSource(filterDto: {}): void {
    this.filterPaneSource$.next(filterDto);
  }
  //#endregion End Handle Filter Pane Flow

  //#region HANDLE FILTER TOKEN FROM OUTSIDE GLOBAL FILTER
  public getFilterTokenAddedFromOutside(): Observable<{}> {
    return this.filterTokenAddedFromOutside$.asObservable();
  }

  public setFilterTokenFromOutSide(organizationId: number): void {
    this.filterTokenAddedFromOutside$.next(organizationId);
  }
  //#endregion End Handle Filter Token From Outside Global Filter

  //#region HANDLE CURRENT FILTER TOKEN -> Using to replace EventBus, step by step
  public getCurrentFilterToken(): Observable<number> {
    return this.currentFilterToken$.asObservable();
  }

  public setCurrentFilterToken(organizationId: number): void {
    this.currentFilterToken$.next(organizationId);
  }
  //#endregion End Handle Current Filter Token

  public getTopicFilter(): Observable<{}> {
    return this.topicFilter$.asObservable();
  }

  public setTopicFilter(threadId: number, threadEntity: string): void {
    this.topicFilter$.next({
      threadId,
      threadEntity,
    });
  }
}
