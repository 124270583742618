export enum StorageEnum {
  credentials = 'CREDENTIALS',
  locale = 'LOCALE',
  accessToken = 'ACCESS_TOKEN',
  refreshToken = 'REFRESH_TOKEN',
  expiredTokenTime = 'EXPIRED_TOKEN_TIME',
  isTokenExpired = 'IS_EXPIRED_TOKEN',
  callBackLoginPage = 'CALLBACK_LOGIN_PAGE',
  domain = 'DOMAIN',
  activeStrictlyCookies = 'ACTIVE_STRICTLY_COOKIES',
  acceptedCookies = 'ACCEPT_COOKIES',
  globalFilters = 'GLOBAL_FILTERS',
  tokenMessageRedirectTarget = 'TOKEN_MESSAGE_REDIRECT_TARGET',
  tokenMessageKey = 'TOKEN_MESSAGE_KEY',
  orgFilterToken = 'ORG_FILTER_TOKEN',
  translationResources = 'TRANSLATION_RESOURCES',
  currentFilterToken = 'CURRENT_FILTER_TOKEN', // Keep current filter token on Local Storage
  previousUrl = 'PREVIOUS_URL',
  paginationSetting = 'PAGINATION_SETTING',
  paginationFilterStored = 'PAGINATION_FILTER_STORED',
  listTableSettingStored = 'LIST_TABLE_SETTING_STORED',
  entityPositionsStored = 'ENTITY_POSITION_STORED',
  isNewSearch = 'IS_NEW_SEARCH',
  sessionId = 'SESSION_ID',
  socialMediaResources = 'SOCIAL_MEDIA_RESOURCES',
  lastLoggedInUser = 'LAST_LOGGED_IN_USER',
  refreshBrowser = 'REFRESH_BROWSER',
  recentChannels = 'RECENT_CHANNELS',
  activeFilterChannel = 'ACTIVE_FILTER_CHANNEL'
}
