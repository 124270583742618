import { Injectable } from '@angular/core';
import {
  FilterCriteria,
  SearchEntitiesService,
} from '@src/app/components/search-results/services/search-entities.service';
import { ChallengeHttpService } from '@src/app/core/http/challenge-http.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { ChannelFilter } from '@src/app/shared/interfaces/organization.interface';
import { UserConfigManagementService } from '@src/app/shared/services/user-config-management.service';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { EventBusService } from 'ngx-eventbus';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SearchChannelsService {
  filterDto!: FilterCriteria;
  keyword$ = new BehaviorSubject<string>(null);
  historyChannels$ = new BehaviorSubject([]);
  filterToken: number;
  activeFilter$ = new BehaviorSubject(null);

  constructor(
    private searchEntitiesService: SearchEntitiesService,
    private readonly orgService: OrganizationHttpService,
    private readonly eventService: EventHttpService,
    private readonly filterStoredService: GlobalFilterStoredService,
    private readonly userConfig: UserConfigManagementService,
    private centralConfig: CentralConfigService,
    private eventBus: EventBusService,
    private readonly challengesService: ChallengeHttpService
  ) {
    this.filterDto = {
      ...this.searchEntitiesService.filterDTO,
      organizationIds: [this.centralConfig.innovationSpaceId],
      pageSize: 1000,
    };

    this.keyword$.subscribe(async (keyword) => {
      this.filterDto = await this.getFilterDto(keyword);
    });

    this.getRecentChannels().then((res) => {
      this.historyChannels$.next(res || []);
    });

    this.filterStoredService
      .getCurrentFilterToken()
      .subscribe(async (token) => {
        if (token) {
          this.filterToken = token;
        }
      });
  }

  getKeyword(): Observable<string> {
    return this.keyword$.asObservable();
  }

  getEntitesAPIs = {
    [EntityName.Event]: (dto) => this.eventService.paginateX(dto),
    [EntityName.Organization]: (dto) => {
      return this.orgService.paginateAsPost(
        dto,
        null,
        dto.organizationId && {
          organizationId: +dto.organizationId,
        }
      )},
    [EntityName.Challenge]: (dto) => this.challengesService.paginateX(dto),
  };

  async getFilterDto(keyword: string): Promise<FilterCriteria> {
    const filterCriteria = await this.filterStoredService.getFilterCriteria();
    const dto = {
      ...this.filterDto,
      ...filterCriteria,
      keyword,
    };
    return dto;
  }

  handleKeywordChange(keyword: string) {
    this.keyword$.next(keyword);
  }

  async getRecentChannels() {
    return await this.userConfig.get(StorageEnum.recentChannels);
  }

  async storeChannelSelection(channel) {
    const recentChannels =
      (await this.userConfig.get(StorageEnum.recentChannels)) || [];
    const channels = [...recentChannels];
    const isExisted = channels.find(
      (item) => item.id === channel.id && item.entityName === channel.entityName
    );

    this.searchEntitiesService.setChannelFilter({
      threadId: channel.id,
      threadEntity:
        channel.entityName === EntityName.Event ? EntityName.Event : '',
    });

    await this.setActiveChannel(channel);

    if (isExisted) return;
    channels.push(channel);
    this.userConfig.set(StorageEnum.recentChannels, channels);
    this.historyChannels$.next(channels);
  }

  async deleteChannel(channel) {
    const recentChannels =
      (await this.userConfig.get(StorageEnum.recentChannels)) || [];

    const newChannels = recentChannels.filter(
      (item) =>
        !(item.id === channel.id && item.entityName === channel.entityName)
    );

    this.userConfig.set(StorageEnum.recentChannels, newChannels);
    this.historyChannels$.next(newChannels);
  }

  async setActiveChannel(channel) {
    await this.userConfig.set(StorageEnum.activeFilterChannel, channel);
  }

  async getActiveChannel() {
    const activeChannel = await this.userConfig.get(
      StorageEnum.activeFilterChannel
    );

    return activeChannel;
  }

  async onSelectChannel(channel) {
    const threadEntity =
      channel.entityName === EntityName.Event ? EntityName.Event : '';
    const { pageSize, ...restDto } = this.searchEntitiesService.filterDTO;
    const globalFilter = await this.userConfig.get(StorageEnum.globalFilters);
    const orgId = this.centralConfig.innovationSpaceId || this.filterToken;
    let keyword = '';

    if (globalFilter) {
      keyword = globalFilter.keyword;
    }

    this.eventBus.triggerEvent(GlobalEventBus.GlobalFilterEvent, {
      filterCriteria: {
        ...restDto,
        ...(orgId && {
          organizationIds: [orgId],
          threadId: channel.id,
          threadEntity,
        }),
        keyword,
      },
    });

    await this.storeChannelSelection(channel);
    await this.getRecentChannels();

    this.keyword$.next('');
    this.setSubCommunityParam(channel);
  }

  async resetChannel(): Promise<void> {
    const filterCriteria = await this.filterStoredService.getFilterCriteria();
    const {
      keyword,
      isMyInvolvement,
      isMyOrgs,
      filteringPath,
      organizationIds,
    } = filterCriteria;
    const orgId = this.centralConfig.innovationSpaceId || this.filterToken;

    this.searchEntitiesService.setChannelFilter({});

    this.eventBus.triggerEvent(GlobalEventBus.GlobalFilterEvent, {
      filterCriteria: {
        ...filterCriteria,
        organizationIds: orgId ? [orgId] : [...organizationIds],
        organizationId: orgId ? orgId : organizationIds[0],
        keyword,
        threadId: '',
        threadEntity: '',
      },
    });

    await this.filterStoredService.saveFilterCriteria(
      keyword,
      isMyInvolvement,
      isMyOrgs,
      filteringPath,
      organizationIds[0],
      null,
      ''
    );

    await this.setActiveChannel(null);

    this.filterStoredService.setTopicFilter(null, '');
    StringUtils.removeParamFromUrl(UrlParam.SubOrganizationId);
  }

  setSubCommunityParam(channel: ChannelFilter): void {
    const subOrgId = StringUtils.getParamFromUrl(UrlParam.SubOrganizationId);

    if (
      channel.entityName === EntityName.Organization &&
      subOrgId !== channel.id
    ) {
      StringUtils.setQueryParamOnUrl(
        UrlParam.SubOrganizationId,
        channel.id.toString(),
        null,
        true
      );
    }
  }
}
