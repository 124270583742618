import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NotificationService } from '@core/notification.service';
import { PaginationSettingService } from '@core/services/pagination-setting.service';
import { NgbModalRef, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import {
  PAGE_SIZE_CONFIG_DATA,
  PageSizeConfig,
} from '@shared/models/pagination.model';
import { MediaBreakpoint } from '../../models/ui.model';

@Component({
  selector: 'app-bootstrap-pagination',
  templateUrl: './bootstrap-pagination.component.html',
})
export class BootstrapPaginationComponent implements OnInit {
  @Input() page: number;
  @Input() pageSize: number;
  @Input() collectionSize: number;
  @Input() showChangePageSizeCtrl = false;
  @Input() pageSizeConfig: PageSizeConfig = PageSizeConfig.FourItemsFirstPage;
  @Input() maxDropdownHeight = 200;

  @Input() allowPaginationWarning = false;
  @Input() shouldShowWarning = false;

  @Output() changePageSize = new EventEmitter<number>();
  @Output() changePage = new EventEmitter<number>();
  @Output() confirmSave = new EventEmitter();

  @ViewChild(NgbPagination, { static: false }) set content(
    content: NgbPagination
  ) {
    if (content && this.allowPaginationWarning) {
      this.checkStateBeforePaging(content);
    }
  }

  constructor(
    private paginationSettingService: PaginationSettingService,
    private notificationService: NotificationService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  pageSizeDropdownItems: number[] = [];
  maxSize = 3;

  get pageDropdownItems(): number[] {
    if (this.collectionSize > 0) {
      const totalPages = Math.ceil(this.collectionSize / this.pageSize);
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    return [];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.setMaxSize(innerWidth);
  }

  ngOnInit(): void {
    this.initPageSizeDropdown();
    this.setMaxSize(window.innerWidth);
  }

  onChangePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.changePageSize.emit(pageSize);
    this.paginationSettingService.setPaginationSettings(
      pageSize,
      this.pageSizeConfig
    );
  }

  onChangePage(page: number): void {
    if (this.allowPaginationWarning && this.shouldShowWarning) {
      this.showWarningDialog().result.then((isSave: boolean) => {
        this.confirmSave.emit(isSave);
        this.page = page;
        this.changePage.emit(page);
      });
    } else {
      this.page = page;
      this.changePage.emit(page);
    }
  }

  private setMaxSize(innerWidth: number): void {
    this.maxSize =
      innerWidth >= MediaBreakpoint.lg
        ? 5
        : innerWidth < MediaBreakpoint.sm
        ? 1
        : 3;
  }

  private initPageSizeDropdown(): void {
    this.pageSizeDropdownItems =
      PAGE_SIZE_CONFIG_DATA[this.pageSizeConfig] || [];
  }

  private checkStateBeforePaging(ngbPagination: NgbPagination): void {
    const oldSelectPage = ngbPagination.selectPage.bind(ngbPagination);

    ngbPagination.selectPage = (pageNumber) => {
      if (!this.shouldShowWarning) {
        return oldSelectPage(pageNumber);
      } else {
        this.showWarningDialog().result.then((isSave: boolean) => {
          this.confirmSave.emit(isSave);

          return oldSelectPage(pageNumber);
        });
      }
    };
  }

  private showWarningDialog(): NgbModalRef {
    const title = 'SITCH_PAGE_Warning-Title';
    const message = 'SITCH_PAGE_Warning-Content';
    const confirmLabel = 'Save';

    return this.notificationService.confirm(
      title,
      message,
      false,
      confirmLabel
    );
  }
}
