import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTimestampDialogComponent } from '@src/app/components/dialogs/create-timestamp-dialog/create-timestamp-dialog.component';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { TimestampIcon } from '../../interfaces/timestamp.interface';
import { StyleMode } from '../../enums/style-mode.enum';

@Component({
  selector: 'app-btn-create-timestamp',
  templateUrl: './btn-create-timestamp.component.html',
})
export class BtnCreateTimestampComponent {
  @Input() httpService: BaseHttpService<any>;
  @Input() entityId: number;
  @Input() styleMode: StyleMode = StyleMode.Detail;
  @Output() createSuccess = new EventEmitter<boolean>();

  styleModeEnum = StyleMode;

  constructor(
    private modalService: NgbModal
  ) { }

  timestampIcon = new TimestampIcon('Icon').icon;

  openCreateTimestamp(): void {
    const modalRef = this.modalService.open(CreateTimestampDialogComponent, {
      centered: true,
      backdrop: 'static',
      scrollable: false,
      size: 'md'
    });

    modalRef.componentInstance.httpService = this.httpService;
    modalRef.componentInstance.entityId = this.entityId;
    modalRef.result
      .then((accepted: boolean) => {
        if (accepted) {
          this.createSuccess.emit(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
