import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommentsModalComponent } from '@shared/components/comments-modal/comments-modal.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { Entity } from '@src/app/shared/models/entity.model';

@Component({
  selector: 'app-comment-entity',
  templateUrl: './comment-entity.component.html'
})
export class CommentEntityComponent {
  @Input() entity!: Entity;
  @Input() entityName!: EntityName;
  @Input() isLoadingCommentsCount = false;

  @Output() afterCommentModalClosed = new EventEmitter();

  EntityName = EntityName;

  constructor(
    private modalService: NgbModal
  ) { }

  openCommentModal(entity: Entity): void {
    const modalRef = this.modalService.open(CommentsModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
    });

    modalRef.componentInstance.entityId = entity.id;
    modalRef.componentInstance.entityName = this.entityName;
    modalRef.result.finally(() => {
      this.afterCommentModalClosed.emit();
    });
  }
}
