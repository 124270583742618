<div *ngIf="data" class="card-item col event-card">
  <div class="row">
    <div class="col-12 col-md-5 p-0">
      <div
        appHoverDisplay
        [dataHoverDirective]="data"
        class="card-item__image-wrapper"
      >
        <img [src]="data.image?.url || data.image" appDefaultPlaceholder />
      </div>
    </div>
    <a
      class="col-12 col-md-7 link-unstyled"
      appUnsavedFormCheck
      [href]="entityUrl"
    >
      <div
        class="card-item__info--link pl-md-4 pt-3 pb-3 h-100 col widget__news-message--background"
      >
        <app-privacy-flag [isPrivate]="data.isPrivate"></app-privacy-flag>

        <div class="mt-2">
          <h5
            class="venture__title widget__heading truncate-line-1"
            appTooltip
            triggers="manual"
            #t="ngbTooltip"
            [toolTipControl]="t"
            [ngbTooltip]="data.title"
          >
            {{ data.title }}
          </h5>
        </div>

        <div
          class="venture__description-without-min-height d-flex flex-md-row flex-column"
        >
          <div class="d-flex flex-column col-12 col-md-6 p-0">
            <div class="venture__label widget__label">
              {{ attributes?.Date?.displayName || ('Date' | translate) }}
            </div>

            <div class="widget__content event-card__date">
              {{
                data.eventDateTime
                  | localizedDate : DateFormat.FullDateTimeCommon
              }}
            </div>
          </div>

          <div class="d-flex flex-column col-12 col-md-6 p-0">
            <div class="venture__label widget__label">
              {{
                attributes?.Date?.displayName || 'UI.Common.Language'
                  | translate
              }}
            </div>

            <div class="widget__content event-card__date">
              {{ languageValue }}
            </div>
          </div>
        </div>

        <div class="venture__description-without-min-height">
          <div class="venture__label widget__label" translate>Description</div>
          <app-rich-text-toggle
            [text]="data.description"
            [controlName]="
              'event-card-description-' + (data?.id || data?.instanceId)
            "
            [hideSeeMore]="true"
            [minNumOfRows]="1"
          ></app-rich-text-toggle>
        </div>

        <!-- Ready for Add to calender / Open QR code feartures -->
        <div
          class="event-card__interaction-section mt-2 d-flex flex-column flex-md-row"
        >
          <button
            *ngIf="data.showExternalRegistration || data.hasJoined"
            appAddToCalendar
            [event]="data"
            class="btn btn-add-to-calendar mr-0 mr-md-2"
          >
            <i class="fas fa-calendar-plus"></i>
            <span>
              {{ 'UI.Event.AddToCalendar' | translate | uppercase }}
            </span>
          </button>

          <button
            *ngIf="false"
            class="btn btn-outline-primary mt-md-0 mt-2"
            appHoverDisplay
            [dataHoverDirective]="data"
            (click)="$event.stopPropagation()"
          >
            <i class="fas fa-qrcode"></i>
            <span>
              {{ 'UI.Event.OpenQRCode' | translate | uppercase }}
            </span>
          </button>
        </div>
      </div>
    </a>
  </div>
</div>
