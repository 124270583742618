<ng-container *ngFor="let item of items">
  <div class="organization__radio-item mb-3">
    <input
      appRequireLogin
      [value]="item.codeId"
      class="organization__radio"
      [id]="item.codeId"
      [name]="controlName"
      [checked]="item.codeId === items[currentIndex].codeId"
      type="radio"
      [class.disabled-input]="!editable"
      (change)="onRadioChange($event, item)"
    />
    <label
      class="radio-info d-flex flex-column"
      [for]="item.codeId"
      [class.disabled-action]="!editable"
    >
      <span class="radio-text">
        {{ item.value }}
      </span>
    </label>
  </div>
</ng-container>
