export interface TimelineItem {
  name: string;
  nameKey: string;
  type: string;
  phaseDate: string;
  isCurrent: boolean;
  isOptional: boolean;
  order: number;
  daysLeft: number;
  tooltip?: string;
  isValid?: boolean;
}

export enum TimelineItemType {
  StartPeriod = 0,
  EventPoint = 1,
}

export enum TimelineNameKey {
  Submission = 'Submission',
  JointCreating = 'JointCreating',
  Evaluation = 'Evaluation',
  Awarding = 'Awarding',
  Closing = 'Closing'
}
