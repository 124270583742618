import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class CustomParamInterceptor implements HttpInterceptor {
  constructor(
    private readonly filterStoredService: GlobalFilterStoredService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.filterStoredService.getCurrentFilterToken().pipe(
      take(1),
      switchMap((orgId) => {
        if (
          this.shouldIncludeRequest(request) &&
          orgId &&
          !request.params.has(UrlParam.FilterTokenId)
        ) {
          request = request.clone({
            params: request.params.append(
              UrlParam.FilterTokenId,
              orgId.toString()
            ),
          });
        }
        return next.handle(request);
      })
    );
  }

  private shouldIncludeRequest(request: HttpRequest<unknown>): boolean {
    const { news, messages, venture } = environment.jipUrl;
    const URLs = [news, messages, venture];

    let found = false;

    for (const key of URLs) {
      if (request.url.includes(key)) {
        found = true;
        return found;
      }
    }
  }
}
