<div
  class="position-relative"
  [ngClass]="{ 'pb-4': !hideSeeMore && showToggle }"
>
  <div
    #contentContainer
    #collapse="ngbCollapse"
    class="rich-text-toggle d-block {{ controlName }}"
    [(ngbCollapse)]="isCollapsed"
    [ngStyle]="{ 'max-height': textContainerHeight }"
    [class.overflow-auto]="expandedMode"
  >
    <div
      *ngIf="coverImage && !isCollapsed"
      appHoverDisplay
      class="rich-text-toggle__image-wrapper mb-2 mt-0"
      [dataHoverDirective]="{ image: coverImage }"
      (click)="$event.stopPropagation()"
    >
      <img
        appDefaultPlaceholder
        appDynamicImgUrl
        class="image"
        alt="message image"
        [src]="coverImage"
      />
    </div>
    <quill-view [content]="text"> </quill-view>
  </div>

  <div
    *ngIf="image"
    appHoverDisplay
    class="rich-text-toggle__image-wrapper"
    [dataHoverDirective]="{ image: image }"
  >
    <img
      appDefaultPlaceholder
      appDynamicImgUrl
      class="image"
      alt="message image"
      [src]="image"
    />
  </div>

  <div
    *ngIf="showToggle && !expandedMode"
    class="rich-text-toggle__overlay d-flex align-items-end"
    [class]="{
      'rich-text-toggle__overlay--background': isCollapsed
    }"
  ></div>
  <button
    *ngIf="(!hideSeeMore && showToggle) || expandedMode"
    type="button"
    class="rich-text-toggle__btn-link btn-link-primary"
    (click)="onViewMore($event)"
  >
    <span>{{
      (isCollapsed ? 'UI.Common.ViewMore' : 'UI.Common.ViewLess') | translate
    }}</span>

    <i
      *ngIf="!showOverlayInsteadOfShowMore"
      class="fas fa-lg ml-2 text-dark"
      [class.fa-caret-down]="isCollapsed !== isRevertCaret"
      [class.fa-caret-up]="isCollapsed === isRevertCaret"
    ></i>
  </button>
</div>
