import { Component, Input } from '@angular/core';
import { collapseAnimation } from '@shared/functions/animations';

@Component({
  selector: 'app-collapsible-hint',
  templateUrl: './collapsible-hint.component.html',
  animations: [collapseAnimation],
})
export class CollapsibleHintComponent {
  @Input() content = '';

  isCollapsed = true;
}
