<ng-container *ngIf="entityName">
  <ng-container [ngSwitch]="entityName">
    <ng-container *ngSwitchCase="EntityName.Organization">
      <app-widget-metadata-table-organization
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      >
      </app-widget-metadata-table-organization>
    </ng-container>

    <ng-container *ngSwitchCase="EntityName.Challenge">
      <app-widget-metadata-table-challenge
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      >
      </app-widget-metadata-table-challenge>
    </ng-container>

    <ng-container *ngSwitchCase="EntityName.Event">
      <app-widget-metadata-table-event
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      >
      </app-widget-metadata-table-event>
    </ng-container>

    <ng-container *ngSwitchCase="EntityName.Venture">
      <app-widget-metadata-table-venture
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
        [editable]="editable === 'true'"
      >
      </app-widget-metadata-table-venture>
    </ng-container>

    <ng-container *ngSwitchCase="EntityName.Person">
      <app-widget-metadata-table-people
        [orgId]="orgId"
        [domain]="domain"
        [fromWidget]="true"
      >
      </app-widget-metadata-table-people>
    </ng-container>
  </ng-container>
</ng-container>
