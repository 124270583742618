<app-modal-wrapper
  class="export-summary-dialog"
  [textHeader]="textHeader"
  [isSubmitting]="isSubmitting"
  textOk="UI.Common.Export"
  (btnOkClicked)="submit()"
  (btnCancelClicked)="cancel()"
  [disabled]="shouldDisableExportButton()"
>
  <div class="px-2">
    <div translate>UI.Export.Label.Choose-time-range</div>
    <div class="mt-1 row flex-md-row justify-content-between">
      <div class="col-md-6">
        <div translate class="widget__label">UI.Common.From</div>
        <app-boostrap-datepicker
          [time]="exportStartTime"
          [maxTime]="maxFromDate"
          [editable]="true"
          [hideTimeOfDate]="true"
          [shouldUseDefaultDate]="true"
          (dateTimeChange)="setFromDate($event)"
        >
        </app-boostrap-datepicker>
      </div>
      <div class="col-md-6">
        <div translate class="widget__label">UI.Common.To</div>
        <app-boostrap-datepicker
          [time]="exportEndTime"
          [editable]="true"
          [hideTimeOfDate]="true"
          [shouldUseDefaultDate]="true"
          (dateTimeChange)="setToDate($event)"
          [minTime]="minToDate"
        >
        </app-boostrap-datepicker>
      </div>
    </div>

    <div class="mt-4 pt-3" translate>UI.Export.Label.Choose-entities</div>
    <div class="mt-2">
      <app-tree-view-check-box
        [items]="checkboxItems || []"
        class="tree-view-check-box"
        (treeChanged)="onCheckboxItemsChange()"
      ></app-tree-view-check-box>
    </div>

    <ng-container *ngIf="entityId && checkboxOptionsItems.length > 0">
      <div class="options" *ngIf="!disableCheckboxOptions">
        <div translate>UI.Export.Options</div>
        <div class="mt-2">
          <app-tree-view-check-box
            [disabled]="disableCheckboxOptions"
            [items]="checkboxOptionsItems || []"
            class="tree-view-check-box"
          ></app-tree-view-check-box>
        </div>
      </div>
    </ng-container>
  </div>
</app-modal-wrapper>
