export enum OrgsSearchMode {
  ForSubmitting = 'for-submitting',
  ForSubmittingAll = 'for-submitting-all',
  ForLead = 'for-lead',
  ForLeadPerson = 'for-lead-person',
  PresonAfferableOrgs = 'affable-orgs',

  // Special case
  ForLeadIncludeChallenge = 'for-lead-include-challenge',
  ForFilterVenture = 'for-filter-venture'
}

export enum OrgsSearchTagFieldTypeEnum {
  FillOrg = 'FillOrg',
  Header = 'Header',
}
