import { EntityName } from '../enums/entity-name.enum';
import { CrossSearchingEntity } from '../models/cross-searching-entity.model';

export const defaultEntities: CrossSearchingEntity[] = [
  {
    key: EntityName.Challenge,
    label: 'Calls & Challenges',
  },
  {
    key: EntityName.Venture,
    label: 'Ventures',
  },
  {
    key: EntityName.Person,
    label: 'Community',
  },
  {
    key: EntityName.Event,
    label: 'Events',
  },
  {
    key: EntityName.Organization,
    label: 'Organizations',
  },
  {
    key: EntityName.News,
    label: 'News & Messages',
  },
];

export const ORDERED_ORGANIZATION_CUSTOM_ATTRIBUTES = [
    'ResidentOf',
    'InnovationFocus',
    'Competency',
    'CompanyType',
    'CountryOfOrigin',
  ];