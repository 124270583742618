import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadCompany } from '../../interfaces/lead-company.interface';

@Component({
  selector: 'app-organization-challenge-view',
  templateUrl: './organization-challenge-view.component.html',
})
export class OrganizationChallengeViewComponent implements OnInit {
  @Input() numberOfDisplays = 3;
  @Input() items: LeadCompany[] = [];
  organizations: LeadCompany[] = [];
  challenges: LeadCompany[] = [];

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
    this.organizations = this.items?.filter((filterOrg) => !filterOrg.showAsChallenge) || [];
    this.challenges = this.items?.filter((filterChallenge) => filterChallenge.showAsChallenge) || [];
  }

  open(modalRef: TemplateRef<any>, event: Event): void {
    ((event.target as Element)?.closest('datatable-body-cell') as HTMLElement)?.blur();
    this.modalService.open(modalRef, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
  }
}
