import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import {
  FormComponentInterface,
  FormErrorService,
} from '@core/form/form-error.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { RegisterResponse } from '@shared/models/authen.model';
import { TextValidator } from '@shared/utils/form-fields-validation';
import { StringUtils } from '@shared/utils/string-utils';
import { ProfileEmailService } from '@src/app/core/authen/profile-email.service';
import { ToastService } from '@src/app/core/toast.service';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
})
export class FormRegisterComponent implements OnInit, FormComponentInterface {
  @Input() invitationTokens = '';

  @Input() portalName: string = environment.portalName;

  @Input() portalUrl: string = environment.portalUrl;

  @Input() termOfUseUrl = environment.termOfUseUrl;

  @Input() assignToOrgId: number;
  @Input() applyPendingToAutoAssignment: boolean;

  @Input() eventId

  @Output() sendConfirmationEmail = new EventEmitter();
  @Output() loginSuccess = new EventEmitter();

  usernameMinLength = environment.loginFormLimit.usernameMinLength;
  passwordMinLength = environment.loginFormLimit.passwordMinLength;

  loginForm: UntypedFormGroup;
  formErrorKey: string;
  errorMessage: { [key: string]: string };
  isSubmitting?: boolean;
  isSubmitted?: boolean;

  passwordIsShow = false;
  passwordRepeatIsShow = false;

  isRegisteredAccount = false;
  sentEmailAddress: string;

  constructor(
    public sessionService: SessionService,
    protected toastService: ToastService,
    private fb: UntypedFormBuilder,
    public authenService: AuthenService,
    private formErrorService: FormErrorService,
    private profileEmailService: ProfileEmailService,
    private centralConfig: CentralConfigService,
    private cookieService: CookieService,
    private el: ElementRef
  ) {
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.portalUrl = this.cookieService.get(SessionKeyEnum.TENANT_DOMAIN);
  }

  getForm(): UntypedFormGroup {
    return this.loginForm;
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group(
      {
        firstName: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        lastName: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        username: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
              TextValidator.cannotContainWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        email: [
          '',
          {
            validators: [Validators.required, Validators.email],
            updateOn: 'blur',
          } as AbstractControlOptions,
        ],
        password: [
          '',
          {
            validators: [
              Validators.required,
              TextValidator.notEmptyOrWhitespace,
            ],
          } as AbstractControlOptions,
        ],
        passwordRepeat: [
          '',
          {
            validators: [Validators.required],
          } as AbstractControlOptions,
        ],
        recaptcha: ['', Validators.required],
        termAgreement: [false, [Validators.requiredTrue]],
      },
      {
        validator: Validators.compose([checkPasswordMatch]),
      }
    );
    this.formErrorService.register(this);
  }

  ngOnDestroy(): void {
    /**/
  }

  onSubmit(): void {
    this.isSubmitting = true;

    this.authenService
      .register(
        this.loginForm.value,
        this.invitationTokens,
        this.assignToOrgId,
        this.applyPendingToAutoAssignment
      )
      .subscribe({
        next: (data: RegisterResponse | ApiResponse) => {
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            this.checkError(error);
          } else {
            const resData = data as RegisterResponse;
            this.completeRegistration(resData);
            this.scrollToTop();
          }
        },
        error: (error) => {
          this.checkError(error);
        },
      });
  }

  private completeRegistration(response: RegisterResponse): void {
    this.isRegisteredAccount = true;
    this.sentEmailAddress = response.email;
    this.sendConfirmationEmail.emit();
    this.isSubmitting = false;
    this.isSubmitted = true;
  }

  checkError(data): void {
    this.formErrorService.handleError(data);
    this.isSubmitting = false;
    this.isSubmitted = false;

    setTimeout(() => this.resendActivationMail());
  }

  togglePassword(event): void {
    event.preventDefault();
    this.passwordIsShow = !this.passwordIsShow;
  }

  togglePasswordRepeat(event): void {
    event.preventDefault();
    this.passwordRepeatIsShow = !this.passwordRepeatIsShow;
  }

  get firstName(): any {
    return this.loginForm.get('firstName');
  }

  get lastName(): any {
    return this.loginForm.get('lastName');
  }

  get username(): any {
    return this.loginForm.get('username');
  }

  get email(): any {
    return this.loginForm.get('email');
  }
  get password(): any {
    return this.loginForm.get('password');
  }

  get passwordRepeat(): any {
    return this.loginForm.get('passwordRepeat');
  }

  resendConfirmationEmail(usernameOrEmail: string): void {
    this.isSubmitting = true;
    this.isSubmitted = false;

    const sentToEmail = usernameOrEmail?.trim();
    this.authenService.resendConfirmationEmail(sentToEmail).subscribe({
      next: (data: RegisterResponse | ApiResponse) => {
        const error = data as ApiResponse;
        if (error && error.status === 400) {
          this.checkError(error);
        } else {
          const resData = data as RegisterResponse;
          this.completeRegistration(resData);
        }
        this.isSubmitting = false;
      },
      error: (error) => {
        this.checkError(error);
      },
    });
  }

  /**
   * Add event listener for "click here" link to resend confirmation email
   */
  private resendActivationMail(): void {
    const element = document.getElementById('resendEmail') as HTMLAnchorElement;

    if (!element) return;

    element.onclick = (e: MouseEvent) => {
      e.preventDefault();

      const email = StringUtils.getParamFromUrl(
        UrlParam.ResendEmail,
        element.href
      );
      const token = StringUtils.getParamFromUrl(UrlParam.Token, element.href);

      this.profileEmailService
        .resendChangeEmailAnonymously(email, token)
        .pipe(untilDestroyed(this))
        .subscribe((data: any) => {
          if (data.isEmailDelivered) {
            this.toastService.showSuccess('UI.Toast.SentSuccessfully');
          }
        });
    };
  }

  private scrollToTop(): void {
    this.el.nativeElement.ownerDocument.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}

export const checkPasswordMatch: ValidatorFn = (
  group: UntypedFormGroup
): ValidationErrors | null => {
  const pass = group.get('password').value;
  const confirmPass = group.get('passwordRepeat').value;

  return pass === confirmPass ? null : { passwordNotSame: true };
};
