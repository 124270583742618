import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenTypes } from '@shared/enums/token-type';
import { FormUtils } from '@shared/utils/form-utils';
import { StringUtils } from '@shared/utils/string-utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { TranslateServiceLanguage } from '@src/app/shared/interfaces/language.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { StringReplacementPipe } from '@src/app/shared/pipes/string-replacement.pipe';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { finalize } from 'rxjs/operators';

export const NEW_LINE_CODE = '%0D%0A';

@Component({
  selector: 'app-invitation-by-email',
  templateUrl: './invitation-by-email.component.html',
})
export class InvitationByEmailComponent implements OnInit {
  @Input() baseHttpService: BaseHttpService<any>;
  @Input() entityId;
  @Input() entityName = '';
  @Input() entityUrl = '';
  @Input() willUseEntityId = true;

  @Input() inviteTokenType: TokenTypes;
  @Input() label = 'UI.Invitation.InvitingPeopleButton';
  @Input() messageSubject = `UI.Invitation.Mail--Subject`;
  @Input() messageBody = '';
  @Input() senderName = '';
  @Input() senderSignature = '';
  @Input() disableReason = '';
  @Input() ccEmail = '';
  @Input() orgName = '';

  inviting = false;

  profile: UserInterface;

  readonly TokenTypes = TokenTypes;

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private stringReplacementPipe: StringReplacementPipe,
    private cookieService: CookieService,
    private authenService: AuthenService
  ) {}

  ngOnInit(): void {
    this.authenService
      .getProfile()
      .pipe(untilDestroyed(this))
      .subscribe((profile: UserInterface) => {
        this.profile = profile;
      });
  }

  openInvitationEmail(): void {
    if (!this.baseHttpService || (this.willUseEntityId && !this.entityId)) {
      return;
    }

    this.inviting = true;
    const getEndpoint = 'token/invite';
    let postEndpoint = `/${getEndpoint}`;
    postEndpoint += this.inviteTokenType
      ? `?tokenType=${this.inviteTokenType}`
      : '';

    const apiRequest$ = this.willUseEntityId
      ? this.baseHttpService.postByEntity(this.entityId, {}, postEndpoint)
      : this.baseHttpService.getByEntity('', getEndpoint);

    apiRequest$
      .pipe(finalize(() => (this.inviting = false)))
      .subscribe((res: any) => {
        if (res?.token) {
          this.openEmailClientTenantSpecific(res?.token);
        } else {
          this.toastService.showError(
            res?.title,
            'Only initiator and stakeholder can do this action'
          );
        }
      });
  }

  openEmailClient(token): void {
    if (!this.messageBody) {
      return;
    }
    // build Subject
    const subject = this.stringReplacementPipe.transform(
      this.translateService.instant(this.messageSubject),
      {
        '{portalName}': environment.portalName,
        '{sender}': this.senderName,
      }
    );

    // buid Dear fortmat
    const dearReservedText = this.translateService.instant(
      'UI.Invitation.Mail-content--Dear'
    );

    // build Body main text
    const entityText = StringUtils.buildEntityWithLinkText(
      this.entityName,
      this.entityUrl
    );

    const bodyMainText = this.stringReplacementPipe.transform(
      this.translateService.instant(this.messageBody),
      {
        '{entityName}': entityText,
        '{portalLink}': environment.portalUrl,
      }
    );

    // build Registration Link
    const currentLang =
      this.translateService.currentLang?.toLocaleLowerCase() ||
      TranslateServiceLanguage.en.toLocaleLowerCase();
    const link = `${location.origin}/${currentLang}${environment.jipUrl.processToken}?token=${token}`;

    const registrationSentence = this.stringReplacementPipe.transform(
      this.translateService.instant(
        'UI.Invitation.Mail-content--Link-To-Register'
      ),
      {
        '{link}': link,
      }
    );

    // build Looking Forward Sentence
    const lookingForward = this.translateService.instant(
      'UI.Invitation.Mail-content--Looking-Forward'
    );

    const emailFooter = this.translateService.instant(
      'UI.Invitation.Mail-content--Footer'
    );

    const lines = [
      dearReservedText,
      ` `,
      bodyMainText,
      registrationSentence,
      lookingForward,
      ' ',
      this.senderSignature,
      ' ',
      emailFooter,
    ];

    const body = lines.join(NEW_LINE_CODE);

    this.goTo(`mailto:?cc=${this.ccEmail}&subject=${subject}&body=${body}`);
  }

  openEmailClientTenantSpecific(token): void {
    const tenantName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    if (this.inviteTokenType === TokenTypes.InviteChallengeJuror) {
      this.messageSubject = 'UI.Invitation.Mail-tenant-specific--Subject-Jury';
    } else {
      this.messageSubject = 'UI.Invitation.Mail-tenant-specific--Subject';
    }

    const subject = this.stringReplacementPipe.transform(
      this.translateService.instant(this.messageSubject),
      {
        '{tenantName}': tenantName,
        '{sender}': this.senderName,
      }
    );

    const dearReservedText = this.translateService.instant(
      'UI.Invitation.Mail-content--Dear'
    );

    //build body main text
    const entityText = StringUtils.buildEntityWithLinkText(
      this.entityName,
      this.entityUrl
    );
    this.messageBody = this.getTenantSpecificBodyMainText();
    const bodyMainText = this.stringReplacementPipe.transform(
      this.translateService.instant(this.messageBody),
      {
        '{entityName}': entityText,
        '{tenantName}': tenantName,
      }
    );

    // build Registration Link
    const currentLang =
      this.translateService.currentLang?.toLocaleLowerCase() ||
      TranslateServiceLanguage.en.toLocaleLowerCase();
    const link = `${location.origin}/${currentLang}${environment.jipUrl.processToken}?token=${token}`;

    const registrationSentence = this.stringReplacementPipe.transform(
      this.translateService.instant(
        this.inviteTokenType === TokenTypes.InviteEvent
          ? 'UI.Invitation.Mail-content-tenant-specific--Link-To-Register-Event'
          : 'UI.Invitation.Mail-content-tenant-specific--Link-To-Register'
      ),
      {
        '{link}': link,
      }
    );

    //build footer
    const emailFooter = this.stringReplacementPipe.transform(
      this.translateService.instant(
        'UI.Invitation.Mail-content-tenant-specific--Footer'
      ),
      { '{tenantName}': tenantName }
    );

    this.senderSignature = `${this.profile.firstName} ${this.profile.lastName}`;

    const lines = [
      dearReservedText,
      ' ',
      bodyMainText,
      '',
      registrationSentence,
      '',
      this.senderSignature,
      ' ',
      emailFooter,
    ];
    const body = lines.join(NEW_LINE_CODE);

    this.goTo(`mailto:?cc=${this.ccEmail}&subject=${subject}&body=${body}`);
  }

  getTenantSpecificBodyMainText(): string {
    switch (this.inviteTokenType) {
      case TokenTypes.InviteChallenge:
        if (this.orgName) {
          return 'UI.Invitation.Mail-content-tenant-specific--Body-For-Organization';
        }
        return 'UI.Invitation.Mail-content-tenant-specific--Body-For-Challenge';
      case TokenTypes.InviteEvent:
        return 'UI.Invitation.Mail-content-tenant-specific--Body-For-Event';
      case TokenTypes.InviteChallengeJuror:
        return 'UI.Invitation.Mail-content-tenant-specific--Body-For-Jury';
      case TokenTypes.InviteVenture:
        return 'UI.Invitation.Mail-content-tenant-specific--Body-For-Venture';
      case TokenTypes.InvitePeople:
        return 'UI.Invitation.Mail-content-tenant-specific--Body-For-People';
      default:
        break;
    }
  }

  goTo(url: string): void {
    FormUtils.navigateTo(url);
  }

  ngOnDestroy() {
    /** */
  }
}
