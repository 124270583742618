<div class="jip-multi-link">
  <ng-container *ngFor="let item of items; index as i">
    <app-social-link-item
      [data]="item"
      [placeholder]="placeholder"
      [regex]="regex"
      [formControlLinkItem]="formControlSocialLink"
    >
      <div class="jip-multi-link__btn-manage">
        <button
          (click)="removeLink(item)"
          class="btn btn-black widget__btn-create-custom ml-1 ml-md-4 mt-2"
        >
          <i class="far fa-times"></i>
        </button>
      </div>
    </app-social-link-item>
  </ng-container>

  <app-social-link-item
    [data]="newLink"
    [placeholder]="placeholder"
    [regex]="regex"
    [addBtnClicked]="addBtnClicked"
    (addLinkEvent)="addLinkFromEvent()"
    [formControlLinkItem]="formControlSocialLink"
    [firstLoadInput]="firstAddItem"
  >
    <div class="jip-multi-link__btn-manage"></div>
  </app-social-link-item>
</div>
