<div class="moodboard-edit__group-title venture__label--head">
  {{ groupTitle }}
</div>
<div class="moodboard-edit__items">
  <div
    (click)="showImagesPopup(item)"
    *ngFor="let item of defaultMoodboardItems"
    class="moodboard-edit__item"
  >
    <i [class]="item.placeHolder"></i>
    <div
      class="moodboard-edit__image-wraper pointer-cursor"
      [ngbTooltip]="'MOOD-BOARD_Choose-image' | translate"
      tooltipClass="no-limit-width-tooltip"
      container="body"
    >
      <img
        *ngIf="item.selectedImg"
        [src]="item.selectedImg"
        appDefaultPlaceholder
        appDynamicImgUrl
      />
    </div>
    <span
      *ngIf="item.selectedImg"
      class="
        image-list__deleted-icon
        d-flex
        align-items-center
        justify-content-center
      "
      [ngbTooltip]="'MOOD-BOARD_Remove-image' | translate"
      container="body"
      (click)="removeImage(item, $event)"
    >
      <i class="fas fa-trash"></i>
    </span>
  </div>
</div>
