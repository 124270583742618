<ul class="list-inline d-flex venture__actions justify-content-start">
  <app-like-entity
    [entity]="message"
    (triggerToggleLike)="toggleLike(message)"
    (triggerShowLikingsData)="onLikesClicked(message)"
  ></app-like-entity>

  <li
    *ngIf="message?.replyCount > 0"
    class="list-inline-item venture__actions-item margin-left-percent-20"
    [ngClass]="{ 'disabled-input': isLoadingThreadMessage }"
    role="button"
    appRequireLogin
    appDebounceClick
    (debounceClick)="openMessageBack()"
  >
    <app-internal-icon
      [height]="20"
      [name]="'comment-dots'"
      [className]="'filter-gray'"
    ></app-internal-icon>
    <span class="venture__actions-label font-weight-500">
      <span class="text-hide-small">{{ 'Thread' | translate }}</span>
      <span [innerHTML]="message?.replyCount | parentheses"></span>
    </span>
  </li>
</ul>
