import { Injectable } from '@angular/core';
import { Validator, Validators } from '@angular/forms';
import { AttributeType } from '@shared/enums/attribute-type.enum';
import {
  AttributeDescription,
  EntityDescription,
  MetaInformation
} from '@shared/interfaces/attribute-description.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { SingleSelectionValidator, TextValidator } from '@src/app/shared/utils/form-fields-validation';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomFormService {
  entityDescription: EntityDescription;
  relatedEntitiesDescription: AttributeDescription[];
  customAttributeDescriptions: AttributeDescription[];

  subject = new BehaviorSubject(null);
  data = this.subject.asObservable();

  setControlConfig(descriptions: MetaInformation): void {
    this.entityDescription = descriptions?.entityDescription;
    this.relatedEntitiesDescription = descriptions?.relatedEntitiesDescription;
  }

  generateControlsConfig(requiredKey = []): { [key: string]: any } {
    return this.createControl(this.entityDescription?.attributeDescriptions, requiredKey);
  }

  getValidators(item: AttributeDescription, requiredKey = []): Validator[] {
    const validators = [];
    if (requiredKey.includes(item.propertyName) || item.required) {
      switch (item.attributeType) {
        case AttributeType.SingleSelection:
          validators.push(SingleSelectionValidator.required);
          break;

        case AttributeType.Boolean:
          validators.push(Validators.requiredTrue);
          break;

        default:
          validators.push(Validators.required);
          break;
      }

      if (
        item.attributeType === AttributeType.StringSingle ||
        item.attributeType === AttributeType.String
      ) {
        validators.push(TextValidator.notEmptyOrWhitespace);
      }
    }

    if (item.maximumLength > 0 && !item.isHtml) {
      validators.push(Validators.maxLength(item.maximumLength));
    }
    if (item.rangeMinimum > 0) {
      validators.push(Validators.min(item.rangeMinimum));
    }
    if (item.rangeMaximum > 0) {
      validators.push(Validators.max(item.rangeMaximum));
    }
    if (item.attributeType === AttributeType.String && item.regularExpression) {
      validators.push(Validators.pattern(item.regularExpression));
    }
    return validators;
  }

  getFieldOptions(key): AttributeDescription | null {
    return FormUtils.getFieldOptions(
      this.entityDescription?.attributeDescriptions,
      key
    );
  }

  getCustomAttributeFieldOptions(key): AttributeDescription | null {
    return FormUtils.getFieldOptions(
      this.customAttributeDescriptions,
      key
    );
  }

  //#region HANDLE CUSTOM ATTRIBUTE CONTROL
  setCustomAttributeControlConfig(customAttributeDescriptions: AttributeDescription[]): void {
    this.customAttributeDescriptions = customAttributeDescriptions;
  }
  //#endregion

  createControl(attrDescriptions: AttributeDescription[] = [], requiredKey = [], byPassValidator = false): { [key: string]: any } {
    const result = {};
    if (!!attrDescriptions && attrDescriptions.length > 0) {
      attrDescriptions.forEach((item) => {
        let value = null;
        if (item?.attributeType === AttributeType.Boolean) {
          value = item.defaultValue;
        }
        const validators = byPassValidator ? [] : this.getValidators(item, requiredKey);
        result[item.propertyName] = [value, validators];
      });
    }
    return result;
  }
}

