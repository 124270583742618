import { Component, Injector, Input } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { MessageTo } from '@src/app/shared/interfaces/message-to.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { SendMessageBoxBaseComponent } from '../send-message-box-base/send-message-box-base.component';
import { SessionService } from '@src/app/core/session.service';

@Component({
  selector: 'app-send-message-dialog',
  templateUrl: './send-message-dialog.component.html',
  providers: [
    { provide: SendMessageHttpService, useClass: SendMessageHttpService },
  ],
})
export class SendMessageDialogComponent extends SendMessageBoxBaseComponent {
  peopleURL = environment.jipUrl.people;
  @Input() receiverName: string;
  @Input() toPersonId: number;
  @Input() parentMessageId: number | null | undefined;

  sessionService: SessionService;

  constructor(
    public activeModal: NgbActiveModal,
    public unsavedFormCheckService: UnsavedFormCheckService,
    protected sendMessageHttpService: SendMessageHttpService,
    protected authenService: AuthenService,
    protected injector: Injector,
    private toastService: ToastService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authenService,
      sendMessageHttpService,
      injector
    );

    this.sessionService = injector.get(SessionService);
  }

  send(): void {
    this.isSubmitting = true;
    const message: MessageTo = {
      toId: this.toPersonId,
      content: this.content?.value,
      parentId: null,
      isSendMail: !!this.isSendMail?.value,
      imageId: this.imageId?.value,
    };

    this.sendMessageHttpService
      .send(message)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: ChatMessage | ApiResponse) => {
          const msgResponse = data as ChatMessage;
          if (msgResponse) {
            this.toastService.showSuccess('UI.Toast.MessageSuccessfullySent');
          }
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            console.log('error when sent message: ', error);
          }
          this.isSubmitting = false;
          this.activeModal.close(true);
        },
        error: (error) => {
          console.log('error when sent message: ', error);
        },
      });
  }
}
