import { Injectable } from '@angular/core';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import { Post } from '@shared/interfaces/post.interface';
import { Observable } from 'rxjs';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root',
})
export class NewsMessagesHttpService extends BaseHttpService<NewsMessageInterface> {
  getApiRootPath(): string {
    return 'news';
  }

  getCounts(): Observable<number> {
    const url = `${this.apiUrl}/counts`;
    return this.http.get<any>(url);
  }

  createPost(payload: Post): Observable<void> {
    return this.http.post<void>(this.apiUrl, payload);
  }

  editPost(id: number, payload: Post): Observable<void> {
    const dtoArray = [];

    for (const key of Object.keys(payload)) {
      dtoArray.push({
        value: payload[key],
        path: key,
        op: 'replace',
      });
    }

    return this.http.patch<void>(`${this.apiUrl}/${id}/post`, dtoArray);
  }

  tranformData(item: NewsMessageInterface): NewsMessageInterface {
    const countOfReply = item.replies?.length;
    if (item && countOfReply > 0) {
      item.latestMessage = item.replies[countOfReply - 1];
    }
    return item;
  }

  deletePost(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}/post`);
  }
}
