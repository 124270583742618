import { AttributeDescription } from '@shared/interfaces/attribute-description.interface';
import { VentureHttpService } from '@core/http/venture.http.service';
import { Component, OnInit } from '@angular/core';
import { VentureCardComponent } from '../venture-card/venture-card.component';

@Component({
  selector: 'app-venture-phase-card',
  templateUrl: './venture-phase-card.component.html',
})
export class VenturePhaseCardComponent extends VentureCardComponent implements OnInit {
  constructor(protected service: VentureHttpService) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get phaseAttrDescription(): AttributeDescription {
    return this.attributes?.Phase;
  }
}
