import { Component, forwardRef, Input } from '@angular/core';
import { BaseControlComponent } from '@shared/components/form-custom-attributes/base-control/base-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-mail-forwarding-checkbox',
  templateUrl: './mail-forwarding-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MailForwardingCheckboxComponent),
      multi: true,
    },
  ],
})
export class MailForwardingCheckboxComponent extends BaseControlComponent {
  @Input() alignLeft = false;
}
