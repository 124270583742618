<div class="modal-header">
  <h4 class="modal-title truncate-line-2" translate>{{ title }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-body-confirmation">
  <ng-container *ngIf="isHtmlMessage; else defaultView">
    <quill-view [content]="message" class="ql-editor-only-view"></quill-view>
  </ng-container>
  <ng-template #defaultView>
    {{ message | translate }}
  </ng-template>
</div>
<div class="modal-footer" *ngIf="shouldShowFooter">
  <div class="d-flex flex-column w-100">
    <ng-container *ngIf="checkboxConfirmMessage">
      <div class="d-flex justify-content-start mb-3">
        <app-check-box
          [elementId]="'checkboxConfirm'"
          [content]="checkboxConfirmMessage"
          (selectItem)="setCheckboxValue($event)"
        ></app-check-box>
      </div>
    </ng-container>
    <div class="d-flex flex-row justify-content-center">
      <button
        type="button"
        class="btn"
        [class.btn-outline-primary]="!isLeaveSite"
        [class.btn-primary]="isLeaveSite"
        (click)="cancel()"
        translate
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn ml-4"
        [class.btn-primary]="!isLeaveSite"
        [class.btn-outline-primary]="isLeaveSite"
        (click)="confirm()"
        [disabled]="checkboxConfirmMessage && !isConfirmMessage"
        translate
      >
        {{ confirmLabel }}
      </button>
    </div>
  </div>
</div>
