<div
  class="d-flex justify-content-center align-items-center h-100 internal-icon-container"
>
  <img
    class="position-relative internal-icon"
    appDefaultPlaceholder
    appDynamicImgUrl
    [src]="iconSrc"
    [style.height.px]="height"
    [style.min-width.px]="minWidth ? minWidth : height"
    [class]="className"
    [style.width.px]="width"
  />

  <span *ngIf="hasFilter" class="news__badge"></span>
</div>
