import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';
import { IconType, InternalIcon } from '@shared/enums/internal-icon.enum';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html'
})
export class LoadingIndicatorComponent implements OnInit {
  isShownGlobalLoading = false;

  InternalIcon = InternalIcon;
  IconType = IconType;

  constructor(
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingService.getLoadingState().subscribe(
      (isLoading: boolean) => {
        this.isShownGlobalLoading = isLoading;
      });
  }
}
