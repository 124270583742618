import { Pipe, PipeTransform } from '@angular/core';
import {
  entityVisibilityConfig,
  PropertyName,
  TemplateName,
} from '@shared/constants/visibility-config.const';

@Pipe({
  name: 'visibility',
})
export class VisibilityPipe implements PipeTransform {
  transform(templateName: TemplateName, attributeName: PropertyName): boolean {
    if (!templateName) return true;

    return (
      entityVisibilityConfig[templateName][attributeName] ??
      entityVisibilityConfig[templateName].default
    );
  }
}
