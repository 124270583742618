import { Component, OnInit } from '@angular/core';
import { EventInterface } from '@shared/interfaces/event.interface';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { environment } from '@src/environments/environment';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
})
export class EventCardComponent extends BaseCardComponent<EventInterface> implements OnInit {
  DateFormat = DateFormat;

  constructor(
    protected service: EventHttpService,
  ) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEntityDetailPageUrl(environment.jipUrl.event);
  }

  get languageValue(): string {
    let result = '';
    if (this.data && this.data.language && this.attributes) {
      const language = this.data.language;

      if (typeof language === 'string') {
        const options = this.attributes.Language.choice?.selections;
        result = options?.find(x => x.codeId === language)?.value || '';
      }
      else {
        result = language.value;
      }
    }
    return result;
  }
}
