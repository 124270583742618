export enum ChallengeTimelinePhase {
  Submission = 'Submission',
  JointCreating = 'JointCreating',
  Evaluation = 'Evaluation',
  Awarding = 'Awarding',
  Closing = 'Closing',
}

export enum ChallengeTimelineAttributeName {
  Name = 'Name',
  PhaseDate = 'PhaseDate',
}
