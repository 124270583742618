import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-wrapper[textHeader]',
  templateUrl: './modal-wrapper.component.html',
})
export class ModalWrapperComponent {
  @Input() textHeader: string;
  @Input() textOk = 'OK';
  @Input() textCancel = 'Cancel';
  @Input() disabled: boolean;
  @Input() isSubmitting: boolean;

  @Output() btnOkClicked = new EventEmitter();
  @Output() btnCancelClicked = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  clickBtnOk(): void {
    this.btnOkClicked.emit();
  }

  cancelBtnOk(): void {
    this.btnCancelClicked.emit();
  }
}
