import { Version } from '@src/app/core/services/application.service';
import {
  CustomAtrributeValue,
  LinkInterface,
} from './attribute-description.interface';
import { BaseEntityEditInterface } from './base/base-entity-edit.interface';
import { ImageInterface } from './file.interface';

export interface TenantInterface extends BaseEntityEditInterface {
  name: string;
  domain: LinkInterface;
  dataSegregationModel: CustomAtrributeValue;
  logo: ImageInterface;
  companyName: string;
  companyAddress: string;
  companyURL: LinkInterface;
  welcomeImage: ImageInterface;
  welcomeMessage: string;
  contactPerson: string;
  contactEmail: string;
  contactMobilePhone: string;
  copyright: string;
  termsOfServiceUrl: string;
  termsOfService: string;
  activated: boolean;
  billingInformations: BillingInformation[];
  languages: Language[];
  systemInfomation: Version;
  defaultOrganizationLogo: ImageInterface;
  defaultOrganizationBanner: ImageInterface;
  defaultChallengeLogo: ImageInterface;
  defaultChallengeBanner: ImageInterface;
  enableCommunication: boolean;
}

export interface BillingInformation {
  id: number;
  contactName: string;
  streetAddress: string;
  streetAddressContinued: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  tenantId: number;
}

export interface Language {
  id: number;
  locale: string;
  code: string;
  name: string;
  activated: boolean;
  isDefault: boolean;
  order: number;
  tenantId: number;
}

export enum TenantConfigurationSectionIndex {
  TenantAttributes,
  TenantEmailConfiguration,
  TenantCommunicationSettings
}

export const TENANT_ATTRIBUTES_SECTION_FIELDSNAME = [
  'Id',
  'Name',
  'Domain',
  'DataSegregationModel',
  'DatabaseConnectionString',
  'Logo',
  'CompanyName',
  'CompanyAddress',
  'CompanyURL',
  'WelcomeImage',
  'WelcomeMessage',
  'ContactPerson',
  'ContactEmail',
  'ContactMobilePhone',
  'Copyright',
  'TermsOfServiceUrl',
  'TermsOfService',
  'SendEmail',
  'DefaultOrganizationLogo',
  'DefaultOrganizationBanner',
  'DefaultChallengeLogo',
  'DefaultChallengeBanner',
];

export const TENANT_EMAIL_CONFIGURATION_FIELDNAMES = [
  'EmailSMTPServer',
  'EmailSMTPPort',
  'EmailSMTPPassword',
  'EmailSMTPUserName',
  'EmailSMTPEnableTsl',
  'EmailSalutationMessage',
];

export const TENANT_DEFAULT_LOGO_PROPERTY = [
  'DefaultOrganizationLogo',
  'DefaultChallengeLogo',
];
export const TENANT_DEFAULT_BANNER_PROPERTY = [
  'DefaultOrganizationBanner',
  'DefaultChallengeBanner',
];

export const TENANT_COMMUNICATION_SETTINGS = [
  'EnableCommunication'
]