import { PeopleInterface } from '@shared/interfaces/people.interface';
import { Component, OnInit } from '@angular/core';
import { environment } from '@src/environments/environment';
import { BaseCardComponent } from '../base-card/base-card.component';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';

@Component({
  selector: 'app-person-card',
  templateUrl: './person-card.component.html',
})
export class PersonCardComponent extends BaseCardComponent<PeopleInterface> implements OnInit {

  constructor(protected service: PeopleHttpService) {
    super(service);
  }

  ngOnInit(): void {
    this.getEntityDetailPageUrl(environment.jipUrl.people);
  }
}
