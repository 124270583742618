<div class="range-slider align-items-center d-flex w-100 mt-3">
  <button
    (click)="onZoom(-STEP)"
    [class.disabled]="value <= roundNumber(RANGE[0] + 1)"
    class="btn btn-primary rounded-circle flex-shrink-0 p-0 mr-2"
  >
    <i class="fas fa-minus"></i>
  </button>

  <input
    #rangeInput
    (input)="onValueChange(+rangeInput.value)"
    [max]="RANGE[1]"
    [min]="RANGE[0]"
    [step]="STEP"
    [value]="value - 1"
    class="flex-grow-1 mx-1"
    type="range"
  />

  <button
    (click)="onZoom(STEP)"
    [class.disabled]="value >= RANGE[1] + 1"
    class="btn btn-primary rounded-circle flex-shrink-0 p-0 ml-2"
  >
    <i class="fas fa-plus"></i>
  </button>
</div>
