import { Injectable } from '@angular/core';
import { CommentInterface } from '@src/app/shared/interfaces/comment.interface';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class CommentHttpService extends BaseHttpService<CommentInterface> {
  getApiRootPath(): string {
    return 'comments';
  }
}
