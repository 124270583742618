<div *ngIf="editable; else formatedTime" class="form-group mb-0">
  <div class="input-w-datepicker input-group">
    <input
      class="input-w-datepicker__input form-control"
      [(ngModel)]="date"
      (ngModelChange)="updateDateTime()"
      name="dp"
      ngbDatepicker
      [placeholder]="placeholder || ''"
      [footerTemplate]="footerRef"
      [placement]="placement"
      [positionTarget]="buttonEl"
      [autoClose]="hideTimeOfDate"
      #datePicker="ngbDatepicker"
      [disabled]="!editable"
      [minDate]="minTime"
      [maxDate]="maxTime"
      readonly
      [id]="inputId"
    />

    <div class="input-group-append" [class.hidden]="!editable">
      <button
        #buttonEl
        class="input-w-datepicker__btn btn btn-outline-secondary"
        (click)="datePicker.toggle()"
        type="button"
      >
        <em class="input-w-datepicker__icon fas fa-calendar-alt"></em>
      </button>
    </div>
  </div>
</div>

<ng-template #formatedTime>
  <span>{{ getFormatedTime() }}</span>
</ng-template>

<ng-template #footerRef>
  <div *ngIf="editable" class="datepicker__footer mt-1">
    <ngb-timepicker
      *ngIf="!hideTimeOfDate"
      [(ngModel)]="time"
      (ngModelChange)="updateDateTime()"
      [meridian]="true"
      [disabled]="!editable"
    >
    </ngb-timepicker>

    <div class="datepicker__btn-group mt-2">
      <button
        *ngIf="isOptional"
        class="btn btn-outline-primary"
        (click)="clear()"
        type="button"
        translate
      >
        Clear
      </button>

      <ng-container *ngIf="!hideTimeOfDate">
        <button
          class="btn btn-outline-primary"
          (click)="cancel()"
          type="button"
          translate
        >
          Cancel
        </button>

        <button
          class="btn btn-primary"
          (click)="confirm()"
          type="button"
          translate
          [disabled]="!timeIsDifferent"
        >
          Ok
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
