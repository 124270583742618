import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplacement',
})
export class StringReplacementPipe implements PipeTransform {
  transform(value: string, args: { [key: string]: string } = null): string {
    if (
      !value ||
      !args ||
      typeof value !== 'string' ||
      typeof args !== 'object'
    ) {
      return value;
    }
    let result = value;

    for (const key in args) {
      if (args.hasOwnProperty(key)) {
        // replace all
        result = result.replace(new RegExp(key, 'g'), args[key]);
      }
    }

    return result;
  }
}
