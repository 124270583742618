import { Component, Input } from '@angular/core';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';

@Component({
  selector: 'app-widget-metadata-table',
  templateUrl: './widget-metadata-table.component.html',
})
export class WidgetMetadataTableComponent {
  @Input() domain: string;
  @Input() orgId: number;
  @Input() entityName: string;
  @Input() editable = 'false'; // Elementor control only can pass string

  EntityName = EntityName;
}
