import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @Input() disableHoverForSmallText = true;
  @Input() toolTipControl: NgbTooltip;

  @Input() hoverClass = 'pointer-cursor';

  constructor(
    public renderer: Renderer2,
    private hostElement: ElementRef<HTMLElement>
  ) { }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.hostElement && this.hostElement.nativeElement) {
      const element = this.hostElement.nativeElement;
      if (this.hasTruncated()) {
        this.renderer.addClass(element, this.hoverClass);
      }
    }
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (this.hostElement && this.hostElement.nativeElement) {
      const element = this.hostElement.nativeElement;
      this.renderer.removeClass(element, this.hoverClass);
    }
  }

  @HostListener('window:scroll') onScroll(): void {
    if (this.toolTipControl instanceof NgbTooltip) {
      this.toolTipControl.close();
    }
  }

  @HostListener('click', ['$event']) onClick(event: any): void {
    if (this.disableHoverForSmallText && !this.hasTruncated()) {
      return;
    }

    if (
      this.toolTipControl &&
      typeof this.toolTipControl.toggle === 'function'
    ) {
      this.toolTipControl.toggle();
    }
    event.preventDefault();
    event.stopPropagation();
  }

  public hasTruncated(): boolean {
    const element = this.hostElement.nativeElement;

    return (
      element.clientHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth
    );
  }
}
