import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
})
export class NotificationComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() title = '';
  @Input() message = '';
  @Input() isHtmlMessage = false;
  @Input() btnText = '';

  constructor(public activeModal: NgbActiveModal) { }
}
