import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { AuthenService } from '@core/authen/authen.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { EntityCreationModalService } from '@core/services/entity-creation-modal.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import {
  EntityName,
  EntityStateCount,
  MessageAction,
} from '@shared/enums/entity-name.enum';
import { NewsMessagesFilterType } from '@shared/enums/filter.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { News } from '@shared/interfaces/news.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { CrossSearchingEntity } from '@shared/models/cross-searching-entity.model';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { applyFilterByWidgetConfig } from '@shared/utils/filter.utils';
import { FormUtils } from '@shared/utils/form-utils';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { Observable } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';

const { jipUrl, wordpressJipImages } = environment;

@Component({
  selector: 'app-news-tiles',
  templateUrl: './news-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class NewsTilesComponent
  extends EntityStateCountComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() domain: string;

  @Input() columns: string;

  // tslint:disable-next-line:variable-name
  @Input() page_size: '2' | '3';

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_paginator: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_creation_btn: boolean;

  // tslint:disable-next-line:variable-name
  @Input() bg_color: string;

  // tslint:disable-next-line:variable-name
  @Input() org_id: string;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() is_open_in_new_tab: boolean;

  // tslint:disable-next-line:variable-name
  @Input() redirect_url: string;

  // tslint:disable-next-line:variable-name
  @Input() filter_selection: string;

  data$: Observable<ApiGetResponse<News>>;
  isLoading = false;
  skeletonCount = [];

  readonly entityPath = jipUrl.news;

  readonly entityCreationPath = `${this.entityPath}/create`;

  readonly user$: Observable<UserInterface> = this.authService
    .getProfile()
    .pipe(share());

  readonly MessageAction = MessageAction;

  constructor(
    public readonly listService: NewsMessagesHttpService,
    public readonly searchEntitiesService: SearchEntitiesService,
    public readonly authService: AuthenService,
    protected readonly sessionService: SessionService,
    private readonly entityCreationModalService: EntityCreationModalService
  ) {
    super();

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: CrossSearchingEntity[]) => arr?.length > 0),
      switchMap((res: CrossSearchingEntity[]) => res[0].content),
      map((res: ApiGetResponse<News>) => {
        this.runEntityStateCount(EntityName.News, res.items, this.listService);

        return res;
      }),
      share()
    );
    
    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.domain?.currentValue && this.domain) {
      this.sessionService.setDomain(this.domain);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.listenPostCreated();
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  syncCommentStateCount(news: News): void {
    this.syncEntityStateCount(
      EntityStateCount.Comment,
      EntityName.News,
      [news],
      this.listService
    );
  }

  goToListPage(): void {
    FormUtils.openWithLanguagePath(
      this.sessionService,
      this.redirect_url || this.entityPath,
      this.is_open_in_new_tab ? '_blank' : '_self'
    );
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncCommentsState();
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.News }];
    this.searchEntitiesService.currentFilter = EntityName.News;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter =
      !this.org_id && this.sessionService.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.filterType = NewsMessagesFilterType.News;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];
    applyFilterByWidgetConfig(this.filter_selection, filterDTO);

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  private listenPostCreated(): void {
    this.entityCreationModalService.newsMessageCreated$
      .pipe(
        filter((entityName: EntityName) => entityName === EntityName.Post),
        untilDestroyed(this)
      )
      .subscribe(() => this.searchEntitiesService.refreshList());
  }
}
