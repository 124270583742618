<div *ngIf="source" class="image-input-display w-100 p-2 mt-2 border">
  <div
    *ngIf="uploading"
    style="height: 50px"
    class="w-100 card-background loading-skeleton"
  ></div>

  <div class="d-flex justify-content-between" *ngIf="!uploading">
    <div class="flex-grow-1 d-flex align-items-center gap-16">
      <img
        class="image-thumbnail flex-shrink-0"
        [src]="source"
        alt="message image"
      />
      <span class="file-name flex-grow-1">
        {{ fileName }}
      </span>
    </div>
    <button
      type="button"
      class="btn-close close flex-shrink-0"
      (click)="clearImage()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
