import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { FormUtils, FORM_ERROR_KEY } from '@shared/utils/form-utils';
import { StringUtils } from '@shared/utils/string-utils';

@Injectable({
  providedIn: 'root',
})
export class FormErrorService {
  component: FormComponentInterface;
  form: UntypedFormGroup;
  constructor(private translateService: TranslateService) { }

  register(component: FormComponentInterface): void {
    this.component = component;
    this.component.errorMessage = {};
    this.component.formErrorKey = FORM_ERROR_KEY;

    this.form = this.component.getForm();

    if (this.form) {
      this.form?.valueChanges.subscribe(() => {
        FormUtils.removeError(this.form, 'formError');
        this.component.isSubmitting = false;
        this.component.isSubmitted = false;

        this.component.errorMessage[FORM_ERROR_KEY] = null;
      });

      for (const key of Object.keys(this.form.controls)) {
        const control = this.form.controls[key];
        control.valueChanges.subscribe(() => {
          FormUtils.removeError(control, 'jip_' + key);
          this.component.errorMessage[key] = null;
        });
      }
    }
  }

  handleError(data: ApiResponse): string {
    if (!data) {
      return '';
    }

    const errorMessage = FormUtils.getErrorObject(data);

    if (errorMessage?.hasOwnProperty(FORM_ERROR_KEY)) {
      this.form?.setErrors({ [FORM_ERROR_KEY]: true });
    } else {
      let found = false;

      for (const key of Object.keys(errorMessage)) {
        if (key) {
          const message = errorMessage[key];
          const lowerKey = StringUtils.toLowerCaseFirstLetter(key);
          const control = this.form?.get(lowerKey);
          if (control) {
            control.setErrors({ ['jip_' + lowerKey]: true });
            found = true;
            if (this.component.errorMessage) {
              this.component.errorMessage[lowerKey] = message;
            }
          }
        }
      }

      if (!found) {
        this.form?.setErrors({ [FORM_ERROR_KEY]: true });
      }
    }

    this.component.errorMessage = errorMessage;

    const returnErrorArr = Object.values(errorMessage)?.map(
      (errorData: any) => {
        return (typeof errorData === 'object' && Array.isArray(errorData)) ? errorData.join(', ') : errorData;
      }
    );

    return returnErrorArr?.join(' - ');
  }
}
export interface FormComponentInterface {
  formErrorKey: string;
  errorMessage: { [key: string]: string };
  isSubmitting?: boolean;
  isSubmitted?: boolean;
  getForm(): UntypedFormGroup;
}
