<div class="jip-multi-link__item d-flex align-items-start">
  <div class="jip-multi-link__prefix jip-multi-link__prefix--editable">
    <app-social-link-figure
      [data]="data"
      [editable]="true"
      (changeSrcUrl)="changeSourceUrl($event)"
    ></app-social-link-figure>
  </div>

  <div class="jip-multi-link__input-container mr-3">
    <input
      #nameInput="ngModel"
      name="socialMediaName"
      [(ngModel)]="data.socialMediaName"
      type="text"
      class="form-control mr-4"
      [placeholder]="'Name' | translate"
      (keydown.enter)="addLink($event, nameInput, linkInput)"
      (ngModelChange)="isValid([nameInput])"
      maxlength="512"
      #inputName
      required
      ngDefaultControl
    />
    <div
      *ngIf="!firstLoadInput && nameInput?.invalid && nameInput?.control.dirty"
      class="alert alert-danger"
      translate
    >
      Field is required.
    </div>
  </div>

  <div class="jip-multi-link__input-container">
    <input
      #linkInput="ngModel"
      [(ngModel)]="data.link"
      name="link"
      type="text"
      class="form-control"
      [placeholder]="placeholder"
      [pattern]="regex"
      (keydown.enter)="addLink($event, nameInput, linkInput)"
      (ngModelChange)="isValid([linkInput]) ? onLinkChange($event) : null"
      maxlength="512"
      required
      ngDefaultControl
    />
    <div
      *ngIf="!firstLoadInput && linkInput?.invalid && linkInput?.dirty"
      class="alert alert-danger"
      translate
    >
      UI.Common.Url-Hint
    </div>
  </div>

  <ng-content></ng-content>
</div>
