import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-organization-logo',
  templateUrl: './organization-logo.component.html',
})
export class OrganizationLogoComponent {
  @Input() organizationLogo: string;

  @Input() alt = '';

  @Input() filterTokenUrl = '';

  @Input() showCopyText = false;

  @Input() windowOrigin: string = window.origin;
}
