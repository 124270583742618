import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EntityStateCountComponent } from '@shared/components/entity-state-count/entity-state-count.component';
import { LikeModalComponent } from '@shared/components/like-modal/like-modal.component';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { CommentHttpService } from '@src/app/core/http/comment-http.service';
import { EntityStateCount } from '@src/app/shared/enums/entity-name.enum';
import { CommentInterface } from '@src/app/shared/interfaces/comment.interface';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
})
export class CommentsComponent extends EntityStateCountComponent implements OnChanges, OnDestroy {
  @Input() comments: CommentInterface[] = [];
  @Output() triggerDeleteComment = new EventEmitter<number>();
  @Output() triggerEditComment = new EventEmitter<CommentInterface>();

  constructor(
    private commentService: CommentHttpService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments && changes.comments.currentValue) {
      this.runEntityStateCount(EntityStateCount.Comment, this.comments, this.commentService);
    }
  }

  toggleLike(comment: CommentInterface): void {
    if (!comment.isLiked) {
      this.commentService
        .like(comment.id)
        .pipe(untilDestroyed(this))
        .subscribe((newLikeId: number) => {
          comment.isLiked = !comment.isLiked;
          comment.likesCount = comment.likesCount !== undefined ? comment.likesCount + 1 : 1;
          comment.likeId = newLikeId;
        });
    } else {
      this.commentService.unlike(comment.likeId)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          comment.isLiked = !comment.isLiked;
          comment.likesCount = comment.likesCount > 0 ? comment.likesCount - 1 : 0;
          comment.likeId = null;
        });
    }
  }

  openLikeModal(comment: CommentInterface): void {
    const modalRef = this.modalService.open(LikeModalComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
      scrollable: false,
    });

    modalRef.componentInstance.entityId = comment.id;
    modalRef.componentInstance.modalTitle = this.translateService.instant('UI.Likes.Comment');
    modalRef.componentInstance.httpService = this.commentService;
  }

  commentTrackBy(index: number, comment: CommentInterface): number {
    return comment.id;
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
  }

  ngOnDestroy(): void { /**/ }
}
