<div class="card p-4">
  <div>
    <app-reporting-chart
      #reportingChart
      [entity_id]="tenantId"
      [title]="entityType"
      [entity_type]="entityType"
      [showExportButton]="true"
      [exportReportName]="reportName"
    ></app-reporting-chart>
  </div>
</div>
