import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  template: '',
})
export class BaseControlComponent<T = unknown> implements ControlValueAccessor {
  @Input() placeholder: string;

  @Input() label: string;

  value: T;

  disabled = false;

  protected onWriteValue$ = new Subject<T>();

  private onChange: (value: T) => void;

  private onTouched: () => void;

  writeValue(value: T): void {
    this.value = value;
    this.onWriteValue$.next(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValueChange(value: T): void {
    if (this.onChange) {
      this.onChange(value);
      this.writeValue(value);
    }
  }
}
