import { Directive, DoCheck, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPagingItem]',
})
export class PagingItemDirective implements DoCheck {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngDoCheck() {
    this.bindPagingButtonToCentralConfig();
  }

  bindPagingButtonToCentralConfig(): void {
    const customClass = 'btn-paging-primary';
    const pagingItems = this.el.nativeElement.querySelectorAll('.page-item');

    pagingItems.forEach((item) => {
      this.renderer.addClass(item, customClass);
    });
  }
}
