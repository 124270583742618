import { Injectable } from '@angular/core';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { Entity } from '@src/app/shared/models/entity.model';
import { BaseHttpService } from '../http/base-http.service';
import { ChallengeHttpService } from '../http/challenge-http.service';
import { EventHttpService } from '../http/event-http.service';
import { NewsMessagesHttpService } from '../http/news-messages-http.service';
import { OrganizationHttpService } from '../http/organization-http.service';
import { PeopleHttpService } from '../http/people-http.service';
import { VentureHttpService } from '../http/venture.http.service';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {
  constructor(
    private ventureHttpService: VentureHttpService,
    private eventHttpService: EventHttpService,
    private organizationHttpService: OrganizationHttpService,
    private challengeHttpService: ChallengeHttpService,
    private peopleHttpService: PeopleHttpService,
    private newsMessagesHttpService: NewsMessagesHttpService,
    private baseHttpService: BaseHttpService<Entity>
  ) { }

  createServiceByEntityName(entityName: EntityName): BaseHttpService<Entity> {
    switch (entityName) {
      case EntityName.Venture:
        return this.ventureHttpService;

      case EntityName.Organization:
        return this.organizationHttpService;

      case EntityName.Challenge:
        return this.challengeHttpService;

      case EntityName.Event:
        return this.eventHttpService;

      case EntityName.Person:
        return this.peopleHttpService;

      case EntityName.News:
      case EntityName.Message:
        return this.newsMessagesHttpService;

      default:
        return this.baseHttpService;
    }
  }
}
