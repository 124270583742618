import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity } from '@shared/models/entity.model';

@Component({
  selector: 'app-follow-interact',
  templateUrl: './follow-interact.component.html',
})
export class FollowInteractComponent {
  @Input() entity: Entity;
  @Input() hasBigSize = false;
  @Input() uiTemplate: 'button' | 'icon' = 'button'
  @Output() followClicked = new EventEmitter<Entity>();

  toggleFavorite(entity: Entity): void {
    if (entity) {
      this.followClicked.emit(entity);
    }
  }
}
