import { HttpParams } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { LikeInterface } from '@shared/interfaces/like.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-likes-modal',
  templateUrl: './likes-modal.component.html',
})
export class LikesModalComponent implements OnChanges, OnDestroy {
  @ViewChild('modalRef') modalRef: ElementRef;

  @Input() modalTitle = '';
  @Input() entityId: number;
  @Input() entityTitle: string;
  @Input() httpService: BaseHttpService<any>;
  @Input() ignoreMapModal = false;

  @Output() dismissModal = new EventEmitter<any>();

  avatarUrl = environment.wordpressJipImages + 'avatar.png';
  people: UserInterface[] = [];

  // Pagination
  page = 1;
  pageSize = 9;
  collectionSize = 0;

  isLoading = false;

  constructor(private modalService: NgbModal) {}

  private openModal(): void {
    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        scrollable: true,
        windowClass: 'people__modal',
      })
      .result.then(
        (result) => {
          this.entityId = null;
          this.people = [];
        },
        (reason) => {
          this.entityId = null;
          this.people = [];

          this.dismissModal.emit();
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entityId && this.httpService) {
      this.openModal();
      this.paginateLikes();
    }
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateLikes();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.paginateLikes();
  }

  private paginateLikes(): void {
    this.isLoading = true;
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    this.httpService
      .paginateLikes(this.entityId, params)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (res: ApiGetResponse<LikeInterface>) => {
          if (this.ignoreMapModal) {
            this.people = res?.items as any;
          } else {
            this.people = res?.items.map((item) => item.owner);
          }
          this.collectionSize = res?.rowCount;
        },
        error: () => {
          this.dismissModal.emit();
        },
      });
  }

  ngOnDestroy(): void {
    /* */
  }
}
