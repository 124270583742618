import { Component, HostListener, OnInit } from '@angular/core';
import { I18nService } from '@core/i18n/i18n.service';
import { LanguageInterface } from '@shared/interfaces/language.interface';
import { MediaBreakpoint } from '@shared/models/ui.model';
import { FormUtils } from '@shared/utils/form-utils';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
})
export class LanguagesComponent implements OnInit {
  languages: LanguageInterface[];
  limit = 2;
  showAsDropdown = false;

  get currentLanguage(): LanguageInterface {
    return this.i18nService.language;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.showAsDropdown =
      !this.isSmallScreen() && this.languages?.length > this.limit;
  }

  constructor(private i18nService: I18nService) {}

  ngOnInit(): void {
    this.getLanguages();
  }

  setLanguage(language: LanguageInterface): void {
    const destinationHref = this.getDestination(language);
    this.i18nService.language = language;

    FormUtils.navigateTo(destinationHref);
  }

  getDestination(newLanuge: LanguageInterface): string {
    const oldLanguage = this.i18nService.language?.locale;
    const newLanguage = newLanuge?.locale;
    const currentUrl = location.href;
    return currentUrl.replace(
      `/${oldLanguage?.toLowerCase()}/`,
      `/${newLanguage?.toLowerCase()}/`
    );
  }

  private getLanguages(): void {
    this.i18nService.supportedLanguages$.subscribe((items) => {
      this.languages = items;
      this.showAsDropdown = !this.isSmallScreen() && items?.length > this.limit;
    });
  }

  private isSmallScreen(): boolean {
    return window.innerWidth <= MediaBreakpoint.sm;
  }
}
