import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
// app
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { QrCodeModalComponent } from '@src/app/shared/components/qr-code-modal/qr-code-modal.component';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { placeholderImg } from '../../constants/common';
@Component({
  selector: 'app-image-wrapper',
  templateUrl: './image-wrapper.component.html',
})
export class ImageWrapperComponent implements OnChanges {
  @Input() imgObject: ImageInterface | any;
  @Input() imgAlt: string;
  @Input() wrapperClasses = 'widget__image-wrapper entity__image-wrapper';
  @Input() imageclasses = 'widget__image entity__image';
  @Input() placeholderImg = placeholderImg;
  @Input() isVCardShown = false;
  @Input() vCardImg = '';
  @Input() titleTemplateRef?: TemplateRef<any>;
  @Input() getEntityDataCallback?: () => Observable<any>;

  @Output() imageChanged = new EventEmitter();

  isFirstChanged = true;

  url = null;
  tooltip = 'UI.Image-Wrapper.Tooltip';
  tenantName = '';

  constructor(
    private modalService: NgbModal,
    private cookieService: CookieService
  ) {
    this.tenantName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgObject) {
      this.updateUrlByLocale();
    }
  }

  updateUrlByLocale(): void {
    if (this.imgObject?.url) {
      this.setUrl(this.imgObject?.url);
    } else {
      this.setUrl(this.imgObject); // string
    }
  }

  setUrl(url: string): void {
    this.url = typeof url === 'string' ? url : null;
    this.imageChanged.next(this.url);
    if (!this.url) return;
    this.isFirstChanged = false;
  }

  /* Refactor later
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgObject) {
      const urlValue = this.imgObject?.url || this.imgObject;
      this.url = typeof urlValue === 'string' ? urlValue : null;
      this.imageChanged.next(this.url);
    }
  }
  */

  handleQrCodeModal(): void {
    const modalRef = this.modalService.open(QrCodeModalComponent, {
      centered: true,
      windowClass: 'bootstrap-modal-custom',
    });

    modalRef.componentInstance.getEntityDataCallback =
      this.getEntityDataCallback;
    modalRef.componentInstance.titleTemplateRef = this.titleTemplateRef;
  }
}
