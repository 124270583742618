<div class="modal-header">
  <h4 class="modal-title">{{ title | translate }}</h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="customUserRoles.length > 0">
    <div class="form-group mb-3">
      <label class="widget__label">
        {{ 'UI.Common.SelectRole' | translate }}:
      </label>
      <app-boostrap-dropdown
        class="dropdown--full-width truncate-selection"
        [items]="customUserRoles"
        [(currentIndex)]="currentRoleIndex"
      >
      </app-boostrap-dropdown>
    </div>
  </ng-container>

  <div class="form-group">
    <label class="widget__label">
      {{ 'UI.MessageOptional' | translate }}:
    </label>
    <textarea
      class="form-control"
      [(ngModel)]="message"
      [placeholder]="placeholder | translate"
      [rows]="6"
    ></textarea>
  </div>
</div>
<div class="modal-footer message-command">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="close()"
    translate
  >
    Cancel
  </button>
  <button
    appRequireLogin
    [disabled]="isSending"
    type="button"
    ngbAutofocus
    class="btn btn-primary"
    (click)="send()"
  >
    <ng-container *ngIf="isSending">
      <i class="fas fa-spinner fa-spin btn-icon-right"></i>
    </ng-container>
    <span>{{ 'UI.Common.Request' | translate }}</span>
  </button>
</div>
