import { Pipe, PipeTransform } from '@angular/core';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';

@Pipe({
  name: 'eventRegisterTooltip',
})
export class EventRegisterTooltipPipe implements PipeTransform {
  transform(event: EventInterface): string {
    if (event.isCancelled) {
      return 'UI.Event.Cancelled';
    } else {
      if (event.isPassedEvent) {
        return 'UI.Event.AlreadyPassed';
      } else if (event.isFullyBooked) {
        return 'UI.Event.FullyBooked';
      } else {
        if (event.showExternalRegistration) {
          return 'UI.Event.ExternalRegistration';
        }
        return 'UI.Event.Register';
      }
    }
  }
}

@Pipe({
  name: 'eventRegisterBtnDisabled',
})
export class EventRegisterBtnDisabledPipe implements PipeTransform {
  transform(event: EventInterface): boolean {
    return event.isFullyBooked || event.isPassedEvent || event.isCancelled;
  }
}