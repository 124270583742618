import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageEnum } from '@shared/enums/storage.enum';
import { RegisterEventPayload } from '@shared/interfaces/request/register-event.interface';
import {
  TokenValidation,
  VerifyInvititationsPayload,
} from '@shared/interfaces/token-validation';
import { UserInterface } from '@shared/interfaces/user.interface';

import { StringUtils } from '@shared/utils/string-utils';
import { TimeUtils } from '@shared/utils/time-utils';
import { EnforceProfileDialogComponent } from '@src/app/components/dialogs/enforce-profile-dialog/enforce-profile-dialog.component';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import {
  ChangePasswordPayload,
  LoginPayload,
  RegisterPayload,
} from '@src/app/shared/interfaces/request/authen.interface';
import { ApiResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { RegisterResponse } from '@src/app/shared/models/authen.model';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenService {
  baseUrl = 'accounts';
  isLogin$ = new BehaviorSubject<boolean>(false);
  profile$ = new BehaviorSubject<UserInterface>(null);
  accessToken: string;

  PROFILE_REQUIRED_FIELDS = ['JobTitle', 'LeadCompany'];

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private modal: NgbModal
  ) {
    this.cookieService.delete(StorageEnum.accessToken, '/jip-login');

    this.accessToken = this.getAccessToken();
    this.isLogin$.next(!!this.accessToken);
  }

  getAccessToken(): string {
    let token = '';
    const expiredTime = this.cookieService.get(StorageEnum.expiredTokenTime);

    if (
      Number(expiredTime) >
      TimeUtils.convertUtcToLocalTimeInSeconds(new Date(), 60)
    ) {
      token = this.cookieService.get(StorageEnum.accessToken);
    }

    return token;
  }

  updateProfileInfo(allowEnforceProfile = false): void {
    if (!this.accessToken) {
      return;
    }

    combineLatest([this.getUserAttribute(), this.getProfileInfo()]).subscribe(
      ([attributeDescriptions, profile]: [
        AttributeDescription[],
        UserInterface
      ]) => {
        const showEnforceDialog = this.shouldShowEnforceDialog(
          attributeDescriptions,
          profile
        );

        this.profile$.next(profile);
        this.handleCheckLastLoggedinUser(profile);

        if (allowEnforceProfile && showEnforceDialog) {
          const modalRef = this.modal.open(EnforceProfileDialogComponent, {
            centered: true,
            backdrop: 'static',
            size: 'md',
            modalDialogClass: 'enforce-profile-modal',
            keyboard: false
          });

          modalRef.componentInstance.id = profile.id;
        }
      },
      (error) => {
        if (error.status !== 0) {
          this.resetSession();
          location.reload();
        }
      }
    );
  }

  shouldShowEnforceDialog(
    attributeDescriptions: AttributeDescription[],
    profile: UserInterface
  ): boolean {
    let showProfileEnforcementDialog = false;

    this.PROFILE_REQUIRED_FIELDS.forEach((key) => {
      const attributeDescription = FormUtils.getFieldOptions(
        attributeDescriptions,
        key
      );

      if (attributeDescription.required) {
        if (key === 'LeadCompany') {
          if (!profile.company) showProfileEnforcementDialog = true;
        } else {
          const profileKey = StringUtils.toLowerCaseFirstLetter(key);
          const value = profile[profileKey];
          if (!value) {
            showProfileEnforcementDialog = true;
          }
        }
      }
    });

    return showProfileEnforcementDialog;
  }

  getUserAttribute() {
    const url = `people/attributes`;
    return this.http.get(url);
  }

  getProfileInfo(): Observable<UserInterface> {
    const url = `${this.baseUrl}/info`;
    return this.http.get<UserInterface>(url);
  }

  private setCookie(key: string, value: string | boolean): void {
    const expiredDate = TimeUtils.getDateWithOffset(new Date(), 1);
    this.cookieService.set(key, value as any, expiredDate, '/');
  }

  login(dto: LoginPayload): any {
    return this.http.post<UserInterface>(`${this.baseUrl}/login`, dto).pipe(
      tap((data) => {
        this.handleLoginSuccess(data);
      }),
      catchError((error: ApiResponse) => {
        if (error.status !== 200) {
          return of(error);
        }
        this.isLogin$.next(false);
        this.accessToken = null;

        throw error;
      })
    );
  }

  refreshToken(): Observable<UserInterface> {
    return this.http.post<UserInterface>(`${this.baseUrl}/refresh`, {
      accessToken: this.getToken(),
      refreshToken: this.getRefreshToken(),
    });
  }

  resetSession(): void {
    this.isLogin$.next(false);
    this.accessToken = null;
    this.cookieService.delete(StorageEnum.accessToken, '/');
    this.cookieService.delete(StorageEnum.expiredTokenTime, '/');
    this.cookieService.delete(StorageEnum.globalFilters, '/');
  }

  logout(redirect: string = ''): void {
    this.resetSession();

    this.redirectAfterLogout(redirect);
  }

  redirectAfterLogout(redirect: string): void {
    if (redirect) {
      this.goTo('redirect');
    } else if (location.href.includes('my-profile')) {
      this.goTo('/');
    } else {
      this.reloadWithoutParams();
    }
  }

  register(
    dto: RegisterPayload,
    token = null,
    orgId = null,
    applyPendingProcess = null
  ): Observable<RegisterResponse | ApiResponse> {
    const payload = {
      username: dto.username.trim(),
      password: dto.password,
      email: dto.email.trim(),
      firstName: dto.firstName.trim(),
      lastName: dto.lastName.trim(),
    } as any;
    if (token) {
      payload.invitationTokens = [token];
    }

    if (orgId) {
      payload.orgId = orgId;
      payload.applyPendingProcess = applyPendingProcess;
    }

    return this.http
      .post<RegisterResponse>(`${this.baseUrl}/register`, payload)
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  resendConfirmationEmail(
    email: string
  ): Observable<RegisterResponse | ApiResponse> {
    const payload = {
      email,
    };

    return this.http
      .post<any>(`${this.baseUrl}/register/re-send`, payload)
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  getAccountInfo(
    usernameOrEmail: string
  ): Observable<RegisterResponse | ApiResponse> {
    const decodeUsernameOrEmail = encodeURIComponent(usernameOrEmail);
    return this.http
      .get<RegisterResponse>(
        `${this.baseUrl}/register/user-info?userName=${decodeUsernameOrEmail}`
      )
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  activeAccount(email: string, token: string): Observable<any | ApiResponse> {
    const payload = {
      email,
      token,
    };

    return this.http
      .post<any>(`${this.baseUrl}/register/activate`, payload)
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  getProfile(): Observable<UserInterface> {
    return this.profile$.asObservable();
  }

  isLogin(): Observable<boolean> {
    return this.isLogin$.asObservable();
  }

  getToken(): string {
    return this.accessToken;
  }

  getRefreshToken(): string {
    return this.cookieService.get(StorageEnum.refreshToken);
  }

  getTokenValidation(token: string): Observable<TokenValidation | ApiResponse> {
    const url = `${this.baseUrl}/token/${token}`;
    return this.http.get<TokenValidation>(url).pipe(
      catchError((error: ApiResponse) => {
        if (error.status === 404) {
          this.goTo('notfound');
          return of(error);
        }
        throw error;
      })
    );
  }

  loginWithLinkedIn(
    code: string,
    redirectUri: string,
    eventId?: number,
    orgId?: number,
    applyPendingProcess?: boolean
  ): Observable<UserInterface> {
    const url = `${this.baseUrl}/login/linkedin`;
    const payload = {
      code,
      redirect_uri: redirectUri,
      ...(eventId && { eventId }),
      ...(orgId && { orgId }),
      ...(orgId && { applyPendingProcess }),
    };

    return this.http.post<UserInterface>(url, payload).pipe(
      catchError((error: ApiResponse) => {
        this.handleLoginFailed();

        throw error;
      })
    );
  }

  registerAccount(
    token: string,
    assignToOrgId?: number,
    applyPendingProcess?: boolean
  ): Observable<UserInterface> {
    const url = `${this.baseUrl}/register/linkedin`;
    const payload: {
      token: string;
      orgId?: number;
      applyPendingProcess?: boolean;
    } = { token };

    if (assignToOrgId) {
      payload.orgId = assignToOrgId;
      payload.applyPendingProcess = applyPendingProcess;
    }

    return this.http.post<UserInterface>(url, payload).pipe(
      catchError((error: ApiResponse) => {
        this.handleLoginFailed();

        throw error;
      })
    );
  }

  registerEvent(payload: RegisterEventPayload): Observable<UserInterface> {
    const url = `${this.baseUrl}/register/event`;

    return this.http.post<UserInterface>(url, payload);
  }

  verifyInvititations(
    payload: VerifyInvititationsPayload
  ): Observable<TokenValidation> {
    const url = `${this.baseUrl}/token/verify-invitations`;
    return this.http.put<TokenValidation>(url, payload);
  }

  changePassword(
    inputPayload: ChangePasswordPayload,
    newPasswordOnly = false
  ): Observable<any> {
    let payload: ChangePasswordPayload;

    if (newPasswordOnly) {
      payload = {
        newPassword: inputPayload.newPassword.trim(),
      };
    } else {
      payload = {
        newPassword: inputPayload.newPassword.trim(),
        oldPassword: inputPayload.oldPassword.trim(),
      };
    }

    return this.http.post(`${this.baseUrl}/password/change`, payload).pipe(
      catchError((error: ApiResponse) => {
        return this.handleError(error);
      })
    );
  }

  requestResetPassword(usernameOrEmail: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/password/reset`, { username: usernameOrEmail })
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  resendRequestResetPassword(email: string, token: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/password/reset/re-send`, {
        email,
        token,
      })
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  commitResetPassword(
    email: string,
    token: string,
    newPassword: string
  ): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/password/reset/process`, {
        email,
        token,
        newPassword,
      })
      .pipe(
        catchError((error: ApiResponse) => {
          return this.handleError(error);
        })
      );
  }

  checkExistedEmail(email: string, eventId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/email/check-exist`, {
      params: { email, eventId },
    });
  }

  goTo(url: string): void {
    const languagePath = this.cookieService.get(StorageEnum.locale) || '';
    FormUtils.navigateTo('/' + languagePath.toLowerCase() + url);
  }

  handleLoginFailed(): void {
    this.resetSession();
    this.goTo(environment.jipUrl.login);
  }

  handleLoginSuccess(data: UserInterface): void {
    if (data) {
      this.accessToken = data.accessToken;
      const expiredTime = TimeUtils.convertUtcToLocalTimeInSeconds(
        data.expiredDateTime
      );

      this.setCookie(StorageEnum.expiredTokenTime, expiredTime + '');
      this.setCookie(StorageEnum.isTokenExpired, false);
      this.setCookie(StorageEnum.accessToken, data.accessToken);
      this.setCookie(StorageEnum.refreshToken, data.refreshToken);

      this.cookieService.delete(StorageEnum.isTokenExpired);
      this.cookieService.delete(StorageEnum.isTokenExpired, '/');
      this.isLogin$.next(true);
    } else {
      this.isLogin$.next(true);
      this.accessToken = null;
    }
  }

  private handleError(error: ApiResponse): Observable<ApiResponse> {
    if (error.status === 400) {
      return of(error);
    }
    throw error;
  }

  private reloadWithoutParams(): void {
    window.location.href = StringUtils.removeAllParamsFromUrl(
      window.location.href
    );
  }

  handleCheckLastLoggedinUser(profile: UserInterface): void {
    if (!profile) return;
    const redirectInfo = JSON.parse(
      this.cookieService.get(StorageEnum.lastLoggedInUser) || '{}'
    );
    if (redirectInfo) {
      if (redirectInfo.userId && redirectInfo.userId !== profile.userId) {
        localStorage.setItem(StorageEnum.refreshBrowser, JSON.stringify(true));
      }
    }
  }
}
