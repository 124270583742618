<ng-container *ngIf="entity && currentJoinVentureState">
  <ng-container *ngIf="isSyncLatestJoinState; else joinTemp">
    <i class="fas fa-spinner fa-spin"></i>
  </ng-container>
  <ng-template #joinTemp>
    <ng-container [ngSwitch]="currentJoinVentureState">
      <ng-container *ngSwitchCase="JoinVentureState.CanJoin">
        <button
          [ngbTooltip]="'UI.Venture.JoinHint.JoinIn' | translate"
          class="btn btn-primary btn-interact btn-interact--big"
          appRequireLogin
          appDebounceClick
          (click)="join($event, entity)"
        >
          <i class="fas fa-sign-in-alt"></i>
          <span class="text-hide-small" translate>UI.Venture.JoinHint.JoinIn</span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="JoinVentureState.HasJoined">
        <button class="btn btn-interact btn-interact--big btn-is-member">
          <i class="fas fa-check-circle"></i>
          <span class="text-hide-small">
            <ng-container *ngIf="isTeam">
              {{ 'UI.Interaction.ImMember' | translate }}
            </ng-container>
            <ng-container *ngIf="isOwner">
              {{ 'UI.Interaction.ImAdmin' | translate }}
            </ng-container>
          </span>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="JoinVentureState.IsPending">
        <div [ngbTooltip]="'UI.Venture.JoinHint.Pending' | translate">
          <button
            class="btn btn-secondary-dark btn-interact btn-interact--big text-white"
            disabled
          >
            <span> {{ 'UI.Interaction.Joining' | translate }}</span>
          </button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="JoinVentureState.NotAccepted">
        <div [ngbTooltip]="'UI.Venture.JoinHint.NotAccepted' | translate">
          <button
            class="btn btn-secondary-dark btn-interact btn-interact--big text-white"
            disabled
          >
            <span> {{ 'UI.Interaction.TeamIsComplete' | translate }}</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
