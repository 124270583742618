import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { HttpStatusCode } from '@shared/enums/httpstatuscode.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { UserInterface } from '@shared/interfaces/user.interface';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<UserInterface>(null);

  constructor(
    private sessionService: SessionService,
    private authService: AuthenService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpResponse<HttpErrorResponse>) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return this.handle401Error(error, next, request);
        }

        throw error;
      })
    );
  }

  private handle401Error(
    error: HttpEvent<unknown>,
    next: HttpHandler,
    request: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    const refreshTokenPath = 'accounts/refresh';

    if (request.url.includes(refreshTokenPath)) {
      this.handleRefreshtokenExpired();
      throw error;
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken().pipe(
          switchMap((data: UserInterface) => {
            if (data) {
              this.authService.handleLoginSuccess(data);
              this.refreshTokenSubject.next(data);

              return next.handle(request);
            }
            this.handleRefreshtokenExpired();

            return EMPTY;
          }),
          catchError((refreshTokenError) => {
            this.handleRefreshtokenExpired();

            throw refreshTokenError;
          }),
          finalize(() => (this.isRefreshing = false))
        );
      }

      throw error;
    } else {
      return this.refreshTokenSubject.pipe(
        filter(Boolean),
        take(1),
        switchMap(() => next.handle(request))
      );
    }
  }

  handleRefreshtokenExpired(): void {
    this.sessionService.setLoginCallBackpage(location.href);
    this.authService.handleLoginFailed();

    this.sessionService.setCookie(StorageEnum.isTokenExpired, true);
  }
}
