<div class="event-registration p-3 card-box-shadow card-background">
  <app-event-card *ngIf="entity" [data]="entity"></app-event-card>

  <div class="mt-3"></div>
  <app-email-input [eventId]="id" (resent)="showSuccessMessage()">
    <div *ngIf="entity" class="w-100 d-flex mt-4">
      <app-login-with-linkedin-btn
        [redirectParam]="{ key: UrlParam.RegisterEvent, value: entity.id }"
        (onClicked)="setLoginCallback(id)"
        [text]="'Register with LinkedIn'"
        [eventHasPayment]="entity.hasPayment"
      ></app-login-with-linkedin-btn>
    </div>
  </app-email-input>
</div>
