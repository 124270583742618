<div *ngIf="data" class="card-item col">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 p-0">
      <div
        appHoverDisplay
        [dataHoverDirective]="data"
        class="card-item__image-wrapper"
      >
        <img [src]="data.image?.url || data.image" appDefaultPlaceholder />
      </div>
    </div>

    <a
      class="col-12 col-md-6 col-lg-7 link-unstyled"
      appUnsavedFormCheck
      [href]="entityUrl"
    >
      <div
        class="card-item__info--link pt-3 pt-md-4 pb-3 pb-md-4 pl-md-4 h-100 col widget__news-message--background"
      >
        <div class="row">
          <ng-container *ngIf="isLabelNameShown">
            <div
              *ngIf="data?.showAsChallenge"
              class="col-12 venture__label widget__label"
            >
              {{ 'UI.Challenge.Name' | translate }}
            </div>

            <div
              *ngIf="!data?.showAsChallenge"
              class="col-12 venture__label widget__label"
            >
              {{ 'UI.Organization.Name' | translate }}
            </div>
          </ng-container>

          <div class="organization__logo d-flex ml-3 col-1">
            <img
              [src]="data.logo?.url || data.logo"
              appDefaultPlaceholder
              [default]="placeholderImg"
              alt=""
              appDynamicImgUrl
            />
          </div>

          <div class="col">
            <h5 class="venture__title widget__heading truncate-line-1">
              {{ data.orgName }}
            </h5>
            <div class="organization__sub-name">
              <span class="text-reset truncate-line-1">
                {{ data?.enclosingOrgName }}
              </span>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="venture__label widget__label" translate>Description</div>
          <app-rich-text-toggle
            [text]="data.description"
            [controlName]="
              'org-card-description-' + (data?.id || data?.instanceId)
            "
            [hideSeeMore]="true"
            [minNumOfRows]="4"
          ></app-rich-text-toggle>
        </div>
      </div>
    </a>
  </div>
</div>
