<ng-template #emojisPopover>
  <div placement="false">
    <div class="row row-cols-5 mx-0 w-100">
      <div
        *ngFor="let item of emojis"
        class="col d-flex my-1 justify-content-center align-items-center"
      >
        <ngx-emoji
          (emojiClick)="emojiClick($event)"
          [emoji]="item.id"
          [size]="20"
          class="pointer-cursor"
          isNative="true"
          set="twitter"
          tooltip="true"
        ></ngx-emoji>
      </div>
    </div>
  </div>
</ng-template>

<button
  placement="bottom"
  [ngbPopover]="emojisPopover"
  [ngClass]="btnStyleClass"
  id="picker_btn"
  type="button"
>
  <i class="fas fa-laugh fa-lg"></i>
</button>
