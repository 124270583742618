import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-privacy-flag',
  templateUrl: './privacy-flag.component.html',
})
export class PrivacyFlagComponent {
  @Input() isPrivate = false;
  @Input() parentClass = '';
}
