<div class="row">
  <div [class.offset-md-2]="!alignLeft" class="col-md-10 col-xs-12 offset-xs-0">
    <div class="form-group mt-2">
      <div class="form-check">
        <input
          class="form-check-input pointer-cursor"
          type="checkbox"
          id="defaultCheck1"
          [value]="value"
          [checked]="value"
          (change)="onValueChange($event.target?.checked)"
        />
        <label
          class="form-check-label checkbox-text-font-size pointer-cursor"
          for="defaultCheck1"
        >
          {{ 'UI.New-Message.Forward-email-hint' | translate }}
        </label>
      </div>
    </div>
  </div>
</div>
