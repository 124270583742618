import { Inject, Injectable } from '@angular/core';
import { EntityName } from '@shared/enums/entity-name.enum';
import { StorageEnum } from '@shared/enums/storage.enum';
import { WINDOW } from '@shared/helpers/window.token';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { MediaBreakpoint } from '@shared/models/ui.model';
import { getCurrentFragment } from '@shared/utils/common.utils';
import { fromEvent, lastValueFrom } from 'rxjs';
import { debounceTime, delay, first, map, mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScrollingHandlerService {
  constructor(@Inject(WINDOW) private window: Window) {}
  // Support save one position only
  isRestorationComplete = false;

  readonly HEADER_ID = 'page-header-panel';

  private lastVisibleItem: string;

  private itemCount = 0;

  private readonly STORAGE: Storage = sessionStorage;

  saveItem(entityName: EntityName, isVisible: boolean, item: string): void {
    if (!this.isRestorationComplete) return;

    const { entityPositionsStored: storageKey } = StorageEnum;
    let entityPositions: { [key: string]: string };

    try {
      entityPositions = JSON.parse(this.STORAGE.getItem(storageKey));
    } catch {
      this.STORAGE.removeItem(storageKey);
    }

    const visibleItem = isVisible ? item : this.lastVisibleItem;

    entityPositions = {
      ...entityPositions,
      [entityName]: visibleItem,
    };

    this.STORAGE.setItem(storageKey, JSON.stringify(entityPositions));

    this.lastVisibleItem = visibleItem;
  }

  removeItem(entityName: EntityName): void {
    const { entityPositionsStored: storageKey } = StorageEnum;
    const entityPositions = JSON.parse(this.STORAGE.getItem(storageKey));

    if (entityPositions) {
      delete entityPositions[entityName];

      this.STORAGE.setItem(storageKey, JSON.stringify(entityPositions));
    }
  }

  restorePosition(
    entityName: EntityName,
    id: number | string,
    margin?: number,
    force = false
  ): void {
    if (this.isRestorationComplete) return;

    let previousId: string;
    const { entityPositionsStored: storageKey } = StorageEnum;
    const entityPositions = this.STORAGE.getItem(storageKey);

    if (!entityPositions) {
      this.isRestorationComplete = true;
      return;
    }

    try {
      previousId = JSON.parse(entityPositions)[entityName];
    } catch {
      this.STORAGE.removeItem(storageKey);
    }

    if (force) {
      this.scrollToId(previousId, margin);

      return;
    }

    if (!previousId || previousId !== id.toString()) {
      this.itemCount++;
      if (this.itemCount === PageSizeConfig.EightItemsFirstPage - 1) {
        this.isRestorationComplete = true;
      }

      return;
    }

    this.scrollToId(previousId, margin);
  }

  scrollToId(id: string, margin?: number): void {
    const ele: HTMLElement = document.getElementById(id);
    if (!ele) {
      this.isRestorationComplete = true;
      this.STORAGE.removeItem(StorageEnum.entityPositionsStored);
      return;
    }

    this.scrollToElementRef(ele, margin).then(() => {
      this.isRestorationComplete = true;
      this.lastVisibleItem = this.lastVisibleItem ?? id;
    });
  }

  scrollToElementRef(element: HTMLElement, margin?: number): Promise<boolean> {
    this.scrollToJustAbove(element, margin);

    return lastValueFrom(
      fromEvent(this.window, 'scroll').pipe(
        debounceTime(100),
        delay(1500),
        first(),
        map(() => true)
      )
    )
  }

  scrollToJustAbove(element: HTMLElement, margin = 120): void {
    const dims = element.getBoundingClientRect();

    this.window.scrollTo(this.window.scrollX, dims.top - margin);
  }

  scrollToFragment(id: string): void {
    const ele: HTMLElement = document.getElementById(id);
    const fragment = this.window.location.hash?.substring(1);

    if (!ele || id !== fragment) return;

    this.scrollToJustAbove(ele);
  }

  scrollToFragmentIfAny(delayTime = 0): void {
    const fragment = getCurrentFragment(this.window);

    if (!fragment) return;

    const panel = document.getElementById(this.HEADER_ID);
    const offsetTop = this.window.innerWidth < MediaBreakpoint.sm ? 40 : 80;
    const totalOffset = (panel?.clientHeight ?? 0) + offsetTop;

    setTimeout(() => {
      const ele = document.getElementById(fragment);

      this.scrollToJustAbove(ele, totalOffset);
    }, delayTime);
  }
}
