import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/notification.service';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((response) => {
        // @ts-ignore
        if (!response) {
          this.showSeverError();
        }
      }),
      catchError((error: HttpResponse<HttpErrorResponse>) => {
        const willHandle = !/(wp-json\/wp\/v2\/media)/i.test(request.url);

        if (willHandle && !EXCLUDED_STATUS.includes(error.status)) {
          this.showSeverError(error);
        }
        console.log('Error: ');
        console.log(request);
        console.log(error);
        return this.errorHandler(error);
      })
    );
  }

  showSeverError(error: HttpEvent<any> = null): void {
    if (navigator.onLine) {
      this.notificationService.showSomethingWentWrong(error);
    } else {
      this.notificationService.show(
        this.translateService.instant('Network-Disconnected'),
        this.translateService.instant('Unknown-Error')
      );
    }
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    throw response;
  }
}

const EXCLUDED_STATUS = [200, 400, 401, 403, 404, 0];
