import { Component, OnInit } from '@angular/core';
import { SessionService } from '@src/app/core/session.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styles: [],
})
export class AdminDashboardComponent implements OnInit {
  tenantId: number;
  entityType = 'USER_REGISTERED';
  isExportLoading = false;
  reportName = 'number-of-registered-users';

  constructor(protected sessionService: SessionService) {}

  ngOnInit(): void {
    const tenantId = Number(this.sessionService.getTenantId());
    if (tenantId) {
      this.tenantId = tenantId;
    }
  }

  onExportLoading(loading: boolean): void {
    this.isExportLoading = loading;
  }
}
