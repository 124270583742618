import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  MoodboardImageItem,
  MoodboardPosition
} from '@shared/interfaces/people.interface';

@Component({
  selector: 'app-moodboard',
  templateUrl: './moodboard.component.html',
})
export class MoodboardComponent implements OnInit, OnChanges {
  @Input() personalMoodboards: MoodboardImageItem[];
  @Input() innovationMoodboards: MoodboardImageItem[];

  items: MoodboardImageItem[];

  options = [];

  firstIsBigger = false;
  ratio = 0.4;

  ngOnInit(): void {
    this.calculateSection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mergeItems();
    this.calculateSection();
  }

  mergeItems(): void {
    const personalMoodboards = this.personalMoodboards || [];
    const innovationMoodboards = this.innovationMoodboards || [];
    this.items = personalMoodboards.concat(innovationMoodboards);
  }

  calculateSection(): void {
    if (this.items?.length > 0) {
      let positions: MoodboardPosition[] = [
        {
          top: 0,
          left: 0,
          width: 100,
          height: 100,
        },
      ];

      for (let i = 1; i < this.items.length; i++) {
        const biggestItem = positions.shift();
        const separatedItems = this.separateItem(biggestItem);

        positions = positions.concat(separatedItems);

        positions.sort((a, b) => {
          return b.width * b.height - a.width * a.height;
        });
      }

      positions.sort((a, b) => {
        return (a.top - b.top) * 10 + (a.left - b.left);
      });
      this.options = positions;
    }
  }

  private separateItem(item: MoodboardPosition): MoodboardPosition[] {
    const item1 = { ...item };
    const item2 = { ...item };
    const delta = 1;

    let ratio = this.ratio;

    if (this.firstIsBigger) {
      ratio = 1 - ratio;
    }
    this.firstIsBigger = !this.firstIsBigger;
    if (item.width > item.height) {
      item1.width = Math.round(item.width * ratio) - delta;

      item2.left = item.left + item1.width + delta;
      item2.width = item.width - item1.width - 1;
    } else {
      item1.height = Math.round(item.height * ratio) - delta;

      item2.top = item.top + item1.height + delta;
      item2.height = item.height - item1.height - 1;
    }

    return [item1, item2];
  }
}
