import { FormUtils } from '@shared/utils/form-utils';
import { environment } from '@env/environment';
import { BaseComponentWithServiceComponent } from '@src/app/components/base/base-list/base-component-with-service/base-component-with-service.component';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';

@Component({
  selector: 'app-base-detail',
  templateUrl: './base-detail.component.html',
})
export class BaseDetailComponent
  extends BaseComponentWithServiceComponent
  implements OnInit, OnChanges {
  @Input() id: number;
  @Input() isInDesignMode = false;

  isCreating = false;

  constructor(
    public authenService: AuthenService,
    public sessionService: SessionService
  ) {
    super(sessionService);
  }

  ngOnInit(): void {
    this.authenService.isLogin().subscribe((login) => {
      this.checkRedirect();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.isCreating = !this.id;
    this.checkRedirect();
  }

  checkRedirect(): void {
    if (this.isCreating && !this.sessionService.isLogin) {
      if (!this.isInDesignMode) {
        this.goToLogin();
      }
    }
  }

  goToLogin(): void {
    this.sessionService.setLoginCallBackpage(location.href);
    FormUtils.navigateTo(
      this.sessionService.appendLanguagePath(environment.jipUrl.login)
    );
  }
}
