import { MessageAction } from '../enums/entity-name.enum';

export const MESSAGE_TRANSLATOR = {
  // Your venture submitted to Challenge/Organization
  [MessageAction.VentureSubmittedToChallenge]:
    'UI.NewMessage.SubmitVentureToChallenge',
  [MessageAction.VentureSubmittedOrganization]:
    'UI.NewMessage.SubmitVentureToChallenge',

  // Venture submitted to your Challenge/Organization
  [MessageAction.NewVentureSubmittedToChallenge]:
    'UI.NewMessage.SubmitVentureToYourChallenge',
  [MessageAction.NewVentureSubmittedToOrganization]:
    'UI.NewMessage.SubmitVentureToYourChallenge',

  [MessageAction.AcceptedSubmittedVenture]:
    'UI.NewMessage.SubmitVentureToChallengeAccepted',
  [MessageAction.RefusedSubmittedVenture]:
    'UI.NewMessage.SubmitVentureToChallengeRefused',
};
