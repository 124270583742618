<div class="social-links">
  <div *ngFor="let item of items" class="social-links__item">
    <div class="social-links__image-wrapper">
      <a
        [value]="item.link"
        appUrlPrefix
        target="_blank"
        [title]="item.socialMediaName"
      >
        <img [src]="item.socialMediaLogo" appDefaultPlaceholder appDynamicImgUrl />
      </a>
    </div>
  </div>
</div>
