<!-- //NOSONAR --><li class="list-inline-item venture__actions-item">
  <ng-container *ngIf="isLoadingCommentsCount; else commentsBtn">
    <div class="text-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #commentsBtn>
    <a
      appDebounceClick
      (debounceClick)="openCommentModal(entity)"
      class="d-flex align-items-center"
    >
      <app-internal-icon
        [height]="20"
        [name]="'comment-dots'"
        [className]="'filter-gray'"
      ></app-internal-icon>
      <span class="venture__actions-label font-weight-500 text-gray-600">
        <span class="text-hide-small">{{ 'Comments' | translate }}</span>
        <span [innerHTML]="entity?.commentsCount | parentheses"></span>
      </span>
    </a>
  </ng-template>
</li>
