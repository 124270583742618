<div class="form-section">
  <div
    *ngIf="showEditButton && !editting"
    (click)="toggle($event)"
    class="form-section__trigger"
  >
    <i class="fas fa-pencil-alt"></i>
  </div>
  <ng-content> </ng-content>

  <div
    *ngIf="editting && !hideControls && shouldButtonsShown"
    class="form-section__btn-wrapper"
    [class]="formSectionClass"
  >
    <button
      (click)="cancel()"
      class="btn btn-outline-primary form-section__btn"
      translate
    >
      Cancel
    </button>

    <button
      *ngIf="!hideSaveBtn"
      appRequireSession
      (credentialClick)="onSave()"
      class="btn btn-primary form-section__btn"
      [disabled]="!canSave || requesting"
    >
      <ng-container *ngIf="requesting">
        <i class="fas fa-spinner fa-spin"></i>
      </ng-container>
      <span>{{ 'Save' | translate }}</span>
    </button>
  </div>
</div>
