<div class="text-right">
  <ng-container [ngSwitch]="entity_name">
    <ng-container *ngSwitchCase="EntityName.Venture">
      <button
        class="btn btn-primary widget__btn-create my-3"
        translate
        appRequireLogin
        [callBackLoginPage]="creationPath"
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <button
        *ngIf="shouldShowButton()"
        appRequireLogin
        (click)="createEntity(entity_name)"
        class="page-header__btn widget-btn btn btn-primary"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>{{
          entity_name === EntityName.Post
            ? 'UI.NewMessage.CreatePost'
            : 'UI.NewMessage.CreateMessage'
        }}</span>
      </button>
    </ng-container>
  </ng-container>
</div>
