import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-nda-inform-modal',
  templateUrl: './change-nda-inform-modal.component.html',
})
export class ChangeNdaInformModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
