import { Component, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-zoom-out-img-modal',
  templateUrl: './zoom-out-img-modal.component.html',
  styles: [],
})
export class ZoomOutImgModalComponent {
  @Input() data: any;
  @Input() titleTemplateRef?: TemplateRef<any>;

  constructor(public activeModal: NgbActiveModal) {}
}
