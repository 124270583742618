export class ArrayUtils {
  /**
   * Return new array with excluding itemToRemove
   * Orginal array is not changed
   *
   * @param  source Input Array
   * @param  removedItems items to remove
   * @param  [key=null] key to compare
   * @returns  new array
   */
  public static removeItems<T>(
    source: T[],
    removedItems: any[],
    key: string = null
  ): T[] {
    if (
      !source ||
      source.length === 0 ||
      !removedItems ||
      removedItems?.length === 0
    ) {
      return source;
    }

    return source.filter((item) => {
      const idx = removedItems.findIndex((removedItem) => {
        const itemToRemove = removedItem[key] || removedItem;
        return key ? item[key] === itemToRemove : item === removedItem;
      });
      return idx < 0 ? true : false;
    });
  }

  /**
   * Return new array with excluding itemToRemove
   * Orginal array is not changed
   *
   * @param  list Input Array
   * @param  removedItem Item to remove
   * @param  [key=null] key to compare
   * @returns  new array
   */
  public static removeItem(list: any[], removedItem: any, key = null): any[] {
    if (!Array.isArray(list)) {
      return [];
    }

    return list.filter((item) => {
      if (!key) {
        return item !== removedItem;
      } else {
        return item[key] !== removedItem[key];
      }
    });
  }

  /**
   * Modify source array with excluding itemToRemove
   * Orginal array is not changed
   *
   * @param  arrays Input Array
   * @param  itemToRemove Item to remove
   * @param  [key=null] key to compare
   * @returns  source array
   */
  public static removeItemHard(arrays, itemToRemove, key = null): any[] {
    if (!Array.isArray(arrays)) {
      return arrays;
    }

    const index = arrays.findIndex((item) => {
      if (!key) {
        return item !== itemToRemove;
      } else {
        return item[key] !== itemToRemove[key];
      }
    });
    arrays.splice(index, 1);
    return arrays;
  }

  public static hasItem<T>(arrays: T[], item: any, key = null): boolean {
    if (!Array.isArray(arrays) || !item) {
      return false;
    }

    let result = false;
    arrays.forEach((element) => {
      if (!key) {
        result = result || item === element;
      } else {
        result = result || item[key] === element[key];
      }
    });

    return result;
  }

  public static concact(array1: any[] = [], array2: any[] = []): any[] {
    if (!array1 || !array2) {
      return array1 || array2;
    }
    if (!Array.isArray(array2)) {
      return array1;
    }
    try {
      return array1.concat(array2);
    } catch (err) {
      return [];
    }
  }

  public static isEqual(
    array1: any[] = [],
    array2: any[] = [],
    key = null
  ): boolean {
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      return false;
    }
    if (!key) {
      return JSON.stringify([...array1]) === JSON.stringify([...array2]);
    }

    const formatArray1 = array1.map((item) => item[key]);
    const formatArray2 = array2.map((item) => item[key]);

    return JSON.stringify(formatArray1) === JSON.stringify(formatArray2);
  }
}
