import { Pipe, PipeTransform } from '@angular/core';
import { TemplateName } from '../constants/visibility-config.const';

@Pipe({
  name: 'kczTranslationKey',
})
export class KczTranslationKeyPipe implements PipeTransform {
  transform(value: string, templateName: TemplateName): string {
    if (templateName === TemplateName.Reduced) return `${value} - KCZ`;

    return value;
  }
}
