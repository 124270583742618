import { Injectable } from '@angular/core';
import { JoinState } from '@shared/interfaces/join.interface';
import { catchError, map } from 'rxjs/operators';
import { BaseHttpService } from '@core/http/base-http.service';
import { Observable, of } from 'rxjs';
import { BaseEntityInterface } from '@shared/interfaces/base/base-entity.interface';

@Injectable({
  providedIn: 'root',
})
export class EntityJointStateService {
  static mapJointState<T extends BaseEntityInterface>(
    entities: T[],
    entityHttpService: BaseHttpService<T>
  ): Observable<T[]> {
    const stringIds = entities.map((i) => String(i.id));

    return entityHttpService.getJoinStates(stringIds).pipe(
      map((joinStates: JoinState[]) => {
        entities.forEach((item) => {
          const foundState = joinStates.find(
            (state) => state.entityId === item.id
          );

          item.hasJoined = foundState.hasJoined || false;
          item.canJoin = foundState.canJoin || false;
          item.isPending = foundState.isPending || false;
        });

        return entities;
      }),
      catchError(() => of(entities))
    );
  }
}
