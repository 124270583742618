<ng-template #modalRef let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" translate>{{ modalTitle }}</h4>
      <h6 *ngIf="entityTitle">
        {{ entityTitle }}
      </h6>
    </div>
    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true"> &times; </span>
    </button>
  </div>

  <div class="modal-body modal-body-minspace">
    <ng-container *ngIf="isLoading; else viewLikes">
      <div class="spinner-loading">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </ng-container>
    <ng-template #viewLikes>
      <ng-template [ngIf]="people?.length > 0" [ngIfElse]="emptyPeopleMsg">
        <app-people-card-list [displayItems]="people"></app-people-card-list>
      </ng-template>

      <ng-template #emptyPeopleMsg>
        <span class="message-empty" translate>There are no Likes yet</span>
      </ng-template>
    </ng-template>
  </div>

  <div class="modal-footer">
    <app-bootstrap-pagination
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>
  </div>
</ng-template>
