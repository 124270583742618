<img
  class="shared-icon"
  alt="custom icon"
  [src]="iconPlaceholder"
  [class]="className"
  [ngClass]="{ 'shared-icon--big': hasBigSize }"
  [style.background-position]="iconPosition[name]"
  [style.width]="width"
  [style.height]="height"
/>
