import { AttributeType } from '../enums/attribute-type.enum';

export const ATTRIBUTE_TYPE_SUPPORT_EDIT_IN_TABLE = [
  AttributeType.String,
  AttributeType.Integer,
  AttributeType.SingleSelection,
  AttributeType.DateTime,
  AttributeType.RTE,
  AttributeType.MultipleSelection,
  AttributeType.Boolean,
  AttributeType.Image
];

export const RELATED_ENTITY_SUPPORTED_TO_EDIT_IN_VENTURE_TABLE = [
  'CurrentSubmission',
  'Initiator',
  'Stakeholders'
];
