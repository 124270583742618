import { Injectable } from '@angular/core';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { BehaviorSubject } from 'rxjs';

export interface SelectedChoice {
  propertyName: string;
  value: CustomAtrributeValue;
}
@Injectable({
  providedIn: 'root',
})
export class DependantSelectionService {
  private selectedChoices: SelectedChoice[] = [];

  selectedChoices$ = new BehaviorSubject<SelectedChoice[]>([]);

  masterChoiceId$ = new BehaviorSubject('');

  updateSelectedChoice(choice: {
    propertyName: string;
    value: CustomAtrributeValue;
  }): void {
    const index = this.selectedChoices.findIndex(
      (i) => i.propertyName === choice.propertyName
    );

    if (index < 0) {
      this.selectedChoices.push(choice);
    } else {
      this.selectedChoices[index] = choice;
    }
    this.selectedChoices$.next(this.selectedChoices);
  }

  getSelectedChoices() {
    return this.selectedChoices$.asObservable();
  }

  updateMasterChoiceId(propertyName: string) {
    this.masterChoiceId$.next(propertyName);
  }

  getMasterChoiceId() {
    return this.masterChoiceId$.asObservable();
  }
}
