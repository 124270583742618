<ng-container *ngIf="message">
  <app-message-header-wrapper
    [people]="lastReplier || welcomeMessagePerson"
    [isSystemNews]="message.isSystemNews"
    [messageDate]="message.dateTime"
  >
    <div class="card-action jip-upper-first-letter">
      <span *ngIf="messageHtml" [innerHTML]="messageHtml"> </span>
      <div *ngIf="!messageHtml" class="news-messages__body">
        <app-message-card
          [showAsHeader]="true"
          [message]="message"
          [currentPersonId]="currentPersonId"
        >
        </app-message-card>
      </div>
    </div>
  </app-message-header-wrapper>

  <div *ngIf="messageHtml" class="news-messages__body">
    <app-message-card [message]="message" [currentPersonId]="currentPersonId">
    </app-message-card>
  </div>
</ng-container>
