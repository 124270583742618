import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
// app
import { placeholderImg } from '@src/app/shared/constants/common';
import { ImageInterface } from '@src/app/shared/interfaces/file.interface';
import { SocialMedia } from '@src/app/shared/interfaces/people.interface';
import { FileUploadComponent } from '../../file-upload/file-upload.component';

@Component({
  selector: 'app-social-link-figure',
  templateUrl: './social-link-figure.component.html',
})
export class SocialLinkFigureComponent {
  @Input() editable: boolean;
  @Input() data: SocialMedia;

  @Output() changeSrcUrl = new EventEmitter<ImageInterface>();

  @ViewChild(FileUploadComponent) fileUploadComp: FileUploadComponent;

  placeholderImg = placeholderImg;

  uploadFile(file: File): void {
    if (!file || !this.fileUploadComp) {
      return;
    }

    this.fileUploadComp.upload(file);
  }
}
