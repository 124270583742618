<div class="simple-card" [ngClass]="cardClass">
  <div class="simple-card__header">
    <ng-content select="[slot='header']"></ng-content>
  </div>
  <div
    [hidden]="shouldExpandContent"
    class="simple-card__img"
    [class.simple-card__img--square]="isSquareImage"
  >
    <ng-content select="[slot='img']"></ng-content>
  </div>
  <div class="simple-card__body" [class.pt-0]="shouldExpandContent">
    <ng-content select="[slot='body']"></ng-content>
  </div>
  <div class="simple-card__footer">
    <ng-content select="[slot='footer']"></ng-content>
  </div>
</div>
