import { Component, Injector, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { UnsavedFormCheckService } from '@core/form/unsaved-form-check.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldCustomComponent } from '@shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';
import { StorageEnum } from '@shared/enums/storage.enum';
import {
  CustomAtrributeValue,
  MetaInformation,
} from '@shared/interfaces/attribute-description.interface';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { EntityGlobalFilterCriteria } from '@shared/interfaces/filters/global-filter.interface';
import { News } from '@shared/interfaces/news.interface';
import { OrganizationFilter } from '@shared/interfaces/organization.interface';
import { Post } from '@shared/interfaces/post.interface';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import { SendMessageBoxBaseComponent } from '@src/app/components/dialogs/send-message-box-base/send-message-box-base.component';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { ToastService } from '@src/app/core/toast.service';
import {
  IconType,
  InternalIcon,
} from '@src/app/shared/enums/internal-icon.enum';
import { TextValidator } from '@src/app/shared/utils/form-fields-validation';

@Component({
  selector: 'app-new-post-dialog',
  templateUrl: './new-post-dialog.component.html',
})
export class NewPostDialogComponent
  extends SendMessageBoxBaseComponent
  implements OnInit
{
  @Input() postData: News;

  @Input() placeholder = 'UI.SendMessage.Placeholder';

  @Input() globalFilterCriteria: EntityGlobalFilterCriteria;

  currentOrg: OrganizationFilter;

  globalOrgId: number;

  items: CustomAtrributeValue[] = [];

  InternalIcon = InternalIcon;

  IconType = IconType;

  //#region SERVICES
  protected toastService: ToastService;
  //#endregion End Services

  constructor(
    public readonly activeModal: NgbActiveModal,
    public readonly unsavedFormCheckService: UnsavedFormCheckService,
    protected readonly injector: Injector,
    protected readonly authService: AuthenService,
    protected readonly sendMessageHttpService: SendMessageHttpService,
    private readonly newsMessagesHttpService: NewsMessagesHttpService,
    private readonly userConfig: UserConfigManagementService
  ) {
    super(
      activeModal,
      unsavedFormCheckService,
      authService,
      sendMessageHttpService,
      injector
    );
    this.toastService = injector.get<ToastService>(ToastService);
  }

  isFormNotSaved(): boolean {
    return this.content?.value;
  }

  isCreationMode(post: News): boolean {
    return !post?.id;
  }

  createPost(): void {
    this.isSubmitting = true;
    const payload: Post = {
      content: this.content?.value,
      imageId: this.imageId?.value,
      orgId: this.currentOrg?.id ?? this.globalOrgId,
    };

    this.newsMessagesHttpService.createPost(payload).subscribe(() => {
      this.toastService.showSuccess('UI.Toast.ShareSuccessfully');
      this.isSubmitting = false;
      this.activeModal.close(true);
    });
  }

  editPost(post: News): void {
    this.isSubmitting = true;
    const payload: Post = {
      content: this.content?.value,
    };

    if (this.imageId?.value) {
      payload.imageId = this.imageId?.value;
    } else if (!this.messageForm?.imageInputDisplay?.source) {
      payload.imageId = null;
    }

    this.newsMessagesHttpService.editPost(post.id, payload).subscribe(() => {
      this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
      this.isSubmitting = false;
      this.activeModal.close(true);
    });
  }

  formFieldLoaded(newPostDialogComponent: FormFieldCustomComponent): void {
    if (!this.postData?.content?.image) return;

    const image: ImageInterface = { url: this.postData.content.image };
    newPostDialogComponent?.imageInputDisplay?.setImage(image);
  }

  onSubmit(post: News): void {
    this.isCreationMode(post) ? this.createPost() : this.editPost(post);
  }

  getModalTitle(post: News): string {
    return this.isCreationMode(post) ? 'UI.News.NewPost' : 'UI.News.EditPost';
  }

  getSubmitButtonText(post: News): string {
    return this.isCreationMode(post) ? 'UI.Interaction.Share' : 'Save';
  }

  protected handleAfterLoadAttributeDescription(res: MetaInformation): void {
    this.initForm();
    this.setCurrentOrg().then();
    this.setFormDataIfAny(this.postData);
  }

  private async setCurrentOrg(): Promise<void> {
    const currentOrgList = await this.userConfig.get(
      StorageEnum.orgFilterToken
    );

    this.currentOrg = currentOrgList?.find((item: OrganizationFilter) => {
      return item.id === this.globalFilterCriteria?.organizationIds[0];
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      Content: [
        '',
        {
          validators: [Validators.required, TextValidator.notEmptyOrWhitespace],
        },
      ],
      imageId: [],
    });

    this.formErrorService.register(this);
  }

  private setFormDataIfAny(post: News): void {
    if (!post) return;

    this.form.patchValue({
      Content: post.message,
    });
  }
}
