<div class="input-group flex-nowrap" [ngClass]="styleClass">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1"
      ><i class="fas fa-search"></i
    ></span>
  </div>
  <input
    class="people-cards__search-btn"
    [placeholder]="'Search' | translate"
    [ngModel]="searchName"
    (ngModelChange)="setDisplayItems($event)"
  />
</div>
