import { Injectable } from '@angular/core';
import { StorageEnum } from '@shared/enums/storage.enum';
import { UserConfigManagementService } from '@shared/services/user-config-management.service';
import {
  PageSizeConfig,
  PaginationFilterStored,
  PaginationSetting,
  PaginationSettingPlace,
} from '@src/app/shared/models/pagination.model';

@Injectable({
  providedIn: 'root',
})
export class PaginationSettingService {
  private currentPaginationSettings: PaginationSetting[] = [];
  private currentPaginationFilterStored: PaginationFilterStored[] = [];

  constructor(private userConfig: UserConfigManagementService) {}

  async getPaginationSettings(
    pageSizeConfig: PageSizeConfig
  ): Promise<PaginationSetting> {
    await this.getLatestPaginationDataStored();

    return this.currentPaginationSettings?.find(
      (x) => x.pageSizeConfig === pageSizeConfig
    );
  }

  async getPaginationFilterStored(
    paginationSettingPlace: PaginationSettingPlace
  ): Promise<PaginationFilterStored> {
    await this.getLatestPaginationDataStored();

    let updatedcurrentPaginationFilterStored: PaginationFilterStored =
      this.currentPaginationFilterStored?.find(
        (x) => x.paginationSettingPlace === paginationSettingPlace
      );

    if (updatedcurrentPaginationFilterStored) {
      const newPageSize = this.currentPaginationSettings?.find(
        (x) =>
          x.pageSizeConfig ===
          updatedcurrentPaginationFilterStored.pageSizeConfig
      )?.keepPageSize;

      if (newPageSize !== null && !isNaN(newPageSize)) {
        updatedcurrentPaginationFilterStored = this.calculateNewPageIndex(
          updatedcurrentPaginationFilterStored,
          newPageSize
        );
        this.setPaginationFilterStored(updatedcurrentPaginationFilterStored);
      } else {
        this.setPaginationSettings(
          updatedcurrentPaginationFilterStored.keepFilter.pageSize,
          updatedcurrentPaginationFilterStored.pageSizeConfig
        );
      }
    }

    return updatedcurrentPaginationFilterStored;
  }

  async setPaginationSettings(
    pageSize: number,
    pageSizeConfig: PageSizeConfig
  ): Promise<void> {
    await this.getLatestPaginationDataStored();

    const index = this.currentPaginationSettings.findIndex(
      (x) => x.pageSizeConfig === pageSizeConfig
    );
    const keepPageSize = pageSize || pageSizeConfig;
    if (index > -1) {
      this.currentPaginationSettings[index].keepPageSize = keepPageSize;
    } else {
      this.currentPaginationSettings.push(
        new PaginationSetting(keepPageSize, pageSizeConfig)
      );
    }

    await this.userConfig.set(
      StorageEnum.paginationSetting,
      this.currentPaginationSettings
    );
  }

  async setPaginationFilterStored(
    paginationFilterStored: PaginationFilterStored
  ): Promise<void> {
    await this.getLatestPaginationDataStored();

    const index = this.currentPaginationFilterStored.findIndex(
      (x) =>
        x.paginationSettingPlace ===
        paginationFilterStored.paginationSettingPlace
    );
    if (index > -1) {
      this.currentPaginationFilterStored[index] = paginationFilterStored;
    } else {
      this.currentPaginationFilterStored.push(paginationFilterStored);
    }

    await this.userConfig.set(
      StorageEnum.paginationFilterStored,
      this.currentPaginationFilterStored
    );
  }

  private calculateNewPageIndex(
    paginationFilterStored: PaginationFilterStored,
    newPageSize: number
  ): PaginationFilterStored {
    const result: PaginationFilterStored = { ...paginationFilterStored };
    if (result.keepFilter.pageIndex && result.keepFilter.pageSize) {
      const oldPageSize = result.keepFilter.pageSize;
      const oldPageIndex = result.keepFilter.pageIndex;
      const newPageIndex = Math.ceil(
        (oldPageSize * (oldPageIndex - 1) + 1) / newPageSize
      );
      result.keepFilter.pageIndex = newPageIndex;
      result.keepFilter.pageSize = newPageSize;
      return result;
    } else {
      return { ...result, keepFilter: {} };
    }
  }

  private async getLatestPaginationDataStored(): Promise<void> {
    const paginationSettingString = await this.userConfig.get(
      StorageEnum.paginationSetting
    );

    this.currentPaginationSettings = paginationSettingString || [];

    const paginationFilterStoredString = await this.userConfig.get(
      StorageEnum.paginationFilterStored
    );

    this.currentPaginationFilterStored = paginationFilterStoredString || [];
  }
}
