<ng-template #modalRef let-modal>
  <div class="modal-header">
    <div>
      <h4 class="modal-title" translate>{{ modalTitle }}</h4>
      <h6>
        {{ entityTitle }}
      </h6>
    </div>

    <button
      type="button"
      class="btn-close close"
      aria-label="Close"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true"> &times; </span>
    </button>
  </div>

  <div class="modal-body modal-body-minspace">
    <app-shared-events
      [entityId]="entityId"
      [entityTitle]="entityTitle"
      modalTitle="{{ 'Events' | translate }}"
      [httpService]="httpService"
    ></app-shared-events>
  </div>
</ng-template>
