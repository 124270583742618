<div class="cookies-settings">
  <div class="cookies-settings__header d-flex justify-content-between mb-4">
    <ng-container *ngIf="logoDisplayOption === 'jip'">
      <app-jip-logo
        class="jip-login__logo--others"
        name="jip-logo"
        width="380px"
        height="106px"
        style="width: 250px;"
      ></app-jip-logo>

      <app-jip-logo
        class="jip-login__logo--mobile"
        name="jip-logo"
        width="380px"
        height="106px"
        style="width: 150px;"
      ></app-jip-logo>
    </ng-container>

    <div
      *ngIf="logoDisplayOption === 'innovation_space'"
      class="cookies-settings__org-logo"
    >
      <app-organization-logo
        [className]="'filter__org-logo text-center'"
        [organizationLogo]="
          (centralConfig?.read(globalOrgId) | async)?.logo.url
        "
      ></app-organization-logo>
    </div>

    <div class="flex-grow-1"></div>

    <ng-container *ngIf="poweredByLogo === 'bbv'">
      <div class="cookies-settings__powered-by">
        <span> {{ 'UI.PoweredBy' | translate }}</span>
      </div>

      <app-shared-icon
        class="cookies-settings__logo mr-3"
        name="logoBBV"
        width="50px"
        height="30px"
      ></app-shared-icon>
    </ng-container>

    <ng-container *ngIf="poweredByLogo === 'jointcreate'">
      <div>
        <img
          src="/wp-content/themes/jip/assets/images/operated-by-jointcreate-logo.png"
          alt="operated-by-jointcreate-logo"
        />
      </div>
    </ng-container>
  </div>

  <div class="cookies-settings__body">
    <div class="cookies-settings__content">
      <div class="cookies-settings__content-header" translate>
        Data Protection Overview
      </div>

      <div class="cookies-settings__content--left cookies-settings__text">
        {{ 'UI.Cookies.Description' | translate }}
      </div>
    </div>

    <div class="cookies-settings__content">
      <div translate class="cookies-settings__content-header">
        Strictly Necessary Cookies
      </div>

      <div class="cookies-settings__content-main">
        <div class="cookies-settings__content--left">
          <div class="cookies-settings__text">
            {{ 'UI.Cookies.ShouldBeActivated' | translate }}
          </div>
          <div class="cookies-settings__text">
            {{ 'UI.Cookies.WarningIfDeactive' | translate }}
          </div>
        </div>

        <div class="cookies-settings__content-main__right">
          <app-toggle-button-slider
            [className]="'cursor-not-allowed'"
            [(status)]="isActiveStrictlyCookies"
            [settings]="{
              disabled: true,
              activeClass: 'disabled',
              activeText: 'Yes' | translate,
              deactiveText: 'No' | translate
            }"
          >
          </app-toggle-button-slider>
        </div>
      </div>
    </div>
  </div>
  <div class="cookies-settings__controls d-flex justify-content-end">
    <button
      translate
      class="cookies-acceptance__button-accept btn btn-outline-primary"
      (click)="activeModal.close()"
    >
      Close
    </button>

    <button
      translate
      class="cookies-acceptance__button-accept btn btn-primary"
      (click)="saveSettings()"
    >
      UI.Common.SaveAndContinue
    </button>
  </div>
</div>
