<div
  *ngIf="collectionSize > 0"
  class="boostrap-pagination d-flex mt-3 flex-column flex-md-row align-items-center"
  [class.justify-content-md-between]="showChangePageSizeCtrl"
  [class.justify-content-end]="!showChangePageSizeCtrl"
>
  <div
    class="d-flex mb-2 mb-md-0 align-items-center"
    *ngIf="showChangePageSizeCtrl"
  >
    <span class="venture__label widget__label mr-1">
      {{ 'UI.Pagination.ChangePageSizeLabel' | translate }}
    </span>
    <div container="body" ngbDropdown class="jip-dropdown">
      <div class="btn jip-dropdown__btn-select" ngbDropdownToggle>
        {{ pageSize }}
      </div>

      <div
        ngbDropdownMenu
        class="overflow-auto"
        [style.max-height.px]="maxDropdownHeight"
      >
        <button
          *ngFor="let pageSizeItem of pageSizeDropdownItems"
          ngbDropdownItem
          (click)="onChangePageSize(pageSizeItem)"
        >
          {{ pageSizeItem }}
        </button>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column flex-md-row align-items-center">
    <div class="d-flex mb-2 mb-md-0 align-items-center mr-3 mr-md-4">
      <span class="venture__label widget__label mr-1">{{
        'UI.Pagination.ChangePageLabel' | translate
      }}</span>
      <div container="body" ngbDropdown class="jip-dropdown">
        <div class="btn jip-dropdown__btn-select" ngbDropdownToggle>
          {{ page }}
        </div>

        <div
          ngbDropdownMenu
          class="overflow-auto"
          [style.max-height.px]="maxDropdownHeight"
        >
          <button
            *ngFor="let pageItem of pageDropdownItems"
            ngbDropdownItem
            (click)="onChangePage(pageItem)"
          >
            {{ pageItem }}
          </button>
        </div>
      </div>
    </div>

    <ngb-pagination
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      [maxSize]="maxSize"
      [rotate]="true"
      (pageChange)="onChangePage($event)"
      appPagingItem
    >
      <ng-template ngbPaginationPrevious>
        <i class="far fa-chevron-left"></i>
      </ng-template>

      <ng-template ngbPaginationNext>
        <i class="far fa-chevron-right"></i>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
