<div class="modal-header">
  <h4 class="modal-title" translate>Profile Detail</h4>
</div>
<div class="modal-body modal-body-confirmation">
  <div
    class="mb-3"
    [innerHTML]="
      'UI.EnforceProfile.Message'
        | translate
        | stringReplacement
          : {
              '{tenantName}':
                this.getTenantName()
            }
    "
  ></div>
  <div class="d-flex justify-content-center px-2 pt-2">
    <app-enforce-profile
      class="w-100"
      [isCreating]="false"
      [id]="id"
    ></app-enforce-profile>
  </div>
</div>
