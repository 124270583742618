import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  showStandard(text: string, header = ''): void {
    this.show(text, header);
  }

  showSuccess(text: string, header = '', delay = 5000): void {
    this.show(text, header, { classname: 'jip-success', delay });
  }

  showError(text: string, header = '', delay = 10000): void {
    this.show(text, header, { classname: 'jip-error', delay });
  }

  private show(
    textOrTpl: string | TemplateRef<any>,
    header = '',
    options: any = {}
  ): void {
    this.toasts.push({ textOrTpl, header, ...options });
  }

  remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  removeAll(): void {
    this.toasts = [];
  }
}
