import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { EventHttpService } from '@core/http/event-http.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityName } from '@shared/enums/entity-name.enum';
import { EventInterface } from '@shared/interfaces/event.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { UIPosition } from '@shared/models/ui.model';
import { EventUtils } from '@src/app/shared/utils/event-utils';
import { Observable } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-event-tiles',
  templateUrl: './event-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class EventTilesComponent extends BaseWidgetComponent implements OnInit {
  data$: Observable<ApiGetResponse<EventInterface>>;

  readonly entityPath = environment.jipUrl.event;

  readonly entityCreationPath = `${this.entityPath}/create`;

  readonly eventLocationFlagPos = new UIPosition('1.5rem', 'unset', '1.5rem');

  isLoading = false;

  skeletonCount = [];

  uiPosition = new UIPosition('unset', '0.75rem', 'unset', '0.5rem');

  constructor(
    public readonly searchEntitiesService: SearchEntitiesService,
    public readonly eventService: EventHttpService,
    protected readonly sessionService: SessionService
  ) {
    super(sessionService);

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: any) => arr.length),
      switchMap((res) => res[0].content),
      map((res: any) => {
        const mappedRes = {
          ...res,
          items: this.mapEventState(res.items),
        };

        return mappedRes;
      }),
      share()
    ) as Observable<ApiGetResponse<EventInterface>>;

    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.Event }];
    this.searchEntitiesService.currentFilter = EntityName.Event;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter = !this.org_id && this.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  afterJoinEvent(event: EventInterface): void {
    event.hasJoined = true;
  }

  mapEventState(events: EventInterface[]) {
    return events.map((event) => {
      return {
        ...event,
        isFullyBooked: EventUtils.isEventFullyBooked(event),
        isPassedEvent: EventUtils.isPassedEvent(event),
        isCancelled: EventUtils.isCancelledEvent(event),
      };
    });
  }
}
