import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@src/app/shared/components/form-custom-attributes/base-control/base-control.component';
import { LinkInterface } from '@src/app/shared/interfaces/attribute-description.interface';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinkComponent),
      multi: true,
    },
  ],
})
export class LinkComponent extends BaseControlComponent<LinkInterface> {
  @Input() regularExpression: string | RegExp;

  onLinkChange(newLink: string): void {
    super.onValueChange({
      ...this.value,
      url: newLink,
    });
  }
}
