import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MESSAGE_TRANSLATOR } from '@shared/constants/venture-submitted-to-challenge.const';
import { GetInTouchMessage } from '@shared/interfaces/getintouch-message.interface';
import { StringReplacementPipe } from '@shared/pipes/string-replacement.pipe';
import { ApplicationService } from '@src/app/core/services/application.service';
import { SessionService } from '@src/app/core/session.service';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import {
  EntityName,
  MessageAction,
  MessageType,
} from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { TranslateServiceLanguage } from '@src/app/shared/interfaces/language.interface';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import {
  LinkWrapperPipe,
  SpanWrapperPipe,
} from '@src/app/shared/pipes/link-wrapper.pipe';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-get-in-touch-message-item',
  templateUrl: './get-in-touch-message-item.component.html',
})
export class GetInTouchMessageItemComponent
  implements OnChanges, AfterContentInit
{
  @Input() message: GetInTouchMessage;

  @Input() uiTemplate: TemplateName;

  @Output() contentLoaded = new EventEmitter();

  portalName = environment.portalName;
  portalUrl = environment.portalUrl;
  peopleUrl = environment.jipUrl.people;

  entityUrlHtml = '';
  peopleUrlHtml = '';
  himUrlHtml = '';

  MessageAction = MessageAction;
  MessageType = MessageType;
  DateFormat = DateFormat;
  entityName = '';

  messageHtml = '';
  EntityName = EntityName;

  constructor(
    public sessionService: SessionService,
    private linkWrapperPipe: LinkWrapperPipe,
    private translateService: TranslateService,
    private spanWrapperPipe: SpanWrapperPipe,
    private readonly applicationService: ApplicationService,
    private readonly stringReplacementPipe: StringReplacementPipe
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.message?.currentValue) {
      await this.onMessageChange();
    }
  }

  ngAfterContentInit(): void {
    this.contentLoaded.emit();
  }

  get showMessageBody(): boolean {
    return (
      this.message.action === MessageAction.GetInTouch ||
      this.message.action === MessageAction.PendingRequest ||
      this.message.action === MessageAction.Shared
    );
  }

  get keyPeople(): PeopleInterface {
    if (this.message) {
      switch (this.message.action) {
        case MessageAction.UnavailablePendingMember:
        case MessageAction.Registered:
          return this.message.to;

        case MessageAction.VentureSubmittedToChallenge:
        case MessageAction.NewVentureSubmittedToChallenge:
        case MessageAction.AcceptedSubmittedVenture:
        case MessageAction.RefusedSubmittedVenture:
          return null;

        default:
          return this.message.from;
      }
    }
  }

  get canReply(): boolean {
    return this.message.action === MessageAction.GetInTouch;
  }

  async onMessageChange(): Promise<void> {
    if (this.message) {
      switch (this.message.messageType) {
        case MessageType.VentureMessage:
          this.entityName = EntityName.Venture;
          break;

        case MessageType.OrganizationMessage:
          this.entityName = EntityName.Organization;
          break;

        case MessageType.ChallengeMessage:
          this.entityName = EntityName.Challenge;
          break;

        case MessageType.EventMessage:
          this.entityName = EntityName.Event;
          break;

        case MessageType.PersonMessage:
          this.entityName = EntityName.Person;
          break;
      }

      if (
        this.message.content?.entityName ===
        EntityName.VentureSubmittedToChallenge
      ) {
        this.messageHtml = this.buildSubmittedVentureMessage(
          this.message.content,
          this.message.action
        );
        return;
      }

      const entityUrl = StringUtils.getEntityUrlByEntityName(this.entityName);
      const entityTranslate = this.translateService.instant(this.entityName);
      const deactiveUser = this.translateService.instant('UI.UnsubscribedUser');
      const spanDeactiveUser = this.spanWrapperPipe.transform(
        deactiveUser,
        'deactive-label'
      );

      if (
        this.entityName === EntityName.Organization &&
        this.message.action === MessageAction.Approved
      ) {
        const orgName = this.message.content?.orgName;

        this.entityUrlHtml = this.getUrlHtml(
          `${entityUrl}/${this.message.content?.instanceId}`,
          orgName
        );
      } else {
        this.entityUrlHtml = this.getUrlHtml(
          `${entityUrl}/${this.message.content?.instanceId}`,
          entityTranslate
        );
      }

      await this.handleSender(spanDeactiveUser);
      await this.handleReceiver(spanDeactiveUser);

      const haveVerb = this.isSender(this.message)
        ? this.translateService.instant('have')
        : this.translateService.instant('has');
      const pronoun =
        this.entityName === EntityName.Venture ? 'dieses' : 'diese';

      this.messageHtml = this.stringReplacementPipe.transform(
        this.translateService.instant(this.getHeaderMessage(this.message)),
        {
          '{link}': this.entityUrlHtml,
          '{name}': this.peopleUrlHtml,
          '{him}': this.himUrlHtml,
          '{have}': haveVerb,
          '{pronoun}': pronoun,
        }
      );
    }
  }

  async handleSender(spanDeactiveUser: string): Promise<void> {
    if (this.message.from) {
      const senderUrl = await this.handlePersonUrlWithEncryptedCode(
        this.message.from
      );
      const himTranslate = `${this.translateService.instant(
        'UI.Common.him'
      )}/${this.translateService.instant('UI.Common.her')}`;
      this.himUrlHtml = this.getUrlHtml(senderUrl, himTranslate);
    } else {
      this.himUrlHtml = spanDeactiveUser;
    }
  }

  getUrlHtml(url: string, translateText: string): string {
    const finalUrl = this.sessionService.appendLanguagePath(url);
    return this.linkWrapperPipe.transform(
      finalUrl,
      translateText,
      'appUnsavedFormCheck',
      'card-action__link'
    );
  }

  async handleReceiver(spanDeactiveUser: string): Promise<void> {
    if (this.message.to) {
      const receiverUrl = await this.handlePersonUrlWithEncryptedCode(
        this.message.to
      );
      let receiverName =
        this.message.to.firstName + ' ' + this.message.to.lastName;
      if (this.message.action === MessageAction.PendingRequest) {
        receiverName = 'Person';
        if (this.translateService.currentLang === TranslateServiceLanguage.en) {
          receiverName = receiverName.toLowerCase();
        }
      }
      this.peopleUrlHtml = this.getUrlHtml(receiverUrl, receiverName);
    } else {
      this.peopleUrlHtml = spanDeactiveUser;
    }
  }

  getHeaderMessage(message: GetInTouchMessage): string {
    let result = '';
    switch (message.action) {
      case MessageAction.GetInTouch:
        result =
          this.sessionService.isLogin && this.isReceiver(message)
            ? 'Get-In-Touch-With-You-On-Entity'
            : 'Get-In-Touch-With-Someone-On-Entity';
        break;

      case MessageAction.UnavailablePendingMember:
        result = 'UI.NewMessage.Venture.UnavailablePendingMember';
        break;

      case MessageAction.Approved:
        if (this.entityName === EntityName.Organization) {
          result = this.getMessageByPermission(
            message,
            'UI.NewMessage.ApprovePendingOrgMemberForAdmin',
            'UI.NewMessage.ApprovePendingOrgMemberForOtherAdmin',
            'UI.NewMessage.ApprovePendingOrgMember'
          );
        } else {
          result = this.getMessageByPermission(
            message,
            'UI.NewMessage.ApprovePendingMemberForAdmin',
            'UI.NewMessage.ApprovePendingMemberForOtherAdmin',
            'UI.NewMessage.ApprovePendingMember'
          );
        }
        break;

      case MessageAction.Rejected:
        result = this.getMessageByPermission(
          message,
          'UI.NewMessage.RejectPendingMemberForAdmin',
          'UI.NewMessage.RejectPendingMemberForOtherAdmin',
          'UI.NewMessage.RejectPendingMember'
        );
        break;

      case MessageAction.PendingRequest:
        result =
          message.messageType === MessageType.OrganizationMessage
            ? 'UI.NewMessage.PendingMemberOrganizationForAdmin'
            : 'UI.NewMessage.PendingMemberForAdmin';
        break;

      case MessageAction.RemovedMember:
        result = this.getMessageByPermission(
          message,
          'UI.NewMessage.RemovedMemberForAdmin',
          'UI.NewMessage.RemovedMemberForOtherReceiver',
          this.entityName === EntityName.Event
            ? 'UI.NewMessage.RemovedMemberInEvent'
            : 'UI.NewMessage.RemovedMember'
        );
        break;

      case MessageAction.Registered:
        result = 'UI.NewMessage.RegisteredMember';
        break;

      case MessageAction.RemovedMyselfFromMember:
        if (this.entityName === EntityName.Event) {
          result =
            this.sessionService.isLogin && this.isSender(message)
              ? 'UI.NewMessage.RemovedMyselfFromMemberInEvent'
              : 'UI.NewMessage.RemovedMyselfFromMemberForOthersInEvent';
        } else {
          result =
            this.sessionService.isLogin && this.isSender(message)
              ? 'UI.NewMessage.RemovedMyselfFromMember'
              : 'UI.NewMessage.RemovedMyselfFromMemberForOthers';
        }
        break;

      case MessageAction.Shared:
        result = 'UI.MessageLabel.SharedThis';
        break;
    }
    return result;
  }

  buildSubmittedVentureMessage(content: any, action: number): string {
    const ventureUrl = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.venture}/${content.instanceId}`
    );
    const ventureTranslatedText = this.translateService.instant(
      EntityName.Venture
    );

    let orgUrl: string;
    let orgTranslatedText: string;

    if (content?.showAsChallenge || content?.organization?.showAsChallenge) {
      orgUrl = this.sessionService.appendLanguagePath(
        `${environment.jipUrl.challenges}/${content?.organization?.id}`
      );
      orgTranslatedText = this.translateService.instant(EntityName.Challenge);
    } else {
      orgUrl = this.sessionService.appendLanguagePath(
        `${environment.jipUrl.organizations}/${content?.organization?.id}`
      );
      orgTranslatedText = this.translateService.instant(
        EntityName.Organization
      );
    }

    const replacementOptions = {
      '{venture}': this.wrapLink(ventureUrl, ventureTranslatedText),
      '{challenge}': this.wrapLink(orgUrl, orgTranslatedText),
    };

    return this.stringReplacementPipe.transform(
      this.translateService.instant(MESSAGE_TRANSLATOR[action]),
      replacementOptions
    );
  }

  private isReceiver(message: GetInTouchMessage): boolean {
    return message.to && message.to.id === this.sessionService.currentUser?.id;
  }

  private isOtherReceiver(message: GetInTouchMessage): boolean {
    return !this.isSender(message) && !this.isReceiver(message);
  }

  wrapLink(
    url: string,
    displayText: string,
    className = 'card-action__link'
  ): string {
    const link = this.linkWrapperPipe.transform(url, displayText);
    if (className) {
      return this.spanWrapperPipe.transform(link, className);
    } else {
      return link;
    }
  }

  private isSender(message: GetInTouchMessage): boolean {
    return (
      message.from && message.from.id === this.sessionService.currentUser?.id
    );
  }

  private getMessageByPermission(
    message: GetInTouchMessage,
    forSenderMess: string,
    forOtherReceiverMess: string,
    defaultMess: string
  ): string {
    if (this.sessionService.isLogin) {
      if (this.isSender(message)) {
        return forSenderMess;
      } else if (this.isOtherReceiver(message)) {
        return forOtherReceiverMess;
      }
    }
    return defaultMess;
  }

  private async handlePersonUrlWithEncryptedCode(
    person: PeopleInterface
  ): Promise<string> {
    const personId = person.id;
    const shouldByPassEncrypt =
      !person.isPrivate || this.sessionService.currentUser?.id === person.id;

    let personUrl = `${this.peopleUrl}/${personId}`;

    if (shouldByPassEncrypt) {
      return personUrl;
    }

    try {
      const encryptedCode = await this.applicationService
        .encryptInfo(`${EntityName.Person}-${personId}`)
        .toPromise();
      if (encryptedCode) {
        personUrl = `${personUrl}?${UrlParam.Code}=${encryptedCode}`;
      }
    } catch (error) {
      console.log(error);
    }
    return personUrl;
  }
}
