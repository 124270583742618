<!-- Define the recursive template. -->

<div *ngFor="let item of items" class="node">
  <ng-template
    [ngTemplateOutlet]="nodeTemplateRef"
    [ngTemplateOutletContext]="{ node: item }"
  ></ng-template>
  <div *ngIf="item?.children?.length" class="node__children">
    <ng-container *ngFor="let child of item.children">
      <!-- Invoke the recursive template. -->
      <ng-template
        [ngTemplateOutlet]="nodeTemplateRef"
        [ngTemplateOutletContext]="{ node: child }"
      ></ng-template>
    </ng-container>
  </div>
</div>

<!-- Initiate the recursive template rendering. -->
<ng-template #nodeTemplateRef let-node="node">
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      [id]="'customCheckbox' + node.text"
      [disabled]="disabled"
      name="customCheck"
      [ngModel]="node.checked"
      (ngModelChange)="selectNode(node, $event)"
    />
    <label
      class="pl-2 custom-control-label flex-grow-1"
      [for]="'customCheckbox' + node.text"
    >
      {{ node.text | translate }}
    </label>
  </div>
</ng-template>
