<i
  class="fas fa-info-circle pointer-cursor"
  (click)="isCollapsed = !isCollapsed"
></i>

<div
  class="mt-2 mb-3"
  #collapse="ngbCollapse"
  [(ngbCollapse)]="isCollapsed"
  [@expandCollapse]="isCollapsed ? 'closed' : 'open'"
>
  <quill-view
    class="font-weight-normal font-italic"
    [content]="content"
  ></quill-view>
</div>
