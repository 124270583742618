<ng-container *ngIf="!isRegisteredAccount; else alreadySentConfirmationEmail">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="firstName">{{ 'UI.Common.FirstName' | translate }}</label>
          <input
            id="firstName"
            type="text"
            class="form-control"
            formControlName="firstName"
          />
          <div
            *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="firstName.errors.required" translate>
              First Name is required.
            </div>
            <div *ngIf="firstName.errors.notEmptyOrWhitespace">
              {{ 'UI.Common.FirstName' | translate }}
              {{ 'cannot be empty or whitespace' | translate }}.
            </div>
            <div *ngIf="firstName.errors.jip_firstName" translate>
              {{ errorMessage ? errorMessage.firstName : '' }}
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="lastName">{{ 'UI.Common.LastName' | translate }}</label>
          <input
            id="lastName"
            type="text"
            class="form-control"
            formControlName="lastName"
          />
          <div
            *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
            class="alert alert-danger"
          >
            <div *ngIf="lastName.errors.required" translate>
              Last Name is required.
            </div>
            <div *ngIf="lastName.errors.notEmptyOrWhitespace" translate>
              {{ 'UI.Common.LastName' | translate }}
              {{ 'cannot be empty or whitespace' | translate }}.
            </div>
            <div *ngIf="lastName.errors.jip_lastName" translate>
              {{ errorMessage ? errorMessage.lastName : '' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="username"> {{ 'Username' | translate }}</label>
      <input
        id="username"
        type="text"
        class="form-control"
        formControlName="username"
      />
      <div
        *ngIf="username.invalid && (username.dirty || username.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="username.errors.required" translate>
          User Name is required.
        </div>
        <div *ngIf="username.errors.notEmptyOrWhitespace">
          {{ 'Username' | translate }}
          {{ 'cannot be empty or whitespace' | translate }}.
        </div>
        <div *ngIf="username.errors.cannotContainWhitespace">
          {{ 'Username' | translate }}
          {{ 'cannot contain whitespace' | translate }}.
        </div>
        <div *ngIf="username.errors.jip_username" translate>
          {{ errorMessage ? errorMessage.username : '' }}
        </div>
      </div>
      <small>
        {{ 'UI.Validation.NoSpace' | translate }}
      </small>
    </div>

    <div class="form-group">
      <label for="email" translate>Email</label>
      <input
        id="email"
        type="text"
        class="form-control"
        formControlName="email"
      />
      <div
        *ngIf="email.invalid && (email.dirty || email.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="email.errors.required" translate>Email is required.</div>
        <div *ngIf="email.errors.email" translate>
          {{ 'Invalid email address' }}
        </div>
        <div *ngIf="email.errors.jip_email" translate>
          {{ errorMessage ? errorMessage.email : '' }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="password" translate>Password</label>
      <div class="input-group">
        <input
          id="password"
          [type]="passwordIsShow ? 'text' : 'password'"
          class="form-control"
          formControlName="password"
        />
        <div class="input-group-append">
          <a href="" class="input-group-text" (click)="togglePassword($event)">
            <i
              class="fa"
              [class]="{
                'fa-eye-slash': passwordIsShow,
                'fa-eye': !passwordIsShow
              }"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>

      <div
        *ngIf="password.invalid && (password.dirty || password.touched)"
        class="alert alert-danger"
      >
        <div *ngIf="password.errors.required">
          {{ 'UI.Validation.PasswordIsRequired' | translate }}
        </div>
        <div *ngIf="password.errors.notEmptyOrWhitespace" translate>
          {{ 'Password' | translate }}
          {{ 'cannot be empty or whitespace' | translate }}.
        </div>
        <div *ngIf="password.errors.jip_password" translate>
          {{ errorMessage.password }}
        </div>
      </div>
      <small>
        {{ 'UI.Validation.PasswordHint' | translate }}
        <b><span>#$^+=!*()&#64;%&.,+-_<></span></b></small
      >
    </div>

    <div class="form-group">
      <label class="d-block" for="passwordRepeat" translate
        >Re-type Password</label
      >
      <div class="input-group">
        <input
          id="passwordRepeat"
          [type]="passwordRepeatIsShow ? 'text' : 'password'"
          class="form-control"
          formControlName="passwordRepeat"
        />
        <div class="input-group-append">
          <a
            href=""
            class="input-group-text"
            (click)="togglePasswordRepeat($event)"
          >
            <i
              class="fa"
              [class]="{
                'fa-eye-slash': passwordRepeatIsShow,
                'fa-eye': !passwordRepeatIsShow
              }"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </div>

      <div
        *ngIf="
          passwordRepeat.invalid &&
          (passwordRepeat.dirty || passwordRepeat.touched)
        "
        class="alert alert-danger"
      >
        <div *ngIf="passwordRepeat.errors.required">
          {{ 'UI.Validation.PasswordIsRequired' | translate }}
        </div>
      </div>
      <div
        *ngIf="
          passwordRepeat.value &&
          loginForm.errors?.passwordNotSame &&
          (passwordRepeat.dirty || passwordRepeat.touched)
        "
        class="alert alert-danger"
      >
        <div translate>Password does not match.</div>
      </div>
    </div>

    <div class="form-group confirm-account-recaptcha">
      <app-google-recapcha [formInput]="loginForm"></app-google-recapcha>
    </div>

    <div class="form-group">
      <div class="form-check mb-4">
        <app-term-agreement-checkbox
          [termOfUseUrl]="termOfUseUrl"
          [portalName]="portalName"
          formControlName="termAgreement"
        ></app-term-agreement-checkbox>
      </div>
    </div>

    <div
      *ngIf="
        loginForm.errors &&
        loginForm.errors[formErrorKey] &&
        (loginForm.dirty || loginForm.touched)
      "
      class="alert alert-danger"
      translate
    >
      <div [innerHTML]="errorMessage[formErrorKey] | safeHtml"></div>
    </div>

    <div class="form-group text-center">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="
          !loginForm.valid ||
          isSubmitting ||
          isSubmitted ||
          !(loginForm?.touched || loginForm?.dirty)
        "
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span translate> Register</span>
      </button>
    </div>
  </form>
</ng-container>
<ng-template #alreadySentConfirmationEmail>
  <div class="email-confirmation">
    <div class="email-confirmation__info mt-4">
      <span
        class="info-text"
        [innerHTML]="
          'UI.Registration.Inform-email-sent'
            | translate
            | stringReplacement
              : {
                  '{portalName}':
                    portalUrl | linkWrapper : portalName,
                  '{userEmail}':
                    this.sentEmailAddress | emailWrapper : this.sentEmailAddress
                }
        "
      ></span>
      <span class="info-text" translate
        >UI.Registration.Inform-follow-link</span
      >
      <span class="mt-3">
        <b translate>UI.Registration.Didnot-receive-email</b>
        <span class="ml-2" translate
          >UI.Registration.Please-check-your-spam</span
        >
      </span>
    </div>

    <div class="form-group text-center email-confirmation__buttons">
      <button
        type="button"
        appDebounceClick
        (debounceClick)="resendConfirmationEmail(this.sentEmailAddress)"
        class="btn btn-secondary"
        [disabled]="isSubmitting"
      >
        <ng-container *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </ng-container>
        <span>{{ 'UI.Registration.ResendEmail' | translate }}</span>
      </button>
      <a href="/" class="btn btn-primary ml-3">
        {{ 'UI.Registration.OK' | translate }}
      </a>
    </div>
  </div>
</ng-template>
