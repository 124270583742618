<footer class="footer">
  <div class="container">
    <div class="footer__wrapper">
      <p class="footer__terms">
        <a href="https://building-excellence.ch/datenschutzerklaerung">
          <span>{{ 'UI.TermsOfUse' | translate }}</span>
        </a>
      </p>

      <p class="footer__copyright">
        <app-version-info></app-version-info> &copy; {{ currentYear }} by
        {{ appName }}
      </p>

      <p class="footer__powered-by">
        <app-power-by-bbv></app-power-by-bbv>
      </p>
    </div>
  </div>
</footer>
<!-- #colophon -->
