import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat } from '@shared/enums/date.enum';
import { TranslateServiceLanguage } from '@shared/interfaces/language.interface';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(value: Date | string, format = 'mediumDate'): string {
    const datePipe = new DatePipe(this.translateService.currentLang || TranslateServiceLanguage.en);
    return (
      this.translateService.currentLang === TranslateServiceLanguage.de
      && (format === DateFormat.FullDateTimeCommon || format === DateFormat.FullDateTimeCommon2)
    )
      ? datePipe.transform(value, format)?.replace('.', '')
      : datePipe.transform(value, format);
  }
}
