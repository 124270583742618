import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ChallengeTimelineAttributeName,
  ChallengeTimelinePhase,
} from '@shared/enums/challenge.enum';
// app
import { AttributeDescription } from '@shared/interfaces/attribute-description.interface';
import { TimelineItem } from '@shared/interfaces/timeline-item.interface';
import { environment } from '@src/environments/environment';
import { TimeUtils } from '../../utils/time-utils';
import { CustomDateParser2Formatter } from '../boostrap-datepicker/boostrap-datepicker.component';

export enum SiblingItemIndex {
  Prev = -1,
  Next = 1,
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styles: [],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class TimelineComponent implements OnChanges {
  @Input() editing = false;
  @Input() data: AttributeDescription[];
  @Input() timelineDescription: AttributeDescription;

  @Output() valueChange = new EventEmitter<TimelineItem[]>();

  readonlyAttributes = [ChallengeTimelineAttributeName.Name];
  timelineAttributes: AttributeDescription[] = [];

  items: TimelineItem[] = [];
  errors = [];
  duplicateItems: boolean[] = [];
  isTest = environment.test || environment.dev;
  advancedEditing: boolean;

  constructor(
    private readonly translateService: TranslateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.data && changes.data.currentValue) ||
      (changes.timelineDescription && changes.timelineDescription.currentValue)
    ) {
      if (
        changes.timelineDescription &&
        changes.timelineDescription.currentValue &&
        changes.timelineDescription.isFirstChange() &&
        this.timelineDescription?.relatedEntityDefaultValues
      ) {
        // Midnight Phase Date now generate from Frontend site to handle timezone and daylight saving time
        this.timelineDescription?.relatedEntityDefaultValues.forEach(
          (item: TimelineItem) => {
            item.phaseDate = this.getDefaultPhaseDate(item.nameKey);
          }
        );
      }

      this.setTimelineAttributes();
      this.generateItems();
    }
  }

  generateItems(): void {
    const defaultItems: TimelineItem[] =
      this.data?.length > 0
        ? this.data
        : this.timelineDescription?.relatedEntityDefaultValues ?? [];

    const sortedItems = [...defaultItems].sort(
      (item1: TimelineItem, item2: TimelineItem) => item1.order - item2.order
    );

    sortedItems.forEach((item) => {
      item.tooltip = this.getTooltip(item.nameKey);
      return item;
    });

    this.items = [...sortedItems];
    this.cd.detectChanges();
  }

  validateDuplicateValues(): void {
    const phaseNames = this.items.map(({ name }) => name).filter(Boolean);
    const duplicateNames = phaseNames.filter(
      (item, index) => phaseNames.indexOf(item) !== index
    );

    this.duplicateItems =
      this.items.map((item) => duplicateNames.includes(item.name))?.slice() ??
      [];
  }

  emitValue(data: TimelineItem, index: number): void {
    if (!data || !this.items[index]) {
      return;
    }

    const items = [...this.items];
    items[index].phaseDate = data.phaseDate;
    items[index].name = data.name;

    this.items = items.map((item, i) => {
      item.isValid = true;

      if (i === index) {
        item.isValid = this.isValidInput(items, index);
      }

      return item;
    });

    this.valueChange.emit(this.items);
  }

  isValidInput(items: TimelineItem[], index: number): boolean {
    if (!Array.isArray(items)) {
      return false;
    }

    if (this.hasError(items, index)) {
      return false;
    }

    return this.checkAllItems(items) || true;
  }

  hasError(items: TimelineItem[], index: number): boolean {
    const item: TimelineItem = items[index];
    const haveDuplicatedName = !!this.items.find(
      (x) => x.name === item?.name && x.nameKey !== item?.nameKey
    );

    if (!item.name || haveDuplicatedName) return true;

    if (this.shouldResetPhaseError(item)) {
      this.errors[index] = '';
      return false;
    }

    return (
      this.hasErrorWithSiblingItem(items, index, SiblingItemIndex.Prev) ||
      this.hasErrorWithSiblingItem(items, index, SiblingItemIndex.Next)
    );
  }

  checkAllItems(items: TimelineItem[]): boolean {
    for (let i = 0; i < items.length; i++) {
      if (this.hasError(items, i)) {
        return false;
      }
    }
  }

  shouldResetPhaseError(item: TimelineItem): boolean {
    return !item || (item.isOptional && !item.phaseDate);
  }

  hasErrorWithSiblingItem(
    items: TimelineItem[],
    index: number,
    siblingItemIndex: SiblingItemIndex
  ): boolean {
    const item: TimelineItem = items[index];
    const siblingItem: TimelineItem = this.getItem(
      items,
      index,
      siblingItemIndex
    );
    const phaseDate = TimeUtils.getMidnightDate(new Date(item?.phaseDate));
    const siblingPhaseDate = TimeUtils.getMidnightDate(
      new Date(siblingItem?.phaseDate)
    );

    if (siblingItemIndex === SiblingItemIndex.Prev) {
      if (siblingItem && phaseDate <= siblingPhaseDate) {
        this.errors[index] = {
          PhaseDate: `Date should be after ${siblingItem.name}`,
        };

        return true;
      }
    } else if (siblingItem && phaseDate >= siblingPhaseDate) {
      this.errors[index] = {
        PhaseDate: `Date should be before ${siblingItem.name}`,
      };

      return true;
    }

    this.errors[index] = '';
    return false;
  }

  getItem(
    items: TimelineItem[],
    index: number,
    direction: SiblingItemIndex.Prev | SiblingItemIndex.Next
  ): TimelineItem {
    let foundIndex = index + direction;
    let item: TimelineItem = items[foundIndex];

    for (
      ;
      this.shouldResetPhaseError(item) &&
      foundIndex > -1 &&
      foundIndex < items.length;

    ) {
      foundIndex += direction;
      item = items[foundIndex];
    }

    return item;
  }

  cancelEditing(): void {
    this.advancedEditing = false;
  }

  /* Private */
  private getTooltip(phaseKey: ChallengeTimelinePhase | string): string {
    switch (phaseKey) {
      case ChallengeTimelinePhase.Submission:
        return this.translateService.instant(
          'UI.VenturePhase.SubmissionPhaseStartDate'
        );
      case ChallengeTimelinePhase.JointCreating:
        return this.translateService.instant(
          'UI.VenturePhase.JointcreatingPhaseStartDate'
        );
      case ChallengeTimelinePhase.Evaluation:
        return this.translateService.instant(
          'UI.VenturePhase.EvaluationPhaseStartDate'
        );
      case ChallengeTimelinePhase.Awarding:
        return this.translateService.instant('UI.VenturePhase.AwardingDate');
      case ChallengeTimelinePhase.Closing:
        return this.translateService.instant(
          'UI.VenturePhase.ChallengeHideDate'
        );
      default:
        return '';
    }
  }

  private setTimelineAttributes(): void {
    this.timelineAttributes =
      this.timelineDescription?.relatedEntityAttributeDescriptions?.filter(
        (attribute) =>
          attribute.isVisibleInSystem &&
          (attribute.propertyName === ChallengeTimelineAttributeName.Name ||
            attribute.propertyName === ChallengeTimelineAttributeName.PhaseDate)
      );
  }

  private getDefaultPhaseDate(
    phaseKey: ChallengeTimelinePhase | string
  ): string {
    const currentDateMidnight = TimeUtils.getMidnightDate();
    const startPhaseDate = TimeUtils.addDays(currentDateMidnight, 7);
    if (TimeUtils.isValidDate(startPhaseDate)) {
      switch (phaseKey) {
        case ChallengeTimelinePhase.Submission:
          return startPhaseDate.toISOString();

        case ChallengeTimelinePhase.JointCreating:
          return null;

        case ChallengeTimelinePhase.Evaluation:
          return TimeUtils.addDays(startPhaseDate, 7).toISOString();

        case ChallengeTimelinePhase.Awarding:
          return TimeUtils.addDays(startPhaseDate, 14).toISOString();

        case ChallengeTimelinePhase.Closing:
          return TimeUtils.addDays(startPhaseDate, 21).toISOString();
      }
    }
    return null;
  }
}
