import { PeopleInterface } from '@shared/interfaces/people.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { BaseEntityInterface } from './base/base-entity.interface';

export interface NewsMessageInterface extends BaseEntityInterface {
  content?: {
    entityName: 'Event' | string;
    eventTitle: '';
    eventDateTime: '2020-09-24T06:56:07Z';
    description: '';
    entityId: number;
    instanceId: number;
    uiTemplate?: TemplateName;
  };
  uiTemplate?: TemplateName;
  messageType: string;
  action: number;
  dateTime: string;
  message?: string;
  image?: string;
  from?: PeopleInterface;
  to?: PeopleInterface;
  isGroup?: boolean;
  group: { entityName; name; title; toItems: UserInterface[] | any };
  replyCount: number;
  replies?: NewsMessageInterface[];
  latestMessage: NewsMessageInterface;
  isSystemNews?: boolean;
  isLoading?: boolean;
  type?: NewsMessageType;
  isRepliable?: boolean;
}

export interface MessagePayload {
  type: string;
  ids: number[];
  isSendMail?: boolean;
}

export interface CustomMessageInterface {
  entityName: string;
  entityId: number;
  action: string;
}

export enum NewsMessageType {
  News = 'News',
  Message = 'Message',
}
