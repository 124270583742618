import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { NavInterface } from '@shared/interfaces/nav.interface';
import { NewsMessagesHttpService } from '@src/app/core/http/news-messages-http.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-news-messages',
  templateUrl: './nav-news-messages.component.html',
})
export class NavNewsMessagesComponent implements OnInit, OnDestroy {
  @Input() wrapperClass = ''
  newsLength = 0;
  isLogin$: Observable<boolean>;

  navItem: NavInterface = {
    name: 'Messages',
    faIcon: 'fas fa-comment-alt',
    route: environment.jipUrl.messages,
  };

  constructor(
    private newsMessagesHttpService: NewsMessagesHttpService,
    private sessionService: SessionService,
    private authenService: AuthenService
  ) {}

  ngOnInit(): void {
    this.isLogin$ = this.authenService.isLogin();
    this.loadData();
  }

  loadData(): void {
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (apiReady) {
          this.loadItems();
        }
      });
  }

  loadItems(): void {
    this.isLogin$.subscribe((login) => {
      if (!login) {
        return;
      }
      this.newsMessagesHttpService.getCounts().subscribe((counts) => {
        this.newsLength = counts;
      });
    });
  }

  ngOnDestroy(): void {
    /**/
  }
}
