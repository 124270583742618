<div class="news-card-footer venture__footer">
  <ul class="list-inline d-flex justify-content-between venture__actions">
    <app-like-entity
      [entity]="news"
      (triggerToggleLike)="toggleLike(news)"
      (triggerShowLikingsData)="showLikes(news)"
    ></app-like-entity>

    <app-send-message-btn
      *ngIf="
        news.action !== MessageAction.VentureAwarded &&
        currentPersonId !== news.from?.id
      "
      class="margin-left-percent-20"
      [receiver]="news.from"
    >
    </app-send-message-btn>

    <app-comment-entity
      class="margin-left-percent-20"
      [entity]="news"
      [entityName]="EntityName.News"
      (afterCommentModalClosed)="onCommentModalClosed(news)"
    ></app-comment-entity>
  </ul>
</div>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [modalTitle]="'UI.Likes.Message' | translate"
  [httpService]="newsService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
