<div class="map-view__container mt-2">
  <div *ngIf="secureMap; else mapView" [innerHTML]="secureMap"></div>
  <ng-template #mapView>
    <div *ngIf="!hideLink" class="mb-3">
      <a
        [href]="googleMapUrl"
        appUrlPrefix
        class="underline"
        target="_blank"
        translate
      >
        {{ placeholder }}
      </a>
    </div>

    <map-view
      *ngIf="mapObject"
      [boundsPadding]="100"
      [fitBounds]="mapObject?.viewport"
      [latitude]="mapObject?.latitude"
      [longitude]="mapObject?.longitude"
      [zoom]="mapObject?.zoom"
      [streetViewControl]="false"
    >
      <map-marker
        [latitude]="mapObject?.latitude"
        [longitude]="mapObject?.longitude"
      >
      </map-marker>
    </map-view>
  </ng-template>
</div>
