import { Component, Input } from '@angular/core';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';

@Component({
  selector: 'app-entity-card-container',
  templateUrl: './entity-card-container.component.html',
  styles: [],
})
export class EntityCardContainerComponent {
  @Input() entityName: string;
  @Input() entity: any;
  @Input() uiTemplate: TemplateName;

  EntityName = EntityName;
}
