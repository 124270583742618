<div
  role="group"
  class="btn-group toggle-button-slider"
  [ngClass]="{
    'button-toggle--active': status,
    'button-toggle--disabled': settings && settings?.disabled
  }"
>
  <button
    appRequireLogin
    [bypassRequiredLogin]="!shouldRequiredLogin"
    (click)="toggle(true)"
    type="button"
    class="btn toggle-button-slider__btn"
    [class]="status ? 'btn-primary' : 'btn-outline-secondary'"
    [ngClass]="settings?.activeClass"
    translate
  >
    <span *ngIf="settings?.activeIcon"
      ><i [class]="settings?.activeIcon"></i>
    </span>
    {{ settings?.activeText || 'UI.Common.Activated' }}
  </button>
  <button
    appRequireLogin
    [bypassRequiredLogin]="!shouldRequiredLogin"
    (click)="toggle(false)"
    type="button"
    class="btn toggle-button-slider__btn"
    [class]="status ? 'btn-outline-secondary' : 'btn-primary'"
    translate
  >
    <span *ngIf="settings?.deactiveIcon"
      ><i [class]="settings?.deactiveIcon"></i>
    </span>
    {{ settings?.deactiveText || 'UI.Common.Deactivated' }}
  </button>
</div>
