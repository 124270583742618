<div class="modal-header">
  <div>
    <h4 class="modal-title" translate>{{ modalTitle }}</h4>
  </div>

  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss()"
  >
    <span aria-hidden="true"> &times; </span>
  </button>
</div>

<div class="modal-body modal-body-minspace">
  <ng-container [ngSwitch]="entityName">
    <ng-container *ngSwitchCase="EntityName.Event">
      <app-shared-events-client
        *ngIf="!isLoading; else loading"
        [events]="entityItems"
        [httpService]="httpService"
      >
      </app-shared-events-client>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Organization">
      <app-venture-organizations
        *ngIf="!isLoading; else loading"
        [editting]="false"
        [items]="entityItems"
        [emptyTextChallenges]="'UI.Challenge.EmptyChallenges'"
        [emptyTextOrganizations]="'UI.Organization.EmptyOrganizations'"
      ></app-venture-organizations>
    </ng-container>
    <ng-container *ngSwitchCase="EntityName.Venture">
      <app-shared-ventures-client
        *ngIf="!isLoading; else loading"
        [ventures]="entityItems"
        [httpService]="httpService"
      >
      </app-shared-ventures-client>
    </ng-container>
  </ng-container>
</div>
<ng-template #loading>
  <div class="spinner-loading">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
</ng-template>
