import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
// app
import { newNavs } from '@app/configs';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { NavInterface } from '@shared/interfaces/nav.interface';
import { GlobalFilterComponent } from '@src/app/components/master-layout/navs/components/global-filter/global-filter.component';
import { SearchEntitiesService } from '@src/app/components/search-results/services/search-entities.service';
import { CommunitySelectorStoredService } from '@src/app/core/services/community-selector-stored.service';
import { I18nService } from '@src/app/core/i18n/i18n.service';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { BaseComponentWithServiceComponent } from '../../base/base-list/base-component-with-service/base-component-with-service.component';

@Component({
  selector: 'app-navs',
  templateUrl: './navs.component.html',
})
export class NavsComponent
  extends BaseComponentWithServiceComponent
  implements OnInit
{
  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  navs: NavInterface[] = newNavs;
  isNavsActivated = false;
  isSearchActivated = false;
  globalFilterRegisterEvent: any;
  isVisibleFilterButton = false;
  orgUrl = environment.jipUrl.organizations;

  profile: UserInterface;
  isShowSearchBar = false;
  searchText = '';

  homeNav = { route: '/', name: 'Home', jipIcon: 'home' };

  @ViewChild('globalFilter') globalFilter: GlobalFilterComponent;
  navDropdownItems: NavInterface[];

  selectedOrg;

  get tenantLogo(): string {
    return this.sessionService.getTenantLogo();
  }

  constructor(
    public sessionService: SessionService,
    public authenService: AuthenService,
    private breakpointObserver: BreakpointObserver,
    public communitySelectorStored: CommunitySelectorStoredService,
    readonly searchEntitiesService: SearchEntitiesService,
    private i18nService: I18nService
  ) {
    super(sessionService);

    this.listenToViewportChange();
  }

  ngOnInit(): void {
    this.sessionService.isHeaderVisible = true;

    const globalSearchParam = StringUtils.getParamFromUrl(
      UrlParam.GlobalSearchTab
    );
    if (globalSearchParam) {
      this.isShowSearchBar = true;
    }

    this.communitySelectorStored.selectedOrg
      .pipe(untilDestroyed(this))
      .subscribe((org) => {
        this.selectedOrg = org;
      });

    this.communitySelectorStored.keyword$
      .pipe(untilDestroyed(this))
      .subscribe((keyword: string) => {
        this.searchText = keyword;
      });
  }

  loadProfile(): void {
    this.authenService.getProfile().subscribe((profile) => {
      this.profile = profile;
    });
  }

  toggleNavs(): void {
    this.isNavsActivated = !this.isNavsActivated;
  }

  toggleSearch(): void {
    this.isSearchActivated = !this.isSearchActivated;
  }

  toggleSearchBar(): void {
    this.isShowSearchBar = !this.isShowSearchBar;
  }

  navigateToActiveInnovationSpaceDetail(id = null) {
    if (id) {
      FormUtils.navigateToWithLanguagePath(
        this.sessionService,
        this.orgUrl + '/' + id
      );
    } else {
      FormUtils.navigateToWithLanguagePath(this.sessionService, '/');
    }
  }

  onSearch() {
    // this.globalFilter.search();
    this.communitySelectorStored.search();
  }

  onClearText() {
    this.communitySelectorStored.setKeyword('');
    this.communitySelectorStored.search();

    // this.globalFilter?.resetSearch();
  }

  onSearchTextChange(event) {
    console.log({ event });
    this.communitySelectorStored.setKeyword(event);
  }

  private listenToViewportChange() {
    this.breakpointObserver
      .observe([
        '(max-width: 1920px)',
        '(max-width: 1024px)',
        '(max-width: 768px)',
        '(max-width: 576px)',
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(max-width: 576px)']) {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        } else if (state.breakpoints['(max-width: 768px)']) {
          this.navs = [];
          this.navDropdownItems = [...newNavs];
        } else if (state.breakpoints['(max-width: 1024px)']) {
          this.navs = newNavs.filter(
            (nav) =>
              nav.route === environment.jipUrl.news ||
              nav.route === environment.jipUrl.challenges
          );
          this.navDropdownItems = newNavs.filter(
            (nav) =>
              nav.route !== environment.jipUrl.news &&
              nav.route !== environment.jipUrl.challenges
          );
        } else if (state.breakpoints['(max-width: 1920px)']) {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        } else {
          this.navs = [...newNavs];
          this.navDropdownItems = [];
        }
      });
  }

  shouldActiveMore(): boolean {
    const currentUri = location.origin + location.pathname;
    const locale = this.i18nService.language?.locale.toLowerCase();

    return this.navDropdownItems.some(({ route }) => {
      const itemRoute = location.origin + '/' + locale + route;
      return (
        currentUri.includes(itemRoute) &&
        currentUri.length - itemRoute.length < 2
      );
    });
  }
}
