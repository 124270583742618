import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { ChallengeTimelineAttributeName } from '@src/app/shared/enums/challenge.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { TimelineItem } from '@src/app/shared/interfaces/timeline-item.interface';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[app-dynamic-object]',
  templateUrl: './dynamic-object.component.html',
  styles: [],
  providers: [CustomFormService],
})
export class DynamicObjectComponent implements OnInit, OnChanges {
  @Input() data: TimelineItem;
  @Input() error = {};
  @Input() editing = false;
  @Input() attributes: AttributeDescription[];
  @Input() readonlyAttributes = [];
  @Input() advancedEditing: boolean;
  @Input() isDuplicateValue = false;
  @Input() duplicateFieldName = 'Name';

  @Output() dataChange = new EventEmitter();

  form: UntypedFormGroup;
  tooltipPlacement = '';
  ChallengeTimelineAttributeName = ChallengeTimelineAttributeName;
  isTest = environment.test || environment.dev;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.setTooltipPlacement(innerWidth);
  }

  constructor(
    protected fb: UntypedFormBuilder,
    protected customFormService: CustomFormService
  ) {}

  ngOnInit(): void {
    this.setTooltipPlacement(window.innerWidth);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.attributes) {
      this.generateForm();
    }

    if (changes.isDuplicateValue) {
      if (this.isDuplicateValue) {
        this.form.get(this.duplicateFieldName).setErrors({ duplicate: true });
        this.form.get(this.duplicateFieldName).markAsDirty();
      } else {
        this.form.get(this.duplicateFieldName).setErrors({ duplicate: false });
      }
    }
  }

  generateForm(): void {
    if (!this.data && !this.attributes) {
      return;
    }

    this.customFormService.setControlConfig({
      entityDescription: { attributeDescriptions: this.attributes } as any,
    });

    const controls = this.customFormService.generateControlsConfig();
    this.form = this.fb.group({
      ...controls,
    });

    this.form.valueChanges.subscribe(() => {
      this.emitValue();
    });

    this.updateFormValue();
  }

  /**
   * Update when form's ready
   * this.form !=null
   * this.entity != null
   */
  updateFormValue(): void {
    if (!this.customFormService.entityDescription) {
      return;
    }

    for (const attr of this.customFormService.entityDescription
      .attributeDescriptions) {
      const key = attr.propertyName;
      const value = this.getFormFieldValueFromEntity(key);
      this.form.patchValue(
        {
          [key]: value,
        },
        {
          emitEvent: false,
        }
      );
    }
  }

  emitValue(): void {
    this.data = {
      ...this.data,
      ...FormUtils.lowercaseProperty(this.form.value),
    };
    this.dataChange.emit(this.data);
  }

  getFormFieldValueFromEntity(name: string): any {
    return (
      this.data[name] ?? this.data[StringUtils.toLowerCaseFirstLetter(name)]
    );
  }

  getError(key: string): string {
    const error = this.error;

    if (error && error[key]) {
      return error[key];
    }
    return null;
  }

  isEditable(item: AttributeDescription): boolean {
    return (
      item.IsCustomizedPhase ||
      !this.readonlyAttributes.includes(item.propertyName)
    );
  }

  /* Private */
  private setTooltipPlacement(width: number): void {
    this.tooltipPlacement = width < MediaBreakpoint.lg ? 'left' : 'right';
  }
}
