import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityName } from '@shared/enums/entity-name.enum';
import { ChallengeInterface } from '@shared/interfaces/challenge.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { Observable } from 'rxjs';
import { filter, share, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-challenge-tiles',
  templateUrl: './challenge-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class ChallengeTilesComponent
  extends BaseWidgetComponent
  implements OnInit
{
  data$: Observable<ApiGetResponse<ChallengeInterface>>;

  readonly entityPath = environment.jipUrl.challenges;

  readonly entityCreationPath = `${this.entityPath}/create`;

  constructor(
    public readonly searchEntitiesService: SearchEntitiesService,
    protected readonly sessionService: SessionService
  ) {
    super(sessionService);

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: any) => arr.length),
      switchMap((res) => res[0].content),
      share()
    ) as Observable<ApiGetResponse<ChallengeInterface>>;
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.Challenge }];
    this.searchEntitiesService.currentFilter = EntityName.Challenge;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter = !this.org_id && this.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }
}
