import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkWrapper',
})
export class LinkWrapperPipe implements PipeTransform {
  transform(
    link: string,
    displayText: string,
    directive = 'appUnsavedFormCheck',
    customclass = ''
  ): string {
    if (link) {
      return `<a href="${link}" class="${customclass} btn-link-primary" ${directive}>${displayText}</a>`;
    }
    return `<a class="${customclass} btn-link-primary" ${directive}>${displayText}</a>`;
  }
}

@Pipe({
  name: 'spanWrapper',
})
export class SpanWrapperPipe implements PipeTransform {
  transform(displayText: string, customClass = 'appUnsavedFormCheck'): string {
    return `<span class="${customClass} btn-link-primary">${displayText}</span>`;
  }
}

@Pipe({
  name: 'emailWrapper',
})
export class EmailWrapperPipe implements PipeTransform {
  transform(email: string, directive = 'appUnsavedFormCheck'): string {
    return `<a href="mailto:${email}" ${directive}>${email}</a>`;
  }
}
