import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { EventInterface } from '@shared/interfaces/event.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { environment } from '@src/environments/environment';
import { finalize } from 'rxjs/operators';
import { DateFormat } from '../../enums/date.enum';
import { EntityName } from '../../enums/entity-name.enum';
import { EventPartnerStatusCodeIdEnum } from '../../enums/partner-status.enum';
import { untilDestroyed } from '../../functions/until-destroyed';
import { EntityStateCountComponent } from '../entity-state-count/entity-state-count.component';

@Component({
  selector: 'app-shared-events',
  templateUrl: './shared-events.component.html',
  providers: [CustomFormService],
})
export class SharedEventsComponent
  extends EntityStateCountComponent
  implements OnInit
{
  @Input() isChallenge = false;
  @Input() modalTitle = '';
  @Input() entityId: number;
  @Input() entityTitle: string;
  @Input() httpService: BaseHttpService<any>;
  @Input() pageSize = 2;
  @Input() isRegisteredEvent = false;

  items: EventInterface[] = [];
  page = 1;
  collectionSize = 0;

  showPeopleModal = false;
  selectedEntityId: number;
  selectedEntity: EventInterface;

  entitybase = environment.jipUrl.event;
  isLoading = false;

  EventPartnerStatusCodeId = EventPartnerStatusCodeIdEnum;
  EntityName = EntityName;
  DateFormat = DateFormat;

  constructor(
    public eventHttpService: EventHttpService,
    public sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.paginateItems();
  }

  handleSyncEntityStateCount(): void {
    this.syncFollowingsState();
    this.syncCommentsState();
  }

  paginateItems(): void {
    if (!this.entityId || !this.httpService) {
      return;
    }
    this.isLoading = true;

    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    let url = this.entitybase;
    if (this.isRegisteredEvent) {
      url = '/registered-events';
    }

    this.httpService
      .paginateByEntity(url, this.entityId, params)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res: ApiGetResponse<EventInterface>) => {
        this.items = res?.items;
        this.collectionSize = res?.rowCount;
        this.runEntityStateCount(
          EntityName.Event,
          this.items,
          this.eventHttpService
        );
      });
  }

  showEntityData(entity: EventInterface): void {
    if (entity) {
      this.selectedEntityId = null;
      this.selectedEntity = null;
      setTimeout(() => {
        this.selectedEntityId = entity.id;
        this.selectedEntity = entity;
      });
    }
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateItems();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.paginateItems();
  }

  afterLeaveEvent(isLeaveSuccess: boolean): void {
    if (isLeaveSuccess) {
      this.paginateItems();
    }
  }

  afterCommentModalClosed(): void {
    this.syncCommentsState();
  }
}
