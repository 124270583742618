import { ChangeDetectorRef, Component } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import { MetadataTableComponent } from '../metadata-table.component';

@Component({
  selector: 'app-metadata-table-people',
  templateUrl: '../metadata-table.component.html'
})
export class MetadataTablePeopleComponent extends MetadataTableComponent<PeopleInterface> {
  entityName = this.EntityName.Person;
  peopleFilterPropertyName: string[] = [
    'Id',
    'DateOfBirth',
    'JobTitle',
    'LastName',
    'MiddleName',
    'FirstName',
    'Image',
    'Location',
    'Gender',
    'Company',
    'CompanyLink',
    'CompanyLogo',
    'CompanySize',
    'Education',
    'MotivationStatement',
    'Phone',
  ];
  readonlyField = ['Id'];

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: EventHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.peopleFilterPropertyName;
  }
}
