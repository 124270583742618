import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesPopupSelectionComponent } from '@src/app/components/entity-section/images-popup-selection/images-popup-selection.component';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import {
  MoodboardCategoryItem,
  MoodboardImageItem,
} from '@src/app/shared/interfaces/people.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';

@Component({
  selector: 'app-moodboard-group',
  templateUrl: './moodboard-group.component.html',
})
export class MoodboardGroupComponent implements OnChanges {
  @Input() moodboardKey: string;
  @Input() groupTitle = '';
  @Input() currentItems: MoodboardImageItem[];

  @Output() valueChange = new EventEmitter<MoodboardImageItem[]>();

  defaultMoodboardItems: MoodboardCategoryItem[];

  categoryInfoObj: { [key: string]: MoodboardImageItem[] } = {};

  constructor(
    private modalService: NgbModal,
    private peopleService: PeopleHttpService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.moodboardKey && changes.currentItems) {
      if (this.moodboardKey && this.currentItems) {
        this.mapWithSelectedMoodboard();
      }
    }
  }

  mapWithSelectedMoodboard(): void {
    this.peopleService
      .gettMoodboardCategories(this.moodboardKey)
      .subscribe((items) => {
        this.defaultMoodboardItems = items || [];

        this.defaultMoodboardItems.forEach((element) => {
          const selectedItem = this.currentItems.find((item) =>
            StringUtils.equal(item.category, element.name)
          );
          if (selectedItem) {
            element.selectedImg = selectedItem.image;
            element.selectedImgId = selectedItem.imageId;
          }
        });
      });
  }

  showImagesPopup(item: MoodboardCategoryItem): void {
    const modalRef = this.modalService.open(ImagesPopupSelectionComponent, {
      backdropClass: 'modal-cookies-settings',
      windowClass: 'modal-cookies-settings',
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.componentInstance.modalTitle = item.name;
    modalRef.componentInstance.allowCropImg = true;
    modalRef.componentInstance.selectedItem = {
      imageId: item.selectedImgId,
      image: item.selectedImg,
    };

    if (this.categoryInfoObj[item.name]) {
      modalRef.componentInstance.defaultImages =
        this.categoryInfoObj[item.name];
      modalRef.componentInstance.calculateDisplayImages();
    } else {
      this.peopleService.getMoodboardImages(item.name).subscribe((items) => {
        modalRef.componentInstance.defaultImages = items || [];
        modalRef.componentInstance.calculateDisplayImages();
      });
    }

    modalRef.result
      .then((image) => {
        if (image) {
          this.choseImage(item, image);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  choseImage(
    defaultItem: MoodboardCategoryItem,
    selectedItem: MoodboardImageItem
  ): void {
    this.defaultMoodboardItems.forEach((item) => {
      if (StringUtils.equal(item.name, defaultItem.name)) {
        item.selectedImg = selectedItem.image;
        item.selectedImgId = selectedItem.imageId;
        this.emitValueChange();
      }
    });
  }

  emitValueChange(): void {
    const result: MoodboardImageItem[] = this.defaultMoodboardItems
      .filter((item) => !!item.selectedImgId)
      .map((item) => {
        const moodboard: MoodboardImageItem = {
          imageId: item.selectedImgId,
          image: item.selectedImg,
          category: item.name,
        };
        return moodboard;
      });

    this.valueChange.emit(result);
  }

  removeImage(img: MoodboardCategoryItem, event: Event): void {
    event.stopPropagation();

    this.currentItems = this.currentItems.filter(
      (x) => x.imageId !== img.selectedImgId
    );

    this.defaultMoodboardItems.forEach((element) => {
      if (element.selectedImgId === img.selectedImgId) {
        delete element.selectedImgId;
        delete element.selectedImg;
      }
    });

    this.emitValueChange();
  }
}
