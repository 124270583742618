import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { OrgDropdownOption } from '@src/app/shared/interfaces/organization.interface';
import { MediaBreakpoint } from '@src/app/shared/models/ui.model';
import { isMobile } from 'detect-touch-device';

@Component({
  selector: 'app-partner-organization-select',
  templateUrl: './partner-organization-select.component.html',
  styles: [],
})
export class PartnerOrganizationSelectComponent implements OnChanges {
  @Input() leadOrgId: number;
  @Input() orgOptions: OrgDropdownOption[] = [];
  @Output() partnerOrgChange = new EventEmitter();

  innerWidth: number;
  selectedOption: OrgDropdownOption;
  isTouchDevice: boolean = isMobile;
  NO_FILTER_OPTION_ID = 0;
  MediaBreakpoint = MediaBreakpoint;

  constructor() {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const { innerWidth } = event?.target;
    this.innerWidth = innerWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orgOptions) {
      this.selectedOption = this.orgOptions.find(
        (org) => org.id === this.leadOrgId
      ); // default option

      if (this.selectedOption) {
        this.partnerOrgChange.emit(this.selectedOption.id);
      }
    }
  }

  onSelectPartnerOrg(org: OrgDropdownOption): void {
    this.selectedOption = org;
    this.partnerOrgChange.emit(org.id);
  }
}
