<ng-container *ngIf="isCustomTemplate; else cardActionTemplate">
  <div
    class="card-action jip-upper-first-letter"
    [innerHTML]="messageHtml"
  ></div>
</ng-container>
<ng-template #cardActionTemplate>
  <div class="card-action">
    <span *ngIf="ownerNameTemplate">
      <ng-container [ngTemplateOutlet]="ownerNameTemplate"></ng-container
      >&nbsp;</span
    >
    <span
      translate
      [translateParams]="{
        have: haveVerb,
        pronoun: pronoun
      }"
      [innerHTML]="actionText"
    ></span>
    <a
      appUnsavedFormCheck
      [href]="entityLink"
      class="card-action__link btn-link-primary"
      >{{ entityText | translate }}</a
    >
    <span *ngIf="suffixText"> {{ suffixText | translate }}</span>
  </div>
</ng-template>
