import { Component, OnInit } from '@angular/core';
import { ApplicationService, Version } from '@core/services/application.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  appName: string;
  currentYear: string = new Date().getFullYear().toString();

  constructor(private readonly applicationService: ApplicationService) { }

  ngOnInit(): void {
    const packageVersion = this.applicationService.getStaticVersion();
    console.log('App version:', packageVersion?.version);
    this.applicationService.getServiceVersion().subscribe((version) => {
      Object.assign(packageVersion, version);
      this.displayName(packageVersion);
    });
  }

  displayName(version: Version): void {
    this.appName = version?.name;
  }
}
