<ng-container *ngIf="ventureId; else fromChallenge">
  <i class="fas fa-file-upload"></i>
  <span class="venture__actions-label font-weight-bold ml-2" translate>
    UI.Common.SubmitToChallenge
  </span>
</ng-container>

<ng-template #fromChallenge>
  <button
    [disabled]="disabled"
    class="btn btn-primary form-section__btn w-100 ml-0"
    translate
  >
    UI.Common.SubmitAnExistingIdea
  </button>
</ng-template>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ modalHeader | translate }}</h4>

    <button
      (click)="modal.close()"
      aria-describedby="modal-title"
      aria-label="Close"
      class="btn-close close font-size-2xl"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="height: 500px">
    <div class="h-100 d-flex flex-column">
      <!--      Search input-->
      <app-search-input-wrapper class="flex-shrink-0">
        <span
          class="pointer-cursor search__icon-wrapper"
          (click)="searchVenture()"
        >
          <i class="search__icon fas fa-search"></i>
        </span>
        <input
          type="text"
          class="form-control mb-3 pl-5"
          placeholder="{{ 'Search' | translate }}"
          [(ngModel)]="keyword"
          (keyup.enter)="searchVenture()"
          name="searchVentureText"
        />
      </app-search-input-wrapper>

      <div class="flex-grow-1 overflow-auto">
        <!--      Main content-->
        <div
          [hidden]="isEmpty || loading"
          class="fill-container"
          id="modalContent"
        ></div>

        <!--      Spinner-->
        <div *ngIf="loading" class="fill-container spinner-loading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>

        <!--      Empty message-->
        <div *ngIf="isEmpty && !loading" class="fill-container">
          <span class="message-empty" translate
            >FILTERING_LISTING_EMPTY_MESSAGE</span
          >
        </div>
      </div>

      <!--      Paginator-->
      <div class="pr-3 pb-2 flex-shrink-0">
        <app-bootstrap-pagination
          [page]="pageIndex"
          [pageSize]="pageSize"
          [collectionSize]="collectionSize"
          (changePage)="onPageChange($event)"
        ></app-bootstrap-pagination>
      </div>

      <app-people-modal
        *ngIf="showPeopleModal"
        [entityId]="selectedEntityId"
        [entityTitle]="selectedEntity?.title"
        [modalTitle]="'Followers' | translate"
        [httpService]="ventureService"
        (dismissModal)="showPeopleModal = false"
      ></app-people-modal>
    </div>
  </div>
</ng-template>
