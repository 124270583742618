import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { smoothHeight } from '@shared/functions/animations';
import { News } from '@shared/interfaces/news.interface';
import { generateUniqueId } from '@shared/utils/common.utils';
import { MessageAction } from '@src/app/shared/enums/entity-name.enum';

const { wordpressJipImages } = environment;

@Component({
  selector: 'app-news-tiles-item',
  templateUrl: './news-tiles-item.component.html',
  animations: [smoothHeight],
})
export class NewsTilesItemComponent {
  @Input() news: News;

  @Input() userId: number;

  @Output() syncCommentStateCount = new EventEmitter<News>();

  readonly defaultAvatar = `${wordpressJipImages}avatar-square.png`;

  readonly defaultImage = `${wordpressJipImages}placeholder.png`;

  readonly MessageAction = MessageAction;

  readonly uid = generateUniqueId();

  onSyncCommentStateCount(event: News): void {
    this.syncCommentStateCount.emit(event);
  }
}
