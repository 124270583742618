<div class="modal-header">
  <button
    type="button"
    class="btn-close close"
    aria-label="Close"
    (click)="activeModal.dismiss(false)"
  >
    <i class="far fa-times"></i>
  </button>
</div>
<div class="modal-body" translate>
  <ng-container
    *ngIf="titleTemplateRef"
    [ngTemplateOutlet]="titleTemplateRef"
  ></ng-container>
  <div class="img-zoom-popup">
    <img
      *ngIf="!isLoading"
      [src]="data | base64Image"
      appDefaultPlaceholder
      alt=""
    />
    <span *ngIf="isLoading">
      <i class="fas fa-spinner fa-spin mr-0"></i>
    </span>
  </div>
</div>
<div class="modal-footer"></div>
