<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>
<div class="modal-header">
  <h1 class="modal-title" id="modal-title" translate>Messages</h1>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body message-back-dialog-body">
  <div
    *ngIf="messages?.length > 0"
    class="news-messages news-messages__list news-messages__list--vh-40 p-0"
  >
    <app-message-back-item
      *ngFor="let item of messages"
      [message]="item"
      [direction]="getMessageDirection(item)"
      (deletedThreadMessage)="afterDeleteThreadMessage($event)"
    >
    </app-message-back-item>
  </div>
</div>

<div class="modal-footer pt-3 flex-column">
  <ng-container
    *ngIf="isLoadingAttributeDescriptions$ | async; else sendMessageTemplate"
  >
    <div class="d-flex justify-content-center align-items-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #sendMessageTemplate>
    <div class="message-send-box w-100">
      <div class="d-flex justify-content-center">
        <div class="message-to-profile">
          <img [src]="profileImage" appDefaultAvatar appDynamicImgUrl />
        </div>
      </div>

      <div class="message-content-quill">
        <app-form-field-custom
          #messageForm
          [formInput]="form"
          [type]="AttributeType.RTE"
          [attributeDescription]="contentAttributeDescription"
          [shouldValidateOnTouch]="true"
          [options]="{
            placeholder: 'UI.SendMessage.Placeholder' | translate,
            allowEmojiOnRTE: true,
            allowImgOnRTE: true,
            allowCropImg: true,
            aspectRatio: 15 / 8
          }"
        >
        </app-form-field-custom>
      </div>
    </div>

    <form class="w-100" [formGroup]="form">
      <app-mail-forwarding-checkbox
        formControlName="isSendMail"
      ></app-mail-forwarding-checkbox>
    </form>
  </ng-template>
  <div class="pt-4">
    <button
      type="button"
      class="btn btn-outline-secondary mr-2"
      (click)="activeModal.dismiss('cancel click')"
      translate
    >
      Cancel
    </button>

    <button
      [disabled]="
        form.invalid ||
        isSubmitting ||
        messageForm?.imageInputDisplay?.uploading
      "
      type="button"
      ngbAutofocus
      class="btn btn-primary"
      appRequireLogin
      appDebounceClick
      (debounceClick)="sendMesageBack()"
      [hidden]="isHideSendBtn()"
    >
      <ng-container *ngIf="isSubmitting">
        <i class="fas fa-spinner fa-spin btn-icon-right"></i>
      </ng-container>
      <span translate>Send</span>
    </button>
  </div>
</div>
