import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NewsMessageInterface } from '@shared/interfaces/news-message.interface';
import {
  EntityName,
  MessageType,
} from '@src/app/shared/enums/entity-name.enum';
import { MessageUtils, SingleMessageType } from '@shared/utils/message-utils';

@Component({
  selector: 'app-message-item-container[currentUserId][item]',
  templateUrl: './message-item-container.component.html',
})
export class MessageItemContainerComponent implements OnInit, OnChanges {
  @Input() item: NewsMessageInterface;

  @Input() currentUserId: number;

  @Output() likesModalOpened = new EventEmitter<NewsMessageInterface>();

  @Output() commentModalClosed = new EventEmitter<NewsMessageInterface>();

  @Output() contentLoaded = new EventEmitter<NewsMessageInterface>();

  MessageType = MessageType;

  EntityName = EntityName;

  isGroupMessage: boolean;

  singleMessageType: SingleMessageType;

  isNotSupportSendGroupView: boolean;

  isShowAsNoReplyMessageYet: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item || changes.currentUserId) {
      this.isShowAsNoReplyMessageYet = MessageUtils.isShowAsNoReplyMessageYet(
        this.item,
        this.currentUserId
      );

      this.isNotSupportSendGroupView = MessageUtils.isNotSupportSendGroupView(
        this.item?.action
      );
    }
  }

  ngOnInit(): void {
    const { isGroup, action, messageType } = this.item;
    const { entityName } = this.item.content ?? {};

    this.isGroupMessage =
      isGroup && MessageUtils.isNotSupportSendGroupView(action);

    this.singleMessageType = MessageUtils.getSingleMessageType(
      messageType === MessageType.Message,
      entityName === EntityName.VentureSubmittedToChallenge
    );
  }

  messageLoaded(id: number): void {
    this.contentLoaded.emit();
  }

  openLikesModal(item: NewsMessageInterface): void {
    this.likesModalOpened.emit(item);
  }
}
