import { Pipe, PipeTransform } from '@angular/core';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';

@Pipe({
  name: 'descriptionValue',
})
export class DescriptionValuePipe implements PipeTransform {
  transform(
    attributeDescriptions: AttributeDescription[],
    fieldName: string
  ): AttributeDescription {
    return FormUtils.getFieldOptions(attributeDescriptions, fieldName);
  }
}
