<app-metadata-table-challenge
  [entityDatas]="items"
  [isLoading]="isLoading"
  [editable]="false"
></app-metadata-table-challenge>
<app-bootstrap-pagination
  [page]="page"
  [pageSize]="pageSize"
  [collectionSize]="collectionSize"
  (changePageSize)="onPageSizeChange($event)"
  (changePage)="onPageChange($event)"
></app-bootstrap-pagination>
