import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JoinInDialogComponent } from '@src/app/components/dialogs/join-in-dialog/join-in-dialog.component';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { JoinVentureState, VentureStateCodeId } from '@src/app/shared/enums/venture.enum';
import { AttributeDescription, CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { JoinInteractComponent } from '../join-interact.component';

@Component({
  selector: 'app-join-venture',
  templateUrl: './join-venture.component.html',
})
export class JoinVentureComponent extends JoinInteractComponent<VentureInterface> {
  customUserRoles: CustomAtrributeValue[] = [];
  JoinVentureState = JoinVentureState;

  constructor(
    public readonly sessionService: SessionService,
    protected readonly toastService: ToastService,
    protected modalService: NgbModal
  ) {
    super(sessionService, toastService);
  }

  join(event: Event, entity: VentureInterface): void {
    if (
      !this.sessionService.isLogin ||
      !entity ||
      entity.hasJoined ||
      !entity.id
    ) {
      return;
    }

    this.baseHttpService
      .getStakeHolderCustomAttribute(this.entity?.id)
      .subscribe((attrDesList: AttributeDescription[]) => {
        const roleAttrDes = attrDesList?.find((x) => x.propertyName === 'Role');
        this.customUserRoles = roleAttrDes
          ? roleAttrDes.choice?.selections
          : [];

        if (this.customUserRoles.length > 0) {
          this.openJoinInDialog(entity);
        } else {
          super.join(event, entity);
        }
      });
  }

  openJoinInDialog(entity: VentureInterface): void {
    const modalRef = this.modalService.open(JoinInDialogComponent, {
      centered: true,
      backdrop: 'static',
      scrollable: false,
      windowClass: 'message-modal-custom',
      size: 'sm',
    });

    modalRef.componentInstance.entity = entity;
    modalRef.componentInstance.title = 'UI.JoinDialog.Title';
    modalRef.componentInstance.placeholder =
      'UI.Venture.JoinInMessagePlaceholder';
    modalRef.componentInstance.customUserRoles = this.customUserRoles;
    modalRef.componentInstance.baseHttpService = this.baseHttpService;
  }

  // @ts-ignore: Ignore the error related to member type mismatch
  get isOwner(): boolean {
    return (
      this.sessionService.isLogin &&
      this.entity.initiator?.id === this.sessionService.currentUser?.id
    );
  }

  get isTeam(): boolean {
    return (
      this.sessionService.isLogin &&
      ArrayUtils.hasItem(
        this.entity?.stakeholders,
        this.sessionService.currentUser,
        'id'
      )
    );
  }

  get currentJoinVentureState(): JoinVentureState {
    return this.getJoinVentureState(this.entity);
  }

  private getJoinVentureState(venture: VentureInterface): JoinVentureState {
    if (
      !venture ||
      venture.canJoin === undefined ||
      venture.hasJoined === undefined ||
      venture.isPending === undefined
    ) {
      return null;
    }
    if (!this.sessionService.isLogin) {
      if (this.isVentureNotAcceptMember(venture)) {
        return JoinVentureState.NotAccepted;
      } else {
        return JoinVentureState.CanJoin;
      }
    }
    if (venture.isPending) {
      return JoinVentureState.IsPending;
    }
    if (!venture.canJoin && venture.hasJoined) {
      return JoinVentureState.HasJoined;
    }
    if (this.isVentureNotAcceptMember(venture)) {
      return JoinVentureState.NotAccepted;
    }
    return JoinVentureState.CanJoin;
  }

  private isVentureNotAcceptMember(venture: VentureInterface): boolean {
    return (
      venture.isNDARequired ||
      venture.status?.codeId === VentureStateCodeId.Closed ||
      venture.status?.codeId === VentureStateCodeId.Suspended ||
      venture.status?.codeId === VentureStateCodeId.Deactivated
    );
  }
}
