import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@src/app/core/toast.service';
import { TreeNode } from '@src/app/shared/components/tree-view-check-box/tree-view-check-box.component';
import { HttpStatusCode } from '@src/app/shared/enums/httpstatuscode.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { SaveFileUtils } from '@src/app/shared/utils/save-file-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-entity-export',
  templateUrl: './entity-export.component.html',
  styles: [],
})
export class EntityExportComponent implements OnDestroy {
  @Input() entityId: number;

  isSubmitting = false;
  checkboxItems: TreeNode[] = [];
  checkboxOptionsItems: TreeNode[] = [];

  cancelExport = false;

  constructor(
    public activeModal: NgbActiveModal,
    protected toastService: ToastService
  ) {}

  handleExport(force = false): void {
    // implement in inherit entity;
  }

  submit(): void {
    this.handleExport(true);
  }

  cancel(): void {
    this.cancelExport = true;
    this.activeModal.dismiss();
  }

  haveCheckedItem(): boolean {
    return this.checkboxItems.some((x) => x.checked);
  }

  protected handleExportResponse(api$: Observable<any>): void {
    this.isSubmitting = true;
    api$.pipe(untilDestroyed(this)).subscribe({
      next: (res: HttpResponse<any>) => {
        if (res) {
          if (res.status === HttpStatusCode.Ok) {
            SaveFileUtils.saveFile(res);
            this.toastService.showSuccess('UI.Export.Success');
            this.isSubmitting = false;
            this.activeModal.dismiss();
          } else if (res.status === HttpStatusCode.NoContent) {
            if (this.cancelExport === false) {
              setTimeout(() => {
                this.handleExport();
              }, 1000);
            }
          }
        } else {
          this.handleError();
        }
      },
      error: () => {
        this.handleError();
      },
    });
  }

  protected handleError(): void {
    this.toastService.showError('UI.Export.Failed');
    this.isSubmitting = false;
  }

  protected getParamsExportValue(
    list: TreeNode[],
    paramsWithKeyExport: any
  ): any {
    if (list?.length) {
      list.forEach((item) => {
        paramsWithKeyExport[item.value] = item.checked;
        if (item.children?.length) {
          this.getParamsExportValue(item.children, paramsWithKeyExport);
        }
      });
    }
    return paramsWithKeyExport;
  }

  ngOnDestroy(): void {
    /**/
  }
}
