import {
  Directive,
  Input,
  HostListener,
  HostBinding,
  Renderer2,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[appDefaultImg][default]',
})
export class DefaultImageDirective {
  @Input()
  default;

  constructor(
    public renderer: Renderer2,
    private hostElement: ElementRef<HTMLElement>
  ) {}

  @HostBinding('src')
  @Input()
  src: string;

  @HostListener('error')
  onError(): void {
    this.src = this.default;
    if (this.hostElement && this.hostElement.nativeElement) {
      const ele = this.hostElement.nativeElement;
      this.renderer.addClass(ele, 'error-img'); // add class to check on hover directive
      this.renderer.setAttribute(ele, 'alt', 'Default Image');
    }
  }
}
