import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { JurorFormState } from '@src/app/shared/enums/juror.enum';

@Component({
  selector: 'app-jury-menu-tabs-bar',
  templateUrl: './jury-menu-tabs-bar.component.html',
})
export class JuryMenuTabsBarComponent implements OnChanges {
  @Input() canViewRatingForm: boolean;

  @Output() filterChanged = new EventEmitter();

  tabs: Record<string, string>[] = [
    { key: JurorFormState.List, label: 'UI.Challenge.JurorList' },
    { key: JurorFormState.Rating, label: 'UI.Challenge.JurorRating' },
  ];

  currentTab = this.tabs[0].key;

  private originalTabs: Record<string, string>[] = this.tabs.slice();

  ngOnChanges(changes: SimpleChanges): void {
    this.tabs = !changes.canViewRatingForm?.currentValue
      ? this.tabs.filter((tab) => tab.key !== JurorFormState.Rating)
      : [...this.originalTabs];
  }

  onFilterChange(event: JurorFormState): void {
    this.filterChanged.emit(event);
  }
}
