<ng-container *ngIf="isSmallScreen">
  <div
    #dropdownRef
    class="selected-space"
    ngbDropdown
    [placement]="'bottom-left'"
  >
    <div
      class="d-flex align-items-center justify-content-between px-3 py-2"
      style="background-color: #eee"
      ngbDropdownToggle
      id="dropdownBasic1"
      container="body"
      (click)="checkDropDown(dropdownRef)"
    >
      <div
        *ngIf="noFilterApplied"
        class="d-flex align-items-center community-item"
      >
        <app-organization-logo
          [className]="'filter__org-logo'"
          [organizationLogo]="sessionService.tenant?.logo?.url"
          [showCopyText]="false"
        ></app-organization-logo>

        <label
          translate
          class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2"
          >UI.Sidebar.AllContent
        </label>
      </div>
      <ng-container *ngIf="isLogin$ | async">
        <div
          *ngIf="isMyInvolvement"
          class="d-flex align-items-center community-item pointer-cursor"
        >
          <div class="filter__org-logo">
            <app-internal-icon
              [width]="32"
              [height]="32"
              [name]="InternalIcon.MyInvolvement"
              [className]="'org-logo'"
            >
            </app-internal-icon>
          </div>

          <label
            translate
            class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2"
            >My Involvement
          </label>
        </div>
        <div
          *ngIf="isMyOrgs"
          class="d-flex align-items-center community-item pointer-cursor"
        >
          <div class="filter__org-logo">
            <app-internal-icon
              [width]="32"
              [height]="32"
              [name]="InternalIcon.MyOrganization"
              [className]="'org-logo'"
            >
            </app-internal-icon>
          </div>

          <label
            translate
            class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2 text-truncate"
            >My Organizations
          </label>
        </div>
      </ng-container>
      <div
        *ngIf="!!organizationId && orgFilterList?.length > 0"
        class="d-flex align-items-center community-item w-100"
      >
        <app-organization-logo
          (click)="$event.preventDefault()"
          [className]="'filter__org-logo'"
          [organizationLogo]="selectedOrg?.imgUrl"
          [showCopyText]="false"
        ></app-organization-logo>

        <label
          class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2"
          >{{ selectedOrg?.name || selectedOrg?.orgName}}
        </label>
      </div>
    </div>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="w-100">
      <ng-container [ngTemplateOutlet]="sidebarMain"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="!isSmallScreen"
  [ngTemplateOutlet]="sidebarMain"
></ng-container>

<ng-template #sidebarMain>
  <div
    class="community-selector__wrapper"
    [ngClass]="{
      expanded: isExpanded
    }"
    (touchmove)="removeAllBoxes($event)"
  >
    <!-- Header -->
    <div *ngIf="!isSmallScreen" class="community-selector__header p-3">
      <div
        class="d-flex align-items-center"
        [ngClass]="{
          'justify-content-center': !isExpanded,
          'justify-content-between': isExpanded
        }"
      >
        <div
          class="d-flex align-items-center pointer-cursor text-medium-gray"
          (click)="toggleCollapse()"
        >
          <i
            class="fas"
            [class.fa-chevron-left]="isExpanded"
            [class.fa-chevron-right]="!isExpanded"
          ></i>
          <span *ngIf="isExpanded" class="font-size-sm ml-2" translate>
            UI.Sidebar.Minimize
          </span>
        </div>

        <div
          *ngIf="isExpanded"
          appTooltip
          class="ml-2 d-flex align-items-center"
          triggers="click"
          placement="right"
          [ngbTooltip]="
            'UI.Sidebar.Helptext'
              | translate
              | stringReplacement
                : {
                    '{tenantName}': sessionService.tenant?.name
                  }
          "
          tooltipClass="sidebar-helptext-tooltip"
          #t="ngbTooltip"
          [toolTipControl]="t"
          container="body"
        >
          <i
            class="tooltip-icon fas fa-info-circle align-middle pointer-cursor"
          ></i>
        </div>
      </div>

      <div *ngIf="isExpanded" class="font-size-xl font-weight-bold mt-3">
        Community Spaces
      </div>
    </div>

    <div class="community-selector__main-content">
      <!-- General Section -->
      <div class="community-selector__general">
        <div
          class="d-flex align-items-center community-item px-3 py-2"
          [class.active]="!organizationId && !isMyOrgs && !isMyInvolvement"
          (click)="onClickItem('box-all-innovation')"
        >
          <input
            (change)="selectAll(organizationId, $event)"
            [(ngModel)]="allInnovation"
            id="box-all-innovation"
            type="checkbox"
            class="d-none"
          />

          <app-organization-logo
            (click)="$event.preventDefault()"
            [className]="'filter__org-logo'"
            [organizationLogo]="sessionService.tenant?.logo?.url"
            [showCopyText]="false"
          ></app-organization-logo>

          <label
            *ngIf="isExpanded"
            class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2"
            translate
            >UI.Sidebar.AllContent
          </label>
        </div>
        <ng-container *ngIf="sessionService.isLogin">
          <div
            class="d-flex align-items-center community-item px-3 py-2 pointer-cursor"
            [class.active]="isMyInvolvement"
            (click)="onClickItem('box-my-involvement')"
          >
            <input
              (change)="filterMyEnvolvement()"
              [(ngModel)]="isMyInvolvement"
              id="box-my-involvement"
              type="checkbox"
              class="d-none"
            />

            <div class="filter__org-logo">
              <app-internal-icon
                [width]="32"
                [height]="32"
                [name]="InternalIcon.MyInvolvement"
                [className]="'org-logo'"
              >
              </app-internal-icon>
            </div>

            <label
              *ngIf="isExpanded"
              class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2"
              translate
              >My Involvement
            </label>
          </div>
          <div
            class="d-flex align-items-center community-item px-3 py-2 pointer-cursor"
            [class.active]="isMyOrgs"
            (click)="onClickItem('box-my-org')"
          >
            <input
              (change)="filterMyOrgs()"
              [(ngModel)]="isMyOrgs"
              id="box-my-org"
              type="checkbox"
              class="d-none"
            />

            <div class="filter__org-logo">
              <app-internal-icon
                [width]="32"
                [height]="32"
                [name]="InternalIcon.MyOrganization"
                [className]="'org-logo'"
              >
              </app-internal-icon>
            </div>

            <label
              *ngIf="isExpanded"
              class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2 text-truncate"
              translate
              >My Organizations
            </label>
          </div>
        </ng-container>
      </div>

      <!-- Favortie Section -->
      <div *ngIf="orgFilterList" class="community-selector__favorite">
        <div
          *ngIf="isExpanded"
          class="d-flex align-items-center justify-content-between mt-3 text-medium-gray px-3"
        >
          <span
            class="d-flex align-items-center pointer-cursor text-medium-gray"
            (click)="toggleFavorite()"
            translate
            ><i
              class="fas mr-2"
              [class.fa-caret-down]="isFavoriteOpen"
              [class.fa-caret-up]="!isFavoriteOpen"
            ></i>
            UI.Sidebar.Favorites</span
          >

          <span class="pointer-cursor" (click)="openAddFavoriteDialog()"
            ><i class="fas fa-plus"></i
          ></span>
        </div>
        <ng-container
          *ngIf="!isExpanded"
          [ngTemplateOutlet]="divider"
          [ngTemplateOutletContext]="{ orgList: orgFilterList }"
        ></ng-container>
        <div
          class="community-selector__favorite--list-wrapper"
          *ngIf="isFavoriteOpen"
        >
          @if(orgFilterList.length > 0) {
          <ng-container *ngFor="let item of removeDuplicates(orgFilterList)">
            <div
              *ngIf="item | call : shouldShowFilterToken"
              class="d-flex align-items-center community-item px-3 py-2 position-relative"
              appLongPress
              (longPress)="onLongPress(item)"
              (click)="onClickItem('box-org-' + item.id)"
              [ngClass]="{
                active: item.id === organizationId
              }"
            >
              <input
                (click)="
                  organizationId !== item.id && filterOrganization(item.id)
                "
                [id]="'box-org-' + item.id"
                [ngModel]="organizationId === item.id"
                type="checkbox"
                class="d-none"
              />
              <div class="community-item__logo-container">
                <app-organization-logo
                  [className]="'filter__org-logo d-block'"
                  [organizationLogo]="item.imgUrl"
                  [showCopyText]="false"
                  [alt]="item.name"
                  (click)="$event.preventDefault()"
                ></app-organization-logo>

                <div
                  [ngbTooltip]="'Remove'"
                  placement="top"
                  container="body"
                  [disableTooltip]="isSmallScreen || isTouchDevice"
                  class="community-item__logo-container--delete-icon position-absolute"
                  (click)="$event.stopPropagation(); removeItem(item)"
                >
                  <span>×</span>
                </div>
              </div>

              <label
                *ngIf="isExpanded"
                class="flex-1 truncate-line-1 d-inline-block font-size-sm pointer-cursor ml-2 text-truncate"
                [ngbTooltip]="item.name"
                container="body"
                [disableTooltip]="isSmallScreen || isTouchDevice"
                >{{ item.name }}
              </label>

              <div
                class="remove-box-container d-none align-items-center pointer-cursor"
                [id]="'remove-box-' + item.id"
                (click)="removeItem(item)"
              >
                <i class="fas fa-times-circle mr-2"></i>
                <span>Remove from list</span>
              </div>
            </div>
          </ng-container>
          } @else {
          <div
            *ngIf="isExpanded"
            class="mt-3 px-3"
            [innerHTML]="
              'UI.Sidebar.AddToFavoritesGuide'
                | translate
                | stringReplacement
                  : {
                      '{searchForAndAddText}':
                        null
                        | linkWrapper
                          : ('UI.Sidebar.SearchForAndAdd' | translate)
                    }
            "
          ></div>
          }
        </div>
      </div>

      <!-- Popular Section -->
      <div class="community-selector__popular pb-3">
        <span
          *ngIf="isExpanded"
          class="d-flex align-items-center pointer-cursor mt-3 text-medium-gray px-3"
          (click)="togglePopular()"
          translate
          ><i
            class="fas mr-2"
            [class.fa-caret-down]="isPopularOpen"
            [class.fa-caret-up]="!isPopularOpen"
          ></i>
          UI.Sidebar.Popular</span
        >

        <ng-container
          *ngIf="!isExpanded"
          [ngTemplateOutlet]="divider"
          [ngTemplateOutletContext]="{ orgList: popularOrgs }"
        ></ng-container>
        <div *ngIf="isPopularOpen">
          <div
            *ngFor="let item of popularOrgs"
            class="d-flex align-items-center community-item px-3 py-2"
            (click)="onClickItem('box-org-' + item.id)"
            [ngClass]="{
              active: item.id === organizationId
            }"
          >
            <input
              (click)="
                organizationId !== item.id && filterOrganization(item.id)
              "
              [id]="'box-org-' + item.id"
              [ngModel]="organizationId === item.id"
              type="checkbox"
              class="d-none"
            />

            <app-organization-logo
              [className]="'filter__org-logo flex-shrink-0'"
              [organizationLogo]="item.logo.url"
              [showCopyText]="false"
            ></app-organization-logo>

            <div *ngIf="isExpanded" class="ml-2 pointer-cursor overflow-hidden">
              <label
                class="flex-1 truncate-line-1 d-block font-size-sm pointer-cursor text-truncate"
                [ngbTooltip]="item.orgName"
                container="body"
                >{{ item.orgName }}
              </label>

              <div
                *ngIf="shouldShowAddToFavorite(item)"
                appDebounceClick
                (debounceClick)="handleAddOrgToFavorite(item.id, false)"
                class="add-to-favorite"
              >
                <i class="fas fa-plus"></i>
                <span class="ml-1" translate>UI.Sidebar.AddToFavorites</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalRef let-modal>
  <div class="modal-header">
    <h4 class="modal-title" translate>UI.Sidebar.AddToFavorites</h4>

    <button
      (click)="modal.close()"
      aria-describedby="modal-title"
      aria-label="Close"
      class="btn-close close font-size-2xl"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="height: 500px">
    <app-organisation-search
      #organizationSearch
      [searchMode]="orgsSearchMode.ForSubmittingAll"
      [searchPlaceholder]="'Search and add' | translate"
      [enableDynamicHeight]="false"
      [height]="420"
      [autoOpenDropdown]="true"
      [closeDropdownOnSelect]="false"
      [excludedOrganisationIds]="ignoreOrgIds"
      [updateListOnSelect]="true"
      (selectOrganisation)="selectItem($event)"
    ></app-organisation-search>
  </div>
</ng-template>

<ng-template #divider let-orgList="orgList">
  <div *ngIf="orgList.length" class="divider mx-3 my-2"></div>
</ng-template>
