<div
  class="d-flex flex-column pointer-cursor organization-challenge-view"
  (click)="open(orgChallengeDetail, $event)"
>
  <div *ngIf="organizations?.length > 0" class="mt-1">
    <ul class="list-inline m-0">
      <li
        *ngFor="let org of organizations.slice(0, numberOfDisplays)"
        class="list-inline-item venture__more-list"
      >
        <span class="venture__org-wrapper">
          <img
            class="fill-container"
            [src]="org.logo?.url"
            appDefaultAvatar
            appDynamicImgUrl
            [ngbTooltip]="'Organization' | translate"
            container="body"
          />
        </span>
      </li>

      <li
        *ngIf="organizations.length > numberOfDisplays"
        class="list-inline-item venture__more-list"
      >
        <span class="venture__org-wrapper venture__more-list--add">
          +{{ organizations?.length - numberOfDisplays }}
        </span>
      </li>
    </ul>
  </div>

  <div *ngIf="challenges?.length > 0">
    <ul class="list-inline m-0">
      <li
        *ngFor="let chall of challenges.slice(0, numberOfDisplays)"
        class="list-inline-item venture__more-list"
      >
        <span class="venture__org-wrapper">
          <img
            class="fill-container"
            [src]="chall.logo?.url"
            appDefaultAvatar
            appDynamicImgUrl
            [ngbTooltip]="'Challenge' | translate"
            container="body"
          />
        </span>
      </li>

      <li
        *ngIf="challenges.length > numberOfDisplays"
        class="list-inline-item venture__more-list"
      >
        <span class="venture__org-wrapper venture__more-list--add">
          +{{ challenges?.length - numberOfDisplays }}
        </span>
      </li>
    </ul>
  </div>
</div>

<ng-template #orgChallengeDetail let-modal>
  <div class="modal-header organization-challenge-view__modal-header">
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-venture-organizations
      [editting]="false"
      [items]="items"
    ></app-venture-organizations>
  </div>
</ng-template>
