import { environment } from '@src/environments/environment';

export const maxPageSize = 1000000;

export const placeholderImg =
  environment.wordpressJipImages + 'placeholder.png';

export const SUGGESTIONS = 'Suggestions';

export const ATTRIBUTES_TO_REMOVE = ['class', 'style'];

export const DAYS_OF_WEEK = 7;
