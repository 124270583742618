import { Component, OnInit } from '@angular/core';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { AdminSettingsSection, ADMIN_SIDEBARS, SidebarItem } from '@shared/models/admin-settings.model';
import { BaseComponentWithServiceComponent } from '@src/app/components/base/base-list/base-component-with-service/base-component-with-service.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { SessionService } from '@src/app/core/session.service';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
})
export class AdminSettingsComponent
  extends BaseComponentWithServiceComponent
  implements OnInit
{
  isSidebarExpanded = true;
  selectedSidebarItem = AdminSettingsSection.Dashboard;

  sidebarItems: SidebarItem[] = [];
  AdminSettingsSection = AdminSettingsSection;
  isContentReady = false;
  isLoadingPermission = false;
  isTenantAdmin = false;
  kpiUrl = environment.jipUrl.kpi;
  InternalIcon = InternalIcon;

  constructor(
    public authenService: AuthenService,
    protected sessionService: SessionService
  ) {
    super(sessionService);
    this.isLoadingPermission = true;
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady: boolean) => {
        if (!apiReady) {
          return;
        }
        this.checkPermission();
        this.isContentReady = apiReady;
      });
  }

  ngOnInit(): void {
    const currentSection = StringUtils.getParamFromUrl(UrlParam.TenantSection);
    if (currentSection) {
      this.selectedSidebarItem = Number(currentSection);
    } else {
      this.selectedSidebarItem = AdminSettingsSection.Dashboard;
      StringUtils.setQueryParamOnUrl(
        UrlParam.TenantSection,
        AdminSettingsSection.Dashboard.toString(),
        null,
        true
      );
    }
  }

  onSelectSidebarItem(section: AdminSettingsSection): void {
    if (this.selectedSidebarItem !== section) {
      this.selectedSidebarItem = section;
      StringUtils.setQueryParamOnUrl(
        UrlParam.TenantSection,
        section?.toString(),
        null,
        true
      );
    }
  }

  get adminSectionHeight(): string {
    const headerElement: HTMLElement = document.getElementsByClassName(
      'elementor-location-header'
    )[0] as HTMLElement;
    const headerHeight = headerElement?.offsetHeight || 0;
    const fullHeight = '100vh';
    if (headerHeight > 0) {
      return `calc( ${fullHeight} - ${headerHeight}px)`;
    }
    return fullHeight;
  }

  private checkPermission(): void {
    this.authenService
      .getProfileInfo()
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoadingPermission = false;
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((user: UserInterface) => {
        if (!user || !user.isTenantAdmin) {
          FormUtils.navigateToWithLanguagePath(
            this.sessionService,
            environment.jipUrl.home
          );
        } else {
          this.isTenantAdmin = true;
          this.sidebarItems = ADMIN_SIDEBARS;
        }
      });
  }
}
