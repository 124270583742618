import { Component, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { EntityName } from '@shared/enums/entity-name.enum';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { PageSizeConfig } from '@shared/models/pagination.model';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { placeholderImg } from '@src/app/shared/constants/common';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { JoinState } from '@src/app/shared/interfaces/join.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { Observable } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-organization-tiles',
  templateUrl: './organization-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class OrganizationTilesComponent
  extends BaseWidgetComponent
  implements OnInit
{
  data$: Observable<ApiGetResponse<OrganizationInterface>>;

  readonly entityPath = environment.jipUrl.organizations;

  readonly entityCreationPath = `${this.entityPath}/create`;

  isLoading = false;

  skeletonCount = [];
  InternalIcon = InternalIcon;
  showPeopleModal = false;
  showEventsModal = false;
  showVenturesModal = false;
  showLikesModal = false;
  showKeyOfferModal = false;
  selectedEntityId: any;
  selectedEntity: OrganizationInterface;
  placeholderImg = placeholderImg;
  entityName = EntityName.Organization;
  orgId: number;

  constructor(
    public readonly searchEntitiesService: SearchEntitiesService,
    protected readonly sessionService: SessionService,
    public listService: OrganizationHttpService,
    public centralConfig: CentralConfigService
  ) {
    super(sessionService);

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: any) => arr.length),
      switchMap((res) => res[0].content),
      share()
    ) as Observable<ApiGetResponse<OrganizationInterface>>;

    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });

    this.data$
      .pipe(
        map((res) => {
          return this.syncEntityJoinStates(res.items);
        })
      )
      .subscribe((items) => {
        this.runEntityStateCount(this.entityName, items, this.listService);
      });

    const orgId = this.centralConfig.innovationSpaceId;
    if (orgId) {
      this.orgId = orgId;
    }
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.Organization }];
    this.searchEntitiesService.currentFilter = EntityName.Organization;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const { filterDTO } = this.searchEntitiesService;
    const keepExternalFilter = !this.org_id && this.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];
    filterDTO.organizationId = this.org_id ?? '';
    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  getEntityUrl(org: OrganizationInterface): string {
    return org.showAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;
  }

  toggleLike(entity: OrganizationInterface): void {
    if (entity) {
      if (!entity.isLiked) {
        this.listService
          .like(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe((newLikeId: number) => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount = entity.likesCount + 1;
            entity.likeId = newLikeId;
          });
      } else {
        this.listService
          .unlike(entity.likeId)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount =
              entity.likesCount > 0 ? entity.likesCount - 1 : 0;
            entity.likeId = null;
          });
      }
    }
  }

  toggleFavorite(entity: OrganizationInterface): void {
    if (entity) {
      if (!entity.isFavorite) {
        this.listService
          .follow(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isFavorite = !entity.isFavorite;
            entity.followerCount = entity.followerCount + 1;
          });
      } else {
        this.listService
          .unfollow(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isFavorite = !entity.isFavorite;
            entity.followerCount = entity.followerCount - 1;
          });
      }
    }
  }

  showEntityData(entity: OrganizationInterface): void {
    if (entity) {
      this.selectedEntityId = null;
      this.selectedEntity = null;
      setTimeout(() => {
        this.selectedEntityId = entity.id;
        this.selectedEntity = entity;
      });
    }
  }

  getUiTemplate(organization: OrganizationInterface): string {
    const { attributeDescriptions, attributeValues } = organization;

    return CustomMetadataUtils.getAttributeValueByPropertyName(
      attributeDescriptions,
      attributeValues,
      'UiTemplate'
    );
  }

  protected syncEntityJoinStates(
    items: OrganizationInterface[]
  ): OrganizationInterface[] {
    const stringIds = items.map((i) => String(i.id));

    this.listService
      .getJoinStates(stringIds)
      .pipe(untilDestroyed(this))
      .subscribe((joinStates: JoinState[]) => {
        items = items.map((item) => {
          const foundState = joinStates.find(
            (state) => state.entityId === item.id
          );
          item.hasJoined = foundState ? foundState.hasJoined : false;
          item.canJoin = foundState ? foundState.canJoin : false;
          item.isPending = foundState ? foundState.isPending : false;
          return item;
        });
      });

    return items;
  }
}
