import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { SharedOrgFilter } from '@src/app/shared/interfaces/filters/org-filter.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { SortCriteria } from '@src/app/shared/models/pagination.model';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-organizations-admin-section',
  templateUrl: './organizations-admin-section.component.html',
})
export class OrganizationsAdminSectionComponent implements OnInit, OnDestroy {
  collectionSize = 0;
  isLoading = false;
  isFirstLoad = false;
  orgs: OrganizationInterface[] = [];
  sharedOrgfilter: SharedOrgFilter = {
    pageIndex: 1,
    pageSize: 8,
    keyword: '',
    sortingAttribute: null,
  };

  constructor(
    private organizationHttpService: OrganizationHttpService,
    private centralConfig: CentralConfigService,
    private filterStoredService: GlobalFilterStoredService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isFirstLoad = true;
    await this.onFilter();
  }

  async onFilter(): Promise<void> {
    this.isLoading = true;
    const activeInnovationSpace = this.centralConfig.innovationSpaceId;
    const globalOrgId = await this.getOrgId();
    const orgId = activeInnovationSpace || globalOrgId;
    let params;

    if (orgId) {
      params = { organizationId: orgId };
    }

    this.organizationHttpService
      .paginateAsPost(
        {
          ...this.sharedOrgfilter,
          ...params,
        },
        null,
        params
      )
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isFirstLoad = false;
          this.isLoading = false;
        })
      )
      .subscribe((res: ApiGetResponse<OrganizationInterface>) => {
        this.orgs = res?.items;
        this.collectionSize = res?.rowCount;
      });
  }

  onSort(sortCriteria: SortCriteria): void {
    this.sharedOrgfilter.sortingAttribute = sortCriteria;
    this.onFilter();
  }

  onPageChange(pageNumber: number): void {
    this.sharedOrgfilter.pageIndex = pageNumber;
    this.onFilter();
  }

  onPageSizeChange(pageSize: number): void {
    this.sharedOrgfilter.pageSize = pageSize;
    this.onFilter();
  }

  onResetSearchText(): void {
    this.sharedOrgfilter.keyword = '';
    this.onFilter();
  }

  async getOrgId(): Promise<number> {
    const filterCriteria = await this.filterStoredService.getFilterCriteria();
    return filterCriteria?.organizationIds?.[0];
  }

  ngOnDestroy(): void {
    /**/
  }
}
