<div class="moodboard__wrapper">
  <div
    class="moodboard--empty text-center"
    *ngIf="!options || options.length === 0; else moodboardRef"
  >
    <div>
      <i class="far fa-image"></i>
    </div>
    <span>Empty</span>
  </div>
  <ng-template #moodboardRef>
    <div
      *ngFor="let option of options; index as i"
      class="moodboard__item"
      [style.left.%]="option.left"
      [style.top.%]="option.top"
      [style.width.%]="option.width"
      [style.height.%]="option.height"
      [ngbTooltip]="items[i].category | translate"
    >
      <img [src]="items[i].image" appDefaultPlaceholder /></div
  ></ng-template>
</div>
