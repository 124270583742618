import { HttpResponse } from '@angular/common/http';
import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { Subject } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { untilDestroyed } from '../functions/until-destroyed';
import { EventInterface } from '../interfaces/event.interface';
import { SaveFileUtils } from '../utils/save-file-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';

@Directive({
  selector: '[appAddToCalendar]'
})
export class AddToCalendarDirective implements OnInit, OnDestroy {
  @Input() event: EventInterface;

  isLoading = false;
  private clicks = new Subject<Event>();

  @HostListener('click', ['$event'])
  clickEvent(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.clicks.next(e);
  }

  constructor(
    private eventService: EventHttpService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.clicks
      .pipe(
        filter(() => !this.isLoading),
        untilDestroyed(this),
      )
      .subscribe((e: Event) => {
        this.isLoading = true;
        if (this.event && this.event.eventDateTime) {
          const eventId = this.event.id || this.event.instanceId;
          const targetDateTimeZoneOffset = TimeUtils.getTargetDateTimezone(
            this.event.eventDateTime
          );
          this.eventService
            .addToCalendar(eventId, {
              targetTimezone: targetDateTimeZoneOffset,
            })
            .pipe(
              finalize(() => {
                this.isLoading = false;
              })
            )
            .subscribe(
              (res: HttpResponse<Blob>) => {
                if (res) {
                  SaveFileUtils.saveFile(res);
                  this.toastService.showSuccess('UI.Export.Success');
                }
              },
              () => {
                this.toastService.showError('UI.Export.Failed');
              }
            );
        }
      });
  }

  ngOnDestroy(): void { /**/ }
}
