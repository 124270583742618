import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from '@core/session.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StrictlyCookies } from '@shared/enums/strictly-cookies.enum';
import { LogoDisplayOption, PoweredByLogoOption } from '@shared/models/logo-type.model';
import { CookiesWeAreSorryComponent } from './cookies-operate/cookies-we-are-sorry/cookies-we-are-sorry.component';
import { CookiesSettingsComponent } from './cookies-settings/cookies-settings.component';

@Component({
  selector: 'app-cookies-acceptance',
  templateUrl: './cookies-acceptance.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CookiesAcceptanceComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() logo_display_option: LogoDisplayOption;

  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config
  @Input() poweredByLogo: PoweredByLogoOption;

  acceptedCookies = false;
  isOpenSetttings = false;
  isOpenWeAreSorry = false;
  isBrowserCookiesEnabled = true;

  constructor(
    private modalService: NgbModal,
    private sessionService: SessionService
  ) {
    this.isBrowserCookiesEnabled = this.checkBrowserCookies();
    this.acceptedCookies = !!this.sessionService.getAcceptedCookies();
  }

  ngOnInit(): void {
    if (!this.isBrowserCookiesEnabled) {
      this.openWeAreSorry();
    } else {
      if (!this.checkPolicyCookies()) {
        this.openWeAreSorry();
      }
    }
  }

  private checkBrowserCookies(): boolean {
    let isCookieEnabled = navigator.cookieEnabled;

    if (!isCookieEnabled) {
      document.cookie = 'jiptestcookie';
      isCookieEnabled = document.cookie.indexOf('testcookie') !== -1;
    }

    return isCookieEnabled;
  }

  private checkPolicyCookies(): boolean {
    // Strictly necessary Cookies
    const strictlyCookies = this.sessionService.getStrictlyCookies();

    if (!this.acceptedCookies && strictlyCookies) {
      return strictlyCookies !== StrictlyCookies.Inactive;
    }

    return true;
  }

  openSettings(): void {
    this.isOpenSetttings = true;

    const modalRef = this.modalService.open(CookiesSettingsComponent, {
      backdropClass: 'modal-cookies-settings',
      windowClass: 'modal-cookies-settings',
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.result
      .then((accepted) => {
        if (accepted?.strictlyCookies) {
          this.acceptCookies();
        }

        if (accepted && !accepted.strictlyCookies) {
          this.setStrictlyCookies(StrictlyCookies.Inactive);
          if (!this.isOpenWeAreSorry) {
            this.openWeAreSorry();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isOpenSetttings = false;
      });

    const strictlyCookies = this.sessionService.getStrictlyCookies();
    modalRef.componentInstance.isActiveStrictlyCookies =
      strictlyCookies !== StrictlyCookies.Inactive;

    modalRef.componentInstance.logoDisplayOption = this.logo_display_option;
    modalRef.componentInstance.globalOrgId = this.global_org_id;
    modalRef.componentInstance.poweredByLogo = this.poweredByLogo;
  }

  openWeAreSorry(): void {
    this.isOpenWeAreSorry = true;
    const modalRef = this.modalService.open(CookiesWeAreSorryComponent, {
      backdropClass: 'modal-cookies-settings',
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });

    modalRef.result.finally(() => {
      this.isOpenWeAreSorry = false;
    });
  }

  acceptCookies(): void {
    this.acceptedCookies = true;
    this.sessionService.setAcceptedCookies();
    this.sessionService.deleteStrictlyCookies();

    if (this.isOpenWeAreSorry) {
      this.modalService.dismissAll(ModalDismissReasons.ESC);
    }
  }

  setStrictlyCookies(strictlyCookies: StrictlyCookies): void {
    this.sessionService.setStrictlyCookies(strictlyCookies);
  }
}
