import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LinkInterface } from '../interfaces/attribute-description.interface';

@Directive({
  selector: '[appUrlPrefix]',
})
export class UrlPrefixDirective implements OnChanges {
  @Input() value: string | LinkInterface;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.value) {
      const valueAsString = this.value as string;
      const valueAsLink = this.value as LinkInterface;
      const urlValue =
        typeof valueAsString === 'string' ? valueAsString : valueAsLink?.url;

      if (urlValue) {
        const url =
          urlValue.includes('http') || urlValue.includes('mailto:')
            ? urlValue
            : `http://${urlValue}`;

        this.el.nativeElement.setAttribute('href', url);
      }
    }
  }
}
