<div *ngIf="data" class="card-item col">
  <div class="row">
    <a class="col link-unstyled" appUnsavedFormCheck [href]="entityUrl">
      <div class="card-item__info--link pt-2 pb-2 h-100 col">
        <div>
          <h5
            class="venture__title widget__heading truncate-line-1"
            appTooltip
            triggers="manual"
            #t="ngbTooltip"
            [toolTipControl]="t"
            [ngbTooltip]="data.title"
          >
            {{ data.title }}
          </h5>
        </div>

        <ng-container *ngIf="uiTemplate | visibility : 'InnovationProgress'">
          <div class="venture__label widget__label">
            {{ 'UI.VenturePhase.InnovationProgress' | translate }}
          </div>
          <div>
            <app-form-field-custom
              [editable]="false"
              [selectedIndex]="data.phase"
              [type]="attributeType.VenturePhase"
              [attributeDescription]="phaseAttrDescription"
            ></app-form-field-custom>
          </div>
        </ng-container>
      </div>
    </a>
  </div>
</div>
