import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmojiData, EmojiEvent } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  selector: 'app-emojis-picker',
  templateUrl: './emojis-picker.component.html',
})
export class EmojisPickerComponent {
  @Input() position ='bottom';

  @Input() btnStyleClass = '';

  @Output() emojiSelected = new EventEmitter<EmojiData>();

  emojis = [
    {
      id: 'grinning',
    },
    {
      id: 'slightly_smiling_face',
    },
    {
      id: 'white_frowning_face',
    },
    {
      id: 'sob',
    },
    {
      id: 'joy',
    },
    {
      id: 'heart_eyes',
    },
    {
      id: 'face_with_rolling_eyes',
    },
    {
      id: 'persevere',
    },
    {
      id: 'open_mouth',
    },
    {
      id: 'sunglasses',
    },
    {
      id: 'star2',
    },
    {
      id: '+1',
    },
    {
      id: 'heart',
    },
  ];

  emojiClick(event: EmojiEvent): void {
    this.emojiSelected.emit(event.emoji);
  }
}
