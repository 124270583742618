<!-- //NOSONAR -->
<li class="nav-item dropdown dropdown-hover nav-profile standalone-widget">
  <!-- Login / Profile dropdown in navbar -->
  <a
    [href]="profileUrl"
    appUnsavedFormCheck
    class="navigation__profile-user d-lg-block"
  >
    <div
      *ngIf="!sessionService.isLogin; else profileLink"
      class="nav-link text-center"
    >
      <a appRequireLogin class="d-flex flex-md-column align-items-center">
        <img class="rounded-circle nav-profile__avatar" appDefaultAvatar />
      </a>

      <span class="nav-login__dropdown dropdown-toggle" id="dropdownMenuLogin">
        {{ 'Login' | translate }}
      </span>
    </div>

    <ng-template #profileLink>
      <div class="nav-link text-center">
        <ng-container *ngTemplateOutlet="profileAvatarRef"></ng-container>

        <span class="nav-profile__dropdown dropdown-toggle standalone-widget">
          {{ 'Profile' | translate }}
        </span>
      </div>
    </ng-template>
  </a>

  <!-- Dropdown -->
  <div
    class="nav-profile__dropdown-menu dropdown-menu dropdown-menu-right standalone-widget dropdown px-0"
    aria-labelledby="dropdownMenuButton"
  >
    <!-- Logged-in user info -->
    <section
      class="nav-profile d-block d-lg-block"
      *ngIf="profile && sessionService.isLogin"
    >
      <div class="navigation__content--padding">
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <img
              [src]="profile?.image"
              appDefaultAvatar
              class="rounded-circle nav-profile__avatar nav-profile__avatar--small"
            />
          </div>

          <div class="nav-profile__text">
            <li class="nav-profile__name text-truncate font-weight-bold">
              {{ profile.firstName }} {{ profile.lastName }}
            </li>

            <li class="text-truncate">
              {{ profile.email }}
            </li>

            <li class="text-truncate">
              {{ profile.phone }}
            </li>

            <li class="text-truncate">
              {{ profile.company?.name || profile.company }}
            </li>
          </div>
        </div>

        <a
          appUnsavedFormCheck
          [href]="profileUrl"
          class="btn btn-outline-primary w-100 py-0 mt-2"
          translate
        >
          <i class="fas fa-user"></i>
          My Profile
        </a>
      </div>

      <ng-container *ngTemplateOutlet="profileActions"></ng-container>

      <ng-container *ngTemplateOutlet="appInformationRef"></ng-container>

      <ng-container *ngTemplateOutlet="loginLogoutRef"></ng-container>
    </section>
  </div>

  <div
    class="dropdown-menu dropdown-menu-right dropdown px-0"
    aria-labelledby="dropdownMenuLogin"
  >
    <section
      class="nav-profile d-lg-block"
      aria-labelledby="dropdownMenuLink"
      *ngIf="!sessionService.isLogin"
    >
      <ng-container *ngTemplateOutlet="appInformationRef"></ng-container>

      <ng-template [ngTemplateOutlet]="loginRef"></ng-template>
    </section>
  </div>
</li>

<ng-template #profileActions>
  <ng-container *ngIf="sessionService.isLogin">
    <div class="navigation__content--padding text-center">
      <div class="nav-profile__action-item font-weight-bold" translate>
        UI.Nav-Profile.Account
      </div>

      <div class="nav-profile__action-item">
        <a
          appRequireLogin
          appUnsavedFormCheck
          (click)="storePreviousUrl()"
          [href]="changePasswordUrl"
          class="nav-link p-0"
        >
          {{ 'ChangePassword' | translate }}
        </a>
      </div>

      <div class="nav-profile__action-item">
        <a
          appRequireLogin
          appUnsavedFormCheck
          (click)="storePreviousUrl()"
          [href]="changeEmailUrl"
          class="nav-link p-0"
        >
          {{ 'UI.Nav-Profile.Change-email' | translate }}
        </a>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #appInformationRef>
  <app-nav-information
    [navigateUrl]="about_link"
    [isTenantAdmin]="profile?.isTenantAdmin"
    isStandaloneWidget="true"
  ></app-nav-information>
</ng-template>

<ng-template #profileAvatarRef>
  <a
    [href]="profileUrl"
    appUnsavedFormCheck
    class="d-flex flex-md-column align-items-center text-center justify-content-center"
  >
    <img
      [src]="profile?.image"
      class="rounded-circle nav-profile__avatar"
      appDefaultAvatar
    />
  </a>
</ng-template>

<ng-template #loginRef>
  <div
    (click)="goToLoginPage()"
    appRequireLogin
    class="nav-profile__action-item nav-profile__action-item--logout"
    translate
  >
    Login
  </div>
</ng-template>

<ng-template #loginLogoutRef>
  <div
    *ngIf="sessionService.isLogin; else loginRef"
    appUnsavedFormCheck
    (clickAfterChecking)="logout($event)"
    class="nav-profile__action-item nav-profile__action-item--logout"
    translate
  >
    Log out
  </div>
</ng-template>
