import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-section-layout',
  templateUrl: './search-results-section-layout.component.html',
})
export class SearchResultsSectionLayoutComponent {
  @Input() title = '';

  @Input() isShowPaging = false;

  @Input() isEmpty = false;

  @Input() class = '';

  @Input() page = 1;

  @Input() pageSize = 10;

  @Input() collectionSize = 0;

  @Output() pageChanged = new EventEmitter<number>();

  @Output() viewMoreSpecificEntity = new EventEmitter<void>();

  onPageChange(event: number): void {
    this.pageChanged.emit(event);
  }

  viewSpecificEntity(): void {
    this.viewMoreSpecificEntity.emit();
  }
}
