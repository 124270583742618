import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import {
  EntityName,
  MessageAction,
  MessageType,
} from '../enums/entity-name.enum';
import { NewsMessageInterface } from '../interfaces/news-message.interface';
import { PeopleInterface } from '../interfaces/people.interface';

export enum SingleMessageType {
  Default,
  VentureSubmittedToChallenge,
  OthersEntityMessage,
}

export class MessageUtils {
  static isNotSupportSendGroupView(messageAction: MessageAction): boolean {
    return [
      MessageAction.GetInTouch,
      MessageAction.UnavailablePendingMember,
      MessageAction.Approved,
      MessageAction.Rejected,
      MessageAction.PendingRequest,
      MessageAction.RemovedMember,
      MessageAction.Registered,
      MessageAction.RemovedMyselfFromMember,
    ].includes(messageAction);
  }

  static isShowAsNoReplyMessageYet(
    message: NewsMessageInterface,
    userId: number
  ): boolean {
    if (
      message.messageType !== MessageType.Message &&
      message.action !== MessageAction.GetInTouch
    ) {
      return false;
    }

    if (
      message.content?.entityName === EntityName.VentureSubmittedToChallenge
    ) {
      return false;
    }

    return message?.latestMessage
      ? userId !== message.latestMessage?.from?.id
      : userId !== message?.from?.id;
  }

  static getSingleMessageType(
    isMessageType: boolean,
    isVentureSubmittedToChallenge: boolean
  ): SingleMessageType {
    let messageType: SingleMessageType;

    if (isMessageType && !isVentureSubmittedToChallenge) {
      messageType = SingleMessageType.Default;
    } else if (isMessageType && isVentureSubmittedToChallenge) {
      messageType = SingleMessageType.VentureSubmittedToChallenge;
    } else {
      messageType = SingleMessageType.OthersEntityMessage;
    }

    return messageType;
  }

  static getRecipients(
    message: NewsMessageInterface,
    filteringId?: number
  ): PeopleInterface[] {
    if (message?.latestMessage?.group) {
      return message.latestMessage.group?.toItems?.filter(
        ({ id }) => id !== filteringId
      );
    }

    return [message.from, ...message.group?.toItems].filter(
      ({ id }) =>
        id !== MessageUtils.getLastReplier(message).id && id !== filteringId
    );
  }

  static getLastReplier(
    message: NewsMessageInterface | ChatMessage
  ): PeopleInterface {
    if (message?.latestMessage) {
      return message.latestMessage.from;
    }

    return message.replyCount && message.replies
      ? message.replies[message.replies.length - 1].from
      : message.from;
  }

  static transformRecipientName(recipient: UserInterface): void {
    if (!recipient) return;

    const { firstName, lastName } = recipient;

    recipient.name = [firstName, lastName].filter(Boolean).join(' ');
  }
}
