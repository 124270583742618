import { Component, Input } from '@angular/core';
import { SessionService } from '@src/app/core/session.service';
import { EventPartnerStatusCodeIdEnum, PartnerStatusEnum } from '../../enums/partner-status.enum';
import { CustomAtrributeValue } from '../../interfaces/attribute-description.interface';

@Component({
  selector: 'app-partner-status',
  templateUrl: './partner-status.component.html',
  styles: [
  ]
})
export class PartnerStatusComponent {
  @Input() partnerStatus: CustomAtrributeValue;
  @Input() isEventPartnerStatus = false;
  @Input() appendPrefixText = '';

  PartnerStatusEnum = PartnerStatusEnum;

  constructor(
    protected sessionService: SessionService
  ) { }

  // #region HANDLE PARTNER STATUS
  get isShownPartnerStatus(): boolean {
    if (this.partnerStatus) {
      if (this.isEventPartnerStatus) {
        return this.partnerStatus.codeId !== EventPartnerStatusCodeIdEnum.DefaultValue;
      }
      else {
        return this.partnerStatus.codeId !== PartnerStatusEnum.Hide;
      }
    }
    return false;
  }

  getPartnerStatusClass(partnerStatusCode: string): string {
    let result = 'partner-state--';
    if (!partnerStatusCode) {
      return '';
    }
    if (this.isEventPartnerStatus) {
      switch (partnerStatusCode) {
        case EventPartnerStatusCodeIdEnum.DefaultValue:
          result = '';
          break;

        case EventPartnerStatusCodeIdEnum.BronzePartner:
        case EventPartnerStatusCodeIdEnum.SilverPartner:
        case EventPartnerStatusCodeIdEnum.GoldPartner:
        case EventPartnerStatusCodeIdEnum.PlatinumPartner:
        case EventPartnerStatusCodeIdEnum.DiamondPartner:
        case EventPartnerStatusCodeIdEnum.MediaPartner:
        case EventPartnerStatusCodeIdEnum.ExecutionPartner:
          result += `Event${partnerStatusCode}`;
      }
      return result;
    }
    else {
      switch (partnerStatusCode) {
        case PartnerStatusEnum.Hide:
          result = '';
          break;

        case PartnerStatusEnum.Free:
        case PartnerStatusEnum.Silver:
        case PartnerStatusEnum.Gold:
        case PartnerStatusEnum.Platinum:
          result += partnerStatusCode;
          break;
      }
      return result;
    }
  }

  getPartnertStatusValue(partnerStatus: string): string {
    return this.appendPrefixText ? `${this.appendPrefixText} ${partnerStatus}` : partnerStatus;
  }
  // #endregion End Handle Partner Status
}
