import { Component, Input } from '@angular/core';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-shared-icon',
  templateUrl: './shared-icon.component.html',
  styles: [],
})
export class SharedIconComponent {
  @Input() name: string;
  @Input() className: string;
  @Input() hasBigSize = false;
  @Input() width = '20px';
  @Input() height = '20px';

  /* fix unwanted border due to missing src attribute */
  readonly iconPlaceholder = `${environment.wordpressJipImages}icons/icon-placeholder.svg`;

  iconPosition = {
    lightbulb: '0 0',
    unicorn: '-20px 0',
    people: '-40px 0',
    event: '-60px 0',
    organisation: '-80px 0',
    notification: '-100px 0',
    person: '-120px 0',
    logoBBV: '0 -20px',
    logoRectangle: '0 -51px',
    logoRectangleMobile: '-20px -51px',
    logo: '-344px 0',
  };
}
