import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '@src/app/core/session.service';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-session-expiration-dialog',
  templateUrl: './session-expiration-dialog.component.html',
  styles: [],
})
export class SessionExpirationDialogComponent {
  @ViewChild('modalRef') modalRef: ElementRef;
  @Input() title = 'Session expired';
  @Input() message: string;
  @Input() confirmLabel = 'Ok';

  constructor(
    public activeModal: NgbActiveModal,
    private sessionService: SessionService
  ) {}

  confirm(): void {
    this.activeModal.close(true);
    FormUtils.openWithLanguagePath(
      this.sessionService,
      environment.jipUrl.login,
      '_blank'
    );
  }
}
