import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PeopleEditComponent } from '@src/app/components/peoples/components/people/people-edit/people-edit.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { PeopleHttpService } from '@src/app/core/http/people-http.service';
import { UploadService } from '@src/app/core/http/upload.service';
import { SessionService } from '@src/app/core/session.service';
import { FormSectionComponent } from '@src/app/shared/components/form-section/form-section.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import {
  FileInterface,
  ImageInterface,
} from '@src/app/shared/interfaces/file.interface';
import { PeopleInterface } from '@src/app/shared/interfaces/people.interface';
import { environment } from '@src/environments/environment';
import { Observable, catchError, finalize, of, tap } from 'rxjs';

@Component({
  selector: 'app-enforce-profile',
  templateUrl: './enforce-profile.component.html',
  providers: [CustomFormService],
})
export class EnforceProfileComponent
  extends PeopleEditComponent
  implements OnInit
{
  isUploading = false;
  showUploadImageSection = true;

  isSaving = false;

  @ViewChild('enforceProfileFormSection')
  enforceProfileFormSection!: FormSectionComponent;

  constructor(
    public peopleHttpService: PeopleHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector,
    private uploadService: UploadService,
    private activeModal: NgbActiveModal
  ) {
    super(peopleHttpService, authenService, sessionService, injector);

    this.entityName = EntityName.Person;
  }

  get image() {
    return this.form?.get('Image');
  }

  get leadCompany() {
    return this.form?.get('LeadCompany');
  }

  get jobTitle() {
    return this.form?.get('JobTitle');
  }

  get canSaveForm() {
    return this.jobTitle?.valid && this.leadCompany?.valid;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  customInit(): void {
    this.entityRoot = environment.jipUrl.people;
    this.formSections = [
      {
        fieldsName: ['Image', 'JobTitle', 'LeadCompany', 'CompanySize'],
      },
    ];
  }

  onSelectFile(event: any): void {
    // called each time file input changes
    if (event.target.files) {
      const file: File = event.target.files[0];
      this.upload(file);
      this.showUploadImageSection = false;
    }
  }

  upload(file: File): void {
    this.isUploading = true;
    this.uploadService.imageUploadPublisher.next(true);
    let apiRequest: Observable<object>;

    apiRequest = this.uploadService.uploadSingleLocale(
      this.id,
      file,
      file?.name,
      '',
      'en_US'
    );

    apiRequest
      .pipe(
        catchError(() => {
          return of(null);
        }),
        finalize(() => {
          this.isUploading = false;
        })
      )
      .subscribe((data: FileInterface | any) => {
        if (data) {
          if (Array.isArray(data.images)) {
            data.images.forEach((image: ImageInterface, index) => {
              if (image.locale === 'en_US' || index === 0) {
                image.id = data.id;
                this.form.patchValue(
                  { Image: { ...image } },
                  { emitEvent: true }
                );
              }
            });
          } else if (data.image?.url) {
            data.image.id = data.id;
            this.form.patchValue(
              { Image: { ...data.image, id: data.id } },
              { emitEvent: true }
            );
          }
        }
        this.uploadService.imageUploadPublisher.next(false);
      });
  }

  toggleUpload() {
    this.showUploadImageSection = !this.showUploadImageSection;
  }

  showLeadOrgError(): boolean {
    const leadCompany = this.form?.controls?.LeadCompany;
    const leadCompanyAttribute = this.getFieldOptions('LeadCompany');

    return leadCompanyAttribute.required && !leadCompany.value;
  }

  onSave(): void {
    this.enforceProfileFormSection?.save();
  }

  handleAfterGetEntityDetail(entity: PeopleInterface) {
    super.handleAfterGetEntityDetail(entity);
    if (entity.image.url) {
      this.showUploadImageSection = false;
    }
  }

  saveSection(...index: number[]): Observable<boolean> {
    this.isSaving = true;
    return super.saveSection(...index).pipe(
      tap((success) => {
        if (success) {
          this.isSaving = false;
          this.activeModal.dismiss();
        }
      })
    );
  }
}
