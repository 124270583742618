<app-modal-wrapper
  class="export-summary-dialog"
  [textHeader]="textHeader"
  [isSubmitting]="isSubmitting"
  textOk="UI.Common.Export"
  (btnOkClicked)="submit()"
  (btnCancelClicked)="cancel()"
  [disabled]="!haveCheckedItem()"
>
  <div class="px-2">
    <div>
      {{ 'UI.Export.Label.Choose-entities' | translate }}
    </div>
    <div class="mt-2">
      <app-tree-view-check-box
        [items]="checkboxItems || []"
        class="tree-view-check-box"
      ></app-tree-view-check-box>
    </div>
  </div>
</app-modal-wrapper>
