import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading$ = new BehaviorSubject<boolean>(false);

  setLoadingState(isLoading: boolean, delayTime?: number): void {
    setTimeout(() => this.isLoading$.next(isLoading), delayTime);
  }

  getLoadingState(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
