<ng-container *ngIf="isVisible && searchKeyword">
  <div
    *ngIf="showGroupLogo"
    class="channel-search-list__group--title d-flex align-items-center"
  >
    <app-shared-icon [name]="entityLogoName"></app-shared-icon>
    <span class="ml-2">
      {{ entityLabel | translate }}
    </span>
  </div>
  <ng-container *ngIf="!isSearching; else loading">
    <ng-container *ngIf="searchItems && searchItems.length === 0; else list">
      <p class="result-not-found m-0" translate>
        {{ 'UI.TopicFilter.NoResult.' + entityName }}
      </p>
    </ng-container>
    <ng-template #list>
      <ul
        *ngFor="let item of searchItems"
        class="channel-search-list__group--items m-0 p-0"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      >
        <li
          class="channel-filter-item dropdown-item w-100"
          (click)="onSelectChannel($event, item)"
          style="list-style-type: none"
        >
          <div class="d-flex align-items-center gap-8 w-100">
            <div
              *ngIf="item.image"
              class="upload-file__container partner-org-select__image-container flex-shrink-0"
            >
              <img
                appDefaultPlaceholder
                appDynamicImgUrl
                [src]="item.image.url"
                [alt]="item.image.alt"
              />
            </div>
            <div
              class="overflow-hidden truncate-line-1 d-flex align-items-center"
            >
              <h6 class="option-name m-0">
                {{ item.displayName }}
              </h6>
            </div>
          </div>
        </li>
      </ul>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="w-100 d-flex justify-content-center">
      <i class="search__icon fas fa-spinner fa-spin"></i>
    </div>
  </ng-template>
</ng-container>
