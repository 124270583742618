import { Directive, Input, OnChanges } from '@angular/core';
import { ScrollingHandlerService } from '@shared/services/scrolling-handler.service';

@Directive({
  selector: '[appScrollToFragment]',
})
export class ScrollToFragmentDirective implements OnChanges {
  @Input('appScrollToFragment') data: any;

  ngOnChanges(): void {
    if (this.data) this.scrollingHandler.scrollToFragmentIfAny();
  }

  constructor(private scrollingHandler: ScrollingHandlerService) {}
}
