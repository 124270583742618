import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import {
  VENTURE_CHARACTERISTICS,
  VentureHttpService,
} from '@core/http/venture.http.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { AttributeType } from '@shared/enums/attribute-type.enum';
import { GlobalEventBus } from '@shared/enums/event-bus.enum';
import { UserInterface } from '@shared/interfaces/user.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { SessionService } from '@src/app/core/session.service';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { CommentInterface } from '@src/app/shared/interfaces/comment.interface';
import {
  EntityGlobalFilterCriteria,
  FilterPanePayload,
} from '@src/app/shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { PaginationSettingPlace } from '@src/app/shared/models/pagination.model';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { Observable } from 'rxjs';
import { BaseListComponent } from '../base/base-list/base-list.component';
import { GetInTouchDialogComponent } from '../dialogs/get-in-touch-dialog/get-in-touch-dialog.component';

@Component({
  selector: 'app-ventures',
  templateUrl: './ventures.component.html',
  providers: [{ provide: BaseHttpService, useClass: VentureHttpService }],
})
export class VenturesComponent
  extends BaseListComponent<VentureInterface>
  implements OnInit, OnChanges, OnDestroy
{
  // tslint:disable-next-line:variable-name
  @Input() ui_template: TemplateName = TemplateName.Default;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_filter_pane: boolean;
  @Input() filterPaneUi: string;

  entitybase = environment.jipUrl.venture;
  organizationBase = environment.jipUrl.organizations;

  allType = AttributeType;
  comments: CommentInterface[];
  currentPerson: UserInterface;
  entityName = EntityName.Venture;

  showPeopleModal = false;
  showLikesModal = false;

  page = 1;
  pageSize = 8;

  isAlwaysShowAttributes = true;

  private globalPaneEvent: any;

  constructor(
    public listService: VentureHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.isAlwaysShowAttributes = this.ui_template === TemplateName.Default;
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((currentUser: UserInterface) => {
        this.currentPerson = currentUser;
      });

    this.handleStandaloneWidgetMode();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.globalPaneEvent) {
      this.eventBus.removeEventListener(this.globalPaneEvent);
    }
  }

  getEntityBase(): string {
    return environment.jipUrl.venture;
  }

  shouldShowVentureDetails(item = {} as VentureInterface): boolean {
    return this.listService.shouldShowContent(
      item.attributeDescriptions,
      item,
      VENTURE_CHARACTERISTICS
    );
  }

  shouldShowGetInTouch(item: VentureInterface, id: number): boolean {
    const isStakeholder = item.stakeholders.some((s) => s.id === id);
    const isInitiator = item.initiator?.id === id;

    return !isStakeholder && !isInitiator;
  }

  shouldShowDescription(item: VentureInterface): boolean {
    return this.listService.shouldShowContent(
      item.attributeDescriptions,
      item,
      ['Content', 'Excerpt']
    );
  }

  shouldShowVentureConstitutionState(item: VentureInterface): boolean {
    return item.canViewNdaContent;
  }

  openGetInTouch(venture: VentureInterface): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;
      
    const modalRef = this.modalService.open(GetInTouchDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    modalRef.componentInstance.venture = venture;
  }

  handleStandaloneWidgetMode(): void {
    // Defer execution until widgets are fully loaded
    setTimeout(() => {
      if (this.sessionService.isHeaderVisible) return;

      this.updatePageFilters({
        keyword: '',
        isMyInvolvement: false,
        isMyOrgs: false,
        fromFilterPane: true,
      });

      this.listenFilterPaneEvent();
    });
  }

  getCurrentVentureStatusIndex(venture: VentureInterface): number {
    let idx = 0;
    if (!venture) {
      return idx;
    }
    const lstVentureStatusChoices =
      venture.attributeDescriptions.find((x) => x.propertyName === 'Status')
        ?.choice?.selections || [];
    idx = lstVentureStatusChoices.findIndex(
      (x) => x.codeId === venture.status?.codeId
    );
    return idx;
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncEntityJoinStates();
    this.modifyDescriptionView();
  }

  protected getFilterDto(
    filterCriteria: EntityGlobalFilterCriteria
  ): EntityGlobalFilterCriteria {
    let filterDto = super.getFilterDto(filterCriteria);
    const filterPanePayload: FilterPanePayload[] = [];
    this.setLatestFilterPaneSourceToPaginationFilterStored(
      this.filterPaneSource,
      PaginationSettingPlace.VentureMainList
    );

    for (const key of Object.keys(this.filterPaneSource)) {
      const attrDes = this.customFormService.getCustomAttributeFieldOptions(
        StringUtils.toUpperCaseFirstLetter(key)
      );
      const dto: FilterPanePayload = {
        propertyName: StringUtils.toUpperCaseFirstLetter(key),
        attributeType: attrDes?.originalAttributeType || attrDes?.attributeType,
        values: this.filterPaneSource[key] || [],
      };
      filterPanePayload.push(dto);
    }

    filterDto = {
      ...filterDto,
      attributeValues: [...filterPanePayload],
    };
    return filterDto;
  }

  protected getPagingMethod(
    filterDto: EntityGlobalFilterCriteria
  ): Observable<ApiGetResponse<VentureInterface>> {
    return this.listService.paginateAsPost(filterDto);
  }

  private modifyDescriptionView(): void {
    this.items.forEach((venture: VentureInterface) => {
      if (venture.excerpt) {
        venture.excerpt = this.enhanceDescriptionHtmlString(venture.excerpt);
      }
      if (venture.content) {
        venture.content = this.enhanceDescriptionHtmlString(venture.content);
      }
    });
  }

  private listenFilterPaneEvent(): void {
    this.globalPaneEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.FilterPaneEvent,
      callback: () => this.filterData(),
    });
  }
}
