import { Component, Input, OnInit } from '@angular/core';
import { NavProfileComponent } from '@src/app/components/master-layout/navs/components/nav-profile/nav-profile.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { ReportingPopupService } from '@src/app/core/services/reporting-popup.service';
import { SessionService } from '@src/app/core/session.service';

@Component({
  selector: 'app-nav-custom-link-profile',
  templateUrl: './nav-custom-link-profile.component.html',
  styles: [],
})
export class NavCustomLinkProfileComponent
  extends NavProfileComponent
  implements OnInit
{
  // tslint:disable-next-line:variable-name
  @Input() about_link: string;

  constructor(
    authenService: AuthenService,
    reportingPopupService: ReportingPopupService,
    sessionService: SessionService
  ) {
    super(authenService, reportingPopupService, sessionService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
