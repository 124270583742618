export enum RecipientType {
  People = 'SendMessageChoicesPeople',
  Ventures = 'SendMessageChoicesVentures',
  Organizations = 'SendMessageChoicesOrganizations',
  SubmittedVentures = 'SendMessageChoicesSubmittedVentures',
  Event = 'SendMessageChoicesEvents',

  // Entity sharing
  ShareMessageChoicesEverybody = 'ShareMessageChoicesEverybody',
}
