export enum DateFormat {
  MetadataTable = 'd. MMM y',
  Timestamp = 'EEEE, d MMMM y | h:mm a',
  TimestampDate = 'EEEE, d MMMM y',
  TimestampTime = 'h:mm a',
  FullDateTimeCommon = 'E, LLL dd, y HH:mm',
  FullDateTimeCommon2 = 'E, dd.MM.y | HH:mm',
  FullDateTimeCommon3 = 'E, dd. MM y | HH:mm',
  Timeline = 'dd.MM.yyyy',

  ddd = 'ddd',
  dddd = 'dddd',
  MMM_YYYY = 'MMMM YYYY',
  MMMM_YYYY = 'MMMM YYYY',
  DD_MMM_YYYY = 'DD MMM YYYY',
}
