import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityName, MessageAction } from '@shared/enums/entity-name.enum';
import { News } from '@shared/interfaces/news.interface';
import { SessionService } from '@core/session.service';
import { NewsMessagesHttpService } from '@core/http/news-messages-http.service';

@Component({
  selector: 'app-news-card-footer',
  templateUrl: './news-card-footer.component.html',
})
export class NewsCardFooterComponent {
  @Input() news: News;

  @Input() currentPersonId: number;

  @Output() commentModalClosed = new EventEmitter<News>();

  MessageAction = MessageAction;

  EntityName = EntityName;

  showLikesModal: boolean;
  selectedEntityId: number;
  selectedEntity: News;

  constructor(
    private readonly sessionService: SessionService,
    public readonly newsService: NewsMessagesHttpService
  ) {}

  toggleLike(news: News): void {
    if (!this.sessionService.isLogin) {
      return;
    }
    if (news) {
      if (!news.isLiked) {
        this.newsService.like(news.id).subscribe((newLikeId) => {
          news.isLiked = !news.isLiked;
          news.likesCount = news.likesCount + 1;
          news.likeId = newLikeId;
        });
      } else {
        this.newsService.unlike(news.likeId).subscribe((res) => {
          news.isLiked = !news.isLiked;
          news.likesCount = news.likesCount > 0 ? news.likesCount - 1 : 0;
          news.likeId = null;
        });
      }
    }
  }

  showLikes(news: News): void {
    this.selectedEntityId = null;
    this.selectedEntity = null;
    setTimeout(() => {
      this.selectedEntityId = news.id;
      this.selectedEntity = news;
    });
    this.showLikesModal = true;
  }

  onCommentModalClosed(news: News): void {
    this.commentModalClosed.emit(news);
  }
}
