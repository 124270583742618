import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityExportComponent } from '@src/app/components/entity-section/entity-export/entity-export.component';
import { ExportHttpServiceService } from '@src/app/core/http/export-http-service.service';
import { ToastService } from '@src/app/core/toast.service';
import { TreeNode } from '@src/app/shared/components/tree-view-check-box/tree-view-check-box.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-event-export',
  templateUrl: './event-export.component.html',
  styles: [
  ]
})
export class EventExportComponent extends EntityExportComponent {
  textHeader = 'UI.Event.ExportHeader';
  isSubmitting = false;
  checkboxItems: TreeNode[] = [
    {
      text: 'UI.EventEdit.Participants',
      value: 'exportPeople',
      checked: true,
    },
    {
      text: 'UI.Event.Detail',
      value: 'exportEvent',
      checked: true,
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    protected toastService: ToastService,
    protected exportService: ExportHttpServiceService
  ) {
    super(activeModal, toastService);
  }

  handleExport(force = false): void {
    let api$: Observable<any>;
    const params = {
      ...this.getParamsExportValue(this.checkboxItems, { force })
    };

    if (this.entityId) {
      api$ = this.exportService.getReportForEventOwner(this.entityId, params);
      this.handleExportResponse(api$);
    }
  }
}
