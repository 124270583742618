<ng-container *ngIf="editing; else viewRef">
  <div class="timeline-edit">
    <table class="table">
      <thead>
        <tr>
          <ng-container *ngFor="let attribute of timelineAttributes">
            <th *ngIf="attribute.isVisibleInSystem">
              {{ attribute.displayName }}
            </th>
          </ng-container>
          <ng-container *ngIf="isTest">
            <th>Data store in BE</th>
          </ng-container>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let item of items; index as index; last as last">
          <tr
            app-dynamic-object
            [data]="item"
            [error]="errors[index]"
            [isDuplicateValue]="duplicateItems[index]"
            [editing]="editing"
            [advancedEditing]="advancedEditing"
            [attributes]="timelineAttributes"
            [readonlyAttributes]="readonlyAttributes"
            (dataChange)="emitValue($event, index); validateDuplicateValues()"
          ></tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div>
    <app-advanced-edit-button (click)="advancedEditing = !advancedEditing">
    </app-advanced-edit-button>
  </div>
</ng-container>

<ng-template #viewRef>
  <app-timeline-view [items]="items"> </app-timeline-view>
</ng-template>
