import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantInterface } from '@shared/interfaces/tenant.interface';
import { TranslateServiceLanguage } from '@src/app/shared/interfaces/language.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { Observable } from 'rxjs';
import { AuthenService } from '../authen/authen.service';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root',
})
export class TenantHttpService extends BaseHttpService<TenantInterface> {
  apiUrl = 'tenants';

  constructor(
    protected http: HttpClient,
    protected authenService: AuthenService
  ) {
    super(http, authenService);
  }

  // Get current id by domain
  getCurrentTenantId(domain: string): Observable<TenantInterface> {
    const currentDomain = domain;
    const jipLocale = StringUtils.getLocaleFromUrl()
      || TranslateServiceLanguage.de; // For elementor page

    const headers = new HttpHeaders({ 'jip-locale': jipLocale });

    return this.http.get<TenantInterface>(
      `${this.apiUrl}/domain?domain=${currentDomain}`,
      { headers }
    );
  }
}
