<div class="row">
  <ng-container *ngIf="message && messageText">
    <div class="col-12 mb-3" [class.col-lg-10]="!showAsHeader">
      <app-rich-text-toggle
        [text]="messageText"
        [image]="
          message.latestMessage ? message.latestMessage.image : message.image
        "
        [controlName]="'group-message' + message.id"
        [minNumOfRows]="2"
      ></app-rich-text-toggle>
    </div>
  </ng-container>

  <ng-container *ngIf="message | call : shouldShowReplyBtn">
    <div class="col-12 col-lg-2 text-lg-right">
      <button
        appRequireLogin
        appDebounceClick
        (debounceClick)="openMessageBack()"
        class="btn btn-outline-primary people__follow-btn"
        translate
      >
        Reply
      </button>
    </div>
  </ng-container>
</div>
