<div
  *ngIf="displayItems?.length > 0; else emptyMessageRef"
  class="organization-card-list"
>
  <ng-container *ngFor="let item of displayItems">
    <div
      *ngIf="!item?.isPending"
      class="organization-card-list__item d-flex flex-column"
    >
      <app-organizer
        [leadCompany]="item"
        [editing]="false"
        [advancedSetting]="!editting && advancedSetting"
        [ignoreCompany]="ignoreCompany"
      >
      </app-organizer>

      <!-- Show / Hide Venture Control -->
      <ng-container *ngIf="editting && showVenturesCtrl">
        <div class="d-flex justify-content-center pl-3 pr-3 pb-3" appTooltip>
          <div [ngbTooltip]="'UI.Tooltip.ShowVentures' | translate">
            <app-toggle-button-slider
              [(status)]="item.showVentures"
              [settings]="{
                activeText: 'UI.Toggle.ShowVentures' | translate,
                deactiveText: 'UI.Toggle.HideVentures' | translate,
                activeIcon: 'fas fa-eye',
                deactiveIcon: 'fas fa-eye-slash'
              }"
            >
            </app-toggle-button-slider>
          </div>
        </div>
      </ng-container>

      <!-- Event Partner Status -->
      <ng-container *ngIf="lstEventPartnerStatus.length > 0">
        <div
          class="d-flex align-items-center px-3 pb-3 event-partner-status"
          [ngClass]="{
            'event-partner-status--height':
              !editting &&
              item.eventPartnerStatus &&
              item.eventPartnerStatus.codeId !==
                EventPartnerStatusCodeId.DefaultValue
          }"
        >
          <ng-container *ngIf="editting; else eventPartnerStatusViewMode">
            <label class="mr-2 word-wrap--normal"> Status </label>
            <app-boostrap-dropdown
              class="dropdown--full-width truncate-selection"
              [items]="lstEventPartnerStatus"
              [currentIndex]="
                getEventPartnerStatusIndex(item.eventPartnerStatus)
              "
              (selectItem)="selectEventPartnerStatus(item, $event)"
            >
            </app-boostrap-dropdown>
          </ng-container>
          <ng-template #eventPartnerStatusViewMode>
            <app-partner-status
              [partnerStatus]="item.eventPartnerStatus"
              [isEventPartnerStatus]="true"
            ></app-partner-status>
          </ng-template>
        </div>
      </ng-container>

      <!-- Show Challenge Timeline -->
      <div *ngIf="showChallengeTimeline">
        <app-timeline-current-phase [leadCompany]="item">
        </app-timeline-current-phase>
      </div>

      <span
        appRequireLogin
        *ngIf="shouldShowRemoveBtn(item)"
        (click)="removeItem(item)"
        class="organization-card-list__remove"
      >
        <em class="far fa-times fa-sm"></em>
      </span>
    </div>
  </ng-container>
</div>

<ng-template #emptyMessageRef>
  <div class="people-cards-emtpy" translate>
    {{ emptyPlacholderKey }}
  </div>
</ng-template>
