export interface LanguageInterface {
  locale: string;
  code: string;
  name: string;
}

export interface TranslationResourceItem {
  key: string;
  value: string;
}

export interface TranslationResourcesCache {
  version: string;
  locale: string;
  data: {};
}

export enum TranslateServiceLanguage {
  en = 'en_US',
  de = 'de_CH',
  fr = 'fr_CH',
}

export enum LanguageTranslateKey {
  en = 'English',
  de = 'Deutsch',
  fr = 'France',
}
