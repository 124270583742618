<div
  class="admin-settings d-flex justify-content-center"
  [style.height]="adminSectionHeight"
>
  <ng-container *ngIf="isLoadingPermission; else adminSectionRef">
    <div class="text-center">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
  </ng-container>
  <ng-template #adminSectionRef>
    <ng-container *ngIf="isTenantAdmin; else noPermission">
      <nav class="sidebar p-0" [class.expanded]="isSidebarExpanded">
        <ng-container *ngIf="sidebarItems?.length > 0">
          <div
            *ngFor="let item of sidebarItems"
            class="sidebar__item"
            [class.disabled]="item.disabled"
            [class.active]="selectedSidebarItem === item.section"
            (click)="onSelectSidebarItem(item.section)"
          >
            <div class="title">
              <i [class]="item.icon"></i>
              <span> {{ item.name | translate }} </span>
            </div>
            <i class="fas fa-plus-circle" *ngIf="item.childs?.length > 0"></i>
          </div>
        </ng-container>
      </nav>

      <div class="content col d-flex flex-column p-0">
        <div
          class="content__header d-flex justify-content-start align-items-center"
        >
          <div
            class="toggle mr-2"
            (click)="isSidebarExpanded = !isSidebarExpanded"
          >
            <i class="fas fa-bars"></i>
          </div>

          <div
            class="d-flex align-items-center justify-content-between flex-grow-1"
          >
            <h3 class="m-0">
              {{ sidebarItems[selectedSidebarItem]?.name | translate }}
            </h3>
            <a
              *ngIf="
                sidebarItems[selectedSidebarItem]?.section ===
                AdminSettingsSection.Dashboard
              "
              [href]="kpiUrl"
              target="_blank"
              class="btn btn-primary d-flex align-items-center"
            >
              <app-internal-icon
                [name]="InternalIcon.Kpi"
                [height]="18"
                [minWidth]="19.5"
                [className]="'filter-white mr-2'"
              ></app-internal-icon>
              <span class="font-weight-bold" translate> UI.TenantSetting.ViewKPI</span>
            </a>
          </div>
        </div>

        <div class="content__body">
          <ng-container *ngIf="isContentReady">
            <ng-container
              *ngIf="selectedSidebarItem === AdminSettingsSection.Organizations"
            >
              <app-organizations-admin-section></app-organizations-admin-section>
            </ng-container>

            <ng-container
              *ngIf="
                selectedSidebarItem === AdminSettingsSection.TenantConfiguration
              "
            >
              <app-tenant-configuration></app-tenant-configuration>
            </ng-container>

            <ng-container
              *ngIf="selectedSidebarItem === AdminSettingsSection.Dashboard"
            >
              <app-admin-dashboard></app-admin-dashboard>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-template #noPermission>
      <h3 class="d-flex justify-content-center align-items-center w-100">
        You don't have permission to access this panel.
      </h3>
    </ng-template>
  </ng-template>
</div>
