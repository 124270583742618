import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from '@core/i18n/i18n.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiHeadersInterceptor implements HttpInterceptor {
  constructor(
    private readonly sessionService: SessionService,
    private readonly i18nService: I18nService,
    private readonly centralConfig: CentralConfigService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldExcludeRequest(request)) {
      return next.handle(request);
    }

    if (!request.headers.get('Content-Type')) {
      request.headers.set('Content-Type', 'application/json');
    }

    const currentTimeZoneOffset = new Date().getTimezoneOffset() / -60;
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const defaultLocale = this.i18nService.language?.locale;
    const locale = request.headers.get('jip-locale') || defaultLocale;

    request = request.clone({
      setHeaders: {
        'jip-locale': locale,
        'Tenant-Id': this.sessionService.getTenantId() || '',
        'Time-Zone-Offset': currentTimeZoneOffset.toString(),
        'Time-Zone': currentTimeZone,
        InnovationSpace: this.centralConfig.innovationSpaceId?.toString() || '',
        NewsRedirectPath: this.centralConfig.newsRedirectPath || '',
      },
    });

    return next.handle(request);
  }

  private shouldExcludeRequest(request: HttpRequest<unknown>): boolean {
    return (
      /(tenants\/domain)/i.test(request.url) &&
      /(wp-json\/wp\/v2\/media)/i.test(request.url)
    );
  }
}
