import { Component, Injector, OnInit } from '@angular/core';
import { EventHttpService } from '@core/http/event-http.service';
import { TranslateService } from '@ngx-translate/core';
import { EventInterface } from '@shared/interfaces/event.interface';
import { EventUtils } from '@shared/utils/event-utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SessionService } from '@src/app/core/session.service';
import { ToastService } from '@src/app/core/toast.service';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { UIPosition } from '@src/app/shared/models/ui.model';
import { EventRegisterBtnDisabledPipe } from '@src/app/shared/pipes/event-register-tooltip.pipe';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';
import { BaseListComponent } from '../base/base-list/base-list.component';

@Component({
  selector: 'app-event-list',
  templateUrl: './events.component.html',
  providers: [{ provide: BaseHttpService, useClass: EventHttpService }],
})
export class EventListComponent
  extends BaseListComponent<EventInterface>
  implements OnInit
{
  entityName = EntityName.Event;
  registerIcon =
    environment.wordpressJipImages + 'icons/file-medical-solid.svg';

  page = 1;
  pageSize = 8;
  showPeopleModal = false;
  showLikesModal = false;
  orgUrl = environment.jipUrl.organizations;
  locationFlagPosition = new UIPosition('unset', 'unset', '0.75rem', '4.75rem');

  //#region DEFINE ATTRIBUTE DESCRIPTIONS
  eventDateTimeAttrDes: AttributeDescription;
  languageAttrDes: AttributeDescription;
  participantAttrDes: AttributeDescription;
  //#endregion

  constructor(
    public listService: EventHttpService,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    private toastService: ToastService,
    protected injector: Injector,
    private eventRegisterBtnDisabledPipe: EventRegisterBtnDisabledPipe
  ) {
    super(
      listService,
      translateService,
      sessionService,
      authenService,
      injector
    );
  }

  getEntityBase(): string {
    return environment.jipUrl.event;
  }

  afterEventPassed(event: EventInterface): void {
    if (event) {
      event.isPassedEvent = true;
    }
  }

  afterLeaveEntity(event: EventInterface): void {
    if (event) {
      event.hasJoined = false;
      event.availableSeats++;
      event.participantsCount--;
    }
  }

  afterJoinEvent(event: EventInterface): void {
    if (event) {
      event.hasJoined = true;
      event.availableSeats =
        event.availableSeats - 1 > 0 ? event.availableSeats - 1 : 0;
      event.isFullyBooked = EventUtils.isEventFullyBooked(event);
      if (event.participantsCount != null && !isNaN(event.participantsCount)) {
        event.participantsCount++;
      }
      this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
    }
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncParticipantsState();
    this.syncEntityJoinStates();
    this.syncEventBindingVariables();
  }

  protected syncAttributeDescriptions(): void {
    this.eventDateTimeAttrDes =
      this.customFormService.getFieldOptions('EventDateTime');
    this.languageAttrDes = this.customFormService.getFieldOptions('Language');
    this.participantAttrDes =
      this.customFormService.getFieldOptions('Participants');
  }

  private syncEventBindingVariables(): void {
    this.items.forEach((event: EventInterface) => {
      event.isFullyBooked = EventUtils.isEventFullyBooked(event);
      event.isPassedEvent = EventUtils.isPassedEvent(event);
      event.isCancelled = EventUtils.isCancelledEvent(event);
      if (event.description) {
        event.description = this.enhanceDescriptionHtmlString(
          event.description
        );
      }
    });
  }

  handleExternalRegister(e, eventDetail): void {
    const isDisabled = this.eventRegisterBtnDisabledPipe.transform(eventDetail);

    if (isDisabled) {
      e.preventDefault();
    }
  }

  getEventEndDate(eventStartDateString: string, eventLength: number): Date {
    const eventStartDate = new Date(eventStartDateString);

    return TimeUtils.addHoursToDate(eventStartDate, eventLength);
  }
}
