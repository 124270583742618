import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styles: [],
})
export class QrCodeModalComponent implements OnInit, OnDestroy {
  @Input() titleTemplateRef?: TemplateRef<any>;
  @Input() getEntityDataCallback?: () => Observable<any>;
  data: any;
  isLoading = true;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.getEntityDataCallback) {
      this.isLoading = true;
      this.getEntityDataCallback()
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.data = data;
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy(): void {
    /** */
  }
}
