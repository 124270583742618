<div>
  <app-search-input-wrapper
    [searchText]="address"
    [editMode]="editting"
    (resetSearchClick)="clearSearch()"
  >
    <input
      type="text"
      class="form-control mb-2"
      (keydown.enter)="$event.preventDefault()"
      [class.hidden]="!editting"
      [placeholder]="hintSearch"
      [(ngModel)]="address"
      (ngModelChange)="clearCurrentMap()"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
    />
  </app-search-input-wrapper>

  <div *ngIf="!!mapObject?.url && !editting" class="mb-2">
    <a target="_blank" [href]="mapObject?.url" appUrlPrefix>
      {{ placeholder }}
    </a>
  </div>
  <ng-container *ngIf="useMapWithJsonData || editting; else mapViewRef">
    <map-view
      *ngIf="mapObject?.viewport; else noFitBoundsRef"
      [boundsPadding]="100"
      [fitBounds]="mapObject?.viewport"
      [latitude]="mapObject?.latitude"
      [longitude]="mapObject?.longitude"
      [streetViewControl]="false"
    >
      <map-marker
        *ngIf="mapObject?.name"
        [latitude]="mapObject?.latitude"
        [longitude]="mapObject?.longitude"
        [markerDraggable]="true"
        (dragEnd)="markerDragEnd($event)"
      >
        <map-info-window [disableAutoPan]="false" #infoWindow>
          <h5>{{ mapObject?.name }}</h5>
          <div>{{ mapObject?.address }}</div>
        </map-info-window>
      </map-marker>
    </map-view>
  </ng-container>
</div>

<ng-template #noFitBoundsRef>
  <map-view
    [zoom]="mapObject?.zoom"
    [latitude]="mapObject?.latitude"
    [longitude]="mapObject?.longitude"
    [streetViewControl]="false"
  >
    <map-marker
      *ngIf="mapObject?.name"
      [latitude]="mapObject?.latitude"
      [longitude]="mapObject?.longitude"
      [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)"
    >
      <map-info-window [disableAutoPan]="false" #infoWindow>
        <h5>{{ mapObject?.name }}</h5>
        <div>{{ address }}</div>
      </map-info-window>
    </map-marker>
  </map-view>
</ng-template>

<ng-template #mapViewRef>
  <app-google-map-view
    [googleMapUrl]="mapData"
    [placeholder]="placeholder"
  ></app-google-map-view>
</ng-template>
