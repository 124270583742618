import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ZoomOutImgModalComponent } from '../components/zoom-out-img-modal/zoom-out-img-modal.component';

@Directive({
  selector: '[appHoverDisplay]',
})
export class HoverDisplayDirective {
  @Input() dataHoverDirective: any;
  @Input() isOverlayDivShown = true;
  @Input() titleTemplateRef: TemplateRef<any>;

  constructor(
    public renderer: Renderer2,
    private hostElement: ElementRef<HTMLElement>,
    private translateService: TranslateService,
    public modalService: NgbModal
  ) {}

  @HostListener('mouseenter') @HostListener('touchstart') onMouseEnter(): void {
    if (
      this.hostElement &&
      this.hostElement.nativeElement &&
      !this.checkHasErrorImg() &&
      this.isOverlayDivShown
    ) {
      const element = this.hostElement.nativeElement;
      const divChildContainer = this.renderer.createElement('div');
      const divContent = this.renderer.createElement('div');

      const text = this.renderer.createText(
        this.translateService.instant('Open-Full-Image')
      );

      this.renderer.appendChild(divContent, text);

      const divChildContentContainer = this.renderer.createElement('div');
      this.renderer.addClass(divChildContainer, 'mask-container');
      this.renderer.addClass(divChildContentContainer, 'mask-content');
      this.renderer.addClass(divContent, 'content');

      this.renderer.setProperty(
        divChildContentContainer,
        'innerHTML',
        '<i class="fa fa-search-plus" aria-hidden="true"></i>'
      );
      this.renderer.appendChild(divChildContentContainer, divContent);
      this.renderer.appendChild(divChildContainer, divChildContentContainer);
      this.renderer.appendChild(element, divChildContainer);
      this.renderer.setStyle(element.firstElementChild, 'opacity', '0.5');
      this.renderer.setStyle(element, 'background-color', '#000000');
      this.renderer.setStyle(element, 'cursor', 'pointer');
    }
  }

  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onMouseLeave(): void {
    if (
      this.hostElement &&
      this.hostElement.nativeElement &&
      !this.checkHasErrorImg() &&
      this.isOverlayDivShown
    ) {
      const element = this.hostElement.nativeElement;
      const childElements = this.hostElement.nativeElement.lastChild;
      this.renderer.removeStyle(element.firstElementChild, 'opacity');
      this.renderer.removeStyle(element, 'background-color');
      this.renderer.removeChild(element, childElements);
    }
  }

  @HostListener('click') onClick(): void {
    if (
      this.hostElement &&
      this.hostElement.nativeElement &&
      !this.checkHasErrorImg()
    ) {
      const modalRef = this.modalService.open(ZoomOutImgModalComponent, {
        centered: true,
        windowClass: 'bootstrap-modal-custom',
      });
      modalRef.componentInstance.data = this.dataHoverDirective;
      modalRef.componentInstance.titleTemplateRef = this.titleTemplateRef;
    }
  }

  checkHasErrorImg(): boolean {
    const element = this.hostElement.nativeElement;
    const childs = element.querySelectorAll('.error-img');
    if (childs.length) {
      return true;
    }
    return false;
  }
}
