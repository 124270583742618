<ng-container
  [appScrollToFragment]="data$ | async"
  *ngIf="data$ | async as response"
>
  <ng-container *ngIf="response.items?.length; else emptyContent">
    <div
      class="list-tiles"
      [class.widget__card--list--background]="!bg_color"
      [style.background-color]="bg_color"
      [class.two-columns]="columns === '2'"
    >
      <div
        (click)="goToEntityDetails(challenge.id)"
        *ngFor="let challenge of response.items"
        class="list-tiles__item"
      >
        <app-card-layout>
          <!--        Image-->
          <ng-container slot="img">
            <app-image-wrapper
              [wrapperClasses]="
                'widget__image-wrapper entity__image-wrapper h-100'
              "
              [imgObject]="challenge.image"
            >
              <span
                class="simple-card__floating organization__logo position-absolute"
              >
                <img
                  [src]="challenge.logo"
                  appDefaultPlaceholder
                  appDynamicImgUrl
                  alt=""
                />
              </span>
            </app-image-wrapper>
          </ng-container>

          <!--        Content-->
          <ng-container slot="body">
            <h3 class="simple-card__title widget__heading">
              {{ challenge.orgName }}
            </h3>
            <div class="simple-card__description widget__content">
              <p appEllipsis [innerHTML]="challenge.description"></p>
            </div>
          </ng-container>

          <!--        Footer-->
          <ng-container slot="footer">
            <div class="p-3">
              <app-challenge-card-footer
                [currentPhase]="challenge.currentTimelinePhase"
                [keyOfferings]="challenge.keyOfferings"
              ></app-challenge-card-footer>
            </div>
          </ng-container>
        </app-card-layout>
      </div>
    </div>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="searchEntitiesService.changePaging($event)"
      [page]="searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>

    <div class="text-right" *ngIf="show_creation_btn">
      <button
        class="btn btn-primary widget__btn-create my-3"
        [callBackLoginPage]="entityCreationPath"
        appRequireLogin
        translate
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
