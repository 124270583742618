import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { environment } from '@src/environments/environment';
import { finalize } from 'rxjs/operators';
import { LikeInterface } from '../../interfaces/like.interface';
import { ApiGetResponse } from '../../interfaces/responses/ApiResponse.interface';
import { UserInterface } from '../../interfaces/user.interface';
import { Entity } from '../../models/entity.model';

@Component({
  selector: 'app-like-modal',
  templateUrl: './like-modal.component.html'
})
export class LikeModalComponent implements OnInit, OnDestroy {
  @Input() modalTitle = '';
  @Input() entityId: number;
  @Input() entityTitle: string;
  @Input() httpService: BaseHttpService<Entity>;

  avatarUrl = environment.wordpressJipImages + 'avatar.png';
  people: UserInterface[] = [];

  // Pagination
  page = 1;
  pageSize = 9;
  collectionSize = 0;
  isLoading = false;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.entityId && this.httpService) {
      this.paginateLikes();
    }
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.paginateLikes();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.paginateLikes();
  }

  private paginateLikes(): void {
    this.isLoading = true;
    const params = new HttpParams({
      fromString: `pageIndex=${this.page}&pageSize=${this.pageSize}`,
    });

    this.httpService
      .paginateLikes(this.entityId, params)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res: ApiGetResponse<LikeInterface>) => {
          this.people = res?.items.map((item) => item.owner);
          this.collectionSize = res?.rowCount;
        },
      );
  }

  ngOnDestroy(): void { /* */ }
}
