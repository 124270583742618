<ng-container *ngIf="baseHttpService && entityId">
  <ng-container
    *ngIf="
      inviteTokenType !== TokenTypes.InviteChallengeJuror;
      else inviteButton
    "
  >
    <label class="venture__label venture__label--head widget__label">
      <ng-container *ngIf="!orgName">
        {{ 'UI.Invitation.InvitingPeople' | translate }}
      </ng-container>
      <ng-container *ngIf="orgName">
        <span
          [innerHTML]="
            'UI.Invitation.InvitingPeopleWithOrgName'
              | translate
              | stringReplacement : { '{orgName}': orgName }
          "
        ></span>
      </ng-container>
    </label>
    <div class="d-flex flex-column flex-md-row mt-2">
      <ng-content></ng-content>
      <div
        class="tooltip-wrapper"
        appTooltip
        #t="ngbTooltip"
        [toolTipControl]="t"
        [ngbTooltip]="(disableReason !== '' ? disableReason : '') | translate"
        [disableHoverForSmallText]="false"
      >
        <ng-container *ngTemplateOutlet="inviteButton"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #inviteButton>
  <button
    appRequireLogin
    appDebounceClick
    (debounceClick)="openInvitationEmail()"
    class="btn btn-primary form-section__btn m-0 mt-2 mt-md-0 ml-md-2"
    [disabled]="inviting || disableReason !== ''"
  >
    <ng-container *ngIf="inviting">
      <i class="fas fa-spinner fa-spin"></i>
    </ng-container>
    <span class="pointer-none">{{ label | translate }}</span>
  </button>
</ng-template>
