import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SearchChannelsService } from '@src/app/components/master-layout/navs/components/thread-filter/services/search-channels.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { SessionService } from '@src/app/core/session.service';
import { defaultEntities } from '@src/app/shared/constants/entities.const';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { filter, first, pairwise, skip } from 'rxjs/operators';
@Component({
  selector: 'app-thread-filter',
  templateUrl: './thread-filter.component.html',
  styles: [],
})
export class ThreadFilterComponent implements OnInit, OnDestroy {
  searchKeyword: string = '';
  activeChannel: any;
  openDropdown: boolean = false;
  activeFilter = EntityName.All;

  @ViewChild('channelInput', { static: false })
  channelInput!: ElementRef<HTMLInputElement>;

  entitySearchKeys = [
    EntityName.Event,
    EntityName.Organization,
    EntityName.Challenge,
  ];
  searchEntities = [
    {
      key: EntityName.All,
      label: 'UI.Common.AllResults',
    },
    ...defaultEntities.filter((entity) =>
      this.entitySearchKeys.includes(entity.key)
    ),
  ];
  isHoverDropdownMenu = false;
  isVisible = false;

  constructor(
    private searchChannelsService: SearchChannelsService,
    private centralConfig: CentralConfigService,
    private globalFilterStored: GlobalFilterStoredService,
    public sessionService: SessionService
  ) {
    this.centralConfig.innovationSpace$
      .pipe(untilDestroyed(this))
      .subscribe((innovationSpace: OrganizationInterface) => {
        if (innovationSpace.id) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      });
    this.sessionService.hasTopicFilter = true;
  }

  async ngOnInit() {
    this.globalFilterStored
      .getCurrentFilterToken()
      .pipe(untilDestroyed(this), filter(Boolean), first())
      .subscribe(async () => {
        this.isVisible = true;
        const activeChannel =
          await this.searchChannelsService.getActiveChannel();

        const subOrganization = StringUtils.getParamFromUrl(
          UrlParam.SubOrganizationId
        );

        if (subOrganization && +subOrganization !== activeChannel.id) return;
        if (activeChannel && this.sessionService.isOnListPage) {
          this.onSelectChannel(activeChannel);
        }
      });

    this.globalFilterStored
      .getCurrentFilterToken()
      .pipe(untilDestroyed(this), filter(Boolean), skip(1), pairwise())
      .subscribe(async ([lastValue, currentValue]) => {
        if (lastValue !== currentValue) {
          await this.searchChannelsService.setActiveChannel(null);
          this.activeChannel = null;
        }
      });

    this.globalFilterStored
      .getCurrentFilterToken()
      .pipe(untilDestroyed(this), pairwise())
      .subscribe(([lastToken, token]) => {
        if (lastToken !== token) {
          this.activeChannel = null;
        }
        if (token) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      });

    this.searchChannelsService.activeFilter$
      .pipe(untilDestroyed(this), filter(Boolean))
      .subscribe((activeOrg) => {
        this.setActiveChannel(activeOrg);
      });
  }

  setActiveChannel(channel): void {
    this.activeChannel = channel;
  }

  async resetSearch(): Promise<void> {
    this.activeChannel = null;
    this.openDropdown = false;

    await this.searchChannelsService.resetChannel();
  }

  onFilterChanged(filterType): void {
    this.activeFilter = filterType;
  }

  async onSelectChannel(channel) {
    this.setActiveChannel(channel);
    this.openDropdown = false;
    await this.searchChannelsService.onSelectChannel(channel);
    this.searchKeyword = '';
  }

  onKeywordChange(keyword): void {
    this.searchChannelsService.handleKeywordChange(keyword);
  }

  onInputFocus($event): void {
    $event.preventDefault();
    this.openDropdown = true;
  }

  onInputBlur(): void {
    if (!this.isHoverDropdownMenu) {
      this.openDropdown = false;
    }
  }

  onMouseenter(): void {
    this.isHoverDropdownMenu = true;
  }

  onMouseLeave(): void {
    this.isHoverDropdownMenu = false;
  }

  ngOnDestroy(): void {
    /** */
  }
}
