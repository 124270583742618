import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@src/app/core/http/base-http.service';

@Injectable({
  providedIn: 'root',
})
export class TagsHttpService extends BaseHttpService<any> {
  getApiRootPath(): string {
    return 'tags';
  }

  getTagsSuggestion(
    field,
    entityName,
    keyword,
    pageIndex,
    pageSize,
    ignoreIds = []
  ) {
    const url = `${this.apiUrl}/tag-suggestion`;

    let params = new HttpParams()
      .set('Keyword', keyword)
      .set('Field', field)
      .set('EntityName', entityName)
      .set('PageSize', pageSize.toString())
      .set('PageIndex', pageIndex.toString())

    ignoreIds.forEach((id) => {
      params = params.append('IgnoreIds', id.toString());
    });

    return this.http.get(url, { params });
  }
}
