import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '@src/app/core/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts-container.component.html',

  // tslint:disable-next-line: no-host-metadata-property
  host: { '[class.ngb-toasts]': 'true', '[class.jip-toast]': 'true' },
})
export class ToastsContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: any): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
