import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@src/app/core/http/base-http.service';

@Component({
  selector: 'app-ventures-modal',
  templateUrl: './ventures-modal.component.html',
})
export class VenturesModalComponent implements OnChanges {
  @Input() modalTitle = '';
  @Input() globalOrgId = 0;
  @Input() entityId: number;
  @Input() entityTitle: string;
  @Input() httpService: BaseHttpService<any>;

  @Output() dismissModal = new EventEmitter<any>();

  @ViewChild('modalRef') modalRef: ElementRef;

  constructor(private modalService: NgbModal) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.entityId !== null && this.entityId >= 0) {
      this.open();
    }
  }

  open(): void {
    this.modalService
      .open(this.modalRef, {
        centered: true,
        backdrop: 'static',
        scrollable: true,
        windowClass: 'ventures-modal',
        size: 'xl',
      })
      .result.then(
        (result) => {
          // do nothing
        },
        (reason) => {
          this.dismissModal.emit();
        }
      );
  }
}
