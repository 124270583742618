import { Injectable } from '@angular/core';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService extends UploadService {
  getApiRootPath(): string {
    return 'files/documents';
  }
}
