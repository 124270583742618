import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { TagInputComponent } from 'ngx-chips';
import { Observable, of } from 'rxjs';
import { TagFieldComponent, TagFieldCustom } from '../tag-field.component';
import { TagFieldType } from './../tag-field.component';

@Component({
  selector: 'app-tag-field-autocomplete',
  templateUrl: './tag-field-autocomplete.component.html',
})
export class TagFieldAutocompleteComponent
  extends TagFieldComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() searchData: (text: string) => Observable<any>;
  @Input() limitItemsTo = 8;
  @Input() keyMapImg = 'image';

  @Output() newTagAdded = new EventEmitter<TagFieldCustom>();

  @ViewChild('tagInput') tagInputViewChild: TagInputComponent;

  TagFieldType = TagFieldType;

  private dropdown: HTMLElement;

  ngAfterViewInit(): void {
    this.listenToDropdownScrollEvent();
  }

  ngOnDestroy(): void {
    if (this.dropdown) {
      this.dropdown.removeEventListener(
        'scroll',
        this.dropdownScrollEventHandler
      );
    }
  }

  searchItems = (text: string): Observable<TagFieldCustom[]> => {
    if (this.searchData) {
      if (this.items.length) {
        this.items.forEach((item) => {
          if (item.value.toString() === text) {
            text = '';
          }
        });
      }

      return this.searchData(text);
    }

    return of([]);
  };

  addTag(item: TagFieldCustom): void {
    this.newTagAdded.emit(item);
  }

  removeTag(): void {
    // Reload search input
    this.tagInputViewChild?.inputForm.input.nativeElement.click();
  }

  checkMatching = (value: string, target: TagFieldCustom) => {
    return true;
  };

  emitValue(): void {
    this.itemsChange.emit(this.items);
  }

  private listenToDropdownScrollEvent(): void {
    this.dropdown = document.querySelector('.ng2-dropdown-menu');
    if (!this.dropdown) {
      return;
    }

    this.dropdown.addEventListener('scroll', this.dropdownScrollEventHandler);
  }

  private dropdownScrollEventHandler = (event: Event) => {
    // do nothing
  };
}
