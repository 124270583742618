import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { SessionService } from '@core/session.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'a[href]',
})
export class LanguagePathDirective implements OnChanges {
  @HostBinding('attr.href') hrefAttr = '';
  @Input() href: string;

  constructor(private sessionService: SessionService) {}

  ngOnChanges(): void {
    this.hrefAttr = this.sessionService.appendLanguagePath(this.href);
  }
}
