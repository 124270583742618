<ng-container *ngIf="item">
  <a
    appActiveRoute
    appUnsavedFormCheck
    class="nav-link nav-link-dropdown d-flex justify-content-start align-items-center nav-link-route p-0"
    [href]="item.route ? item.route : '#'"
    [ngClass]="{ 'dropdown-hover__trigger': item.subMenus }"
    (click)="launchLoadingIndicator($event, item.route ? item.route : '#')"
    (unsavedForm)="handleUnsavedFormState($event)"
  >
    <span class="nav-item__icon-container position-relative">
      <app-shared-icon
        *ngIf="item.jipIcon && item.isSharedIcon"
        [name]="item.jipIcon"
      ></app-shared-icon>
      <i *ngIf="item.faIcon" class="nav__item-icon" [class]="item.faIcon"></i>
      <app-internal-icon
        *ngIf="item.jipIcon && !item.isSharedIcon"
        [name]="item.jipIcon"
        [height]="24"
      >
      </app-internal-icon>

      <ng-container *ngIf="!!count">
        <span class="news__badge"></span>
      </ng-container>
    </span>
    <span
      [style.min-width.px]="item.minWidth || 'unset'"
      class="py-1 font-size-sm ml-3"
    >
      {{ item.name | translate }}
    </span>
  </a>
</ng-container>
