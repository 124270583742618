import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MapsAPILoader, NgMapsInfoWindowComponent } from '@ng-maps/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
declare var google: any;

@Component({
  selector: 'app-google-map-autocomplete',
  templateUrl: './google-map-autocomplete.component.html',
})
export class GoogleMapAutoCompleteComponent
  implements AfterViewInit, OnChanges
{
  @Input() mapData = '';
  @Input() placeholder: string;
  @Input() hintSearch: string;

  @Input() hideLink;
  @Input() editting: boolean;

  @Output() locationSelect = new EventEmitter<string>();

  address = '';
  defaultZoom = 13;
  largeViewZoom = 7;
  geoCoder;
  mapObject: LocationInfo = {} as any;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @ViewChild('infoWindow')
  public infoWindow: NgMapsInfoWindowComponent;
  infoWindowOpen$: Subject<void>;
  useMapWithJsonData = true;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
    this.infoWindowOpen$ = new Subject();
    this.infoWindowOpen$.pipe(debounceTime(500)).subscribe(() => {
      this.openInfoWindow();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mapData) {
      this.handleMapDataInput(this.mapData);
    }
  }

  handleMapDataInput(mapData): void {
    try {
      this.useMapWithJsonData = true;
      this.mapObject = JSON.parse(mapData) as LocationInfo;
      if (!this.address || this.mapObject.address) {
        this.address = this.mapObject.address;
      }
    } catch (err) {
      this.setCurrentLocation(mapData);
      this.useMapWithJsonData = false;
    }

    this.infoWindowOpen$.next();
  }

  ngAfterViewInit(): void {
    // Load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if (!this.mapObject?.longitude && !this.mapObject.latitude) {
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder();
      const options = {
        fields: ['formatted_address', 'geometry', 'name', 'url'],
        strictBounds: false,
      };

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        options
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.handleplaceSelected(autocomplete.getPlace());
        });
      });
    });
  }

  handleplaceSelected(place: any): void {
    // Verify result
    if (!place?.geometry) {
      return;
    }
    // Set latitude, longitude and zoom
    this.mapObject = {
      name: place.name,
      url: place.url,
      address: place.formatted_address,
      latitude: place.geometry.location?.lat(),
      longitude: place.geometry.location?.lng(),
    };
    this.changeAddress(this.mapObject.address);

    if (place.geometry.viewport) {
      this.mapObject.viewport = place.geometry.viewport;
    } else {
      this.mapObject.zoom = this.defaultZoom;
    }
    this.emitLocation();
  }

  changeAddress(address): void {
    this.mapObject.address = address;
    this.address = address;
  }

  markerDragEnd(data: { coords: { lat; lng } }): void {
    if (!data) {
      return;
    }
    this.mapObject.latitude = data.coords.lat;
    this.mapObject.longitude = data.coords.lng;

    this.emitLocation();
  }

  clearSearch(): void {
    this.address = '';
    this.clearCurrentMap();
  }

  clearCurrentMap(): void {
    const { latitude, longitude, viewport, zoom } = this.mapObject;
    this.mapObject = { latitude, longitude, viewport, zoom } as any;
    this.emitLocation();
  }

  emitLocation(): void {
    this.useMapWithJsonData = true;
    this.locationSelect.emit(JSON.stringify(this.mapObject));
  }

  private openInfoWindow(): void {
    if (this.infoWindow) {
      if (this.mapObject && (this.mapObject.name || this.mapObject.address)) {
        this.infoWindow.open();
      }
    }
  }

  // Set Current Location Coordinates
  private setCurrentLocation(address = null): void {
    this.mapObject = this.mapObject || ({} as any);

    // Default to Switzerland Innovation Park Zurich
    Object.assign(this.mapObject, {
      latitude: 47.404984423186306,
      longitude: 8.629762727310876,
      zoom: this.largeViewZoom,
    });
    if (address) {
      this.mapObject.address = address;
    }

    this.infoWindowOpen$.next();
  }
}

export interface LocationInfo {
  address?: string;
  name?: string;
  url?: string;
  viewport?;
  latitude: number;
  longitude: number;
  zoom?: number;
}
