import { Injectable } from '@angular/core';

import {
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import compare from 'just-compare';
import { SessionService } from '@core/session.service';
import { StorageEnum } from '@shared/enums/storage.enum';
import { BaseHttpService } from '@core/http/base-http.service';
import { Observable, lastValueFrom, of } from 'rxjs';
import { AuthenService } from '@core/authen/authen.service';

type UserConfig = Record<string, object>;
type UserConfigRes = { data: UserConfig };
type CookieOptions = {
  expires?: number | Date;
  path?: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserConfigManagementService extends BaseHttpService<any> {
  globalConfig: UserConfig = {};

  isLogin$ = this.authService.isLogin$;

  apiReady$ = this.sessionService.apiReady$.pipe(filter(Boolean));

  private readonly API_PATH = 'accounts/my-preferences';

  private readonly userConfig$ = this.http
    .get<UserConfigRes>(this.API_PATH)
    .pipe(shareReplay());

  constructor(
    protected http: HttpClient,
    protected authService: AuthenService,
    protected sessionService: SessionService,
    private cookie: CookieService
  ) {
    super(http, authService);
  }

  get(key: StorageEnum): Promise<any> {
    return this.asyncCaller(
      this.getUserConfig.bind(this, key),
      this.getLocalConfig.bind(this, key)
    );
  }

  set(key: StorageEnum, value: object, option?: {}): Promise<unknown> {
    return this.asyncCaller(
      this.setUserConfig.bind(this, key, value),
      this.setLocalConfig.bind(this, key, value, option)
    );
  }

  private readonly asyncCaller = (
    localHandler,
    userHandler
  ): Promise<unknown> =>
    lastValueFrom(
      this.apiReady$.pipe(
        withLatestFrom(this.isLogin$),
        mergeMap(([_, isLogin]) => (isLogin ? localHandler() : userHandler())),
        first()
      )
    );

  private setLocalConfig(
    key: StorageEnum,
    payload,
    option?: CookieOptions
  ): Observable<any> {
    if (key === StorageEnum.globalFilters) {
      this.cookie.set(
        StorageEnum.globalFilters,
        JSON.stringify(payload),
        option.expires,
        option.path
      );
    } else {
      localStorage.setItem(key, JSON.stringify(payload));
    }

    return of(true);
  }

  private setUserConfig(key: StorageEnum, value: object): Observable<unknown> {
    if (compare({ ...this.globalConfig[key] }, value)) {
      return of(true);
    }

    this.globalConfig[key] = value;

    return this.http.patch(this.API_PATH, { data: this.globalConfig });
  }

  private getLocalConfig(key: StorageEnum): Observable<unknown> {
    let result =
      key === StorageEnum.globalFilters
        ? this.cookie.get(key)
        : localStorage.getItem(key);

    result = result ? JSON.parse(result) : undefined;

    return of(result);
  }

  private getUserConfig(key: StorageEnum): Observable<object> {
    const config = this.globalConfig[key];

    if (config) {
      return of(config);
    }

    return this.userConfig$.pipe(
      map((res) => res || { data: {} }),
      tap(({ data }) => (this.globalConfig = data)),
      map(({ data }) => data[key])
    );
  }
}
