<div class="d-flex" [ngClass]="parentClass" *ngIf="isPrivate !== undefined">
  <ng-container *ngIf="isPrivate; else isPublished">
    <label class="flag flag-private">
      <i class="fas fa-lock mr-1"></i>
      <span translate>Private</span>
    </label>
  </ng-container>
  <ng-template #isPublished>
    <label class="flag flag-publish">
      <i class="fas fa-globe-europe mr-1"></i>
      <span translate>Public</span>
    </label>
  </ng-template>
</div>
