import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TextDirection } from '@shared/enums/text-direction.enum';
import { ChatMessage } from '@shared/interfaces/chat-message.interface';
import { EntityCreationModalService } from '@src/app/core/services/entity-creation-modal.service';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-message-back-item',
  templateUrl: './message-back-item.component.html',
})
export class MessageBackItemComponent implements OnInit, OnDestroy {
  peopleURL = environment.jipUrl.people;
  TextDirection = TextDirection;
  DateFormat = DateFormat;
  InternalIcon = InternalIcon;

  @Input() message: ChatMessage;
  @Input() direction: TextDirection;
  @Output() deletedThreadMessage = new EventEmitter();

  constructor(
    private readonly createEntityModalService: EntityCreationModalService
  ) {}

  ngOnInit(): void {
    this.listenToDeleteMessageEvent();
  }

  onDelete() {
    this.createEntityModalService.deleteThreadMessage(this.message);
  }

  private listenToDeleteMessageEvent(): void {
    this.createEntityModalService.threadMessageDeleted$
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        if (this.message.id === id) {
          this.deletedThreadMessage.emit(this.message.id);
        }
      });
  }

  ngOnDestroy(): void {
    /** */
  }
}
