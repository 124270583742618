import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SessionService } from '@src/app/core/session.service';
import { MetadataTableComponent } from '@src/app/shared/components/metadata-table/metadata-table.component';
import { OrganizationSettingDialogComponent } from '@src/app/shared/components/molecules/organization-setting-dialog/organization-setting-dialog.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { OrganizationPropertyName } from '@src/app/shared/enums/property-name.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';

@Component({
  selector: 'app-metadata-table-partner-organization',
  templateUrl: '../metadata-table.component.html',
})
export class MetadataTablePartnerOrganizationComponent extends MetadataTableComponent<OrganizationInterface> {
  @Input() isAdmin: boolean;
  entityName = EntityName.Organization;
  orgFilterPropertyName: string[] = [
    'Id',
    'Logo',
    'OrgName',
    'EnclosingOrgName',
    'CreatedDate',
    'Image',
    'Headline',
    'SubHeadline',
    'Description',
    'Expertises',
    'KeyOfferings',
    'ResidentOf',
    'InnovationFocus',
    'Competency',
    'CompanyType',
    'CountryOfOrigin',
  ];

  constructor(
    protected readonly modalService: NgbModal,
    protected sessionService: SessionService,
    public baseHttpService: OrganizationHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  hasEditPermission(attrDes: AttributeDescription, entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    const canEdit = super.hasEditPermission(attrDes, entityId);

    return (
      canEdit && (this.isOwner(entity) || this.hasCustomPermission(attrDes))
    );
  }

  getImgCustomClass(propertyName: string): string {
    switch (propertyName) {
      case 'Logo':
        return 'organization__logo';
      case 'Image':
        return 'organization__banner';
      default:
        return super.getImgCustomClass(propertyName);
    }
  }

  isSettingShown(entityId: number): boolean {
    return this.isAdmin;
  }

  onSetting(id, parentEntity) {
    const entity = this.getEntityById(id);
    this.openSettingModal(entity);
  }

  openSettingModal(organization: OrganizationInterface): void {
    const modalRef = this.modalService.open(
      OrganizationSettingDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'sm',
        scrollable: false,
        windowClass: 'message-modal-custom',
      }
    );

    modalRef.componentInstance.organization = organization;
    modalRef.componentInstance.leadOrg = this.parentEntity;
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.orgFilterPropertyName;
  }

  protected customUpdateColumn(resultColumn: Record<string, any>): void {
    if (resultColumn.propertyName === OrganizationPropertyName.PartnerStatus) {
      resultColumn.width = 170;
    }
  }

  protected hasCustomPermission(attrDes: AttributeDescription): boolean {
    return (
      this.sessionService.currentUser?.isTenantAdmin &&
      attrDes.propertyName === OrganizationPropertyName.PartnerStatus
    );
  }
}
