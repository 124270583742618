import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-people-box',
  templateUrl: './search-people-box.component.html',
})
export class SearchPeopleBoxComponent {
  @Input() styleClass = '';

  @Input() searchName: string;

  @Output() keyChanged = new EventEmitter<string>();

  setDisplayItems(event: string): void {
    this.keyChanged.emit(event);
  }
}
