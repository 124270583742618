import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventInterface } from '@shared/interfaces/event.interface';

@Component({
  selector: 'app-event-external-register-button',
  templateUrl: './event-external-register-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventExternalRegisterButtonComponent {
  @Input() event: EventInterface;
}
