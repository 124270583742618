import { Component, Input } from '@angular/core';
import { TagInterface } from '@shared/interfaces/venture.interface';

@Component({
  selector: 'app-open-for',
  templateUrl: './open-for.component.html',
})
export class OpenForComponent {
  @Input() demands: TagInterface[];

  @Input() offerings: TagInterface[];
}
