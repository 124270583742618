<div class="d-flex align-items-center mt-3">
  <div class="organization__radio-item mb-3">
    <input
      appRequireLogin
      [value]="true"
      class="organization__radio"
      id="'true-radio'"
      [name]="controlName"
      [checked]="isChecked"
      type="radio"
      [class.disabled-input]="!editable"
      (change)="setValue($event)"
    />
    <label class="radio-info d-flex flex-column" for="true-radio">
      <span class="radio-text" translate>
        {{ trueText }}
      </span>
    </label>
  </div>

  <div class="organization__radio-item mb-3 ml-5">
    <input
      appRequireLogin
      [value]="false"
      class="organization__radio"
      id="'false-radio'"
      [name]="controlName"
      [checked]="!isChecked"
      type="radio"
      [class.disabled-input]="!editable"
      (change)="setValue($event)"
    />
    <label class="radio-info d-flex flex-column" for="false-radio">
      <span class="radio-text" translate>
        {{ falseText }}
      </span>
    </label>
  </div>
</div>
