import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from '@env/environment';
import { BaseControlComponent } from '@shared/components/form-custom-attributes/base-control/base-control.component';

@Component({
  selector: 'app-term-agreement-checkbox',
  templateUrl: './term-agreement-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TermAgreementCheckboxComponent),
      multi: true,
    },
  ],
})
export class TermAgreementCheckboxComponent extends BaseControlComponent {
  @Input() termOfUseUrl = environment.termOfUseUrl;

  @Input() portalName = 'jointcreate.com';
}
