import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Entity } from '@src/app/shared/models/entity.model';

@Component({
  selector: 'app-like-entity',
  templateUrl: './like-entity.component.html',
})
export class LikeEntityComponent {
  @Input() isLoadingLikingsCount = false;
  @Input() entity!: Entity;
  @Input() hideLikeNumber = false;

  @Output() triggerToggleLike = new EventEmitter();
  @Output() triggerShowLikingsData = new EventEmitter();

  toggleLike(): void {
    this.triggerToggleLike.emit();
  }

  showLikingsData(): void {
    this.triggerShowLikingsData.emit();
  }
}
