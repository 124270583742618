import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-hidden-content',
  templateUrl: './hidden-content.component.html',
})
export class HiddenContentComponent {
  @Input() message = 'Hidden-content-message';

  @Input() styleClass =
    'hidden-content d-flex align-items-center justify-content-center';
}
