import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-radio-boolean',
  templateUrl: './radio-boolean.component.html',
})
export class RadioBooleanComponent {
  @Input() trueText = 'Yes';
  @Input() falseText = 'No';
  @Input() controlName = '';
  @Input() editable: boolean;
  @Input() isChecked: boolean;

  @Output() selectItem = new EventEmitter();

  setValue(event): void {
    this.selectItem.emit(event.target.value);
  }
}
