export const QUILL_MODULES = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ color: [] }],
    [{ font: [] }],

    [{ header: [1, 2, 3] }],
    ['bold', 'italic', 'underline'],
    ['blockquote'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '+1' }, { indent: '-1' }],
    ['link'],
  ],
};

export const QUILL_FORMATS = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula'
];
