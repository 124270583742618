import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'youTransform',
})
export class YouTransformPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(value: { id: number; firstName: string; lastName: string }, currentId: number): string {
    if (value?.id === currentId) {
      return this.translateService.instant('UI.Common.you');
    } else {
      return value?.firstName + ' ' + value?.lastName;
    }
  }
}
