import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadFilesService } from '@core/http/upload.files.service';
import { UploadService } from '@core/http/upload.service';
import { I18nService } from '@core/i18n/i18n.service';
import { ImageUploaderDialogComponent } from '@shared/components/molecules/image-uploader-dialog/image-uploader-dialog.component';
import {
  FileInterface,
  ImageInterface,
} from '@shared/interfaces/file.interface';
import { ToastService } from '@src/app/core/toast.service';
import { environment } from '@src/environments/environment';
import { isMobile } from 'detect-touch-device';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() id;
  @Input() srcUrl = false;
  @Input() invalid = false;
  @Input() multiLocale = false;
  @Input() acceptType =
    'image/svg+xml, image/png, image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg'; // image/jpg
  @Input() disabled: boolean;
  @Input() inputClass: string;
  @Input() inputId = 'file-upload-input-id';
  @Input() isDocumentFiles = false;
  @Input() documentFiles: any[] = [];

  @Input() allowCropImg = false;

  @Input() locale: string = this.i18nService.language?.locale;

  @Input() aspectRatio: number = 1;

  @Input() displayFileLoading = true;

  @Output() srcUrlChange = new EventEmitter<ImageInterface | string>();

  @Output() fileInputted = new EventEmitter<ImageInterface>();

  @ViewChild('inputFileUpload') inputFileUploadViewChild: ElementRef;

  imgCropperRef: ImageUploaderDialogComponent;

  @ViewChild('imgCropper', { static: false }) set content(
    content: ImageUploaderDialogComponent
  ) {
    if (content) {
      this.imgCropperRef = content;
    }
  }

  isUploading = false;

  isMobile = isMobile;

  constructor(
    private uploadService: UploadService,
    private uploadDocumentService: UploadFilesService,
    private toastService: ToastService,
    private i18nService: I18nService
  ) {}

  onSelectFile(event: any): void {
    // called each time file input changes
    if (event.target.files) {
      const file: File = event.target.files[0];
      if (this.isFileValidated(file)) {
        this.upload(file);

        this.emitImageChanged(file);
      } else {
        this.resetInputFileUpload();
      }
    }
  }

  upload(file: File): void {
    this.isUploading = true;
    this.uploadService.imageUploadPublisher.next(true);
    let service: UploadFilesService | UploadService;
    let apiRequest: Observable<object>;

    if (this.isDocumentFiles) {
      service = this.uploadDocumentService;
    } else {
      service = this.uploadService;
    }

    if (this.multiLocale) {
      apiRequest = service.uploadMultiLocale(this.id, file, file?.name);
    } else {
      apiRequest = service.uploadSingleLocale(
        this.id,
        file,
        file?.name,
        '',
        this.locale
      );
    }

    apiRequest
      .pipe(
        catchError((error) => {
          this.showCommonError();
          return of(null);
        }),
        finalize(() => {
          this.resetInputFileUpload();
        })
      )
      .subscribe((data: FileInterface | any) => {
        this.isUploading = false;
        if (data) {
          if (data.source_url) {
            // wordpress
            this.emitData(data.source_url);
          } else {
            this.emitData(data);
          }
        }
        this.uploadService.imageUploadPublisher.next(false);
      });
  }

  emitData(data: any): void {
    if (!data) {
      return;
    }

    if (Array.isArray(data.images)) {
      data.images.forEach((image: ImageInterface, index) => {
        if (image.locale === this.locale || index === 0) {
          image.id = data.id;
          this.srcUrlChange.emit(image);
        }
      });
    } else if (data.image?.url) {
      data.image.id = data.id;

      this.srcUrlChange.emit(data.image);
    } else {
      this.srcUrlChange.emit(data); // string
    }
  }

  emitImageChanged(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () =>
      this.fileInputted.emit({
        url: reader.result as string,
        alt: file.name,
      });
  }

  showCommonError(): void {
    this.toastService.showError(
      'Fail to upload File: File is too large or unsupported.',
      '',
      5000
    );
  }

  private resetInputFileUpload(): void {
    this.inputFileUploadViewChild.nativeElement.value = '';
  }

  private isFileValidated(file: File): boolean {
    if (!file || file.size > environment.maxUploadSize) {
      this.showCommonError();
      return false;
    }

    if (this.isDocumentFiles && this.documentFiles?.length > 0) {
      const existedFileName = this.documentFiles.some(
        (item) =>
          (item.file?.alt ||
            item.alt ||
            (typeof item === 'string' && item.replace(/^.*[\\\/]/, ''))) ===
          file.name
      );
      if (existedFileName) {
        this.toastService.showError('File name is already exist!', '', 5000);
        return false;
      }
    }
    return true;
  }

  triggerUpload(): void {
    if (this.allowCropImg) {
      this.imgCropperRef?.open();
    } else {
      this.inputFileUploadViewChild.nativeElement.click();
    }
  }
}
