import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomAtrributeValue } from '../../interfaces/attribute-description.interface';
import {
  DateFilterPayload,
  DateFilterType, DATE_FILTER_OPTIONS
} from '../../models/filter-pane.model';
import { TimeUtils } from '../../utils/time-utils';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
})
export class DateFilterComponent {
  @Input() dateFilter: DateFilterPayload = {
    from: null,
    to: null,
    selectedMode: DateFilterType.NoFilter,
  };

  @Input() labelPosition: 'top' | 'left' = 'left';

  @Output() dateFilterChange = new EventEmitter<DateFilterPayload>();

  dateFilterOptions: CustomAtrributeValue[] = [];

  fromTime: NgbTimeStruct;
  toTime: NgbTimeStruct;
  minToDate: NgbDateStruct = null;
  maxFromDate: NgbDateStruct = null;

  DateFilterType = DateFilterType;

  private inputtedDate: string;

  private readonly DAYS_OF_WEEK = 7;

  constructor(private translateService: TranslateService) {
    this.fromTime = this.getDefaultMinTime();
    this.toTime = this.getDefaultMaxTime();
    this.initDateFilterOptions();
  }

  changeDateFilterMode(
    dateFilterOption: CustomAtrributeValue,
    emitEvent = false
  ): void {
    if (this.dateFilter.selectedMode === DateFilterType.Period) {
      this.inputtedDate = null;
    }

    this.dateFilter.selectedMode = dateFilterOption.codeId as DateFilterType;

    const previousDate: Date = this.inputtedDate
      ? new Date(this.inputtedDate)
      : null;

    switch (this.dateFilter.selectedMode) {
      case DateFilterType.NoFilter:
        this.setFromDate(null);
        this.setToDate(null);
        this.inputtedDate = null;
        break;

      case DateFilterType.After:
        this.setFromDate(TimeUtils.getMidnightDate(previousDate).toISOString());
        this.setToDate(TimeUtils.getMaxDate().toISOString());
        break;

      case DateFilterType.Before:
        this.setFromDate(TimeUtils.getMinDate().toISOString());
        this.setToDate(TimeUtils.getEndDate(previousDate).toISOString());
        break;

      case DateFilterType.On:
        this.setFromDate(TimeUtils.getMidnightDate(previousDate).toISOString());
        this.setToDate(TimeUtils.getEndDate(previousDate).toISOString());
        break;

      case DateFilterType.Period:
        const today = new Date();
        const priorDate = new Date(
          today.setDate(today.getDate() - this.DAYS_OF_WEEK)
        );

        this.setFromDate(TimeUtils.getMidnightDate(priorDate).toISOString());
        this.setToDate(TimeUtils.getEndDate().toISOString());
        break;
    }
    if (emitEvent) {
      this.dateFilterChange.emit(this.dateFilter);
    }
  }

  setFromDate(date: string, emitEvent = false): void {
    this.dateFilter.from = date;
    if (this.dateFilter.selectedMode === DateFilterType.On && date) {
      this.dateFilter.to = TimeUtils.getEndDate(new Date(date)).toISOString();
    }

    this.minToDate =
      this.dateFilter.selectedMode === DateFilterType.Period
        ? TimeUtils.getBoostrapDate(date)
        : null;

    if (emitEvent) {
      this.dateFilterChange.emit(this.dateFilter);
      this.inputtedDate = date;
    }
  }

  setToDate(date: string, emitEvent = false): void {
    this.dateFilter.to = date;
    this.dateFilterChange.emit();

    this.maxFromDate =
      this.dateFilter.selectedMode === DateFilterType.Period
        ? TimeUtils.getBoostrapDate(date)
        : null;

    if (emitEvent) {
      this.dateFilterChange.emit(this.dateFilter);
    }
  }

  get selectedModeIndex(): number {
    return (
      this.dateFilterOptions.findIndex(
        (x) => x.codeId === this.dateFilter.selectedMode
      ) || 0
    );
  }

  private initDateFilterOptions(): void {
    this.dateFilterOptions = DATE_FILTER_OPTIONS.map((x) => {
      return {
        codeId: x.codeId,
        value: this.translateService.instant(x.value),
        order: x.order,
      };
    });
  }

  private getDefaultMinTime(): NgbTimeStruct {
    return {
      hour: 0,
      minute: 0,
      second: 0,
    };
  }

  private getDefaultMaxTime(): NgbTimeStruct {
    return {
      hour: 23,
      minute: 59,
      second: 59,
    };
  }
}
