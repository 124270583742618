import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageInterface } from '@shared/interfaces/file.interface';

@Component({
  selector: 'app-image-input-display',
  templateUrl: './image-input-display.component.html',
})
export class ImageInputDisplayComponent {
  // Local source
  @Input() source = '';

  @Input() fileName = '';

  @Input() uploading = false;

  @Output() imageRemoved = new EventEmitter();

  imgUrl = '';

  setImage(image: ImageInterface): void {
    this.source = image.url;
    this.fileName = image.alt;
  }

  setLoading(isLoading: boolean): void {
    this.uploading = isLoading;
  }

  clearImage(): void {
    this.source = '';
    this.imageRemoved.emit();
  }

  setImageUrl(image: ImageInterface): void {
    this.imgUrl = image.url;
  }
}
