import { AttributeDescription, AttributeValue } from './attribute-description.interface';

export interface MetadataRecord {
  attributeDescriptions: AttributeDescription[];
  attributeValues: AttributeValue[];
}

export interface SortEventInterface {
  sorts: { dir: string, prop: string }[];
}

export interface ResizeEventInterface {
  column: DatatableColumn;
  newValue: number;
}

export interface DatatableColumn {
  name: string;
  prop: string;
  canAutoResize: boolean;
  draggable: boolean;
  resizeable: boolean;
  sortable: boolean;
  width: number;
}

export class TableSettingStored {
  entityName: string;
  columnData: Partial<DatatableColumn>[];

  constructor(entityName: string, columnData: Partial<DatatableColumn>[] = []) {
    this.entityName = entityName;
    this.columnData = columnData;
  }
}

export const DEFAULT_IMAGE_CLASS_VALUE = 'default-img pb-1';
