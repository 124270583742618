import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookies-we-are-sorry',
  templateUrl: './cookies-we-are-sorry.component.html',
})
export class CookiesWeAreSorryComponent {
  constructor(public activeModal: NgbActiveModal) { }

  closeWeAreSorry(): void {
    this.activeModal.close(false);
    location.reload();
  }
}
