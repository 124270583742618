<div class="news-messages__header">
  <div class="news-messages__owner">
    <div class="news-messages__owner-avatar-wrapper">
      <div class="news-messages__owner-avatar">
        <ng-container *ngIf="people; else replaceImg">
          <ng-container *ngIf="showPeopleAsJipLogo; else peopleImg">
            <app-shared-icon [name]="'logo'"></app-shared-icon>
          </ng-container>

          <ng-template #peopleImg>
            <a appUnsavedFormCheck [href]="peopleUrl + '/' + people.id">
              <img [src]="people.image" appDefaultAvatar appDynamicImgUrl />
            </a>
          </ng-template>
        </ng-container>

        <ng-template #replaceImg>
          <ng-container *ngIf="!isSystemNews; else portalImg">
            <app-deactive-entity></app-deactive-entity>
          </ng-container>

          <ng-template #portalImg>
            <app-shared-icon [name]="'logo'"></app-shared-icon>
          </ng-template>
        </ng-template>
      </div>
    </div>

    <ng-content></ng-content>
  </div>

  <div
    *ngIf="!hideMessageDate || isPostOwner"
    class="news-messages__date-time flex-shrink-0 mb-auto"
  >
    <ng-container *ngIf="!hideMessageDate">
      {{ messageDate | localizedDate : DateFormat.FullDateTimeCommon2 }}
    </ng-container>

    <span *ngIf="isPostOwner || isEdited">| </span>
    <button
      *ngIf="isPostOwner"
      (click)="onEdit()"
      class="edit-btn flex-shrink-0 border-none bg-transparent mb-1 px-0"
    >
      <i class="ml-1 fas fa-pencil-alt"></i>
    </button>
    <button
      *ngIf="isPostOwner"
      (click)="onDelete()"
      class="edit-btn flex-shrink-0 border-none bg-transparent mb-1 px-0"
    >
      <i
        class="ml-2 fas fa-trash"
        [ngbTooltip]="'UI.DeactivatePost.Tooltip' | translate"
      ></i>
    </button>

    <ng-container *ngIf="isEdited">
      <span class="ml-2">{{ 'UI.Interaction.Edited' | translate }}</span>
    </ng-container>
  </div>
</div>
