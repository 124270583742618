import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { ToastService } from '@src/app/core/toast.service';
import { VentureInterface } from '@src/app/shared/interfaces/venture.interface';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-join-in-dialog',
  templateUrl: './join-in-dialog.component.html',
  styles: [],
})
export class JoinInDialogComponent {
  @Input() baseHttpService: BaseHttpService<any>;
  @Input() entity: VentureInterface;
  @Input() placeholder = '';
  @Input() title = '';
  @Input() customUserRoles: CustomAtrributeValue[] = [];

  isSending = false;
  currentRoleIndex = 0;
  message: string;

  constructor(
    protected authenService: AuthenService,
    public activeModal: NgbActiveModal,
    private toastService: ToastService
  ) {}

  send(): void {
    this.isSending = true;
    const dto: JoinInRequestInterface = {
      message: this.message,
    };

    if (this.customUserRoles.length > 0) {
      dto.role = this.customUserRoles[this.currentRoleIndex]?.codeId;
    }

    this.baseHttpService
      .join(this.entity.id, dto)
      .pipe(
        finalize(() => {
          this.isSending = false;
          this.close();
        })
      )
      .subscribe({
        next: () => {
          this.entity.isPending = true;
          this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        },
        error: (errorRes: HttpErrorResponse) => {
          if (errorRes.error?.title) {
            this.toastService.showError(errorRes.error?.title);
          }
        },
      });
  }

  close(): void {
    this.activeModal.close(false);
  }
}

export interface JoinInRequestInterface {
  role?: string;
  message?: string;
  targetTimezone?: number;
}
