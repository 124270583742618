import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SessionService } from '@core/session.service';
import { ApiResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { AuthenService } from '@src/app/core/authen/authen.service';
import {
  FormComponentInterface,
  FormErrorService,
} from '@src/app/core/form/form-error.service';
import { EventPaymentService } from '@src/app/core/http/event-payment.service';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { LoginPayload } from '@src/app/shared/interfaces/request/authen.interface';
import { RegisterResponse } from '@src/app/shared/models/authen.model';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  providers: [FormErrorService, EventPaymentService],
})
export class FormLoginComponent implements OnInit, FormComponentInterface {
  @Input() email: string;

  @Input() assignToOrgId: number;

  @Input() applyPendingToAutoAssignment: boolean;

  @Input() eventId

  @Output() loginSuccess = new EventEmitter<void>();

  @Output() openForgotPasswordForm: EventEmitter<any> = new EventEmitter();
  @Output() openAccountInactiveInformation = new EventEmitter();

  usernameMinLength = environment.loginFormLimit.usernameMinLength;
  passwordMinLength = environment.loginFormLimit.passwordMinLength;

  loginForm: UntypedFormGroup;
  passwordIsShow = false;
  errorMessage: { [key: string]: string };
  formErrorKey;

  portalName: string = environment.portalName;
  portalUrl: string = environment.portalUrl;
  userAccountNotActive = false;
  sentEmailAddress: string;
  isSubmitting?: boolean;

  constructor(
    protected fb: UntypedFormBuilder,
    public authenService: AuthenService,
    public formErrorService: FormErrorService,
    protected sessionService: SessionService,
    public cookieService: CookieService,
    private eventPayment: EventPaymentService
  ) {
    this.portalName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
    this.portalUrl = this.cookieService.get(SessionKeyEnum.TENANT_DOMAIN);
  }

  getForm(): UntypedFormGroup {
    return this.loginForm;
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [
        '',
        {
          validators: [Validators.required],
        },
      ],
      password: [
        '',
        {
          validators: [Validators.required],
        },
      ],
    });

    if (this.email) {
      this.loginForm.get('username')?.setValue(this.email);
    }

    this.formErrorService.register(this);
  }

  onSubmit(): void {
    this.isSubmitting = true;

    this.loginForm.value.username = this.loginForm.value.username.trim();

    const loginPayload: LoginPayload = {
      ...this.loginForm.value,
    };

    const token = StringUtils.getParamFromUrl(UrlParam.Token);

    const eventDateTime = StringUtils.getParamFromUrl(UrlParam.EventDateTime)

    if (token) {
      loginPayload.invitedToken = token;
    }

    if (this.assignToOrgId) {
      loginPayload.orgId = this.assignToOrgId;
      loginPayload.applyPendingProcess = this.applyPendingToAutoAssignment;
    }

    if(this.eventId) {
      delete loginPayload.invitedToken;
    }

    this.authenService
      .login(loginPayload)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
        })
      )
      .subscribe((data: any) => {
        if (this.sessionService.isLogin) {
          this.sessionService.deleteGlobalFilterCookies();
          this.loginSuccess.next();
          if(this.eventId) {
            this.eventPayment.handleEventPayment({id: this.eventId, eventDateTime } as EventInterface)
          }
        } else if (this.handleNotActiveAccount(data)) {
          return;
        } else {
          this.checkError(data);
        }
      });
  }

  handleNotActiveAccount(errorResponse: any): boolean {
    // Replace condition by status code
    if (
      errorResponse &&
      errorResponse.status === 400 &&
      errorResponse.error.exceptionType === 'NotActivatedException'
    ) {
      this.userAccountNotActive = true;

      const username = this.username.value;
      this.authenService.getAccountInfo(username).subscribe({
        next: (data: RegisterResponse | ApiResponse) => {
          const error = data as ApiResponse;
          if (error && error.status === 400) {
            this.checkError(error);
          } else {
            const resData = data as RegisterResponse;
            this.sentEmailAddress = resData.email;
            this.openAccountInactiveInformation.emit();
          }
        },
        error: (error) => {
          this.checkError(error);
        },
      });

      return true;
    }

    return false;
  }

  resendConfirmationEmail(usernameOrEmail: string): void {
    this.isSubmitting = true;

    const sentToEmail = usernameOrEmail?.trim();
    this.authenService.resendConfirmationEmail(sentToEmail).subscribe({
      next: (data: any) => {
        this.isSubmitting = false;

        const error = data as ApiResponse;
        if (error && error.status === 400) {
          this.checkError(error);
        }
      },
      error: (error) => {
        this.isSubmitting = false;
        this.checkError(error);
      },
    });
  }

  checkError(data): void {
    this.formErrorService.handleError(data);
  }

  togglePassword(event: Event): void {
    event.preventDefault();
    this.passwordIsShow = !this.passwordIsShow;
  }

  toggleForgotPasswordForm(event: Event): void {
    event.preventDefault();
    this.openForgotPasswordForm.emit(event);
  }

  get username(): AbstractControl {
    return this.loginForm.get('username');
  }
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
}
