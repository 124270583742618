<ng-container
  [appScrollToFragment]="data$ | async"
  *ngIf="data$ | async as response; else skeleton"
>
  <app-filter-pane
    [httpService]="ventureService"
    [entityName]="EntityName.Venture"
    [isShowFilterPane]="show_filter_pane"
    [orgId]="org_id"
    [filterPaneUi]="filterPaneUi"
  >
  </app-filter-pane>

  <ng-container *ngIf="response.items?.length; else emptyContent">
    <div
      class="list-tiles"
      [class.two-columns]="columns === '2'"
      [class.widget__card--list--background]="!bg_color"
      [style.background-color]="bg_color"
      *ngIf="!isLoading; else skeleton"
    >
      <div
        (click)="goToEntityDetails(venture.id)"
        *ngFor="let venture of response.items"
        class="list-tiles__item"
      >
        <app-card-layout>
          <!--        Image-->
          <ng-container slot="img">
            <app-image-wrapper
              [wrapperClasses]="
                'widget__image-wrapper entity__image-wrapper h-100'
              "
              [imgObject]="venture.image"
            >
            </app-image-wrapper>
          </ng-container>

          <!--        Content-->
          <ng-container slot="body">
            <h3 class="simple-card__title widget__heading">
              {{ venture.title }}
            </h3>
            <div class="simple-card__description widget__content">
              <app-rich-text-toggle
                controlName="dummyName"
                [text]="venture.content"
                [showOverlayInsteadOfShowMore]="true"
                [hideSeeMore]="true"
                [controlStyleByCentralConfig]="true"
                [minNumOfRows]="show_open_for ? 5 : 7"
              >
              </app-rich-text-toggle>
            </div>

            <app-open-for
              *ngIf="show_open_for"
              [demands]="venture.demands"
              [offerings]="venture.offerings"
            ></app-open-for>
          </ng-container>

          <!--        Footer-->
          <ng-container slot="footer">
            <div class="p-3">
              <app-read-more></app-read-more>
            </div>
          </ng-container>
        </app-card-layout>
      </div>
    </div>

    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="searchEntitiesService.changePaging($event)"
      [page]="searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>

    <div class="text-right" *ngIf="show_creation_btn">
      <button
        class="btn btn-primary widget__btn-create my-3"
        [callBackLoginPage]="entityCreationPath"
        appRequireLogin
        translate
      >
        <i class="fas fa-plus fa-lg"></i>
      </button>
    </div>
  </ng-container>
</ng-container>
<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
<ng-template #skeleton>
  <div class="loading-skeleton">
    <div class="row">
      <div
        *ngFor="let item of skeletonCount"
        class="col-12"
        [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="skeleton-item img-skeleton"></div>
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6 class="w-50">Card title</h6>
            <h6>Card title</h6>
            <div class="d-flex justify-content-end mt-4">
              <h6 class="w-25">Card title</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
