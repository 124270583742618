import {
  Component,
  Input, OnInit
} from '@angular/core';
import { TimelineItem } from '@shared/interfaces/timeline-item.interface';
import { SessionService } from '@src/app/core/session.service';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-timeline-current-phase',
  templateUrl: './timeline-current-phase.component.html',
  styles: [],
})
export class TimelineCurrentPhaseComponent implements OnInit {
  @Input() hideNameBlock: boolean;
  @Input() leadCompany: LeadCompany = {};

  currentPhase: TimelineItem;

  constructor(
    private sessionService: SessionService
  ) { }

  ngOnInit(): void {
    this.currentPhase = this.leadCompany?.currentTimelinePhase;
  }

  getEntityName(): string {
    return this.leadCompany?.showAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;
  }

  goToEntityPage(): void {
    if (this.leadCompany?.id) {
      const url = this.getEntityName() + '/' + this.leadCompany?.id;
      FormUtils.navigateToWithLanguagePath(this.sessionService, url);
    }
  }
}
