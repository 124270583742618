import { AfterViewInit, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appDynamicImgUrl]'
})
export class DynamicImgUrlDirective implements AfterViewInit, OnInit {
  constructor(
    private elementRef: ElementRef
  ) { }
  ngOnInit(): void {
    this.setLazyForLoadingAttribute();
  }

  ngAfterViewInit(): void {
    const url = this.elementRef.nativeElement?.getAttribute('src');
    if (url && typeof url === 'string') {
      this.setUrl(url);
    }
  }

  setUrl(url: string): void {
    let urlWithQueryParams = url;
    const currentEl = this.elementRef.nativeElement;

    let currentImgWidth = currentEl?.offsetWidth > 0
      ? currentEl?.offsetWidth
      : currentEl?.parentElement?.offsetWidth;

    if (currentImgWidth > 0) {
      if (currentImgWidth < 100) {
        // trick for handle image crash when image from server scale too small
        currentImgWidth = 100;
      }

      const param = urlWithQueryParams?.indexOf('?v=') !== -1
        ? `&width=${currentImgWidth}` // Handle for image url with version
        : `?width=${currentImgWidth}`;
      urlWithQueryParams += param;

      currentEl?.setAttribute('src', urlWithQueryParams);
    }
  }

  setLazyForLoadingAttribute(): void {
    this.elementRef.nativeElement.setAttribute('loading', 'lazy');
  }
}
