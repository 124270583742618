import { defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  apiUrl: 'https://jointcreate-api.jointcreate.com/api/',
  googleMapApiKey: 'AIzaSyDMLiQznlCZdnfCQDjSnLb_Bcgx8IaFWb0',
  google_reCAPTCHA_siteKey: '6LdJqmgaAAAAADB_KJzMTbKJYJkWfbdlpy9E5lur',
  production: true,
  linkedinConfig: {
    pathName: 'https://www.linkedin.com/oauth/v2/authorization',
    clientId: '78gcysj07rkflu',
    redirectUri: 'https://home.jointcreate.com',
  },
};
