<ng-container *ngIf="customMessage && entity">
  <app-new-message-dialog
    [showProfile]="false"
    [placeholder]="placeholder"
    [title]="title"
    [customMessage]="customMessage"
    [defaultContent]="defaultContent"
    [hasContent]="true"
    [choice]="choice"
    [httpService]="httpService"
    [entity]="entity"
    [allowImgOnRTE]="false"
  >
    <app-entity-card-container [entity]="entity" [entityName]="entityName">
    </app-entity-card-container>
  </app-new-message-dialog>
</ng-container>
