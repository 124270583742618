<div class="form-group">
  <div
    class="custom-control custom-switch custom-switch-md check-box-confirmation"
    (click)="openConfirmationModalAsView($event)"
  >
    <input
      #checkbox
      type="checkbox"
      class="custom-control-input"
      [id]="elementId"
      [name]="elementId"
      (click)="openConfirmationModal($event)"
      [ngModel]="isChecked"
      (change)="toggle($event)"
      [disabled]="disabled"
    />
    <label
      class="custom-control-label check-box-confirmation__label"
      [for]="elementId"
    >
      {{ content }}
    </label>
  </div>
</div>
