import { Component, Input, OnInit } from '@angular/core';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';

// app
import { placeholderImg } from '@src/app/shared/constants/common';
import { OrganizationInterface } from '@src/app/shared/interfaces/organization.interface';
import { environment } from '@src/environments/environment';
import { BaseCardComponent } from '../base-card/base-card.component';

@Component({
  selector: 'app-organization-card',
  templateUrl: './organization-card.component.html',
})
export class OrganizationCardComponent
  extends BaseCardComponent<OrganizationInterface>
  implements OnInit
{
  @Input() isLabelNameShown = true;
  placeholderImg = placeholderImg;

  constructor(protected service: OrganizationHttpService) {
    super(service);
  }

  ngOnInit(): void {
    if (this.data?.showAsChallenge) {
      this.getEntityDetailPageUrl(environment.jipUrl.challenges);
    } else {
      this.getEntityDetailPageUrl(environment.jipUrl.organizations);
    }
  }
}
