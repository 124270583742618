import { AttributeType, PatternType, SystemType } from '../enums/attribute-type.enum';
import { AttributeDescription, AttributeValue, CustomAtrributeValue, DescribedValue } from '../interfaces/attribute-description.interface';
import { MetadataRecord } from '../interfaces/metadata-table.interface';

export class CustomMetadataUtils {
  /**
   * Return new metadata record object with filter support attribute type
   *
   * @param  attributeDescriptions list attribute description
   * @param  attributeValues list attribute value
   * @param  patternType pattern type of attribute
   * @param  filterAttributeType supported Attribute Type
   * @returns  new metadata record object
   */
  public static filterMetadataRecord(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = [],
    patternType: string[] = [],
    filterAttributeType: AttributeType[] = []
  ): MetadataRecord {
    const metadataRecord: MetadataRecord = {
      attributeDescriptions: [],
      attributeValues: []
    };

    if (attributeDescriptions.length > 0) {
      for (const attrDes of attributeDescriptions) {
        if (patternType.length > 0 && !patternType.includes(attrDes.type)) {
          continue;
        }
        if (filterAttributeType.length > 0 && !filterAttributeType.includes(attrDes.attributeType as AttributeType)) {
          continue;
        }

        metadataRecord.attributeDescriptions.push(attrDes);

        const attrVal: AttributeValue = attributeValues.find(
          x => x.propertyName === attrDes.propertyName
        );
        if (attrVal) {
          metadataRecord.attributeValues.push(attrVal);
        }
        else {
          const defaultAttrVal: AttributeValue = this.getDefaultAtrributeValue(attrDes);
          metadataRecord.attributeValues.push(defaultAttrVal);
        }
      }
    }
    return metadataRecord;
  }

  /**
   * Return attribute value by property name
   *
   * @param  attributeDescriptions list attribute description
   * @param  attributeValues list attribute value
   * @param  propertyName property name
   * @returns value follow attribute type
   */
  public static getAttributeValueByPropertyName(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = [],
    propertyName = ''
  ): any {
    const attrDes = attributeDescriptions.find(x => x.propertyName === propertyName);

    if (attrDes) {
      const attrVal = attributeValues.find(x => x.propertyName === propertyName);
      if (attrVal?.describedValue) {
        switch (attrDes.attributeType) {
          case AttributeType.SingleSelection:
            if (attrVal.describedValue.selection?.codeId === '') {
              return attrDes.defaultValue;
            }
            return attrVal.describedValue.selection;

          case AttributeType.MultipleSelection:
            return attrVal.describedValue.selections;

          case AttributeType.Image:
            return attrVal.describedValue;

          case AttributeType.RelatedEntity:
            if (attrVal.propertyName === 'CurrentSubmission') {
              return attrVal.describedValue.value?.submissionStatus;
            }
            return attrVal.describedValue.value;

          case AttributeType.Link:
            return attrVal.describedValue.url;

          default:
            return attrVal.describedValue.value;
        }
      }
      else {
        // Handle for table (allow attribute value undefined)
        return undefined;
      }
    }
  }

  public static setNewAttributeValueByPropertyName(
    attributeDescriptions: AttributeDescription[],
    attributeValues: AttributeValue[],
    propertyName: string,
    value: any
  ): void {
    const attrDes = attributeDescriptions.find(x => x.propertyName === propertyName);

    if (attrDes) {
      const attrVal = attributeValues.find(x => x.propertyName === propertyName);
      if (attrVal?.describedValue) {
        attrVal.describedValue = this.getDescribedValueObject(value, attrDes.attributeType, propertyName);
      }
    }
  }

  public static getDescribedValueObject(
    value: any,
    attributeType: AttributeType | string,
    propertyName: string
  ): DescribedValue {
    let result: DescribedValue = {};
    switch (attributeType) {
      case AttributeType.SingleSelection:
        result.selection = value;
        break;

      case AttributeType.MultipleSelection:
        result.selections = value;
        break;

      case AttributeType.Image:
        result = value;
        break;

      case AttributeType.RelatedEntity:
        if (propertyName === 'CurrentSubmission') {
          result.value = {
            submissionStatus: value
          };
        }
        else {
          result.value = value;
        }
        break;

      case AttributeType.Link:
        result.url = value;
        break;

      default:
        result.value = value;
        break;
    }
    return result;
  }

  public static getListPropertyName(
    attributeDescriptions: AttributeDescription[] = [],
  ): string[] {
    const propertyNames: string[] = [];
    if (attributeDescriptions.length > 0) {
      attributeDescriptions.forEach(
        (x: AttributeDescription) => {
          propertyNames.push(x.propertyName);
        }
      );
    }
    return propertyNames;
  }

  public static formatDtoValueForUpdateCustomAttribute(dto: Record<string, any>, key: string, type: string): void {
    if (type === AttributeType.SingleSelection) {
      const value = dto[key] as CustomAtrributeValue;
      if (value && value.codeId) {
        dto[key] = value.codeId;
      }
    }
    if (type === AttributeType.MultipleSelection) {
      const value = dto[key] as CustomAtrributeValue[];
      let formatValue = [];
      if (value && Array.isArray(value)) {
        formatValue = value.map((item) => {
          return item.codeId;
        });
      }
      dto[key] = formatValue;
    }
    if (type === AttributeType.String
      && (dto[key] === null || dto[key] === undefined)) {
      dto[key] = '';
    }
  }

  public static getFormOptions(attrDes: AttributeDescription): Record<string, any> {
    const result: Record<string, any> = {};
    if (attrDes.type === PatternType.Dedicated) {
      result.readonly = attrDes.readonly;
      if (attrDes.attributeType === AttributeType.DateTime) {
        result.hideTimeOfDate = attrDes.dateOnly;
        result.isOptional = !attrDes.required;
      }
      if (attrDes.attributeType === AttributeType.String) {
        result.areRowsLimited = true;
      }
    }
    if (attrDes.attributeType === AttributeType.Image) {
      result.multiLocale = attrDes.systemType === SystemType.MultilingualImage;
    }
    return result;
  }

  private static getDefaultAtrributeValue(attrDes: AttributeDescription): AttributeValue {
    const describedValue: DescribedValue = {};
    switch (attrDes.attributeType) {
      case AttributeType.DateTime:
        describedValue.value = attrDes.defaultValue || null;
        break;

      case AttributeType.SingleSelection:
        describedValue.selection = attrDes.defaultValue;
        break;

      case AttributeType.MultipleSelection:
        describedValue.selections = attrDes.defaultValue;
        break;

      default:
        describedValue.value = attrDes.defaultValue;
        break;
    }

    const defaultAttrVal: AttributeValue = {
      propertyName: attrDes.propertyName,
      describedValue
    };
    return defaultAttrVal;
  }
}

export const ORG_SPECIFIC_INFO_SUPPORT_ATTRIBUTE_TYPE = [
  AttributeType.String,
  AttributeType.StringSingle,
  AttributeType.DateTime,
  AttributeType.SingleSelection,
  AttributeType.MultipleSelection,
  AttributeType.Boolean
];

export const FILTER_PANE_SUPPORT_ATTRIBUTE_TYPE = [
  AttributeType.SingleSelection,
  AttributeType.MultipleSelection,
  AttributeType.DateTime
];
