<div class="{{ class }}" class="card">
  <div class="font-weight-bold p-3" translate>{{ title }}</div>

  <div class="body">
    <div class="px-3 pt-2 pb-3">
      <ng-content></ng-content>
    </div>
  </div>
  <hr />
  <div
    *ngIf="!isShowPaging"
    class="py-3 shadow-none text-center font-weight-bold text-secondary"
  >
    <span (click)="viewSpecificEntity()" class="font-size-sm pointer-cursor">
      <span>
        {{
          'UI.Common.SeeAll'
            | translate
            | stringReplacement
              : {
                  '{entity}': (title | translate)
                }
        }}
      </span>
    </span>
  </div>

  <div *ngIf="isShowPaging && !isEmpty" class="pr-3 pb-4">
    <app-bootstrap-pagination
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePage)="onPageChange($event)"
    ></app-bootstrap-pagination>
  </div>
</div>
