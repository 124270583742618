import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-challenge-card-list',
  templateUrl: './challenge-card-list.component.html',
})
export class ChallengeCardListComponent {
  @Input() items: LeadCompany[];
  @Input() checkNDAFn: (venture: VentureInterface) => Observable<boolean>;

  @Output() submittedVentureToChallenge = new EventEmitter();

  submitVenture(challenge: LeadCompany): void {
    this.submittedVentureToChallenge.emit({ challengeId: challenge.id });
  }

  trackByFn(_index: number, item: VentureInterface): number {
    return item.id;
  }
}
