import { AttributeDescription, CustomAtrributeValue } from '../interfaces/attribute-description.interface';

export class FilterPaneFilter {
  isSelectAll = false;
  attributeDescription: AttributeDescription;
}


//#region DATE FILTER
export interface DateFilterPayload {
  from: string;
  to: string;
  selectedMode: DateFilterType;
}

export enum DateFilterType {
  NoFilter = 'Default',
  After = 'After',
  Before = 'Before',
  On = 'On',
  Period = 'Period'
}

export const DATE_FILTER_OPTIONS: CustomAtrributeValue[] = [
  {
    codeId: DateFilterType.NoFilter,
    value: 'UI.Common.NoFilter',
    order: 0
  },
  {
    codeId: DateFilterType.After,
    value: 'UI.Common.After',
    order: 1
  },
  {
    codeId: DateFilterType.Before,
    value: 'UI.Common.Before',
    order: 2
  },
  {
    codeId: DateFilterType.On,
    value: 'UI.Common.On',
    order: 3
  },
  {
    codeId: DateFilterType.Period,
    value: 'UI.Common.Period',
    order: 4
  }
];
//#endregion End Date Filter
