import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import packageVersion from '../../../../package.json';

export interface Version {
  version: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  apiUrl = 'application';

  constructor(private http: HttpClient) {}

  getStaticVersion(): Version {
    return {
      version: packageVersion.version,
      name: packageVersion.name,
    };
  }

  getServiceVersion(): Observable<Version> | null {
    const url = `${this.apiUrl}/info`;
    return this.http.get<Version>(url);
  }

  encryptInfo(content: string): Observable<string> {
    if (content) {
      const url = `${this.apiUrl}/encrypt-info`;
      return this.http.post<string>(url, { content });
    }
    return of('');
  }

  verifyCaptChaSolution(response: string): Observable<boolean> {
    const url = `${this.apiUrl}/verify-solution`;

    return this.http.post<boolean>(url, { response });
  }
}
