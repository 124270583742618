import { TemplateName } from '../constants/visibility-config.const';

export function getActiveSectionIds(uiTemplate: TemplateName): string[] {
  const defaultIds = [
    'lead-company-panel',
    'venture-details-panel',
    'custom-metadata-panel',
    'advanced-setting-panel',
  ];
  const additionalIds = ['submit-to-orgs', 'timestamp-panel', 'people-panel'];

  return uiTemplate === TemplateName.Default
    ? [...defaultIds, ...additionalIds]
    : defaultIds;
}
